import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { forIn, reduce, get } from 'lodash';
import { withRouter } from 'react-router';

/** Configs, Utils**/
import addLoadWithRedux from 'services/decorators/addLoadWithRedux';
import { msgError } from 'services/errorHandler';

/** Actions**/
import {
  getOsceDays,
  getRooms,
  getDiscipline,
  addOsceDay,
} from 'actions/circuits';

/** UI**/
import { Spin, Collapse, Icon, Button } from 'antd';

/** Components**/
import OsceDayHeader from './OsceDayHeader';
import OsceDayHeaderManual from './OsceDayHeader/OsceDayHeaderManual';
import OsceDayDetails from './OsceDayDetails';

/** Styles**/
import './style.less';

const { Panel } = Collapse;

/**
 * The main component, which render circuits page
 * @reactProps {func} translate - function which translated content according to chosen language
 * @reactProps {func} getOsceDays - function for getting all osce days
 * @reactProps {func} getRooms - function for getting all available rooms
 * @reactProps {func} getDiscipline - function for getting disciplines
 * @reactProps {func} resetData - function for clear all data from store
 * @reactProps {object} osceDaysData - data about osce days
 * @reactProps {boolean} osceDaysLoading - boolean value for tracking loading of data
 * @reactProps {boolean} circuitLoading - boolean value for tracking loading of data
 * @reactProps {boolean} sequenceLoading - boolean value for tracking loading of data
 */
@withRouter
@addLoadWithRedux({
  i18Name: 'circuit',
  stateToProps: ({ runtime }) => {
    const circuitLoading = [];
    const sequenceLoading = [];
    const resetNames = [];
    forIn(runtime, (value, key) => {
      if (/^sequence[0-9]+Loading$/.test(key)) {
        if (!resetNames.includes(key)) {
          sequenceLoading.push(value);
          resetNames.push(key.replace('Loading', ''));
        }
      }
      if (/^circuit[0-9]+Loading$/.test(key)) {
        if (!resetNames.includes(key)) {
          circuitLoading.push(value);
          resetNames.push(key.replace('Loading', ''));
        }
      }
    });
    const reducer = (acc, val) => acc || val;
    return {
      osceData: runtime.osceData,
      osceDaysData: runtime.osceDaysData,
      osceDaysLoading: runtime.osceDaysLoading,
      circuitLoading: reduce(circuitLoading, reducer),
      sequenceLoading: reduce(sequenceLoading, reducer),
      scheduleLoading: get(runtime, 'scheduleLoading'),
      resetNames: resetNames,
    };
  },
  mapMethods: { getOsceDays, getRooms, getDiscipline },
})
export default class OsceDaysWrapper extends Component {
  static propsTypes = {
    translate: PropTypes.func.isRequired,
    getOsceDays: PropTypes.func.isRequired,
    getRooms: PropTypes.func.isRequired,
    getDiscipline: PropTypes.func.isRequired,
    resetData: PropTypes.func.isRequired,
    osceData: PropTypes.object.isRequired,
    osceDaysData: PropTypes.object.isRequired,
    osceDaysLoading: PropTypes.bool,
    circuitLoading: PropTypes.bool,
    sequenceLoading: PropTypes.bool,
    scheduleLoading: PropTypes.bool,
  };

  static defaultProps = {
    osceDaysData: {},
  };

  componentDidMount() {
    this.loadOsceDays();
    this.loadStationData();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.match.params.id !== this.props.match.params.id ||
      prevProps.location.pathname !== this.props.location.pathname
    ) {
      this.props.resetData('osceDays');
      this.props.resetNames.forEach(name => {
        this.props.resetData(name);
      });
      this.loadOsceDays();
    }
  }

  loadOsceDays = async () => {
    const {
      match: {
        params: { id },
      },
      getOsceDays,
    } = this.props;
    await getOsceDays('osceDays', id);
  };

  loadStationData = async () => {
    const { getRooms, getDiscipline } = this.props;
    await getRooms('rooms');
    await getDiscipline('disciplines');
  };

  get isManualOsce() {
    const { osceData } = this.props;
    return get(osceData, 'osceType') === 'MANUAL';
  }

  get isDisabled() {
    return (
      get(this.props.osceData, 'osceStatus') !== 'CIRCUITS CREATED' ||
      get(this.props.osceData, 'freezed') === true
    );
  }

  get renderListOfDays() {
    const { translate, osceData, withControlButtons } = this.props;
    const { data = [] } = this.props.osceDaysData || {};
    return data.map((day, index) => (
      <Panel
        header={
          this.isManualOsce ? (
            <OsceDayHeaderManual
              {...day}
              disabled={this.isDisabled}
              countDays={data.length}
              translate={translate}
            />
          ) : (
            <OsceDayHeader
              {...day}
              translate={translate}
              osceStatus={get(osceData, 'osceStatus')}
              isPreponeDisabled={!day.canPreponeRotation}
              isPostponeDisabled={!day.canPostponeRotation}
              withControlButtons={withControlButtons}
            />
          )
        }
        key={index}
      >
        <OsceDayDetails translate={translate} day={day} key={day.id} />
      </Panel>
    ));
  }

  get shouldSpining() {
    const { props } = this;
    return (
      props.osceDaysLoading || props.sequenceLoading || props.circuitLoading
    );
  }

  addDay = async () => {
    try {
      const {
        updateData,
        match: {
          params: { id },
        },
      } = this.props;
      await addOsceDay(id);
      const { data } = await getOsceDays(id);
      updateData(data, 'osceDays');
    } catch (e) {
      msgError(e);
    }
  };

  render() {
    const { osceDaysData } = this.props;
    return (
      <Spin spinning={this.shouldSpining || false}>
        {this.props.children}
        {get(osceDaysData, 'data.length') > 0 ? (
          <div className="circuit">
            <Collapse
              destroyInactivePanel
              accordion
              defaultActiveKey={['0']}
              bordered={false}
            >
              {this.renderListOfDays}
            </Collapse>
            {this.isManualOsce && (
              <Button
                type="dashed"
                disabled={this.isDisabled}
                className="osce-day_manual_add-day"
                onClick={this.addDay}
              >
                <Icon type="plus" /> {this.props.translate('addDay')}
              </Button>
            )}
          </div>
        ) : (
          !this.shouldSpining && <p style={{ marginTop: 10 }}>No data</p>
        )}
      </Spin>
    );
  }
}
