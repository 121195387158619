import api from 'services/api';
import {
  EXAMINERS_REQUEST_START,
  FETCH_EXAMINERS_SUCCESS,
  FETCH_EXAMINERS_ERROR,
  LOAD_MORE_EXAMINERS_SUCCESS,
} from 'constants/examiners';

import dispatchSend from '../services/dispatchSend';
import { clinicCRUD, examinersCRUD } from './cruds';

export const getClinicProfile = id =>
  dispatchSend('clinicProfile', clinicCRUD.get(id));

export const getExaminerProfile = id =>
  dispatchSend('examinerProfile', examinersCRUD.get(id));

export const getAllExaminers = data => async dispatch => {
  try {
    dispatch({ type: EXAMINERS_REQUEST_START });
    const response = await api.getAllExaminers(data);
    const examiners = response.data;
    dispatch({ type: FETCH_EXAMINERS_SUCCESS, payload: { examiners } });
    return examiners;
  } catch (error) {
    dispatch({ type: FETCH_EXAMINERS_ERROR, error });
    throw error;
  }
};

export const lazyLoadExaminers = data => async dispatch => {
  try {
    dispatch({ type: EXAMINERS_REQUEST_START });
    const response = await api.getAllExaminers(data);
    const examiners = response.data;
    dispatch({ type: LOAD_MORE_EXAMINERS_SUCCESS, payload: { examiners } });
    return examiners;
  } catch (error) {
    dispatch({ type: FETCH_EXAMINERS_ERROR, error });
    throw error;
  }
};
