import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Radio, Button, Alert } from 'antd';

import { QUESTION_TYPES } from 'constants/options';

import i18n from 'services/i18n';

import Comment from './Comment';
import AnswerText from './AnswerText';

@i18n('error')
export default class Answers extends PureComponent {
  static propTypes = {
    question: PropTypes.object.isRequired,
    onAnswer: PropTypes.func.isRequired,
    onComment: PropTypes.func.isRequired,
    onOptionComment: PropTypes.func.isRequired,
    onDeleteAnswer: PropTypes.func.isRequired,
  };

  render() {
    const {
      id,
      comment,
      answers,
      loading,
      errorMsg,
      questionType,
      answersOptions,
      answerComments,
      sectionResponse,
    } = this.props.question;

    switch (questionType) {
      case QUESTION_TYPES.YES_NO:
      case QUESTION_TYPES.SINGLE_SELECTION: {
        // This comes because of poor DB design, can do nothing.
        const userResponse = answers.length
          ? answers.find(a => a.answersOptionsResponse.id !== null)
          : null;

        const selected = userResponse
          ? userResponse.answersOptionsResponse.id.toString()
          : null;

        return (
          <React.Fragment>
            <Comment
              loading={loading}
              comment={comment}
              showComments={this.props.showComments}
              onComment={comment =>
                this.props.onComment(sectionResponse.id, {
                  idQuestion: id,
                  comment,
                })
              }
            />
            <div className="question-divider" />
            <Radio.Group
              className="question"
              value={selected}
              onChange={e =>
                this.props.onAnswer(sectionResponse.id, {
                  idQuestion: id,
                  idAnswerOption: [e.target.value],
                })
              }
            >
              {answersOptions.map(option => {
                const optionCommentObj = answerComments.find(
                  item => item.answerOptionId === option.id,
                );
                const optionComment = optionCommentObj
                  ? optionCommentObj.text
                  : '';

                return (
                  <div className="questions-answer-row" key={option.id}>
                    <Radio value={option.id.toString()}>{option.value}</Radio>
                    <Comment
                      loading={loading}
                      comment={optionComment}
                      showComments={this.props.showComments}
                      onComment={comment =>
                        this.props.onOptionComment({
                          sectionId: sectionResponse.id,
                          questionId: id,
                          answerOptionId: option.id,
                          comment,
                        })
                      }
                    />
                  </div>
                );
              })}
              {answers.length ? (
                <Button
                  type="link"
                  icon="delete"
                  size="small"
                  onClick={() =>
                    this.props.onDeleteAnswer(sectionResponse.id, {
                      idQuestion: id,
                    })
                  }
                >
                  {this.props.translate('deleteAnswer', 'questionnaireAnswers')}
                </Button>
              ) : null}
            </Radio.Group>
          </React.Fragment>
        );
      }

      case QUESTION_TYPES.MULTIPLE_SELECTION:
        return (
          <React.Fragment>
            <Comment
              loading={loading}
              comment={comment}
              showComments={this.props.showComments}
              onComment={comment =>
                this.props.onComment(sectionResponse.id, {
                  idQuestion: id,
                  comment,
                })
              }
            />
            <div className="question-divider" />
            <Checkbox.Group
              className="question"
              value={answers.map(answer => {
                // This comes because of poor DB design, can do nothing.
                const { id } = answer.answersOptionsResponse;
                return id ? id.toString() : null;
              })}
              onChange={values =>
                this.props.onAnswer(sectionResponse.id, {
                  idQuestion: id,
                  idAnswerOption: values,
                })
              }
            >
              {answersOptions.map(option => {
                const optionCommentObj = answerComments.find(
                  item => item.answerOptionId === option.id,
                );
                const optionComment = optionCommentObj
                  ? optionCommentObj.text
                  : '';

                return (
                  <div className="questions-answer-row" key={option.id}>
                    <Checkbox value={option.id.toString()}>
                      {option.value}
                    </Checkbox>
                    <Comment
                      loading={loading}
                      comment={optionComment}
                      showComments={this.props.showComments}
                      onComment={comment =>
                        this.props.onOptionComment({
                          sectionId: sectionResponse.id,
                          questionId: id,
                          answerOptionId: option.id,
                          comment,
                        })
                      }
                    />
                  </div>
                );
              })}
              {errorMsg && (
                <Alert
                  message={this.props.translate('noOptionChosen')}
                  type="warning"
                  showIcon
                />
              )}
              {answers.length ? (
                <Button
                  type="link"
                  icon="delete"
                  size="small"
                  onClick={() =>
                    this.props.onDeleteAnswer(sectionResponse.id, {
                      idQuestion: id,
                    })
                  }
                >
                  {this.props.translate('deleteAnswer', 'questionnaireAnswers')}
                </Button>
              ) : null}
            </Checkbox.Group>
          </React.Fragment>
        );

      case QUESTION_TYPES.OPEN:
      default: {
        // If the user already answered there could be only 1 answer
        const userResponse = answers.length
          ? answers.find(a => a.textAnswer !== null)
          : null;

        const answer = userResponse ? userResponse.textAnswer : '';

        return (
          <React.Fragment>
            <Comment
              loading={loading}
              comment={comment}
              showComments={this.props.showComments}
              onComment={comment =>
                this.props.onComment(sectionResponse.id, {
                  idQuestion: id,
                  comment,
                })
              }
            />
            <div className="question-divider" />
            <AnswerText
              translate={this.props.translate}
              loading={loading}
              answer={answer}
              comment={comment}
              onAnswer={answer =>
                this.props.onAnswer(sectionResponse.id, {
                  idQuestion: id,
                  answer,
                })
              }
              onDelete={() =>
                this.props.onDeleteAnswer(sectionResponse.id, {
                  idQuestion: id,
                })
              }
            />
          </React.Fragment>
        );
      }
    }
  }
}
