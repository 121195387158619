import React from 'react';

/** Components */
import TimeSlotData from './TimeSlotData';

const BreakStation = props => (
  <TimeSlotData {...props} name="students" data={props.studentAssignments} />
);

export default BreakStation;
