import React, { Component } from 'react';
import { Form, Alert } from 'antd';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

const FormItem = Form.Item;

@injectIntl
export default class FormError extends Component {
  static contextTypes = {
    form: PropTypes.object,
  };

  render() {
    const { intl } = this.props;
    if (this.props.errorText) {
      return (
        <FormItem>
          <Alert
            message={intl.formatMessage({
              id: this.props.errorText,
            })}
            type="error"
            closable
            onClose={() => this.props.toggleError('')}
          />
        </FormItem>
      );
    }
    return <div />;
  }
}
