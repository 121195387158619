import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Spin, Input, Row } from 'antd';

import i18n from 'services/i18n';

@i18n('patient')
export default class Comment extends PureComponent {
  static propTypes = {
    comment: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    onComment: PropTypes.func.isRequired,
    showComments: PropTypes.bool.isRequired,
  };

  state = {
    editable: false,
    editedComment: '',
  };

  // Close editor after response
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.loading === true && this.props.loading === false) {
      this.closeEditable();
    }
  }

  openEditable = () => this.setState({ editable: true });

  closeEditable = () => this.setState({ editable: false });

  onSubmit = e => {
    e.preventDefault();
    this.props.onComment(this.state.editedComment);
  };

  handleCommentChange = e => this.setState({ editedComment: e.target.value });

  render() {
    if (!this.props.showComments) {
      return null;
    }

    if (this.state.editable) {
      return (
        <div className="comment-opened">
          <Spin spinning={this.props.loading}>
            <Form className="answer-text-editable" onSubmit={this.onSubmit}>
              <Form.Item colon={false}>
                <Input.TextArea
                  defaultValue={this.props.comment}
                  onChange={this.handleCommentChange}
                  autosize={{ minRows: 3, maxRows: 7 }}
                />
              </Form.Item>
              <Row className="comment-row">
                <Button
                  shape="circle"
                  icon="close"
                  size="small"
                  onClick={this.closeEditable}
                />
                <Button
                  shape="circle"
                  icon="check"
                  size="small"
                  type="primary"
                  htmlType="submit"
                  className="answer-text-btn-last"
                />
              </Row>
            </Form>
          </Spin>
        </div>
      );
    }
    return (
      <span onClick={this.openEditable} className="comment-text">
        {this.props.comment || this.props.translate('noComment')}
      </span>
    );
  }
}
