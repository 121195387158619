import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { pick } from 'lodash';
// Services
import i18n from 'services/i18n';
// Actions
import { typeFilter } from 'actions/adaptFunctions';
// Components
import { generateContent } from './Content';

import '../style.less';

@i18n('roleCriteria')
export default class Criterias extends Component {
  static propTypes = {
    selectedRole: PropTypes.object,
    selectedCriteria: PropTypes.array,
    onChangeCriteria: PropTypes.func.isRequired,
  };

  static defaultProps = {
    selectedRole: { id: null, criteria: [] },
  };

  constructor(props) {
    super();
    this.columns = [
      {
        title: props.translate('criteria'),
        dataIndex: 'type',
        render: (text, record) =>
          record.operation === 'OR' ? (
            <React.Fragment>
              <span className="criteria_table_operation">
                {props.translate('or')}
              </span>
              <span className="criteria_table_value">
                {props.translate(text)}
              </span>
            </React.Fragment>
          ) : (
            props.translate(text)
          ),
      },
      {
        dataIndex: '',
        width: '45%',
        render: (text, record) =>
          generateContent(text, record, props.translate),
      },
    ];
  }

  onSelectChange = (selectedRowKeys, selectedCriteria) => {
    const { selectedRole } = this.props;

    const criteria = selectedCriteria.map(item =>
      pick(item, ['id', 'criterion', 'type', 'operation']),
    );

    typeFilter(criteria);
    this.props.onChangeCriteria({ ...selectedRole, criteria });
  };

  render() {
    if (this.props.selectedRole.criteria.length) {
      const selectedRowKeys = [];

      this.props.selectedCriteria.forEach(criteria => {
        const index = this.props.selectedRole.criteria.findIndex(
          cr => cr.id === criteria.id,
        );

        if (index > -1) selectedRowKeys.push(index);
      });

      const rowSelection = {
        selectedRowKeys,
        onChange: this.onSelectChange,
      };

      return (
        <div className="table-with-action sp-selection_criterias">
          <Table
            bordered
            pagination={false}
            rowSelection={rowSelection}
            columns={this.columns}
            dataSource={this.props.selectedRole.criteria}
          />
        </div>
      );
    }

    return null;
  }
}
