import { forIn } from 'lodash';

import {
  END_LOAD,
  RESET_RUNTIME,
  SET_RUNTIME_VARIABLE,
  UPDATE_ELEMENT_IN_ARRAY,
  UPDATE_SPEC_DATA,
  ADD_ARR_ELEM,
  SPLICE_ARR_ELEM,
  LAZY_START,
} from '../constants/actions';
import dispatchSend from '../services/dispatchSend';

export const updateData = (data, name) => dispatch =>
  dispatch({
    type: END_LOAD,
    name,
    data,
  });

export const setData = (value, name) => dispatch =>
  dispatch({
    type: SET_RUNTIME_VARIABLE,
    name: name,
    value,
  });

export const resetData = name => dispatch =>
  dispatch({
    type: RESET_RUNTIME,
    name,
  });

export const updateSpecData = (data, name) => dispatch =>
  dispatch({
    type: UPDATE_SPEC_DATA,
    name,
    data,
  });

export const updateElementInArray = (path, searcher, data) => dispatch =>
  dispatch({
    type: UPDATE_ELEMENT_IN_ARRAY,
    path,
    searcher,
    data,
  });

export const updateAllByPattern = (pattern, searcher, data) => (
  dispatch,
  getState,
) => {
  const { runtime } = getState();
  forIn(runtime, (value, key) => {
    dispatch({
      type: UPDATE_ELEMENT_IN_ARRAY,
      path: key,
      searcher,
      data,
    });
  });
};

export const replaceSpecArr = (path, searcher, newElement) => dispatch =>
  dispatch({
    type: SPLICE_ARR_ELEM,
    path,
    searcher,
    newElement,
  });

export const reloadTableData = (name, action, opt) =>
  dispatchSend(name, action.getList(opt), {
    timeout: 0,
    startAction: LAZY_START,
    adaptData: resp => {
      let key = 0;
      resp.data.forEach(item => (item.key = key++));
      return resp;
    },
  });
