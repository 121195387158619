import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';

/**
 * Components
 */
import Stations from './Stations';
import DataForm from './DataForm';
import OscePreview from './oscePreview';

const mapStateToProps = ({ runtime }) => ({ data: runtime.osceData });

/**
 * Component OSCE basic page
 * @reactProps {object} data - OSCE data
 */
@connect(mapStateToProps)
export default class BasicData extends PureComponent {
  render() {
    const { data } = this.props;
    return (
      <React.Fragment>
        <DataForm {...this.props} />
        {get(data, 'osceType') === 'AUTO' && (
          <React.Fragment>
            <Stations />
            <OscePreview />
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}
