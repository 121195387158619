import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, InputNumber, Select } from 'antd';
import { get } from 'lodash';
// Services
import i18n from 'services/i18n';
// Options
import { NUMERIC_SIGN, NUMERIC_FIELD } from 'constants/options';

@i18n('form')
class NumericForm extends Component {
  static propTypes = {
    record: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
  };

  render() {
    const { record, translate } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <Form>
        <Input.Group compact>
          <Form.Item style={{ marginBottom: 0 }}>
            {getFieldDecorator('field', {
              initialValue: get(record, 'criterion.field', 'AGE'),
            })(
              <Select
                style={{ marginRight: 10 }}
                placeholder={translate('chooseProperty')}
                onClick={e => e.stopPropagation()}
              >
                {NUMERIC_FIELD.map(item => (
                  <Select.Option key={item.value} value={item.value}>
                    {translate(item.value)}
                  </Select.Option>
                ))}
              </Select>,
            )}
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            {getFieldDecorator('sign', {
              initialValue: get(record, 'criterion.sign', 'OVER'),
            })(
              <Select
                style={{ marginRight: 10 }}
                placeholder={translate('chooseOperation')}
                onClick={e => e.stopPropagation()}
              >
                {NUMERIC_SIGN.map(item => (
                  <Select.Option key={item.value} value={item.value}>
                    {item.title}
                  </Select.Option>
                ))}
              </Select>,
            )}
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            {getFieldDecorator('value', {
              initialValue: get(record, 'criterion.value', 0),
            })(<InputNumber min={0} />)}
          </Form.Item>
        </Input.Group>
      </Form>
    );
  }
}

export default NumericForm;
