export const CHANGE_DISCIPLINES_START = 'CHANGE_DISCIPLINES_START';
export const FETCH_DISCIPLINES_SUCCESS = 'FETCH_DISCIPLINES_SUCCESS';
export const FETCH_DISCIPLINES_ERROR = 'FETCH_DISCIPLINES_ERROR';
export const LOAD_MORE_DISCIPLINES_SUCCESS = 'LOAD_MORE_DISCIPLINES_SUCCESS';
export const CREATE_DISCIPLINE_SUCCESS = 'CREATE_DISCIPLINE_SUCCESS';
export const CREATE_DISCIPLINE_ERROR = 'CREATE_DISCIPLINE_ERROR';
export const DELETE_DISCIPLINE_SUCCESS = 'DELETE_DISCIPLINE_SUCCESS';
export const DELETE_DISCIPLINE_ERROR = 'DELETE_DISCIPLINE_ERROR';
export const UPDATE_DISCIPLINE_SUCCESS = 'UPDATE_DISCIPLINE_SUCCESS';
export const UPDATE_DISCIPLINE_ERROR = 'UPDATE_DISCIPLINE_ERROR';
export const UPDATE_SKILL_SUCCESS = 'UPDATE_SKILL_SUCCESS';
export const UPDATE_SKILL_ERROR = 'UPDATE_SKILL_ERROR';
export const DELETE_SKILL_SUCCESS = 'DELETE_SKILL_SUCCESS';
export const DELETE_SKILL_ERROR = 'DELETE_SKILL_ERROR';
