import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { Dropdown, Icon, Menu, Spin } from 'antd';

/** Constants */
import * as SIDEBARS from 'constants/sidebars';

/** Components */
import MainLayout from 'components/MainLayout';
import Table from 'components/Table/index';
import Card from 'components/Form/Card';

/** Services */
import i18n from 'services/i18n';
import i18nTable from 'services/i18nTable';
import { downloadFile } from 'utils/downloadFile';
import { msgError } from 'services/errorHandler';

/** Actions */
import { getStudentProfile, printStudent } from 'actions/administrator';
import { studentCRUD } from 'actions/cruds';

import StudentForm from './EditStudent';

const mapStateToProps = ({ runtime, localization }) => ({
  student: runtime.studentProfileData,
  loaded: runtime.studentProfileLoaded,
  loading: runtime.studentProfileLoading,
  error: runtime.studentProfileError,
  language: localization.lang,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ dispatch, getStudentProfile }, dispatch);

export const MENU = [
  { key: 'all', text: 'All items' },
  { key: 'failed', text: 'Failed / Partly passed Items' },
];

@i18n('patient')
@i18nTable('studentsOSCEParticipationColumns')
@withRouter
@connect(
  mapStateToProps,
  mapDispatchToProps,
)
export default class Profile extends Component {
  constructor(props) {
    super(props);
    this.columns = this.props.studentsOSCEParticipationColumns;
    this.columns.push({
      title: '',
      dataIndex: '',
      width: '25%',
      render: record => (
        <div className="editable-row-operations">
          <Dropdown overlay={this.getMenu(record.osceId)} trigger={['click']}>
            <a className="ant-dropdown-link action-btn">
              <Icon type="printer" /> {props.translate('print')}{' '}
              <Icon type="down" />
            </a>
          </Dropdown>
        </div>
      ),
    });
  }

  componentDidMount() {
    this.props.getStudentProfile(this.props.match.params.id);
  }

  getMenu = id => {
    return (
      <Menu>
        {MENU.map(({ text, key }) => (
          <Menu.Item key={key} onClick={this.printStudent(key, id)}>
            {text}
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  printStudent = (type, id) => async () => {
    try {
      const { match, language } = this.props;
      const params = {
        // id: match.params.id,
        osceId: id,
        locale: language === 'en' ? 'ENGLISH' : 'GERMAN',
        showAllAnswers: type === 'all',
      };
      const resp = await printStudent(match.params.id, params);
      downloadFile(
        resp.data,
        `StudentRoleDetail${params.showAllAnswers && '_full'}.zip`,
        resp.headers['content-type'],
      );
    } catch (e) {
      const blob = new Blob([e.response.data], { type: 'application/json' });
      const errString = await blob.text();
      const error = JSON.parse(errString);
      msgError(error);
    }
  };

  render() {
    const { loaded, match } = this.props;
    return (
      <MainLayout
        multipleMenus={SIDEBARS.ADMINISTRATION}
        nameMenu="systemAdministration"
      >
        <Spin spinning={this.props.loading}>
          {loaded && (
            <StudentForm data={this.props.student} id={match.params.id} />
          )}
        </Spin>
        <Card from="cards" value="OSCEParticipation" className="card-for-table">
          <div className="table-with-action">
            <Table
              name="osceStudent"
              action={studentCRUD}
              additionalUrl={`${match.params.id}/osce`}
              columns={this.columns}
            />
          </div>
        </Card>
      </MainLayout>
    );
  }
}
