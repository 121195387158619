import React, { PureComponent } from 'react';
import { reduce } from 'lodash';

import i18n from 'services/i18n';
import stateSend from 'services/stateSend';

export default function addLoad({
  name = 'load',
  loadingName = `${name}ing`,
  defaultState = false,
  i18Name = 'form',
  mapMethods,
} = {}) {
  return function(Child) {
    @i18n(i18Name)
    class StateLoading extends PureComponent {
      state = { loading: defaultState };

      componentWillMount() {
        this.methods = {
          [`${name}Enable`]: this.enable,
          [`${name}Disable`]: this.disable,
          [`${name}Toggle`]: this.toggle,
          [`${name}Set`]: this.set,
          [`${name}TimeOut`]: this.timeOut,
          [`state${loadingName}`]: this.stateLoading,
          ...this.mappedMethods,
        };
      }

      enable = () => this.setState({ [loadingName]: true });

      disable = () => this.setState({ [loadingName]: false });

      toggle = () => this.setState({ [loadingName]: !this.state[loadingName] });

      set = el => this.setState({ [loadingName]: el });

      timeOut = (timeout = 1) => {
        this.setState({ [loadingName]: true });
        setTimeout(() => this.setState({ [loadingName]: false }), timeout);
      };

      stateLoading = (promise, opts) =>
        stateSend(this.set, promise, {
          translate: this.props.translate,
          ...opts,
        });

      mapfunc = func => (...args) => this.stateLoading(func(...args));

      get mappedMethods() {
        return reduce(
          mapMethods,
          (result, method, key) => ({ ...result, [key]: this.mapfunc(method) }),
          {},
        );
      }

      render() {
        const opt = { [loadingName]: this.state.loading };
        return <Child {...this.props} {...opt} {...this.methods} />;
      }
    }
    return StateLoading;
  };
}
