import React, { Component } from 'react';
import { invoke } from 'lodash';
import { Form, Input, InputNumber, Icon } from 'antd';
import PropTypes from 'prop-types';

/* Services */
import i18n from 'services/i18n';
import { wait } from 'services/promise';
import { onlyNumber } from 'services/validations';

import './formsStyles.less';

const FormItem = Form.Item;

@i18n('form')
export default class FormInput extends Component {
  static propTypes = {
    title: PropTypes.string, // it will be translated by i18n form
    name: PropTypes.string.isRequired, // it will be key on submit object, if !title name will be translated to
    required: PropTypes.bool, // form option, if your key is mandatory
    requiredStar: PropTypes.bool,
    focus: PropTypes.bool,
    validator: PropTypes.object, // 44line (ant )
    rules: PropTypes.array, // ant form rules
    initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // ant form initialValue
    icon: PropTypes.string, // ant icon name
    type: PropTypes.string, // html input type
    placeholder: PropTypes.bool, // string will translate name from 'form'
    placeholderWithoutTranslate: PropTypes.string, // placeholder
    placeholderForm: PropTypes.string, // placeholder
    precision: PropTypes.number, // number opt
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]), // label
    labelWidth: PropTypes.number,
    colon: PropTypes.bool,
  };

  static contextTypes = {
    disabled: PropTypes.bool,
    form: PropTypes.object,
  };

  componentDidMount() {
    this.focus();
  }

  saveRef = ref => {
    if (!ref) {
      return;
    }
    const { props } = this;
    invoke(props.save, ref);
    this.inputRef = ref;
  };

  focus = async () => {
    await wait();
    this.props.focus && invoke(this.inputRef, 'focus');
  };

  get input() {
    const { props } = this;
    let { placeholder } = props;
    const { autoComplete, type, icon, translate, name } = props;
    const { disabled } = this.context;

    let prefix;
    if (icon) {
      prefix = <Icon type={icon} style={{ fontSize: 13 }} />;
    }
    if (placeholder === true) {
      placeholder = translate(name);
    } else if (props.placeholderForm) {
      placeholder = translate(props.placeholderForm);
    } else {
      placeholder = props.placeholderWithoutTranslate;
    }
    const opt = {
      prefix,
      placeholder,
      autoComplete,
      ref: this.saveRef,
      disabled,
    };

    switch (type) {
      case 'number':
        return (
          <InputNumber
            {...opt}
            min={props.min}
            precision={props.precision || 0}
          />
        );
      case 'textarea':
        return <Input.TextArea {...opt} />;
      default:
        return <Input type={type} {...opt} />;
    }
  }

  render() {
    const { getFieldDecorator } = this.context.form;
    let { label } = this.props;
    const {
      name,
      type,
      colon,
      validator,
      translate,
      initialValue,
      required,
      labelWidth,
      inputWidth,
      requiredStar,
    } = this.props;
    let rules = [];
    if (required)
      rules = [
        {
          required: true,
          message: translate('insert', 'error'),
        },
      ];
    if (validator) {
      rules.push({
        validator: validator.func,
        message: translate(validator.message, 'error'),
      });
    }
    if (type === 'number') {
      rules.push({
        validator: onlyNumber,
        message: translate('onlyPositiveNumber', 'error'),
      });
    }
    if (label === true) {
      label = translate(name);
    }
    return (
      <FormItem
        colon={colon}
        label={label || ''}
        labelCol={{ span: labelWidth }}
        wrapperCol={{ span: inputWidth }}
        required={requiredStar || false}
      >
        {getFieldDecorator(name, { rules, initialValue })(this.input)}
      </FormItem>
    );
  }
}
