import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Checkbox } from 'antd';
import { momentFormatDateMarks } from 'services/format';

@Form.create()
export default class PopoverOsceDays extends PureComponent {
  static propTypes = {
    form: PropTypes.object,
    visible: PropTypes.bool,
    checkedOsceDays: PropTypes.array,
    listOsceDays: PropTypes.array,
    closePopover: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
  };

  componentDidUpdate(prevProps, prevState) {
    // Reset form fields on close to their initial values
    if (!this.props.visible) {
      this.props.form.resetFields();
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSubmit(values);
        this.props.closePopover();
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const options = this.props.listOsceDays.map(day => ({
      label: `${day.osceName} - ${
        day.osceDayDate
          ? momentFormatDateMarks(day.osceDayDate)
          : `Day ${day.osceDayName}`
      }`,
      value: day.id,
    }));

    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Item>
          {getFieldDecorator('ids', {
            initialValue: this.props.checkedOsceDays,
          })(<Checkbox.Group style={{ margin: 0 }} options={options} />)}
        </Form.Item>
        <Button type="primary" htmlType="submit">
          OK
        </Button>
      </Form>
    );
  }
}
