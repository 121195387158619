import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Col, Row, Button } from 'antd';
import { withRouter } from 'react-router';
import { downloadFile } from 'utils/downloadFile';

/** Services */
import i18n from 'services/i18n';
import addLoadWithRedux from 'services/decorators/addLoadWithRedux';
import { msgError } from 'services/errorHandler';

/** Actions */
import { exportOsceStatistic } from 'actions/osceAdministration';

/** Components */
import TablePanel from 'components/TablePanel';
import Import from './Import';

import '../style.less';

@withRouter
@addLoadWithRedux({
  runtimeReduxNames: ['osce'],
})
@i18n('import')
export default class ImportExport extends React.PureComponent {
  static propTypes = {
    osceData: PropTypes.object,
  };

  exportCSV = route => async () => {
    try {
      const { osceData } = this.props;
      const resp = await exportOsceStatistic(route, {
        osceId: this.props.match.params.id,
      });
      const fileName =
        route === 'download'
          ? `Export_${osceData.name}_${osceData.osceDate}.zip`
          : `NewExport_${osceData.name}_${osceData.osceDate}.zip`;
      downloadFile(resp.data, fileName, 'application/zip');
    } catch (err) {
      msgError(err);
    }
  };

  render() {
    const { translate, match, osceData, updateData } = this.props;
    return (
      <Row gutter={40} className="import-block">
        <Col span={10}>
          <Import
            translate={translate}
            osceId={match.params.id}
            osceData={osceData}
            lastImportedFromIOsce={get(osceData, 'lastImportedFromIOsce')}
            osceImportStatus={get(osceData, 'osceImportStatus')}
            updateData={updateData}
          />
        </Col>
        <Col span={10}>
          <div className="import-block_title">
            {translate('downloadResultFiles')}
          </div>
          <TablePanel>
            <Button
              disabled={
                get(osceData, 'osceImportStatus') !== 'SUCCESSFUL' &&
                get(osceData, 'osceImportStatus') !== 'PARTIALLY_FAILED'
              }
              icon="export"
              onClick={this.exportCSV('download')}
              className="table-panel_btn"
            >
              {translate('export', 'form')}
            </Button>
            <Button
              disabled={
                get(osceData, 'osceImportStatus') !== 'SUCCESSFUL' &&
                get(osceData, 'osceImportStatus') !== 'PARTIALLY_FAILED'
              }
              icon="export"
              onClick={this.exportCSV('new/download')}
              className="table-panel_btn"
            >
              {translate('newExport')}
            </Button>
          </TablePanel>
        </Col>
      </Row>
    );
  }
}
