import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import createRootReducer from 'reducers';

const nextReducer = require('./reducers');

export const history = createBrowserHistory();

export default function index(initialState) {
  const create = window.devToolsExtension
    ? window.devToolsExtension()(createStore)
    : createStore;

  const createStoreWithMiddleware = applyMiddleware(
    thunkMiddleware,
    routerMiddleware(history),
  )(create);

  const store = createStoreWithMiddleware(
    createRootReducer(history),
    initialState,
  );

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      store.replaceReducer(connectRouter(history)(nextReducer));
    });
  }

  return store;
}
