import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Col, Row, Tooltip } from 'antd';
// Services
import api from 'services/api';
import i18n from 'services/i18n';
// Components
import LazySelect from 'components/LazySelect';
import StudentsTable from '../StudentsTable';
import SPTable from '../SPTable';
import ExaminersTable from '../ExaminersTable';

@withRouter
@i18n('output')
export default class ExportData extends Component {
  state = {
    osceId: null,
  };

  componentDidUpdate(prevProps, prevState) {
    // If semester have changed clear osceId
    // so that user can select appropriate osce in this semester
    if (
      prevProps.match.params.semesterId !== this.props.match.params.semesterId
    ) {
      this.setState({ osceId: null });
    }
  }

  handleLoadOsces = data => {
    const { semesterId } = this.props.match.params;
    return api.getAllOscesBySemester(semesterId, data).then(res => {
      return res.data;
    });
  };

  handleOsceChange = osceId => this.setState({ osceId });

  render() {
    return (
      <React.Fragment>
        <div style={{ marginBottom: 16, width: 220 }}>
          <Tooltip
            placement="top"
            title={this.props.translate('printSchedules', 'tooltips')}
          >
            <LazySelect
              key={this.props.match.params.semesterId}
              loadData={this.handleLoadOsces}
              onChange={this.handleOsceChange}
            />
          </Tooltip>
        </div>
        {this.state.osceId && (
          <Row gutter={20}>
            <Col span={8}>
              <StudentsTable osceId={this.state.osceId} />
            </Col>
            <Col span={8}>
              <SPTable
                osceId={this.state.osceId}
                semesterId={this.props.match.params.semesterId}
              />
            </Col>
            <Col span={8}>
              <ExaminersTable
                exportQR
                osceId={this.state.osceId}
                semesterId={this.props.match.params.semesterId}
              />
            </Col>
          </Row>
        )}
      </React.Fragment>
    );
  }
}
