import api from 'services/api';
import {
  CHANGE_PROFESSIONS_START,
  GET_PROFESSIONS_SUCCESS,
  GET_PROFESSIONS_ERROR,
  DELETE_PROFESSION_SUCCESS,
  DELETE_PROFESSION_ERROR,
  UPDATE_PROFESSION_SUCCESS,
  UPDATE_PROFESSION_ERROR,
  CREATE_PROFESSION_SUCCESS,
  CREATE_PROFESSION_ERROR,
  LOAD_MORE_PROFESSIONS_SUCCESS,
} from 'constants/profession';

export const getAllProfessions = data => async dispatch => {
  try {
    dispatch({ type: CHANGE_PROFESSIONS_START });
    const response = await api.getAllProfessions(data);
    const professions = response.data.data;
    dispatch({ type: GET_PROFESSIONS_SUCCESS, payload: { professions } });
    return response.data;
  } catch (error) {
    dispatch({ type: GET_PROFESSIONS_ERROR, error });
    throw error;
  }
};

export const lazyLoadProfessions = data => async dispatch => {
  try {
    dispatch({ type: CHANGE_PROFESSIONS_START });
    const response = await api.getAllProfessions(data);
    const professions = response.data.data;
    dispatch({ type: LOAD_MORE_PROFESSIONS_SUCCESS, payload: { professions } });
    return response.data;
  } catch (error) {
    dispatch({ type: GET_PROFESSIONS_ERROR, error });
    throw error;
  }
};

export const createProfession = data => async dispatch => {
  try {
    dispatch({ type: CHANGE_PROFESSIONS_START });
    const response = await api.createProfession(data);
    const profession = response.data;
    dispatch({ type: CREATE_PROFESSION_SUCCESS, payload: { profession } });
    return profession;
  } catch (error) {
    dispatch({ type: CREATE_PROFESSION_ERROR, error });
    throw error;
  }
};

export const deleteProfession = id => async dispatch => {
  try {
    dispatch({ type: CHANGE_PROFESSIONS_START });
    await api.deleteProfession(id);
    dispatch({ type: DELETE_PROFESSION_SUCCESS, payload: { id } });
  } catch (error) {
    dispatch({ type: DELETE_PROFESSION_ERROR, error });
    throw error;
  }
};

export const updateProfession = data => async dispatch => {
  try {
    dispatch({ type: CHANGE_PROFESSIONS_START });
    const response = await api.updateProfession(data);
    const profession = response.data;
    dispatch({ type: UPDATE_PROFESSION_SUCCESS, payload: { profession } });
    return profession;
  } catch (error) {
    dispatch({ type: UPDATE_PROFESSION_ERROR, error });
    throw error;
  }
};
