import React, { Component } from 'react';
import { Layout } from 'antd';

import Sidebar from '../Sidebar';
import './main-layout.less';

const { Content } = Layout;

export default class MainLayout extends Component {
  render() {
    return (
      <Layout className="main-layout">
        <Sidebar {...this.props} />
        <Content className="main-layout_content">{this.props.children}</Content>
      </Layout>
    );
  }
}
