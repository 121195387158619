import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

/** Utils, Configs*/
import addLoadWithRedux from 'services/decorators/addLoadWithRedux';

/** Components*/
import CircuitStation from '../Station/CircuitStation';

/**
 * Component for rendering stations at circuit
 * @reactProps {number} id - circuit Id
 * @reactProps {array} elements - stations details collection
 * @reactProps {number} osceSequenceId - sequence id
 */
@addLoadWithRedux({
  i18Name: 'circuit',
  runtimeReduxNames: ['schedule', 'osce'],
})
export default class CircuitContext extends Component {
  static propsTypes = {
    id: PropTypes.number.isRequired,
    circuitCopyId: PropTypes.number,
    elements: PropTypes.array.isRequired,
    osceSequenceId: PropTypes.number.isRequired,
    osceData: PropTypes.object.isRequired,
    hasTwoSequence: PropTypes.bool.isRequired,
  };

  get isManualOsce() {
    return (
      get(this.props.osceData, 'osceType') === 'MANUAL' &&
      get(this.props.osceData, 'osceStatus') === 'CIRCUITS CREATED'
    );
  }

  render() {
    const { id, elements, osceDay, osceSequenceId } = this.props;
    const list = elements.map((element, i) => (
      <CircuitStation
        key={i}
        index={i}
        station={element}
        positionInOsceSequence={this.props.index + 1}
        circuitId={id}
        countStations={elements.length}
        isManualOsce={this.isManualOsce}
      />
    ));
    if (this.isManualOsce && elements.findIndex(item => !item.id) === -1) {
      const element = {
        id: null,
        osceDayId: osceDay.id,
        osceSequenceId,
      };
      list.push(
        <CircuitStation
          key={list.length}
          index={list.length}
          station={element}
          positionInOsceSequence={this.props.index + 1}
          circuitId={id}
          countStations={elements.length}
          isManualOsce={this.isManualOsce}
        />,
      );
    }

    return (
      <div
        style={{
          display: 'flex',
          background: 'rgba(255,255,255,0.45)',
          paddingLeft: '5px',
        }}
      >
        {list}
      </div>
    );
  }
}
