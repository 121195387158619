export const ROLE_REQUEST_START = 'ROLE_REQUEST_START';
export const FETCH_ROLE_SKILLS_SUCCESS = 'FETCH_ROLE_SKILLS_SUCCESS';
export const FETCH_ROLE_SKILLS_ERROR = 'FETCH_ROLE_SKILLS_ERROR';
export const FETCH_ROLE_INFO_SUCCESS = 'FETCH_ROLE_INFO_SUCCESS';
export const FETCH_ROLE_INFO_ERROR = 'FETCH_ROLE_INFO_ERROR';
export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS';
export const UPDATE_ROLE_ERROR = 'UPDATE_ROLE_ERROR';
export const FETCH_ROLES_SUCCESS = 'FETCH_ROLES_SUCCESS';
export const FETCH_ROLES_ERROR = 'FETCH_ROLES_ERROR';
export const FETCH_ROLES_CRITERIA_SUCCESS = 'FETCH_ROLES_CRITERIA_SUCCESS';
export const FETCH_ROLES_CRITERIA_ERROR = 'FETCH_ROLES_CRITERIA_ERROR';
export const DELETE_ROLE_CRITERION_SUCCESS = 'DELETE_ROLE_CRITERION_SUCCESS';
export const DELETE_ROLE_CRITERION_ERROR = 'DELETE_ROLE_CRITERION_ERROR';
export const UPDATE_ROLE_CRITERION_SUCCESS = 'UPDATE_ROLE_CRITERION_SUCCESS';
export const UPDATE_ROLE_CRITERION_ERROR = 'UPDATE_ROLE_CRITERION_ERROR';
export const CREATE_ROLE_CRITERION_SUCCESS = 'CREATE_ROLE_CRITERION_SUCCESS';
export const CREATE_ROLE_CRITERION_ERROR = 'CREATE_ROLE_CRITERION_ERROR';
export const LOAD_MORE_CRITERIA_SUCCESS = 'LOAD_MORE_CRITERIA_SUCCESS';
export const FETCH_ALL_CATALOGS_SUCCESS = 'FETCH_ALL_CATALOGS_SUCCESS';
export const FETCH_ALL_CATALOGS_ERROR = 'FETCH_ALL_CATALOGS_ERROR';
export const FETCH_ALL_ROLES_OSCES_SUCCESS = 'FETCH_ALL_ROLES_OSCES_SUCCESS';
export const FETCH_ALL_ROLES_OSCES_ERROR = 'FETCH_ALL_ROLES_OSCES_ERROR';
export const LOAD_MORE_ROLES_OSCES_SUCCESS = 'FETCH_ALL_ROLES_OSCES_SUCCESS';
