import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import runtime from './runtime';
import form from './form';
import localization from './localization';
import question from './question';
import discipline from './discipline';
import role from './role';
import checkList from './checkList';
import questionnaire from './questionnaire';
import profession from './profession';
import simulatedPatients from './simulatedPatients';
import examiners from './examiners';
import clinics from './clinics';
import spSelection from './spSelection';
import osce from './osce';

export default history =>
  combineReducers({
    router: connectRouter(history),
    localization,
    question,
    runtime,
    discipline,
    role,
    form,
    checkList,
    questionnaire,
    profession,
    simulatedPatients,
    examiners,
    clinics,
    spSelection,
    osce,
  });
