import React, { Component } from 'react';
import { Spin } from 'antd';
import { connect } from 'react-redux';

import addLoad from 'services/decorators/addLoad';

import Circuite from './circuite';

const mapStateToProps = ({ runtime }) => ({ data: runtime.oscePreviewData });

@addLoad({
  i18Name: 'preview',
})
@connect(mapStateToProps)
export default class Preview extends Component {
  render() {
    const { data, translate } = this.props;

    if (data) {
      const { length } = data.osceDay;
      return (
        <Spin spinning={this.props.loading}>
          {data.osceDay.map((day, i) => (
            <React.Fragment key={i}>
              <span className="osce-profile-name_wrapper">
                <p className="osce-profile-name">
                  {translate('day')} {i + 1} {translate('of')} {length}
                </p>
              </span>
              <Circuite day={day} />
            </React.Fragment>
          ))}
        </Spin>
      );
    }
    return null;
  }
}
