import api from 'services/api';
import {
  SP_SELECTION_REQUEST_START,
  FETCH_SELECTED_SPS_SUCCESS,
  FETCH_SELECTED_SPS_ERROR,
  REMOVE_SP_FROM_SEMESTER_SUCCESS,
  FETCH_SELECTED_OSCE_DAYS_SUCCESS,
  FETCH_SELECTED_OSCE_DAYS_ERROR,
  REMOVE_SP_FROM_SEMESTER_ERROR,
  SELECT_OSCE_DAY,
  AUTO_ASSING_SP_SUCCESS,
  AUTO_ASSING_SP_ERROR,
  ASSIGN_SP_FOR_HALF_DAY_SUCCESS,
  ASSIGN_SP_FOR_HALF_DAY_ERROR,
  SELECT_OSCE_ROLE,
  CLEAR_SELECTED_ROLE,
  FILTER_SELECTED_SPS,
  ASSIGN_SP_TO_ROLE_SUCCESS,
  ASSIGN_SP_TO_ROLE_ERROR,
} from 'constants/spSelection';

export const getAllSelectedSPs = (semesterId, data) => async dispatch => {
  try {
    dispatch({ type: SP_SELECTION_REQUEST_START });
    const response = await api.getAllSelectedSPs(semesterId, data);
    const selectedSps = response.data;
    dispatch({ type: FETCH_SELECTED_SPS_SUCCESS, payload: { selectedSps } });
    return selectedSps;
  } catch (error) {
    dispatch({ type: FETCH_SELECTED_SPS_ERROR, error });
    throw error;
  }
};

export const addSpToSemester = (selectedSpId, semesterId) => async dispatch => {
  try {
    return await api.addSpToSemester(selectedSpId, semesterId);
  } catch (error) {
    throw error;
  }
};

export const addAllSpToSemester = semesterId => async dispatch => {
  try {
    return await api.addAllSpToSemester(semesterId);
  } catch (error) {
    throw error;
  }
};

export const removeSpFromSemester = (
  selectedSpId,
  semesterId,
) => async dispatch => {
  try {
    await api.removeSpFromSemester(selectedSpId, semesterId);
    dispatch({
      type: REMOVE_SP_FROM_SEMESTER_SUCCESS,
      payload: { selectedSpId },
    });
  } catch (error) {
    dispatch({ type: REMOVE_SP_FROM_SEMESTER_ERROR, error });
    throw error;
  }
};

export const removeSpFromRole = data => async dispatch => {
  try {
    return await api.removeSpFromRole(data);
  } catch (error) {
    throw error;
  }
};

export const autoAssingSP = data => async dispatch => {
  try {
    dispatch({ type: SP_SELECTION_REQUEST_START });
    await api.autoAssingSP(data);
    dispatch({ type: AUTO_ASSING_SP_SUCCESS });
  } catch (error) {
    dispatch({ type: AUTO_ASSING_SP_ERROR, error });
    throw error;
  }
};

export const assignSpTo = data => async dispatch => {
  try {
    dispatch({ type: SP_SELECTION_REQUEST_START });
    await api.assignSpTo(data);
    dispatch({ type: ASSIGN_SP_TO_ROLE_SUCCESS });
  } catch (error) {
    dispatch({ type: ASSIGN_SP_TO_ROLE_ERROR });
    throw error;
  }
};

export const assignSpToMultipleDays = (
  selectedSpId,
  data,
) => async dispatch => {
  try {
    return await api.assignSpToMultipleDays(selectedSpId, data);
  } catch (error) {
    throw error;
  }
};

export const assignSpForHalfDay = (
  osceDayId,
  assignSpForHalfDay,
) => async dispatch => {
  try {
    dispatch({ type: SP_SELECTION_REQUEST_START });
    const response = await api.assignSpForHalfDay(
      osceDayId,
      assignSpForHalfDay,
    );
    const { osceDay } = response.data;
    dispatch({
      type: ASSIGN_SP_FOR_HALF_DAY_SUCCESS,
      payload: { osceDayId, assignSpForHalfDay },
    });
    return osceDay;
  } catch (error) {
    dispatch({ type: ASSIGN_SP_FOR_HALF_DAY_ERROR, error });
    throw error;
  }
};

export const getSelectedOSCEDays = semesterId => async dispatch => {
  try {
    dispatch({ type: SP_SELECTION_REQUEST_START });
    const response = await api.getSelectedOSCEDays(semesterId);
    const osceDays = response.data.osceDayResponses;
    dispatch({ type: FETCH_SELECTED_OSCE_DAYS_SUCCESS, payload: { osceDays } });
    return osceDays;
  } catch (error) {
    dispatch({ type: FETCH_SELECTED_OSCE_DAYS_ERROR, error });
    throw error;
  }
};

export const selectOSCEDays = osceDays => dispatch => {
  dispatch({ type: SELECT_OSCE_DAY, payload: { osceDays } });
};

export const selectOSCERole = selectedRole => async dispatch => {
  try {
    dispatch({ type: SELECT_OSCE_ROLE, payload: { selectedRole } });
    const response = await api.filterSelectedSPsByRole(selectedRole);
    const selectedSps = response.data;
    dispatch({ type: FILTER_SELECTED_SPS, payload: { selectedSps } });
    return selectedSps;
  } catch (error) {
    throw error;
  }
};

export const changeRoleCriteria = selectedRole => async dispatch => {
  try {
    const response = await api.filterSelectedSPsByRole(selectedRole);
    const selectedSps = response.data;
    dispatch({ type: FILTER_SELECTED_SPS, payload: { selectedSps } });
    return selectedSps;
  } catch (error) {
    throw error;
  }
};

export const clearSelection = () => dispatch =>
  dispatch({ type: CLEAR_SELECTED_ROLE });
