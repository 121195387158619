import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Tag, Popover } from 'antd';
// Services
import api from 'services/api';
import i18n from 'services/i18n';
// Actions
import {
  addTargetSystemToRole,
  removeTargetSystemFromRole,
} from 'actions/role';
// Components
import LazySelect from 'components/LazySelect';

const mapStateToProps = ({ role }) => ({
  lastVersion: role.info.lastVersion,
});

const mapDispatchToProps = {
  addTargetSystemToRole,
  removeTargetSystemFromRole,
};
@withRouter
@i18n('role')
@connect(
  mapStateToProps,
  mapDispatchToProps,
)
export default class Catalog extends Component {
  static propTypes = {
    catalog: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      description: PropTypes.string,
      enabled: PropTypes.bool,
    }),
    targets: PropTypes.array,
  };

  handleLoadTargetSystems = data => {
    return api
      .getTargetSystemByCatalog(this.props.catalog.id, data)
      .then(res => {
        // Convert target systems in order to render select
        const targetSystems = res.data.data.map(ts => ({
          ...ts,
          name: `${ts.fullShortcut} | ${ts.description}`,
        }));

        return { data: targetSystems };
      });
  };

  handleRemoveTargetSystem = id => {
    const roleId = this.props.match.params.id;
    this.props.removeTargetSystemFromRole(id, roleId);
  };

  handleAddTargetSystem = id => {
    const roleId = this.props.match.params.id;
    this.props.addTargetSystemToRole(id, roleId);
  };

  render() {
    return (
      <div className="catalog-container" key={this.props.catalog.id}>
        <div className="catalog-name">{this.props.catalog.name}</div>
        <div className="catalog-tags">
          {this.props.targets.map(target => (
            <Tag
              key={target.id}
              closable
              onClose={() => this.handleRemoveTargetSystem(target.id)}
            >
              <Popover
                content={target.description}
                overlayStyle={{ width: '230px' }}
                placement="topLeft"
              >
                {target.fullShortcut}
              </Popover>
            </Tag>
          ))}
        </div>
        <div className="catalog-add">
          <LazySelect
            disabled={!this.props.lastVersion}
            dropdownMatchSelectWidth
            placeholder={this.props.translate('addTargetSystem')}
            loadData={this.handleLoadTargetSystems}
            onChange={this.handleAddTargetSystem}
          />
        </div>
      </div>
    );
  }
}
