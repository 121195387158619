import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
import configure, { history } from 'store';
import { intersection } from 'lodash';

/** Containers **/
import Localization from 'containers/Localization';
import AuthContainer from 'containers/AuthContainer';
import MainContainer from 'containers/MainContainer';

/** Components **/
import NotFound from 'pages/notFound';

import 'style/index.less';

import { ADMIN_ROLES } from 'constants/common';

const store = configure();

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/not-found" component={NotFound} />
      <Route
        path="/auth"
        render={props =>
          localStorage.getItem('token') &&
          !!intersection(JSON.parse(localStorage.getItem('roles')), ADMIN_ROLES)
            .length ? (
            <Redirect to="/" />
          ) : (
            <AuthContainer {...props} />
          )
        }
      />
      <Redirect exact from="/" to="/sp/patients" />
      <Route
        path="/"
        render={() =>
          !localStorage.getItem('token') ||
          (localStorage.getItem('token') &&
            !intersection(
              JSON.parse(localStorage.getItem('roles')),
              ADMIN_ROLES,
            ).length) ? (
            <Redirect to="/auth/login" />
          ) : (
            <MainContainer />
          )
        }
      />
    </Switch>
  );
};

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Localization>
        <Routes />
      </Localization>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root'),
);
