/* eslint-disable max-len */
import React from 'react';

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="350"
    height="200"
    viewBox="0 0 350 200"
  >
    <g>
      <path
        fill="#727272"
        d="M137.592,118.892C129.622,118.925 137.623,118.888 129.586,118.895L129.586,140.675C129.586,157.65 113.606,171.427 93.917,171.427C74.227,171.427 58.244,157.647 58.244,140.675L58.157,115.725C67.494,114.652 76.619,109.943 84.736,101.902C85.928,100.722 86.29,99.199 86.085,97.746C96.694,85.653 103.275,69.184 103.275,56.525C103.275,36.981 93.117,34.952 76.32,31.638L76.296,31.602C75.263,29.809 73.177,28.512 70.65,28.512C67.101,28.512 64.223,30.99 64.223,34.014C64.223,37.074 67.098,39.553 70.65,39.553C72.055,39.553 73.372,39.049 74.418,38.333L74.568,38.381C90.875,41.609 95.276,42.484 95.276,56.525C95.276,67.659 89.165,82.569 79.824,93.334C78.277,93.412 76.748,93.999 75.604,95.096C70.831,99.858 63.204,105.559 54.194,105.559C45.19,105.559 37.57,99.912 32.798,95.162C31.65,94.065 30.124,93.478 28.588,93.403C19.223,82.599 13.098,67.671 13.098,56.522C13.098,42.472 17.426,41.6 33.834,38.372L33.889,38.327C35.015,39.043 36.243,39.55 37.737,39.55C41.287,39.55 44.161,37.068 44.161,34.011C44.158,30.99 41.287,28.512 37.734,28.512C35.2,28.512 33.117,29.809 32.012,31.596L31.998,31.599C15.184,34.943 5.009,36.936 5.009,56.525C5.009,69.196 11.613,85.725 22.317,97.815C22.112,99.268 22.477,100.794 23.669,101.971C31.779,109.958 40.817,114.649 50.144,115.725L50.154,140.672C50.158,161.456 69.812,178.362 93.917,178.362C118.021,178.362 137.592,161.456 137.592,140.672L137.592,118.892Z"
      />
      <path
        fill="#FF4B00"
        d="M102.361,88.384C102.361,82.763 103.326,78.046 105.256,74.231C106.696,71.421 108.662,68.899 111.153,66.665C113.644,64.432 116.373,62.776 119.34,61.697C123.286,60.241 127.837,59.514 132.992,59.514C142.324,59.514 149.791,62.035 155.393,67.079C160.995,72.123 163.796,79.137 163.796,88.121C163.796,97.029 161.017,103.999 155.458,109.031C149.899,114.062 142.468,116.578 133.165,116.578C123.747,116.578 116.258,114.075 110.699,109.068C105.14,104.062 102.361,97.167 102.361,88.384ZM115.538,88.008C115.538,94.256 117.194,98.993 120.506,102.218C123.819,105.442 128.024,107.055 133.122,107.055C138.22,107.055 142.403,105.455 145.673,102.255C148.942,99.056 150.576,94.256 150.576,87.857C150.576,81.534 148.985,76.816 145.802,73.704C142.619,70.593 138.393,69.037 133.122,69.037C127.851,69.037 123.603,70.611 120.377,73.761C117.151,76.91 115.538,81.659 115.538,88.008Z"
      />
      <path
        fill="#FF4B00"
        d="M170.536,97.682L182.979,96.628C183.728,100.267 185.247,102.939 187.537,104.646C189.826,106.352 192.916,107.205 196.804,107.205C200.923,107.205 204.026,106.446 206.114,104.928C208.202,103.41 209.246,101.634 209.246,99.602C209.246,98.297 208.807,97.186 207.929,96.27C207.05,95.354 205.517,94.558 203.328,93.88C201.83,93.428 198.417,92.625 193.088,91.471C186.233,89.99 181.423,88.171 178.658,86.013C174.77,82.977 172.826,79.275 172.826,74.909C172.826,72.098 173.74,69.47 175.569,67.023C177.398,64.576 180.034,62.713 183.476,61.433C186.917,60.153 191.072,59.514 195.94,59.514C203.889,59.514 209.873,61.032 213.891,64.068C217.909,67.105 220.019,71.157 220.22,76.226L207.432,76.716C206.885,73.88 205.711,71.841 203.911,70.599C202.111,69.357 199.41,68.736 195.81,68.736C192.095,68.736 189.186,69.401 187.083,70.731C185.729,71.584 185.053,72.726 185.053,74.156C185.053,75.461 185.686,76.578 186.953,77.506C188.566,78.686 192.484,79.915 198.705,81.195C204.926,82.475 209.527,83.798 212.508,85.166C215.489,86.534 217.822,88.403 219.507,90.775C221.192,93.146 222.035,96.076 222.035,99.564C222.035,102.726 221.027,105.687 219.01,108.447C216.994,111.208 214.143,113.259 210.456,114.602C206.769,115.944 202.175,116.615 196.674,116.615C188.667,116.615 182.518,115.003 178.226,111.779C173.935,108.554 171.371,103.855 170.536,97.682Z"
      />
      <path
        fill="#FF4B00"
        d="M273.317,95.348L285.717,98.773C283.816,104.796 280.655,109.269 276.233,112.193C271.812,115.116 266.203,116.578 259.406,116.578C250.995,116.578 244.083,114.075 238.668,109.068C233.253,104.062 230.546,97.218 230.546,88.535C230.546,79.351 233.268,72.217 238.711,67.136C244.155,62.054 251.312,59.514 260.183,59.514C267.931,59.514 274.225,61.509 279.063,65.498C281.944,67.857 284.104,71.245 285.544,75.662L272.885,78.297C272.136,75.436 270.574,73.177 268.198,71.521C265.821,69.865 262.934,69.037 259.535,69.037C254.841,69.037 251.031,70.505 248.108,73.441C245.185,76.377 243.723,81.132 243.723,87.707C243.723,94.683 245.163,99.652 248.043,102.613C250.923,105.574 254.668,107.055 259.276,107.055C262.675,107.055 265.598,106.114 268.046,104.231C270.495,102.349 272.252,99.388 273.317,95.348Z"
      />
      <path
        fill="#FF4B00"
        d="M296.69,115.637L296.69,60.455L343.653,60.455L343.653,69.79L309.479,69.79L309.479,82.023L341.276,82.023L341.276,91.32L309.479,91.32L309.479,106.339L344.862,106.339L344.862,115.637L296.69,115.637Z"
      />
      <path d="M142.872,147.042L142.872,122L151.498,122L156.678,139.082L161.799,122L170.445,122L170.445,147.042L165.09,147.042L165.09,127.33L159.423,147.042L153.874,147.042L148.227,127.33L148.227,147.042L142.872,147.042Z" />
      <path d="M201.912,147.042L195.642,147.042L193.149,141.353L181.739,141.353L179.382,147.042L173.268,147.042L184.387,122L190.482,122L201.912,147.042ZM191.3,137.134L187.366,127.842L183.511,137.134L191.3,137.134Z" />
      <path d="M205.028,147.042L205.028,122L210.636,122L222.319,138.723L222.319,122L227.674,122L227.674,147.042L221.891,147.042L210.382,130.712L210.382,147.042L205.028,147.042Z" />
      <path d="M259.511,147.042L253.241,147.042L250.749,141.353L239.338,141.353L236.982,147.042L230.867,147.042L241.986,122L248.081,122L259.511,147.042ZM248.899,137.134L244.965,127.842L241.11,137.134L248.899,137.134Z" />
      <path d="M275.848,137.835L275.848,133.616L288.272,133.616L288.272,143.591C287.064,144.616 285.315,145.518 283.024,146.299C280.733,147.079 278.412,147.469 276.063,147.469C273.077,147.469 270.474,146.919 268.254,145.82C266.034,144.721 264.366,143.15 263.25,141.106C262.133,139.062 261.575,136.838 261.575,134.436C261.575,131.828 262.198,129.51 263.445,127.483C264.691,125.456 266.515,123.902 268.916,122.82C270.747,121.989 273.025,121.573 275.751,121.573C279.295,121.573 282.063,122.225 284.056,123.529C286.049,124.833 287.331,126.635 287.902,128.935L282.177,129.875C281.774,128.645 281.018,127.674 279.908,126.962C278.798,126.251 277.413,125.895 275.751,125.895C273.233,125.895 271.23,126.595 269.744,127.996C268.257,129.397 267.514,131.475 267.514,134.231C267.514,137.203 268.267,139.432 269.773,140.918C271.279,142.404 273.252,143.147 275.693,143.147C276.9,143.147 278.11,142.939 279.324,142.524C280.538,142.108 281.58,141.604 282.449,141.012L282.449,137.835L275.848,137.835Z" />
      <path d="M293.588,147.042L293.588,122L314.754,122L314.754,126.236L299.351,126.236L299.351,131.788L313.683,131.788L313.683,136.007L299.351,136.007L299.351,142.822L315.299,142.822L315.299,147.042L293.588,147.042Z" />
      <path d="M320.206,147.042L320.206,122L332.338,122C335.388,122 337.605,122.225 338.987,122.675C340.37,123.125 341.477,123.925 342.307,125.075C343.138,126.225 343.554,127.54 343.554,129.021C343.554,130.9 342.924,132.451 341.665,133.675C340.406,134.9 338.523,135.671 336.018,135.99C337.264,136.628 338.293,137.328 339.104,138.091C339.916,138.854 341.009,140.209 342.385,142.156L345.871,147.042L338.978,147.042L334.811,141.593C333.331,139.645 332.318,138.418 331.773,137.912C331.228,137.405 330.65,137.058 330.04,136.87C329.43,136.682 328.463,136.588 327.138,136.588L325.97,136.588L325.97,147.042L320.206,147.042ZM325.97,132.591L330.235,132.591C333,132.591 334.726,132.488 335.414,132.283C336.102,132.078 336.641,131.725 337.03,131.224C337.42,130.723 337.615,130.097 337.615,129.345C337.615,128.503 337.358,127.822 336.845,127.304C336.333,126.786 335.609,126.459 334.674,126.322C334.207,126.265 332.805,126.236 330.468,126.236L325.97,126.236L325.97,132.591Z" />
    </g>
  </svg>
);
