export const CHANGE_CHECKLIST_START = 'CHANGE_CHECKLIST_START';
export const FETCH_CHECKLIST_SUCCESS = 'FETCH_CHECKLIST_SUCCESS';
export const FETCH_CHECKLIST_ERROR = 'FETCH_CHECKLIST_ERROR';
export const CREATE_CHECKLIST_SECTION_SUCCESS =
  'CREATE_CHECKLIST_SECTION_SUCCESS';
export const CREATE_CHECKLIST_SECTION_ERROR = 'CREATE_CHECKLIST_SECTION_ERROR';
export const FETCH_CHECKLIST_SECTIONS_SUCCESS =
  'FETCH_CHECKLIST_SECTIONS_SUCCESS';
export const FETCH_CHECKLIST_SECTIONS_ERROR = 'FETCH_CHECKLIST_SECTIONS_ERROR';
export const IMPORT_CHECKLIST_SECTIONS_SUCCESS =
  'IMPORT_CHECKLIST_SECTIONS_SUCCESS';
export const IMPORT_CHECKLIST_SECTIONS_ERROR =
  'IMPORT_CHECKLIST_SECTIONS_ERROR';
export const CHANGE_CHECKLIST_QUEUE_SUCCESS = 'CHANGE_CHECKLIST_QUEUE_SUCCESS';
export const CHANGE_CHECKLIST_QUEUE_ERROR = 'CHANGE_CHECKLIST_QUEUE_ERROR';
export const FETCH_CHECKLIST_QUESTIONS_SUCCESS =
  'FETCH_CHECKLIST_QUESTIONS_SUCCESS';
export const FETCH_CHECKLIST_QUESTIONS_ERROR =
  'FETCH_CHECKLIST_QUESTIONS_ERROR';
export const CHANGE_CHECKLIST_QUESTION_QUEUE_SUCCESS =
  'CHANGE_CHECKLIST_QUESTION_QUEUE_SUCCESS';
export const CHANGE_CHECKLIST_QUESTION_QUEUE_ERROR =
  'CHANGE_CHECKLIST_QUESTION_QUEUE_ERROR';
export const DELETE_CHECKLIST_SECTION_SUCCESS =
  'DELETE_CHECKLIST_SECTION_SUCCESS';
export const DELETE_CHECKLIST_SECTION_ERROR = 'DELETE_CHECKLIST_SECTION_ERROR';
export const UPDATE_CHECKLIST_SECTION_SUCCESS =
  'UPDATE_CHECKLIST_SECTION_SUCCESS';
export const UPDATE_CHECKLIST_SECTION_ERROR = 'UPDATE_CHECKLIST_SECTION_ERROR';
export const CREATE_QUESTION_SUCCESS = 'CREATE_QUESTION_SUCCESS';
export const CREATE_QUESTION_ERROR = 'CREATE_QUESTION_ERROR';
export const IMPORT_QUESTION_SUCCESS = 'IMPORT_QUESTION_SUCCESS';
export const IMPORT_QUESTION_ERROR = 'IMPORT_QUESTION_ERROR';
export const UPDATE_QUESTION_IN_SECTION_SUCCESS =
  'UPDATE_QUESTION_IN_SECTION_SUCCESS';
export const UPDATE_QUESTION_IN_SECTION_ERROR =
  'UPDATE_QUESTION_IN_SECTION_ERROR';
export const DELETE_QUESTION_IN_SECTION_SUCCESS =
  'DELETE_QUESTION_IN_SECTION_SUCCESS';
export const DELETE_QUESTION_IN_SECTION_ERROR =
  'DELETE_QUESTION_IN_SECTION_ERROR';
