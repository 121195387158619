import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import 'components/Carousel/style.less';

/**
 * Component for rendering station header
 * @reactProps {string} title - title of section
 * @reactProps {string} type - type of station ( regular, double, double sp, preparation )
 * @reactProps {element} isDisabled - disable button remove
 */
export default class SectionHeader extends PureComponent {
  static propsTypes = {
    title: PropTypes.string.isRequired,
    type: PropTypes.string,
    contentDeleteBtn: PropTypes.any,
  };

  render() {
    const { props } = this;
    return (
      <div className="circuit_section_header">
        <h3>{props.title}</h3>
        <p>{props.type}</p>
        {this.props.contentDeleteBtn}
      </div>
    );
  }
}
