import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';

import { semesterCRUD } from 'actions/cruds';
import { SEMESTER } from 'constants/options';

import handleSubmit from 'services/form';
import i18n from 'services/i18n';
import EditableLabelDropDown from 'components/Form/formEditableLabelDropDown';
import FormInput from 'components/Form/formInput';
import Footer from 'components/Form/modalFooter';
import { msgLocalError } from 'services/errorHandler';
import notification from 'services/decorators/notif';
import 'components/Form/formsStyles.less';

@i18n('form')
@notification()
@Form.create({})
export default class AddNewSemester extends Component {
  static childContextTypes = {
    form: PropTypes.object,
  };

  getChildContext() {
    return { form: this.props.form };
  }

  addNewRoomSubmit = async e => {
    const values = await handleSubmit(e, this.props.form);
    try {
      await semesterCRUD.post(values);
      this.props.notifySuccess('success');
      await this.props.updateTable();
      this.handleReset();
    } catch (error) {
      msgLocalError({ error, translate: this.props.translate });
    }
  };

  handleReset = () => {
    this.props.form.resetFields();
    this.props.toggleWindow();
  };

  render() {
    const nowYear = new Date().getFullYear();
    return (
      <Form onSubmit={this.addNewRoomSubmit}>
        <div className="form_content">
          <FormInput
            required
            min={0}
            initialValue={nowYear}
            type="number"
            name="calendarYear"
            label={this.props.translate('calendarYear')}
          />
          <EditableLabelDropDown
            name="semesterType"
            options={SEMESTER}
            label={this.props.translate('semesterType')}
            onlyDropDown
            required
            fullWidth
            withoutLabel
            withoutTranslate
          />
        </div>
        <Footer
          submitText="form.save"
          cancelText="form.cancel"
          onCancel={this.handleReset}
        />
      </Form>
    );
  }
}
