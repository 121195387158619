import numeral from 'numeral';
import moment from 'moment';
import { get, set } from 'lodash';

import {
  DATE,
  GENERAL_NUMBER,
  DATE_SEND,
  TIME_SEND,
  DATE_MARK,
  DATE_WITH_TIME,
} from 'constants/formats';

export const formatDateStr = value =>
  moment.isMoment(value) ? value.format(DATE_SEND) : value;
export const formatTimeStr = value =>
  moment.isMoment(value) ? value.format(TIME_SEND) : value;
export const checkValue = (values, name, format) =>
  set(values, name, format(get(values, name)));

export const momentFormatDateMarks = value =>
  value ? moment(value).format(DATE_MARK) : '';

export const momentFormatDateTime = value =>
  value ? moment(value).format(DATE_WITH_TIME) : '';

export const parseAge = value => (value ? moment().diff(value, 'years') : '');

export const checkTimeIsBefore = (timeFirst, timeSecond) =>
  moment(timeFirst, TIME_SEND).isBefore(moment(timeSecond, TIME_SEND));

export const checkTimeIsSame = (timeFirst, timeSecond) =>
  moment(timeFirst, TIME_SEND).isSame(moment(timeSecond, TIME_SEND));

export const multipleDate = ({ osceFirstDay, osceLastDay }) => {
  if (osceFirstDay) {
    if (osceLastDay) {
      return `${moment(osceFirstDay).format(DATE_MARK)} / ${moment(
        osceLastDay,
      ).format(DATE_MARK)}`;
    }
    return osceFirstDay;
  }
  return '-';
};

export const calculateDiff = ({ startTime, endTime }) =>
  moment(endTime, TIME_SEND).diff(moment(startTime, TIME_SEND), 'minutes');
