import { combineReducers } from 'redux';
import {
  FETCH_QUESTIONS_START,
  FETCH_QUESTIONS_SUCCESS,
  FETCH_QUESTIONS_ERROR,
  POST_ANSWER_START,
  POST_ANSWER_SUCCESS,
  POST_ANSWER_ERROR,
  POST_COMMENT_START,
  POST_COMMENT_SUCCESS,
  POST_COMMENT_ERROR,
  REMOVE_ANSWER_SUCCESS,
  POST_COMMENT_TO_OPTION_SUCCESS,
  POST_COMMENT_TO_OPTION_ERROR,
} from 'constants/question';

const loading = (state = false, action) => {
  switch (action.type) {
    case FETCH_QUESTIONS_START:
      return true;

    case FETCH_QUESTIONS_SUCCESS:
    case FETCH_QUESTIONS_ERROR:
      return false;

    default:
      return state;
  }
};

const sections = (state = [], action) => {
  switch (action.type) {
    case FETCH_QUESTIONS_SUCCESS:
      return action.payload.sections.map(section => ({
        id: section.id,
        name: section.name,
      }));

    default:
      return state;
  }
};

const questions = (state = {}, action) => {
  switch (action.type) {
    case FETCH_QUESTIONS_SUCCESS: {
      return action.payload.sections.reduce(
        (newState, section) => ({
          ...newState,
          [section.id]: section.questions,
        }),
        {},
      );
    }

    case POST_ANSWER_START:
    case POST_COMMENT_START: {
      const { sectionId, questionId } = action.payload;
      // Get question array by seciton id
      const questions = state[sectionId];
      // Find updated question by id and update loading
      const updatedQuestions = questions.map(question =>
        question.id === questionId ? { ...question, loading: true } : question,
      );

      return { ...state, [sectionId]: updatedQuestions };
    }

    case POST_ANSWER_SUCCESS: {
      const { sectionId, questionId, answers } = action.payload;
      // Get question array by seciton id
      const questions = state[sectionId];
      // Find updated question by id and update answers array
      const updatedQuestions = questions.map(question =>
        question.id === questionId
          ? {
              ...question,
              loading: false,
              errorMsg: '',
              answers,
            }
          : question,
      );
      // Update state
      return { ...state, [sectionId]: updatedQuestions };
    }

    case POST_COMMENT_SUCCESS: {
      const { sectionId, questionId, comment } = action.payload;
      // Get question array by seciton id
      const questions = state[sectionId];
      // Find updated question by id and update answers array
      const updatedQuestions = questions.map(question =>
        question.id === questionId
          ? {
              ...question,
              comment,
              loading: false,
              errorMsg: '',
            }
          : question,
      );
      // Update state
      return { ...state, [sectionId]: updatedQuestions };
    }

    case POST_COMMENT_TO_OPTION_SUCCESS: {
      const { sectionId, questionId, data } = action.payload;
      // Get question array by seciton id
      const questions = state[sectionId];
      // Find updated question by id and update answers array
      const updatedQuestions = questions.map(question =>
        question.id === questionId
          ? {
              ...question,
              answerComments: question.answerComments
                .filter(
                  comment => comment.answerOptionId !== data.answerOptionId,
                )
                .concat(data),
              loading: false,
              errorMsg: '',
            }
          : question,
      );
      // Update state
      return { ...state, [sectionId]: updatedQuestions };
    }

    case POST_ANSWER_ERROR:
    case POST_COMMENT_ERROR:
    case POST_COMMENT_TO_OPTION_ERROR: {
      const { sectionId, questionId, message } = action.payload;
      // Get question array by seciton id
      const questions = state[sectionId];
      // Find updated question by id and update error msg
      const updatedQuestions = questions.map(question =>
        question.id === questionId
          ? { ...question, loading: false, errorMsg: message }
          : question,
      );
      // Update state
      return { ...state, [sectionId]: updatedQuestions };
    }

    case REMOVE_ANSWER_SUCCESS: {
      const { sectionId, questionId } = action.payload;
      const questions = state[sectionId];
      const updatedQuestions = questions.map(question =>
        question.id === questionId ? { ...question, answers: [] } : question,
      );

      // Update state
      return { ...state, [sectionId]: updatedQuestions };
    }

    default:
      return state;
  }
};

const error = (state = null, action) => {
  switch (action.type) {
    case FETCH_QUESTIONS_ERROR:
      return action.payload.error;

    default:
      return state;
  }
};

const question = combineReducers({
  loading,
  sections,
  questions,
  error,
});

export default question;
