import React, { Component } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { Icon } from 'antd';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import i18n from 'services/i18n';

import Option from './Option';

@i18n('role')
class Options extends Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
  };

  handleAddNewOption = () => {
    const { getFieldValue, setFieldsValue } = this.props.form;
    const oldQuestionOptionRequestList = getFieldValue(
      'questionOptionRequestList',
    );
    const numberInQueue = oldQuestionOptionRequestList.length;
    const id = Date.now(); //generate unique id

    const newQuestionOptionRequestList = oldQuestionOptionRequestList.concat([
      {
        id,
        textDescription: '',
        pointCount: 0,
        comment: '',
        values: 0,
        numberInQueue,
      },
    ]);

    setFieldsValue({ questionOptionRequestList: newQuestionOptionRequestList });
  };

  handleChangeOption = (name, value, index) => {
    const { getFieldValue, setFieldsValue } = this.props.form;
    // Old array list
    const oldQuestionOptionRequestList = getFieldValue(
      'questionOptionRequestList',
    );
    // Create new array list
    const newQuestionOptionRequestList = [...oldQuestionOptionRequestList];
    // Set value
    newQuestionOptionRequestList[index][name] = value;
    // Actually update state
    setFieldsValue({ questionOptionRequestList: newQuestionOptionRequestList });
  };

  handleRemoveOption = index => {
    const { getFieldValue, setFieldsValue } = this.props.form;
    // Old array list
    const oldQuestionOptionRequestList = getFieldValue(
      'questionOptionRequestList',
    );
    // Create new array list
    const newQuestionOptionRequestList = oldQuestionOptionRequestList
      .filter((_, i) => i !== index)
      .map((criteria, index) => ({ ...criteria, numberInQueue: index }));
    // Actually update state
    setFieldsValue({ questionOptionRequestList: newQuestionOptionRequestList });
  };

  onDragEnd = result => {
    const { destination, source } = result;
    // If user drop item outside of droppable list
    if (!destination) {
      return;
    }

    // If item position didn't change
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    // Move items
    const { getFieldValue, setFieldsValue } = this.props.form;
    // Old array list
    const oldQuestionOptionRequestList = getFieldValue(
      'questionOptionRequestList',
    );
    // Create new array list
    const newQuestionOptionRequestList = [...oldQuestionOptionRequestList];
    // Get fields objects that was moved
    const sourceField = newQuestionOptionRequestList[source.index];
    const destField = newQuestionOptionRequestList[destination.index];
    sourceField.numberInQueue = destination.index;
    destField.numberInQueue = source.index;
    // Remove field object from old position
    newQuestionOptionRequestList.splice(source.index, 1);
    // Add field object to new position
    newQuestionOptionRequestList.splice(destination.index, 0, sourceField);

    // Actually update state
    setFieldsValue({ questionOptionRequestList: newQuestionOptionRequestList });
  };

  render() {
    const { getFieldValue } = this.props.form;
    const questionOptionRequestList = getFieldValue(
      'questionOptionRequestList',
    );

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {(dropProvid, dropSnap) => (
            <div
              ref={dropProvid.innerRef}
              className={cn([
                'modal-draggable-container',
                {
                  'modal-draggable-isDragging': dropSnap.isDraggingOver,
                },
              ])}
            >
              {questionOptionRequestList.map((option, index) => (
                <Option
                  key={option.id}
                  index={index}
                  option={option}
                  form={this.props.form}
                  onChange={this.handleChangeOption}
                  onRemove={this.handleRemoveOption}
                />
              ))}
              <span className="action-btn" onClick={this.handleAddNewOption}>
                <Icon type="plus" /> {this.props.translate('addOption')}
              </span>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

export default Options;
