import React, { Component } from 'react';
import { get } from 'lodash';
import { Form, Col, Spin } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { updateData } from 'actions/updateData';
import TablePanel from 'components/TablePanel/index';
import EditableDoubleLabel from 'components/Form/formEditableDoubleLabel';
import profileFormDecorator from 'services/decorators/profileFormDecorator';
import { studentCRUD } from 'actions/cruds';

import GeneralInfo from './cards/generalInformation';
import UnNamedInfo from './cards/unNamedInfo';

const mapDispatchToProps = dispatch =>
  bindActionCreators({ dispatch, updateData }, dispatch);

@connect(
  null,
  mapDispatchToProps,
)
@profileFormDecorator()
export default class EditStudent extends Component {
  state = {
    loading: false,
  };

  render() {
    const { data } = this.props;
    return (
      <Spin spinning={this.state.loading}>
        <Form
          onSubmit={this.props.wrapSubmit(
            values => studentCRUD.put(this.props.id, values),
            newData => this.props.updateData(newData, 'studentProfile'),
          )}
        >
          <TablePanel className="clinic_panel_line">
            <EditableDoubleLabel
              firstLabelName="firstName"
              secondLabelName="lastName"
              firstInit={get(data, 'firstName')}
              secondInit={get(data, 'lastName')}
              rules={[
                {
                  required: true,
                  message: this.props.translate('required', 'error'),
                },
              ]}
            />
          </TablePanel>
          <div className="examiner-card-row">
            <Col span={8}>
              <GeneralInfo data={data} />
            </Col>
            <span className="card-border-vertical" />
            <Col span={8} className="card-col-padding">
              <UnNamedInfo data={data} />
            </Col>
          </div>
        </Form>
      </Spin>
    );
  }
}
