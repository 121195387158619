import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Form, Input, Select, message } from 'antd';
// Services
import i18n from 'services/i18n';
import api from 'services/api';
// Components
import LazySelect from 'components/LazySelect';
// Options
import { ANSWERED_CRITERIA } from 'constants/options';

@i18n('form')
class QuestionnaireForm extends Component {
  static propTypes = {
    record: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
  };

  state = {
    questions: [],
    selectedQuestion: this.props.record.criterion.question,
    answer: get(
      this.props.record,
      'criterion.answerFromAnswersOptions.id',
      null,
    ),
    openQuestionSearch: get(
      this.props.record,
      'criterion.openQuestionSearch',
      null,
    ),
  };

  generateInput = () => {
    const { selectedQuestion } = this.state;
    const { getFieldDecorator } = this.props.form;
    const { translate } = this.props;

    if (selectedQuestion.questionType !== 'OPEN') {
      return (
        <Form.Item style={{ width: 150, marginBottom: 0 }}>
          {getFieldDecorator('answer', {
            initialValue: this.state.answer,
            rules: [
              {
                required: true,
                message: this.props.translate('checkboxGroup'),
              },
            ],
          })(
            <Select
              style={{ width: '100%' }}
              placeholder={translate('chooseAnswer', 'sections')}
              onClick={e => e.stopPropagation()}
            >
              {selectedQuestion.answersOptions.map(answer => (
                <Select.Option key={answer.id} value={answer.id}>
                  {answer.value}
                </Select.Option>
              ))}
            </Select>,
          )}
        </Form.Item>
      );
    }

    return (
      <Form.Item style={{ width: 150, marginBottom: 0 }}>
        {getFieldDecorator('openQuestionSearch', {
          initialValue: this.state.openQuestionSearch,
          rules: [
            {
              required: true,
              message: this.props.translate('insert', 'error'),
            },
          ],
        })(<Input type="text" />)}
      </Form.Item>
    );
  };

  handleLoadQuestions = data => {
    return api
      .getAllQuestions(data)
      .then(res => {
        // Save questions in temp var
        this.setState({ questions: res.data.data });
        // Create a proper data structure
        const questionsArr = res.data.data.map(question => ({
          id: question.id,
          name: question.question,
        }));

        return { data: questionsArr };
      })
      .catch(err => message.error(this.props.translate('all', 'error')));
  };

  handleQuestionChange = id => {
    // Find question by id
    const selectedQuestion = this.state.questions.find(q => q.id === id);
    // Set question instance
    this.setState({ selectedQuestion, answer: null, openQuestionSearch: null });
  };

  render() {
    const { record, translate } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <Form>
        <Input.Group compact>
          <Form.Item style={{ width: 150, marginRight: 10, marginBottom: 0 }}>
            {getFieldDecorator('questionId', {
              initialValue: record.criterion.question.id,
            })(
              <LazySelect
                loadData={this.handleLoadQuestions}
                onChange={this.handleQuestionChange}
              />,
            )}
          </Form.Item>
          <Form.Item style={{ width: 150, marginRight: 10, marginBottom: 0 }}>
            {getFieldDecorator('isAnsweredWith', {
              initialValue: record.criterion.isAnsweredWith
                ? 'answer'
                : 'noanswer',
            })(
              <Select
                style={{ width: '100%' }}
                placeholder={translate('chooseType')}
                onClick={e => e.stopPropagation()}
              >
                {ANSWERED_CRITERIA.map(item => (
                  <Select.Option key={item.value} value={item.value}>
                    {translate(item.value, 'questionnaireAnswers')}
                  </Select.Option>
                ))}
              </Select>,
            )}
          </Form.Item>
          {this.generateInput()}
        </Input.Group>
      </Form>
    );
  }
}

export default QuestionnaireForm;
