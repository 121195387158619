import React, { Component } from 'react';
import { reduce, get, omit } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  updateData,
  updateSpecData,
  updateElementInArray,
  updateAllByPattern,
  resetData,
} from 'actions/updateData';
import i18n from 'services/i18n';
import dispatchSend from 'services/dispatchSend';

export default function addLoadWithRedux({
  i18Name = 'form',
  runtimeReduxNames = [],
  getLanguageFromRedux = false,
  stateToProps,
  addAddDispatchToProps = [],
  mapMethods,
} = {}) {
  return function(Child) {
    const mapStateToProps = ({ runtime, localization }, props) => {
      if (stateToProps) return stateToProps({ runtime }, props);
      const resultObj = {};
      runtimeReduxNames.forEach(runtimeName => {
        if (typeof runtimeName === 'function') {
          const name = runtimeName(props);
          resultObj[`${runtimeName}Loading`] = get(
            runtime,
            `${runtimeName}Loading`,
          );
          resultObj[`${name.as}Loaded`] = get(
            runtime,
            `${name.runtimeName}Loaded`,
          );
          resultObj[`${name.as}Data`] = get(runtime, `${name.runtimeName}Data`);
          resultObj[`${name.as}Error`] = get(
            runtime,
            `${name.runtimeName}Error`,
          );
          resultObj[`${name.as}RealName`] = name.runtimeName;
        } else {
          resultObj[`${runtimeName}Loading`] = get(
            runtime,
            `${runtimeName}Loading`,
          );
          resultObj[`${runtimeName}Loaded`] = get(
            runtime,
            `${runtimeName}Loaded`,
          );
          resultObj[`${runtimeName}Data`] = get(runtime, `${runtimeName}Data`);
          resultObj[`${runtimeName}Error`] = get(
            runtime,
            `${runtimeName}Error`,
          );
        }
      });
      if (getLanguageFromRedux) {
        resultObj.language = localization.lang;
      }
      return resultObj;
    };

    const mapDispatchToProps = (dispatch, props) =>
      bindActionCreators(
        {
          dispatchSend,
          updateData,
          updateSpecData,
          updateElementInArray,
          resetData,
          updateAllByPattern,
          ...addAddDispatchToProps,
        },
        dispatch,
      );

    @i18n(i18Name)
    @connect(
      mapStateToProps,
      mapDispatchToProps,
    )
    class DispatchLoading extends Component {
      componentWillMount() {
        this.methods = {
          ...this.mappedMethods,
        };
      }

      dispatchLoading = (promise, name = 'test', opts = {}) =>
        this.props.dispatchSend(name, promise, {
          translate: this.props.translate,
          ...opts,
        });

      mapfunc = func => (name, args, dispatchParams) =>
        this.dispatchLoading(func(args), name, dispatchParams);

      get mappedMethods() {
        return reduce(
          mapMethods,
          (result, method, key) => ({ ...result, [key]: this.mapfunc(method) }),
          {},
        );
      }

      render() {
        return (
          <Child {...omit(this.props, 'dispatchSend')} {...this.methods} />
        );
      }
    }
    return DispatchLoading;
  };
}
