import React, { Component } from 'react';
import PropTypes from 'prop-types';
import OpenedElement from './openedElement';

import './style.less';

/**
 * This is Our Accordion component which should allow animate open and close states.
 * @reactProps {array} elements - it should be list of elements.
 * @reactProps {string} openFirst - openFirst item or no.
 */
export default class Carousel extends Component {
  static propTypes = {
    elements: PropTypes.array,
    openFirst: PropTypes.bool,
    withColorPicker: PropTypes.bool,
  };

  static defaultProps = {
    elements: [],
    withColorPicker: false,
  };

  get renderListOfOpenedElements() {
    const { openFirst, withColorPicker, elements } = this.props;
    return elements.map((props, i) => (
      <OpenedElement
        withColorPicker={withColorPicker}
        isOpened={openFirst && i === 0}
        key={props.id || i}
        id={props.id || i}
        {...props}
      />
    ));
  }

  render() {
    return (
      <div className="carousel_wrapper">{this.renderListOfOpenedElements}</div>
    );
  }
}
