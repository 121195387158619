import React, { Component } from 'react';
import { get } from 'lodash';
import { Col, Row } from 'antd';

import EditableLabel from 'components/Form/formEditableLabel';
import Card from 'components/Form/Card';

export default class DepotCredentials extends Component {
  renderPassword = password => {
    if (!password) return null;
    const arr = [];
    for (let i = 0; i < password.length; i++) arr.push('*');
    return arr;
  };

  render() {
    const { data } = this.props;
    return (
      <div>
        <Card
          from="cards"
          value="feedbackDepot"
          className="osce-settings-card osce-settings-card_border"
        >
          <Row gutter={48}>
            <Col span={12}>
              <EditableLabel
                name="feedbackDepotHost"
                initialValue={get(data, 'feedbackDepotHost')}
              />
            </Col>
            <Col span={12}>
              <EditableLabel
                name="feedbackDepotPassword"
                initialValue={get(data, 'feedbackDepotPassword')}
                autoComplete="new-password"
                type="password"
                staticRenderFunction={this.renderPassword}
              />
            </Col>
          </Row>
        </Card>
        <Card
          from="cards"
          value="deployDepot"
          className="osce-settings-card osce-settings-card_border"
        >
          <Row gutter={48}>
            <Col span={12}>
              <EditableLabel
                name="deployDepotHost"
                initialValue={get(data, 'deployDepotHost')}
              />
            </Col>
            <Col span={12}>
              <EditableLabel
                name="deployDepotPassword"
                initialValue={get(data, 'deployDepotPassword')}
                autoComplete="new-password"
                type="password"
                staticRenderFunction={this.renderPassword}
              />
            </Col>
          </Row>
        </Card>
        <Card
          from="cards"
          value="resultsDepot"
          className="osce-settings-card osce-settings-card_border"
        >
          <Row gutter={48}>
            <Col span={12}>
              <EditableLabel
                name="resultsDepotHost"
                initialValue={get(data, 'resultsDepotHost')}
              />
            </Col>
            <Col span={12}>
              <EditableLabel
                name="resultsDepotPassword"
                initialValue={get(data, 'resultsDepotPassword')}
                autoComplete="new-password"
                type="password"
                staticRenderFunction={this.renderPassword}
              />
            </Col>
          </Row>
        </Card>
      </div>
    );
  }
}
