import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon, Popconfirm } from 'antd';
import { connect } from 'react-redux';
// Services
import i18n from 'services/i18n';
// Actions
import {
  changeQueueQuestion,
  updateQuestion,
  deleteQuestion,
} from 'actions/section';
// Components
import EditDragTable from 'components/EditDragTable';
import QuestionModal from './QuestionModal';

const mapStateToProps = (state, ownProps) => ({
  questions: state.questionnaire.questionsBySection[ownProps.sectionId] || [],
});

const mapDispatchToProps = {
  changeQueueQuestion,
  updateQuestion,
  deleteQuestion,
};

@i18n('form')
@connect(
  mapStateToProps,
  mapDispatchToProps,
)
export default class TableQuestion extends Component {
  static propTypes = {
    sectionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    questions: PropTypes.array.isRequired,
    onEditQuestion: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.columns = [
      {
        dataIndex: '',
        width: '20px',
        className: 'col-drag-and-drop',
        render: (text, record) => (
          <span className="icon-drag">
            <Icon />
          </span>
        ),
      },
      {
        dataIndex: 'question',
        render: (text, record) => <div>{record.question}</div>,
      },
      {
        dataIndex: '',
        width: '300px',
        render: (text, record) => {
          return (
            <div className="editable-row-operations">
              <span>
                <a
                  disabled={!record.canEdit}
                  className="action-btn"
                  onClick={e => {
                    e.stopPropagation();
                    this.handleOpenEditQuestionModal(e, record.id);
                  }}
                >
                  <Icon type="edit" /> {this.props.translate('edit')}
                </a>
                <Popconfirm
                  title={this.props.translate('areYouSureDelete', 'confirm')}
                  onClick={e => e.stopPropagation()}
                  onCancel={e => e.stopPropagation()}
                  onConfirm={e => {
                    e.stopPropagation();
                    this.handleDeleteQuestion(record);
                  }}
                  disabled={!record.canDelete}
                >
                  <a className="action-btn" disabled={!record.canDelete}>
                    <Icon type="delete" /> {this.props.translate('delete')}
                  </a>
                </Popconfirm>
              </span>
            </div>
          );
        },
      },
    ];

    this.state = {
      isEditQuestionModalOpen: false,
      questionId: null,
    };
  }

  handleOpenEditQuestionModal = (e, id) =>
    this.setState({ isEditQuestionModalOpen: true, questionId: id });

  handleCloseEditQuestionModal = e =>
    this.setState({ isEditQuestionModalOpen: false });

  handleChangeQueueQuestion = data => {
    this.props.changeQueueQuestion(data);
  };

  handleEditQuestion = fields => {
    const { questionId } = this.state;
    // Remove technical data before send
    delete fields.keys;
    // If fields have answer options array
    // And user delete first elements of it we get
    // [null, null, 'A']
    // remove nulls
    if (fields.answersOptions) {
      fields.answersOptions = fields.answersOptions.filter(opt => opt !== null);
    }

    // If question was moved out to another section
    // we need make new request to update section table
    this.props
      .updateQuestion(questionId, fields)
      .then(res => this.props.onEditQuestion());
  };

  handleDeleteQuestion = question => {
    const { sectionId } = this.props;
    this.props.deleteQuestion(sectionId, question.id);
  };

  render() {
    const { questionId } = this.state;
    const dataSource = this.props.questions.map(question => ({
      ...question,
      key: question.id,
    }));

    const question = this.props.questions.find(q => q.id === questionId);

    return (
      <React.Fragment>
        <QuestionModal
          title={this.props.translate('edit', 'sections')}
          visible={this.state.isEditQuestionModalOpen}
          sectionId={this.props.sectionId}
          question={question}
          onCancel={this.handleCloseEditQuestionModal}
          onSubmit={this.handleEditQuestion}
        />
        <div className="table-with-action">
          <EditDragTable
            showHeader={false}
            columns={this.columns}
            dataSource={dataSource}
            onSave={() => {}}
            changeQueue={this.handleChangeQueueQuestion}
          />
        </div>
      </React.Fragment>
    );
  }
}
