import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table, Input, Form } from 'antd';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import i18n from 'services/i18n';
import BodyRow from './TableDrag&Drop/BodyRow';
import './TableDrag&Drop/tableDragDrop.less';

const EditableContext = React.createContext();

@i18n('error')
class EditableCell extends Component {
  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      record,
      index,
      children,
      translate,
      required = true,
      rules = [],
      inputType = 'text',
      ...restProps
    } = this.props;

    const validationRules = [
      {
        required: required,
        message: translate('insert'),
      },
      ...rules,
    ];

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(dataIndex, {
              rules: validationRules,
              initialValue: record[dataIndex],
            })(<Input type={inputType} onClick={e => e.stopPropagation()} />)}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  render() {
    return (
      <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
    );
  }
}

@DragDropContext(HTML5Backend)
class EditDragTable extends Component {
  static propTypes = {
    dataSource: PropTypes.array.isRequired,
    expandRowByClick: PropTypes.bool,
    showHeader: PropTypes.bool,
    expandIconAsCell: PropTypes.bool,
    columns: PropTypes.array.isRequired,
    changeQueue: PropTypes.func,
    onSave: PropTypes.func.isRequired,
    onSort: PropTypes.func,
    expandedRowRender: PropTypes.func,
    canDrag: PropTypes.bool,
    className: PropTypes.string,
    expandIcon: PropTypes.func,
    bordered: PropTypes.bool,
  };

  static defaultProps = {
    bordered: true,
    expandRowByClick: false,
    expandIconAsCell: false,
    canDrag: true,
    showHeader: true,
    className: 'table-drag-and-drop',
    changeQueue: () => {},
    onSort: () => {},
  };

  constructor(props) {
    super(props);
    this.state = { data: props.dataSource, editingKey: '' };
    this.columns = props.columns.map(col => {
      if (typeof col.render === 'function' && col.render instanceof Function) {
        // Inject this and context so that parent component could have
        // access to instance methods like edit, cancel, etc...
        return {
          ...col,
          render: (text, record, index) =>
            col.render(text, record, index, this, EditableContext),
        };
      }
      return col;
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.dataSource !== this.props.dataSource) {
      this.setState({ data: this.props.dataSource });
    }
  }

  isEditing = record => record.key === this.state.editingKey;

  cancel = () => {
    this.setState({ editingKey: '' });
  };

  save(form, key) {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }
      const newData = [...this.state.data];
      const index = newData.findIndex(item => key === item.key);
      if (index > -1) {
        const item = newData[index];
        const newItem = { ...item, ...row };

        newData.splice(index, 1, newItem);
        // Update local cached data
        this.setState({ data: newData, editingKey: '' });
        // Initiate request
        this.props.onSave(newItem);
      } else {
        newData.push(row);
        this.setState({ data: newData, editingKey: '' });
      }
    });
  }

  edit(key) {
    this.setState({ editingKey: key });
  }

  moveRow = (dragIndex, hoverIndex) => {
    if (this.props.canDrag) {
      const { data } = this.state;
      const dragRow = data[dragIndex];
      // Update local cached data
      this.setState(
        update(this.state, {
          data: {
            $splice: [[dragIndex, 1], [hoverIndex, 0, dragRow]],
          },
        }),
      );
      const newData = [...this.state.data];
      newData.forEach((item, index) => {
        item.numberInQueue = index;
      });
      // Call the callback in order to change positioning
      this.props.changeQueue(newData);
    }
  };

  render() {
    const components = {
      body: {
        row: BodyRow,
        cell: EditableCell,
      },
    };

    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
          required: col.required,
          rules: col.rules,
          inputType: col.inputType,
        }),
      };
    });

    return (
      <EditableContext.Provider value={this.props.form}>
        <Table
          bordered={this.props.bordered}
          pagination={false}
          rowClassName="editable-row"
          columns={columns}
          className={this.props.className}
          expandIcon={this.props.expandIcon}
          showHeader={this.props.showHeader}
          expandIconAsCell={this.props.expandIconAsCell}
          expandRowByClick={this.props.expandRowByClick}
          expandedRowRender={this.props.expandedRowRender}
          dataSource={this.state.data}
          components={components}
          onChange={this.props.onSort}
          onRow={(record, index) => ({
            index,
            moveRow: this.moveRow,
          })}
        />
      </EditableContext.Provider>
    );
  }
}

const EditableFormTable = Form.create()(EditDragTable);
export default EditableFormTable;
