import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Button, Select } from 'antd';
// Services
import api from 'services/api';
import i18n from 'services/i18n';

const mapStateToProps = state => ({
  skillId: state.role.info.skillId,
});

const mapDispatchToProps = {};

@i18n('form')
@Form.create()
@connect(
  mapStateToProps,
  mapDispatchToProps,
)
export default class ImportQuestion extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    skillId: PropTypes.number.isRequired,
  };

  state = {
    roles: [],
    sections: [],
    questions: [],
  };

  componentDidMount() {
    this.loadRoles();
  }

  loadRoles = () => {
    api.getSkill(this.props.skillId).then(res => {
      const { roles } = res.data;
      this.setState({ roles });
    });
  };

  loadSections = checkListId => {
    api.getCheckList(checkListId).then(res => {
      const sections = res.data.checkListSectionResponseList;
      this.setState({ sections });
      this.props.form.resetFields(['idSection', 'idQuestion']);
    });
  };

  loadQuestions = sectionId => {
    api.getCheckListQuestionsInSection(sectionId).then(res => {
      const questions = res.data;
      this.setState({ questions });
      this.props.form.resetFields(['idQuestion']);
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSubmit(values);
        this.props.form.resetFields();
        this.props.onCancel();
      }
    });
  };

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;

    return (
      <Form style={{ width: '100%' }} onSubmit={this.handleSubmit}>
        <div className="form_content">
          <Form.Item label={this.props.translate('selectRole')}>
            {getFieldDecorator('idCheckList', {
              rules: [
                {
                  required: true,
                  message: this.props.translate('checkboxGroup'),
                },
              ],
            })(
              <Select
                showSearch
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onChange={this.loadSections}
              >
                {this.state.roles.map(role => (
                  <Select.Option key={role.id} value={role.id}>
                    {role.name}
                  </Select.Option>
                ))}
              </Select>,
            )}
          </Form.Item>
          <Form.Item label={this.props.translate('selectSection')}>
            {getFieldDecorator('idSection', {
              rules: [
                {
                  required: true,
                  message: this.props.translate('checkboxGroup'),
                },
              ],
            })(
              <Select
                disabled={!getFieldValue('idCheckList')}
                showSearch
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onChange={this.loadQuestions}
              >
                {this.state.sections.map(section => (
                  <Select.Option key={section.id} value={section.id}>
                    {section.name}
                  </Select.Option>
                ))}
              </Select>,
            )}
          </Form.Item>
          <Form.Item label={this.props.translate('selectQuestion')}>
            {getFieldDecorator('idQuestion', {
              rules: [
                {
                  required: true,
                  message: this.props.translate('checkboxGroup'),
                },
              ],
            })(
              <Select
                disabled={!getFieldValue('idSection')}
                showSearch
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {this.state.questions.map(question => (
                  <Select.Option key={question.id} value={question.id}>
                    {question.text}
                  </Select.Option>
                ))}
              </Select>,
            )}
          </Form.Item>
        </div>
        <div className="ant-modal-footer footer_max_width">
          <Button onClick={this.props.onCancel}>
            {this.props.translate('cancel')}
          </Button>
          <Button htmlType="submit" className="blueBtn">
            {this.props.translate('save')}
          </Button>
        </div>
      </Form>
    );
  }
}
