import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';

/** UI (Antd) */
import { Select, Spin } from 'antd';

/** Services */
import stateSend from 'services/stateSend';

/** Constants*/
import { DEFAULT_SCROLL_OFFSET } from 'constants/common';

/**
 * Select with search
 * @reactProps {object} action - function for search person
 * @reactProps {func} onChange - change examiner
 * @reactProps {string} additionalUrl - if need additional url for route
 * @reactProps {number} value - current selected examiner
 * @reactProps {string} placeholder - placeholder for select
 */
export default class PersonSearch extends Component {
  static propTypes = {
    action: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    additionalUrl: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    placeholder: PropTypes.string,
    scroll: PropTypes.bool,
    allowClear: PropTypes.bool,
  };

  static defaultProps = {
    scroll: true,
    allowClear: false,
  };

  constructor(props) {
    super(props);
    this.state = { persons: { data: [] } };
    this.debouncedTryLoadMore = debounce(this.tryLoadMore, 100, {
      leading: false,
    });
    this.debouncedSearch = debounce(this.onSearch, 200, {
      leading: false,
    });
  }

  componentWillMount() {
    this.load({ page: 0 });
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (!nextProps.value && this.props.value) {
      this.load({ page: 0 }, true);
    }
  }

  tryLoadMore = offset => {
    const { persons } = this.state;
    if (
      offset < DEFAULT_SCROLL_OFFSET &&
      this.checkLoadMore() &&
      !persons.search
    ) {
      this.load({ page: persons.pagination.page + 1 });
    }
  };

  checkLoadMore = () => {
    const { total } = this.state.persons.pagination;
    return !!total && this.state.persons.data.length < total;
  };

  load = async (params, loadFirst = false) => {
    const { persons } = this.state;
    const { additionalUrl, action } = this.props;
    const response = await stateSend(
      loading => this.setState({ loading }),
      action.getList({ params, additionalUrl }),
    );
    let data = [];
    const responseData = response.data.map(item => ({
      ...item,
      name: `${item.firstName} ${item.lastName}`,
    }));
    if (params.search !== undefined || loadFirst) {
      data = [...responseData];
    } else {
      data = [...persons.data, ...responseData];
    }
    this.setState({
      persons: {
        data,
        pagination: response.pagination,
        search: params.search,
      },
    });
  };

  onScroll = e => {
    const { target } = e;
    this.debouncedTryLoadMore(
      target.scrollHeight - target.offsetHeight - target.scrollTop,
    );
  };

  onSearch = search => {
    this.load({ page: 0, search });
  };

  onChange = value => {
    let target;
    // This is crutch
    if (value) {
      // As this component works with different Enteties
      // there possibly could be the colision where item could have both
      // id and sequenceNumber, or id and examinerId
      target = this.state.persons.data.find(item => {
        // To distinguish between items we should first check
        // if item has sequenceNumber lookup by sequenceNumber
        if (item.sequenceNumber) {
          return item.sequenceNumber === value;
        }
        // if it has examinerId lookup by examinerId
        if (item.examinerId) {
          return item.examinerId === value;
        }
        // for all other items lookup by id
        return item.id === value;
      });
    }
    this.props.onChange(target);
    this.load({ page: 0 }, true);
  };

  render() {
    const { persons } = this.state;
    return (
      <Select
        showSearch
        allowClear={this.props.allowClear}
        style={{ width: '200px' }}
        filterOption={false}
        onSearch={this.debouncedSearch}
        onPopupScroll={this.props.scroll ? this.onScroll : undefined}
        placeholder={this.props.placeholder}
        onChange={this.onChange}
        value={this.props.value}
        notFoundContent={
          this.state.loading ? (
            <Spin size="small" />
          ) : (
            this.props.translate('notFound')
          )
        }
      >
        {persons.data.map(item => (
          <Select.Option
            key={item.sequenceNumber || item.id}
            value={item.sequenceNumber || item.id || item.examinerId}
          >
            {`${
              this.props.name === 'student' ? `S0${item.sequenceNumber}` : ''
            } ${item.firstName} ${item.lastName}`}
          </Select.Option>
        ))}
      </Select>
    );
  }
}
