import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import moment from 'moment';
import { chain, uniqBy } from 'lodash';

/** Utils, Configs*/
import addLoadWithRedux from 'services/decorators/addLoadWithRedux';

/** Components*/
import { Button, Popconfirm, TimePicker, DatePicker, Popover } from 'antd';

/** Actions*/
import { changeOsceDay, deleteOsceDay, getOsceDays } from 'actions/circuits';

/** Services */
import { TIME_SEND, DATE_SEND, DATE_MARK } from 'constants/formats';
import { msgError } from 'services/errorHandler';

/**
 * Component which render details about osce day ( date, time, lunch break )
 * @reactProps {func} translate - function which translated content according to chosen language
 * @reactProps {string} name - osce day name
 * @reactProps {string} date - osce day date
 * @reactProps {string} dayStartTime - osce day start time
 * @reactProps {string} dayEndTime - osce day end time
 * @reactProps {string} lunchBreakEndTime - osce day end lunch break time
 * @reactProps {string} lunchBreakStartTime - osce day start lunch break time
 * @reactProps {number} id - osce day id
 * @reactProps {boolean} withControlButtons - boolean value for make
 * visible / unvisible control buttons ( earlier / later lunch , postpone / prepone rotation )
 */
@withRouter
@addLoadWithRedux({
  i18Name: 'circuit',
  runtimeReduxNames: ['osceDays'],
})
export default class OsceDayHeaderManual extends PureComponent {
  static propsTypes = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    osceType: PropTypes.string.isRequired,
    dayStartTime: PropTypes.string.isRequired,
    dayEndTime: PropTypes.string.isRequired,
    lunchBreakEndTime: PropTypes.string.isRequired,
    lunchBreakStartTime: PropTypes.string.isRequired,
    firstCoffeeBreakStartTime: PropTypes.string.isRequired,
    firstCoffeeBreakEndTime: PropTypes.string.isRequired,
    secondCoffeeBreakStartTime: PropTypes.string.isRequired,
    secondCoffeeBreakEndTime: PropTypes.string.isRequired,
    maxStudents: PropTypes.number.isRequired,
    translate: PropTypes.func.isRequired,
    countDays: PropTypes.number.isRequired,
    osceDaysData: PropTypes.object,
    timeSlotResponses: PropTypes.array,
  };

  static defaultProps = {
    date: undefined,
    lunchBreakEndTime: '',
    lunchBreakStartTime: '',
    withControlButtons: true,
  };

  state = { openTimePicker: false };

  searchBreaks = type =>
    chain(this.props.timeSlotResponses)
      .filter(item => item.timeSlotType === type)
      .groupBy('osceSequenceId')
      .map(item => uniqBy(item, 'sequenceNumber'))
      .flatten()
      .value();

  contentPopoverBreak = (breaks, translate) => {
    return (
      <ul className="list-breaks">
        {breaks.map((item, i) => (
          <li>
            {this.props.translate(translate)} #{i + 1} - {item.startTime} -{' '}
            {item.endTime}
          </li>
        ))}
      </ul>
    );
  };

  contentBreak = (startTime, endTime, translate, number) => (
    <div className="osce-day-header_text-wrapper">
      <p className="osce-day-header_small-name">
        {this.props.translate(translate)} {number && `#${number}`}
      </p>

      <p className="osce-day-header_small-value">
        {startTime}-{endTime}
      </p>
    </div>
  );

  changeStart = async value => {
    try {
      const {
        id,
        updateData,
        osceDaysData: { data, pagination },
      } = this.props;
      await changeOsceDay(id, value);
      const newData = [...data];
      const index = newData.findIndex(item => item.id === id);
      if (index >= 0) {
        newData[index] = Object.assign(newData[index], value);
        updateData({ data: newData, pagination }, 'osceDays');
      }
    } catch (e) {
      msgError(e);
    }
  };

  deleteDay = async e => {
    e.stopPropagation();
    try {
      const {
        id,
        updateData,
        match: { params },
      } = this.props;
      await deleteOsceDay(id);
      const { data } = await getOsceDays(params.id);
      updateData(data, 'osceDays');
    } catch (e) {
      msgError(e);
    }
  };

  handleOpenChange = openTimePicker => {
    this.setState({ openTimePicker });
  };

  handleClose = () => this.setState({ openTimePicker: false });

  render() {
    const { props } = this;
    const {
      countDays,
      name,
      date,
      disabled,
      translate,
      dayStartTime,
      dayEndTime,
      lunchBreakEndTime,
      lunchBreakStartTime,
      firstCoffeeBreakStartTime,
      firstCoffeeBreakEndTime,
      secondCoffeeBreakStartTime,
      secondCoffeeBreakEndTime,
      maxStudents,
    } = props;
    const coffeeBreaks = this.searchBreaks('COFFEE_BREAK');
    const lunchBreaks = this.searchBreaks('LUNCH_BREAK');
    return (
      <div className="osce-day">
        <div className="osce-day-header osce-day-header_manual">
          <div className="osce-day-header_left">
            <div className="osce-day-header_block osce-day-header_main-name-block">
              <p className="osce-day-header_name">
                {translate('day')} {name}
              </p>
            </div>
            <div className="osce-day-header_block">
              <div className="osce-day_expander" />
              <div className="osce-day-header_text-wrapper">
                <p className="osce-day-header_small-name">
                  {translate('date')}
                </p>
                <div
                  className="osce-day-header_small-value"
                  onClick={e => e.stopPropagation()}
                >
                  <DatePicker
                    disabled={disabled}
                    format={DATE_MARK}
                    onChange={value =>
                      this.changeStart({
                        date: value ? value.format(DATE_SEND) : value,
                      })
                    }
                    value={date ? moment(date, DATE_SEND) : undefined}
                  />
                </div>
              </div>
              <div className="osce-day_expander" />
            </div>
            <div className="osce-day-header_block osce-day-header_block-short-time">
              <div className="osce-day_expander" />
              <div className="osce-day-header_text-wrapper">
                <p className="osce-day-header_small-name">
                  {translate('dayStartTime')}
                </p>
                <div
                  className="osce-day-header_small-value"
                  onClick={e => e.stopPropagation()}
                >
                  <TimePicker
                    disabled={disabled}
                    format={TIME_SEND}
                    onChange={value =>
                      this.changeStart({
                        dayStartTime: value ? value.format(TIME_SEND) : null,
                      })
                    }
                    value={
                      dayStartTime ? moment(dayStartTime, TIME_SEND) : undefined
                    }
                    open={this.state.openTimePicker}
                    onOpenChange={this.handleOpenChange}
                    addon={() => (
                      <Button
                        size="small"
                        type="primary"
                        onClick={this.handleClose}
                      >
                        Ok
                      </Button>
                    )}
                  />
                </div>
              </div>
              <div className="osce-day_expander" />
            </div>
            <div className="osce-day-header_block">
              <div className="osce-day_expander" />
              {coffeeBreaks.length > 2 ? (
                <Popover
                  placement="bottom"
                  content={this.contentPopoverBreak(
                    coffeeBreaks,
                    'dayCoffeeBreak',
                  )}
                >
                  {this.contentBreak(
                    firstCoffeeBreakStartTime,
                    firstCoffeeBreakEndTime,
                    'dayCoffeeBreak',
                    1,
                  )}
                </Popover>
              ) : (
                this.contentBreak(
                  firstCoffeeBreakStartTime,
                  firstCoffeeBreakEndTime,
                  'dayCoffeeBreak',
                  1,
                )
              )}
              <div className="osce-day_expander" />
            </div>
            <div className="osce-day-header_block">
              <div className="osce-day_expander" />
              {lunchBreaks.length > 1 ? (
                <Popover
                  placement="bottom"
                  content={this.contentPopoverBreak(
                    lunchBreaks,
                    'dayLunchBreak',
                  )}
                >
                  {this.contentBreak(
                    lunchBreakStartTime,
                    lunchBreakEndTime,
                    'dayLunchBreak',
                  )}
                </Popover>
              ) : (
                this.contentBreak(
                  lunchBreakStartTime,
                  lunchBreakEndTime,
                  'dayLunchBreak',
                )
              )}
              <div className="osce-day_expander" />
            </div>
            <div className="osce-day-header_block">
              <div className="osce-day_expander" />
              {coffeeBreaks.length > 2 ? (
                <Popover
                  placement="bottom"
                  content={this.contentPopoverBreak(
                    coffeeBreaks,
                    'dayCoffeeBreak',
                  )}
                >
                  {this.contentBreak(
                    secondCoffeeBreakStartTime,
                    secondCoffeeBreakEndTime,
                    'dayCoffeeBreak',
                    2,
                  )}
                </Popover>
              ) : (
                this.contentBreak(
                  secondCoffeeBreakStartTime,
                  secondCoffeeBreakEndTime,
                  'dayCoffeeBreak',
                  2,
                )
              )}
              <div className="osce-day_expander" />
            </div>
            <div className="osce-day-header_block osce-day-header_block-short">
              <div className="osce-day_expander" />
              <div className="osce-day-header_text-wrapper">
                <p className="osce-day-header_small-name">
                  {translate('dayEndTime')}
                </p>
                <p className="osce-day-header_small-value">{dayEndTime}</p>
              </div>
              <div className="osce-day_expander" />
            </div>
            <div className="osce-day-header_block osce-day-header_block-short">
              <div className="osce-day_expander" />
              <div className="osce-day-header_text-wrapper">
                <p className="osce-day-header_small-name">
                  {translate('students', 'output')}
                </p>
                <p className="osce-day-header_small-value">{maxStudents}</p>
              </div>
              <div className="osce-day_expander" />
            </div>
          </div>
          <div className="osce-day-header_right">
            <Popconfirm
              placement="bottom"
              title={translate('areYouSureDelete', 'confirm')}
              onCancel={e => e.stopPropagation()}
              onConfirm={this.deleteDay}
              disabled={disabled}
            >
              <Button
                onClick={e => e.stopPropagation()}
                disabled={countDays < 2 || disabled}
                style={{ marginRight: '10px' }}
              >
                {translate('delete', 'form')}
              </Button>
            </Popconfirm>
          </div>
        </div>
      </div>
    );
  }
}
