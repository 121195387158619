import React, { Component } from 'react';
import PropTypes from 'prop-types';
import EditDragTable from 'components/EditDragTable';
import {
  Popconfirm,
  Icon,
  Input,
  Form,
  Select,
  InputNumber,
  message,
} from 'antd';
import { v1 as uuidv1 } from 'uuid';
// Services
import api from 'services/api';
import i18n from 'services/i18n';
// Options
import {
  ANSWERED_CRITERIA,
  WORK_PERMISSION,
  LANGUAGE_LEVEL,
  MARITAL_STATUS,
  NUMERIC_FIELD,
  NUMERIC_SIGN,
  HAS_CRITERIA,
  IS_CRITERIA,
  GENDER,
  BUTTONS,
} from 'constants/options';
// Components
import LazySelect from 'components/LazySelect';

const getLanguageLevel = level =>
  LANGUAGE_LEVEL.filter(item => item.value === level);

const getNumericSign = sign => NUMERIC_SIGN.filter(item => item.value === sign);

@i18n('roleCriteria')
class CriteriaTable extends Component {
  static propTypes = {
    criteria: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.columns = [
      {
        title: props.translate('criteria'),
        dataIndex: 'type',
        width: '25%',
        className: 'relative-column',
        render: (text, record, index, tableInstance, Context) => {
          const editable = tableInstance.isEditing(record);

          const obj = {
            children: [],
          };

          if (record.operation === 'OR') {
            obj.children.push(
              <span
                className="criteria_table_operation"
                key={`criteria_table_operation-${index}`}
              >
                {props.translate('or')}
              </span>,
            );
          }

          if (editable) {
            const children = (
              <div
                className={
                  record.operation === 'OR' ? 'criteria_table_value' : ''
                }
                key={`criteria_table_value-${index}`}
              >
                <Context.Consumer>
                  {form => {
                    return (
                      <Form.Item style={{ marginBottom: 0 }}>
                        {form.getFieldDecorator('type', {
                          initialValue: record.type,
                        })(
                          <Select
                            style={{
                              width: 220,
                              maxWidth: 220,
                            }}
                            placeholder={props.translate('chooseType', 'form')}
                            onChange={type =>
                              this.handleChangeType(form.resetFields)
                            }
                          >
                            {BUTTONS.map(item => (
                              <Select.Option key={item.type} value={item.type}>
                                {props.translate(item.type)}
                              </Select.Option>
                            ))}
                          </Select>,
                        )}
                      </Form.Item>
                    );
                  }}
                </Context.Consumer>
              </div>
            );

            obj.children.push(children);
          } else {
            const children = (
              <div
                className={
                  record.operation === 'OR' ? 'criteria_table_value' : ''
                }
                key={`criteria_table_value-${index}`}
              >
                {props.translate(text)}
              </div>
            );

            obj.children.push(children);
          }

          return obj;
        },
      },
      {
        title: '',
        dataIndex: '',
        width: '45%',
        render: (text, record, index, tableInstance, Context) => {
          const editable = tableInstance.isEditing(record);

          const obj = {
            children: [],
          };

          // If row is in edit mode
          if (editable) {
            const children = (
              <Context.Consumer>
                {form => {
                  const { getFieldDecorator } = form;
                  const type = form.getFieldValue('type');

                  switch (type) {
                    case 'NUMERIC':
                      return (
                        <Input.Group compact key={type}>
                          <Form.Item
                            style={{ marginBottom: 0, marginRight: 10 }}
                          >
                            {getFieldDecorator('field', {
                              initialValue: 'AGE',
                              rules: [
                                {
                                  required: true,
                                  message: this.props.translate(
                                    'checkboxGroup',
                                    'form',
                                  ),
                                },
                              ],
                            })(
                              <Select
                                style={{ width: 100 }}
                                placeholder={this.props.translate(
                                  'chooseProperty',
                                  'form',
                                )}
                              >
                                {NUMERIC_FIELD.map(item => (
                                  <Select.Option
                                    key={item.value}
                                    value={item.value}
                                  >
                                    {this.props.translate(item.value, 'form')}
                                  </Select.Option>
                                ))}
                              </Select>,
                            )}
                          </Form.Item>
                          <Form.Item
                            style={{ marginBottom: 0, marginRight: 10 }}
                          >
                            {getFieldDecorator('sign', {
                              initialValue: 'OVER',
                              rules: [
                                {
                                  required: true,
                                  message: this.props.translate(
                                    'checkboxGroup',
                                    'form',
                                  ),
                                },
                              ],
                            })(
                              <Select
                                style={{ width: 55 }}
                                placeholder={this.props.translate(
                                  'chooseOperation',
                                  'form',
                                )}
                              >
                                {NUMERIC_SIGN.map(item => (
                                  <Select.Option
                                    key={item.value}
                                    value={item.value}
                                  >
                                    {item.title}
                                  </Select.Option>
                                ))}
                              </Select>,
                            )}
                          </Form.Item>
                          <Form.Item style={{ marginBottom: 0 }}>
                            {getFieldDecorator('value', {
                              initialValue: 0,
                              rules: [
                                {
                                  required: true,
                                  message: this.props.translate(
                                    'insert',
                                    'form',
                                  ),
                                },
                              ],
                            })(
                              <InputNumber style={{ width: '100%' }} min={0} />,
                            )}
                          </Form.Item>
                        </Input.Group>
                      );
                    case 'GENDER':
                      return (
                        <Input.Group compact key={type}>
                          <Form.Item
                            style={{ marginBottom: 0, marginRight: 10 }}
                          >
                            {getFieldDecorator('isSimPatHasCriterion', {
                              initialValue: 'is',
                              rules: [
                                {
                                  required: true,
                                  message: this.props.translate(
                                    'checkboxGroup',
                                    'form',
                                  ),
                                },
                              ],
                            })(
                              <Select
                                style={{ width: 69 }}
                                placeholder={this.props.translate(
                                  'chooseOptions',
                                  'form',
                                )}
                              >
                                {IS_CRITERIA.map(item => (
                                  <Select.Option
                                    key={item.value}
                                    value={item.value}
                                  >
                                    {this.props.translate(item.value, 'form')}
                                  </Select.Option>
                                ))}
                              </Select>,
                            )}
                          </Form.Item>
                          <Form.Item style={{ marginBottom: 0 }}>
                            {getFieldDecorator('gender', {
                              initialValue: 'MALE',
                              rules: [
                                {
                                  required: true,
                                  message: this.props.translate(
                                    'checkboxGroup',
                                    'form',
                                  ),
                                },
                              ],
                            })(
                              <Select
                                style={{ width: 100 }}
                                placeholder={this.props.translate(
                                  'chooseGender',
                                  'form',
                                )}
                              >
                                {GENDER.map(item => (
                                  <Select.Option
                                    key={item.value}
                                    value={item.value}
                                  >
                                    {this.props.translate(item.value, 'form')}
                                  </Select.Option>
                                ))}
                              </Select>,
                            )}
                          </Form.Item>
                        </Input.Group>
                      );
                    case 'LANGUAGE':
                      return (
                        <Input.Group compact key={type}>
                          <Form.Item
                            style={{
                              marginBottom: 0,
                              marginRight: 10,
                              width: 170,
                            }}
                          >
                            {getFieldDecorator('languageId', {
                              rules: [
                                {
                                  required: true,
                                  message: this.props.translate(
                                    'checkboxGroup',
                                    'form',
                                  ),
                                },
                              ],
                            })(
                              <LazySelect
                                dropdownMatchSelectWidth
                                loadData={this.handleLoadLanguages}
                              />,
                            )}
                          </Form.Item>
                          <Form.Item
                            style={{ marginBottom: 0, marginRight: 10 }}
                          >
                            {getFieldDecorator('isOnLevel', {
                              initialValue: 'EQUAL',
                              rules: [
                                {
                                  required: true,
                                  message: this.props.translate(
                                    'checkboxGroup',
                                    'form',
                                  ),
                                },
                              ],
                            })(
                              <Select
                                style={{ width: 134 }}
                                placeholder={this.props.translate(
                                  'chooseOperation',
                                  'form',
                                )}
                              >
                                {NUMERIC_SIGN.map(item => (
                                  <Select.Option
                                    key={item.value}
                                    value={item.value}
                                  >
                                    {this.props.translate(item.value, 'form')}
                                  </Select.Option>
                                ))}
                              </Select>,
                            )}
                          </Form.Item>
                          <Form.Item style={{ marginBottom: 0 }}>
                            {getFieldDecorator('level', {
                              initialValue: 'A1',
                              rules: [
                                {
                                  required: true,
                                  message: this.props.translate(
                                    'checkboxGroup',
                                    'form',
                                  ),
                                },
                              ],
                            })(
                              <Select
                                style={{ width: 170 }}
                                placeholder={this.props.translate(
                                  'chooseLevel',
                                  'form',
                                )}
                              >
                                {LANGUAGE_LEVEL.map(item => (
                                  <Select.Option
                                    key={item.key}
                                    value={item.value}
                                  >
                                    {item.title}
                                  </Select.Option>
                                ))}
                              </Select>,
                            )}
                          </Form.Item>
                        </Input.Group>
                      );
                    case 'MARITAL_STATUS':
                      return (
                        <Input.Group compact key={type}>
                          <Form.Item
                            style={{ marginBottom: 0, marginRight: 10 }}
                          >
                            {getFieldDecorator('isSimPatHasCriterion', {
                              initialValue: 'is',
                              rules: [
                                {
                                  required: true,
                                  message: this.props.translate(
                                    'checkboxGroup',
                                    'form',
                                  ),
                                },
                              ],
                            })(
                              <Select
                                style={{ width: 85 }}
                                placeholder={this.props.translate(
                                  'chooseOptions',
                                  'form',
                                )}
                              >
                                {IS_CRITERIA.map(item => (
                                  <Select.Option
                                    key={item.value}
                                    value={item.value}
                                  >
                                    {this.props.translate(item.value, 'form')}
                                  </Select.Option>
                                ))}
                              </Select>,
                            )}
                          </Form.Item>
                          <Form.Item style={{ marginBottom: 0 }}>
                            {getFieldDecorator('maritalStatus', {
                              initialValue: 'CIVIL_UNION',
                              rules: [
                                {
                                  required: true,
                                  message: this.props.translate(
                                    'checkboxGroup',
                                    'form',
                                  ),
                                },
                              ],
                            })(
                              <Select
                                style={{ width: 140 }}
                                placeholder={this.props.translate(
                                  'chooseMaritalStatus',
                                  'form',
                                )}
                              >
                                {MARITAL_STATUS.map(item => (
                                  <Select.Option
                                    key={item.value}
                                    value={item.value}
                                  >
                                    {this.props.translate(item.value, 'form')}
                                  </Select.Option>
                                ))}
                              </Select>,
                            )}
                          </Form.Item>
                        </Input.Group>
                      );
                    case 'WORK_PERMISSION':
                      return (
                        <Input.Group compact key={type}>
                          <Form.Item
                            style={{ marginBottom: 0, marginRight: 10 }}
                          >
                            {getFieldDecorator('isSimPatHasCriterion', {
                              initialValue: 'has',
                              rules: [
                                {
                                  required: true,
                                  message: this.props.translate(
                                    'checkboxGroup',
                                    'form',
                                  ),
                                },
                              ],
                            })(
                              <Select
                                style={{ width: 85 }}
                                placeholder={this.props.translate(
                                  'chooseOptions',
                                  'form',
                                )}
                              >
                                {HAS_CRITERIA.map(item => (
                                  <Select.Option
                                    key={item.value}
                                    value={item.value}
                                  >
                                    {this.props.translate(item.value, 'form')}
                                  </Select.Option>
                                ))}
                              </Select>,
                            )}
                          </Form.Item>
                          <Form.Item style={{ marginBottom: 0 }}>
                            {getFieldDecorator('workPermission', {
                              initialValue: 'B',
                              rules: [
                                {
                                  required: true,
                                  message: this.props.translate(
                                    'checkboxGroup',
                                    'form',
                                  ),
                                },
                              ],
                            })(
                              <Select
                                style={{ width: 140 }}
                                placeholder={this.props.translate(
                                  'chooseWorkPermission',
                                  'form',
                                )}
                              >
                                {WORK_PERMISSION.map(item => (
                                  <Select.Option
                                    key={item.value}
                                    value={item.value}
                                  >
                                    {item.value}-
                                    {this.props.translate(
                                      'permission',
                                      'patient',
                                    )}
                                  </Select.Option>
                                ))}
                              </Select>,
                            )}
                          </Form.Item>
                        </Input.Group>
                      );
                    case 'NATIONALITY':
                      return (
                        <Input.Group compact key={type}>
                          <Form.Item
                            style={{ marginBottom: 0, marginRight: 10 }}
                          >
                            {getFieldDecorator('isSimPatHasCriterion', {
                              initialValue: 'is',
                              rules: [
                                {
                                  required: true,
                                  message: this.props.translate(
                                    'checkboxGroup',
                                    'form',
                                  ),
                                },
                              ],
                            })(
                              <Select
                                style={{ width: 69 }}
                                placeholder={this.props.translate(
                                  'chooseOptions',
                                  'form',
                                )}
                              >
                                {IS_CRITERIA.map(item => (
                                  <Select.Option
                                    key={item.value}
                                    value={item.value}
                                  >
                                    {this.props.translate(item.value, 'form')}
                                  </Select.Option>
                                ))}
                              </Select>,
                            )}
                          </Form.Item>
                          <Form.Item style={{ marginBottom: 0, width: 180 }}>
                            {getFieldDecorator('nationalityId', {
                              rules: [
                                {
                                  required: true,
                                  message: this.props.translate(
                                    'checkboxGroup',
                                    'form',
                                  ),
                                },
                              ],
                            })(
                              <LazySelect
                                dropdownMatchSelectWidth
                                loadData={this.handleLoadNationalities}
                              />,
                            )}
                          </Form.Item>
                        </Input.Group>
                      );
                    case 'PROFESSION':
                      return (
                        <Input.Group compact key={type}>
                          <Form.Item
                            style={{
                              marginBottom: 0,
                              marginRight: 10,
                            }}
                          >
                            {getFieldDecorator('isSimPatHasCriterion', {
                              initialValue: 'is',
                              rules: [
                                {
                                  required: true,
                                  message: this.props.translate(
                                    'checkboxGroup',
                                    'form',
                                  ),
                                },
                              ],
                            })(
                              <Select
                                style={{ width: 69 }}
                                placeholder={this.props.translate(
                                  'chooseOptions',
                                  'form',
                                )}
                              >
                                {IS_CRITERIA.map(item => (
                                  <Select.Option
                                    key={item.value}
                                    value={item.value}
                                  >
                                    {this.props.translate(item.value, 'form')}
                                  </Select.Option>
                                ))}
                              </Select>,
                            )}
                          </Form.Item>
                          <Form.Item style={{ marginBottom: 0, width: 280 }}>
                            {getFieldDecorator('professionId', {
                              rules: [
                                {
                                  required: true,
                                  message: this.props.translate(
                                    'checkboxGroup',
                                    'form',
                                  ),
                                },
                              ],
                            })(
                              <LazySelect
                                dropdownMatchSelectWidth
                                loadData={this.handleLoadProfessions}
                              />,
                            )}
                          </Form.Item>
                        </Input.Group>
                      );
                    case 'QUESTIONNAIRE':
                      return (
                        <Input.Group compact key={type}>
                          <Form.Item
                            style={{
                              marginBottom: 0,
                              marginRight: 10,
                              width: 170,
                            }}
                          >
                            {getFieldDecorator('questionId', {
                              rules: [
                                {
                                  required: true,
                                  message: this.props.translate(
                                    'checkboxGroup',
                                    'form',
                                  ),
                                },
                              ],
                            })(
                              <LazySelect
                                dropdownMatchSelectWidth
                                loadData={this.handleLoadQuestions}
                                onChange={id =>
                                  this.handleQuestionChange(
                                    id,
                                    form.resetFields,
                                  )
                                }
                              />,
                            )}
                          </Form.Item>
                          <Form.Item
                            style={{ marginBottom: 0, marginRight: 10 }}
                          >
                            {getFieldDecorator('isAnsweredWith', {
                              initialValue: 'answer',
                              rules: [
                                {
                                  required: true,
                                  message: this.props.translate(
                                    'checkboxGroup',
                                    'form',
                                  ),
                                },
                              ],
                            })(
                              <Select
                                style={{ width: 157 }}
                                placeholder={this.props.translate(
                                  'chooseType',
                                  'form',
                                )}
                              >
                                {ANSWERED_CRITERIA.map(item => (
                                  <Select.Option
                                    key={item.value}
                                    value={item.value}
                                  >
                                    {this.props.translate(
                                      item.value,
                                      'questionnaireAnswers',
                                    )}
                                  </Select.Option>
                                ))}
                              </Select>,
                            )}
                          </Form.Item>
                          {this.generateInput(form, getFieldDecorator)}
                        </Input.Group>
                      );
                    default:
                      return null;
                  }
                }}
              </Context.Consumer>
            );

            obj.children.push(children);
          } else {
            const { criterion } = record;
            const { isSimPatHasCriterion, isAnsweredWith } = criterion;
            const isString = isSimPatHasCriterion
              ? props.translate('is', 'form')
              : props.translate('isnt', 'form');
            const hasString = isSimPatHasCriterion
              ? props.translate('has', 'form')
              : props.translate('hasnt', 'form');
            const isAnsweredWithString = isAnsweredWith
              ? props.translate('answer', 'questionnaireAnswers')
              : props.translate('noanswer', 'questionnaireAnswers');

            const children = (
              <Context.Consumer>
                {form => {
                  switch (record.type) {
                    case 'QUESTIONNAIRE': {
                      return `"${record._name}" ${isAnsweredWithString} ${
                        record._answer
                      }`;
                    }

                    case 'LANGUAGE': {
                      const { isOnLevel, level } = criterion;

                      return `${record._name} ${props.translate(
                        isOnLevel,
                        'form',
                      )} ${getLanguageLevel(level)[0].title}`;
                    }

                    case 'MARITAL_STATUS': {
                      const { maritalStatus } = criterion;

                      return `${isString} ${props.translate(
                        maritalStatus,
                        'form',
                      )}`;
                    }

                    case 'NATIONALITY': {
                      return `${isString} ${record._name}`;
                    }

                    case 'NUMERIC': {
                      const {
                        field = 'AGE',
                        sign = 'OVER',
                        value = 0,
                      } = criterion;

                      return `${props.translate(field)} ${
                        getNumericSign(sign)[0].title
                      } ${value}`;
                    }

                    case 'PROFESSION': {
                      return `${isString} ${record._name}`;
                    }

                    case 'WORK_PERMISSION': {
                      const { workPermission } = criterion;

                      return `${hasString} ${this.localizeOptions(
                        workPermission,
                      )}`;
                    }

                    case 'GENDER': {
                      const { gender } = criterion;

                      return `${isString} ${props.translate(gender, 'form')}`;
                    }

                    default:
                      return null;
                  }
                }}
              </Context.Consumer>
            );

            obj.children.push(children);
          }

          return obj;
        },
      },
      {
        dataIndex: '',
        width: '200px',
        className: 'table-button_centered',
        render: (text, record, index, tableInstance, Context) => {
          const { editingKey } = tableInstance.state;
          const editable = tableInstance.isEditing(record);

          // If row is in edit mode
          if (editable) {
            return (
              <div className="editable-row-operations">
                <span>
                  <Context.Consumer>
                    {form => (
                      <a
                        className="action-btn"
                        onClick={e => {
                          e.stopPropagation();
                          tableInstance.save(form, record.key);
                        }}
                        style={{ marginRight: 8 }}
                      >
                        {this.props.translate('save', 'form')}
                      </a>
                    )}
                  </Context.Consumer>
                  <a
                    className="action-btn"
                    onClick={e => {
                      e.stopPropagation();
                      this.handleCancelEdit(record, tableInstance);
                    }}
                  >
                    {this.props.translate('cancel', 'form')}
                  </a>
                </span>
              </div>
            );
          }

          // If row is not in edit mode
          return (
            <div className="editable-row-operations">
              <span>
                {record.operation === 'OR' ? null : (
                  <a
                    className="action-btn"
                    disabled={editingKey !== ''}
                    onClick={e => {
                      e.stopPropagation();
                      this.handleAddOrCriteria(index, tableInstance);
                    }}
                  >
                    <Icon type="plus" /> {this.props.translate('or')}
                  </a>
                )}
                <Popconfirm
                  title={this.props.translate('areYouSureDelete', 'confirm')}
                  onClick={e => e.stopPropagation()}
                  onCancel={e => e.stopPropagation()}
                  onConfirm={e => {
                    e.stopPropagation();
                    this.handleDeleteCriterion(record, index);
                  }}
                >
                  <a className="action-btn" disabled={editingKey !== ''}>
                    <Icon type="delete" />{' '}
                    {this.props.translate('delete', 'form')}
                  </a>
                </Popconfirm>
              </span>
            </div>
          );
        },
      },
    ];

    this.state = {
      dataSource: this.props.criteria.map(item => ({
        ...item,
        key: uuidv1(),
      })),
      questions: [],
      languages: [],
      nationalities: [],
      professions: [],
      selectedQuestion: { answersOptions: [] },
    };
  }

  handleDeleteCriterion = (criterion, index) => {
    const dataSource = [...this.state.dataSource];

    // If criterion that was clicked is of AND type
    if (criterion.operation === 'AND') {
      let spliceCount = 1; // How many items to remove
      let nextItemIndex = index + 1; // Select next item

      // While next item exists and it's operation type is OR
      while (
        dataSource[nextItemIndex] &&
        dataSource[nextItemIndex].operation === 'OR'
      ) {
        spliceCount++;
        nextItemIndex++;
      }
      // Delete criterion and all OR criteria that belongs to it
      dataSource.splice(index, spliceCount);
    } else {
      // If criterion that was clicked of type OR
      // just remove it
      dataSource.splice(index, 1);
    }
    // Execute callback to change state
    // and update table data
    this.setState({ dataSource }, () => {
      this.props.onChange(dataSource);
    });
  };

  handleAddOrCriteria = (index, tableInstance) => {
    // Copy datasource
    const dataSource = [...this.state.dataSource];
    // Create new row
    const newRow = {
      key: uuidv1(),
      criterion: {},
      operation: 'OR',
      type: 'NUMERIC',
    };
    // Add new row underneath the clicked one
    dataSource.splice(index + 1, 0, newRow);
    // Update local state, then open row for edit
    this.setState({ dataSource }, tableInstance.edit(newRow.key));
  };

  handleSaveEdit = record => {
    const { type, key, operation, criterion, ...criterionProperties } = record;
    // Based on type convert corresponding fields to bool,
    // set name and answer props

    let _name = '';
    let _answer = '';

    switch (type) {
      case 'LANGUAGE': {
        const language = this.state.languages.find(
          l => l.id === criterionProperties.languageId,
        );
        _name = language.name;
        break;
      }

      case 'PROFESSION': {
        const profession = this.state.professions.find(
          p => p.id === criterionProperties.professionId,
        );
        _name = profession.name;
        criterionProperties.isSimPatHasCriterion =
          criterionProperties.isSimPatHasCriterion === 'is'; // convert to bool
        break;
      }

      case 'NATIONALITY': {
        const nationality = this.state.nationalities.find(
          n => n.id === criterionProperties.nationalityId,
        );
        _name = nationality.name;
        criterionProperties.isSimPatHasCriterion =
          criterionProperties.isSimPatHasCriterion === 'is'; // convert to bool
        break;
      }

      case 'GENDER':
      case 'MARITAL_STATUS':
        criterionProperties.isSimPatHasCriterion =
          criterionProperties.isSimPatHasCriterion === 'is'; // convert to bool
        break;

      case 'WORK_PERMISSION':
        criterionProperties.isSimPatHasCriterion =
          criterionProperties.isSimPatHasCriterion === 'has'; // convert to bool
        break;

      case 'QUESTIONNAIRE': {
        const question = this.state.questions.find(
          q => q.id === criterionProperties.questionId,
        );
        _name = question.question;

        criterionProperties.question = {
          id: criterionProperties.questionId,
          questionType: question.questionType,
        };

        // If type OPEN add openQuestionSearch field to DTO
        if (question.questionType === 'OPEN') {
          criterionProperties.openQuestionSearch =
            criterionProperties.openQuestionSearch;
          _answer = criterionProperties.openQuestionSearch;
        } else {
          // If any other type, find answer object by answer id
          const answerFromAnswersOptions = question.answersOptions.find(
            a => a.id === criterionProperties.answer,
          );
          // Add answer object to DTO
          criterionProperties.answerFromAnswersOptions = answerFromAnswersOptions;
          // For rendering purposes only, used in CriteriaTable
          _answer = answerFromAnswersOptions.value;
        }

        criterionProperties.isAnsweredWith =
          criterionProperties.isAnsweredWith === 'answer'; // convert to bool
        break;
      }

      default:
        break;
    }
    // Map old datasource, find criterion object by it's key
    // update criterion properties. Table return edited record with flat object
    // we need to put criteriaProperties into criterion object
    const dataSource = this.state.dataSource.map(item =>
      item.key === key
        ? {
            type,
            key,
            operation,
            _name,
            _answer,
            criterion: { ...criterionProperties },
          }
        : item,
    );

    // Call callback in order to update
    // table data
    this.props.onChange(dataSource);
  };

  handleCancelEdit = (record, tableInstance) => {
    // Remove row from local cache
    const dataSource = this.state.dataSource.filter(
      item => item.key !== record.key,
    );
    // Cancel row edit
    tableInstance.cancel(record.key);
    // Update local cache
    this.setState({ dataSource });
  };

  localizeOptions = item =>
    `${item}-${this.props.translate('permission', 'patient')}`;

  handleLoadProfessions = data => {
    return api
      .getAllProfessions(data)
      .then(res => {
        this.setState({ professions: res.data.data });
        return res.data;
      })
      .catch(err => message.error(this.props.translate('all', 'error')));
  };

  handleLoadNationalities = data => {
    return api
      .getAllNationalities(data)
      .then(res => {
        this.setState({ nationalities: res.data.data });
        return res.data;
      })
      .catch(err => message.error(this.props.translate('all', 'error')));
  };

  handleLoadLanguages = data => {
    return api
      .getAllLanguages(data)
      .then(res => {
        this.setState({ languages: res.data.data });
        return res.data;
      })
      .catch(err => message.error(this.props.translate('all', 'error')));
  };

  handleLoadQuestions = data => {
    return api
      .getAllQuestions(data)
      .then(res => {
        // Create a proper data structure
        const questionsArr = res.data.data.map(question => ({
          id: question.id,
          name: question.question,
        }));

        this.setState({ questions: res.data.data });

        return { data: questionsArr };
      })
      .catch(err => message.error(this.props.translate('all', 'error')));
  };

  handleQuestionChange = (id, resetFields) => {
    // Find question by id
    const selectedQuestion = this.state.questions.find(q => q.id === id);
    // Set question instance, then reset answer field
    // to prevent getting previously selected answer
    this.setState({ selectedQuestion }, () => resetFields(['answer']));
  };

  handleChangeType = resetFields => {
    // Set question type, then reset *isSimPatHasCriterion* field
    // to prevent getting previously selected answer
    resetFields(['isSimPatHasCriterion']);
  };

  generateInput = (form, getFieldDecorator) => {
    const { selectedQuestion } = this.state;
    const { translate } = this.props;

    if (selectedQuestion.questionType !== 'OPEN') {
      return (
        <Form.Item style={{ marginBottom: 0 }}>
          {getFieldDecorator('answer', {
            initialValue: '',
            rules: [
              {
                required: true,
                message: translate('checkboxGroup', 'form'),
              },
            ],
          })(
            <Select
              style={{ minWidth: 108, width: 163 }}
              placeholder={translate('chooseAnswer', 'sections')}
            >
              {selectedQuestion.answersOptions.map(answer => (
                <Select.Option key={answer.id} value={answer.id}>
                  {answer.value}
                </Select.Option>
              ))}
            </Select>,
          )}
        </Form.Item>
      );
    }

    return (
      <Form.Item style={{ marginBottom: 0, minWidth: 108, maxWidth: 163 }}>
        {getFieldDecorator('openQuestionSearch', {
          initialValue: '',
          rules: [
            {
              required: true,
              message: translate('insert', 'form'),
            },
          ],
        })(<Input type="text" />)}
      </Form.Item>
    );
  };

  render() {
    return (
      <div className="table-with-action" style={{ marginTop: 16 }}>
        <EditDragTable
          canDrag={false}
          columns={this.columns}
          dataSource={this.state.dataSource}
          onSave={this.handleSaveEdit}
        />
      </div>
    );
  }
}

export default CriteriaTable;
