import { buildCRUD } from '../utils/ajax';

export const patientCRUD = buildCRUD('/patient');
export const settingsDefaultCRUD = buildCRUD('/settings/default');
export const examinersCRUD = buildCRUD('/examiner');
export const examinersAssignCRUD = buildCRUD('/examiner/assign');
export const clinicCRUD = buildCRUD('/clinic');
export const disciplineCRUD = buildCRUD('/discipline');
export const skillCRUD = buildCRUD('/skill');
export const circuitCRUD = buildCRUD('/circuit');
export const postCRUD = buildCRUD('/post');
export const scheduleCRUD = buildCRUD('/schedule');
export const roleCRUD = buildCRUD('/role');
export const osceCRUD = buildCRUD('/osce');
export const osceDayCRUD = buildCRUD('/osce-day');
export const inviteCRUD = buildCRUD('/public/confirm/invite');
export const registrationCRUD = buildCRUD('/public/registration');
export const emailSendCRUD = buildCRUD('/public/request/resetPassword');
export const resetPasswordCRUD = buildCRUD('/public/change/password');
export const nationality = buildCRUD('/nationality');
export const administratorsCRUD = buildCRUD('/administrator');
export const roomCRUD = buildCRUD('/room');
export const semesterCRUD = buildCRUD('/semester');
export const administratorsRolesCRUD = buildCRUD('/administrator/roles');
export const administratorsInviteCRUD = buildCRUD('/administrator/invite');
export const studentCRUD = buildCRUD('/student');
export const catalogCRUD = buildCRUD('/catalog');
export const activityLogCRUD = buildCRUD('/activity');
