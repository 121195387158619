import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';

class SelectTable extends Component {
  static propTypes = {
    dataSource: PropTypes.array.isRequired,
    showHeader: PropTypes.bool,
    columns: PropTypes.array.isRequired,
    className: PropTypes.string,
    bordered: PropTypes.bool,
    onSelect: PropTypes.func,
  };

  static defaultProps = {
    bordered: true,
    showHeader: true,
    className: '',
    onSelect: () => {},
  };

  state = {
    selectedRowKeys: [],
  };

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys }, () =>
      this.props.onSelect(selectedRowKeys),
    );
  };

  render() {
    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    return (
      <Table
        pagination={false}
        className={this.props.className}
        bordered={this.props.bordered}
        showHeader={this.props.showHeader}
        rowSelection={rowSelection}
        columns={this.props.columns}
        dataSource={this.props.dataSource}
      />
    );
  }
}

export default SelectTable;
