import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Spin, Icon, Popconfirm, Button, message } from 'antd';
import { debounce } from 'lodash';
// Services
import i18n from 'services/i18n';
import { msgError } from 'services/errorHandler';
// Actions
import {
  getAllProfessions,
  deleteProfession,
  updateProfession,
  createProfession,
  lazyLoadProfessions,
} from 'actions/profession';
// Components
import Search from 'components/Search';
import EditDragTable from 'components/EditDragTable';
import AddProfession from './AddProfession';

const mapStateToProps = state => ({
  professions: state.profession.data,
  loading: state.profession.loading,
});

const mapDispatchToProps = {
  getAllProfessions,
  deleteProfession,
  updateProfession,
  createProfession,
  lazyLoadProfessions,
};

@i18n('professionColumns')
@connect(
  mapStateToProps,
  mapDispatchToProps,
)
export default class Professions extends Component {
  static propTypes = {
    professions: PropTypes.array.isRequired,
    loading: PropTypes.bool,
  };

  static defaultProps = {
    loading: false,
  };

  constructor(props) {
    super(props);
    this.columns = [
      {
        title: props.translate('name'),
        dataIndex: 'name',
        sorter: true,
        editable: true,
      },
      {
        dataIndex: '',
        width: '370px',
        className: 'table-button_centered',
        render: (text, record, index, tableInstance, Context) => {
          const { editingKey } = tableInstance.state;
          const editable = tableInstance.isEditing(record);

          // If row is in edit mode
          if (editable) {
            return (
              <div className="editable-row-operations">
                <span>
                  <Context.Consumer>
                    {form => (
                      <a
                        className="action-btn"
                        onClick={e => {
                          e.stopPropagation();
                          tableInstance.save(form, record.key);
                        }}
                        style={{ marginRight: 8 }}
                      >
                        {props.translate('save', 'form')}
                      </a>
                    )}
                  </Context.Consumer>
                  <a
                    className="action-btn"
                    onClick={e => {
                      e.stopPropagation();
                      tableInstance.cancel(record.key);
                    }}
                  >
                    {props.translate('cancel', 'form')}
                  </a>
                </span>
              </div>
            );
          }

          // If row is not in edit mode
          return (
            <div className="editable-row-operations">
              <span>
                <a
                  className="action-btn"
                  disabled={editingKey !== ''}
                  onClick={e => {
                    e.stopPropagation();
                    tableInstance.edit(record.key);
                  }}
                >
                  <Icon type="edit" /> {this.props.translate('edit', 'form')}
                </a>
                <Popconfirm
                  title={this.props.translate('areYouSureDelete', 'confirm')}
                  onClick={e => e.stopPropagation()}
                  onCancel={e => e.stopPropagation()}
                  onConfirm={e => {
                    e.stopPropagation();
                    this.handleDeleteProfession(record);
                  }}
                >
                  <a className="action-btn">
                    <Icon type="delete" />{' '}
                    {this.props.translate('delete', 'form')}
                  </a>
                </Popconfirm>
              </span>
            </div>
          );
        },
      },
    ];

    this.state = {
      search: '',
      page: 0,
      pageSize: 30,
      total: 0,
      sortProp: 'name',
      sortDirection: 'ASC',
      isAddProfessionModalOpen: false,
      preventLazyLoad: false,
    };
  }

  componentDidMount() {
    this.handleUpdateTableData();
    window.addEventListener('scroll', this.handlePageScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handlePageScroll);
  }

  handlePageScroll = debounce(e => {
    const { body } = document;
    if (
      !this.state.preventLazyLoad &&
      this.props.professions.length < this.state.total &&
      window.scrollY > body.scrollHeight - body.offsetHeight - 30
    ) {
      this.setState(
        { page: this.state.page + 1, preventLazyLoad: true },
        this.lazyLoadProfessions,
      );
    }
  }, 100);

  lazyLoadProfessions = () => {
    const { search, page, pageSize, sortDirection, sortProp } = this.state;
    this.props
      .lazyLoadProfessions({ search, page, pageSize, sortProp, sortDirection })
      .then(res => {
        this.setState({ total: res.pagination.total, preventLazyLoad: false });
      })
      .catch(msgError);
  };

  handleOpenProfessionModal = e =>
    this.setState({ isAddProfessionModalOpen: true });

  handleCloseProfessionModal = e =>
    this.setState({ isAddProfessionModalOpen: false });

  handleUpdateTableData = () => {
    const { search, page, pageSize, sortDirection, sortProp } = this.state;
    this.props
      .getAllProfessions({ search, page, pageSize, sortProp, sortDirection })
      .then(res => {
        this.setState({ total: res.pagination.total });
      })
      .catch(msgError);
  };

  handleSearch = search =>
    this.setState(
      { search, page: 0, pageSize: 30 },
      this.handleUpdateTableData,
    );

  handleSort = (pagination, filters, sorter) => {
    // If sorting column is enabled
    if (sorter.columnKey) {
      const sortDirection = sorter.order === 'ascend' ? 'ASC' : 'DESC';
      const sortProp = sorter.columnKey;
      this.setState(
        { sortDirection, sortProp, page: 0 },
        this.handleUpdateTableData,
      );
    }
  };

  handleAddProfession = fields => {
    this.props.createProfession(fields).then(() => {
      this.setState({ page: 0, pageSize: 30 }, this.handleUpdateTableData);
    });
  };

  handleDeleteProfession = profession => {
    this.props.deleteProfession(profession.id).catch(error => {
      if (error?.response?.status === 409) {
        // profession is in use
        message.error(this.props.translate('1451', 'error'));
      } else {
        // unhandled error
        message.error(this.props.translate('all', 'error'));
      }
    });
  };

  handleUpdateProfession = fields => {
    this.props.updateProfession(fields);
  };

  render() {
    const dataSource = this.props.professions.map(profession => ({
      ...profession,
      key: profession.id,
    }));

    return (
      <React.Fragment>
        <AddProfession
          visible={this.state.isAddProfessionModalOpen}
          onSubmit={this.handleAddProfession}
          onCancel={this.handleCloseProfessionModal}
        />
        <div className="table-panel">
          <Button
            icon="plus"
            className="blueBtn table-panel_btn"
            onClick={this.handleOpenProfessionModal}
          >
            {this.props.translate('addBtn')}
          </Button>
          <Search
            value={this.state.search}
            count={this.state.total}
            placeholder={this.props.translate('search', 'form')}
            onSearch={this.handleSearch}
          />
        </div>
        <Spin spinning={this.props.loading}>
          <div className="table-with-action">
            <EditDragTable
              canDrag={false}
              columns={this.columns}
              dataSource={dataSource}
              onSave={this.handleUpdateProfession}
              onSort={this.handleSort}
            />
          </div>
        </Spin>
      </React.Fragment>
    );
  }
}
