import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Select, Input, InputNumber, Button, message } from 'antd';
// Services
import api from 'services/api';
import i18n from 'services/i18n';
// Components
import LazySelect from 'components/LazySelect';
// Options
import {
  NUMERIC_SIGN,
  LANGUAGE_LEVEL,
  BUTTONS,
  NUMERIC_FIELD,
  IS_CRITERIA,
  HAS_CRITERIA,
  GENDER,
  MARITAL_STATUS,
  WORK_PERMISSION,
  ANSWERED_CRITERIA,
} from 'constants/options';

@i18n('form')
@Form.create()
export default class AddCriterion extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
  };

  state = {
    type: '',
    questions: [],
    selectedQuestion: { answersOptions: [] },
  };

  handleLoadProfessions = data => {
    return api
      .getAllProfessions(data)
      .then(res => res.data)
      .catch(err => message.error(this.props.translate('all', 'error')));
  };

  handleLoadNationalities = data => {
    return api
      .getAllNationalities(data)
      .then(res => res.data)
      .catch(err => message.error(this.props.translate('all', 'error')));
  };

  handleLoadLanguages = data => {
    return api
      .getAllLanguages(data)
      .then(res => res.data)
      .catch(err => message.error(this.props.translate('all', 'error')));
  };

  handleLoadQuestions = data => {
    return api
      .getAllQuestions(data)
      .then(res => {
        // Create a proper data structure
        const questionsArr = res.data.data.map(question => ({
          id: question.id,
          name: question.question,
        }));

        this.setState({ questions: res.data.data });

        return { data: questionsArr };
      })
      .catch(err => message.error(this.props.translate('all', 'error')));
  };

  handleQuestionChange = id => {
    const { resetFields } = this.props.form;
    // Find question by id
    const selectedQuestion = this.state.questions.find(q => q.id === id);
    // Set question instance, then reset answer field
    // to prevent getting previously selected answer
    this.setState({ selectedQuestion }, () => resetFields(['answer']));
  };

  generateInput = () => {
    const { selectedQuestion } = this.state;
    const { getFieldDecorator } = this.props.form;
    const { translate } = this.props;

    if (selectedQuestion.questionType !== 'OPEN') {
      return (
        <Form.Item>
          {getFieldDecorator('answer', {
            initialValue: '',
            rules: [
              {
                required: true,
                message: this.props.translate('checkboxGroup'),
              },
            ],
          })(
            <Select
              style={{ width: '100%' }}
              placeholder={translate('chooseAnswer', 'sections')}
            >
              {selectedQuestion.answersOptions.map(answer => (
                <Select.Option key={answer.id} value={answer.id}>
                  {answer.value}
                </Select.Option>
              ))}
            </Select>,
          )}
        </Form.Item>
      );
    }

    return (
      <Form.Item>
        {getFieldDecorator('openQuestionSearch', {
          initialValue: '',
          rules: [
            {
              required: true,
              message: this.props.translate('insert'),
            },
          ],
        })(<Input type="text" />)}
      </Form.Item>
    );
  };

  renderFields = type => {
    const { getFieldDecorator } = this.props.form;

    switch (type) {
      case 'NUMERIC':
        return (
          <React.Fragment key={type}>
            <Form.Item label={this.props.translate('fillRequired', 'error')}>
              {getFieldDecorator('field', {
                initialValue: 'AGE',
                rules: [
                  {
                    required: true,
                    message: this.props.translate('checkboxGroup'),
                  },
                ],
              })(
                <Select placeholder={this.props.translate('chooseProperty')}>
                  {NUMERIC_FIELD.map(item => (
                    <Select.Option key={item.value} value={item.value}>
                      {this.props.translate(item.value)}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('sign', {
                initialValue: 'OVER',
                rules: [
                  {
                    required: true,
                    message: this.props.translate('checkboxGroup'),
                  },
                ],
              })(
                <Select placeholder={this.props.translate('chooseOperation')}>
                  {NUMERIC_SIGN.map(item => (
                    <Select.Option key={item.value} value={item.value}>
                      {item.title}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('value', {
                initialValue: 0,
                rules: [
                  {
                    required: true,
                    message: this.props.translate('insert'),
                  },
                ],
              })(<InputNumber style={{ width: '100%' }} min={0} />)}
            </Form.Item>
          </React.Fragment>
        );
      case 'GENDER':
        return (
          <React.Fragment key={type}>
            <Form.Item label={this.props.translate('fillRequired', 'error')}>
              {getFieldDecorator('isSimPatHasCriterion', {
                initialValue: 'is',
                rules: [
                  {
                    required: true,
                    message: this.props.translate('checkboxGroup'),
                  },
                ],
              })(
                <Select placeholder={this.props.translate('chooseOptions')}>
                  {IS_CRITERIA.map(item => (
                    <Select.Option key={item.value} value={item.value}>
                      {this.props.translate(item.value)}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('gender', {
                initialValue: 'MALE',
                rules: [
                  {
                    required: true,
                    message: this.props.translate('checkboxGroup'),
                  },
                ],
              })(
                <Select placeholder={this.props.translate('chooseGender')}>
                  {GENDER.map(item => (
                    <Select.Option key={item.value} value={item.value}>
                      {this.props.translate(item.value)}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </Form.Item>
          </React.Fragment>
        );
      case 'LANGUAGE':
        return (
          <React.Fragment key={type}>
            <Form.Item label={this.props.translate('fillRequired', 'error')}>
              {getFieldDecorator('languageId', {
                rules: [
                  {
                    required: true,
                    message: this.props.translate('checkboxGroup'),
                  },
                ],
              })(
                <LazySelect
                  dropdownMatchSelectWidth
                  loadData={this.handleLoadLanguages}
                />,
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('isOnLevel', {
                initialValue: 'EQUAL',
                rules: [
                  {
                    required: true,
                    message: this.props.translate('checkboxGroup'),
                  },
                ],
              })(
                <Select placeholder={this.props.translate('chooseOperation')}>
                  {NUMERIC_SIGN.map(item => (
                    <Select.Option key={item.value} value={item.value}>
                      {this.props.translate(item.value)}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('level', {
                initialValue: 'A1',
                rules: [
                  {
                    required: true,
                    message: this.props.translate('checkboxGroup'),
                  },
                ],
              })(
                <Select placeholder={this.props.translate('chooseLevel')}>
                  {LANGUAGE_LEVEL.map(item => (
                    <Select.Option key={item.key} value={item.value}>
                      {item.title}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </Form.Item>
          </React.Fragment>
        );
      case 'MARITAL_STATUS':
        return (
          <React.Fragment key={type}>
            <Form.Item label={this.props.translate('fillRequired', 'error')}>
              {getFieldDecorator('isSimPatHasCriterion', {
                initialValue: 'is',
                rules: [
                  {
                    required: true,
                    message: this.props.translate('checkboxGroup'),
                  },
                ],
              })(
                <Select placeholder={this.props.translate('chooseOptions')}>
                  {IS_CRITERIA.map(item => (
                    <Select.Option key={item.value} value={item.value}>
                      {this.props.translate(item.value)}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('maritalStatus', {
                initialValue: 'CIVIL_UNION',
                rules: [
                  {
                    required: true,
                    message: this.props.translate('checkboxGroup'),
                  },
                ],
              })(
                <Select
                  placeholder={this.props.translate('chooseMaritalStatus')}
                >
                  {MARITAL_STATUS.map(item => (
                    <Select.Option key={item.value} value={item.value}>
                      {this.props.translate(item.value)}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </Form.Item>
          </React.Fragment>
        );
      case 'WORK_PERMISSION':
        return (
          <React.Fragment key={type}>
            <Form.Item label={this.props.translate('fillRequired', 'error')}>
              {getFieldDecorator('isSimPatHasCriterion', {
                initialValue: 'has',
                rules: [
                  {
                    required: true,
                    message: this.props.translate('checkboxGroup'),
                  },
                ],
              })(
                <Select placeholder={this.props.translate('chooseOptions')}>
                  {HAS_CRITERIA.map(item => (
                    <Select.Option key={item.value} value={item.value}>
                      {this.props.translate(item.value)}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('workPermission', {
                initialValue: 'B',
                rules: [
                  {
                    required: true,
                    message: this.props.translate('checkboxGroup'),
                  },
                ],
              })(
                <Select
                  placeholder={this.props.translate('chooseWorkPermission')}
                >
                  {WORK_PERMISSION.map(item => (
                    <Select.Option key={item.value} value={item.value}>
                      {item.value}-
                      {this.props.translate('permission', 'patient')}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </Form.Item>
          </React.Fragment>
        );
      case 'NATIONALITY':
        return (
          <React.Fragment key={type}>
            <Form.Item label={this.props.translate('fillRequired', 'error')}>
              {getFieldDecorator('isSimPatHasCriterion', {
                initialValue: 'is',
                rules: [
                  {
                    required: true,
                    message: this.props.translate('checkboxGroup'),
                  },
                ],
              })(
                <Select placeholder={this.props.translate('chooseOptions')}>
                  {IS_CRITERIA.map(item => (
                    <Select.Option key={item.value} value={item.value}>
                      {this.props.translate(item.value)}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('nationalityId', {
                rules: [
                  {
                    required: true,
                    message: this.props.translate('checkboxGroup'),
                  },
                ],
              })(
                <LazySelect
                  dropdownMatchSelectWidth
                  loadData={this.handleLoadNationalities}
                />,
              )}
            </Form.Item>
          </React.Fragment>
        );
      case 'PROFESSION':
        return (
          <React.Fragment key={type}>
            <Form.Item label={this.props.translate('fillRequired', 'error')}>
              {getFieldDecorator('isSimPatHasCriterion', {
                initialValue: 'is',
                rules: [
                  {
                    required: true,
                    message: this.props.translate('checkboxGroup'),
                  },
                ],
              })(
                <Select placeholder={this.props.translate('chooseOptions')}>
                  {IS_CRITERIA.map(item => (
                    <Select.Option key={item.value} value={item.value}>
                      {this.props.translate(item.value)}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('professionId', {
                rules: [
                  {
                    required: true,
                    message: this.props.translate('checkboxGroup'),
                  },
                ],
              })(
                <LazySelect
                  dropdownMatchSelectWidth
                  loadData={this.handleLoadProfessions}
                />,
              )}
            </Form.Item>
          </React.Fragment>
        );
      case 'QUESTIONNAIRE':
        return (
          <React.Fragment key={type}>
            <Form.Item label={this.props.translate('fillRequired', 'error')}>
              {getFieldDecorator('questionId', {
                rules: [
                  {
                    required: true,
                    message: this.props.translate('checkboxGroup'),
                  },
                ],
              })(
                <LazySelect
                  dropdownMatchSelectWidth
                  loadData={this.handleLoadQuestions}
                  onChange={this.handleQuestionChange}
                />,
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('isAnsweredWith', {
                initialValue: 'answer',
                rules: [
                  {
                    required: true,
                    message: this.props.translate('checkboxGroup'),
                  },
                ],
              })(
                <Select
                  style={{ width: '100%' }}
                  placeholder={this.props.translate('chooseType')}
                >
                  {ANSWERED_CRITERIA.map(item => (
                    <Select.Option key={item.value} value={item.value}>
                      {this.props.translate(item.value, 'questionnaireAnswers')}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </Form.Item>
            {this.generateInput()}
          </React.Fragment>
        );
      default:
        return null;
    }
  };

  handleChangeType = type => {
    const { resetFields } = this.props.form;
    // Set question type, then reset *isSimPatHasCriterion* field
    // to prevent getting previously selected answer
    this.setState({ type }, () => resetFields(['isSimPatHasCriterion']));
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, fields) => {
      if (!err) {
        // Create DTO object to be send
        const data = {
          type: fields.type,
          operation: 'OR',
        };

        switch (fields.type) {
          case 'NUMERIC':
            data.criterion = {
              field: fields.field,
              sign: fields.sign,
              value: fields.value,
            };
            break;
          case 'LANGUAGE':
            data.criterion = {
              languageId: fields.languageId,
              isOnLevel: fields.isOnLevel,
              level: fields.level,
            };
            break;
          case 'QUESTIONNAIRE': {
            try {
              // Get all questions list
              const { questions } = this.state;
              // Find selected question by id
              const question = questions.find(q => q.id === fields.questionId);
              // Get quesiton type, [ OPEN, SINGLE_SELECTION, MULTIPLE_SELECTION, YES_NO ]
              const { questionType } = question;
              // Convert is answered to bool value
              const isAnsweredWith = fields.isAnsweredWith === 'answer';
              // Create DTO object
              data.criterion = {
                isAnsweredWith,
                question: {
                  id: fields.questionId,
                  questionType,
                },
              };
              // If type OPEN add openQuestionSearch field to DTO
              if (questionType === 'OPEN') {
                data.criterion.openQuestionSearch = fields.openQuestionSearch;
              } else {
                // If any other type, find answer object by answer id
                const answerFromAnswersOptions = question.answersOptions.find(
                  a => a.id === fields.answer,
                );
                // Add answer object to DTO
                data.criterion.answerFromAnswersOptions = answerFromAnswersOptions;
              }
            } catch (error) {
              throw error;
            }
            break;
          }
          case 'GENDER':
            data.criterion = {
              gender: fields.gender,
              isSimPatHasCriterion: fields.isSimPatHasCriterion === 'is', // Convert to bool val
            };
            break;
          case 'MARITAL_STATUS':
            data.criterion = {
              maritalStatus: fields.maritalStatus,
              isSimPatHasCriterion: fields.isSimPatHasCriterion === 'is', // Convert to bool val
            };
            break;
          case 'WORK_PERMISSION':
            data.criterion = {
              workPermission: fields.workPermission,
              isSimPatHasCriterion: fields.isSimPatHasCriterion === 'has', // Convert to bool val
            };
            break;
          case 'NATIONALITY':
            data.criterion = {
              nationalityId: fields.nationalityId,
              isSimPatHasCriterion: fields.isSimPatHasCriterion === 'is', // Convert to bool val
            };
            break;
          case 'PROFESSION':
            data.criterion = {
              professionId: fields.professionId,
              isSimPatHasCriterion: fields.isSimPatHasCriterion === 'is', // Convert to bool val
            };
            break;
          default:
            throw new Error('Unknown criterion type');
        }
        this.props.onSubmit(data);
        this.props.form.resetFields();
        this.props.onCancel();
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Item
          style={{ marginBottom: 20 }}
          label={this.props.translate('type', 'circuit')}
        >
          {getFieldDecorator('type', {
            initialValue: '',
            rules: [
              {
                required: true,
                message: this.props.translate('checkboxGroup'),
              },
            ],
          })(
            <Select
              style={{ width: '100%' }}
              placeholder={this.props.translate('chooseType')}
              onChange={this.handleChangeType}
            >
              {BUTTONS.map(item => (
                <Select.Option key={item.type} value={item.type}>
                  {this.props.translate(item.type, 'roleCriteria')}
                </Select.Option>
              ))}
            </Select>,
          )}
        </Form.Item>
        {this.renderFields(this.state.type)}
        <div style={{ paddingTop: 16, textAlign: 'right' }}>
          <Button
            style={{ marginRight: 10 }}
            onClick={e => {
              this.props.form.resetFields();
              this.props.onCancel();
            }}
          >
            {this.props.translate('cancel')}
          </Button>
          <Button htmlType="submit" className="blueBtn">
            {this.props.translate('save')}
          </Button>
        </div>
      </Form>
    );
  }
}
