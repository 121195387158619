import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { Form, message } from 'antd';

import handleSubmit from 'services/form';

import i18n from 'services/i18n';
import { localizeError } from 'services/errorHandler';

export default function profileFormDecorator({ i18Name = 'form' } = {}) {
  return function(Child) {
    @i18n(i18Name)
    @Form.create({})
    class FormDecorator extends Component {
      static propTypes = {
        closeWindow: PropTypes.func,
      };

      state = { editableRow: null, loading: false };

      static childContextTypes = {
        form: PropTypes.object,
        handleReset: PropTypes.func,
        remoteSubmit: PropTypes.func,
        getStatus: PropTypes.func,
        setStatus: PropTypes.func,
      };

      getChildContext() {
        return {
          form: this.props.form,
          handleReset: this.handleReset,
          remoteSubmit: this.remoteSubmit,
          getStatus: this.getEditableRow,
          setStatus: this.setEditableRow,
        };
      }

      saveForm = _form => _form && (this.formRef = _form);

      getEditableRow = () => this.state.editableRow;

      setEditableRow = name => this.setState({ editableRow: name });

      wrapSubmit = (onSubmit, updateAction) => {
        this.remoteSubmit = async e => {
          const { form } = this.props;
          const values = await handleSubmit(e, form);

          this.setState({ loading: true });
          try {
            const resp = await onSubmit(values);
            updateAction && (await updateAction(resp.data ? resp.data : resp));
            this.setEditableRow();
          } catch (error) {
            console.error(error);
            const { editableRow } = this.state;
            const errorText = localizeError({
              error,
              translate: this.props.translate,
            });
            if (editableRow) {
              form.setFields({
                [editableRow]: {
                  value: get(values, editableRow),
                  errors: [new Error(errorText)],
                },
              });
            } else {
              message.error(errorText);
            }
          } finally {
            this.setState({ loading: false });
          }
        };
        return this.remoteSubmit;
      };

      render() {
        return (
          <Child
            {...this.props}
            loading={this.state.loading}
            wrapSubmit={this.wrapSubmit}
            handleReset={this.handleReset}
            saveForm={this.saveForm}
            remoteSubmit={this.remoteSubmit}
          />
        );
      }
    }
    return FormDecorator;
  };
}
