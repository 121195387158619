import api from 'services/api';
import {
  SP_REQUEST_START,
  FETCH_SPS_SUCCESS,
  FETCH_SPS_ERROR,
  LOAD_MORE_SPS_SUCCESS,
  CREATE_SP_SUCCESS,
  CREATE_SP_ERROR,
  GET_SP_PROFILE_SUCCESS,
  GET_SP_PROFILE_ERROR,
  FETCH_ALL_SP_OSCES_SUCCESS,
  FETCH_ALL_SP_OSCES_ERROR,
  LOAD_MORE_SP_OSCES_SUCCESS,
} from 'constants/simulatedPatients';

export const exportSP = criteria => dispatch => api.exportSp(criteria);

export const getAllSimulatedPatients = data => async dispatch => {
  try {
    dispatch({ type: SP_REQUEST_START });
    const response = await api.getAllSimulatedPatients(data);
    const simulatedPatients = response.data;
    dispatch({ type: FETCH_SPS_SUCCESS, payload: { simulatedPatients } });
    return simulatedPatients;
  } catch (error) {
    dispatch({ type: FETCH_SPS_ERROR, error });
    throw error;
  }
};

export const lazyLoadSPs = data => async dispatch => {
  try {
    dispatch({ type: SP_REQUEST_START });
    const response = await api.getAllSimulatedPatients(data);
    const simulatedPatients = response.data;
    dispatch({ type: LOAD_MORE_SPS_SUCCESS, payload: { simulatedPatients } });
    return simulatedPatients;
  } catch (error) {
    dispatch({ type: FETCH_SPS_ERROR, error });
    throw error;
  }
};

export const createSimulatedPatient = data => async dispatch => {
  try {
    dispatch({ type: SP_REQUEST_START });
    const response = await api.createSimulatedPatient(data);
    const patient = response.data;
    dispatch({ type: CREATE_SP_SUCCESS, payload: { patient } });
    return patient;
  } catch (error) {
    dispatch({ type: CREATE_SP_ERROR, error });
    throw error;
  }
};

export const getSimulatedPatientsProfile = id => async dispatch => {
  try {
    dispatch({ type: SP_REQUEST_START });
    const response = await api.getSimulatedPatientsProfile(id);
    const profile = response.data;
    dispatch({ type: GET_SP_PROFILE_SUCCESS, payload: { profile } });
    return profile;
  } catch (error) {
    dispatch({ type: GET_SP_PROFILE_ERROR, error });
    throw error;
  }
};

export const updateSimulatedPatientsPersonalInfo = (
  id,
  data,
) => async dispatch => {
  try {
    dispatch({ type: SP_REQUEST_START });
    const response = await api.updateSimulatedPatientsPersonalInfo(id, data);
    const updatedData = response.data;
    dispatch(getSimulatedPatientsProfile(id));
    return updatedData;
  } catch (error) {
    throw error;
  }
};

export const getAllSimulatedPatientsOSCEs = data => async dispatch => {
  try {
    dispatch({ type: SP_REQUEST_START });
    const response = await api.getAllSimulatedPatientsOSCEs(data);
    const osces = response.data;
    dispatch({ type: FETCH_ALL_SP_OSCES_SUCCESS, payload: { osces } });
    return osces;
  } catch (error) {
    dispatch({ type: FETCH_ALL_SP_OSCES_ERROR, error });
    throw error;
  }
};

export const lazyLoadSimulatedPatientsOSCEs = data => async dispatch => {
  try {
    dispatch({ type: SP_REQUEST_START });
    const response = await api.getAllSimulatedPatientsOSCEs(data);
    const osces = response.data;
    dispatch({ type: LOAD_MORE_SP_OSCES_SUCCESS, payload: { osces } });
    return osces;
  } catch (error) {
    dispatch({ type: FETCH_ALL_SP_OSCES_ERROR, error });
    throw error;
  }
};
