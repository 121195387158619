import React, { Component } from 'react';
import { Card as ACard } from 'antd';
import i18n from 'services/i18n';

import './formsStyles.less';

@i18n()
export default class Card extends Component {
  render() {
    const { translate, children, from, value } = this.props;
    return (
      <ACard
        bordered={false}
        className={`${this.props.className || 'form-card'}`}
      >
        <ACard.Meta description={value && translate(value, from)} />
        {children}
      </ACard>
    );
  }
}
