import React, { Component, PureComponent } from 'react';
import { Form, Input, Spin, InputNumber } from 'antd';
import cn from 'classnames';
import { set, invoke } from 'lodash';
import PropTypes from 'prop-types';

import i18n from 'services/i18n';
import { setFocusInTheEnd } from 'services/form';
import EditableBtns from './FormEditableBtns';

import './formsStyles.less';

const FormItem = Form.Item;

export class StaticValue extends PureComponent {
  render() {
    const { props } = this;
    return (
      <span
        onClick={props.onClick}
        className={props.className || 'card-no-editable'}
      >
        {invoke(props, 'render', props.value) || props.value}
      </span>
    );
  }
}

@i18n('form')
export default class EditableLabel extends Component {
  static propTypes = {
    withoutLabel: PropTypes.bool, // component will be rendered without label
    title: PropTypes.string, // it will be translated by i18n form
    name: PropTypes.string.isRequired, // it will be key on submit object, if !title name will be translated to
    fullWidth: PropTypes.bool, // component will take all width
    disabled: PropTypes.bool, //  disable edit onClick (just label)
    className: PropTypes.string,
    type: PropTypes.string, // html type
    autoComplete: PropTypes.string,
    i18name: PropTypes.string,
    staticRenderFunction: PropTypes.func,
    required: PropTypes.bool, // form option, if your key is mandatory
    precision: PropTypes.number, // form option, if your key is mandatory
    rules: PropTypes.array, // ant form rules
    initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // ant form initialValue
  };

  static contextTypes = {
    form: PropTypes.object,
    getStatus: PropTypes.func,
    setStatus: PropTypes.func,
    submit: PropTypes.func,
    disabled: PropTypes.bool,
  };

  state = { editable: false, loading: false };

  componentWillReceiveProps(nextProps, nextContext) {
    if (
      this.state.editable &&
      (this.props.name !== nextContext.getStatus() ||
        nextContext.disabled !== this.context.disabled)
    ) {
      this.resetStopEdit();
    }
  }

  componentDidUpdate(nextProps, prevState) {
    if (this.state.editable && !prevState.editable) {
      setFocusInTheEnd(this.refInput);
    }
  }

  saveStartEdit = () => {
    if (this.props.disabled || this.context.disabled) return;
    this.setState({ editable: true });
    this.context.setStatus && this.context.setStatus(this.props.name);
  };

  stopEdit = () => this.setState({ editable: false });

  resetStopEdit = () => {
    const { initialValue, name } = this.props;
    const { setFieldsValue } = this.context.form;
    const newValue = {};
    set(newValue, name, initialValue);
    setFieldsValue(newValue);
    this.stopEdit();
  };

  get btns() {
    if (this.props.disabled || this.context.disabled) return null;
    return (
      <EditableBtns
        editable={this.state.editable}
        saveStartEdit={this.saveStartEdit}
        resetStopEdit={this.resetStopEdit}
      />
    );
  }

  saveInput = ref => ref && (this.refInput = ref);

  get input() {
    const { props } = this;
    switch (props.type) {
      case 'number':
        return (
          <InputNumber
            ref={this.saveInput}
            min={props.min}
            precision={props.precision || 0}
            step={props.step || 1}
          />
        );
      case 'textarea':
        return (
          <Input.TextArea
            ref={this.saveInput}
            autoComplete={props.autoComplete}
          />
        );
      default:
        return (
          <Input
            type={props.type}
            ref={this.saveInput}
            autoComplete={props.autoComplete}
          />
        );
    }
  }

  get field() {
    const { props } = this;
    const { form } = this.context;
    let rules = props.rules || [];
    let { className } = props;
    if (props.withoutLabel && !className) className = 'form_header';
    if (props.required)
      rules = [
        {
          required: true,
          message: props.translate('insert', 'error'),
        },
      ];
    // TODO Need review
    if (props.validator) {
      rules = rules.concat([
        {
          validator: props.validator.func,
          message: props.translate(props.validator.message, 'error'),
        },
      ]);
    }
    return form.getFieldDecorator(props.name, {
      rules,
      initialValue: props.initialValue,
    })(
      this.state.editable &&
        (!this.context.disabled && !this.props.disabled) ? (
        props.component || this.input
      ) : (
        <StaticValue
          onClick={this.saveStartEdit}
          formatStatic={props.formatStatic}
          render={props.staticRenderFunction}
          className={className}
        />
      ),
    );
  }

  render() {
    const { props } = this;
    const { fullWidth } = props;
    let label;
    if (!props.withoutLabel) {
      label = (
        <span onClick={this.saveStartEdit}>
          {props.translate(props.title || props.name, props.i18name)}
        </span>
      );
    }
    return (
      <FormItem
        colon={false}
        label={label}
        required={false}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: fullWidth ? 24 : 14 }}
        className={cn({
          'card-item': !fullWidth,
          'card-editable-width': this.state.editable && !fullWidth,
          'form-full-width': fullWidth,
        })}
      >
        <Spin spinning={this.state.loading} delay={100}>
          {this.field}
          {this.btns}
        </Spin>
      </FormItem>
    );
  }
}
