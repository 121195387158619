import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Spin, Col, Row, Button, message } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import addLoad from 'services/decorators/addLoad';

import { downloadFile, downloadZip } from 'utils/downloadFile';
import { updateData } from 'actions/updateData';
import {
  getOsceDefaultProfile,
  exportToiOsce,
  downloadOsceZip, downloadOsceQr,
} from 'actions/osceAdministration';
import profileFormDecorator from 'services/decorators/profileFormDecorator';
import notification from 'services/decorators/notif';
import TableSelections from '../TableSelections';
import api from "../../../../services/api";

const mapStateToProsp = ({ runtime }) => ({
  data: runtime.osceDefaultData,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ dispatch, updateData, getOsceDefaultProfile }, dispatch);

@withRouter
@notification('output')
@addLoad({ i18Name: 'output' })
@connect(
  mapStateToProsp,
  mapDispatchToProps,
)
@profileFormDecorator({ i18Name: 'output' })
export default class ExportData extends PureComponent {
  static propTypes = {
    data: PropTypes.object,
  };

  static contextTypes = {
    form: PropTypes.object,
  };

  componentDidMount() {
    this.props.getOsceDefaultProfile();
  }

  exportIOsce = osceIds => async () => {
    try {
      const { props } = this;
      await props.stateloading(exportToiOsce({ osceIds }), {
        noShowCatch: true,
      });
      props.notifySuccess('successExport');
    } catch (error) {
      const errMsg =
        error.response.data.message || this.props.translate('all', 'error');
      message.error(errMsg);
    }
  };

  handleQr = osce => async () => {
    api
        .downloadQr(osce.id)
        .then(res =>
            downloadFile(
                res.data,
                'qr.png',
                'image/png',
            ),
        )
        .catch(err => message.error(this.props.translate('all', 'error')));
  };

  showQr = osce => async () => {
    const { props } = this;
    const resp = await props.stateloading(downloadOsceQr({ osceId: osce.id }));
    downloadFile(resp, `${osce.name}.png`);
  };

  openInPusher = osce => async () => {
    const { props } = this;
    const resp = await props.stateloading(downloadOsceZip({ osceId: osce.id }));
    downloadFile(resp, `${osce.name}.iosce`);
  };

  download = osce => async () => {
    const { props } = this;
    const resp = await props.stateloading(downloadOsceZip({ osceId: osce.id }));
    downloadZip(resp, `${osce.name}.zip`);
  };

  renderRow = (text, record) => (
    <span className="inline-flex-center">
      <span>{record.name}</span>
      <span className="spacer" />
      <span className="editable-row-operations">
        <a className="action-btn-single" onClick={this.handleQr(record)}>
          {this.props.translate('showQr')}
        </a>
      </span>
    </span>
  );

  /*
  <span className="spacing" />
      <span className="editable-row-operations">
        <a className="action-btn-single" onClick={this.openInPusher(record)}>
          {this.props.translate('openInPusher')}
        </a>
      </span>
      <span className="spacing" />
      <span className="editable-row-operations">
        <a className="action-btn-single" onClick={this.download(record)}>
          {this.props.translate('download')}
        </a>
      </span>
   */

  getButtons = ({ disabled: _disabled, selected }) => {
    const { props } = this;
    const { translate } = props;
    const disabled = _disabled || props.loading;
    return (
      <React.Fragment>
        <Button
          className="sendInvitations-btn"
          disabled={disabled}
          onClick={this.exportIOsce(selected)}
        >
          {translate('exportIOSCE')}
        </Button>
      </React.Fragment>
    );
  };

  render() {
    const { translate } = this.props;
    return (
      <Spin spinning={this.props.loading}>
        <Row className="table-with-action">
          <Col span={12}>
            <TableSelections
              renderRow={this.renderRow}
              getButtons={this.getButtons}
              url="/osce"
              sendParams={{ semesterId: this.props.match.params.semesterId }}
              title={translate('exportedfiles')}
            />
          </Col>
        </Row>
      </Spin>
    );
  }
}
