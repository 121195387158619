import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { pick } from 'lodash';

/* Components */
import Search from './Search';
import AddItem from './AddItem';

/* Styles */
import './table-panel.less';

/**
 * Component for panel of table with buttons and search
 * @reactProps {bool} search - show search input
 * @reactProps {string} name - variable in redux with data table
 * @reactProps {object} action - object with function
 * @reactProps {string} addBtn - text for button add new item
 * @reactProps {string} inputName - text for label for input new item
 * @reactProps {string} className - additional class name for panel
 * @reactProps {object} sendParams - additional params for get
 * @reactProps {bool} childrenInBegin - show search in the end
 */
export default class TablePanel extends Component {
  static propTypes = {
    search: PropTypes.bool,
    name: PropTypes.string,
    action: PropTypes.object,
    addBtn: PropTypes.string,
    inputName: PropTypes.string,
    className: PropTypes.string,
    sendParams: PropTypes.array,
    childrenInBegin: PropTypes.bool,
  };

  render() {
    const { props } = this;
    const className = props.className
      ? `table-panel ${props.className}`
      : 'table-panel';
    return (
      <div className={className}>
        {props.childrenInBegin && props.children}
        {!!props.addBtn && (
          <AddItem
            {...pick(props, ['action', 'name', 'addBtn', 'inputName'])}
          />
        )}
        {props.search && (
          <Search {...pick(props, ['action', 'name', 'sendParams'])} />
        )}
        {!props.childrenInBegin && props.children}
      </div>
    );
  }
}
