import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import { Icon, Spin, Button, Popconfirm, message } from 'antd';
import { connect } from 'react-redux';
// Services
import i18n from 'services/i18n';
// Actions
import {
  getAllDisciplines,
  lazyLoadDisciplines,
  createDiscipline,
  deleteDiscipline,
  updateDiscipline,
} from 'actions/discipline';
import { createNewSkill } from 'actions/role';
// Components
import Search from 'components/Search';
import EditDragTable from 'components/EditDragTable';
import ExpandIcon from 'components/Table/ExpandIcon';
import AddDiscipline from './AddDiscipline';
import AddSkill from '../AddSkill';
import Skills from './Skills';

const mapStateToProps = state => ({
  disciplines: state.discipline.data,
  loading: state.discipline.loading,
});

const mapDispatchToProps = {
  getAllDisciplines,
  lazyLoadDisciplines,
  createNewSkill,
  createDiscipline,
  deleteDiscipline,
  updateDiscipline,
};

@connect(
  mapStateToProps,
  mapDispatchToProps,
)
@i18n('disciplineColumns')
export default class Discipline extends Component {
  static propTypes = {
    disciplines: PropTypes.array.isRequired,
    loading: PropTypes.bool,
  };

  static defaultProps = {
    loading: false,
  };

  constructor(props) {
    super(props);

    this.columns = [
      {
        title: props.translate('discipline'),
        dataIndex: 'name',
        sorter: true,
        editable: true,
      },
      {
        dataIndex: '',
        width: '370px',
        className: 'table-button_centered',
        render: (text, record, index, tableInstance, Context) => {
          const { editingKey } = tableInstance.state;
          const editable = tableInstance.isEditing(record);

          // If row is in edit mode
          if (editable) {
            return (
              <div className="editable-row-operations">
                <span>
                  <Context.Consumer>
                    {form => (
                      <a
                        className="action-btn"
                        onClick={e => {
                          e.stopPropagation();
                          tableInstance.save(form, record.key);
                        }}
                        style={{ marginRight: 8 }}
                      >
                        {this.props.translate('save', 'form')}
                      </a>
                    )}
                  </Context.Consumer>
                  <a
                    className="action-btn"
                    onClick={e => {
                      e.stopPropagation();
                      tableInstance.cancel(record.key);
                    }}
                  >
                    {this.props.translate('cancel', 'form')}
                  </a>
                </span>
              </div>
            );
          }

          // If row is not in edit mode
          return (
            <div className="editable-row-operations">
              <span>
                <a
                  className="action-btn"
                  onClick={e => {
                    e.stopPropagation();
                    this.handleOpenSkillModal(e, record.id);
                  }}
                >
                  <Icon type="plus" /> {this.props.translate('addSkill')}
                </a>
                <a
                  className="action-btn"
                  disabled={editingKey !== ''}
                  onClick={e => {
                    e.stopPropagation();
                    tableInstance.edit(record.key);
                  }}
                >
                  <Icon type="edit" /> {this.props.translate('edit', 'form')}
                </a>
                <Popconfirm
                  title={this.props.translate('areYouSureDelete', 'confirm')}
                  disabled={!record.canDelete}
                  onClick={e => e.stopPropagation()}
                  onCancel={e => e.stopPropagation()}
                  onConfirm={e => {
                    e.stopPropagation();
                    this.handleDeleteDiscipline(record);
                  }}
                >
                  <a disabled={!record.canDelete} className="action-btn">
                    <Icon type="delete" />{' '}
                    {this.props.translate('delete', 'form')}
                  </a>
                </Popconfirm>
              </span>
            </div>
          );
        },
      },
    ];

    this.state = {
      search: '',
      page: 0,
      pageSize: 30,
      total: 0,
      sortProp: 'name',
      sortDirection: 'ASC',
      isAddDisciplineModalOpen: false,
      isSkillModalOpen: false,
      disciplineId: null,
      skillName: '',
      preventLazyLoad: false,
    };
  }

  componentDidMount() {
    this.handleUpdateTableData();
    window.addEventListener('scroll', this.handlePageScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handlePageScroll);
  }

  handlePageScroll = debounce(e => {
    const { body } = document;
    if (
      !this.state.preventLazyLoad &&
      this.props.disciplines.length < this.state.total &&
      window.scrollY > body.scrollHeight - body.offsetHeight - 30
    ) {
      this.setState(
        { page: this.state.page + 1, preventLazyLoad: true },
        this.lazyLoadDisciplines,
      );
    }
  }, 100);

  lazyLoadDisciplines = () => {
    const { search, page, pageSize, sortDirection, sortProp } = this.state;
    this.props
      .lazyLoadDisciplines({ search, page, pageSize, sortProp, sortDirection })
      .then(res => {
        this.setState({ total: res.pagination.total, preventLazyLoad: false });
      })
      .catch(err => message.error(this.props.translate('all', 'error')));
  };

  handleUpdateTableData = () => {
    const { search, page, pageSize, sortProp, sortDirection } = this.state;
    this.props
      .getAllDisciplines({ search, page, pageSize, sortProp, sortDirection })
      .then(res => {
        this.setState({ total: res.pagination.total });
      })
      .catch(err => message.error(this.props.translate('all', 'error')));
  };

  handleSort = (pagination, filters, sorter) => {
    // If sorting column is enabled
    if (sorter.columnKey) {
      const sortDirection = sorter.order === 'ascend' ? 'ASC' : 'DESC';
      const sortProp = sorter.columnKey;
      this.setState(
        { sortDirection, sortProp, page: 0 },
        this.handleUpdateTableData,
      );
    }
  };

  handleOpenDisciplineModal = e =>
    this.setState({ isAddDisciplineModalOpen: true });

  handleCloseDisciplineModal = e =>
    this.setState({ isAddDisciplineModalOpen: false });

  handleOpenSkillModal = (e, disciplineId) =>
    this.setState({ isSkillModalOpen: true, disciplineId });

  handleCloseSkillModal = e => this.setState({ isSkillModalOpen: false });

  handleSearch = search =>
    this.setState(
      { search, page: 0, pageSize: 30 },
      this.handleUpdateTableData,
    );

  handleAddDiscipline = fields => {
    this.props
      .createDiscipline(fields)
      .then(() => {
        this.setState({ page: 0, pageSize: 30 }, this.handleUpdateTableData);
      })
      .catch(err => message.error(this.props.translate('all', 'error')));
  };

  handleUpdateDiscipline = fields => {
    this.props.updateDiscipline(fields);
  };

  handleDeleteDiscipline = discipline => {
    this.props
      .deleteDiscipline(discipline.id)
      .catch(err => message.error(this.props.translate('all', 'error')));
  };

  handleCreateNewSkill = values => {
    this.props.createNewSkill(values).then(res => {
      this.setState(
        { page: 0, pageSize: 30, search: '' },
        this.handleUpdateTableData,
      );
    });
  };

  render() {
    const dataSource = this.props.disciplines.map(discipline => ({
      ...discipline,
      key: discipline.id,
    }));

    return (
      <React.Fragment>
        <AddDiscipline
          visible={this.state.isAddDisciplineModalOpen}
          onCancel={this.handleCloseDisciplineModal}
          onSubmit={this.handleAddDiscipline}
        />
        {/* Component with a key https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#preferred-solutions */}
        <AddSkill
          key={this.state.disciplineId}
          title={this.props.translate('addSkill')}
          skillName={this.state.skillName}
          isVisible={this.state.isSkillModalOpen}
          disciplineId={this.state.disciplineId}
          onClose={this.handleCloseSkillModal}
          onSubmit={this.handleCreateNewSkill}
        />
        <div className="table-panel">
          <Button
            icon="plus"
            className="blueBtn table-panel_btn"
            onClick={this.handleOpenDisciplineModal}
          >
            {this.props.translate('addBtn')}
          </Button>
          <Search
            value={this.state.search}
            count={this.state.total}
            placeholder={this.props.translate('search', 'form')}
            onSearch={this.handleSearch}
          />
        </div>
        <Spin spinning={this.props.loading}>
          <div className="table-with-action">
            <EditDragTable
              canDrag={false}
              expandRowByClick
              expandIcon={ExpandIcon}
              expandIconAsCell
              columns={this.columns}
              dataSource={dataSource}
              onSave={this.handleUpdateDiscipline}
              onSort={this.handleSort}
              expandedRowRender={discipline => (
                <Skills disciplineId={discipline.id} />
              )}
            />
          </div>
        </Spin>
      </React.Fragment>
    );
  }
}
