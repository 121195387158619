import React from 'react';
import PropTypes from 'prop-types';

/** Components */
import TimeSlotData from './TimeSlotData';

const DefaultStation = props => (
  <React.Fragment>
    {[
      { name: 'students', value: 'studentAssignments' },
      { name: 'simulated-patient', value: 'selectedSpAssignments' },
      { name: 'examiner', value: 'examinerAssignments' },
    ].map(item => (
      <TimeSlotData
        key={item.value}
        {...props}
        name={item.name}
        data={props[item.value]}
      />
    ))}
  </React.Fragment>
);

DefaultStation.propTypes = {
  rotations: PropTypes.array,
  stationId: PropTypes.number,
  earlyStart: PropTypes.bool,
  earlyEnd: PropTypes.bool,
  slotPosition: PropTypes.string,
  positionInStation: PropTypes.number,
  studentAssignments: PropTypes.array,
  selectedSpAssignments: PropTypes.array,
  examinerAssignment: PropTypes.array,
};

export default DefaultStation;
