import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { get, pick } from 'lodash';
import { bindActionCreators } from 'redux';
import { Spin, Switch, Button, Popconfirm } from 'antd';

import { DEFAULT_DESKTOP_SIZE } from 'constants/common';
import { getPdfFile } from 'utils/getPDF';
import stateSend from 'services/stateSend';
import { wrapWait } from 'services/promise';
import { updatePatientR, getPatient } from 'actions/patient';
import { patientCRUD } from 'actions/cruds';
import i18n from 'services/i18n';

import './style.less';

const STATUS_PATH = 'simulatedPatientStatus';
const OTHER_PUT_FIELDS = ['firstName', 'lastName'];

const mapStateToProps = ({
  runtime: { patientData: data },
  runtime,
  localization,
}) => ({
  data,
  isDeleted: get(data, 'deletedAt'),
  loaded: runtime.patientLoaded,
  status: get(data, STATUS_PATH),
  language: localization.lang,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ dispatch, updatePatientR, getPatient }, dispatch);

@withRouter
@i18n('patient')
@connect(
  mapStateToProps,
  mapDispatchToProps,
)
export default class PatientHeader extends Component {
  state = { loading: false, deleting: false };

  changeStatus = newStatus => async () => {
    const { props } = this;
    await stateSend(
      loading => this.setState({ loading }),
      patientCRUD
        .put(props.match.params.id, {
          ...pick(props.data, OTHER_PUT_FIELDS),
          [STATUS_PATH]: newStatus,
        })
        .then(wrapWait(200)),
      { translate: this.props.translate },
    );
    props.updatePatientR({ ...props.data, [STATUS_PATH]: newStatus });
  };

  sendDelete = async () => {
    const { props } = this;
    const { id } = props.match.params;
    await stateSend(
      deleting => this.setState({ deleting }),
      (async () => {
        await patientCRUD.deleteRequest(id);
        return props.getPatient(id);
      })(),
      { translate: props.translate },
    );
    return props.rerender;
  };

  get status() {
    const { isDeleted, status, translate } = this.props;
    const isActive = status !== 'INACTIVE';
    let textKey = isActive ? 'active' : 'inactive';
    if (isDeleted) {
      textKey = 'deleted';
    }
    const opt =
      window.screen.width < DEFAULT_DESKTOP_SIZE ? { size: 'small' } : {};
    return (
      <div
        className="patient-status-switcher"
        onClick={
          !isDeleted
            ? this.changeStatus(isActive ? 'INACTIVE' : 'ACTIVE')
            : undefined
        }
      >
        <Spin spinning={this.state.loading}>
          <Switch {...opt} checked={isActive} disabled={isDeleted} />
          <span className="switcher-text">{translate(textKey)}</span>
        </Spin>
      </div>
    );
  }

  get deleteBtn() {
    const { props } = this;
    const { translate } = props;
    const btnText = translate('delete', 'form');
    if (props.isDeleted) {
      return <Button disabled>{btnText}</Button>;
    }
    if (this.state.deleting) {
      return (
        <Button loading disabled>
          {btnText}
        </Button>
      );
    }
    return (
      <Popconfirm
        placement="bottomRight"
        title={translate('areYouSureDelete', 'confirm')}
        onConfirm={this.sendDelete}
      >
        <Button>{btnText}</Button>
      </Popconfirm>
    );
  }

  loadPDF = (e, name) => {
    const { props } = this;
    e.preventDefault();
    getPdfFile({
      url: `/patient/${props.match.params.id}/print/${name}`,
      locale: props.language,
      method: 'POST',
    });
  };

  get headerButtons() {
    const { translate, isDeleted } = this.props;
    return (
      <div className="additional-header-buttons">
        <a onClick={e => this.loadPDF(e, 'questionnary')}>
          <Button disabled={isDeleted}>{translate('print')}</Button>
        </a>
        <a onClick={e => this.loadPDF(e, 'billing')}>
          <Button disabled={isDeleted}>{translate('printFees')}</Button>
        </a>
      </div>
    );
  }

  render() {
    if (!this.props.loaded) {
      return null;
    }
    return (
      <div className="additional-header">
        {this.status}
        {this.headerButtons}
        <div className="spacer" />
        {this.deleteBtn}
      </div>
    );
  }
}
