import React, { Component } from 'react';
import { Form } from 'antd';
import { withRouter } from 'react-router';

import { addNewOsce } from 'actions/osceAdministration';
import formDecorate from 'services/decorators/formDecorate';
import FormItemInput from 'components/Form/formInput';
import Footer from 'components/Form/modalFooter';

import 'components/Form/formsStyles.less';

@formDecorate({ i18Name: 'cards' })
@withRouter
export default class AddNewOsce extends Component {
  onSubmit = values =>
    addNewOsce({
      ...values,
      semesterId: this.props.match.params.semesterId,
    });

  validatorOsceName = (rule, value, callback) => {
    if (/^[A-Za-z0-9 ._-]*$/.test(value)) {
      callback();
    } else {
      callback(new Error());
    }
  };

  render() {
    const { props } = this;
    const checkOsceName = {
      func: this.validatorOsceName,
      message: 'validatorOsceName',
    };
    return (
      <Form onSubmit={props.wrapSubmit(this.onSubmit)}>
        <div className="form_content">
          <FormItemInput
            name="name"
            label={this.props.translate('osceName')}
            validator={checkOsceName}
            required
          />
        </div>
        <Footer
          submitText="form.save"
          cancelText="form.cancel"
          onCancel={props.handleReset}
        />
      </Form>
    );
  }
}
