import React, { Component } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Button, Popconfirm } from 'antd';
import { get } from 'lodash';

/** Utils, Configs*/
import addLoadWithRedux from 'services/decorators/addLoadWithRedux';

/** Actions*/
import { deleteCircuit } from 'actions/circuits';

/** Components*/
import ColorPicker from './colorPicker';
import { msgError } from '../../services/errorHandler';

/**
 * Accordean element
 * @reactProps {string} title - Title.
 * @reactProps {number} id - Title.
 * @reactProps {bool} isOpened - should it be opened or not.
 * @reactProps {string} color - Color of current element
 * @reactProps {node} Element - Node Element
 * @reactProps {bool} withColorPicker - Show color picker options
 * @reactProps {func} onColorChange - callback when color was changed
 * @reactProps {bool} isManualOsce - type OSCE is manual or not
 */
@addLoadWithRedux({
  i18Name: 'form',
  runtimeReduxNames: [
    'osce',
    props => ({ as: 'circuit', runtimeName: `circuit${props.osceSequenceId}` }),
  ],
})
@withRouter
export default class OpenedElement extends Component {
  static propTypes = {
    osceData: PropTypes.object,
    title: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    isOpened: PropTypes.bool,
    color: PropTypes.string,
    Element: PropTypes.node.isRequired,
    countChildren: PropTypes.number,
    circuitData: PropTypes.object,
    withColorPicker: PropTypes.bool,
    onColorChange: PropTypes.func,
    isManualOsce: PropTypes.bool,
  };

  static defaultProps = {
    color: 'orange',
    withColorPicker: true,
    isOpened: false,
    countChildren: 0,
  };

  state = {
    width: null,
    isOpened: false,
    location: this.props.location.pathname,
  };

  divRef = null;

  componentDidMount() {
    if (this.props.isOpened) {
      setTimeout(this.toggleStateItem('isOpened')(), 100);
    }
    this.width();
  }

  componentDidUpdate(prevProps) {
    const { location, countChildren } = this.props;
    const propsLocation = location.pathname;
    if (propsLocation !== this.state.location) {
      this.changeLocation(propsLocation);
    }
    if (countChildren !== prevProps.countChildren) {
      this.width();
    }
  }

  get isDisabled() {
    return (
      get(this.props.osceData, 'osceStatus') !== 'CIRCUITS CREATED' ||
      get(this.props.osceData, 'freezed') === true
    );
  }

  changeLocation = propsLocation => this.setState({ location: propsLocation });

  width() {
    const ref = this.divRef;
    let width = null;
    if (ref) {
      width =
        Array.from(ref.childNodes).reduce((a, b) => a + b.clientWidth, 0) + 2;
    }
    this.setState({ width });
  }

  setTextInputRef = element => {
    this.divRef = element;
  };

  handleChange = (color, event) => {
    const { onColorChange, id } = this.props;
    onColorChange(id, color.hex);
    event.stopPropagation();
  };

  toggleStateItem = key => e => {
    if (e && e.target.className.indexOf('item') === -1) return;
    this.setState({ [key]: !this.state[key] });
  };

  deleteCircuit = async () => {
    try {
      const { circuitId, circuitData, osceSequenceId } = this.props;
      await deleteCircuit(circuitId);
      const newData = [...circuitData.data];
      const target = newData.findIndex(item => item.circuitId === circuitId);
      if (target > -1) {
        newData.splice(target, 1);
        this.props.updateData({ data: newData }, `circuit${osceSequenceId}`);
      }
    } catch (e) {
      msgError(e);
    }
  };

  render() {
    const { isOpened } = this.state;
    const {
      withColorPicker,
      title,
      Element,
      color,
      circuitData,
      translate,
      isManualOsce,
    } = this.props;
    return (
      <React.Fragment>
        <div
          className="item_container"
          style={{
            borderColor: color,
            backgroundColor: color,
          }}
          onClick={this.toggleStateItem('isOpened')}
        >
          <div className="item">
            {isManualOsce && (
              <Popconfirm
                placement="bottom"
                title={translate('areYouSureDelete', 'confirm')}
                onConfirm={this.deleteCircuit}
              >
                <Button
                  disabled={
                    this.isDisabled || get(circuitData, 'data.length') < 2
                  }
                  className="item_close"
                  icon="close"
                  shape="circle"
                  size="small"
                  onClick={e => e.stopPropagation()}
                />
              </Popconfirm>
            )}
            <p className="item_title">{title}</p>
            {withColorPicker && (
              <ColorPicker handleChange={this.handleChange} hexColor={color} />
            )}
          </div>
        </div>
        <div
          className={cn('item_open', {
            item_opened: isOpened,
          })}
          ref={this.setTextInputRef}
          style={{
            minWidth: isOpened ? `${this.state.width}px` : '0px',
            borderWidth: isOpened ? '8px 1px 2px' : '8px 0 2px',
            borderColor: color,
            backgroundColor: color,
          }}
        >
          <React.Fragment>{Element}</React.Fragment>
        </div>
      </React.Fragment>
    );
  }
}
