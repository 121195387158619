import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Table, Icon, Popover } from 'antd';
import cn from 'classnames';
// Services
import i18n from 'services/i18n';
import closest from 'services/closest';

import PopoverOsceDays from './popoverOsceDays';
import '../style.less';

@i18n('spSelectionsColumns')
export default class Patients extends PureComponent {
  static propTypes = {
    selectedRole: PropTypes.object,
    selectedSps: PropTypes.array.isRequired,
    osceDays: PropTypes.array.isRequired,
    onRemoveSp: PropTypes.func.isRequired,
    onAssignSP: PropTypes.func.isRequired,
    onAssignSPToMultipleDays: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired,
  };

  static defaultProps = {
    selectedRole: { id: null, acronym: '' },
  };

  constructor(props) {
    super(props);
    this.state = { recordId: '' };

    this.columns = [
      {
        key: 'name',
        title: props.translate('name'),
        render: record => `${record.firstName} ${record.lastName}`,
      },
      {
        title: props.translate('assignTo'),
        render: record => (
          <Popover
            placement="bottom"
            overlayClassName="sp-selection_multiple"
            content={
              <div>
                {record.selectTo.map((element, key) => (
                  <p key={key}>{element.acronym}</p>
                ))}
              </div>
            }
          >
            <a className="sp-selection_assign">
              {record.selectTo.map(item => item.acronym).join(', ')}
            </a>
          </Popover>
        ),
      },
      {
        key: 'actions',
        width: '88px',
        render: record => {
          const isVisible = this.state.recordId === record.id;
          return (
            <div
              className={cn('editable-row-operations', {
                focus: isVisible,
              })}
            >
              <Popover
                trigger="click"
                placement="bottom"
                overlayClassName="sp-selection_popover"
                content={
                  <PopoverOsceDays
                    visible={isVisible}
                    closePopover={() => this.setState({ recordId: null })}
                    checkedOsceDays={record.osceDays}
                    listOsceDays={this.props.osceDays}
                    onSubmit={data =>
                      this.props.onAssignSPToMultipleDays(record.id, data)
                    }
                  />
                }
                visible={isVisible}
                onClick={() => this.toggleForm(record.id)}
              >
                <a className="action-btn">
                  <Icon type="plus-circle-o" />
                </a>
              </Popover>
              <a
                className="action-btn"
                onClick={() => this.props.onAssignSP(record.id)}
              >
                <Icon type="double-right" />
              </a>
              <a
                className="action-btn"
                onClick={e => props.onRemoveSp(record.id)}
              >
                <Icon type="delete" />
              </a>
            </div>
          );
        },
      },
    ];
  }

  componentDidMount() {
    document.body.addEventListener('click', this.checkElement);
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.checkElement);
  }

  checkElement = e => {
    if (
      closest(e.target, '.ant-popover') === null &&
      closest(e.target, '.ant-select-dropdown') === null
    ) {
      this.setState({ recordId: null });
    }
  };

  toggleForm = id =>
    this.setState(state => ({ recordId: id === state.recordId ? null : id }));

  render() {
    const dataSource = this.props.selectedSps.map(sp => ({
      ...sp,
      key: sp.id,
    }));

    return (
      <div className="table-with-action sp-selection_patients">
        <Table
          bordered
          pagination={false}
          columns={this.columns}
          dataSource={dataSource}
          locale={{
            emptyText: this.props.selectedRole.id
              ? 'There are no SPs who match the criteria'
              : 'No SPs',
          }}
        />
      </div>
    );
  }
}
