import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { get } from 'lodash';
// Services
import i18n from 'services/i18n';
import i18nTable from 'services/i18nTable';
// Components
import Table from 'components/Table';
import TablePanel from 'components/TablePanel/index';
// Actions
import { studentCRUD } from 'actions/cruds';

const mapStateToProps = ({ runtime }, { name }) => ({
  students: get(runtime, `${name}Data.data`),
});

const SEARCH_FIELDS = ['FIRST_NAME', 'LAST_NAME', 'EMAIL', 'STUDENT_ID'];

@withRouter
@connect(mapStateToProps)
@i18n('studentsColumns')
@i18nTable('studentsColumns')
export default class Students extends Component {
  openProfile = student => () =>
    this.props.history.push(`/student-profile/${student.id}`);

  render() {
    return (
      <React.Fragment>
        <TablePanel
          search
          name="students"
          action={studentCRUD}
          sendParams={[]}
        />
        <Table
          clearOnChangeAdditionalUrl
          name="students"
          className="osce-students table-row-pointer"
          action={studentCRUD}
          onRow={student => ({ onClick: this.openProfile(student) })}
          columns={this.props.studentsColumns}
          sendParams={{
            fields: SEARCH_FIELDS,
          }}
        />
      </React.Fragment>
    );
  }
}
