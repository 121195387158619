import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Spin, Select, Button, Col, Input, message } from 'antd';
// Services
import api from 'services/api';
import i18n from 'services/i18n';
import { ANSWERED_CRITERIA, FILTER_TYPE } from 'constants/options';
// Components
import LazySelect from 'components/LazySelect';

@i18n('form')
@Form.create()
class QuestionnaireFilter extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    form: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired,
  };

  static defaultProps = {
    loading: false,
  };

  state = {
    questions: [],
    selectedQuestion: { answersOptions: [] },
  };

  handleLoadQuestions = data => {
    return api
      .getAllQuestions(data)
      .then(res => {
        // Save questions in temp var
        this.setState({ questions: res.data.data });
        // Create a proper data structure
        const questionsArr = res.data.data.map(question => ({
          id: question.id,
          name: question.question,
        }));

        return { data: questionsArr };
      })
      .catch(err => message.error(this.props.translate('all', 'error')));
  };

  handleQuestionChange = id => {
    const { resetFields } = this.props.form;
    // Find question by id
    const selectedQuestion = this.state.questions.find(q => q.id === id);
    // Set question instance, then reset answer field
    // to prevent getting previously selected answer
    this.setState({ selectedQuestion }, () =>
      resetFields(['answer', 'openQuestionSearch']),
    );
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const data = {};

        // Get all questions list
        const { questions } = this.state;
        // Find selected question by id
        const question = questions.find(q => q.id === values.questionId);
        // Get quesiton type, [ OPEN, SINGLE_SELECTION, MULTIPLE_SELECTION, YES_NO ]
        const { questionType } = question;
        // Convert is answered to bool value
        const isAnsweredWith = values.isAnsweredWith === 'answer';
        // Create DTO object
        data.criterion = {
          isAnsweredWith,
          question: {
            id: values.questionId,
            questionType,
          },
        };
        // For rendering purposes only, used in CriteriaTable
        data._name = question.question;

        // If type OPEN add openQuestionSearch field to DTO
        if (questionType === 'OPEN') {
          data.criterion.openQuestionSearch = values.openQuestionSearch;
          data._answer = values.openQuestionSearch;
        } else {
          // If any other type, find answer object by answer id
          const answerFromAnswersOptions = question.answersOptions.find(
            a => a.id === values.answer,
          );
          // Add answer object to DTO
          data.criterion.answerFromAnswersOptions = answerFromAnswersOptions;
          // For rendering purposes only, used in CriteriaTable
          data._answer = answerFromAnswersOptions.value;
        }
        this.props.onSubmit(FILTER_TYPE.QUESTIONNAIRE, data);
      }
    });
  };

  renderAnswerField = () => {
    const { selectedQuestion } = this.state;
    const { getFieldDecorator } = this.props.form;
    const { translate } = this.props;

    if (selectedQuestion.questionType !== 'OPEN') {
      return (
        <Form.Item>
          {getFieldDecorator('answer', {
            initialValue: '',
            rules: [
              {
                required: true,
                message: this.props.translate('checkboxGroup'),
              },
            ],
          })(
            <Select placeholder={translate('chooseAnswer', 'sections')}>
              {selectedQuestion.answersOptions.map(answer => (
                <Select.Option key={answer.id} value={answer.id}>
                  {answer.value}
                </Select.Option>
              ))}
            </Select>,
          )}
        </Form.Item>
      );
    }

    return (
      <Form.Item>
        {getFieldDecorator('openQuestionSearch', {
          initialValue: '',
          rules: [
            {
              required: true,
              message: this.props.translate('insert'),
            },
          ],
        })(<Input style={{ marginTop: 2 }} type="text" />)}
      </Form.Item>
    );
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Spin spinning={this.props.loading}>
        <Form
          className="criteria-form"
          layout="horizontal"
          onSubmit={this.handleSubmit}
        >
          <Form.Item wrapperCol={{ span: 24 }} className="form-full-width">
            {getFieldDecorator('questionId', {
              rules: [
                {
                  required: true,
                  message: this.props.translate('checkboxGroup'),
                },
              ],
            })(
              <LazySelect
                dropdownMatchSelectWidth
                loadData={this.handleLoadQuestions}
                onChange={this.handleQuestionChange}
              />,
            )}
          </Form.Item>
          <Input.Group>
            <Col span={12}>
              <Form.Item wrapperCol={{ span: 24 }} className="form-full-width">
                {getFieldDecorator('isAnsweredWith', {
                  initialValue: 'answer',
                  rules: [
                    {
                      required: true,
                      message: this.props.translate('checkboxGroup'),
                    },
                  ],
                })(
                  <Select placeholder={this.props.translate('chooseType')}>
                    {ANSWERED_CRITERIA.map(item => (
                      <Select.Option key={item.value} value={item.value}>
                        {this.props.translate(
                          item.value,
                          'questionnaireAnswers',
                        )}
                      </Select.Option>
                    ))}
                  </Select>,
                )}
              </Form.Item>
            </Col>
            <Col span={12}>{this.renderAnswerField()}</Col>
          </Input.Group>
          <Button htmlType="submit" icon="plus" className="add-btn">
            {this.props.translate('add')}
          </Button>
        </Form>
      </Spin>
    );
  }
}

export default QuestionnaireFilter;
