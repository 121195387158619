import React from 'react';
import moment from 'moment';
import { range } from 'lodash';

import { ReactComponent as GenderSvg } from 'images/icons/ic-gender.svg';
import { ReactComponent as LangSvg } from 'images/icons/ic-lang.svg';
import { ReactComponent as MaritalSvg } from 'images/icons/ic-marital.svg';
import { ReactComponent as NationSvg } from 'images/icons/ic-nation.svg';
import { ReactComponent as NumberSvg } from 'images/icons/ic-number.svg';
import { ReactComponent as ProfessionSvg } from 'images/icons/ic-profession.svg';
import { ReactComponent as QuestionSvg } from 'images/icons/ic-question.svg';
import { ReactComponent as WorkSvg } from 'images/icons/ic-work.svg';

import PostStart from 'images/POST_START.png';
import PostEnd from 'images/POST_END.png';
import PostWillEnd from 'images/POST_WILL_END.png';
import BreakStart from 'images/BREAK_START.png';
import BreakEnd from 'images/BREAK_END.png';
import Special from 'images/SPECIAL.png';

export const GENDER = [
  { value: 'MALE' },
  { value: 'FEMALE' },
  { value: 'UNKNOWN' },
];

export const SEMESTER = [
  { value: 'HS' },
  { value: 'FS' },
  { value: 'FEDERAL' },
];

export const YEARS = (() => {
  const years = [];
  for (
    let i = 2013;
    i <
    moment()
      .add(5, 'year')
      .format('YYYY');
    i++
  )
    years.push({
      value: i,
    });
  return years;
})();

export const MARITAL_STATUS = [
  { value: 'CIVIL_UNION' },
  { value: 'DIVORCED' },
  { value: 'MARRIED' },
  { value: 'UNMARRIED' },
  { value: 'WIDOWED' },
];

export const WORK_PERMISSION = [
  { value: 'B' },
  { value: 'C' },
  { value: 'G' },
  { value: 'L' },
];

export const STUDY_YEAR = [
  { value: 'BA.1' },
  { value: 'BA.2' },
  { value: 'BA.3' },
  { value: 'MA.1' },
  { value: 'MA.2' },
  { value: 'MA.3' },
];

export const ROLE_TYPE = [
  { value: 'SIM_PAT' },
  { value: 'SUPERNUMERARY' },
  { value: 'MATERIAL' },
];

export const STATISTIC_FACTOR = [
  { value: 'NONE' },
  { value: 'WEIGHT' },
  { value: 'RATIO' },
];

export const NUMERIC_FIELD = [
  { value: 'AGE' },
  { value: 'BMI' },
  { value: 'WEIGHT' },
  { value: 'HEIGHT' },
];

export const NUMERIC_SIGN = [
  { title: '>', value: 'OVER', key: 1 },
  { title: '<', value: 'LOWER', key: 2 },
  { title: '=', value: 'EQUAL', key: 3 },
  { title: '≠', value: 'NOT_EQUAL', key: 4 },
];

export const IS_CRITERIA = [{ value: 'is' }, { value: 'isnt' }];

export const ANSWERED_CRITERIA = [{ value: 'answer' }, { value: 'noanswer' }];

export const HAS_CRITERIA = [{ value: 'has' }, { value: 'hasnt' }];

export const BUTTONS = [
  { icon: NumberSvg, type: 'NUMERIC' },
  { icon: GenderSvg, type: 'GENDER' },
  { icon: QuestionSvg, type: 'QUESTIONNAIRE' },
  { icon: LangSvg, type: 'LANGUAGE' },
  { icon: NationSvg, type: 'NATIONALITY' },
  { icon: ProfessionSvg, type: 'PROFESSION' },
  { icon: WorkSvg, type: 'WORK_PERMISSION' },
  { icon: MaritalSvg, type: 'MARITAL_STATUS' },
];

export const LANGUAGE_LEVEL = [
  { title: 'A1 (Beginner)', value: 'A1', key: 1 },
  { title: 'A2 (Elementary)', value: 'A2', key: 2 },
  { title: 'B1 (Intermediate)', value: 'B1', key: 3 },
  { title: 'B2 (Upper intermediate)', value: 'B2', key: 4 },
  { title: 'C1 (Effective operational proficiency)', value: 'C1', key: 5 },
  { title: 'C2 (Proficiency)', value: 'C2', key: 6 },
  { title: 'Native speaker', value: 'NATIVE', key: 7 },
];

export const STATION_TYPE = [
  { value: 'REGULAR' },
  { value: 'BREAK' },
  { value: 'PREPARATION' },
  { value: 'DOUBLE' },
  { value: 'DOUBLESP' },
];

export const QUESTION_TYPES = {
  OPEN: 'OPEN',
  YES_NO: 'YES_NO',
  SINGLE_SELECTION: 'SINGLE_SELECTION',
  MULTIPLE_SELECTION: 'MULTIPLE_SELECTION',
};

export const FILTER_TYPE = {
  NUMERIC: 'NUMERIC',
  GENDER: 'GENDER',
  QUESTIONNAIRE: 'QUESTIONNAIRE',
  LANGUAGE: 'LANGUAGE',
  NATIONALITY: 'NATIONALITY',
  PROFESSION: 'PROFESSION',
  WORK_PERMISSION: 'WORK_PERMISSION',
  MARITAL_STATUS: 'MARITAL_STATUS',
};

export const BELL_SYSTEM_ICONS = {
  SOUND_SPECIAL: Special,
  SOUND_POST_START: PostStart,
  SOUND_POST_END: PostEnd,
  SOUND_POST_WILL_END: PostWillEnd,
  SOUND_BREAK_START: BreakStart,
  SOUND_BREAK_END: BreakEnd,
};
