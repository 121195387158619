import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { invoke, get } from 'lodash';

/** UI (Antd) */
import {
  Button,
  Popover,
  Icon,
  Popconfirm,
  Modal,
  Dropdown,
  Menu,
  Collapse,
  Spin,
} from 'antd';

/** Utils, Config */
import addLoadWithRedux from 'services/decorators/addLoadWithRedux';
import { downloadFile } from 'utils/downloadFile';

/** Actions */
import {
  getScheduleAssignment,
  assignStudents,
  assignSP,
  updateSP,
  clearAllSchedule,
  exportStudent,
} from 'actions/schedule';
import { msgError } from 'services/errorHandler';
import { calculateDiff } from 'services/format';

import { OSCE_STATUS } from 'constants/common';

/** Components */
import TablePanel from 'components/TablePanel/index';
import OsceDayHeader from './OsceDayHeader';
import Sequence from './Sequence';
import BreakData from './Station/PopUpContent/BreakData';

import './style.less';

const { Panel } = Collapse;

/**
 * Schedule Main Container
 * @reactProps {func} translate - function which translate text.
 * @reactProps {func} getScheduleAssignment - function for get schedule data for osce.
 * @reactProps {object} scheduleData - object which include all data about:
 * students, simulated patients, examinators
 * @reactProps {bool} scheduleLoaded
 * @reactProps {bool} scheduleLoading
 */
@addLoadWithRedux({
  i18Name: 'schedule',
  runtimeReduxNames: ['osce', 'schedule'],
  mapMethods: { getScheduleAssignment },
})
@withRouter
class Schedule extends Component {
  static propTypes = {
    translate: PropTypes.func,
    getScheduleAssignment: PropTypes.func,
    scheduleData: PropTypes.object,
    scheduleLoaded: PropTypes.bool,
  };

  static defaultProps = {
    scheduleData: { osceDays: [] },
  };

  state = { showPopover: false };

  componentDidMount() {
    const {
      getScheduleAssignment,
      match: {
        params: { id },
      },
    } = this.props;
    getScheduleAssignment('schedule', id);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const isChangedStatus =
      get(this.props, 'osceData.osceStatus') === OSCE_STATUS.CIRCUITS_CREATED &&
      get(nextProps, 'osceData.osceStatus') === OSCE_STATUS.SCHEDULE_CREATED;
    if (
      isChangedStatus &&
      !get(nextProps, 'scheduleData[0].studentResponses[0].sequenceNumber')
    ) {
      const {
        getScheduleAssignment,
        match: {
          params: { id },
        },
      } = this.props;
      getScheduleAssignment('schedule', id);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      const {
        getScheduleAssignment,
        match: {
          params: { id },
        },
      } = this.props;
      getScheduleAssignment('schedule', id);
    }
  }

  get isDisabled() {
    return (
      get(this.props, 'osceData.osceStatus') !== OSCE_STATUS.SCHEDULE_CREATED
    );
  }

  get renderAssignmentContent() {
    const { translate } = this.props;
    return (
      <div style={{ width: 260 }}>
        <p>{translate('assignmentInformation')}</p>
        <Button
          className="table-panel_btn"
          onClick={this.checkAssignStudents('ALPHABETICAL')}
        >
          {translate('alphabetically')}
        </Button>
        <Button
          className="table-panel_btn"
          onClick={this.checkAssignStudents('RANDOM')}
        >
          {translate('random')}
        </Button>
      </div>
    );
  }

  get menu() {
    const exportMenu = [
      { key: 'student', text: this.props.translate('students') },
      { key: 'sp-rotations', text: this.props.translate('rotationSp') },
      { key: 'sp', text: this.props.translate('slotsSP') },
    ];
    return (
      <Menu>
        {exportMenu.map(({ text, key }) => (
          <Menu.Item key={key} onClick={this.exportItem(key)}>
            {text}
          </Menu.Item>
        ))}
      </Menu>
    );
  }

  get renderListOfDays() {
    const { translate, scheduleData } = this.props;
    return invoke(scheduleData, 'osceDays.map', day => (
      <Panel
        key={day.id}
        header={<OsceDayHeader {...day} translate={translate} />}
      >
        <div className="osce-day">
          <div className="osce-day_sequences-wrapper">
            {day.sequences.map(sequence => (
              <React.Fragment key={sequence.id}>
                <Sequence
                  translate={translate}
                  key={sequence.id}
                  osceDay={day}
                  {...sequence}
                />
                {day.sequences.length > 1 &&
                  sequence.positionInOsceDay === 1 &&
                  this.lunchBreakContent(day)}
              </React.Fragment>
            ))}
          </div>
        </div>
      </Panel>
    ));
  }

  lunchBreakContent = ({ id, lunchBreakBetweenOsceSequences }) => {
    const duration = lunchBreakBetweenOsceSequences
      ? calculateDiff(lunchBreakBetweenOsceSequences)
      : '-';
    return (
      <div className="osce-day_manual_break-duration">
        <BreakData
          index="lunchBreak"
          isDisabled
          osceDayId={id}
          timeSlot={lunchBreakBetweenOsceSequences}
        >
          <div className="osce-day_manual_break-duration-block">
            {this.props.translate('breakDuration')}
            <span className="osce-day_manual_break-duration-value">
              {duration}
            </span>
          </div>
        </BreakData>
      </div>
    );
  };

  exportItem = type => async () => {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const resp = await exportStudent(id, type);
    downloadFile(
      resp.data,
      `${type}Schedule.xlsx`,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    );
  };

  checkAssignStudents = studentAssignOrder => () => {
    const { scheduleData } = this.props;
    this.handleVisibleChange(false);
    if (
      get(
        scheduleData.osceDays[0].sequences[0].circuits[0].timeSlots[0].posts[0],
        'studentAssignments[0].firstName',
      )
    ) {
      Modal.confirm({
        content:
          'This action will delete all the previously made students assignments. Are you sure you want to assign students in another order?',
        onOk: this.assignStudents(studentAssignOrder),
      });
    } else {
      this.assignStudents(studentAssignOrder)();
    }
  };

  assignStudents = studentAssignOrder => async () => {
    try {
      const {
        getScheduleAssignment,
        match: {
          params: { id },
        },
      } = this.props;
      await assignStudents(id, studentAssignOrder);
      await getScheduleAssignment('schedule', id);
    } catch (err) {
      msgError(err);
    }
  };

  assignSP = action => async () => {
    try {
      const {
        getScheduleAssignment,
        match: {
          params: { id },
        },
      } = this.props;
      action === 'update' ? await updateSP(id) : await assignSP(id);
      await getScheduleAssignment('schedule', id);
    } catch (err) {
      msgError(err);
    }
  };

  handleVisibleChange = visible => {
    this.setState({ showPopover: visible });
  };

  clearAll = async () => {
    try {
      const {
        props,
        props: {
          match: {
            params: { id, semesterId },
          },
        },
      } = this;
      await clearAllSchedule(props.match.params.id);
      props.updateData({ data: [] }, 'osceDays');
      props.updateData(
        { ...props.osceData, osceStatus: 'CIRCUITS CREATED' },
        'osce',
      );
      this.props.resetData('schedule');
      this.props.history.push(
        `/osce-administration/${semesterId}/osce/circuits-details/${id}`,
      );
    } catch (err) {
      msgError(err);
    }
  };

  render() {
    const { translate, scheduleData, scheduleLoading, osceData } = this.props;
    const isDisabledAssignSP = get(
      scheduleData,
      'osceDays[0].sequences[0].circuits[0].timeSlots[0].posts[0].selectedSpAssignments[0].personId',
    );
    return (
      <React.Fragment>
        <TablePanel>
          <Popover
            placement="bottomLeft"
            content={this.renderAssignmentContent}
            trigger="click"
            visible={this.state.showPopover}
            onVisibleChange={this.handleVisibleChange}
          >
            <Button className="table-panel_btn">
              {translate('studentsAssignment')}
            </Button>
          </Popover>
          <Button
            disabled={isDisabledAssignSP}
            className="table-panel_btn"
            onClick={this.assignSP()}
          >
            {translate('spAssignment')}
          </Button>
          <Popconfirm
            placement="bottom"
            title={translate('areYouSureClearAllSchedule')}
            onConfirm={this.clearAll}
          >
            <Button
              className="table-panel_btn table-panel_btn-right"
              style={{ marginRight: '0' }}
              disabled={this.isDisabled}
            >
              {translate('clearAll', 'form')}
            </Button>
          </Popconfirm>
          <Button
            onClick={this.assignSP('update')}
            className="table-panel_btn table-panel_btn-right"
            style={{ marginRight: '10px' }}
          >
            {translate('updateSP')}
          </Button>
          <Dropdown overlay={this.menu} trigger={['click']}>
            <Button
              className="table-panel_btn table-panel_btn-right"
              style={{ marginRight: '10px' }}
            >
              {translate('export')}
              <Icon type="down" />
            </Button>
          </Dropdown>
        </TablePanel>
        <div className="circuit">
          <Spin spinning={scheduleLoading}>
            {get(osceData, 'osceStatus') !== OSCE_STATUS.SCHEDULE_CREATED ? (
              <p style={{ marginTop: 10 }}>No data</p>
            ) : (
              <Collapse
                destroyInactivePanel
                accordion
                defaultActiveKey={['0']}
                bordered={false}
              >
                {this.renderListOfDays}
              </Collapse>
            )}
          </Spin>
        </div>
      </React.Fragment>
    );
  }
}

export default Schedule;
