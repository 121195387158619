import { combineReducers } from 'redux';
import {
  SP_SELECTION_REQUEST_START,
  FETCH_SELECTED_SPS_SUCCESS,
  FETCH_SELECTED_SPS_ERROR,
  REMOVE_SP_FROM_SEMESTER_SUCCESS,
  REMOVE_SP_FROM_SEMESTER_ERROR,
  FETCH_SELECTED_OSCE_DAYS_SUCCESS,
  FETCH_SELECTED_OSCE_DAYS_ERROR,
  SELECT_OSCE_DAY,
  AUTO_ASSING_SP_SUCCESS,
  AUTO_ASSING_SP_ERROR,
  ASSIGN_SP_FOR_HALF_DAY_SUCCESS,
  ASSIGN_SP_FOR_HALF_DAY_ERROR,
  SELECT_OSCE_ROLE,
  CLEAR_SELECTED_ROLE,
  FILTER_SELECTED_SPS,
  ASSIGN_SP_TO_ROLE_SUCCESS,
  ASSIGN_SP_TO_ROLE_ERROR,
} from 'constants/spSelection';

const loading = (state = false, action) => {
  switch (action.type) {
    case SP_SELECTION_REQUEST_START:
      return true;

    case FETCH_SELECTED_SPS_SUCCESS:
    case FETCH_SELECTED_SPS_ERROR:
    case FETCH_SELECTED_OSCE_DAYS_SUCCESS:
    case FETCH_SELECTED_OSCE_DAYS_ERROR:
    case REMOVE_SP_FROM_SEMESTER_SUCCESS:
    case REMOVE_SP_FROM_SEMESTER_ERROR:
    case AUTO_ASSING_SP_SUCCESS:
    case AUTO_ASSING_SP_ERROR:
    case ASSIGN_SP_FOR_HALF_DAY_SUCCESS:
    case ASSIGN_SP_FOR_HALF_DAY_ERROR:
    case ASSIGN_SP_TO_ROLE_SUCCESS:
    case ASSIGN_SP_TO_ROLE_ERROR:
      return false;

    default:
      return state;
  }
};

const patients = (state = [], action) => {
  switch (action.type) {
    case FETCH_SELECTED_SPS_SUCCESS:
    case FILTER_SELECTED_SPS: {
      return action.payload.selectedSps.data;
    }

    case REMOVE_SP_FROM_SEMESTER_SUCCESS:
      return state.filter(
        patient => patient.id !== action.payload.selectedSpId,
      );

    default:
      return state;
  }
};

const osceDays = (state = [], action) => {
  switch (action.type) {
    case FETCH_SELECTED_OSCE_DAYS_SUCCESS:
    case SELECT_OSCE_DAY:
      return action.payload.osceDays;

    case ASSIGN_SP_FOR_HALF_DAY_SUCCESS: {
      const { osceDayId, assignSpForHalfDay } = action.payload;

      return state.map(osceDay =>
        osceDay.id === osceDayId ? { ...osceDay, assignSpForHalfDay } : osceDay,
      );
    }

    default:
      return state;
  }
};

const selectedRole = (state = { criteria: [] }, action) => {
  switch (action.type) {
    case SELECT_OSCE_ROLE:
      return action.payload.selectedRole;

    case CLEAR_SELECTED_ROLE:
      return { criteria: [] };

    default:
      return state;
  }
};

const spSelection = combineReducers({
  loading,
  patients,
  osceDays,
  selectedRole,
});

export default spSelection;
