import React from 'react';
import { Icon } from 'antd';

const ExpandIcon = props => {
  let icon;
  if (props.expanded) {
    icon = 'caret-down';
  } else {
    icon = 'caret-right';
  }
  return <Icon type={icon} onClick={e => props.onExpand(props.record, e)} />;
};

export default ExpandIcon;
