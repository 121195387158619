import React, { Component } from 'react';
import { Button } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import TablePanel from 'components/TablePanel';
import ModalForm from 'components/ModalForm';
import Table from 'components/Table';
import i18n from 'services/i18n';
import i18nTable from 'services/i18nTable';
import { roomCRUD } from 'actions/cruds';
import { getRooms } from 'actions/administrator';

import AddNewRoom from './AddNewRoom';

const mapDispatchToProps = dispatch =>
  bindActionCreators({ dispatch, getRooms }, dispatch);

@i18n('modalForm')
@i18nTable('roomsColumns')
@connect(
  null,
  mapDispatchToProps,
)
export default class Rooms extends Component {
  state = {
    visible: false,
  };

  showModal = () => this.setState({ visible: true });

  handleCancel = () => {
    this.setState({ visible: false });
  };

  render() {
    const { visible } = this.state;
    const { translate } = this.props;
    return (
      <React.Fragment>
        <ModalForm
          title={translate('addNewRoom')}
          visible={visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <AddNewRoom
            toggleWindow={this.handleCancel}
            updateTable={this.props.getRooms}
          />
        </ModalForm>
        <TablePanel name="rooms" action={roomCRUD}>
          <Button
            icon="plus"
            className="blueBtn table-panel_btn"
            onClick={this.showModal}
          >
            {translate('addNewRoom')}
          </Button>
        </TablePanel>
        <Table
          hasActionBtns
          action={roomCRUD}
          name="rooms"
          columns={this.props.roomsColumns}
        />
      </React.Fragment>
    );
  }
}
