import React from 'react';
import PropTypes from 'prop-types';
import { v1 as uuidv1 } from 'uuid';

/** Components */
import TimeSlotData from './TimeSlotData';

const PreparationStation = props =>
  props.positionInStation === 1 ? (
    <TimeSlotData
      {...props}
      key={uuidv1()}
      name="students"
      data={props.studentAssignments}
      hasEndBreak={props.slotPosition ? props.slotPosition === 'last' : true}
    />
  ) : (
    <React.Fragment>
      {[
        { name: 'students', value: 'studentAssignments' },
        { name: 'simulated-patient', value: 'selectedSpAssignments' },
        { name: 'examiner', value: 'examinerAssignments' },
      ].map(item => (
        <TimeSlotData
          {...props}
          key={uuidv1()}
          name={item.name}
          data={props[item.value]}
          hasStartBreak={
            props.slotPosition
              ? props.slotPosition === 'first'
              : !props.isSpPaused
          }
        />
      ))}
    </React.Fragment>
  );

PreparationStation.propTypes = {
  rotations: PropTypes.array,
  stationId: PropTypes.number,
  earlyStart: PropTypes.bool,
  earlyEnd: PropTypes.bool,
  slotPosition: PropTypes.string,
  positionInStation: PropTypes.number,
  studentAssignments: PropTypes.array,
  selectedSpAssignments: PropTypes.array,
  examinerAssignment: PropTypes.array,
};

export default PreparationStation;
