import React from 'react';
import { Switch, Route } from 'react-router';

/** Constants */
import * as SIDEBARS from 'constants/sidebars';

/** Components **/
import MainLayout from 'components/MainLayout';
import Rooms from 'pages/administration/Rooms';
import Semester from 'pages/administration/Semester';
import Students from 'pages/administration/Students';

const CurrentView = ({ match }) => {
  const { url } = match;

  return (
    <Switch>
      <Route path={`${url}/rooms`} component={Rooms} />
      <Route path={`${url}/semesters`} component={Semester} />
      <Route path={`${url}/students-details`} component={Students} />
    </Switch>
  );
};

const Administration = props => {
  return (
    <MainLayout
      multipleMenus={SIDEBARS.ADMINISTRATION}
      nameMenu="systemAdministration"
    >
      <CurrentView {...props} />
    </MainLayout>
  );
};

export default Administration;
