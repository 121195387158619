import React from 'react';
import PropTypes from 'prop-types';

/** Components */
import TimeSlotData from './TimeSlotData';

const DoubleSPStation = props => (
  <React.Fragment>
    <TimeSlotData {...props} name="students" data={props.studentAssignments} />
    {props.selectedSpAssignments.map(item => (
      <TimeSlotData {...props} name="simulated-patient" data={[item]} />
    ))}
    <TimeSlotData {...props} name="examiner" data={props.examinerAssignments} />
  </React.Fragment>
);

DoubleSPStation.propTypes = {
  rotations: PropTypes.array,
  stationId: PropTypes.number,
  earlyStart: PropTypes.bool,
  earlyEnd: PropTypes.bool,
  slotPosition: PropTypes.string,
  positionInStation: PropTypes.number,
  studentAssignments: PropTypes.array,
  selectedSpAssignments: PropTypes.array,
  examinerAssignment: PropTypes.array,
};

export default DoubleSPStation;
