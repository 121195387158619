import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Modal, Tooltip } from 'antd';
import { get } from 'lodash';
import { withRouter } from 'react-router';

import profileFormDecorator from 'services/decorators/profileFormDecorator';
import { OSCE_STATUS, STATION_TYPES } from 'constants/common';

import { disciplineCRUD, skillCRUD, roleCRUD, roomCRUD } from 'actions/cruds';
import {
  updatePost,
  updateRoomRedux,
  updateDisciplineRedux,
  updateRoleRedux,
  updateSkillRedux,
  validateRooms,
} from 'actions/circuits';
import addLoadWithRedux from 'services/decorators/addLoadWithRedux';

import EditableDropDown from './sectionEditableDropDown';

/**
 * Component for rendering editable selects at station
 * @reactProps {func} translate - ket of element ( for react )
 * @reactProps {object} rolesData - detail data about roles
 * @reactProps {object} disciplinesData - detail data about disciplines
 * @reactProps {object} roomsData - detail data about rooms
 * @reactProps {object} skillsData - detail data about skills
 * @reactProps {boolean} index
 */
@addLoadWithRedux({
  i18Name: 'circuit',
  runtimeReduxNames: ['osce', 'disciplines', 'rooms'],
  addAddDispatchToProps: {
    updateRoomRedux,
    updateDisciplineRedux,
    updateSkillRedux,
    updateRoleRedux,
    validateRooms,
  },
})
@profileFormDecorator()
@withRouter
export default class EditableSection extends Component {
  static propsTypes = {
    translate: PropTypes.func.isRequired,
    osceData: PropTypes.object.isRequired,
    rolesData: PropTypes.object.isRequired,
    index: PropTypes.bool.isRequired,
    disciplinesData: PropTypes.object.isRequired,
    roomsData: PropTypes.object.isRequired,
    skillsData: PropTypes.object.isRequired,
  };

  static childContextTypes = {
    disabled: PropTypes.bool,
  };

  getChildContext() {
    return {
      disabled: this.isDisable,
    };
  }

  get isDisable() {
    const { props } = this;
    return (
      Boolean(get(props, 'data.deletedAt')) ||
      get(props, 'osceData.osceStatus') === OSCE_STATUS.SCHEDULE_CREATED ||
      get(props, 'osceData.freezed') === true
    );
  }

  updateActions = {
    updateDiscipline: updatePost('discipline'),
    updateRoom: updatePost('room'),
    updateSkill: updatePost('skill'),
    updateRole: updatePost('role'),
  };

  updateNames = ['room', 'discipline', 'skill', 'role'];

  componentDidUpdate(prevProps) {
    this.updateNames.forEach(updateName =>
      this.checkUpdate(prevProps, updateName),
    );
  }

  checkUpdate = (prevProps, fieldName) => {
    const name = `${fieldName}Response`;
    const newPostIndex = get(this.props, 'index');
    const oldPostIndex = get(prevProps, 'index');
    const newPost = get(this.props, `station.postResponses[${newPostIndex}]`);
    const oldPost = get(prevProps, `station.postResponses[${oldPostIndex}]`);
    if (!newPost || !oldPost) return;
    const oldValue = get(oldPost, `${name}.id`);
    const newValue = get(newPost, `${name}.id`);
    if (oldValue !== newValue) {
      const clearBody =
        (fieldName === 'discipline' && {
          skill: undefined,
          role: undefined,
        }) ||
        (fieldName === 'skill' && {
          role: undefined,
        }) ||
        {};

      this.props.form.setFieldsValue({
        [fieldName]: newValue,
        ...clearBody,
      });
    }
  };

  showConfirm = () =>
    new Promise((resolve, reject) => {
      const { translate } = this.props;
      Modal.confirm({
        title: translate('alreadyAssigned', 'circuit'),
        content: ' ',
        onOk: () => resolve(true),
        okText: translate('yes', 'sections'),
        onCancel: () => resolve(false),
        cancelText: translate('no', 'sections'),
      });
    });

  submit = async values => {
    const {
      station: { id, postResponses, osceSequenceId },
      index,
      stationPosition,
      positionInOsceSequence,
      match: { params },
      sequenceData,
    } = this.props;
    const currentPost = postResponses[index];
    try {
      const updateAction = this.detectAction(currentPost, values);
      if (updateAction === 'Room') {
        const sameRoomObj = this.props.validateRooms(
          positionInOsceSequence,
          stationPosition,
          index,
          values.room,
          osceSequenceId,
        );
        if (sameRoomObj) {
          const confirmResult = await this.showConfirm();
          if (!confirmResult) {
            this.props.form.resetFields(['room']);
            return;
          }
          const resp = await this.updateActions[`update${updateAction}`]({
            osceId: params.id,
            osceSequenceId: sameRoomObj.osceSequenceId,
            positionInCircuit: sameRoomObj.stationPosition,
            positionInOsceSequence: sameRoomObj.positionInOsceSequence,
            positionInStation: currentPost.positionInStation,
            roomId: 0,
            stationId: sameRoomObj.station.id,
          });
          this.props[`update${updateAction}Redux`](
            sameRoomObj.positionInOsceSequence,
            sameRoomObj.stationPosition,
            sameRoomObj.postIndex,
            resp.data,
            osceSequenceId,
            sequenceData.data,
          );
        }
      }
      const resp = await this.updateActions[`update${updateAction}`]({
        disciplineId: values.discipline,
        osceId: params.id,
        stationId: id,
        osceSequenceId,
        positionInCircuit: stationPosition,
        positionInOsceSequence,
        positionInStation: currentPost.positionInStation,
        roleId: values.role,
        roomId: values.room,
        skillId: values.skill,
      });
      this.props[`update${updateAction}Redux`](
        positionInOsceSequence,
        stationPosition,
        index,
        resp.data,
        osceSequenceId,
        sequenceData.data,
      );
    } catch (e) {
      console.error(e);
    }
  };

  detectAction = (currentPost, values) => {
    if (get(currentPost, 'disciplineResponse.id') !== values.discipline)
      return 'Discipline';
    if (get(currentPost, 'roomResponse.id') !== values.room) return 'Room';
    if (get(currentPost, 'skillResponse.id') !== values.skill) return 'Skill';
    if (get(currentPost, 'roleResponse.id') !== values.role) return 'Role';
    return undefined;
  };

  update = () => {
    this.props.form.resetFields();
  };

  get currentPost() {
    const {
      station: { postResponses },
      index,
    } = this.props;
    return postResponses[index];
  }

  preSubmit = name => value => {
    const { form } = this.props;
    const values = Object.assign(form.getFieldsValue(), { [name]: value });
    this.submit(values);
  };

  render() {
    const { props } = this;
    const { currentPost } = this;
    const discipline = get(currentPost, 'disciplineResponse.id');
    const skill = get(currentPost, 'skillResponse.id');
    if (!props.disciplinesData) return null;
    const notInitLoading =
      get(props.osceData, 'osceStatus') !== 'CIRCUITS CREATED';
    return (
      <Form
        onSubmit={props.wrapSubmit(this.submit)}
        className="editable-wrapper"
      >
        <EditableDropDown
          name="discipline"
          initialOptions={props.disciplinesData}
          initialValue={discipline}
          show={props.index === 0}
          onSelect={this.preSubmit('discipline')}
          addItIfNotExist={get(currentPost, 'disciplineResponse')}
          getList={disciplineCRUD.getList}
        />
        <EditableDropDown
          name="skill"
          disabled={Boolean(!discipline)}
          show={props.index === 0}
          onSelect={this.preSubmit('skill')}
          withoutInitLoading={notInitLoading || Boolean(!discipline)}
          checkId={discipline}
          checkName="discipline"
          addItIfNotExist={get(currentPost, 'skillResponse')}
          initialValue={skill}
          getList={skillCRUD.getList}
        />
        {props.station.stationType === STATION_TYPES.PREPARATION ? (
          <Tooltip
            placement="top"
            title={() => {
              if (props.index === 0) {
                return props.translate('materialRole', 'tooltips');
              }
              return props.translate('checklistRole', 'tooltips');
            }}
          >
            <EditableDropDown
              name="role"
              disabled={Boolean(!skill)}
              checkId={skill}
              checkName="skill"
              show={
                props.index !== 1 ||
                props.station.stationType === STATION_TYPES.PREPARATION
              }
              onSelect={this.preSubmit('role')}
              withoutInitLoading={notInitLoading || Boolean(!skill)}
              customName="roleInformationResponse.acronym"
              initialValue={get(currentPost, 'roleResponse.id')}
              addItIfNotExist={get(currentPost, 'roleResponse')}
              getList={roleCRUD.getList}
            />
            <EditableDropDown
              name="room"
              disabled={get(props, 'osceData.freezed')}
              initialOptions={props.roomsData}
              onSelect={this.preSubmit('room')}
              initialValue={get(currentPost, 'roomResponse.id')}
              addItIfNotExist={get(currentPost, 'roomResponse')}
              getList={roomCRUD.getList}
              customName="roomNumber"
            />
          </Tooltip>
        ) : (
          <>
            <EditableDropDown
              name="role"
              disabled={Boolean(!skill)}
              checkId={skill}
              checkName="skill"
              show={
                props.index !== 1 ||
                props.station.stationType === STATION_TYPES.PREPARATION
              }
              onSelect={this.preSubmit('role')}
              withoutInitLoading={notInitLoading || Boolean(!skill)}
              customName="roleInformationResponse.acronym"
              initialValue={get(currentPost, 'roleResponse.id')}
              addItIfNotExist={get(currentPost, 'roleResponse')}
              getList={roleCRUD.getList}
            />
            <EditableDropDown
              name="room"
              disabled={get(props, 'osceData.freezed')}
              initialOptions={props.roomsData}
              onSelect={this.preSubmit('room')}
              initialValue={get(currentPost, 'roomResponse.id')}
              addItIfNotExist={get(currentPost, 'roomResponse')}
              getList={roomCRUD.getList}
              customName="roomNumber"
            />
          </>
        )}
        <Button
          type="primary"
          icon="check"
          size="small"
          style={{ display: 'none' }}
          htmlType="submit"
        />
      </Form>
    );
  }
}
