export const SP_REQUEST_START = 'SP_REQUEST_START';
export const FETCH_SPS_SUCCESS = 'FETCH_SPS_SUCCESS';
export const FETCH_SPS_ERROR = 'FETCH_SPS_ERROR';
export const LOAD_MORE_SPS_SUCCESS = 'LOAD_MORE_SPS_SUCCESS';
export const CREATE_SP_SUCCESS = 'CREATE_SP_SUCCESS';
export const CREATE_SP_ERROR = 'CREATE_SP_ERROR';
export const GET_SP_PROFILE_SUCCESS = 'GET_SP_PROFILE_SUCCESS';
export const GET_SP_PROFILE_ERROR = 'GET_SP_PROFILE_ERROR';
export const FETCH_ALL_SP_OSCES_SUCCESS = 'FETCH_ALL_SP_OSCES_SUCCESS';
export const FETCH_ALL_SP_OSCES_ERROR = 'FETCH_ALL_SP_OSCES_ERROR';
export const LOAD_MORE_SP_OSCES_SUCCESS = 'LOAD_MORE_SP_OSCES_SUCCESS';
