import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { onlyNumber } from 'services/validations';
import { Form, notification } from 'antd';

import { roomCRUD } from 'actions/cruds';

import handleSubmit from 'services/form';
import FormItemInput from 'components/Form/formInput';
import i18n from 'services/i18n';
import ErrorItem from 'components/Form/formError';
import Footer from 'components/Form/modalFooter';
import 'components/Form/formsStyles.less';

@i18n('form')
@Form.create({})
export default class AddNewRoom extends Component {
  static childContextTypes = {
    form: PropTypes.object,
  };

  state = {
    errorText: '',
  };

  getChildContext() {
    return { form: this.props.form };
  }

  addNewRoomSubmit = async e => {
    const values = await handleSubmit(e, this.props.form);
    try {
      await roomCRUD.post(values);
      notification.success({
        message: this.props.translate('success'),
      });
      await this.props.updateTable();
      this.handleReset();
    } catch (error) {
      console.error(error);
      !this.state.errorText && this.toggleError('form.incorrect');
    }
  };

  toggleError = errorText => {
    this.setState(state => ({ errorText }));
  };

  handleReset = () => {
    this.props.form.resetFields();
    this.toggleError('');
    this.props.toggleWindow();
  };

  render() {
    const { translate } = this.props;
    return (
      <Form onSubmit={this.addNewRoomSubmit}>
        <div className="form_content">
          <FormItemInput
            name="roomNumber"
            label={translate('roomNumber')}
            required
          />
          <FormItemInput
            name="floor"
            label={translate('floor')}
            validator={{
              func: onlyNumber,
              message: 'onlyPositiveNumber',
            }}
            required
          />
          <ErrorItem
            toggleError={this.toggleError}
            errorText={this.state.errorText}
          />
        </div>
        <Footer
          submitText="form.save"
          cancelText="form.cancel"
          onCancel={this.handleReset}
        />
      </Form>
    );
  }
}
