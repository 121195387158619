import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import moment from 'moment';

/** Utils, Configs*/
import i18n from 'services/i18n';

/** Components*/
import HeaderButtons from './HeaderButtons';

/**
 * Component which render details about osce day ( date, time, lunch break )
 * @reactProps {func} translate - function which translated content according to chosen language
 * @reactProps {string} name - osce day name
 * @reactProps {string} date - osce day date
 * @reactProps {string} osceStatus - osce status
 * @reactProps {string} dayStartTime - osce day start time
 * @reactProps {string} dayEndTime - osce day end time
 * @reactProps {string} lunchBreakEndTime - osce day end lunch break time
 * @reactProps {string} lunchBreakStartTime - osce day start lunch break time
 * @reactProps {number} id - osce day id
 * @reactProps {boolean} withControlButtons - boolean value for make
 * visible / unvisible control buttons ( earlier / later lunch , postpone / prepone rotation )
 */
@withRouter
@i18n('circuit')
export default class OsceDayHeader extends PureComponent {
  static propsTypes = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    osceStatus: PropTypes.string.isRequired,
    dayStartTime: PropTypes.string.isRequired,
    dayEndTime: PropTypes.string.isRequired,
    lunchBreakEndTime: PropTypes.string.isRequired,
    lunchBreakStartTime: PropTypes.string.isRequired,
    isPreponeDisabled: PropTypes.bool.isRequired,
    isPostponeDisabled: PropTypes.bool.isRequired,
    isLaterDisabled: PropTypes.bool.isRequired,
    isEarlieDisabled: PropTypes.bool.isRequired,
    timeSlotResponses: PropTypes.array.isRequired,
    translate: PropTypes.func.isRequired,
    withControlButtons: PropTypes.bool,
  };

  static defaultProps = {
    lunchBreakEndTime: '',
    lunchBreakStartTime: '',
    withControlButtons: true,
  };

  render() {
    const {
      id,
      name,
      date,
      translate,
      dayStartTime,
      dayEndTime,
      osceStatus,
      lunchBreakEndTime,
      lunchBreakStartTime,
      withControlButtons,
      isLaterDisabled,
      isEarlieDisabled,
      isPreponeDisabled,
      isPostponeDisabled,
      timeSlotResponses,
    } = this.props;
    return (
      <div className="osce-day">
        <div className="osce-day-header">
          <div className="osce-day-header_left">
            <div className="osce-day-header_block osce-day-header_main-name-block">
              <p className="osce-day-header_name">
                {translate('day')} {name}
              </p>
            </div>
            <div className="osce-day-header_block">
              <div className="osce-day_expander" />
              <div className="osce-day-header_text-wrapper">
                <p className="osce-day-header_small-name">
                  {translate('date')}
                </p>
                <p className="osce-day-header_small-value">
                  {moment(date).format('DD.MM.YYYY')}
                </p>
              </div>
              <div className="osce-day_expander" />
            </div>
            <div className="osce-day-header_block">
              <div className="osce-day_expander" />
              <div className="osce-day-header_text-wrapper">
                <p className="osce-day-header_small-name">
                  {translate('dayStartTime')}
                </p>
                <p className="osce-day-header_small-value">{dayStartTime}</p>
              </div>
              <div className="osce-day_expander" />
            </div>
            <div className="osce-day-header_block">
              <div className="osce-day_expander" />
              <div className="osce-day-header_text-wrapper">
                <p className="osce-day-header_small-name">
                  {translate('dayLunchBreak')}
                </p>
                <p className="osce-day-header_small-value">
                  {lunchBreakStartTime}-{lunchBreakEndTime}
                </p>
              </div>
              <div className="osce-day_expander" />
            </div>
            <div className="osce-day-header_block">
              <div className="osce-day_expander" />
              <div className="osce-day-header_text-wrapper">
                <p className="osce-day-header_small-name">
                  {translate('dayEndTime')}
                </p>
                <p className="osce-day-header_small-value">{dayEndTime}</p>
              </div>
              <div className="osce-day_expander" />
            </div>
          </div>
          {withControlButtons && (
            <HeaderButtons
              osceDayId={id}
              osceStatus={osceStatus}
              isPreponeDisabled={isPreponeDisabled}
              isPostponeDisabled={isPostponeDisabled}
              isEarlieDisabled={isEarlieDisabled}
              isLaterDisabled={isLaterDisabled}
              timeSlotResponses={timeSlotResponses}
            />
          )}
        </div>
      </div>
    );
  }
}
