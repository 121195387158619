import React, { Component } from 'react';
import { Form } from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { omit, get } from 'lodash';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';

import { updateData } from 'actions/updateData';
import handleSubmit from 'services/form';
import {
  getOsceCustomProfile,
  postCustomOsceSetting,
} from 'actions/osceAdministration';
import i18n from 'services/i18n';
import profileFormDecorator from 'services/decorators/profileFormDecorator';

import '../../osceSettings/style.less';
import StorageCredentials from '../../settingsFields/storageCredentials';
import IOsceSettings from '../../settingsFields/iOsceSettings';
import OsceSettings from '../../settingsFields/osceSettings';
import FeedbackCredentials from '../../settingsFields/feedbackCredentials';
import DepotCredentials from '../../settingsFields/depotCredentials';

const mapStateToProps = ({ runtime }) => ({
  data: runtime.osceCustomData,
  osceData: get(runtime, 'osceData', {}),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ dispatch, updateData, getOsceCustomProfile }, dispatch);

@withRouter
@i18n('form')
@connect(
  mapStateToProps,
  mapDispatchToProps,
)
@profileFormDecorator()
export default class OsceCustomSettings extends Component {
  static contextTypes = {
    form: PropTypes.object,
    setStatus: PropTypes.func,
  };

  static childContextTypes = {
    disabled: PropTypes.bool,
  };

  getChildContext() {
    return {
      disabled:
        get(this.props, 'osceData.freezed') === true ||
        !get(this.props, 'data.isActive'),
    };
  }

  componentDidMount() {
    this.props.getOsceCustomProfile(this.props.match.params.id);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.context.form.resetFields();
      this.context.setStatus();
      this.props.getOsceCustomProfile(this.props.match.params.id);
    }
  }

  getSettingsProfile = () => {
    this.context.form.resetFields();
    this.context.setStatus();
    this.props.getOsceCustomProfile(this.props.match.params.id);
  };

  submit = async values => {
    let vals = values;
    if (!vals) {
      vals = await handleSubmit(null, this.context.form);
    }
    return postCustomOsceSetting(
      this.props.match.params.id,
      omit(vals, 'checkBox'),
    );
  };

  render() {
    return (
      <Form
        onSubmit={this.props.wrapSubmit(this.submit, newData =>
          this.props.updateData(newData, 'osceCustom'),
        )}
      >
        <OsceSettings
          osceData={this.props.osceData}
          getSettingsProfile={this.getSettingsProfile}
        />
        <StorageCredentials data={this.props.data} />
        <FeedbackCredentials data={this.props.data} />
        <DepotCredentials data={this.props.data} />
        <IOsceSettings submit={this.submit} data={this.props.data} />
      </Form>
    );
  }
}
