import React, { Component } from 'react';

import { Form, Select, Spin } from 'antd';
import cn from 'classnames';
import { set, debounce, chain, find, get, cloneDeep } from 'lodash';
import PropTypes from 'prop-types';

import stateSend from 'services/stateSend';
import { DEFAULT_SCROLL_OFFSET } from 'constants/common';
import i18n from 'services/i18n';
import { focusSelect } from 'services/DOM';
import EditableBtns from 'components/Form/FormEditableBtns';

import 'components/Form/formsStyles.less';

const FormItem = Form.Item;

/**
 * Component for rendering drop-downs at selects of station
 * @reactProps {func} getList - only getList or options, getList - from crudBUILDER for getting dropdown options
 * @reactProps {array} options - dropdowns options
 * @reactProps {string} customName - name of select field
 * @reactProps {string} plusToLabel
 * @reactProps {string} name - it will be key on submit object, if !title name will be translated to
 * @reactProps {boolean} disabled
 * @reactProps {string} i18name - name of string at translation files
 * @reactProps {string} selectClassName
 * @reactProps {boolean} required - form option, if your key is mandatory
 * @reactProps {boolean} search
 * @reactProps {string} sendValueName - ant placeholder
 * @reactProps {boolean} withoutTranslate - boolean value for tracking if we need translate option
 * @reactProps {object} initialOptions - initial data for dropdown
 * @reactProps {array | string | number} initialValue
 */
@i18n('form')
export default class SectionEditableDropDown extends Component {
  static propTypes = {
    getList: PropTypes.func, // only getList or options, getList - from crudBUILDER
    options: PropTypes.array, // if you want to load data without BE
    customName: PropTypes.string,
    plusToLabel: PropTypes.string,
    name: PropTypes.string.isRequired, // it will be key on submit object, if !title name will be translated to
    disabled: PropTypes.bool, //  disable edit onClick (just lable)
    show: PropTypes.bool, //  show
    className: PropTypes.string,
    checkName: PropTypes.string,
    checkId: PropTypes.number,
    i18name: PropTypes.string,
    selectClassName: PropTypes.string,
    required: PropTypes.bool, // form option, if your key is mandatory
    search: PropTypes.bool,
    sendValueName: PropTypes.string, // ant placeholder
    withoutTranslate: PropTypes.bool, // ant form initialValue
    withoutInitLoading: PropTypes.bool, // ant form initialValue
    fullWidth: PropTypes.bool, // ant form initialValue
    initialOptions: PropTypes.object, // ant form initialValue
    addItIfNotExist: PropTypes.oneOfType([PropTypes.object, PropTypes.string]), // will be added to list of options
    initialValue: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.array,
    ]), // ant form initialValue
  };

  static defaultProps = {
    className: 'no-edit-label',
    selectClassName: 'select-section',
    plusToLabel: '...',
    search: true,
    show: true,
    withoutInitLoading: true,
    fullWidth: true,
    addItIfNotExist: '',
  };

  static contextTypes = {
    form: PropTypes.object,
    getStatus: PropTypes.func,
    setStatus: PropTypes.func,
    submit: PropTypes.func,
    disabled: PropTypes.bool,
  };

  state = {
    editable: false,
    loading: false,
    search: undefined,
    array: [],
    pagination: {},
  };

  constructor(props) {
    super(props);
    this.debouncedTryLoadMore = debounce(this.tryLoadMore, 100, {
      leading: false,
    });
  }

  componentDidMount() {
    const {
      name,
      withoutInitLoading,
      getList,
      initialOptions,
      addItIfNotExist,
    } = this.props;
    !withoutInitLoading && getList && this.load();
    if (initialOptions) {
      this.loadInitialOps();
    }
    if ((name === 'skill' || name === 'role') && addItIfNotExist) {
      this.checkInArrayAndAdd();
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (
      this.state.editable &&
      (this.props.name !== nextContext.getStatus() ||
        nextContext.disabled !== this.context.disabled)
    )
      this.resetStopEdit();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.editable && !prevState.editable) {
      this.focus();
    }
    if (prevProps.checkId !== this.props.checkId) {
      this.resetData();
      return;
    }
    if (
      this.props.addItIfNotExist &&
      prevProps.initialValue !== this.props.initialValue
    ) {
      this.checkInArrayAndAdd();
    }
  }

  checkInArrayAndAdd = () => {
    const findInState = find(this.state.array, {
      id: this.props.initialValue,
    });
    if (!findInState) {
      this.setState({
        array: [...this.state.array, this.props.addItIfNotExist],
      });
    }
  };

  resetData = () => {
    this.setState({ array: [] });
    this.context.form.resetFields([this.props.name]);
    this.load();
  };

  saveArray = (data, search) => {
    const findInState = find(this.state.array, { id: this.props.initialValue });
    const findInData = find(data, { id: this.props.initialValue });
    if ((!search && !this.state.search) || search === this.state.search) {
      if (this.props.addItIfNotExist && !findInState && !findInData)
        data.push(this.props.addItIfNotExist);
      if (findInState && findInData) {
        const index = this.state.array.findIndex(
          item => item.id === this.props.initialValue,
        );
        this.state.array.splice(index, 1);
      }
      return this.state.array.concat(data);
    }
    return data;
  };

  getStructure = (resp, search) => ({
    array: this.saveArray(resp.data, search),
    pagination: resp.pagination,
    search,
    selected: this.props.selected,
  });

  loadInitialOps = () => {
    if (this.state.array.length === 0) {
      const initialOptions = cloneDeep(this.props.initialOptions);
      this.setState(this.getStructure(initialOptions));
    }
  };

  load = async (page = 0, search = '', pars = null) => {
    try {
      let _pars = {};
      if (!pars) {
        _pars = this.props.checkId
          ? { [`${this.props.checkName}Id`]: this.props.checkId }
          : {};
      } else {
        _pars = pars;
      }
      const params = {
        page,
        search,
        ..._pars,
      };
      const resp = await stateSend(
        loading => this.setState({ loading }),
        this.props.getList({ params }),
        { translate: this.props.translate },
      );
      this.setState(this.getStructure(resp, search));
    } catch (e) {
      console.error(e);
    }
  };

  checkLoadMore = () => {
    const { total } = this.state.pagination;
    return !!total && this.state.array.length < total;
  };

  tryLoadMore = offset => {
    if (
      this.props.getList &&
      !this.state.loading &&
      offset < DEFAULT_SCROLL_OFFSET &&
      this.checkLoadMore()
    ) {
      this.load(this.state.pagination.page + 1);
    }
  };

  saveStartEdit = () => {
    if (this.props.name !== 'room') {
      if (this.props.disabled || this.context.disabled) return;
    } else if (this.props.disabled) return;

    this.setState({ editable: true });
    this.context.setStatus && this.context.setStatus(this.props.name);
  };

  stopEdit = () => this.setState({ editable: false });

  resetStopEdit = () => {
    const { initialValue, name } = this.props;
    const { setFieldsValue } = this.context.form;
    const newValue = {};
    set(newValue, name, initialValue);
    setFieldsValue(newValue);
    this.stopEdit();
  };

  get btns() {
    const { props } = this;
    if (props.disabled || this.context.disabled || props.onlyDropDown)
      return null;
    return (
      <EditableBtns
        editable={this.state.editable}
        needShowEditable={false}
        saveStartEdit={this.saveStartEdit}
        resetStopEdit={this.resetStopEdit}
      />
    );
  }

  onScroll = e => {
    const { target } = e;
    this.debouncedTryLoadMore(
      target.scrollHeight - target.offsetHeight - target.scrollTop,
    );
  };

  onSearch = value => {
    this.load(0, value);
  };

  get dropDownBody() {
    const { props } = this;
    if (props.getList) {
      return this.state.array.map(element =>
        element instanceof Object ? (
          <Select.Option
            title={get(element, props.customName) || element.name}
            value={element.id}
            key={element.id}
          >
            {get(element, props.customName) || element.name}
          </Select.Option>
        ) : (
          <Select.Option title={element} value={element} key={element}>
            {element}
          </Select.Option>
        ),
      );
    }
    return props.options.map(element => {
      const title = props.withoutTranslate
        ? element.title || element.value
        : props.translate(element.value, props.i18Name);
      return (
        <Select.Option
          title={title}
          value={element[props.sendValueName] || element.value}
          key={element.id || element.value}
        >
          {title}
        </Select.Option>
      );
    });
  }

  getValue = id => {
    const { props } = this;
    if (props.options && props.initialValue) {
      return props.withoutTranslate ? id : props.translate(id, props.i18Name);
    }
    return (
      chain(this.state.array)
        .find({ id })
        .get(props.customName || 'name')
        .value() || id
    );
  };

  saveRef = ref => ref && (this.selectRef = ref);

  focus = () => focusSelect(this.selectRef);

  findSelectedObject = (id, value) => {
    const customName = this.props.customName || 'name';
    return find(this.state.array, { id, [customName]: value });
  };

  onSelect = value => {
    this.setState({ editable: false, array: [] });
    this.props.onSelect(value);
    this.load();
  };

  get select() {
    const { props } = this;
    let rules = props.rules || [];
    if (props.required) {
      rules = rules.concat({
        required: true,
        message: props.translate('checkboxGroup'),
      });
    }
    const { getFieldDecorator, getFieldValue } = this.context.form;
    const id = getFieldValue(props.name);

    const withValueStyles = id
      ? {
          fontSize: '14px',
          fontWeight: '500',
          fontStyle: 'normal',
          fontStretch: 'normal',
          lineHeight: 'normal',
          letterSpacing: 'normal',
          color: 'rgba(0, 0, 0, 0.65)',
        }
      : {};
    const value = id
      ? this.getValue(id)
      : props.translate(props.name) + props.plusToLabel;
    return getFieldDecorator(props.name, {
      initialValue: props.initialValue,
      rules,
    })(
      this.state.editable ? (
        <Select
          ref={this.saveRef}
          dropdownStyle={{
            minWidth: '150px',
          }}
          className={props.selectClassName}
          onPopupScroll={this.onScroll}
          showSearch={props.search}
          onChange={(val, data) =>
            this.props.onChange &&
            this.props.onChange(
              this.findSelectedObject(val, data.props.children),
              data,
            )
          }
          onSearch={this.onSearch}
          onSelect={this.onSelect}
          filterOption={false}
          placeholder={props.placeholder}
          notFoundContent={
            this.state.loading ? <Spin size="small" /> : props.notFoundContent
          }
        >
          {this.dropDownBody}
        </Select>
      ) : (
        <span
          onClick={this.saveStartEdit}
          title={value}
          className={props.className || 'card-no-editable'}
          style={
            this.props.show && this.props.disabled
              ? { cursor: 'not-allowed', ...withValueStyles }
              : { cursor: 'pointer', ...withValueStyles }
          }
        >
          {props.show && value}
        </span>
      ),
    );
  }

  render() {
    return (
      <FormItem
        colon={false}
        className={cn({
          'form-full-width': true,
        })}
        style={
          this.props.show
            ? {
                cursor: this.props.disabled ? 'not-allowed' : 'pointer',
              }
            : {}
        }
      >
        {this.select}
      </FormItem>
    );
  }
}
