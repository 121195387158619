import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Form, notification, Input, Button, Row, Col } from 'antd';

import handleSubmit from 'services/form';
import i18n from 'services/i18n';
import { inviteCRUD, registrationCRUD } from 'actions/cruds';
import FormItemInput from 'components/Form/formInput';
import ErrorItem from 'components/Form/formError';
import Header from './header';

import './loginStyles.less';

const FormItem = Form.Item;

@i18n('form')
@withRouter
@Form.create({})
export default class SignUp extends Component {
  static childContextTypes = {
    form: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      errorText: '',
      email: '',
    };
  }

  componentDidMount() {
    this.loadData();
  }

  getChildContext() {
    return { form: this.props.form };
  }

  loadData = async () => {
    const { hash } = this.props.match.params;
    if (hash) {
      try {
        this.state.loading = true;
        const { data } = await inviteCRUD.post({
          encryptedText: hash,
        });
        this.setState(data);
      } catch (error) {
        notification.error({
          message: this.props.translate('invalidLink', 'error'),
        });
        this.props.history.push('/auth/login');
      }
    }
    this.setState({ loading: false });
  };

  checkPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback(new Error());
    } else {
      callback();
    }
  };

  signUpSubmit = async e => {
    e.preventDefault();
    const values = await handleSubmit(e, this.props.form);
    if (values.password !== values.repeatPassword) {
      !this.state.errorText && this.toggleError('form.unEqualPasswords');
      return;
    }
    try {
      this.state.loading = true;
      await registrationCRUD.post({
        email: this.state.email,
        ...values,
      });
      notification.success({
        message: this.props.translate('success'),
      });
      this.props.history.push('/auth/login');
    } catch (error) {
      console.error(error);
      !this.state.errorText && this.toggleError('form.incorrect');
      this.setState({ loading: false });
    }
  };

  toggleError = errorText => {
    this.setState({ errorText });
  };

  render() {
    const { translate } = this.props;
    return (
      <Form onSubmit={this.signUpSubmit} className="login_form">
        <Header name="form.signUp" />
        <FormItem>
          <Input value={this.state.email} disabled type="text" />
        </FormItem>
        <Row gutter={40}>
          <Col span={12}>
            <FormItemInput placeholder name="firstName" icon="user" required />
          </Col>
          <Col span={12}>
            <FormItemInput placeholder name="lastName" icon="user" required />
          </Col>
        </Row>
        <FormItemInput
          placeholder
          name="password"
          icon="lock"
          type="password"
          required
        />
        <FormItemInput
          placeholder
          required
          name="repeatPassword"
          icon="lock"
          type="password"
          validator={{
            func: this.checkPassword,
            message: 'unEqualPasswords',
          }}
        />
        <ErrorItem
          toggleError={this.toggleError}
          errorText={this.state.errorText}
        />
        <FormItem>
          <Button
            type="primary"
            htmlType="submit"
            className="blueBtn login_max_width"
          >
            {translate('signUp')}
          </Button>
          <FormItem>
            <Link to="/auth/login" className="login_forgot_link">
              {this.props.translate('goToLogin')}
            </Link>
          </FormItem>
        </FormItem>
      </Form>
    );
  }
}
