import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Select, Input, Button, Row, Col, Icon, message } from 'antd';
import { v1 as uuidv1 } from 'uuid';
// Services
import api from 'services/api';
import i18n from 'services/i18n';
import { ahvNumber } from 'services/validations';
// Actions
import { updateSimulatedPatientsPersonalInfo } from 'actions/simulatedPatients';
// Constants
import { WORK_PERMISSION, LANGUAGE_LEVEL } from 'constants/options';
// Components
import LazySelect from 'components/LazySelect';
import EditableField from 'components/EditableField';
import Card from '../Card';

const mapStateToProps = state => ({
  profile: state.simulatedPatients.profile,
});

const mapDispatchToProps = { updateSimulatedPatientsPersonalInfo };

@i18n('form')
@connect(
  mapStateToProps,
  mapDispatchToProps,
)
@Form.create()
export default class ProfessionalDetails extends Component {
  static propTypes = {
    profile: PropTypes.object.isRequired,
  };

  state = {
    editable: '',
  };

  editField = fieldName => this.setState({ editable: fieldName });

  stopEditField = () => this.setState({ editable: '' });

  handleLoadProfessions = data => {
    return api.getAllProfessions(data).then(res => res.data);
  };

  handleLoadLanguages = data => {
    return api.getAllLanguages(data).then(res => res.data);
  };

  onSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // We need some extra data in order to submit form
        const oldPersonalInfo = this.props.profile.patientPersonalInfo;
        // Convert "languages object" back to array before sending
        const languages = values.languages
          ? Object.keys(values.languages).map(key => values.languages[key])
          : [];
        // Create DTO object
        const data = {
          birthday: oldPersonalInfo.birthday,
          gender: oldPersonalInfo.gender,
          height: oldPersonalInfo.height,
          weight: oldPersonalInfo.weight,
          nationalityId: oldPersonalInfo.nationality.id,
          maritalStatus: oldPersonalInfo.maritalStatus,
          socialInsurance: values.socialInsurance,
          workPermission: values.workPermission,
          professionId: values.professionId,
          languageWithLevelRequest: languages,
        };
        // Submit the form
        this.props
          .updateSimulatedPatientsPersonalInfo(this.props.profile.id, data)
          .catch(error => message.error(this.props.translate('all', 'error')))
          .finally(() => {
            const { resetFields } = this.props.form;
            this.stopEditField();
            // This helps to get new initialValues for "keys array"
            // on next render -> see render method
            resetFields(['keys']);
          });
      }
    });
  };

  handleAddLanguage = () => {
    const { getFieldValue, setFieldsValue } = this.props.form;
    const id = uuidv1();
    const keys = getFieldValue('keys');

    setFieldsValue({
      keys: [...keys, { id, languageSkillLevel: '', language: {} }],
    });
  };

  handleRemoveLanguage = id => {
    const { getFieldValue, setFieldsValue } = this.props.form;
    const keys = getFieldValue('keys');
    const newKeys = keys.filter(k => k.id !== id);

    setFieldsValue({ keys: newKeys });
  };

  render() {
    const { getFieldDecorator, getFieldValue, getFieldError } = this.props.form;
    const { patientPersonalInfo = {} } = this.props.profile;

    // Prevent profession to be null
    if (!patientPersonalInfo.profession) {
      patientPersonalInfo.profession = {};
    }
    // Prevent languages to be null
    if (!patientPersonalInfo.languages) {
      patientPersonalInfo.languages = [];
    }

    // Because antd works poorly with "array as field"
    // we need to create intermediate array "keys", add or remove items from it
    // for every item in "keys array" there's corresponding item in "languages object"
    // stored by id {198746-das23: { languageId: 1, languageSkillLevel: 'C2' }}
    getFieldDecorator('keys', {
      initialValue: patientPersonalInfo.languages,
    });

    // Create languages fields
    const keys = getFieldValue('keys');
    const languages = (
      <EditableField
        help=""
        editable={this.state.editable === 'languages'}
        label={this.props.translate('languages')}
        value={
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {keys.map(key => {
              if (!key.language.id || !key.languageSkillLevel) {
                return null;
              }

              const langObj = LANGUAGE_LEVEL.find(
                lvl => lvl.value === key.languageSkillLevel,
              );

              return (
                <div key={key.language.id}>
                  {key.language.name}, {langObj.title}
                </div>
              );
            })}
          </div>
        }
        edit={() => this.editField('languages')}
      >
        <Row style={{ width: '100%' }}>
          <Col span={20}>
            {keys.map(k => (
              <Row key={k.id} gutter={4}>
                <Col span={11}>
                  {getFieldDecorator(`languages[${k.id}].languageId`, {
                    initialValue: k.language.id,
                    rules: [
                      {
                        required: true,
                        message: this.props.translate('insert'),
                      },
                    ],
                  })(<LazySelect loadData={this.handleLoadLanguages} />)}
                  <div style={{ color: 'red' }}>
                    {(
                      getFieldError(`languages[${k.id}].languageId`) || []
                    ).join(', ')}
                  </div>
                </Col>
                <Col span={11}>
                  {getFieldDecorator(`languages[${k.id}].languageSkillLevel`, {
                    initialValue: k.languageSkillLevel,
                    rules: [
                      {
                        required: true,
                        message: this.props.translate('insert'),
                      },
                    ],
                  })(
                    <Select style={{ width: '100%' }}>
                      {LANGUAGE_LEVEL.map(language => (
                        <Select.Option
                          key={language.value}
                          value={language.value}
                        >
                          {language.title}
                        </Select.Option>
                      ))}
                    </Select>,
                  )}
                  <div style={{ color: 'red' }}>
                    {(
                      getFieldError(`languages[${k.id}].languageSkillLevel`) ||
                      []
                    ).join(', ')}
                  </div>
                </Col>
                <Col span={2}>
                  <Icon
                    onClick={() => this.handleRemoveLanguage(k.id)}
                    type="delete"
                  />
                </Col>
              </Row>
            ))}
            <div className="card-editable-language">
              <span className="action-btn" onClick={this.handleAddLanguage}>
                <Icon type="plus" /> {this.props.translate('addLanguage')}
              </span>
            </div>
          </Col>
          <Col span={3} className="profile-card-btns-container">
            <Button
              shape="circle"
              icon="close"
              size="small"
              onClick={this.stopEditField}
            />
            <Button
              shape="circle"
              type="primary"
              icon="check"
              size="small"
              htmlType="submit"
              onClick={this.onSubmit}
            />
          </Col>
        </Row>
      </EditableField>
    );

    return (
      <Card title="professionalDetails">
        <Form onSubmit={this.onSubmit}>
          <EditableField
            editable={this.state.editable === 'profession'}
            label={this.props.translate('profession')}
            value={patientPersonalInfo.profession.name}
            edit={() => this.editField('profession')}
          >
            <React.Fragment>
              {getFieldDecorator('professionId', {
                initialValue: patientPersonalInfo.profession.id,
              })(<LazySelect loadData={this.handleLoadProfessions} />)}
              <div className="profile-card-btns-container">
                <Button
                  shape="circle"
                  icon="close"
                  size="small"
                  onClick={this.stopEditField}
                />
                <Button
                  shape="circle"
                  type="primary"
                  icon="check"
                  size="small"
                  htmlType="submit"
                  onClick={this.onSubmit}
                />
              </div>
            </React.Fragment>
          </EditableField>
          <EditableField
            editable={this.state.editable === 'workPermission'}
            label={this.props.translate('workPermission')}
            value={
              patientPersonalInfo.workPermission
                ? `${patientPersonalInfo.workPermission}-${this.props.translate(
                    'permission',
                    'patient',
                  )}`
                : ''
            }
            edit={() => this.editField('workPermission')}
          >
            <React.Fragment>
              {getFieldDecorator('workPermission', {
                initialValue: patientPersonalInfo.workPermission,
              })(
                <Select>
                  {WORK_PERMISSION.map(permission => (
                    <Select.Option
                      key={permission.value}
                      value={permission.value}
                    >
                      {permission.value}-
                      {this.props.translate('permission', 'patient')}
                    </Select.Option>
                  ))}
                </Select>,
              )}
              <div className="profile-card-btns-container">
                <Button
                  shape="circle"
                  icon="close"
                  size="small"
                  onClick={this.stopEditField}
                />
                <Button
                  shape="circle"
                  type="primary"
                  icon="check"
                  size="small"
                  htmlType="submit"
                  onClick={this.onSubmit}
                />
              </div>
            </React.Fragment>
          </EditableField>
          <EditableField
            editable={this.state.editable === 'socialInsurance'}
            label={this.props.translate('socialInsurance')}
            value={patientPersonalInfo.socialInsurance}
            edit={() => this.editField('socialInsurance')}
          >
            <React.Fragment>
              {getFieldDecorator('socialInsurance', {
                initialValue: patientPersonalInfo.socialInsurance,
                rules: [
                  {
                    validator: ahvNumber,
                    message: this.props.translate('notValidAHVNumber', 'error'),
                  },
                ],
              })(<Input />)}
              <div className="profile-card-btns-container">
                <Button
                  shape="circle"
                  icon="close"
                  size="small"
                  onClick={this.stopEditField}
                />
                <Button
                  shape="circle"
                  type="primary"
                  icon="check"
                  size="small"
                  htmlType="submit"
                  onClick={this.onSubmit}
                />
              </div>
            </React.Fragment>
          </EditableField>
          {languages}
        </Form>
      </Card>
    );
  }
}
