export const SP_SELECTION_REQUEST_START = 'SP_SELECTION_REQUEST_START';
export const FETCH_SELECTED_SPS_SUCCESS = 'FETCH_SELECTED_SPS_SUCCESS';
export const FETCH_SELECTED_SPS_ERROR = 'FETCH_SELECTED_SPS_ERROR';
export const REMOVE_SP_FROM_SEMESTER_SUCCESS =
  'REMOVE_SP_FROM_SEMESTER_SUCCESS';
export const FETCH_SELECTED_OSCE_DAYS_SUCCESS =
  'FETCH_SELECTED_OSCE_DAYS_SUCCESS';
export const REMOVE_SP_FROM_SEMESTER_ERROR = 'REMOVE_SP_FROM_SEMESTER_ERROR';
export const FETCH_SELECTED_OSCE_DAYS_ERROR = 'FETCH_SELECTED_OSCE_DAYS_ERROR';
export const SELECT_OSCE_DAY = 'SELECT_OSCE_DAY';
export const SELECT_OSCE_ROLE = 'SELECT_OSCE_ROLE';
export const AUTO_ASSING_SP_SUCCESS = 'AUTO_ASSING_SP_SUCCESS';
export const AUTO_ASSING_SP_ERROR = 'AUTO_ASSING_SP_ERROR';
export const ASSIGN_SP_FOR_HALF_DAY_SUCCESS = 'ASSIGN_SP_FOR_HALF_DAY_SUCCESS';
export const ASSIGN_SP_FOR_HALF_DAY_ERROR = 'ASSIGN_SP_FOR_HALF_DAY_ERROR';
export const CLEAR_SELECTED_ROLE = 'CLEAR_SELECTED_ROLE';
export const FILTER_SELECTED_SPS = 'FILTER_SELECTED_SPS';
export const ASSIGN_SP_TO_ROLE_SUCCESS = 'ASSIGN_SP_TO_ROLE_SUCCESS';
export const ASSIGN_SP_TO_ROLE_ERROR = 'ASSIGN_SP_TO_ROLE_ERROR';
