import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Icon, Popover } from 'antd';

import { classToFunc } from 'services/decorators/DOM';

export class WhiteBg extends PureComponent {
  static propTypes = {
    vSpace: PropTypes.bool,
    children: PropTypes.node,
  };

  render() {
    const { props } = this;
    return (
      <Fragment>
        <div className="checklist-white-td" />
        <div
          className={cn(['checklist-td-other', { 'v-space': props.vSpace }])}
        >
          {props.children}
        </div>
      </Fragment>
    );
  }
}

export const Option = props => (
  <div className="checklist-option" key={props.id}>
    <span className="checklist-text">{props.textDescription}</span>
    <span className="checklist-comment">{props.comment}</span>
  </div>
);

export const Values = props => (
  <div className="checklist-option" key={props.id}>
    <span className="checklist-text">{props.values}</span>
  </div>
);

export const CriteriaCount = props => (
  <div className="checklist-option" key={props.id}>
    <span className="checklist-text">{props.pointCount}</span>
  </div>
);

@classToFunc
export class BlueTag extends PureComponent {
  static propTypes = {
    text: PropTypes.string,
    comment: PropTypes.string,
  };

  render() {
    const { props } = this;
    return (
      <span className="checklist-tag">
        <span className="tag-name">{props.text}</span>
        {props.comment && (
          <Popover content={props.comment}>
            <Icon type="info-circle-o" />
          </Popover>
        )}
      </span>
    );
  }
}
