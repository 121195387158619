import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { get, findLast } from 'lodash';
import cn from 'classnames';

/** UI*/
import {
  Input,
  InputNumber,
  Modal,
  Button,
  Icon,
  Popover,
  Radio,
  Popconfirm,
  Spin,
  Tooltip,
} from 'antd';

/** Utils, Configs*/
import addLoadWithRedux from 'services/decorators/addLoadWithRedux';
import { msgError } from 'services/errorHandler';
import { calculateDiff } from 'services/format';

/** Actions*/
import {
  getOsceDays,
  getCircuit,
  createCircuit,
  getSequence,
  deleteSequence,
  changeCircuitColor,
  updateSequence,
  splitOsceDayIntoSequence,
  moveLunchBreak,
  addSequence,
} from 'actions/circuits';
import { changeDuration } from 'actions/schedule';

/** Components*/
import Carousel from 'components/Carousel';
import CircuitContext from './CircuitContext';
import Scissor from './ScissorIcon';

const RadioGroup = Radio.Group;

/**
 * Component for rendering sequences elements ( circuit accordion, circuit rotations etc.)
 * @reactProps {object} sequence - sequence detail ( number of rotations, label, id )
 * @reactProps {object} circuitData - data about circuit ( stations, id )
 * @reactProps {boolean} circuitLoaded - boolean value for tracking loading of data
 * @reactProps {string} circuitRealName - function for getting all available rooms
 * @reactProps {function} loadCircuits - function for getting sequences of circuit(s)
 */
@withRouter
@addLoadWithRedux({
  i18Name: 'circuit',
  runtimeReduxNames: [
    'osce',
    'schedule',
    props => ({ as: 'sequence', runtimeName: `sequence${props.osceDay.id}` }),
    props => ({ as: 'circuit', runtimeName: `circuit${props.sequence.id}` }),
  ],
  mapMethods: { getCircuit, getSequence, getOsceDays },
})
export default class Sequence extends Component {
  static propsTypes = {
    osceDay: PropTypes.object.isRequired,
    osceData: PropTypes.object.isRequired,
    scheduleData: PropTypes.object.isRequired,
    sequence: PropTypes.object.isRequired,
    sequenceData: PropTypes.object.isRequired,
    circuitData: PropTypes.object.isRequired,
    circuitLoaded: PropTypes.bool.isRequired,
    circuitRealName: PropTypes.bool.isRequired,
    loadCircuits: PropTypes.func,
  };

  static defaultProps = {};

  state = {
    showAddCircuit: false,
    typeAddCircuit: true,
    isLabelEditable: false,
    sequenceName: this.props.sequence.label,
    isLunchEditable: false,
    loading: false,
  };

  componentDidMount() {
    const {
      sequence: { id },
      getCircuit,
    } = this.props;
    if (id) {
      getCircuit(`circuit${id}`, id);
    }
  }

  get isManualOsce() {
    const { osceData } = this.props;
    return get(osceData, 'osceType') === 'MANUAL';
  }

  get isDisabled() {
    return (
      get(this.props.osceData, 'osceStatus') !== 'CIRCUITS CREATED' ||
      get(this.props.osceData, 'freezed') === true
    );
  }

  get hasTwoSequence() {
    return get(this.props.sequenceData, 'data.length') > 1;
  }

  get showPopoverCircuit() {
    return this.hasTwoSequence && this.props.sequenceData.data[1].name !== '';
  }

  get bodyForCarousel() {
    const {
      osceDay,
      sequence: { id },
    } = this.props;
    const { data = [] } = this.props.circuitData || {};
    const elements = data.filter(item => !item.circuitCopyId);
    return elements.map((circuit, index) => {
      const hasChildren =
        circuit.stationResponse.findIndex(
          item => item.stationType === 'SAMPLE',
        ) === -1;
      return {
        title: `${this.props.translate('circuit')} ${index + 1}`,
        color: circuit.circuitColor,
        isManualOsce: this.isManualOsce,
        onColorChange: this.changeColor(circuit),
        countChildren: hasChildren ? circuit.stationResponse.length : 0,
        osceSequenceId: id,
        circuitId: circuit.circuitId,
        Element: (
          <CircuitContext
            index={index}
            key={index}
            id={circuit.circuitId}
            osceDay={osceDay}
            osceSequenceId={id}
            elements={circuit.stationResponse}
          />
        ),
      };
    });
  }

  get leftSide() {
    const {
      sequence,
      circuitLoaded,
      sequence: { numberRotations },
      translate,
    } = this.props;
    const { isLabelEditable, sequenceName } = this.state;
    return (
      circuitLoaded && (
        <div className="osce-day_cut_wrapper">
          {isLabelEditable ? (
            <Input
              style={{
                width: '40px',
                textAlign: 'center',
              }}
              autoFocus
              defaultValue={sequenceName}
              onBlur={this.onBlur()}
              onPressEnter={this.onEnter}
              maxLength="2"
            />
          ) : (
            <div onClick={this.toggleEdit(true)}>{sequenceName}</div>
          )}
          {this.isManualOsce ? (
            <React.Fragment>
              <Popconfirm
                placement="bottom"
                title={translate('areYouSureDelete', 'confirm')}
                onConfirm={this.deleteSequence}
              >
                <Button
                  disabled={this.isDisabled || !this.showPopoverCircuit}
                  className="circuit_section_header_close"
                  icon="close"
                  shape="circle"
                  size="small"
                />
              </Popconfirm>
              <div className="osce-day_manual_btns-wrapper">
                <Button
                  onClick={this.moveLunch(true)}
                  disabled={
                    this.isDisabled ||
                    sequence.isMoveLunchSoonerDisabled ||
                    this.showPopoverCircuit
                  }
                >
                  <Icon type="arrow-up" />
                  {translate('breakSooner')}
                </Button>
                <Button
                  onClick={this.moveLunch(false)}
                  disabled={
                    this.isDisabled ||
                    sequence.isMoveLunchLaterDisabled ||
                    this.showPopoverCircuit
                  }
                  style={{ marginTop: '10px' }}
                >
                  <Icon type="arrow-down" />
                  {translate('breakLater')}
                </Button>
              </div>
              <Tooltip
                placement="top"
                title={this.props.translate('rotationCount', 'tooltips')}
              >
                <p>
                  <InputNumber
                    min={0}
                    disabled={this.isDisabled}
                    onBlur={this.changeRotation}
                    defaultValue={numberRotations}
                    style={{ width: '60px' }}
                  />
                </p>
              </Tooltip>
            </React.Fragment>
          ) : (
            this.splitButton
          )}
        </div>
      )
    );
  }

  get splitButton() {
    const {
      sequence: { numberRotations },
      osceDay: { isSplitDisabled },
    } = this.props;
    const show = !this.isDisabled && !isSplitDisabled;
    return (
      <React.Fragment>
        {show && (
          <Popconfirm
            placement="rightTop"
            title={this.props.translate('splitOsceDay')}
            onConfirm={this.splitOsceDay}
          >
            <Tooltip
              placement="top"
              title={this.props.translate('scissor', 'tooltips')}
            >
              <div className="osce-day_cut_scissor-wrapper">
                <Scissor />
              </div>
            </Tooltip>
          </Popconfirm>
        )}
        <Tooltip
          placement="top"
          title={this.props.translate('rotationCount', 'tooltips')}
        >
          <p>{numberRotations}</p>
        </Tooltip>
      </React.Fragment>
    );
  }

  get lunchBreak() {
    const { osceDay, sequenceData } = this.props;
    const { loading } = this.state;
    const timeSlot = findLast(
      osceDay.timeSlotResponses,
      item =>
        item.timeSlotType === 'LUNCH_BREAK' &&
        item.osceSequenceId === sequenceData.data[0].id,
    );
    return (
      <div className="osce-day_manual_break-duration">
        <Spin spinning={loading}>{this.lunchBreakContent(timeSlot)}</Spin>
      </div>
    );
  }

  lunchBreakContent = lunchBreak => {
    const duration = lunchBreak ? calculateDiff(lunchBreak) : '-';
    const { isLunchEditable } = this.state;
    return (
      <div className="osce-day_manual_break-duration-block">
        {this.props.translate('breakDuration')}
        <span className="osce-day_manual_break-duration-value">
          {isLunchEditable ? (
            <Input
              style={{
                width: '55px',
                textAlign: 'center',
              }}
              type="number"
              autoFocus
              defaultValue={duration}
              onBlur={this.onBlur(lunchBreak)}
              onPressEnter={this.changeLunch(lunchBreak)}
            />
          ) : (
            <span onClick={lunchBreak && this.toggleLunchEdit(true)}>
              {duration}
            </span>
          )}
        </span>
      </div>
    );
  };

  get addCircuitContent() {
    return (
      <span>
        {this.showPopoverCircuit ? (
          <Popover
            trigger="click"
            placement="right"
            content={this.contentCreateCircuit}
            onVisibleChange={this.handleVisibleChange}
            visible={this.state.showAddCircuit}
          >
            <Button
              type="dashed"
              disabled={this.isDisabled}
              className="osce-day_manual_add-circuit"
            >
              <Icon type="plus" /> {this.props.translate('addCircuit')}
            </Button>
          </Popover>
        ) : (
          <Button
            type="dashed"
            disabled={this.isDisabled}
            className="osce-day_manual_add-circuit"
            onClick={this.addCircuit(false)}
          >
            <Icon type="plus" /> {this.props.translate('addCircuit')}
          </Button>
        )}
      </span>
    );
  }

  changeLunch = lunchBreak => async e => {
    try {
      this.setState({ loading: true });
      const {
        match: { params },
        osceDay,
        updateData,
      } = this.props;
      const props = {
        osceDayId: osceDay.id,
        sequenceNumber: get(lunchBreak, 'sequenceNumber'),
        newDuration: get(e, 'target.value') || e,
      };
      await changeDuration(props);
      const { data } = await getOsceDays(params.id);
      updateData(data, 'osceDays');
      this.toggleLunchEdit(false)();
      this.setState({ loading: false });
    } catch (e) {
      msgError(e);
    }
  };

  moveLunch = sooner => async () => {
    try {
      const { sequence, osceData, osceDay, updateData } = this.props;
      await moveLunchBreak(sequence.id, { sooner });
      const sequenceData = await getSequence(osceDay.id);
      updateData(sequenceData.data, `sequence${osceDay.id}`);
      const daysData = await getOsceDays(osceData.id);
      updateData(daysData.data, 'osceDays');
    } catch (e) {
      msgError(e);
    }
  };

  deleteSequence = async () => {
    try {
      const {
        sequence: { id },
        osceDay,
      } = this.props;
      await deleteSequence(id);
      this.props.getSequence(`sequence${osceDay.id}`, osceDay.id);
    } catch (err) {
      msgError();
    }
  };

  changeColor = circuit => async (id, circuitColor) => {
    const { updateAllByPattern } = this.props;
    const { circuitId, positionInOsceSequence } = circuit;
    await changeCircuitColor(circuitId, { color: circuitColor });
    updateAllByPattern(
      /^circuit[0-9]+Loading$/,
      { positionInOsceSequence },
      { circuitColor },
    );
  };

  toggleEdit = isLabelEditable => () =>
    !this.isDisabled && this.setState({ isLabelEditable });

  toggleLunchEdit = isLunchEditable => () =>
    this.isManualOsce && !this.isDisabled && this.setState({ isLunchEditable });

  onBlur = lunchBreak => e => {
    const { value } = e.target;
    if (lunchBreak || (!lunchBreak && value !== this.state.sequenceName)) {
      Modal.confirm({
        content: 'You have unsaved data, do you want to save it?',
        onOk: () =>
          lunchBreak
            ? this.changeLunch(lunchBreak)(value)
            : this.changeSequenceName(value),
      });
    }
  };

  onEnter = e => {
    this.changeSequenceName(e.target.value);
    this.toggleEdit(false)();
  };

  splitOsceDay = async () => {
    const {
      osceDay: { id },
      loadCircuits,
      getOsceDays,
      match: { params },
    } = this.props;
    await splitOsceDayIntoSequence(id);
    getOsceDays('osceDays', params.id);
    loadCircuits();
  };

  changeSequenceName = label => {
    const { id } = this.props.sequence;
    this.setState({ sequenceName: label }, () => {
      updateSequence(id, { label });
    });
    this.toggleEdit(false)();
  };

  changeRotation = async e => {
    const numberRotations = e.target.value;
    const {
      osceDay,
      sequenceData,
      sequence: { id },
    } = this.props;
    const { data } = await updateSequence(id, { numberRotations });
    const newData = [...sequenceData.data];
    const targetIndex = newData.findIndex(item => item.id === id);
    newData.splice(targetIndex, 1, data);
    this.props.updateData({ data: newData }, `sequence${osceDay.id}`);
  };

  addCircuit = copyToSequences => async () => {
    try {
      const {
        osceDay,
        sequence: { id },
        getSequence,
        sequenceData,
        updateData,
      } = this.props;
      if (id) {
        const { typeAddCircuit } = this.state;
        await createCircuit(id, {
          color: 'ORANGE',
          copyBreak: typeAddCircuit,
          copyToSequences,
        });
        sequenceData.data.forEach(async sequence => {
          if (sequence.id) {
            const { data } = await getCircuit(sequence.id);
            updateData({ data: data.data }, `circuit${sequence.id}`);
          }
        });
      } else {
        await addSequence(osceDay.id);
        await getSequence(`sequence${osceDay.id}`, osceDay.id);
      }
      this.handleVisibleChange(false);
    } catch (err) {
      msgError(err);
    }
  };

  handleVisibleChange = visible =>
    this.setState({ showAddCircuit: visible, typeAddCircuit: true });

  onChangeType = e => this.setState({ typeAddCircuit: e.target.value });

  get contentCreateCircuit() {
    const radioStyle = {
      display: 'block',
      height: '25px',
      lineHeight: '25px',
      fontWeight: '500',
    };
    return (
      <React.Fragment>
        <RadioGroup
          onChange={this.onChangeType}
          value={this.state.typeAddCircuit}
        >
          <p>Do you want to copy this circuit to all OSCE sequence?</p>
          <Radio style={radioStyle} value>
            Copy with break post
          </Radio>
          <Radio style={radioStyle} value={false}>
            Copy without break post
          </Radio>
        </RadioGroup>
        <div className="panel_btns">
          <Button onClick={this.addCircuit(false)}>No</Button>
          <Button type="primary" onClick={this.addCircuit(true)}>
            Yes, copy
          </Button>
        </div>
      </React.Fragment>
    );
  }

  render() {
    const { sequence } = this.props;
    return (
      <React.Fragment>
        <div
          className={cn('osce-day_sequence', {
            'osce-day_manual_sequence': this.isManualOsce,
          })}
        >
          {get(sequence, 'id') && this.leftSide}
          {this.isManualOsce && this.addCircuitContent}
          <Carousel
            openFirst
            onColorChange={this.changeColor}
            elements={this.bodyForCarousel}
            withColorPicker={!this.isDisabled}
          />
        </div>
        {sequence.positionInOsceDay === 1 &&
          this.hasTwoSequence &&
          this.lunchBreak}
      </React.Fragment>
    );
  }
}
