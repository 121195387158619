import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Col, Input, Button } from 'antd';

/* Services */
import i18n from 'services/i18n';

@i18n('form')
@Form.create({})
export default class AddRole extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  handleFormSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSubmit(values);
      }
    });
  };

  handleFormCancel = e => {
    this.props.form.resetFields();
    this.props.onClose();
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleFormSubmit}>
        <div className="form_content">
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item label={this.props.translate('acronym')}>
                {getFieldDecorator('acronym', {
                  rules: [
                    {
                      required: true,
                      message: this.props.translate('insert', 'error'),
                    },
                  ],
                })(<Input type="text" />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                required
                label={this.props.translate('roleDesignation')}
              >
                {getFieldDecorator('roleDesignation', {
                  rules: [
                    {
                      required: true,
                      message: this.props.translate('insert', 'error'),
                    },
                  ],
                })(<Input type="text" />)}
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className="ant-modal-footer footer_max_width">
          <Button onClick={this.handleFormCancel}>
            {this.props.translate('cancel')}
          </Button>
          <Button htmlType="submit" className="blueBtn">
            {this.props.translate('save')}
          </Button>
        </div>
      </Form>
    );
  }
}
