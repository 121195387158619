import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Icon, Popconfirm, message } from 'antd';
// Services
import i18n from 'services/i18n';
// Actions
import { updateSkill, deleteSkill } from 'actions/discipline';
// Components
import EditDragTable from 'components/EditDragTable';
import AddSkill from '../AddSkill';

const mapStateToProps = (state, ownProps) => ({
  skills: state.discipline.skillsByDiscipline[ownProps.disciplineId] || [],
});

const mapDispatchToProps = {
  updateSkill,
  deleteSkill,
};

@i18n('skillInDisciplineColumns')
@connect(
  mapStateToProps,
  mapDispatchToProps,
)
class Skills extends Component {
  static propTypes = {
    disciplineId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    skills: PropTypes.array.isRequired,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);

    this.columns = [
      {
        title: props.translate('skill'),
        dataIndex: 'name',
        width: '35%',
      },
      {
        title: props.translate('studyYear'),
        dataIndex: 'studyYear',
        width: '25%',
      },
      {
        dataIndex: '',
        width: '370px',
        className: 'table-button_centered',
        render: (text, record, index, tableInstance, Context) => {
          const { editingKey } = tableInstance.state;
          // If skill has assigned role to it
          const isDeleteDisable = record.roles.length > 0;

          // If row is not in edit mode
          return (
            <div className="editable-row-operations">
              <span>
                <a
                  className="action-btn"
                  disabled={editingKey !== ''}
                  onClick={e => {
                    e.stopPropagation();
                    this.handleOpenSkillModal(record);
                  }}
                >
                  <Icon type="edit" /> {this.props.translate('edit', 'form')}
                </a>
                <Popconfirm
                  title={this.props.translate('areYouSureDelete', 'confirm')}
                  disabled={isDeleteDisable}
                  onClick={e => e.stopPropagation()}
                  onCancel={e => e.stopPropagation()}
                  onConfirm={e => {
                    e.stopPropagation();
                    this.handleDeleteSkill(record);
                  }}
                >
                  <a disabled={isDeleteDisable} className="action-btn">
                    <Icon type="delete" />{' '}
                    {this.props.translate('delete', 'form')}
                  </a>
                </Popconfirm>
              </span>
            </div>
          );
        },
      },
    ];

    this.state = {
      isEditSkillModalOpen: false,
      skillName: '',
      skillId: null,
      studyYear: '',
    };
  }

  handleOpenSkillModal = skill => {
    this.setState({
      isEditSkillModalOpen: true,
      skillName: skill.name,
      skillId: skill.id,
      studyYear: skill.studyYear,
    });
  };

  handleCloseSkillModal = e => this.setState({ isEditSkillModalOpen: false });

  handleUpdateSkill = fields => {
    const { skillId } = this.state;
    this.props.updateSkill(skillId, fields);
  };

  handleDeleteSkill = skill => {
    this.props
      .deleteSkill(skill.discipline.id, skill.id)
      .catch(err => message.error(this.props.translate('all', 'error')));
  };

  render() {
    const dataSource = this.props.skills.map(skill => ({
      ...skill,
      key: skill.id,
    }));

    return (
      <React.Fragment>
        {/* Component with a key https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#preferred-solutions */}
        <AddSkill
          key={this.props.disciplineId}
          title={this.props.translate('editSkill', 'disciplineColumns')}
          skillName={this.state.skillName}
          studyYear={this.state.studyYear}
          disciplineId={this.props.disciplineId}
          isVisible={this.state.isEditSkillModalOpen}
          onClose={this.handleCloseSkillModal}
          onSubmit={this.handleUpdateSkill}
        />
        <div className="checklist-expanded-row">
          <EditDragTable
            columns={this.columns}
            dataSource={dataSource}
            onSave={() => {}}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default Skills;
