import qs from 'qs';
import { setKey } from './adaptFunctions';
import dispatchSend from '../services/dispatchSend';
import { wrapRequest } from '../utils/ajax';
import {
  administratorsCRUD,
  roomCRUD,
  studentCRUD,
  semesterCRUD,
  catalogCRUD,
} from './cruds';

export const getAdministrators = () =>
  dispatchSend('administrators', administratorsCRUD.getList(), {
    adaptData: setKey,
  });

export const getStudentProfile = id =>
  dispatchSend('studentProfile', studentCRUD.get(id));

export const getRooms = () =>
  dispatchSend('rooms', roomCRUD.getList(), {
    adaptData: setKey,
  });

export const getSemesters = () =>
  dispatchSend('semesters', semesterCRUD.getList(), {
    adaptData: setKey,
  });

export const getCatalogs = () =>
  dispatchSend('catalogs', catalogCRUD.getList(), {
    adaptData: setKey,
  });

export const updateCatalogs = () =>
  dispatchSend('catalogs', catalogCRUD.getList({ additionalUrl: 'import' }), {
    adaptData: setKey,
  });

export const changeStatusCatalog = (id, data) =>
  wrapRequest({
    method: 'PUT',
    url: `/catalog/${id}`,
    data,
  });

export const printStudent = (id, params) =>
  wrapRequest({
    method: 'POST',
    url: `/student/${id}/print`,
    params,
    paramsSerializer: data => qs.stringify(data, { arrayFormat: 'repeat' }),
    responseType: 'arraybuffer',
  });
