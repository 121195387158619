import { combineReducers } from 'redux';
import {
  CHANGE_DISCIPLINES_START,
  FETCH_DISCIPLINES_SUCCESS,
  FETCH_DISCIPLINES_ERROR,
  LOAD_MORE_DISCIPLINES_SUCCESS,
  CREATE_DISCIPLINE_SUCCESS,
  CREATE_DISCIPLINE_ERROR,
  DELETE_DISCIPLINE_SUCCESS,
  DELETE_DISCIPLINE_ERROR,
  UPDATE_DISCIPLINE_SUCCESS,
  UPDATE_DISCIPLINE_ERROR,
  UPDATE_SKILL_SUCCESS,
  UPDATE_SKILL_ERROR,
  DELETE_SKILL_SUCCESS,
  DELETE_SKILL_ERROR,
} from 'constants/discipline';

const loading = (state = false, action) => {
  switch (action.type) {
    case CHANGE_DISCIPLINES_START:
      return true;

    case FETCH_DISCIPLINES_SUCCESS:
    case FETCH_DISCIPLINES_ERROR:
    case LOAD_MORE_DISCIPLINES_SUCCESS:
    case CREATE_DISCIPLINE_SUCCESS:
    case CREATE_DISCIPLINE_ERROR:
    case DELETE_DISCIPLINE_SUCCESS:
    case DELETE_DISCIPLINE_ERROR:
    case UPDATE_DISCIPLINE_SUCCESS:
    case UPDATE_DISCIPLINE_ERROR:
    case UPDATE_SKILL_SUCCESS:
    case UPDATE_SKILL_ERROR:
    case DELETE_SKILL_SUCCESS:
    case DELETE_SKILL_ERROR:
      return false;

    default:
      return state;
  }
};

const errors = (state = {}, action) => {
  switch (action.type) {
    case FETCH_DISCIPLINES_ERROR:
    case CREATE_DISCIPLINE_ERROR:
    case DELETE_DISCIPLINE_ERROR:
    case UPDATE_DISCIPLINE_ERROR:
    case UPDATE_SKILL_ERROR:
    case DELETE_SKILL_ERROR:
      return action.error;

    default:
      return state;
  }
};

const data = (state = [], action) => {
  switch (action.type) {
    case FETCH_DISCIPLINES_SUCCESS:
      return action.payload.disciplines.data;

    case LOAD_MORE_DISCIPLINES_SUCCESS:
      return state.concat(action.payload.disciplines.data);

    case CREATE_DISCIPLINE_SUCCESS:
      return state.concat(action.payload.discipline);

    case UPDATE_DISCIPLINE_SUCCESS: {
      const { discipline } = action.payload;
      return state.map(d => (d.id === discipline.id ? discipline : d));
    }

    case DELETE_DISCIPLINE_SUCCESS:
      return state.filter(d => d.id !== action.payload.id);

    default:
      return state;
  }
};

const skillsByDiscipline = (state = {}, action) => {
  switch (action.type) {
    case FETCH_DISCIPLINES_SUCCESS: {
      const newState = action.payload.disciplines.data.reduce(
        (newState, discipline) => ({
          ...newState,
          [discipline.id]: discipline.skills,
        }),
        {},
      );
      return newState;
    }

    case LOAD_MORE_DISCIPLINES_SUCCESS: {
      const oldState = { ...state };
      const newState = action.payload.disciplines.data.reduce(
        (newState, discipline) => ({
          ...newState,
          [discipline.id]: discipline.skills,
        }),
        {},
      );

      return { ...oldState, ...newState };
    }

    case CREATE_DISCIPLINE_SUCCESS: {
      const { discipline } = action.payload;
      const newState = { ...state, [discipline.id]: [] };

      return newState;
    }

    case DELETE_DISCIPLINE_SUCCESS: {
      const newState = { ...state };
      delete newState[action.payload.id];

      return newState;
    }

    case UPDATE_SKILL_SUCCESS: {
      const { skill } = action.payload;
      const disciplineId = skill.discipline.id;
      const newSkills = state[disciplineId].map(s =>
        s.id === skill.id ? { ...skill } : s,
      );
      const newState = { ...state, [disciplineId]: newSkills };

      return newState;
    }

    case DELETE_SKILL_SUCCESS: {
      const { disciplineId, skillId } = action.payload;
      const newSkills = state[disciplineId].filter(s => s.id !== skillId);
      const newState = { ...state, [disciplineId]: newSkills };

      return newState;
    }

    default:
      return state;
  }
};

const discipline = combineReducers({
  loading,
  data,
  errors,
  skillsByDiscipline,
});

export default discipline;
