import React from 'react';
import { LocaleProvider } from 'antd';
import { connect } from 'react-redux';
import { addLocaleData, IntlProvider } from 'react-intl';
import moment from 'moment';

import antdEn from 'antd/lib/locale-provider/en_US';
import antdDe from 'antd/lib/locale-provider/de_DE';
import enLocal from 'react-intl/locale-data/en';
import deLocal from 'react-intl/locale-data/de';
import 'moment/locale/de';

import en from '../locales/en';
import de from '../locales/de';
import defaults from '../locales/defaults';

addLocaleData([...enLocal, ...deLocal]);

const Localization = ({ lang, children }) => {
  let appLocale = { ...defaults };
  let antdLoc;

  if (lang === 'de') {
    appLocale = { ...appLocale, ...de };
    antdLoc = antdDe;
    moment.locale('de');
  } else if (lang === 'constantName') {
    appLocale = { ...appLocale };
    antdLoc = antdEn;
    moment.locale('en');
  } else {
    appLocale = { ...appLocale, ...en };
    antdLoc = antdEn;
    moment.locale('en');
  }

  return (
    <LocaleProvider locale={antdLoc}>
      <IntlProvider {...appLocale}>{children}</IntlProvider>
    </LocaleProvider>
  );
};

const mapStateToProps = ({ localization: { lang } }) => ({ lang });

export default connect(mapStateToProps)(Localization);
