import React from 'react';
import PropTypes from 'prop-types';

/** Components */
import TimeSlotData from './TimeSlotData';

const hasEndBreak = ({ earlyEnd, hasCoffeeBreaks, slotPosition, isSpPaused }) =>
  earlyEnd &&
  (isSpPaused ||
    (!isSpPaused &&
      (!hasCoffeeBreaks || (hasCoffeeBreaks && slotPosition === 'last'))));

const hasStartBreak = ({
  earlyStart,
  hasCoffeeBreaks,
  slotPosition,
  isSpPaused,
}) =>
  !earlyStart &&
  (isSpPaused ||
    (!isSpPaused &&
      (!hasCoffeeBreaks || (hasCoffeeBreaks && slotPosition === 'first'))));

const DoubleStation = props =>
  [
    { name: 'students', value: 'studentAssignments' },
    { name: 'simulated-patient', value: 'selectedSpAssignments' },
    { name: 'examiner', value: 'examinerAssignments' },
  ].map(item => (
    <TimeSlotData
      {...props}
      name={item.name}
      data={props[item.value]}
      hasEndBreak={hasEndBreak(props)}
      hasStartBreak={hasStartBreak(props)}
    />
  ));

DoubleStation.propTypes = {
  rotations: PropTypes.array,
  stationId: PropTypes.number,
  earlyStart: PropTypes.bool,
  earlyEnd: PropTypes.bool,
  isSpPaused: PropTypes.bool,
  hasCoffeeBreaks: PropTypes.bool,
  slotPosition: PropTypes.string,
  positionInStation: PropTypes.number,
  studentAssignments: PropTypes.array,
  selectedSpAssignments: PropTypes.array,
  examinerAssignment: PropTypes.array,
};

export default DoubleStation;
