import React, { Component } from 'react';
import { connect } from 'react-redux';
import UrlPattern from 'url-pattern';
import { get } from 'lodash';
import { Link } from 'react-router-dom';

import { Menu, Layout } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import SemesterSidebar from 'pages/osceAdministration/osce/semestersSidebar';

import { DEFAULT_DESKTOP_SIZE } from 'constants/common';

import './sidebar.less';

const { Sider } = Layout;

const mapStateToProps = ({ localization: { lang }, runtime }) => ({
  lang,
  semesterId: get(runtime, 'semesterIdData'),
});

@injectIntl
@connect(mapStateToProps)
export default class Sidebar extends Component {
  getKey = item => {
    if (
      item.patterns &&
      item.patterns.some(pattern =>
        new UrlPattern(pattern).match(window.location.pathname),
      )
    ) {
      return window.location.pathname; // eslint-disable-line
    }
    return false;
  };

  renderMenuElement = item => (
    <Menu.Item key={this.getKey(item) || item.url} disabled={item.disabled}>
      <Link
        to={
          item.changeId
            ? item.changeId(item.url, this.props.semesterId)
            : item.url
        }
      >
        <FormattedMessage id={`${this.props.nameMenu}.${item.name}`} />
      </Link>
    </Menu.Item>
  );

  get renderMenu() {
    const { menu = [], multipleMenus = [], intl, nameMenu } = this.props;
    if (!multipleMenus.length) {
      return menu.map(this.renderMenuElement);
    }
    return multipleMenus.map(item => (
      <Menu.ItemGroup
        title={intl.formatMessage({ id: `${nameMenu}.${item.menuHeader}` })}
        key={item.menuHeader}
      >
        {item.elements.map(this.renderMenuElement)}
      </Menu.ItemGroup>
    ));
  }

  render() {
    const widthSidebar = window.screen.width < DEFAULT_DESKTOP_SIZE ? 200 : 230;

    return (
      <Sider className="sidebar" width={widthSidebar}>
        <Menu
          mode="inline"
          selectedKeys={[window.location.pathname]}
          style={{ height: '100%' }}
        >
          {this.renderMenu}
          <Menu.Item key="semesters">
            {this.props.nameMenu === 'osceAdministration' && (
              <SemesterSidebar />
            )}
          </Menu.Item>
        </Menu>
      </Sider>
    );
  }
}
