import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Services
import i18n from 'services/i18n';

// Styles
import './styles.less';

@i18n('form')
class Filter extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    translate: PropTypes.func.isRequired, // this comes from i18n
    children: PropTypes.func.isRequired,
  };

  render() {
    if (!this.props.open) return null;

    return (
      <div className="criteria">
        <div className="criteria_panel_btns">
          {this.props.children(this.props.translate)}
        </div>
      </div>
    );
  }
}

export default Filter;
