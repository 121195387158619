import api from 'services/api';
import {
  OSCE_REQUEST_START,
  FETCH_OSCE_RESULTS_SUCCESS,
  FETCH_OSCE_RESULTS_ERROR,
  LOAD_MORE_RESULTS_SUCCESS,
  FETCH_OSCE_BELL_SYSTEM_SUCCESS,
  FETCH_OSCE_BELL_SYSTEM_ERROR,
  UPDATE_OSCE_BELL_SYSTEM_SUCCESS,
  UPDATE_OSCE_BELL_SYSTEM_ERROR,
} from 'constants/osce';

export const getOsceStudentsResults = (osceId, data) => async dispatch => {
  try {
    dispatch({ type: OSCE_REQUEST_START });
    const response = await api.getOsceStudentsResults(osceId, data);
    const results = response.data;
    dispatch({ type: FETCH_OSCE_RESULTS_SUCCESS, payload: { results } });
    return results;
  } catch (error) {
    dispatch({ type: FETCH_OSCE_RESULTS_ERROR, error });
    throw error;
  }
};

export const lazyLoadResults = (osceId, data) => async dispatch => {
  try {
    dispatch({ type: OSCE_REQUEST_START });
    const response = await api.getOsceStudentsResults(osceId, data);
    const results = response.data;
    dispatch({ type: LOAD_MORE_RESULTS_SUCCESS, payload: { results } });
    return results;
  } catch (error) {
    dispatch({ type: FETCH_OSCE_RESULTS_ERROR, error });
    throw error;
  }
};

export const getOsceBellSystem = osceId => async dispatch => {
  try {
    dispatch({ type: OSCE_REQUEST_START });
    const response = await api.getOsceBellSystem(osceId);
    const bellSystem = response.data;
    dispatch({ type: FETCH_OSCE_BELL_SYSTEM_SUCCESS, payload: { bellSystem } });
    return bellSystem;
  } catch (error) {
    dispatch({ type: FETCH_OSCE_BELL_SYSTEM_ERROR, error });
    throw error;
  }
};

export const updateOsceBellSystem = osceId => async dispatch => {
  try {
    dispatch({ type: OSCE_REQUEST_START });
    const response = await api.updateOsceBellSystem(osceId);
    const bellSystem = response.data;
    dispatch({
      type: UPDATE_OSCE_BELL_SYSTEM_SUCCESS,
      payload: { bellSystem },
    });
    return bellSystem;
  } catch (error) {
    dispatch({ type: UPDATE_OSCE_BELL_SYSTEM_ERROR, error });
    throw error;
  }
};
