import React, { Component } from 'react';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';

import i18n from 'services/i18n';
import { GENDER } from 'constants/options';
import { disciplineCRUD, clinicCRUD } from 'actions/cruds';
import { phoneNumber } from 'services/validations';
import FormItemInput from 'components/Form/formInput';
import EditableLabelDropDown from 'components/Form/formEditableLabelDropDown';
import ErrorItem from 'components/Form/formError';

import 'components/Form/formsStyles.less';

/**
 * Personal Info Tab on add new examiner
 * @reactProps {func} translate - Translate method.
 */
@i18n('form')
export default class PersonalInfo extends Component {
  static contextTypes = {
    submit: PropTypes.func,
    toggleError: PropTypes.func,
    errorText: PropTypes.string,
  };

  render() {
    const { translate } = this.props;
    return (
      <div className="form_content">
        <Row gutter={20}>
          <Col span={12}>
            <FormItemInput
              name="firstName"
              required
              label={translate('firstName')}
              focus
            />
          </Col>
          <Col span={12}>
            <FormItemInput
              name="lastName"
              required
              label={translate('lastName')}
            />
          </Col>
        </Row>
        <EditableLabelDropDown
          name="examinerPersonalInfo.gender"
          options={GENDER}
          onlyDropDown
          label={translate('gender')}
          placeholder={translate('selectGender', 'placeholder')}
          fullWidth
          withoutLabel
        />
        <FormItemInput
          name="examinerPersonalInfo.academicTitle"
          label={translate('title')}
          placeholderWithoutTranslate={translate(
            'academicTitle',
            'placeholder',
          )}
        />
        <EditableLabelDropDown
          search
          name="examinerPersonalInfo.discipline"
          getList={disciplineCRUD.getList}
          onlyDropDown
          label={translate('discipline')}
          placeholder={translate('selectDiscipline', 'placeholder')}
          fullWidth
          withoutLabel
        />
        <FormItemInput
          name="examinerContactDetails.email"
          label={translate('email')}
          type="email"
        />
        <FormItemInput
          name="examinerContactDetails.telephone"
          label={translate('telephone')}
          validator={{
            func: phoneNumber,
            message: 'notValidPhoneNumber',
          }}
        />
        <EditableLabelDropDown
          search
          name="examinerContactDetails.clinic"
          label={translate('clinic')}
          getList={clinicCRUD.getList}
          placeholder={translate('selectClinic', 'placeholder')}
          onlyDropDown
          getPopupContainer={() =>
            document.getElementsByClassName('ant-modal examiner_create-new')[0]
          }
          fullWidth
          withoutLabel
        />
        <ErrorItem
          toggleError={this.context.toggleError}
          errorText={this.context.errorText}
        />
      </div>
    );
  }
}
