import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get, omit } from 'lodash';
import { withRouter } from 'react-router';

/** UI*/
import { Popconfirm, Button, Form } from 'antd';

/** Actions */
import { updateOsce, calculateManualOsce } from 'actions/osceAdministration';
import { clearAllCircuit, getOsceDays, getSequence } from 'actions/circuits';
import { generateSchedule } from 'actions/schedule';

/** Services*/
import { msgError } from 'services/errorHandler';
import addLoadWithRedux from 'services/decorators/addLoadWithRedux';
import formDecorate from 'services/decorators/formDecorate';

/** Components*/
import TablePanel from 'components/TablePanel/index';
import OsceDaysWrapper from 'components/OsceDay';
import BreakComponent from './BreakComponent';

/**
 * Circuit Component which render osce days
 * @reactProps {func} translate - function which translate text.
 * @reactProps {string} semesterId
 * @reactProps {string} id - OSCE id
 */
@withRouter
@formDecorate()
@addLoadWithRedux({
  i18Name: 'circuit',
  runtimeReduxNames: ['osce', 'osceDays'],
})
export default class Circuit extends Component {
  static propsTypes = {
    translate: PropTypes.func.isRequired,
    osceData: PropTypes.object,
    osceDaysData: PropTypes.object,
  };

  static childContextTypes = {
    disabled: PropTypes.bool,
  };

  state = { calculating: false };

  getChildContext() {
    return {
      disabled:
        get(this.props.osceData, 'osceStatus') !== 'CIRCUITS CREATED' ||
        get(this.props.osceData, 'freezed') === true,
    };
  }

  get isDisabled() {
    return (
      get(this.props.osceData, 'osceStatus') !== 'CIRCUITS CREATED' ||
      get(this.props.osceData, 'freezed') === true
    );
  }

  createSchedule = async () => {
    try {
      const {
        osceData,
        match: {
          params: { semesterId, id },
        },
      } = this.props;
      await generateSchedule(id);
      this.props.updateData(
        { ...osceData, osceStatus: 'SCHEDULE CREATED' },
        'osce',
      );
      this.props.history.push(
        `/osce-administration/${semesterId}/osce/schedule/${id}`,
      );
    } catch (err) {
      msgError(err);
    }
  };

  clearAll = async () => {
    try {
      const {
        props,
        props: {
          match: {
            params: { id, semesterId },
          },
        },
      } = this;
      await clearAllCircuit(id);
      props.updateData({ data: [] }, 'osceDays');
      props.updateData(
        { ...props.osceData, osceStatus: 'OSCE CREATED' },
        'osce',
      );
      this.props.history.push(
        `/osce-administration/${semesterId}/osce/basic-data/${id}`,
      );
    } catch (err) {
      msgError(err);
    }
  };

  calculate = async values => {
    try {
      this.setState({ calculating: true });
      const { osceData, updateData } = this.props;
      const { data } = await updateOsce({ ...osceData, ...values });
      const newData = omit(data, ['osceStatus']);
      await calculateManualOsce(osceData.id);
      updateData({ ...osceData, ...newData }, 'osce');
      const days = await getOsceDays(osceData.id);
      updateData(days.data, 'osceDays');
      days.data.data.forEach(async day => {
        const sequenceData = await getSequence(day.id);
        updateData(sequenceData.data, `sequence${day.id}`);
      });
    } catch (e) {
      msgError(e);
    } finally {
      this.setState({ calculating: false });
    }
  };

  get onSubmit() {
    return this.props.wrapSubmit(values => this.calculate(values));
  }

  render() {
    const { translate, osceData, osceDaysData } = this.props;
    return (
      <OsceDaysWrapper withColorPicker>
        <TablePanel>
          <Button
            type="primary"
            className="table-panel_btn"
            onClick={this.createSchedule}
            disabled={get(osceData, 'osceStatus') !== 'CIRCUITS CREATED'}
          >
            {translate('generate')}
          </Button>
          <Popconfirm
            placement="bottom"
            title={translate('areYouSureClearAllCircuit')}
            onConfirm={this.clearAll}
          >
            <Button
              className="table-panel_btn table-panel_btn-right"
              style={{ marginRight: '0' }}
              disabled={this.isDisabled}
            >
              {translate('clearAll', 'form')}
            </Button>
          </Popconfirm>
          {get(osceData, 'osceType') === 'MANUAL' && (
            <Button
              form="breakForm"
              key="submit"
              htmlType="submit"
              loading={this.state.calculating}
              disabled={this.isDisabled}
              style={{ marginRight: '10px' }}
              className="table-panel_btn table-panel_btn-right"
            >
              {translate('calculate')}
            </Button>
          )}
        </TablePanel>
        {get(osceData, 'osceType') === 'MANUAL' && (
          <Form id="breakForm" onSubmit={this.onSubmit} layout="horizontal">
            <BreakComponent data={osceData} days={osceDaysData} />
          </Form>
        )}
      </OsceDaysWrapper>
    );
  }
}
