export const CHANGE_QUESTIONNAIRE_START = 'CHANGE_QUESTIONNAIRE_START';
export const GET_QUESTIONNAIRE_SUCCESS = 'GET_QUESTIONNAIRE_SUCCESS';
export const GET_QUESTIONNAIRE_ERROR = 'GET_QUESTIONNAIRE_ERROR';
export const CREATE_SECTION_SUCCESS = 'CREATE_SECTION_SUCCESS';
export const CREATE_SECTION_ERROR = 'CREATE_SECTION_ERROR';
export const CHANGE_QUEUE_SECTION_SUCCESS = 'CHANGE_QUEUE_SECTION_SUCCESS';
export const CHANGE_QUEUE_SECTION_ERROR = 'CHANGE_QUEUE_SECTION_ERROR';
export const CHANGE_QUEUE_QUESTIONS_SUCCESS = 'CHANGE_QUEUE_QUESTIONS_SUCCESS';
export const CHANGE_QUEUE_QUESTIONS_ERROR = 'CHANGE_QUEUE_QUESTIONS_ERROR';
export const UPDATE_SECTION_SUCCESS = 'UPDATE_SECTION_SUCCESS';
export const UPDATE_SECTION_ERROR = 'UPDATE_SECTION_ERROR';
export const DELETE_SECTION_SUCCESS = 'DELETE_SECTION_SUCCESS';
export const DELETE_SECTION_ERROR = 'DELETE_SECTION_ERROR';
export const CREATE_NEW_QUESTION_SUCCESS = 'CREATE_NEW_QUESTION_SUCCESS';
export const CREATE_NEW_QUESTION_ERROR = 'CREATE_NEW_QUESTION_ERROR';
export const DELETE_QUESTION_SUCCESS = 'DELETE_QUESTION_SUCCESS';
export const DELETE_QUESTION_ERROR = 'DELETE_QUESTION_ERROR';
