import React, { Component } from 'react';
import { Button, Spin } from 'antd';
import { get, omit } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';

import ModalForm from 'components/ModalForm/index';
import TablePanel from 'components/TablePanel/index';
import Table from 'components/Table/index';
import i18nTable from 'services/i18nTable';
import addLoad from 'services/decorators/addLoad';

import { setKey } from 'actions/adaptFunctions';
import { updateData, resetData } from 'actions/updateData';
import { osceCRUD } from 'actions/cruds';

import AddNewOsce from './addNewOsce';

const mapStateToProps = ({ runtime }) => ({
  osce: runtime.osceData,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ dispatch, updateData, resetData }, dispatch);

@i18nTable('osceColumns')
@withRouter
@connect(
  mapStateToProps,
  mapDispatchToProps,
)
@addLoad({ i18Name: 'modalForm' })
export default class List extends Component {
  state = {
    visible: false,
  };

  componentDidMount() {
    const { params } = this.props.match;
    if (get(params, 'semesterId')) {
      this.onChangeSemester(params.semesterId);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { params } = nextProps.match;
    if (
      get(this.props.match.params, 'semesterId') !== get(params, 'semesterId')
    ) {
      this.onChangeSemester(params.semesterId);
    }
  }

  componentWillUnmount() {
    this.props.resetData('osce');
  }

  onChangeSemester = async value => {
    const { props } = this;
    const params = omit(props.osce, ['data', 'pagination']);
    const data = await props.stateloading(
      osceCRUD.getList({
        params: { ...params, semesterId: value },
      }),
    );
    setKey(data);
    props.updateData({ ...data, ...params, semesterId: value }, 'osce');
  };

  showModal = () => this.setState({ visible: true });

  handleCancel = () => {
    this.setState({ visible: false });
  };

  openProfile = osce => () =>
    this.props.history.push(
      `/osce-administration/${
        this.props.match.params.semesterId
      }/osce/basic-data/${osce.id}`,
    );

  get renderTable() {
    return (
      get(this.props.match.params, 'semesterId') && (
        <Table
          name="osce"
          noLoad
          action={osceCRUD}
          className="table-row-pointer"
          columns={this.props.osceColumns}
          sendParams={{ semesterId: this.props.match.params.semesterId }}
          onRow={osce => ({ onClick: this.openProfile(osce) })}
        />
      )
    );
  }

  render() {
    const { props } = this;
    return (
      <React.Fragment>
        <ModalForm
          title={props.translate('addNewOsce')}
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <AddNewOsce
            semesterId={get(props, 'semesterId')}
            redirect={({ id }) =>
              `/osce-administration/${
                this.props.match.params.semesterId
              }/osce/basic-data/${id}`
            }
            closeWindow={this.handleCancel}
          />
        </ModalForm>
        <TablePanel>
          <Button
            icon="plus"
            className="blueBtn table-panel_btn"
            onClick={this.showModal}
          >
            {props.translate('addNewOsce')}
          </Button>
        </TablePanel>
        <Spin spinning={this.props.loading}>{this.renderTable}</Spin>
      </React.Fragment>
    );
  }
}
