import React, { Component } from 'react';
import { connect } from 'react-redux';
import { find, map } from 'lodash';
import { withRouter, Link } from 'react-router-dom';
import { Row, Col, Menu, Dropdown, Icon, Popover } from 'antd';
import { FormattedMessage } from 'react-intl';

import { HEADER_MENU, LANG_MENU, SETTINGS_MENU } from 'constants/common';
import { CHANGE_LANGUAGE } from 'constants/actions';

import './header.less';

const mapStateToProps = ({ localization: { lang } }) => ({ lang });

@withRouter
@connect(mapStateToProps)
export default class Header extends Component {
  logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('roles');
    localStorage.removeItem('fullname');
    this.props.history.push('/auth/login');
  };

  onLangClick = async ({ key }) => {
    await this.props.dispatch({ type: CHANGE_LANGUAGE, lang: key });
    window.location.reload();
  };

  onSettingsClick = ({ key }) => {
    if (key === 'logout') {
      this.logout();
    }
  };

  get menu() {
    return (
      <Menu onClick={this.onLangClick}>
        {LANG_MENU.map(({ text, key }) => (
          <Menu.Item key={key}>{text}</Menu.Item>
        ))}
      </Menu>
    );
  }

  get settings() {
    return (
      <Menu onClick={this.onSettingsClick}>
        {SETTINGS_MENU.map(({ text, key }) => (
          <Menu.Item key={key}>{text}</Menu.Item>
        ))}
      </Menu>
    );
  }

  get currentLang() {
    const langObj = find(LANG_MENU, { key: this.props.lang });
    if (langObj) {
      return langObj.text;
    }
    return 'English';
  }

  get currentUserName() {
    return localStorage.getItem('fullname');
  }

  roleItem = (name, key = 1) => (
    <span key={key}>
      <FormattedMessage id={`rights.${name}`} />
    </span>
  );

  get roles() {
    const roles = JSON.parse(localStorage.getItem('roles'));
    if (roles.length && roles.length > 1) {
      return (
        <Popover
          placement="bottom"
          content={
            <div className="popover-header">
              {map(roles, (role, key) => this.roleItem(role, key))}
            </div>
          }
        >
          <span>
            <FormattedMessage id="header.multiple" />
          </span>
        </Popover>
      );
    }
    return this.roleItem(roles[0]);
  }

  get checkSelected() {
    const selectedMenu = [];
    HEADER_MENU.forEach(item => {
      if (window.location.pathname.indexOf(item.pattern) > -1) {
        selectedMenu.push(item.name);
      }
    });
    return selectedMenu;
  }

  render() {
    const roles = localStorage.getItem('roles');
    return (
      <div className="header">
        <Row>
          <Col span={16}>
            <Menu
              className="header_block"
              mode="horizontal"
              selectedKeys={this.checkSelected}
            >
              {HEADER_MENU.filter(item => roles.indexOf(item.access) > -1).map(
                item => (
                  <Menu.Item key={item.name}>
                    <Link to={item.url}>
                      <FormattedMessage id={`header.${item.name}`} />
                    </Link>
                  </Menu.Item>
                ),
              )}
            </Menu>
          </Col>
          <Col span={8}>
            <ul className="nav navbar-top-links pull-right header_right">
              <li>
                <Dropdown overlay={this.menu} trigger={['click']}>
                  <a className="ant-dropdown-link">
                    {this.currentLang} <Icon type="down" />
                  </a>
                </Dropdown>
              </li>
              <li className="header_right_logout">
                <Dropdown overlay={this.settings} trigger={['click']}>
                  <a>
                    <p className="header_right_logout_username">
                      {this.currentUserName}
                    </p>
                    <p className="header_right_logout_roles">{this.roles}</p>
                  </a>
                </Dropdown>
              </li>
            </ul>
          </Col>
        </Row>
      </div>
    );
  }
}
