import React, { Component } from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import './formsStyles.less';

@injectIntl
export default class ModalFooter extends Component {
  static contextTypes = {
    form: PropTypes.object,
  };

  render() {
    const { intl } = this.props;
    return (
      <div className="ant-modal-footer footer_max_width">
        <Button onClick={this.props.onCancel}>
          {intl.formatMessage({ id: this.props.cancelText })}
        </Button>
        <Button htmlType="submit" className="blueBtn">
          {intl.formatMessage({ id: this.props.submitText })}
        </Button>
      </div>
    );
  }
}
