export const FETCH_QUESTIONS_START = 'FETCH_QUESTIONS_START';
export const FETCH_QUESTIONS_SUCCESS = 'FETCH_QUESTIONS_SUCCESS';
export const FETCH_QUESTIONS_ERROR = 'FETCH_QUESTIONS_ERROR';
export const POST_ANSWER_START = 'POST_ANSWER_START';
export const POST_ANSWER_SUCCESS = 'POST_ANSWER_SUCCESS';
export const POST_ANSWER_ERROR = 'POST_ANSWER_ERROR';
export const POST_COMMENT_START = 'POST_COMMENT_START';
export const POST_COMMENT_SUCCESS = 'POST_COMMENT_SUCCESS';
export const POST_COMMENT_ERROR = 'POST_COMMENT_ERROR';
export const REMOVE_ANSWER_SUCCESS = 'REMOVE_ANSWER_SUCCESS';
export const REMOVE_ANSWER_ERROR = 'REMOVE_ANSWER_ERROR';
export const POST_COMMENT_TO_OPTION_SUCCESS = 'POST_COMMENT_TO_OPTION_SUCCESS';
export const POST_COMMENT_TO_OPTION_ERROR = 'POST_COMMENT_TO_OPTION_ERROR';
