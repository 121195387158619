import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table, Button, message } from 'antd';
import { history } from 'store';
import { debounce } from 'lodash';
// Components
import Search from 'components/Search';
import ModalForm from 'components/ModalForm/index';
import i18n from 'services/i18n';

import { getAllClinics, lazyLoadClinics } from 'actions/clinic';
import AddNewClinic from './addNewClinic';

const mapStateToProps = state => ({
  clinics: state.clinics.data,
  loading: state.clinics.loading,
});

const mapDispatchToProps = {
  getAllClinics,
  lazyLoadClinics,
};

@i18n('form')
@connect(
  mapStateToProps,
  mapDispatchToProps,
)
export default class Clinics extends Component {
  static propTypes = {
    clinics: PropTypes.array.isRequired,
    loading: PropTypes.bool,
  };

  static defaultProps = {
    loading: false,
  };

  constructor(props) {
    super(props);

    this.columns = [
      {
        title: props.translate('name'),
        dataIndex: 'name',
        key: 'name',
        width: '25%',
        sorter: true,
      },
      {
        title: props.translate('city'),
        dataIndex: 'city',
        key: 'city',
        width: '15%',
        sorter: true,
      },
      {
        title: props.translate('postalCode'),
        dataIndex: 'postalCode',
        key: 'postalCode',
        width: '15%',
        sorter: true,
      },
      {
        title: props.translate('street'),
        dataIndex: 'street',
        key: 'street',
        width: '45%',
        sorter: true,
      },
    ];

    this.state = {
      page: 0,
      pageSize: 30,
      total: 0,
      search: '',
      sortProp: 'name',
      sortDirection: 'ASC',
      preventLazyLoad: false,
      isAddClinicModalOpen: false,
    };
  }

  componentDidMount() {
    this.handleUpdateTableData();
    window.addEventListener('scroll', this.handlePageScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handlePageScroll);
  }

  handleOpenClinicModal = e => this.setState({ isAddClinicModalOpen: true });

  handleCloseClinicModal = e => this.setState({ isAddClinicModalOpen: false });

  handlePageScroll = debounce(e => {
    const { body } = document;
    if (
      !this.state.preventLazyLoad &&
      this.props.clinics.length < this.state.total &&
      window.scrollY > body.scrollHeight - body.offsetHeight - 30
    ) {
      this.setState(
        { page: this.state.page + 1, preventLazyLoad: true },
        this.lazyLoadClinics,
      );
    }
  }, 100);

  lazyLoadClinics = () => {
    const { search, page, pageSize, sortDirection, sortProp } = this.state;
    this.props
      .lazyLoadClinics({
        search,
        page,
        pageSize,
        sortProp,
        sortDirection,
      })
      .then(res => {
        this.setState({ total: res.pagination.total, preventLazyLoad: false });
      })
      .catch(err => message.error(this.props.translate('all', 'error')));
  };

  handleUpdateTableData = () => {
    const { search, page, pageSize, sortProp, sortDirection } = this.state;
    this.props
      .getAllClinics({
        search,
        page,
        pageSize,
        sortProp,
        sortDirection,
      })
      .then(res => this.setState({ total: res.pagination.total }))
      .catch(err => message.error(this.props.translate('all', 'error')));
  };

  handleTableChange = (pagination, filters, sorter) => {
    // When no sort column selected sorter = {}
    if (sorter.order) {
      const sortDirection = sorter.order === 'ascend' ? 'ASC' : 'DESC';
      const sortProp = sorter.column.key;
      this.setState(
        { page: 0, sortProp, sortDirection },
        this.handleUpdateTableData,
      );
    } else {
      this.setState(
        { page: 0, sortProp: 'name', sortDirection: 'ASC' },
        this.handleUpdateTableData,
      );
    }
  };

  handleSearch = search => {
    this.setState({ search, page: 0 }, this.handleUpdateTableData);
  };

  handleOpenClinicProfile = clinic =>
    history.push(`/examiners/clinics/${clinic.id}`);

  render() {
    const dataSource = this.props.clinics.map(item => ({
      ...item,
      key: item.id,
    }));

    return (
      <React.Fragment>
        <ModalForm
          destroyOnClose
          title={this.props.translate('addNewClinic', 'modalForm')}
          visible={this.state.isAddClinicModalOpen}
          onCancel={this.handleCloseClinicModal}
        >
          <AddNewClinic
            toggleWindow={this.handleCloseClinicModal}
            redirect={id => `/examiners/clinics/${id}`}
          />
        </ModalForm>
        <div className="table-panel">
          <Button
            icon="plus"
            className="blueBtn table-panel_btn"
            onClick={this.handleOpenClinicModal}
          >
            {this.props.translate('addNewClinic', 'modalForm')}
          </Button>
          <Search
            value={this.state.search}
            count={this.state.total}
            placeholder={this.props.translate('search')}
            onSearch={this.handleSearch}
          />
        </div>
        <Table
          bordered
          columns={this.columns}
          className="table-row-pointer"
          dataSource={dataSource}
          pagination={false}
          loading={this.props.loading}
          onChange={this.handleTableChange}
          onRow={clinic => {
            return {
              onClick: () => {
                this.handleOpenClinicProfile(clinic);
              },
            };
          }}
        />
      </React.Fragment>
    );
  }
}
