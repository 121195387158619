export const CHANGE_PROFESSIONS_START = 'CHANGE_PROFESSIONS_START';
export const GET_PROFESSIONS_SUCCESS = 'GET_PROFESSIONS_SUCCESS';
export const GET_PROFESSIONS_ERROR = 'GET_PROFESSIONS_ERROR';
export const DELETE_PROFESSION_SUCCESS = 'DELETE_PROFESSION_SUCCESS';
export const DELETE_PROFESSION_ERROR = 'DELETE_PROFESSION_ERROR';
export const UPDATE_PROFESSION_SUCCESS = 'UPDATE_PROFESSION_SUCCESS';
export const UPDATE_PROFESSION_ERROR = 'UPDATE_PROFESSION_ERROR';
export const CREATE_PROFESSION_SUCCESS = 'CREATE_PROFESSION_SUCCESS';
export const CREATE_PROFESSION_ERROR = 'CREATE_PROFESSION_ERROR';
export const LOAD_MORE_PROFESSIONS_SUCCESS = 'LOAD_MORE_PROFESSIONS_SUCCESS';
