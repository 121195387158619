export const setKey = data => {
  let key = 0;
  data.data.forEach(item => (item.key = key++));
  return data;
};

export const typeFilter = filter => {
  const names = ['LANGUAGE', 'NATIONALITY', 'PROFESSION'];
  filter.forEach(item => {
    if (names.indexOf(item.type) > -1) {
      const type = item.type.toLowerCase();
      item.criterion[`${type}Id`] = item.criterion[type].id;
    }
  });
};
