import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

/** Constants */
import { STATION_TYPES } from 'constants/common';

/** Components */
import Carousel from 'components/Carousel';
import Station from './Station';

/**
 * Component which render sequence for osce schedule
 * @reactProps {object} osceDay - data of current OSCE day
 */
export default class Sequence extends Component {
  static propTypes = {
    osceDay: PropTypes.object,
    countStudents: PropTypes.number,
  };

  hasBigBreak = stations => {
    const result = stations.filter(
      el =>
        el.stationType === STATION_TYPES.PREPARATION ||
        el.stationType === STATION_TYPES.DOUBLE,
    );
    return result.length > 0;
  };

  get bodyForCarousel() {
    const { circuits, osceDay, translate } = this.props;
    return circuits.map(circuit => {
      const isSpPaused = circuit.name === 'SP_PAUSED';
      return {
        title: isSpPaused
          ? translate('spPaused')
          : `${translate('circuit')} ${circuit.name}`,
        color: circuit.circuitColor,
        key: circuit.id,
        Element: (
          <div
            key={circuit.circuitId}
            style={{
              display: 'flex',
              background: 'rgba(255,255,255,0.45)',
              paddingLeft: '5px',
            }}
          >
            {circuit.stations.map(item => {
              return (
                <Station
                  {...item}
                  key={item.id}
                  isSpPaused={isSpPaused}
                  osceDayId={osceDay.id}
                  sequenceNumberLunchBreak={get(
                    osceDay.lunchBreakBetweenOsceSequences,
                    'sequenceNumber',
                  )}
                  circuitId={circuit.id}
                  timeSlots={circuit.timeSlots}
                  hasBigBreak={this.hasBigBreak(circuit.stations)}
                />
              );
            })}
          </div>
        ),
      };
    });
  }

  render() {
    return (
      <div className="osce-day_sequence">
        <Carousel openFirst elements={this.bodyForCarousel} />
      </div>
    );
  }
}
