import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Modal, Radio, Icon, Button } from 'antd';
// Services
import api from 'services/api';
import i18n from 'services/i18n';
// Components
import LazySelect from 'components/LazySelect';

@i18n('sections')
@Form.create({})
class QuestionModal extends Component {
  static propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired,
    title: PropTypes.string,
    question: PropTypes.object,
    sectionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
  };

  static defaultProps = {
    question: {
      answers: [],
      answersOptions: [],
      question: '',
      questionType: '',
    },
    title: '',
  };

  handleAddNewAnswerOption = () => {
    const { getFieldValue, setFieldsValue } = this.props.form;
    const keys = getFieldValue('keys');
    const id = keys.length;

    setFieldsValue({
      keys: keys.concat(id),
    });
  };

  handleRemoveAnswerOption = key => {
    const { getFieldValue, setFieldsValue } = this.props.form;
    const keys = getFieldValue('keys');
    setFieldsValue({ keys: keys.filter(k => k !== key) });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSubmit(values);
        this.props.form.resetFields();
        this.props.onCancel();
      }
    });
  };

  handleLoadSections = data => {
    return api.getQuestionsGroupedBySection(data).then(res => {
      // Create Select options
      const sections = res.data.data.map(section => ({
        id: section.id,
        name: section.name,
      }));
      // Create appropriate structure for render
      return { data: sections };
    });
  };

  renderAnswerFields = () => {
    const { answersOptions } = this.props.question;
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const keys = getFieldValue('keys');
    const answerType = getFieldValue('questionType');

    switch (answerType) {
      case 'YES_NO':
        return (
          <React.Fragment>
            <Form.Item label={this.props.translate('answer')} colon={false}>
              <Input placeholder={this.props.translate('yes')} disabled />
            </Form.Item>
            <Form.Item scolon={false}>
              <Input placeholder={this.props.translate('no')} disabled />
            </Form.Item>
          </React.Fragment>
        );
      case 'SINGLE_SELECTION':
      case 'MULTIPLE_SELECTION':
        return (
          <React.Fragment>
            <div className="ant-form-item-label">
              <label htmlFor="answersOptions">
                {this.props.translate('answer')}
              </label>
            </div>
            {keys.map(key => (
              <Form.Item key={key}>
                {getFieldDecorator(`answersOptions[${key}]`, {
                  initialValue: answersOptions[key]
                    ? answersOptions[key].value
                    : '',
                  validateTrigger: ['onChange', 'onBlur'],
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: this.props.translate('atLeastOneAnswer'),
                    },
                  ],
                })(
                  <Input
                    className="answersOption"
                    placeholder={this.props.translate('answer')}
                  />,
                )}
                <Button
                  className="dynamic-delete-button"
                  size="small"
                  shape="circle"
                  icon="minus"
                  disabled={keys.length === 1}
                  onClick={() => this.handleRemoveAnswerOption(key)}
                />
              </Form.Item>
            ))}
            <Form.Item>
              <Button type="dashed" onClick={this.handleAddNewAnswerOption}>
                <Icon type="plus" /> {this.props.translate('addAnswer')}
              </Button>
            </Form.Item>
          </React.Fragment>
        );
      default:
        return (
          <Form.Item label={this.props.translate('answer')} colon={false}>
            <Input.TextArea rows={3} autosize={false} disabled />
          </Form.Item>
        );
    }
  };

  render() {
    const { getFieldDecorator, resetFields } = this.props.form;
    // Set initial values for keys
    // If empty array, set first key because at least 1
    // field should be added
    if (this.props.question.answersOptions.length === 0) {
      getFieldDecorator('keys', {
        initialValue: [0],
      });
      // Map answerOptions and for each add key to render this option
    } else {
      getFieldDecorator('keys', {
        initialValue: this.props.question.answersOptions.map((_, key) => key),
      });
    }

    return (
      <Modal
        destroyOnClose
        title={this.props.title}
        className="add-question"
        visible={this.props.visible}
        okText={this.props.translate('save', 'form')}
        cancelText={this.props.translate('cancel', 'form')}
        onOk={this.handleSubmit}
        onCancel={e => {
          resetFields();
          this.props.onCancel();
        }}
      >
        <Form onSubmit={this.handleSubmit}>
          <Form.Item label={this.props.translate('section', 'sectionColumns')}>
            {getFieldDecorator('sectionId', {
              initialValue: this.props.sectionId,
              rules: [
                {
                  required: true,
                  message: this.props.translate(
                    'placeholderSection',
                    'sections',
                  ),
                },
              ],
            })(<LazySelect loadData={this.handleLoadSections} />)}
          </Form.Item>
          <Form.Item label={this.props.translate('question')}>
            {getFieldDecorator('question', {
              initialValue: this.props.question.question,
              rules: [
                {
                  required: true,
                  message: this.props.translate(
                    'placeholderSection',
                    'sections',
                  ),
                },
              ],
            })(
              <Input.TextArea
                rows={4}
                autosize={false}
                placeholder={this.props.translate('typeQuestion', 'sections')}
              />,
            )}
          </Form.Item>
          <Form.Item label={this.props.translate('answerType')} colon={false}>
            {getFieldDecorator('questionType', {
              initialValue: this.props.question.questionType || 'OPEN',
              rules: [
                {
                  required: true,
                  message: this.props.translate('checkboxGroup', 'form'),
                },
              ],
            })(
              <Radio.Group>
                <Radio.Button value="OPEN">
                  {this.props.translate('open')}
                </Radio.Button>
                <Radio.Button value="YES_NO">
                  {this.props.translate('yesNo')}
                </Radio.Button>
                <Radio.Button value="SINGLE_SELECTION">
                  {this.props.translate('single')}
                </Radio.Button>
                <Radio.Button value="MULTIPLE_SELECTION">
                  {this.props.translate('multiply')}
                </Radio.Button>
              </Radio.Group>,
            )}
          </Form.Item>
          {this.renderAnswerFields()}
        </Form>
      </Modal>
    );
  }
}

export default QuestionModal;
