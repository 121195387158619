import React from 'react';

import { Tabs } from 'antd';

import Table from 'components/Table';
import i18nTable from 'services/i18nTable';

import { clinicCRUD } from 'actions/cruds';

import '../styles.less';
import ClinicForm from './editClinic';

@i18nTable(['clinicExaminersColumns', 'clinicExaminersByOsceColumns'])
export default class Profile extends React.PureComponent {
  render() {
    const { id } = this.props.match.params;
    return (
      <React.Fragment>
        <ClinicForm id={id} />
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="Examiners" key="1">
            <Table
              action={clinicCRUD}
              name="clinicExaminer"
              additionalUrl={`${id}/examiner`}
              columns={this.props.clinicExaminersColumns}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Examiners by OSCE" key="2">
            <Table
              action={clinicCRUD}
              name="clinicExaminerByOSCE"
              additionalUrl={`${id}/examiner/osce-participation`}
              columns={this.props.clinicExaminersByOsceColumns}
            />
          </Tabs.TabPane>
        </Tabs>
      </React.Fragment>
    );
  }
}
