import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { isValidIBAN } from 'services/validations';
import i18n from 'services/i18n';

import Card from '../Card';
import Field from '../form/Field';

@i18n('error')
export default class BillingDetails extends Component {
  static childContextTypes = {
    updateName: PropTypes.string,
    path: PropTypes.string,
  };

  getChildContext() {
    return {
      updateName: 'bankAccountResponse',
      path: 'bankAccountInfo',
    };
  }

  render() {
    return (
      <Card title="billingDetails">
        <Field
          staticWidth
          i18Name="IBAN"
          name="iban"
          rules={[
            {
              validator: isValidIBAN,
              message: this.props.translate('iban'),
            },
          ]}
        />
        <Field staticWidth i18Name="owner" name="ownerName" />
        <Field staticWidth i18Name="bank" name="bankName" />
        <Field staticWidth i18Name="BIC" name="bic" />
        <Field staticWidth name="postalCode" />
        <Field staticWidth name="city" />
        <Field staticWidth name="country" />
      </Card>
    );
  }
}
