import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Select, Button, Spin, Col } from 'antd';

import i18n from 'services/i18n';
import { IS_CRITERIA, MARITAL_STATUS, FILTER_TYPE } from 'constants/options';

@i18n('form')
@Form.create()
class MaritalStatusFilter extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    form: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired,
  };

  static defaultProps = {
    loading: false,
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const data = {
          criterion: {
            isSimPatHasCriterion: values.isSimPatHasCriterion === 'is', // convert to bool
            maritalStatus: values.maritalStatus,
          },
        };
        this.props.onSubmit(FILTER_TYPE.MARITAL_STATUS, data);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Spin spinning={this.props.loading}>
        <Form
          className="criteria-form"
          layout="horizontal"
          onSubmit={this.handleSubmit}
        >
          <Input.Group>
            <Col span={10}>
              <Form.Item wrapperCol={{ span: 24 }} className="form-full-width">
                {getFieldDecorator('isSimPatHasCriterion', {
                  initialValue: 'is',
                })(
                  <Select placeholder={this.props.translate('chooseOptions')}>
                    {IS_CRITERIA.map(item => (
                      <Select.Option key={item.value} value={item.value}>
                        {this.props.translate(item.value)}
                      </Select.Option>
                    ))}
                  </Select>,
                )}
              </Form.Item>
            </Col>
            <Col span={14}>
              <Form.Item wrapperCol={{ span: 24 }} className="form-full-width">
                {getFieldDecorator('maritalStatus', {
                  initialValue: 'CIVIL_UNION',
                })(
                  <Select
                    placeholder={this.props.translate('chooseMaritalStatus')}
                  >
                    {MARITAL_STATUS.map(item => (
                      <Select.Option key={item.value} value={item.value}>
                        {this.props.translate(item.value)}
                      </Select.Option>
                    ))}
                  </Select>,
                )}
              </Form.Item>
            </Col>
          </Input.Group>
          <Button htmlType="submit" icon="plus" className="add-btn">
            {this.props.translate('add')}
          </Button>
        </Form>
      </Spin>
    );
  }
}

export default MaritalStatusFilter;
