import React, { Component } from 'react';
import { withRouter } from 'react-router';
import StudentsImport from 'components/StudentsImport';
import { studentCRUD } from 'actions/cruds';

@withRouter
export default class StudentsOsce extends Component {
  render() {
    const { id } = this.props.match.params;
    return (
      <StudentsImport
        name="studentsOSCE"
        action={studentCRUD}
        customColumnsName="studentsOsceColumns"
        additionalUrl={`get/${id}`}
      />
    );
  }
}
