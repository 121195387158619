import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Select, Button, Spin, Col, message } from 'antd';
// Services
import api from 'services/api';
import i18n from 'services/i18n';
// Options
import { IS_CRITERIA, FILTER_TYPE } from 'constants/options';
// Components
import LazySelect from 'components/LazySelect';

@i18n('form')
@Form.create()
class NationalityFilter extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    form: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired,
  };

  static defaultProps = {
    loading: false,
  };

  state = {
    nationalities: [],
  };

  handleLoadNationalities = data => {
    return api
      .getAllNationalities(data)
      .then(res => {
        this.setState({ nationalities: res.data.data });
        return res.data;
      })
      .catch(err => message.error(this.props.translate('all', 'error')));
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const data = {
          criterion: {
            isSimPatHasCriterion: values.isSimPatHasCriterion === 'is', // convert to bool
            nationalityId: values.nationalityId,
          },
        };
        // For rendering purposes only, used in CriteriaTable
        // Find selected nationality
        const nationality = this.state.nationalities.find(
          n => n.id === values.nationalityId,
        );
        data._name = nationality.name;

        this.props.onSubmit(FILTER_TYPE.NATIONALITY, data);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Spin spinning={this.props.loading}>
        <Form
          className="criteria-form"
          layout="horizontal"
          onSubmit={this.handleSubmit}
        >
          <Input.Group>
            <Col span={10}>
              <Form.Item wrapperCol={{ span: 24 }} className="form-full-width">
                {getFieldDecorator('isSimPatHasCriterion', {
                  initialValue: 'is',
                })(
                  <Select placeholder={this.props.translate('chooseOptions')}>
                    {IS_CRITERIA.map(item => (
                      <Select.Option key={item.value} value={item.value}>
                        {this.props.translate(item.value)}
                      </Select.Option>
                    ))}
                  </Select>,
                )}
              </Form.Item>
            </Col>
            <Col span={14}>
              <Form.Item wrapperCol={{ span: 24 }} className="form-full-width">
                {getFieldDecorator('nationalityId', {
                  rules: [
                    {
                      required: true,
                      message: this.props.translate('checkboxGroup'),
                    },
                  ],
                })(<LazySelect loadData={this.handleLoadNationalities} />)}
              </Form.Item>
            </Col>
          </Input.Group>
          <Button htmlType="submit" icon="plus" className="add-btn">
            {this.props.translate('add')}
          </Button>
        </Form>
      </Spin>
    );
  }
}

export default NationalityFilter;
