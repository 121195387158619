import api from 'services/api';
import {
  CLINIC_REQUEST_START,
  FETCH_CLINICS_SUCCESS,
  FETCH_CLINICS_ERROR,
  LOAD_MORE_CLINICS_SUCCESS,
} from 'constants/clinic';

export const getAllClinics = data => async dispatch => {
  try {
    dispatch({ type: CLINIC_REQUEST_START });
    const response = await api.getAllClinics(data);
    const clinics = response.data;
    dispatch({ type: FETCH_CLINICS_SUCCESS, payload: { clinics } });
    return clinics;
  } catch (error) {
    dispatch({ type: FETCH_CLINICS_ERROR, error });
    throw error;
  }
};

export const lazyLoadClinics = data => async dispatch => {
  try {
    dispatch({ type: CLINIC_REQUEST_START });
    const response = await api.getAllClinics(data);
    const clinics = response.data;
    dispatch({ type: LOAD_MORE_CLINICS_SUCCESS, payload: { clinics } });
    return clinics;
  } catch (error) {
    dispatch({ type: FETCH_CLINICS_ERROR, error });
    throw error;
  }
};
