import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import { connect } from 'react-redux';
import { history } from 'store';
import { Table, Button, Select, message } from 'antd';
/** Services **/
import api from 'services/api';
import i18n from 'services/i18n';
/** Actions **/
import { getAllExaminers, lazyLoadExaminers } from 'actions/examiners';
/** Components **/
import ModalForm from 'components/ModalForm/index';
import Search from 'components/Search';
import LazySelect from 'components/LazySelect';

import AddNewExaminer from './addNewExaminer';

const mapStateToProps = state => ({
  examiners: state.examiners.data,
  loading: state.examiners.loading,
});

const mapDispatchToProps = {
  getAllExaminers,
  lazyLoadExaminers,
};

@i18n('examinerColumns')
@connect(
  mapStateToProps,
  mapDispatchToProps,
)
export default class Examiners extends Component {
  static propTypes = {
    examiners: PropTypes.array.isRequired,
    loading: PropTypes.bool,
  };

  static defaultProps = {
    loading: false,
  };

  constructor(props) {
    super(props);

    this.columns = [
      {
        title: props.translate('lastName', 'form'),
        dataIndex: 'lastName',
        key: 'lastName',
        width: '25%',
        sorter: true,
      },
      {
        title: props.translate('firstName'),
        dataIndex: 'firstName',
        key: 'firstName',
        width: '25%',
        sorter: true,
      },
      {
        title: props.translate('title'),
        dataIndex: 'academicTitle',
        key: 'examinerPersonalInfoAcademicTitle',
        width: '25%',
        sorter: true,
      },
      {
        title: props.translate('clinics', 'examiners_sidebar'),
        dataIndex: 'clinicName',
        key: 'examinerContactDetailsClinicName',
        width: '25%',
        sorter: true,
      },
    ];

    this.state = {
      clinicId: undefined,
      page: 0,
      pageSize: 30,
      total: 0,
      search: '',
      sortProp: 'lastName',
      sortDirection: 'ASC',
      preventLazyLoad: false,
      isAddExaminerModalOpen: false,
    };
  }

  componentDidMount() {
    this.handleUpdateTableData();
    window.addEventListener('scroll', this.handlePageScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handlePageScroll);
  }

  handleOpenExaminerModal = e =>
    this.setState({ isAddExaminerModalOpen: true });

  handleCloseExaminerModal = e =>
    this.setState({ isAddExaminerModalOpen: false });

  handlePageScroll = debounce(e => {
    const { body } = document;
    if (
      !this.state.preventLazyLoad &&
      this.props.examiners.length < this.state.total &&
      window.scrollY > body.scrollHeight - body.offsetHeight - 30
    ) {
      this.setState(
        { page: this.state.page + 1, preventLazyLoad: true },
        this.lazyLoadExaminers,
      );
    }
  }, 100);

  lazyLoadExaminers = () => {
    const {
      clinicId,
      search,
      page,
      pageSize,
      sortDirection,
      sortProp,
    } = this.state;
    this.props
      .lazyLoadExaminers({
        clinicId,
        search,
        page,
        pageSize,
        sortProp,
        sortDirection,
      })
      .then(res => {
        this.setState({ total: res.pagination.total, preventLazyLoad: false });
      })
      .catch(err => message.error(this.props.translate('all', 'error')));
  };

  handleUpdateTableData = () => {
    const {
      clinicId,
      search,
      page,
      pageSize,
      sortProp,
      sortDirection,
    } = this.state;
    this.props
      .getAllExaminers({
        clinicId,
        search,
        page,
        pageSize,
        sortProp,
        sortDirection,
      })
      .then(res => this.setState({ total: res.pagination.total }))
      .catch(err => message.error(this.props.translate('all', 'error')));
  };

  handleTableChange = (pagination, filters, sorter) => {
    // When no sort column selected sorter = {}
    if (sorter.order) {
      const sortDirection = sorter.order === 'ascend' ? 'ASC' : 'DESC';
      const sortProp = sorter.column.key;
      this.setState(
        { page: 0, sortProp, sortDirection },
        this.handleUpdateTableData,
      );
    } else {
      this.setState(
        { page: 0, sortProp: 'lastName', sortDirection: 'ASC' },
        this.handleUpdateTableData,
      );
    }
  };

  handleLoadClinics = data => {
    return api
      .getAllClinics(data)
      .then(res => res.data)
      .catch(err => message.error(this.props.translate('all', 'error')));
  };

  handleSearch = search => {
    this.setState({ search, page: 0 }, this.handleUpdateTableData);
  };

  handleChangeClinic = clinicId => {
    // In order to not filter by clinic's
    // just remove clinicId from the request
    if (clinicId === 'all') {
      this.setState(
        { clinicId: undefined, page: 0 },
        this.handleUpdateTableData,
      );
    } else {
      this.setState({ clinicId, page: 0 }, this.handleUpdateTableData);
    }
  };

  handleOpenExaminerProfile = examiner =>
    history.push(`/examiner-profile/${examiner.id}`);

  render() {
    const dataSource = this.props.examiners.map(item => ({
      ...item,
      key: item.id,
    }));

    return (
      <React.Fragment>
        <ModalForm
          title={this.props.translate('addNewExaminer', 'modalForm')}
          visible={this.state.isAddExaminerModalOpen}
          className="examiner_create-new"
          onCancel={this.handleCloseExaminerModal}
        >
          <AddNewExaminer
            toggleWindow={this.handleCloseExaminerModal}
            redirect={id => `/examiner-profile/${id}`}
          />
        </ModalForm>
        <div className="table-panel">
          <Button
            icon="plus"
            className="blueBtn table-panel_btn"
            onClick={this.handleOpenExaminerModal}
          >
            {this.props.translate('addNewExaminer', 'modalForm')}
          </Button>
          <Search
            value={this.state.search}
            count={this.state.total}
            placeholder={this.props.translate('search', 'form')}
            onSearch={this.handleSearch}
          />
          <span style={{ display: 'inline-block', width: 160, marginLeft: 24 }}>
            <LazySelect
              defaultValue="all"
              loadData={this.handleLoadClinics}
              onChange={this.handleChangeClinic}
            >
              <Select.Option key="all" value="all">
                {this.props.translate('allClinics', 'modalForm')}
              </Select.Option>
            </LazySelect>
          </span>
        </div>
        <Table
          bordered
          columns={this.columns}
          className="table-row-pointer"
          dataSource={dataSource}
          pagination={false}
          loading={this.props.loading}
          onChange={this.handleTableChange}
          onRow={examiner => {
            return {
              onClick: () => {
                this.handleOpenExaminerProfile(examiner);
              },
            };
          }}
        />
      </React.Fragment>
    );
  }
}
