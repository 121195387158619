import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Spin, notification } from 'antd';
import { isEmpty, chain } from 'lodash';

import {
  administratorsRolesCRUD,
  administratorsInviteCRUD,
  administratorsCRUD,
} from 'actions/cruds';

import i18n from 'services/i18n';
import FormItemInput from 'components/Form/formInput';
import ErrorItem from 'components/Form/formError';
import { rolesAdminMapper } from 'services/mapReduce';
import FormGroupCheckbox from 'components/Form/formGroupCheckbox';
import Footer from 'components/Form/modalFooter';
import 'components/Form/formsStyles.less';

@i18n('form')
@Form.create({})
export default class AddNewAdministrator extends Component {
  static childContextTypes = {
    form: PropTypes.object,
  };

  state = {
    loading: true,
    options: [],
    roles: [],
    errorText: '',
  };

  componentDidMount() {
    this.loadRoles();
  }

  loadRoles = async () => {
    const response = await administratorsRolesCRUD.getList();
    this.setState({
      options: rolesAdminMapper(response.data, this.props.translate),
      roles: response.data,
      loading: false,
    });
  };

  getChildContext() {
    return { form: this.props.form };
  }

  addNewAdministratorSubmit = async e => {
    e.preventDefault();
    const { translate } = this.props;
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        try {
          if (isEmpty(this.props.initialValues)) {
            await administratorsInviteCRUD.post(values);
          } else {
            await administratorsCRUD.put(this.props.initialValues.id, values);
          }
          notification.success({
            message: translate('success'),
          });
          await this.props.updateTable();
          this.handleReset();
        } catch (error) {
          console.error(error);
          !this.state.errorText && this.toggleError('form.incorrect');
        }
      }
    });
  };

  toggleError = errorText => {
    this.setState(state => ({ errorText }));
  };

  handleReset = () => {
    this.props.form.resetFields();
    this.toggleError('');
    this.props.toggleWindow();
  };

  get checkedValues() {
    return chain(this.state.roles)
      .filter(role => {
        if (this.props.initialValues.roles.indexOf(role.adminRoleType) !== -1) {
          return role.id;
        }
        return null;
      })
      .map(role => role.id)
      .value();
  }

  render() {
    const { translate, initialValues } = this.props;
    if (isEmpty(initialValues)) {
      return (
        <Form onSubmit={this.addNewAdministratorSubmit}>
          <div className="form_content">
            <FormItemInput
              name="email"
              icon="user"
              type="email"
              required
              label={translate('email')}
              focus
            />
            <Spin spinning={this.state.loading} delay={500}>
              <FormGroupCheckbox
                label={translate('rights')}
                required
                name="roles"
                options={this.state.options}
              />
            </Spin>
            <ErrorItem
              toggleError={this.toggleError}
              errorText={this.state.errorText}
            />
          </div>
          <Footer
            submitText="form.save"
            cancelText="form.cancel"
            onCancel={this.handleReset}
          />
        </Form>
      );
    }
    return (
      <Form onSubmit={this.addNewAdministratorSubmit}>
        <div className="form_content">
          <FormItemInput
            name="firstName"
            icon="user"
            required
            label={translate('firstName')}
            initialValue={initialValues.firstName}
          />
          <FormItemInput
            name="lastName"
            icon="user"
            required
            initialValue={initialValues.lastName}
            label={translate('lastName')}
          />
          <Spin spinning={this.state.loading} delay={500}>
            {!this.state.loading && (
              <FormGroupCheckbox
                label={translate('rights')}
                required
                name="roles"
                options={this.state.options}
                initialValue={this.checkedValues}
              />
            )}
          </Spin>
          <ErrorItem
            toggleError={this.toggleError}
            errorText={this.state.errorText}
          />
        </div>
        <Footer
          submitText="form.save"
          cancelText="form.cancel"
          onCancel={this.handleReset}
        />
      </Form>
    );
  }
}
