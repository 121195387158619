import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { chain } from 'lodash';
import { Menu, Tooltip } from 'antd';
import smoothScroll from 'smoothscroll-polyfill';

import i18n from 'services/i18n';
import { QUESTION_TYPES } from 'constants/options';

import Answers from './Answers';

smoothScroll.polyfill();

const filterQuestions = ({ search, filter, sections, questions }) => {
  // Helper fn to check if questions should have answers or not
  const shouldHaveAnswers = (filter, question) => {
    if (filter === 'answered') {
      return !question.answers.length;
    }

    if (filter === 'unanswered') {
      return !!question.answers.length;
    }

    return false;
  };

  const _search = search.toLowerCase();
  const isSectionsEmpty = sections.length;
  const isQuestionsEmpty = Object.keys(questions).length;

  // Temp vars to store filtered data
  const filteredSections = [];
  const filteredQuesitons = {};

  // Run this only if data is not empty
  if (!!isQuestionsEmpty && !!isSectionsEmpty) {
    // Get all sectionsIds
    const sectionsIds = sections.map(section => section.id);

    // For every section
    sectionsIds.forEach(sectionId => {
      // Select questions array for a given section
      let questionsArr = questions[sectionId];

      // Filter questions array by a given criterias
      questionsArr = questionsArr.filter(question => {
        if (shouldHaveAnswers(filter, question)) {
          return false;
        }
        if (!_search) {
          return true;
        }
        // A question or comment satisfies the search phrase
        if (
          chain(question)
            .get('question')
            .invoke('toLowerCase')
            .invoke('includes', _search)
            .value() ||
          chain(question)
            .get('comment')
            .invoke('toLowerCase')
            .invoke('includes', _search)
            .value()
        ) {
          return true;
        }
        // A question of type OPEN check also textAnswer field
        if (question.questionType === QUESTION_TYPES.OPEN) {
          return chain(question)
            .get('answers')
            .find(a => a.textAnswer !== null)
            .get('textAnswer')
            .invoke('toLowerCase')
            .invoke('includes', _search)
            .value();
        }
        // A question response options satisfy the search phrase
        return chain(question)
          .get('answersOptions')
          .some(answer => answer.value.toLowerCase().includes(_search))
          .value();
      });

      // If filtered questions are not empty
      if (questionsArr.length) {
        // Populate corresponding filtered section
        filteredSections.push(
          sections.find(section => section.id === sectionId),
        );
        // Populate result questions object
        filteredQuesitons[sectionId] = questionsArr;
      }
    });
  }

  // Return filtered data
  return [filteredSections, filteredQuesitons];
};

@withRouter
@i18n('patient')
export default class Questions extends PureComponent {
  static propTypes = {
    questions: PropTypes.object,
    sections: PropTypes.array,
    showComments: PropTypes.bool,
    search: PropTypes.string.isRequired,
    filter: PropTypes.string.isRequired,
    onAnswer: PropTypes.func.isRequired,
    onComment: PropTypes.func.isRequired,
    onDeleteAnswer: PropTypes.func.isRequired,
  };

  static defaultProps = {
    questions: {},
    sections: [],
    showComments: false,
  };

  sectionsRefs = {};

  state = { activeSection: undefined };

  componentDidMount() {
    const questionsList = document.getElementById('questions-list');
    const sections = document.querySelectorAll('.section');
    questionsList.addEventListener('scroll', this.handleScroll(sections));
  }

  componentWillUnmount() {
    const questionsList = document.getElementById('questions-list');
    const sections = document.querySelectorAll('.section');
    questionsList.removeEventListener('scroll', this.handleScroll(sections));
  }

  handleScroll = sections => e => {
    // Get scroll position of questions list
    const scrollPos = e.target.scrollTop;

    // Get an array of sections that already passed scroll position
    const scrolledSections = Array.prototype.filter.call(
      sections,
      section => section.offsetTop <= scrollPos,
    );
    // Get the latest scrolled section, it is current section
    const current = scrolledSections[scrolledSections.length - 1];
    // Get current section id
    const currentId = current ? current.id : null;

    if (currentId && this.state.activeSection !== currentId) {
      this.setState({ activeSection: currentId });
    }
  };

  scrollToSection = id =>
    this.sectionsRefs[id].scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });

  createSectionRef = id => el => {
    if (!el) {
      return;
    }
    this.sectionsRefs[id] = el;
  };

  handleSectionSelect = ({ key }) => this.setState({ activeSection: key });

  render() {
    const [filteredSections, filteredQuesitons] = filterQuestions({
      sections: this.props.sections,
      questions: this.props.questions,
      search: this.props.search,
      filter: this.props.filter,
    });

    return (
      <div className="sections-list-container">
        <div className="grey-header flex-center">
          <span>{this.props.translate('questionsAndAnswers')}</span>
          <span className="sections-list-sidebar">
            {this.props.translate('sections')}
          </span>
        </div>
        <div className="flex">
          <div id="questions-list" className="questions-list">
            <div className="questions-full-height">
              {filteredSections.map(section => [
                <div
                  id={section.id}
                  className="questions-title question-row section"
                  key={`section-${section.id}`}
                  ref={this.createSectionRef(section.id)}
                >
                  {section.name}
                </div>,
                filteredQuesitons[section.id].map(question => (
                  <div
                    className="question-row"
                    key={`question-${question.id}_${section.id}`}
                  >
                    <span className="question-text">{question.question}</span>
                    <Answers
                      question={question}
                      onAnswer={this.props.onAnswer}
                      onComment={this.props.onComment}
                      onOptionComment={this.props.onOptionComment}
                      showComments={this.props.showComments}
                      onDeleteAnswer={this.props.onDeleteAnswer}
                    />
                  </div>
                )),
              ])}
            </div>
          </div>
          <div className="sections-list">
            <Menu
              className="questions-sidebar"
              mode="inline"
              selectedKeys={[this.state.activeSection]}
              onClick={({ key }) => this.scrollToSection(key)}
            >
              {filteredSections.map(section => (
                <Menu.Item key={section.id}>
                  <Tooltip
                    placement="top"
                    title={this.props.translate('sectionsColumns', 'tooltips')}
                  >
                    {section.name}
                  </Tooltip>
                </Menu.Item>
              ))}
            </Menu>
          </div>
        </div>
      </div>
    );
  }
}
