import { ReactComponent as Italic } from 'images/editor/italic.svg';
import { ReactComponent as Underline } from 'images/editor/underline.svg';
import { ReactComponent as SubButton } from 'images/editor/close.svg';
import { ReactComponent as SupButton } from 'images/editor/close-copy.svg';
import { ReactComponent as Strikethrough } from 'images/editor/strikethrough.svg';
import { ReactComponent as AlignLeft } from 'images/editor/align-left.svg';
import { ReactComponent as AlignCenter } from 'images/editor/align-center.svg';
import { ReactComponent as AlignRight } from 'images/editor/align-right.svg';
import { ReactComponent as UnorderList } from 'images/editor/unordered-list.svg';
import { ReactComponent as OrderList } from 'images/editor/ordered-list.svg';

export const STYLE_MAP = {
  STRIKETHROUGH: {
    textDecoration: 'line-through',
  },
  SUBSCRIPT: { fontSize: '0.6em', verticalAlign: 'sub' },
  SUPERSCRIPT: { fontSize: '0.6em', verticalAlign: 'super' },
  FONT_FAMILY_SERIF: { fontFamily: 'serif' },
  FONT_FAMILY_SANSSERIF: { fontFamily: 'sans-serif' },
  FONT_FAMILY_MONOSPACE: { fontFamily: 'monospace' },
  FONT_SIZE_9: { fontSize: 9 },
  FONT_SIZE_10: { fontSize: 10 },
  FONT_SIZE_11: { fontSize: 11 },
  FONT_SIZE_12: { fontSize: 12 },
  FONT_SIZE_14: { fontSize: 14 },
  FONT_SIZE_16: { fontSize: 16 },
  FONT_SIZE_18: { fontSize: 18 },
  FONT_SIZE_24: { fontSize: 24 },
  FONT_SIZE_30: { fontSize: 30 },
  FONT_SIZE_36: { fontSize: 36 },
  FONT_SIZE_48: { fontSize: 48 },
  FONT_SIZE_60: { fontSize: 60 },
  FONT_SIZE_72: { fontSize: 72 },
  FONT_SIZE_84: { fontSize: 84 },
  FONT_SIZE_96: { fontSize: 96 },
  COLOR_ORANGE: { color: 'orange' },
  COLOR_BLUE: { color: 'blue' },
  COLOR_GREEN: { color: 'green' },
  COLOR_RED: { color: 'red' },
  COLOR_BLACK: { color: 'black' },
};

export const BUTTONS_EDITOR = [
  { text: 'B', style: 'BOLD', type: 'inline' },
  { icon: Italic, style: 'ITALIC', type: 'inline' },
  { icon: Underline, style: 'UNDERLINE', type: 'inline' },
  { icon: SubButton, style: 'SUBSCRIPT', type: 'inline' },
  { icon: SupButton, style: 'SUPERSCRIPT', type: 'inline' },
  { icon: AlignLeft, style: 'alignleft', type: 'block' },
  { icon: AlignCenter, style: 'aligncenter', type: 'block' },
  { icon: AlignRight, style: 'alignright', type: 'block' },
  { icon: Strikethrough, style: 'STRIKETHROUGH', type: 'inline' },
  { icon: UnorderList, style: 'unordered-list-item', type: 'block' },
  { icon: OrderList, style: 'ordered-list-item', type: 'block' },
];

export const FONT_SIZE_OPTION = [
  { label: '9px', value: 9 },
  { label: '10px', value: 10 },
  { label: '11px', value: 11 },
  { label: '12px', value: 12 },
  { label: '14px', value: 14 },
  { label: '16px', value: 16 },
  { label: '18px', value: 18 },
  { label: '24px', value: 24 },
  { label: '30px', value: 30 },
  { label: '36px', value: 36 },
  { label: '48px', value: 48 },
  { label: '60px', value: 60 },
  { label: '72px', value: 72 },
  { label: '84px', value: 84 },
  { label: '96px', value: 96 },
];

export const FONT_FAMILY_OPTION = [
  { label: 'Serif', value: 'FONT_FAMILY_SERIF' },
  { label: 'Sans-Serif', value: 'FONT_FAMILY_SANSSERIF' },
  { label: 'Monospace', value: 'FONT_FAMILY_MONOSPACE' },
];

export const TEXT_COLOR_OPTION = ['orange', 'blue', 'green', 'red', 'black'];

export const STATIC_INVITE = [
  { label: 'To last name', value: '[LASTNAME]' },
  { label: 'To first name', value: '[FIRSTNAME]' },
  { label: 'From name', value: '[FROM NAME]' },
  { label: 'Static block for assignment', value: '[ASSIGNMENT]' },
];

export const STATIC_SCHEDULE = [
  {
    name: 'Title block',
    children: [
      { label: 'To first name', value: '[FIRSTNAME]' },
      { label: 'To last name', value: '[LASTNAME]' },
    ],
  },
  {
    name: 'OSCE day block',
    children: [
      { label: 'OSCE name', value: '[OSCE]' },
      { label: 'OSCE date', value: '[DATE]' },
      { label: 'List of roles', value: '[ROLES]' },
      { label: 'List of students', value: '[STUDENTS]' },
    ],
  },
  {
    name: 'Break block',
    children: [
      { label: 'Break name', value: '[BREAK NAME]' },
      { label: 'Break start time', value: '[BREAK START TIME]' },
      { label: 'Break end time', value: '[BREAK END TIME]' },
    ],
  },
  {
    name: 'Schedule block',
    children: [
      { label: 'Start time', value: '[START TIME]' },
      { label: 'End time', value: '[END TIME]' },
      { label: 'Sequence number of post', value: '[POST NUMBER]' },
      { label: 'Room number', value: '[ROOM]' },
      { label: 'Role name', value: '[ROLE]' },
      { label: 'Role acronym name', value: '[SHORT ROLE]' },
    ],
  },
];
