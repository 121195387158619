import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Select, Input, Modal } from 'antd';
import { STUDY_YEAR } from 'constants/options';
// Services
import api from 'services/api';
import i18n from 'services/i18n';
// Components
import LazySelect from 'components/LazySelect';

@i18n('form')
@Form.create({})
export default class AddSkill extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    skillName: PropTypes.string,
    studyYear: PropTypes.string,
    disciplineId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isVisible: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  static defaultProps = {
    skillName: '',
    studyYear: 'BA.1',
    disciplineId: null,
    isVisible: false,
  };

  handleSubmit = e => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSubmit(values);
        this.handleClose();
      }
    });
  };

  handleClose = () => {
    this.props.form.resetFields();
    this.props.onClose();
  };

  handleLoadDisciplines = data => {
    return api.getAllDisciplines(data).then(res => res.data);
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Modal
        destroyOnClose
        title={this.props.title}
        visible={this.props.isVisible}
        okText={this.props.translate('save')}
        onOk={this.handleSubmit}
        cancelText={this.props.translate('cancel')}
        onCancel={this.handleClose}
      >
        <Form layout="vertical" id="addItem">
          <Form.Item
            className="form-full-width"
            label={this.props.translate('discipline')}
          >
            {getFieldDecorator('disciplineId', {
              initialValue: this.props.disciplineId,
              rules: [
                {
                  required: true,
                  message: this.props.translate('checkboxGroup'),
                },
              ],
            })(
              <LazySelect
                dropdownMatchSelectWidth
                loadData={this.handleLoadDisciplines}
              />,
            )}
          </Form.Item>
          <Form.Item label={this.props.translate('skillName')}>
            {getFieldDecorator('name', {
              initialValue: this.props.skillName,
              rules: [
                {
                  required: true,
                  message: this.props.translate('insert', 'error'),
                },
              ],
            })(<Input type="text" />)}
          </Form.Item>
          <Form.Item
            className="form-full-width"
            label={this.props.translate('studyYear')}
          >
            {getFieldDecorator('studyYear', {
              initialValue: this.props.studyYear,
            })(
              <Select className="select_studyYear">
                {STUDY_YEAR.map(year => (
                  <Select.Option key={year.value} value={year.value}>
                    {year.value}
                  </Select.Option>
                ))}
              </Select>,
            )}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}
