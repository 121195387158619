import React, { Component } from 'react';
import { get } from 'lodash';
import { Col, Row } from 'antd';
import PropTypes from 'prop-types';

import EditableLabel from 'components/Form/formEditableLabel';
import i18n from 'services/i18n';
import Card from 'components/Form/Card';
import { checkPositiveNumber } from 'services/validations';

const checkPositive = {
  func: checkPositiveNumber,
  message: 'onlyPositiveNumber',
};

@i18n('form')
export default class IOsceSettings extends Component {
  static contextTypes = {
    setStatus: PropTypes.func,
  };

  render() {
    const { data } = this.props;

    return (
      <Card from="cards" value="iOsceSettings" className="osce-settings-card">
        <Row gutter={48}>
          <Col span={12}>
            <EditableLabel
              name="backUpPeriod"
              i18name="iOsceSettings"
              initialValue={get(data, 'backUpPeriod')}
              type="number"
              validator={checkPositive}
            />
            <EditableLabel
              name="settingPassword"
              i18name="iOsceSettings"
              initialValue={get(data, 'settingPassword')}
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <EditableLabel
              name="screenSaverTime"
              type="number"
              i18name="iOsceSettings"
              initialValue={get(data, 'screenSaverTime')}
              validator={checkPositive}
            />
          </Col>
        </Row>
        <Row>
          <EditableLabel
            type="textarea"
            name="screenSaverText"
            i18name="iOsceSettings"
            initialValue={get(data, 'screenSaverText')}
          />
        </Row>
      </Card>
    );
  }
}
