import { combineReducers } from 'redux';
import {
  CHANGE_QUESTIONNAIRE_START,
  GET_QUESTIONNAIRE_SUCCESS,
  GET_QUESTIONNAIRE_ERROR,
  CREATE_SECTION_SUCCESS,
  CREATE_SECTION_ERROR,
  CHANGE_QUEUE_SECTION_SUCCESS,
  CHANGE_QUEUE_SECTION_ERROR,
  CHANGE_QUEUE_QUESTIONS_SUCCESS,
  CHANGE_QUEUE_QUESTIONS_ERROR,
  UPDATE_SECTION_SUCCESS,
  UPDATE_SECTION_ERROR,
  DELETE_SECTION_SUCCESS,
  DELETE_SECTION_ERROR,
  CREATE_NEW_QUESTION_SUCCESS,
  CREATE_NEW_QUESTION_ERROR,
  DELETE_QUESTION_SUCCESS,
  DELETE_QUESTION_ERROR,
} from 'constants/section';

const loading = (state = false, action) => {
  switch (action.type) {
    case CHANGE_QUESTIONNAIRE_START:
      return true;

    case GET_QUESTIONNAIRE_SUCCESS:
    case GET_QUESTIONNAIRE_ERROR:
    case CREATE_SECTION_SUCCESS:
    case CREATE_SECTION_ERROR:
    case CHANGE_QUEUE_SECTION_SUCCESS:
    case CHANGE_QUEUE_SECTION_ERROR:
    case CHANGE_QUEUE_QUESTIONS_SUCCESS:
    case CHANGE_QUEUE_QUESTIONS_ERROR:
    case UPDATE_SECTION_SUCCESS:
    case UPDATE_SECTION_ERROR:
    case DELETE_SECTION_SUCCESS:
    case DELETE_SECTION_ERROR:
    case CREATE_NEW_QUESTION_SUCCESS:
    case CREATE_NEW_QUESTION_ERROR:
    case DELETE_QUESTION_SUCCESS:
    case DELETE_QUESTION_ERROR:
      return false;

    default:
      return state;
  }
};

const errors = (state = {}, action) => {
  switch (action.type) {
    case GET_QUESTIONNAIRE_ERROR:
    case CREATE_SECTION_ERROR:
    case CHANGE_QUEUE_SECTION_ERROR:
    case UPDATE_SECTION_ERROR:
    case DELETE_SECTION_ERROR:
    case CREATE_NEW_QUESTION_ERROR:
    case DELETE_QUESTION_ERROR:
      return action.error;
    default:
      return state;
  }
};

const sections = (state = [], action) => {
  switch (action.type) {
    case GET_QUESTIONNAIRE_SUCCESS: {
      const newState = action.payload.sections.data.map(section => ({
        id: section.id,
        name: section.name,
        canDelete: section.questions.every(
          question => question.canDelete === true,
        ), // A section is not deletable if at least one question in it is not deletable
      }));

      return newState;
    }

    case CHANGE_QUEUE_SECTION_SUCCESS: {
      const newState = action.payload.sections.map(section => ({
        id: section.id,
        name: section.name,
        canDelete: section.questions.every(
          question => question.canDelete === true,
        ), // A section is not deletable if at least one question in it is not deletable
      }));

      return newState;
    }

    case CREATE_SECTION_SUCCESS: {
      const { section } = action.payload;
      const newState = [
        ...state,
        { id: section.id, name: section.name, canDelete: true },
      ];

      return newState;
    }

    case UPDATE_SECTION_SUCCESS: {
      const { section } = action.payload;
      const newState = state.map(s =>
        s.id === section.id ? { ...s, name: section.name } : s,
      );

      return newState;
    }

    case DELETE_SECTION_SUCCESS: {
      const newState = state.filter(
        section => section.id !== action.payload.sectionId,
      );

      return newState;
    }

    default:
      return state;
  }
};

const questionsBySection = (state = {}, action) => {
  switch (action.type) {
    case GET_QUESTIONNAIRE_SUCCESS: {
      const newState = action.payload.sections.data.reduce(
        (newState, section) => ({
          ...newState,
          [section.id]: section.questions,
        }),
        {},
      );
      return newState;
    }

    case CREATE_SECTION_SUCCESS: {
      const { section } = action.payload;
      const newState = { ...state, [section.id]: [] };

      return newState;
    }

    case DELETE_SECTION_SUCCESS: {
      const newState = { ...state };
      delete newState[action.payload.sectionId];

      return newState;
    }

    case CREATE_NEW_QUESTION_SUCCESS: {
      const { question } = action.payload;
      const sectionId = question.sectionResponse.id;
      const newQuestions = state[sectionId].concat(question);
      const newState = { ...state, [sectionId]: newQuestions };

      return newState;
    }

    case DELETE_QUESTION_SUCCESS: {
      const { sectionId, questionId } = action.payload;
      const newQuestions = state[sectionId].filter(q => q.id !== questionId);
      const newState = { ...state, [sectionId]: newQuestions };

      return newState;
    }

    default:
      return state;
  }
};

const role = combineReducers({
  loading,
  errors,
  sections,
  questionsBySection,
});

export default role;
