import React from 'react';
import { find, findIndex, map, cloneDeep, get, isEqual, invoke } from 'lodash';

import { FormattedMessage } from 'react-intl';
import { Input, Select, Popover } from 'antd';

import stateSend from 'services/stateSend';

const renderValue = (value, localize) => {
  if (localize) return <FormattedMessage id={`${localize}.${value}`} />;
  return value;
};

const getMultipleContent = (arr, localize) => (
  <div>
    {map(arr, (element, key) => (
      <p key={key}>{renderValue(element, localize)}</p>
    ))}
  </div>
);

const renderCol = (value, localize) =>
  Array.isArray(value) && value.length > 1 ? (
    <Popover placement="top" content={getMultipleContent(value, localize)}>
      <a>
        <FormattedMessage id="header.multiple" />
      </a>
    </Popover>
  ) : (
    renderValue(value, localize)
  );

const getDropDownBody = (options = []) =>
  options.map(({ value }) => (
    <Select.Option value={value} key={value}>
      {value}
    </Select.Option>
  ));

const witchComponent = ({ value, onChange, onKey, component, options }) => {
  if (component === 'Select') {
    return (
      <Select
        style={{ margin: '-5px 0', width: '100%' }}
        value={value}
        onKeyDown={onKey}
        onChange={val => onChange(val)}
        onClick={e => e.stopPropagation()}
      >
        {getDropDownBody(options)}
      </Select>
    );
  }
  return (
    <Input
      style={{ margin: '-5px 0' }}
      value={value}
      onKeyDown={onKey}
      onChange={e => onChange(e.target.value)}
      onClick={e => e.stopPropagation()}
    />
  );
};

const EditableCell = options => (
  <React.Fragment>
    {options.editable
      ? witchComponent(options)
      : renderCol(options.value, options.localizeFrom)}
  </React.Fragment>
);

export const keyPress = (e, key, actions) => {
  if (e.keyCode === 27) {
    actions.cancel(key);
  } else if (e.keyCode === 13) {
    actions.save(key);
  }
};

export const updateCacheData = (that, nextProps) => {
  that.cacheData =
    invoke(nextProps, 'list.data.map', item => ({ ...item })) || [];
};

export const checkCacheData = (that, nextProps) => {
  if (nextProps.list && !isEqual(that.props.list, nextProps.list)) {
    updateCacheData(that, nextProps);
  }
};

/**
 * @param value
 * @param key index row in table
 * @param column name column in table
 * @param sectionId only for table Sections
 */
const handleChange = function(value, key, column, sectionId) {
  const { props } = this;
  const newData = [...props.list.data];
  const target = sectionId
    ? find(find(newData, { id: sectionId }).questions, { key })
    : find(newData, { key });
  if (target) {
    target[column] = value;
    props.updateData(
      { data: newData, pagination: props.list.pagination },
      props.name,
    );
  }
};

export { EditableCell, handleChange };

const clearEditable = ({ target, sectionId, newData, key, cacheData }) => {
  if (!target.id && !sectionId) {
    newData.splice(findIndex(newData, { key }), 1);
  } else if (sectionId) {
    Object.assign(
      target,
      find(find(cacheData, { id: sectionId }).questions, { key }),
    );
  } else {
    Object.assign(target, find(cacheData, { key }));
  }
  delete target.editable;
};

const actionOfTable = {
  edit: function({ key, sectionId }) {
    const { props } = this;
    const newData = [...props.list.data];
    const target = sectionId
      ? find(find(newData, { id: sectionId }).questions, { key })
      : find(newData, { key });
    if (target) {
      newData.forEach(
        el =>
          el.editable &&
          clearEditable({
            ...el,
            target: el,
            newData,
            cacheData: this.cacheData,
          }),
      );
      target.editable = true;
      this.editable = true;
      this.cacheData.push(cloneDeep(target));
      props.updateData(
        { data: newData, pagination: props.list.pagination },
        props.name,
      );
    }
  },
  save: async function({ key, sectionId }) {
    const { action, name, list } = this.props;
    const newData = [...list.data];
    const target = sectionId
      ? find(find(newData, { id: sectionId }).questions, { key })
      : find(newData, { key });
    if (target) {
      try {
        if (target.id) {
          await stateSend(
            loading => this.setState({ loading }),
            action.put(target.id, target),
            { translate: this.props.translate },
          );
        } else {
          const resp = await stateSend(
            loading => this.setState({ loading }),
            action.post(target),
            { translate: this.props.translate },
          );
          target.id = resp.data.id;
        }
        this.editable = false;
        delete target.editable;
        this.cacheData = newData.map(item => ({ ...item }));
        this.props.updateData(
          { data: newData, pagination: list.pagination },
          name,
        );
      } catch (error) {
        console.error(error);
      }
    }
  },
  cancel: function({ key, sectionId }) {
    const { props } = this;
    const newData = [...props.list.data];
    const target = sectionId
      ? find(find(newData, { id: sectionId }).questions, { key })
      : find(newData, { key });
    if (target) {
      clearEditable({
        target,
        sectionId,
        newData,
        key,
        cacheData: this.cacheData,
      });
      this.editable = false;
      props.updateData(
        { data: newData, pagination: props.list.pagination },
        props.name,
      );
    }
  },
  remove: async function({ key, sectionId }) {
    const { action, name, list, translate, arrayField } = this.props;
    const newData = [...list.data];
    if (sectionId) {
      const subArray = get(
        newData[findIndex(newData, { id: sectionId })],
        arrayField,
      );
      const index = findIndex(subArray, { key });
      await stateSend(
        loading => this.setState({ loading }),
        await action.deleteRequest(subArray[index].id),
      );
      subArray.splice(index, 1);
    } else {
      const index = findIndex(newData, { key });
      await stateSend(
        loading => this.setState({ loading }),
        action.deleteRequest(newData[index].id),
        { translate },
      );
      newData.splice(index, 1);
    }
    this.props.updateData({ data: newData, pagination: list.pagination }, name);
  },
};

export default actionOfTable;
