import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Table, Spin } from 'antd';
import { get } from 'lodash';

import addLoad from 'services/decorators/addLoad';
import { wrapRequest } from 'utils/ajax';
import { checkLoadPropsName } from 'services/mapReduce';
import { DEFAULT_PROPS_TABLE, DEFAULT_LIST_SIZE } from 'constants/common';

import './style.less';

@addLoad()
export default class TableSelections extends PureComponent {
  static propsTypes = {
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    sendParams: PropTypes.object,
    sendData: PropTypes.object,
    renderRow: PropTypes.func,
    getButtons: PropTypes.func,
    isLoading: PropTypes.bool,
    selectionKey: PropTypes.string,
  };

  static defaultProps = {
    method: 'GET',
    selectionKey: 'id',
  };

  state = { selectedRowKeys: [], osces: {} };

  get selectedPropKeys() {
    const {
      selectedRowKeys: keys,
      osces: { data },
    } = this.state;
    return keys.map(key => get(data[key], `[${this.props.selectionKey}]`));
  }

  get canLoadMore() {
    const pagination = get(this.state, 'osces.pagination') || {};
    return (
      !!pagination.pageSize &&
      (pagination.page + 1) * DEFAULT_LIST_SIZE < pagination.total
    );
  }

  componentWillMount() {
    window.addEventListener('scroll', this.onTableScroll, true);
  }

  componentDidMount() {
    this.loadList();
  }

  componentWillReceiveProps(nextProps) {
    if (
      checkLoadPropsName(this.props, nextProps, [
        'sendParams.semesterId',
        'url',
      ])
    ) {
      this.loadList(nextProps);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onTableScroll, true);
  }

  loadList = async (props = this.props, page = 0) => {
    const body = await props.stateloading(
      wrapRequest({
        method: props.method,
        url: props.url,
        params: {
          page: page,
          pageSize: DEFAULT_LIST_SIZE,
          ...props.sendParams,
        },
        data: props.sendData,
      }),
    );
    let newData = [];
    if (!page) {
      newData = { data: body.data };
      this.setState({ selectedRowKeys: [] });
    } else {
      newData = { data: this.state.osces.data.concat(body.data) };
    }
    newData.pagination = body.pagination;
    this.setState({ osces: newData });
  };

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  onTableScroll = () => {
    const { body } = document;
    if (
      !this.props.loading &&
      this.canLoadMore &&
      window.scrollY > body.scrollHeight - body.offsetHeight - 30
    ) {
      this.loadList(this.props, this.state.osces.pagination.page + 1);
    }
  };

  get buttons() {
    const { props, state } = this;
    if (!props.getButtons) {
      return null;
    }
    return props.getButtons({
      selected: this.selectedPropKeys,
      loading: props.loading,
      disabled: props.loading || !state.selectedRowKeys.length,
    });
  }

  render() {
    const { props, state } = this;
    const { selectedRowKeys } = state;
    const columns = [
      {
        title: props.title,
        dataIndex: '',
        render: props.renderRow,
      },
    ];
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    return (
      <Spin spinning={props.isLoading || props.loading} delay={200}>
        {this.buttons}
        <div className="table-with-selection">
          <Table
            loading={props.loading}
            columns={columns}
            {...DEFAULT_PROPS_TABLE}
            rowSelection={rowSelection}
            dataSource={state.osces.data}
          />
        </div>
      </Spin>
    );
  }
}
