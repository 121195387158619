import React, { Component } from 'react';
import { history } from 'store';
import { Form, Row, Col, Spin, notification } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { msgLocalError } from 'services/errorHandler';
import { clinicCRUD } from 'actions/cruds';
import i18n from 'services/i18n';
import TablePanel from 'components/TablePanel/index';

import { updateData } from 'actions/updateData';
import { getClinicProfile } from 'actions/examiners';
import { onlyNumber } from 'services/validations';
import EditableLable from 'components/Form/formEditableLabel';
import profileFormDecorator from 'services/decorators/profileFormDecorator';
import DeleteButton from 'components/DeleteButton';
import 'components/Form/formsStyles.less';

const mapStateToProps = ({ runtime }) => ({
  clinic: runtime.clinicProfileData,
  loaded: runtime.clinicProfileLoaded,
  loading: runtime.clinicProfileLoading,
  error: runtime.clinicProfileError,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ dispatch, getClinicProfile, updateData }, dispatch);

@i18n('error')
@connect(
  mapStateToProps,
  mapDispatchToProps,
)
@profileFormDecorator()
export default class EditClinic extends Component {
  componentDidMount() {
    this.props.getClinicProfile(this.props.id);
  }

  state = { loading: false };

  deleteClinic = async () => {
    try {
      await clinicCRUD.deleteRequest(this.props.id);
      history.push('/examiners/clinics');
      notification.success({
        message: this.props.translate('success'),
      });
    } catch (e) {
      msgLocalError({ error: e, translate: this.props.translate });
    }
  };

  render() {
    const { clinic } = this.props;
    return (
      <Spin spinning={this.props.loading || this.state.loading}>
        {this.props.loaded && (
          <Form
            onSubmit={this.props.wrapSubmit(
              values => clinicCRUD.put(this.props.id, values),
              newData => this.props.updateData(newData, 'clinicProfile'),
            )}
          >
            <TablePanel className="clinic_panel_line">
              <Col span={18}>
                <EditableLable
                  name="name"
                  className="card-username"
                  initialValue={clinic.name}
                  withoutLabel
                  fullWidth
                />
              </Col>
              <DeleteButton action={this.deleteClinic} />
            </TablePanel>
            <Row>
              <p className="clinic_general_header">General Information</p>
            </Row>
            <Row>
              <Col span={7}>
                <EditableLable name="street" initialValue={clinic.street} />
                <EditableLable
                  name="postalCode"
                  initialValue={clinic.postalCode}
                  validator={{
                    func: onlyNumber,
                    message: 'notValidPostalCode',
                  }}
                />
                <EditableLable name="city" initialValue={clinic.city} />
              </Col>
            </Row>
          </Form>
        )}
      </Spin>
    );
  }
}
