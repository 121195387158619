import React from 'react';
import PropTypes from 'prop-types';
import {
  Editor,
  EditorState,
  RichUtils,
  convertToRaw,
  convertFromRaw,
  Modifier,
} from 'draft-js';
import { Modal, Button, Spin } from 'antd';
import { stateToHTML } from 'draft-js-export-html';
import createStyles from 'draft-js-custom-styles';
import { get } from 'lodash';

/** Actions */
import { getTemplate, restoreTemplate, saveTemplate } from 'actions/outputs';

/** Constants */
import {
  STYLE_MAP,
  STATIC_INVITE,
  STATIC_SCHEDULE,
} from 'constants/editorTemplate';

/** Services */
import addLoad from 'services/decorators/addLoad';

import Toolbar from './Toolbar';

const { styles, exporter } = createStyles([
  'font-size',
  'color',
  'font-family',
]);

const getBlockStyle = block => {
  switch (block.getType()) {
    case 'alignleft':
      return 'align-left';
    case 'aligncenter':
      return 'align-center';
    case 'alignright':
      return 'align-right';
    default:
      return null;
  }
};

@addLoad({ i18Name: 'output' })
export default class ModalEditor extends React.PureComponent {
  static propTypes = {
    type: PropTypes.string,
    name: PropTypes.string,
    show: PropTypes.bool,
    close: PropTypes.func,
    loadPDF: PropTypes.func,
    printCopiesParams: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
      selectionState: null,
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.show && !this.props.show) {
      this.loadTemplate();
    }
  }

  get generateStaticValue() {
    const list = this.props.type === 'invite' ? STATIC_INVITE : STATIC_SCHEDULE;
    return list.map(item =>
      item.name ? (
        <li key={item.value}>
          <p className="template-static-data_name">{item.name}</p>
          <div className="template-static-data_block">
            {item.children.map(child => (
              <p key={child.value}>
                <span className="template-static-data_title">
                  {child.value}
                </span>
                <span className="template-static-data_value">
                  {child.label}
                </span>
              </p>
            ))}
          </div>
        </li>
      ) : (
        <li className="template-static-data_item">
          <span className="template-static-data_title">{item.value}</span>
          <span className="template-static-data_value">{item.label}</span>
        </li>
      ),
    );
  }

  loadTemplate = async () => {
    const { name, type, printCopiesParams } = this.props;
    const id =
      type === 'invite'
        ? printCopiesParams.semesterId
        : printCopiesParams.osceId;
    try {
      const resp = await this.props.stateloading(getTemplate(type, name, id));
      const blocksFromRaw = convertFromRaw(JSON.parse(resp.editorTemplate));
      this.setState({
        editorState: EditorState.createWithContent(blocksFromRaw),
      });
    } catch (e) {
      this.setState({
        editorState: EditorState.createEmpty(),
      });
    }
  };

  onOk = type => async () => {
    const { props } = this;
    const blockStyleFn = block => {
      switch (block.getType()) {
        case 'alignleft':
          return {
            attributes: { class: 'alignleft' },
            style: { textAlign: 'left' },
          };
        case 'aligncenter':
          return {
            attributes: { class: 'aligncenter' },
            style: { textAlign: 'center' },
          };
        case 'alignright':
          return {
            attributes: { class: 'alignright' },
            style: { textAlign: 'right' },
          };
        default:
          return null;
      }
    };
    const inlineStyles = exporter(this.state.editorState);
    inlineStyles.SUBSCRIPT = {
      style: { fontSize: '0.6em', verticalAlign: 'sub' },
    };
    inlineStyles.SUPERSCRIPT = {
      style: { fontSize: '0.6em', verticalAlign: 'super' },
    };
    const contentState = this.state.editorState.getCurrentContent();
    const template = stateToHTML(contentState, {
      inlineStyles,
      blockStyleFn,
    });
    const note = { content: convertToRaw(contentState) };
    note.content = JSON.stringify(note.content);
    const data = {
      html: template,
      editorTemplate: note.content,
    };
    const id =
      props.type === 'invite'
        ? props.printCopiesParams.semesterId
        : props.printCopiesParams.osceId;
    if (type === 'print') {
      await props.stateloading(this.props.loadPDF(data));
      this.setState({ editorState: EditorState.createEmpty() });
      this.props.close();
    } else {
      props.stateloading(saveTemplate(props.type, props.name, id, data));
    }
  };

  onRestore = async () => {
    const { props } = this;
    const id =
      props.type === 'invite'
        ? props.printCopiesParams.semesterId
        : props.printCopiesParams.osceId;
    const resp = await props.stateloading(
      restoreTemplate(props.type, props.name, id),
    );
    const blocksFromRaw = convertFromRaw(
      JSON.parse(get(resp, 'editorTemplate')),
    );
    this.setState({
      editorState: EditorState.createWithContent(blocksFromRaw),
    });
  };

  onChange = editorState => this.setState({ editorState });

  onTab = e => {
    const maxDepth = 4;
    this.onChange(RichUtils.onTab(e, this.state.editorState, maxDepth));
  };

  onChangeStyle = (style, type) => () => {
    const { selectionState } = this.state;
    let editorState = null;
    let newEditorState = null;
    switch (type) {
      case 'inline':
        this.onChange(
          RichUtils.toggleInlineStyle(this.state.editorState, style),
        );
        break;
      case 'block':
        this.onChange(RichUtils.toggleBlockType(this.state.editorState, style));
        break;
      case 'fontSize':
        editorState = this.clearStyle('FONT_SIZE_');
        newEditorState = EditorState.forceSelection(
          editorState,
          selectionState,
        );
        newEditorState = styles.fontSize.toggle(newEditorState, `${style}px`);
        this.onChange(
          RichUtils.toggleInlineStyle(newEditorState, `FONT_SIZE_${style}`),
        );
        break;
      case 'fontFamily':
        editorState = this.clearStyle('FONT_FAMILY_');
        newEditorState = EditorState.forceSelection(
          editorState,
          selectionState,
        );
        newEditorState = styles.fontFamily.toggle(newEditorState, style);
        this.onChange(
          RichUtils.toggleInlineStyle(
            newEditorState,
            `FONT_FAMILY_${style.toUpperCase().replace(/[ \t]/g, '_')}`,
          ),
        );
        break;
      case 'color':
        editorState = this.clearStyle('COLOR_');
        newEditorState = EditorState.forceSelection(
          editorState,
          selectionState,
        );
        newEditorState = styles.color.toggle(newEditorState, style);
        this.onChange(
          RichUtils.toggleInlineStyle(
            newEditorState,
            `COLOR_${style.toUpperCase()}`,
          ),
        );
        break;
    }
  };

  clearStyle = prefix => {
    const { editorState, selectionState } = this.state;
    const contentState = editorState.getCurrentContent();
    const styles = editorState.getCurrentInlineStyle();

    const removeStyles = styles.reduce((state, style) => {
      if (style.indexOf(prefix) > -1) {
        return Modifier.removeInlineStyle(state, selectionState, style);
      }
      return Modifier.applyInlineStyle(state, selectionState, style);
    }, contentState);

    const removeBlock = Modifier.setBlockType(
      removeStyles,
      selectionState,
      'unstyled',
    );

    return EditorState.push(editorState, removeBlock);
  };

  focus = () => {
    this.editor.focus();
  };

  render() {
    const { props } = this;
    const currentStyle = this.state.editorState.getCurrentInlineStyle();
    const selection = this.state.editorState.getSelection();
    const blockType = this.state.editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey())
      .getType();
    return (
      <Modal
        width={900}
        footer={null}
        title={props.translate('template')}
        visible={props.show}
        onCancel={this.props.close}
      >
        <Spin spinning={props.loading}>
          <Toolbar
            onChangeStyle={this.onChangeStyle}
            blockType={blockType}
            currentStyle={currentStyle}
          />
          <div className="template-editor" onClick={this.focus}>
            <Editor
              blockStyleFn={getBlockStyle}
              customStyleMap={STYLE_MAP}
              editorState={this.state.editorState}
              onChange={this.onChange}
              onTab={this.onTab}
              onBlur={() => {
                const selectionState = this.state.editorState.getSelection();
                this.setState({ selectionState });
              }}
              ref={element => {
                this.editor = element;
              }}
            />
          </div>
          <div className="template-buttons-panel">
            <Button
              icon="save"
              className="template-buttons-panel_btn"
              onClick={this.onOk()}
            >
              Save Template
            </Button>
            <Button
              icon="undo"
              className="template-buttons-panel_btn"
              onClick={this.onRestore}
            >
              Restore Template
            </Button>
            <Button
              icon="printer"
              style={{ float: 'right' }}
              onClick={this.onOk('print')}
              className="template-buttons-panel_btn"
            >
              Print Template
            </Button>
          </div>
          <div className="template-static-data">
            <p>
              <strong>
                Please do not change or remove the following variables!
              </strong>
            </p>
            <ul>{this.generateStaticValue}</ul>
            {this.props.type === 'invite' ? (
              [
                <p>
                  <strong>Display format for assignments:</strong>
                </p>,
                <p>Date, Start time, End time, Role, Room number</p>,
                <p>
                  <strong>
                    Room number will only appear if recepient is an examiner.
                  </strong>
                </p>,
              ]
            ) : (
              <p>
                [TITLE SEPARATOR] [OSCE_DAY SEPARATOR] [SCHEDULE SEPARATOR]
                [BREAK SEPARATOR]
              </p>
            )}
          </div>
        </Spin>
      </Modal>
    );
  }
}
