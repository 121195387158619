import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { STATION_TYPES } from 'constants/common';

/** Components */
import TimeSlot from './TimeSlot';

const StationTemplate = ({
  stationId,
  stationType,
  isFirst,
  timeSlot,
  rotations,
  isSpPaused,
  circuitId,
  osceDayId,
}) => {
  return (
    <TimeSlot
      isSpPaused={isSpPaused}
      countStudents={get(timeSlot, 'countStudentsInRotation')}
      timeSlot={timeSlot}
      rotations={rotations}
      stationType={stationType}
      stationId={stationId}
      circuitId={circuitId}
      osceDayId={osceDayId}
    />
  );
};

StationTemplate.propTypes = {
  timeSlot: PropTypes.object.isRequired,
  stationId: PropTypes.number.isRequired,
  stationType: PropTypes.string.isRequired,
  isFirst: PropTypes.bool.isRequired,
  isSpPaused: PropTypes.bool,
  countStudents: PropTypes.number,
  osceDayId: PropTypes.number,
  rotations: PropTypes.array,
};

export default StationTemplate;
