import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Spin } from 'antd';

/** Constants */
import * as SIDEBARS from 'constants/sidebars';

/** Components **/
import MainLayout from 'components/MainLayout';
import { examinersCRUD } from 'actions/cruds';
import Table from 'components/Table/index';
import Card from 'components/Form/Card';

/** Services **/
import i18nTable from 'services/i18nTable';
import errorCatcher from 'services/decorators/errorCatcher';

/** Actions **/
import { getExaminerProfile } from 'actions/examiners';

import ExaminerForm from './editExaminer';

const mapStateToProps = ({ runtime }) => ({
  clinic: runtime.examinerProfileData,
  loaded: runtime.examinerProfileLoaded,
  loading: runtime.examinerProfileLoading,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ dispatch, getExaminerProfile }, dispatch);

@i18nTable('examinersOSCEColumns')
@connect(
  mapStateToProps,
  mapDispatchToProps,
)
@errorCatcher('examinerProfile')
export default class Profile extends Component {
  componentDidMount() {
    this.props.getExaminerProfile(this.props.match.params.id);
  }

  render() {
    const { props } = this;
    return (
      <MainLayout menu={SIDEBARS.EXAMINERS_MENU} nameMenu="examiners_sidebar">
        <Spin spinning={props.loading}>
          {props.loaded && (
            <ExaminerForm data={props.clinic} id={props.match.params.id} />
          )}
        </Spin>
        <Card from="cards" value="OSCEParticipation" className="card-for-table">
          <Table
            name="osceExaminers"
            action={examinersCRUD}
            additionalUrl={`${props.match.params.id}/osce`}
            columns={props.examinersOSCEColumns}
          />
        </Card>
      </MainLayout>
    );
  }
}
