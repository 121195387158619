import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Row, Col, Input, Button, Select, DatePicker } from 'antd';
/* Services */
import i18n from 'services/i18n';
import { formatDateStr } from 'services/format';
// Options
import { GENDER } from 'constants/options';

@i18n('form')
@Form.create({})
export default class AddSP extends Component {
  static propTypes = {
    isVisible: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  handleFormSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // convert birthday to send
        values.birthday = formatDateStr(values.birthday);
        this.props.onSubmit(values);
      }
    });
  };

  handleFormCancel = e => {
    this.props.form.resetFields();
    this.props.onClose();
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Modal
        destroyOnClose
        title={this.props.translate('addNewSP', 'modalForm')}
        visible={this.props.isVisible}
        onCancel={this.handleFormCancel}
        bodyStyle={{ padding: '0px' }}
        footer={null}
      >
        <Form onSubmit={this.handleFormSubmit}>
          <div className="form_content">
            <Row gutter={20}>
              <Col span={12}>
                <Form.Item label={this.props.translate('firstName')}>
                  {getFieldDecorator('firstName', {
                    rules: [
                      {
                        required: true,
                        message: this.props.translate('insert'),
                      },
                    ],
                  })(<Input type="text" />)}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item required label={this.props.translate('lastName')}>
                  {getFieldDecorator('lastName', {
                    rules: [
                      {
                        required: true,
                        message: this.props.translate('insert'),
                      },
                    ],
                  })(<Input type="text" />)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col span={12}>
                <Form.Item label={this.props.translate('email')}>
                  {getFieldDecorator('email', {
                    rules: [
                      {
                        required: true,
                        message: this.props.translate('insert'),
                      },
                    ],
                  })(<Input type="email" />)}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item required label={this.props.translate('gender')}>
                  {getFieldDecorator('gender', {
                    initialValue: 'MALE',
                    rules: [
                      {
                        required: true,
                        message: this.props.translate('checkboxGroup'),
                      },
                    ],
                  })(
                    <Select placeholder={this.props.translate('chooseGender')}>
                      {GENDER.map(item => (
                        <Select.Option key={item.value} value={item.value}>
                          {this.props.translate(item.value, 'form')}
                        </Select.Option>
                      ))}
                    </Select>,
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col span={12}>
                <Form.Item label={this.props.translate('city')}>
                  {getFieldDecorator('city', {
                    rules: [
                      {
                        required: true,
                        message: this.props.translate('insert'),
                      },
                    ],
                  })(<Input type="text" />)}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item required label={this.props.translate('birthday')}>
                  {getFieldDecorator('birthday', {
                    rules: [
                      {
                        required: true,
                        message: this.props.translate('insert'),
                      },
                    ],
                  })(
                    <DatePicker
                      style={{ width: '100%' }}
                      format="DD.MM.YYYY"
                    />,
                  )}
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className="ant-modal-footer footer_max_width">
            <Button onClick={this.handleFormCancel}>
              {this.props.translate('cancel')}
            </Button>
            <Button htmlType="submit" className="blueBtn">
              {this.props.translate('save')}
            </Button>
          </div>
        </Form>
      </Modal>
    );
  }
}
