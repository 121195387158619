import React, { Component, PureComponent } from 'react';
import moment from 'moment';
import cn from 'classnames';
import { set, invoke } from 'lodash';
import PropTypes from 'prop-types';
import { Form, Spin, DatePicker, TimePicker } from 'antd';

import { TIME_SEND, DATE_SEND } from 'constants/formats';
import i18n from 'services/i18n';
import { openDatePicker, openTimePicker } from 'services/form';
import EditableBtns from './FormEditableBtns';

const FormItem = Form.Item;

export class StaticValue extends PureComponent {
  get format() {
    return this.props.outputFormat || this.props.format;
  }

  render() {
    const { props } = this;
    const { value } = props;
    return (
      <span
        onClick={props.onClick}
        className={props.className || 'card-no-editable'}
      >
        {invoke(props, 'render', value) || moment.isMoment(value)
          ? moment(value).format(this.format)
          : value}
      </span>
    );
  }
}

@i18n('form')
export default class EditableDatePicker extends Component {
  static propTypes = {
    withoutLabel: PropTypes.bool, // component will be rendered without label
    title: PropTypes.string, // it will be translated by i18n form
    name: PropTypes.string.isRequired, // it will be key on submit object, if !title name will be translated to
    fullWidth: PropTypes.bool, // component will take all width
    disabled: PropTypes.bool, //  disable edit onClick (just label)
    className: PropTypes.string,
    popupClassName: PropTypes.string,
    type: PropTypes.string, // html type
    autoComplete: PropTypes.string,
    i18name: PropTypes.string,
    format: PropTypes.string,
    outputFormat: PropTypes.string,
    staticRenderFunction: PropTypes.func,
    required: PropTypes.bool, // form option, if your key is mandatory
    precision: PropTypes.number, // form option, if your key is mandatory
    rules: PropTypes.array, // ant form rules
    initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // ant form initialValue
  };

  static defaultProps = {
    popupClassName: 'editable-time-popup',
  };

  static contextTypes = {
    form: PropTypes.object,
    getStatus: PropTypes.func,
    setStatus: PropTypes.func,
    submit: PropTypes.func,
    disabled: PropTypes.bool,
  };

  state = { editable: false, loading: false };

  constructor(props) {
    super(props);
    this.checkFormat();
  }

  checkFormat = () => {
    const { props } = this;
    if (props.format) {
      this.format = props.format;
    } else {
      this.format = DATE_SEND;
      if (props.type === 'time') {
        this.format = TIME_SEND;
      }
    }
  };

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.state.editable && this.props.name !== nextContext.getStatus()) {
      this.resetStopEdit();
    }
  }

  componentDidUpdate(nextProps, prevState) {
    if (this.state.editable && !prevState.editable) {
      this.focus();
    }
  }

  focus = () => {
    if (this.props.type === 'time') {
      openTimePicker(this.refInput);
    }
    openDatePicker(this.refInput);
  };

  saveStartEdit = () => {
    if (this.props.disabled || this.context.disabled) return;
    this.setState({ editable: true });
    this.context.setStatus && this.context.setStatus(this.props.name);
  };

  stopEdit = () => this.setState({ editable: false });

  resetStopEdit = () => {
    const { setFieldsValue } = this.context.form;
    const newValue = {};
    set(newValue, this.props.name, this.initialValue);
    setFieldsValue(newValue);
    this.stopEdit();
  };

  saveInput = ref => ref && (this.refInput = ref);

  get initialValue() {
    const { props } = this;
    const { initialValue } = props;
    if (!initialValue) {
      return undefined;
    }
    if (typeof initialValue === 'string') {
      return moment(initialValue, this.format);
    }
    return initialValue;
  }

  get btns() {
    if (this.props.disabled || this.context.disabled) return null;
    return (
      <EditableBtns
        editable={this.state.editable}
        saveStartEdit={this.saveStartEdit}
        resetStopEdit={this.resetStopEdit}
      />
    );
  }

  get input() {
    const { props, format, saveInput } = this;
    const opt = {
      format,
      ref: saveInput,
      popupClassName: props.popupClassName,
    };
    if (props.type === 'time') {
      return <TimePicker {...opt} />;
    }
    if (props.type === 'range') {
      return <DatePicker.RangePicker {...opt} />;
    }
    return <DatePicker {...opt} />;
  }

  get field() {
    const { props } = this;
    let rules = props.rules || [];
    let { className } = props;
    if (props.withoutLabel && !className) className = 'form_header';
    if (props.required)
      rules = [
        {
          required: true,
          message: props.translate('insert', 'error'),
        },
      ];
    if (props.validator) {
      rules = rules.concat([
        {
          validator: props.validator.func,
          message: props.translate(props.validator.message, 'error'),
        },
      ]);
    }
    return this.context.form.getFieldDecorator(props.name, {
      rules,
      initialValue: this.initialValue,
    })(
      this.state.editable ? (
        this.input
      ) : (
        <StaticValue
          onClick={this.saveStartEdit}
          render={props.staticRenderFunction}
          format={this.format}
          outputFormat={props.outputFormat}
          className={className}
        />
      ),
    );
  }

  get label() {
    const { props } = this;
    if (!props.withoutLabel) {
      return (
        // eslint-disable-line
        <span onClick={this.saveStartEdit}>
          {props.translate(props.title || props.name, props.i18name)}
        </span>
      );
    }
    return false;
  }

  render() {
    const { props } = this;
    const { fullWidth } = props;
    return (
      <FormItem
        colon={false}
        label={this.label}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: fullWidth ? 24 : 14 }}
        className={cn({
          'card-item': !fullWidth,
          'card-editable-width': this.state.editable && !fullWidth,
          'form-full-width': fullWidth,
        })}
      >
        <Spin spinning={this.state.loading} delay={500}>
          {this.field}
          {this.btns}
        </Spin>
      </FormItem>
    );
  }
}
