import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Select, Button, Spin, Col } from 'antd';

import i18n from 'services/i18n';
import { HAS_CRITERIA, WORK_PERMISSION, FILTER_TYPE } from 'constants/options';

@i18n('form')
@Form.create()
class WorkPermissionFilter extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    form: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired,
  };

  static defaultProps = {
    loading: false,
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const data = {
          criterion: {
            isSimPatHasCriterion: values.isSimPatHasCriterion === 'has', // convert to bool
            workPermission: values.workPermission,
          },
        };
        this.props.onSubmit(FILTER_TYPE.WORK_PERMISSION, data);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Spin spinning={this.props.loading}>
        <Form
          className="criteria-form"
          layout="horizontal"
          onSubmit={this.handleSubmit}
        >
          <Input.Group>
            <Col span={10}>
              <Form.Item wrapperCol={{ span: 24 }} className="form-full-width">
                {getFieldDecorator('isSimPatHasCriterion', {
                  initialValue: 'has',
                })(
                  <Select placeholder={this.props.translate('chooseOptions')}>
                    {HAS_CRITERIA.map(item => (
                      <Select.Option key={item.value} value={item.value}>
                        {this.props.translate(item.value)}
                      </Select.Option>
                    ))}
                  </Select>,
                )}
              </Form.Item>
            </Col>
            <Col span={14}>
              <Form.Item wrapperCol={{ span: 24 }} className="form-full-width">
                {getFieldDecorator('workPermission', {
                  initialValue: 'B',
                })(
                  <Select
                    placeholder={this.props.translate('chooseWorkPermission')}
                  >
                    {WORK_PERMISSION.map(item => (
                      <Select.Option key={item.value} value={item.value}>
                        {item.value}-
                        {this.props.translate('permission', 'patient')}
                      </Select.Option>
                    ))}
                  </Select>,
                )}
              </Form.Item>
            </Col>
          </Input.Group>
          <Button htmlType="submit" icon="plus" className="add-btn">
            {this.props.translate('add')}
          </Button>
        </Form>
      </Spin>
    );
  }
}

export default WorkPermissionFilter;
