import { combineReducers } from 'redux';
import {
  EXAMINERS_REQUEST_START,
  FETCH_EXAMINERS_SUCCESS,
  FETCH_EXAMINERS_ERROR,
  LOAD_MORE_EXAMINERS_SUCCESS,
} from 'constants/examiners';

const loading = (state = false, action) => {
  switch (action.type) {
    case EXAMINERS_REQUEST_START:
      return true;

    case FETCH_EXAMINERS_SUCCESS:
    case FETCH_EXAMINERS_ERROR:
    case LOAD_MORE_EXAMINERS_SUCCESS:
      return false;

    default:
      return state;
  }
};

const errors = (state = {}, action) => {
  switch (action.type) {
    case FETCH_EXAMINERS_ERROR:
      return action.error;
    default:
      return state;
  }
};

const data = (state = [], action) => {
  switch (action.type) {
    case FETCH_EXAMINERS_SUCCESS:
      return action.payload.examiners.data;

    case LOAD_MORE_EXAMINERS_SUCCESS:
      return state.concat(action.payload.examiners.data);

    default:
      return state;
  }
};

const examiners = combineReducers({
  loading,
  errors,
  data,
});

export default examiners;
