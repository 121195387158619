import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, message } from 'antd';
// Services
import api from 'services/api';
import i18n from 'services/i18n';
// Utils
import { downloadFile } from 'utils/downloadFile';
// Components
import SelectTable from 'components/SelectTable';
import ModalEditor from './modalEditor';

const mapStateToProps = state => ({
  language: state.localization.lang,
});

@i18n('output')
@connect(mapStateToProps)
class StudentsTable extends PureComponent {
  static propTypes = {
    osceId: PropTypes.number.isRequired,
  };

  constructor(props) {
    super();

    this.columns = [
      {
        title: props.translate('students'),
        dataIndex: 'text',
      },
    ];

    this.state = {
      data: [],
      selectedStudents: [],
      showEditor: false,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.osceId !== this.props.osceId) {
      this.loadData();
    }
  }

  loadData = () => {
    api.getStudentsByOsce(this.props.osceId).then(res => {
      this.setState({ data: res.data.data });
    });
  };

  handleOpenEditor = () => this.setState({ showEditor: true });

  handleCloseEditor = () => this.setState({ showEditor: false });

  handleSelectStudents = selectedStudents =>
    this.setState({ selectedStudents });

  handleloadPDF = template => {
    const data = {
      ids: this.state.selectedStudents,
      template,
    };

    const locale = this.props.language === 'en' ? 'ENGLISH' : 'GERMAN';

    api
      .printStudentsSchedule(this.props.osceId, locale, data)
      .then(res =>
        downloadFile(res.data, `student_schedule.pdf`, 'application/pdf'),
      )
      .catch(err => message.error(this.props.translate('all', 'error')));
  };

  handleLoadQR = () => {
    const data = { ids: this.state.selectedStudents };
    api
      .printQRCode(this.props.osceId, 'STUDENT', data)
      .then(res => downloadFile(res.data, 'studentQR.pdf', 'application/pdf'))
      .catch(err => message.error(this.props.translate('all', 'error')));
  };

  render() {
    const dataSource = this.state.data.map(item => ({
      ...item,
      key: item.studentId,
      text: `${item.lastName} ${item.firstName}`,
    }));

    return (
      <React.Fragment>
        <ModalEditor
          type="schedule"
          name="student"
          loadPDF={this.handleloadPDF}
          printCopiesParams={{ osceId: this.props.osceId }}
          show={this.state.showEditor}
          close={this.handleCloseEditor}
        />
        <Button
          className="sendInvitations-btn"
          disabled={!this.state.selectedStudents.length}
          onClick={this.handleOpenEditor}
        >
          {this.props.translate('printCopies')}
        </Button>
        <Button
          className="sendInvitations-btn"
          disabled={!this.state.selectedStudents.length}
          onClick={this.handleLoadQR}
        >
          {this.props.translate('exportQRCode')}
        </Button>
        <div className="table-with-selection">
          <SelectTable
            columns={this.columns}
            dataSource={dataSource}
            onSelect={this.handleSelectStudents}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default StudentsTable;
