import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { debounce } from 'lodash';
import { v1 as uuidv1 } from 'uuid';
import { Table, Spin, message } from 'antd';
import { getAllRolesOSCEs, lazyLoadRolesOSCEs } from 'actions/role';
// Services
import i18n from 'services/i18n';

const mapStateToProps = state => ({
  osces: state.role.osces,
  loading: state.role.loading,
});

const mapDispatchToProps = {
  getAllRolesOSCEs,
  lazyLoadRolesOSCEs,
};

@withRouter
@connect(
  mapStateToProps,
  mapDispatchToProps,
)
@i18n('osceColumns')
export default class RoleOSCEs extends Component {
  static propTypes = {
    osces: PropTypes.array.isRequired,
    loading: PropTypes.bool,
  };

  static defaultProps = {
    loading: false,
  };

  constructor(props) {
    super(props);

    this.columns = [
      {
        title: props.translate('dates'),
        dataIndex: 'osceDayDate',
        sorter: true,
        key: 'date',
      },
      {
        title: props.translate('osces'),
        dataIndex: 'osceName',
        sorter: true,
        key: 'osceName',
        render: (text, osce) => (
          <Link
            to={`/osce-administration/${osce.semesterId}/osce/basic-data/${
              osce.osceId
            }`}
          >
            {text}
          </Link>
        ),
      },
      {
        title: props.translate('sps'),
        dataIndex: 'spsName',
        sorter: true,
        key: 'firstName',
        render: (text, osce) => (
          <Link to={`/sp/patient-profile/${osce.spId}/patient-profile`}>
            {text}
          </Link>
        ),
      },
    ];

    this.state = {
      page: 0,
      pageSize: 30,
      total: 0,
      sortProp: 'date',
      sortDirection: 'DESC',
      preventLazyLoad: false,
    };
  }

  componentDidMount() {
    this.handleUpdateTableData();
    window.addEventListener('scroll', this.handlePageScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handlePageScroll);
  }

  handlePageScroll = debounce(e => {
    const { body } = document;
    if (
      !this.state.preventLazyLoad &&
      this.props.osces.length < this.state.total &&
      window.scrollY > body.scrollHeight - body.offsetHeight - 30
    ) {
      this.setState(
        { page: this.state.page + 1, preventLazyLoad: true },
        this.lazyLoadOSCEs,
      );
    }
  }, 100);

  lazyLoadOSCEs = () => {
    const { page, pageSize, sortDirection, sortProp } = this.state;
    this.props
      .lazyLoadRolesOSCEs({
        roleId: this.props.match.params.id,
        page,
        pageSize,
        sortProp,
        sortDirection,
      })
      .then(res => {
        this.setState({ total: res.pagination.total, preventLazyLoad: false });
      })
      .catch(err => message.error(this.props.translate('all', 'error')));
  };

  handleUpdateTableData = () => {
    const { page, pageSize, sortProp, sortDirection } = this.state;
    this.props
      .getAllRolesOSCEs({
        roleId: this.props.match.params.id,
        page,
        pageSize,
        sortProp,
        sortDirection,
      })
      .then(res => {
        this.setState({ total: res.pagination.total });
      })
      .catch(err => message.error(this.props.translate('all', 'error')));
  };

  handleSort = (pagination, filters, sorter) => {
    // If sorting column is enabled
    if (sorter.columnKey) {
      const sortDirection = sorter.order === 'ascend' ? 'ASC' : 'DESC';
      const sortProp = sorter.column.key;
      this.setState(
        { sortDirection, sortProp, page: 0 },
        this.handleUpdateTableData,
      );
    }
  };

  render() {
    const dataSource = this.props.osces.map(osce => ({
      ...osce,
      key: uuidv1(),
      spsName: `${osce.spFirstName} ${osce.spLastName}`,
    }));

    return (
      <Spin spinning={this.props.loading}>
        <div className="table-with-action">
          <Table
            bordered
            pagination={false}
            canDrag={false}
            columns={this.columns}
            dataSource={dataSource}
            onChange={this.handleSort}
          />
        </div>
      </Spin>
    );
  }
}
