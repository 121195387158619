import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Spin, Button, Icon, Popconfirm, message, Tooltip } from 'antd';
import { connect } from 'react-redux';
// Services
import i18n from 'services/i18n';
import { msgLocalError } from 'services/errorHandler';
// Actions
import {
  getQuestionsGroupedBySection,
  createSection,
  changeQueueSection,
  updateSection,
  deleteSection,
  createNewQuesiton,
} from 'actions/section';
// Components
import EditDragTable from 'components/EditDragTable';
import ExpandIcon from 'components/Table/ExpandIcon';
import Search from 'components/Search';
import QuestionModal from './QuestionModal';
import TableQuestion from './TableQuestion';
import AddSection from './AddSection';

const mapStateToProps = state => ({
  sections: state.questionnaire.sections,
  loading: state.questionnaire.loading,
});

const mapDispatchToProps = {
  getQuestionsGroupedBySection,
  createSection,
  changeQueueSection,
  updateSection,
  deleteSection,
  createNewQuesiton,
};

@i18n('sectionColumns')
@connect(
  mapStateToProps,
  mapDispatchToProps,
)
export default class Questionnaire extends Component {
  static propTypes = {
    sections: PropTypes.array.isRequired,
    loading: PropTypes.bool,
  };

  static defaultProps = {
    loading: false,
  };

  constructor(props) {
    super(props);
    this.columns = [
      {
        dataIndex: '',
        width: '20px',
        className: 'col-drag-and-drop',
        render: () => (
          <span className="icon-drag">
            <Icon />
          </span>
        ),
      },
      {
        title: props.translate('section'),
        dataIndex: 'name',
        editable: true,
      },
      {
        dataIndex: '',
        width: '370px',
        className: 'table-button_centered',
        render: (text, record, index, tableInstance, Context) => {
          const { editingKey } = tableInstance.state;
          const editable = tableInstance.isEditing(record);
          const isDeleteDisabled = !record.canDelete;

          // If row is in edit mode
          if (editable) {
            return (
              <div className="editable-row-operations">
                <span>
                  <Context.Consumer>
                    {form => (
                      <a
                        className="action-btn"
                        onClick={e => {
                          e.stopPropagation();
                          tableInstance.save(form, record.key);
                        }}
                        style={{ marginRight: 8 }}
                      >
                        {this.props.translate('save', 'form')}
                      </a>
                    )}
                  </Context.Consumer>
                  <a
                    className="action-btn"
                    onClick={e => {
                      e.stopPropagation();
                      tableInstance.cancel(record.key);
                    }}
                  >
                    {this.props.translate('cancel', 'form')}
                  </a>
                </span>
              </div>
            );
          }

          // If row is not in edit mode
          return (
            <div className="editable-row-operations">
              <span>
                <a
                  className="action-btn"
                  onClick={e => {
                    e.stopPropagation();
                    this.handleOpenAddQuestionModal(e, record.id);
                  }}
                >
                  <Icon type="plus" /> {this.props.translate('add', 'sections')}
                </a>
                <a
                  className="action-btn"
                  disabled={editingKey !== ''}
                  onClick={e => {
                    e.stopPropagation();
                    tableInstance.edit(record.key);
                  }}
                >
                  <Icon type="edit" /> {this.props.translate('edit', 'form')}
                </a>
                <Popconfirm
                  title={this.props.translate('areYouSureDelete', 'confirm')}
                  disabled={isDeleteDisabled}
                  onClick={e => e.stopPropagation()}
                  onCancel={e => e.stopPropagation()}
                  onConfirm={e => {
                    e.stopPropagation();
                    this.handleDeleteSection(record);
                  }}
                >
                  <a disabled={isDeleteDisabled} className="action-btn">
                    <Icon type="delete" />{' '}
                    {this.props.translate('delete', 'form')}
                  </a>
                </Popconfirm>
              </span>
            </div>
          );
        },
      },
    ];

    this.state = {
      isAddSectionModalOpen: false,
      isAddQuestionModalOpen: false,
      search: '',
      sectionId: -1,
      total: 0,
    };
  }

  componentDidMount() {
    this.handleUpdateTableData();
  }

  handleSearch = search =>
    this.setState({ search }, this.handleUpdateTableData);

  handleUpdateTableData = () => {
    const { search } = this.state;
    this.props.getQuestionsGroupedBySection({ search }).then(res => {
      this.setState({ total: res.pagination.total });
    });
  };

  handleOpenSkillModal = e => this.setState({ isAddSectionModalOpen: true });

  handleCloseSkillModal = e => this.setState({ isAddSectionModalOpen: false });

  handleOpenAddQuestionModal = (e, sectionId) =>
    this.setState({ isAddQuestionModalOpen: true, sectionId });

  handleCloseAddQuestionModal = e =>
    this.setState({ isAddQuestionModalOpen: false });

  handleAddSection = fields => {
    this.props
      .createSection(fields)
      .catch(err =>
        msgLocalError({ error: err, translate: this.props.translate }),
      );
  };

  handleChangeQueueSection = data => {
    this.props.changeQueueSection(data);
  };

  handleUpdateSection = section => {
    const sectionId = section.id;
    const data = { name: section.name };
    this.props.updateSection(sectionId, data);
  };

  handleDeleteSection = section => {
    this.props
      .deleteSection(section.id)
      .catch(err => message.error(this.props.translate('all', 'error')));
  };

  handleCreateNewQuestion = fields => {
    // Remove technical data before send
    delete fields.keys;
    this.props.createNewQuesiton(fields);
  };

  render() {
    const dataSource = this.props.sections.map(item => ({
      ...item,
      key: item.id,
    }));

    return (
      <React.Fragment>
        <AddSection
          visible={this.state.isAddSectionModalOpen}
          onCancel={this.handleCloseSkillModal}
          onSubmit={this.handleAddSection}
        />
        <QuestionModal
          title={this.props.translate('add', 'sections')}
          visible={this.state.isAddQuestionModalOpen}
          sectionId={this.state.sectionId}
          onCancel={this.handleCloseAddQuestionModal}
          onSubmit={this.handleCreateNewQuestion}
        />
        <div className="table-panel">
          <Button
            icon="plus"
            className="blueBtn table-panel_btn"
            onClick={this.handleOpenSkillModal}
          >
            {this.props.translate('addBtn')}
          </Button>
          <Search
            value={this.state.search}
            count={this.state.total}
            placeholder={this.props.translate('search', 'form')}
            onSearch={this.handleSearch}
          />
        </div>
        <Spin spinning={this.props.loading}>
          <Tooltip
            placement="top"
            title={this.props.translate('questionnaireSections', 'tooltips')}
          >
            <div className="table-with-action">
              <EditDragTable
                expandRowByClick
                expandIcon={ExpandIcon}
                expandIconAsCell
                columns={this.columns}
                dataSource={dataSource}
                onSave={this.handleUpdateSection}
                changeQueue={this.handleChangeQueueSection}
                expandedRowRender={section => (
                  <TableQuestion
                    sectionId={section.id}
                    onEditQuestion={this.handleUpdateTableData}
                  />
                )}
              />
            </div>
          </Tooltip>
        </Spin>
      </React.Fragment>
    );
  }
}
