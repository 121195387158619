import React, { Component } from 'react';
import { Input, Icon, Button } from 'antd';
import { find } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import TablePanel from 'components/TablePanel/index';
import ModalForm from 'components/ModalForm/index';
import Table from 'components/Table';
import i18n from 'services/i18n';
import i18nTable from 'services/i18nTable';
import { msgError } from 'services/errorHandler';
import { administratorsCRUD } from 'actions/cruds';
import { wrapRequest } from 'utils/ajax';
import { getAdministrators } from 'actions/administrator';

import AddEditAdministrator from './addNewAdministrator';

const mapDispatchToProps = dispatch =>
  bindActionCreators({ dispatch, getAdministrators }, dispatch);

const mapStateToProps = ({ runtime }) => ({
  administrators: runtime.administratorsData,
});

@i18n('modalForm')
@i18nTable('administratorsColumns')
@connect(
  mapStateToProps,
  mapDispatchToProps,
)
export default class Users extends Component {
  state = {
    modalTitle: '',
    initialValues: {},
    visible: false,
  };

  showModal = (modalTitle, initialValues = {}) =>
    this.setState({ visible: true, modalTitle, initialValues });

  loadAdministrators = () => this.props.getAdministrators();

  handleCancel = () => {
    this.setState({
      visible: false,
      modalTitle: '',
      initialValues: {},
    });
  };

  changeStatus = async (id, status) => {
    try {
      await wrapRequest({
        method: 'GET',
        url: `/administrator/${id}/${status}`,
      });
      this.loadAdministrators();
    } catch (err) {
      msgError(err);
    }
  };

  additionalButton = record => {
    const { translate } = this.props;
    if (record.state === 'ACTIVE')
      return (
        <a
          className="action-btn"
          onClick={() => this.changeStatus(record.id, 'lock')}
        >
          <Icon type="close-circle-o" />{' '}
          {translate('deactivate', 'administratorsColumns')}
        </a>
      );
    if (record.state === 'LOCKED')
      return (
        <a
          className="action-btn"
          onClick={() => this.changeStatus(record.id, 'activate')}
        >
          <Icon type="check-circle-o" />{' '}
          {translate('activate', 'administratorsColumns')}
        </a>
      );
    return null;
  };

  onEditButtonClick = ({ key }) => {
    const user = find(this.props.administrators.data, { key });
    if (user) this.showModal(this.props.translate('editAdministrator'), user);
  };

  render() {
    const { modalTitle, visible } = this.state;
    return (
      <React.Fragment>
        <ModalForm
          title={modalTitle}
          visible={visible}
          destroyOnClose
          onCancel={this.handleCancel}
        >
          <AddEditAdministrator
            initialValues={this.state.initialValues}
            toggleWindow={this.handleCancel}
            updateTable={this.loadAdministrators}
          />
        </ModalForm>
        <TablePanel>
          <Button
            icon="plus"
            className="blueBtn table-panel_btn"
            onClick={() =>
              this.showModal(this.props.translate('addNewAdministrator'))
            }
          >
            {this.props.translate('add', 'form')}
          </Button>
        </TablePanel>
        <Table
          hasActionBtns
          additionalBtns={this.additionalButton}
          editClick={this.onEditButtonClick}
          action={administratorsCRUD}
          name="administrators"
          withoutScroll
          columns={this.props.administratorsColumns}
        />
      </React.Fragment>
    );
  }
}
