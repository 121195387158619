import React from 'react';
import { Redirect, Switch, Route } from 'react-router';

/** Constants */
import * as SIDEBARS from 'constants/sidebars';

/** Components **/
import MainLayout from 'components/MainLayout';
import OsceSettings from 'pages/osceAdministration/osceSettings';
import Osce from 'pages/osceAdministration/osce/list';
import OsceProfile from 'pages/osceAdministration/osce/osceProfile';
import OsceOutput from 'pages/osceAdministration/output';
import SpSelection from 'pages/osceAdministration/spSelection';
import CostAccounting from 'pages/osceAdministration/costAccounting';

const CurrentView = ({ match }) => {
  const { url } = match;

  return (
    <Switch>
      <Route exact path={`${url}/:semesterId/osce`} component={Osce} />
      <Route
        path={`${url}/:semesterId/osce/basic-data/:id`}
        component={OsceProfile}
      />
      <Route
        path={`${url}/:semesterId/osce/circuits-details/:id`}
        component={OsceProfile}
      />
      <Route
        path={`${url}/:semesterId/osce/schedule/:id`}
        component={OsceProfile}
      />
      <Route
        path={`${url}/:semesterId/osce/students-data/:id`}
        component={OsceProfile}
      />
      <Route
        path={`${url}/:semesterId/osce/import-export/:id`}
        component={OsceProfile}
      />
      <Route
        path={`${url}/:semesterId/osce/plan-sp-trainings/:id`}
        component={OsceProfile}
      />
      <Route
        path={`${url}/:semesterId/osce/settings/:id`}
        component={OsceProfile}
      />
      <Route
        path={`${url}/:semesterId/osce/results/:id`}
        component={OsceProfile}
      />
      <Route
        path={`${url}/:semesterId/osce/bell-system/:id`}
        component={OsceProfile}
      />
      <Route path={`${url}/:semesterId/sp-selection`} component={SpSelection} />
      <Redirect
        exact
        from={`${url}/:semesterId/outputs`}
        to={`${url}/:semesterId/outputs/export-data`}
      />
      <Route
        path={`${url}/:semesterId/outputs/export-data`}
        component={OsceOutput}
      />
      <Route
        path={`${url}/:semesterId/outputs/send-invitations`}
        component={OsceOutput}
      />
      <Route
        path={`${url}/:semesterId/outputs/print-schedules`}
        component={OsceOutput}
      />
      <Route
        path={`${url}/:semesterId/cost-accounting`}
        component={CostAccounting}
      />
      <Route path={`${url}/osce-settings`} component={OsceSettings} />
    </Switch>
  );
};

const OsceAdministration = props => {
  return (
    <MainLayout
      multipleMenus={SIDEBARS.OSCE_ADMINISTRATION}
      nameMenu="osceAdministration"
    >
      <CurrentView {...props} />
    </MainLayout>
  );
};

export default OsceAdministration;
