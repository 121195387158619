import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input, Icon, Badge } from 'antd';
import { omit, get } from 'lodash';
import qs from 'qs';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { updateData } from 'actions/updateData';
import { setKey } from 'actions/adaptFunctions';
import { msgError } from 'services/errorHandler';
import i18n from 'services/i18n';

const mapStateToProps = ({ runtime }, props) => ({
  data: runtime[`${props.name}Data`],
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateData }, dispatch);

@i18n('form')
@connect(
  mapStateToProps,
  mapDispatchToProps,
)
export default class Search extends Component {
  static propTypes = {
    name: PropTypes.string,
    action: PropTypes.object,
    sendParams: PropTypes.array,
    additionalUrl: PropTypes.string,
  };

  onSearch = async e => {
    try {
      const { props } = this;
      const search = e.target.value;
      const sendParams = this.props.sendParams || {};
      const params = omit(props.data, ['data', 'pagination']);
      const resp = await this.props.action.getList({
        params: {
          ...params,
          search,
          ...sendParams,
        },
        additionalUrl: this.props.additionalUrl,
        paramsSerializer: par => qs.stringify(par, { arrayFormat: 'repeat' }),
      });
      setKey(resp.data);
      props.updateData({ ...resp.data, ...params, search }, this.props.name);
    } catch (err) {
      msgError(err);
    }
  };

  render() {
    return (
      <Badge
        style={{ backgroundColor: '#4080C7' }}
        count={get(this.props.data, 'pagination.total', 0)}
        overflowCount={999}
      >
        <Input
          placeholder={this.props.translate('search')}
          onInput={this.onSearch}
          prefix={<Icon type="search" />}
          className="table-panel_search"
        />
      </Badge>
    );
  }
}
