import api from 'services/api';
import {
  CHANGE_QUESTIONNAIRE_START,
  GET_QUESTIONNAIRE_SUCCESS,
  GET_QUESTIONNAIRE_ERROR,
  CREATE_SECTION_SUCCESS,
  CREATE_SECTION_ERROR,
  CHANGE_QUEUE_SECTION_SUCCESS,
  CHANGE_QUEUE_SECTION_ERROR,
  CHANGE_QUEUE_QUESTIONS_SUCCESS,
  CHANGE_QUEUE_QUESTIONS_ERROR,
  UPDATE_SECTION_SUCCESS,
  UPDATE_SECTION_ERROR,
  DELETE_SECTION_SUCCESS,
  DELETE_SECTION_ERROR,
  CREATE_NEW_QUESTION_SUCCESS,
  CREATE_NEW_QUESTION_ERROR,
  DELETE_QUESTION_SUCCESS,
  DELETE_QUESTION_ERROR,
} from 'constants/section';

export const changeQueueSection = data => async dispatch => {
  try {
    dispatch({ type: CHANGE_QUESTIONNAIRE_START });
    const response = await api.changeQueueSection(data);
    const sections = response.data.data;
    dispatch({ type: CHANGE_QUEUE_SECTION_SUCCESS, payload: { sections } });
    return sections;
  } catch (error) {
    dispatch({ type: CHANGE_QUEUE_SECTION_ERROR, error });
    throw error;
  }
};

export const changeQueueQuestion = data => async dispatch => {
  try {
    dispatch({ type: CHANGE_QUESTIONNAIRE_START });
    const response = await api.changeQueueQuestion(data);
    const questions = response.data.data;
    dispatch({ type: CHANGE_QUEUE_QUESTIONS_SUCCESS, payload: { questions } });
    return questions;
  } catch (error) {
    dispatch({ type: CHANGE_QUEUE_QUESTIONS_ERROR, error });
    throw error;
  }
};

export const getQuestionsGroupedBySection = data => async dispatch => {
  try {
    dispatch({ type: CHANGE_QUESTIONNAIRE_START });
    const response = await api.getQuestionsGroupedBySection(data);
    const sections = response.data;
    dispatch({ type: GET_QUESTIONNAIRE_SUCCESS, payload: { sections } });
    return sections;
  } catch (error) {
    dispatch({ type: GET_QUESTIONNAIRE_ERROR, error });
    throw error;
  }
};

export const createSection = data => async dispatch => {
  try {
    dispatch({ type: CHANGE_QUESTIONNAIRE_START });
    const response = await api.createSection(data);
    const section = response.data;
    dispatch({ type: CREATE_SECTION_SUCCESS, payload: { section } });
    return section;
  } catch (error) {
    dispatch({ type: CREATE_SECTION_ERROR, error });
    throw error;
  }
};

export const updateSection = (sectionId, data) => async dispatch => {
  try {
    dispatch({ type: CHANGE_QUESTIONNAIRE_START });
    const response = await api.updateSection(sectionId, data);
    const section = response.data;
    dispatch({ type: UPDATE_SECTION_SUCCESS, payload: { section } });
    return section;
  } catch (error) {
    dispatch({ type: UPDATE_SECTION_ERROR, error });
    throw error;
  }
};

export const deleteSection = sectionId => async dispatch => {
  try {
    dispatch({ type: CHANGE_QUESTIONNAIRE_START });
    await api.deleteSection(sectionId);
    dispatch({ type: DELETE_SECTION_SUCCESS, payload: { sectionId } });
  } catch (error) {
    dispatch({ type: DELETE_SECTION_ERROR, error });
    throw error;
  }
};

export const createNewQuesiton = data => async dispatch => {
  try {
    dispatch({ type: CHANGE_QUESTIONNAIRE_START });
    const response = await api.createNewQuesiton(data);
    const question = response.data;
    dispatch({ type: CREATE_NEW_QUESTION_SUCCESS, payload: { question } });
    return question;
  } catch (error) {
    dispatch({ type: CREATE_NEW_QUESTION_ERROR, error });
    throw error;
  }
};

export const updateQuestion = (id, data) => async dispatch => {
  try {
    const response = await api.updateQuestion(id, data);
    const question = response.data;
    return question;
  } catch (error) {
    throw error;
  }
};

export const deleteQuestion = (sectionId, questionId) => async dispatch => {
  try {
    dispatch({ type: CHANGE_QUESTIONNAIRE_START });
    await api.deleteQuestion(questionId);
    dispatch({
      type: DELETE_QUESTION_SUCCESS,
      payload: { sectionId, questionId },
    });
  } catch (error) {
    dispatch({ type: DELETE_QUESTION_ERROR, error });
    throw error;
  }
};
