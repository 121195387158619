import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { invoke, cloneDeep, get } from 'lodash';
import { Icon, Button, Select, Spin } from 'antd';

import addLoadWithRedux from 'services/decorators/addLoadWithRedux';
import {
  getStations,
  addStations,
  editStations,
  deleteStations,
} from 'actions/stations';
import { STATION_TYPE } from 'constants/options';
import { OSCE_STATUS } from 'constants/common';

import './style.less';

/**
 * Component Stations used for stations om the OSCE basic page
 * @reactProps {func} getStations -
 * @reactProps {func} addStations -
 * @reactProps {func} editStations -
 * @reactProps {func} deleteStations -
 */
@addLoadWithRedux({
  i18Name: 'circuit',
  runtimeReduxNames: ['osce'],
  mapMethods: { getStations, addStations, editStations, deleteStations },
})
@withRouter
export default class Stations extends Component {
  state = { stations: [], editable: '' };

  componentDidMount() {
    this.loadStations();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.loadStations();
    }
  }

  get isDisable() {
    const { props } = this;
    return (
      Boolean(get(props, 'osceData.deletedAt')) ||
      get(props, 'osceData.osceStatus') !== OSCE_STATUS.OSCE_CREATED ||
      get(props, 'osceData.freezed') === true
    );
  }

  loadStations = async () => {
    const { data } = await getStations(this.props.match.params.id);
    this.stations = data.data;
    this.setState({ stations: data.data });
  };

  renderStation = (item, index) => {
    const { props } = this;
    return (
      <div className="station_item" key={item.id}>
        <span className="station_item_name">Station {index + 1}</span>
        <span className="station_item_type">
          {this.state.editable !== index ? (
            props.translate(item.stationType)
          ) : (
            <Select
              onChange={value => this.onChange(value, index)}
              value={item.stationType}
            >
              {STATION_TYPE.map(element => (
                <Select.Option value={element.value} key={element.value}>
                  {props.translate(element.value)}
                </Select.Option>
              ))}
            </Select>
          )}
          {this.btns(index)}
        </span>
      </div>
    );
  };

  btns = index => {
    if (this.isDisable) return null;
    if (this.state.editable !== index) {
      return (
        <div
          className="card-btns-container_hover"
          onClick={() => this.saveStartEdit(index)}
        >
          <Button shape="circle" icon="edit" size="small" />
        </div>
      );
    }
    return (
      <div className="card-btns-container">
        <span className="station_item_remove">
          <Icon type="delete" onClick={() => this.remove(index)} />
        </span>
        <Button
          shape="circle"
          icon="close"
          size="small"
          onClick={this.resetStopEdit}
        />
        <Button
          shape="circle"
          type="primary"
          icon="check"
          size="small"
          onClick={this.saveStations}
        />
      </div>
    );
  };

  saveStartEdit = index => {
    this.stations = cloneDeep(this.state.stations);
    this.setState({ editable: index });
  };

  stopEdit = () => this.setState({ editable: '' });

  resetStopEdit = () => {
    this.setState({ stations: this.stations });
    this.stopEdit();
  };

  onChange = (value, index) => {
    const stations = [...this.state.stations];
    stations[index].stationType = value;
    this.setState({ stations });
  };

  saveStations = async () => {
    const { state } = this;
    const target = state.stations[state.editable];
    await editStations(target.id, { stationType: target.stationType });
    this.stopEdit();
  };

  remove = async index => {
    this.stopEdit();
    const stations = [...this.state.stations];
    const { id } = stations[index];
    stations.splice(index, 1);
    this.setState({ stations });
    deleteStations(id);
  };

  add = async () => {
    const {
      match: { params },
    } = this.props;
    const resp = await addStations(params.id, { stationType: 'REGULAR' });
    const stations = [...this.state.stations];
    stations.push(resp.data);
    this.setState({ stations });
  };

  get renderAddStation() {
    return (
      <span className="action-btn" onClick={this.add}>
        <Icon type="plus" /> Add station
      </span>
    );
  }

  render() {
    return (
      <Spin spinning={false}>
        <div className="station">
          <p className="station_header">Stations</p>
          {invoke(this.state, 'stations.map', this.renderStation)}
          {!this.isDisable && this.renderAddStation}
        </div>
        <div className="basic-data-separator" />
      </Spin>
    );
  }
}
