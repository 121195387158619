import React, { PureComponent } from 'react';
import { withRouter } from 'react-router';
import { Col, Row } from 'antd';
import UrlPattern from 'url-pattern';

import i18n from 'services/i18n';
import PrintExportList from '../PrintExportList';

const pattern = new UrlPattern('/semester/:semesterId/:type');

@withRouter
@i18n('output')
export default class SendInvitations extends PureComponent {
  getUrl = type => pattern.stringify({ ...this.props.match.params, type });

  render() {
    const { getUrl, props } = this;
    const { translate } = props;
    const commonOpt = {
      printCopiesParams: props.match.params,
      type: 'invite',
      sendMail: true,
    };
    return (
      <Row gutter={20}>
        <Col span={9}>
          <PrintExportList
            {...commonOpt}
            url={getUrl('sp')}
            name="patient"
            title={translate('simulatedPatients')}
            renderRow={(text, record) => (
              <span>
                {record.lastName} {record.firstName}
              </span>
            )}
          />
        </Col>
        <Col span={9}>
          <PrintExportList
            {...commonOpt}
            url={getUrl('examiner')}
            name="examiner"
            title={translate('examiners')}
            renderRow={(text, record) => (
              <span>
                {record.lastName} {record.firstName}
              </span>
            )}
          />
        </Col>
      </Row>
    );
  }
}
