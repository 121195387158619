import { get } from 'lodash';
import {
  NUMERIC_SIGN,
  LANGUAGE_LEVEL,
  ANSWERED_CRITERIA,
} from 'constants/options';

const getAnswerCriteria = answerType => {
  if (answerType) {
    return ANSWERED_CRITERIA[0].value;
  }
  return ANSWERED_CRITERIA[1].value;
};

const getLanguageLevel = level =>
  LANGUAGE_LEVEL.filter(item => item.value === level);

const getNumericSign = sign => NUMERIC_SIGN.filter(item => item.value === sign);

const localizeOptions = (item, translate) =>
  `${item}-${translate('permission', 'patient')}`;

export const generateContent = (text, record, translate) => {
  const { criterion } = record;
  const isCriteriaStr = get(criterion, 'isSimPatHasCriterion')
    ? translate('is', 'form')
    : translate('isnt', 'form');
  const hasCriteriaStr = get(criterion, 'isSimPatHasCriterion')
    ? translate('has', 'form')
    : translate('hasnt', 'form');
  switch (record.type) {
    case 'QUESTIONNAIRE':
      return `"${get(criterion, 'question.question') ||
        record.name ||
        criterion.question.name}" ${translate(
        getAnswerCriteria(criterion.isAnsweredWith),
        'questionnaireAnswers',
      )} ${get(criterion, 'answerFromAnswersOptions.value') ||
        get(criterion, 'openQuestionSearch') ||
        get(criterion, 'answer') ||
        get(record, 'answer')}`;
    case 'LANGUAGE':
      return `${get(criterion, 'language.name') || record.name} ${translate(
        criterion.isOnLevel,
        'form',
      )} ${get(getLanguageLevel(criterion.level)[0], 'title')}`;
    case 'MARITAL_STATUS':
      return `${isCriteriaStr} ${translate(criterion.maritalStatus, 'form')}`;
    case 'NATIONALITY':
      return `${isCriteriaStr} ${get(criterion, 'nationality.name') ||
        record.name}`;
    case 'NUMERIC':
      return `${translate(criterion.field)} ${get(
        getNumericSign(criterion.sign)[0],
        'title',
      )} ${criterion.value}`;
    case 'PROFESSION':
      return `${isCriteriaStr} ${get(criterion, 'profession.name') ||
        record.name}`;
    case 'WORK_PERMISSION':
      return `${hasCriteriaStr} ${localizeOptions(
        criterion.workPermission,
        translate,
      )}`;
    case 'GENDER':
      return `${isCriteriaStr} ${translate(criterion.gender, 'form')}`;
    default:
      return '';
  }
};
