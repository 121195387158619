import React from 'react';
import { Button } from 'antd';

const FormEditableBtns = ({
  editable,
  saveStartEdit,
  resetStopEdit,
  needShowEditable = true,
}) => {
  if (!editable) {
    if (!needShowEditable) return null;
    return (
      <div className="card-btns-container_hover" onClick={saveStartEdit}>
        <Button shape="circle" icon="edit" size="small" />
      </div>
    );
  }
  return (
    <div className="card-btns-container">
      <Button
        shape="circle"
        icon="close"
        size="small"
        onClick={resetStopEdit}
      />
      <Button
        shape="circle"
        type="primary"
        icon="check"
        size="small"
        htmlType="submit"
      />
    </div>
  );
};

export default FormEditableBtns;
