import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Layout } from 'antd';

import { history } from 'store';
import i18n from 'services/i18n';
import { HEADER_MENU } from 'constants/common';

import './styles.less';

const { Content } = Layout;

@i18n('notFound')
class NotFound extends React.PureComponent {
  returnToHome = () => {
    const roles = localStorage.getItem('roles');
    const allowedItem = HEADER_MENU.filter(
      item => roles.indexOf(item.access) > -1,
    );
    history.push(allowedItem[0].url);
  };

  render() {
    const { translate } = this.props;
    return (
      <ThemeProvider
        theme={{
          space: [0, 5, 10, 15, 20],
          breakpoints: ['32em', '48em', '64em'],
        }}
      >
        <Layout>
          <Content className="main-content flex ">
            <div className="not-found-container">
              <div className="not-found-title">404</div>
              <div className="not-found-text">{translate('notExist')}</div>
              <div className="not-found-link">
                <span onClick={this.returnToHome}>
                  {translate('returnHomePage')}
                </span>
              </div>
            </div>
          </Content>
        </Layout>
      </ThemeProvider>
    );
  }
}

export default NotFound;
