import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Button, Icon, Select } from 'antd';
import cn from 'classnames';

import {
  BUTTONS_EDITOR,
  FONT_SIZE_OPTION,
  FONT_FAMILY_OPTION,
  TEXT_COLOR_OPTION,
} from 'constants/editorTemplate';

export default class Toolbar extends React.PureComponent {
  static propTypes = {
    onChangeStyle: PropTypes.func,
    blockType: PropTypes.string,
    currentStyle: PropTypes.object,
  };

  get currentColor() {
    return TEXT_COLOR_OPTION.find(item =>
      this.props.currentStyle.has(`COLOR_${item.toUpperCase()}`),
    );
  }

  get currentFontFamily() {
    const target = FONT_FAMILY_OPTION.find(item =>
      this.props.currentStyle.has(item.value),
    );
    return get(target, 'label');
  }

  get currentFontSize() {
    const target = FONT_SIZE_OPTION.find(item =>
      this.props.currentStyle.has(`FONT_SIZE_${item.value}`),
    );
    return get(target, 'value');
  }

  render() {
    const { props } = this;
    return (
      <div className="template-toolbar">
        <div className="template-toolbar_line">
          {BUTTONS_EDITOR.map(item => (
            <Button
              key={item.style}
              className={cn('template-toolbar_button', {
                'template-toolbar_button__active':
                  item.type === 'inline'
                    ? this.props.currentStyle.has(item.style)
                    : item.style === this.props.blockType,
              })}
              onMouseDown={event => {
                event.preventDefault();
              }}
              onClick={props.onChangeStyle(item.style, item.type)}
            >
              {item.icon ? <Icon component={item.icon} /> : item.text}
            </Button>
          ))}
        </div>
        <div className="template-toolbar_line">
          <Select
            className="template-toolbar_select"
            placeholder="Font"
            onSelect={item => props.onChangeStyle(item, 'fontFamily')()}
            onMouseEnter={event => {
              event.preventDefault();
            }}
            value={this.currentFontFamily}
          >
            {FONT_FAMILY_OPTION.map(item => (
              <Select.Option
                key={item.label}
                value={item.label}
                style={{ fontFamily: item.label }}
              >
                {item.label}
              </Select.Option>
            ))}
          </Select>
          <Select
            className="template-toolbar_select"
            onChange={item => props.onChangeStyle(item, 'fontSize')()}
            placeholder="Font size"
            value={this.currentFontSize}
          >
            {FONT_SIZE_OPTION.map(item => (
              <Select.Option key={item.value} value={item.value}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
          <Select
            className="template-toolbar_select"
            onChange={item => props.onChangeStyle(item, 'color')()}
            placeholder="Foreground"
            value={this.currentColor}
          >
            {TEXT_COLOR_OPTION.map(item => (
              <Select.Option key={item} value={item}>
                <span
                  className="template-toolbar_select_color"
                  style={{ backgroundColor: item }}
                >
                  &nbsp;
                </span>{' '}
                {item}
              </Select.Option>
            ))}
          </Select>
        </div>
      </div>
    );
  }
}
