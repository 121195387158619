import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, DatePicker, InputNumber, Select, Button, message } from 'antd';
import moment from 'moment';
// Services
import api from 'services/api';
import i18n from 'services/i18n';
// Actions
import { updateSimulatedPatientsPersonalInfo } from 'actions/simulatedPatients';
// Constants
import { MARITAL_STATUS, GENDER } from 'constants/options';
// Components
import LazySelect from 'components/LazySelect';
import EditableField from 'components/EditableField';
import Card from '../Card';

const mapStateToProps = state => ({
  profile: state.simulatedPatients.profile,
});

const mapDispatchToProps = { updateSimulatedPatientsPersonalInfo };

@i18n('form')
@connect(
  mapStateToProps,
  mapDispatchToProps,
)
@Form.create()
export default class PersonalInformation extends Component {
  static propTypes = {
    profile: PropTypes.object.isRequired,
  };

  state = {
    editable: '',
  };

  handleLoadNationalities = data => {
    return api
      .getAllNationalities(data)
      .then(res => res.data)
      .catch(err => message.error(this.props.translate('all', 'error')));
  };

  editField = fieldName => this.setState({ editable: fieldName });

  stopEditField = () => this.setState({ editable: '' });

  onSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // We need some extra data in order to submit form
        const oldPersonalInfo = this.props.profile.patientPersonalInfo;
        // Create DTO object
        const data = {
          ...values,
          birthday: values.birthday.format('YYYY-MM-DD'),
          socialInsurance: oldPersonalInfo.socialInsurance,
          workPermission: oldPersonalInfo.workPermission,
          professionId: oldPersonalInfo.profession.id,
          languageWithLevelRequest: oldPersonalInfo.languages.map(l => ({
            languageId: l.language.id,
            languageSkillLevel: l.languageSkillLevel,
          })),
        };
        // Submit the form
        this.props
          .updateSimulatedPatientsPersonalInfo(this.props.profile.id, data)
          .catch(error => message.error(this.props.translate('all', 'error')));

        this.stopEditField();
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { patientPersonalInfo = {} } = this.props.profile;

    // Prevent nationality to be null
    if (!patientPersonalInfo.nationality) {
      patientPersonalInfo.nationality = {};
    }

    return (
      <Card title="personalInformation">
        <Form onSubmit={this.onSubmit}>
          <EditableField
            editable={this.state.editable === 'birthday'}
            label={this.props.translate('birthday')}
            value={moment(patientPersonalInfo.birthday).format('DD. MMMM YYYY')}
            edit={() => this.editField('birthday')}
          >
            <React.Fragment>
              {getFieldDecorator('birthday', {
                initialValue: moment(patientPersonalInfo.birthday),
                rules: [
                  { required: true, message: this.props.translate('insert') },
                ],
              })(<DatePicker format="DD.MM.YYYY" />)}
              <div className="profile-card-btns-container">
                <Button
                  shape="circle"
                  icon="close"
                  size="small"
                  onClick={this.stopEditField}
                />
                <Button
                  shape="circle"
                  type="primary"
                  icon="check"
                  size="small"
                  htmlType="submit"
                  onClick={this.onSubmit}
                />
              </div>
            </React.Fragment>
          </EditableField>
          <EditableField
            editable={this.state.editable === 'gender'}
            label={this.props.translate('gender')}
            value={this.props.translate(patientPersonalInfo.gender)}
            edit={() => this.editField('gender')}
          >
            <React.Fragment>
              {getFieldDecorator('gender', {
                initialValue: patientPersonalInfo.gender,
                rules: [
                  {
                    required: true,
                    message: this.props.translate('insert'),
                  },
                ],
              })(
                <Select>
                  {GENDER.map(gender => (
                    <Select.Option key={gender.value} value={gender.value}>
                      {this.props.translate(gender.value)}
                    </Select.Option>
                  ))}
                </Select>,
              )}
              <div className="profile-card-btns-container">
                <Button
                  shape="circle"
                  icon="close"
                  size="small"
                  onClick={this.stopEditField}
                />
                <Button
                  shape="circle"
                  type="primary"
                  icon="check"
                  size="small"
                  htmlType="submit"
                  onClick={this.onSubmit}
                />
              </div>
            </React.Fragment>
          </EditableField>
          <EditableField
            editable={this.state.editable === 'height'}
            label={this.props.translate('height')}
            value={patientPersonalInfo.height}
            edit={() => this.editField('height')}
          >
            <React.Fragment>
              {getFieldDecorator('height', {
                initialValue: patientPersonalInfo.height,
              })(<InputNumber min={0} max={500} step={1} precision={0} />)}
              <div className="profile-card-btns-container">
                <Button
                  shape="circle"
                  icon="close"
                  size="small"
                  onClick={this.stopEditField}
                />
                <Button
                  shape="circle"
                  type="primary"
                  icon="check"
                  size="small"
                  htmlType="submit"
                  onClick={this.onSubmit}
                />
              </div>
            </React.Fragment>
          </EditableField>
          <EditableField
            editable={this.state.editable === 'weight'}
            label={this.props.translate('weight')}
            value={patientPersonalInfo.weight}
            edit={() => this.editField('weight')}
          >
            <React.Fragment>
              {getFieldDecorator('weight', {
                initialValue: patientPersonalInfo.weight,
              })(<InputNumber min={0} step={1} precision={0} />)}
              <div className="profile-card-btns-container">
                <Button
                  shape="circle"
                  icon="close"
                  size="small"
                  onClick={this.stopEditField}
                />
                <Button
                  shape="circle"
                  type="primary"
                  icon="check"
                  size="small"
                  htmlType="submit"
                  onClick={this.onSubmit}
                />
              </div>
            </React.Fragment>
          </EditableField>
          <EditableField
            editable={this.state.editable === 'nationalityId'}
            label={this.props.translate('nationality')}
            value={patientPersonalInfo.nationality.name}
            edit={() => this.editField('nationalityId')}
          >
            <React.Fragment>
              {getFieldDecorator('nationalityId', {
                initialValue: patientPersonalInfo.nationality.id,
              })(
                <LazySelect
                  dropdownMatchSelectWidth
                  loadData={this.handleLoadNationalities}
                />,
              )}
              <div className="profile-card-btns-container">
                <Button
                  shape="circle"
                  icon="close"
                  size="small"
                  onClick={this.stopEditField}
                />
                <Button
                  shape="circle"
                  type="primary"
                  icon="check"
                  size="small"
                  htmlType="submit"
                  onClick={this.onSubmit}
                />
              </div>
            </React.Fragment>
          </EditableField>
          <EditableField
            editable={this.state.editable === 'maritalStatus'}
            label={this.props.translate('maritalStatus')}
            value={
              patientPersonalInfo.maritalStatus
                ? this.props.translate(patientPersonalInfo.maritalStatus)
                : ''
            }
            edit={() => this.editField('maritalStatus')}
          >
            <React.Fragment>
              {getFieldDecorator('maritalStatus', {
                initialValue: patientPersonalInfo.maritalStatus,
              })(
                <Select>
                  {MARITAL_STATUS.map(status => (
                    <Select.Option key={status.value} value={status.value}>
                      {this.props.translate(status.value)}
                    </Select.Option>
                  ))}
                </Select>,
              )}
              <div className="profile-card-btns-container">
                <Button
                  shape="circle"
                  icon="close"
                  size="small"
                  onClick={this.stopEditField}
                />
                <Button
                  shape="circle"
                  type="primary"
                  icon="check"
                  size="small"
                  htmlType="submit"
                  onClick={this.onSubmit}
                />
              </div>
            </React.Fragment>
          </EditableField>
        </Form>
      </Card>
    );
  }
}
