import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Form, Button, notification } from 'antd';

import { history } from 'store';
import handleSubmit from 'services/form';
import { resetPasswordCRUD } from 'actions/cruds';
import FormItemInput from 'components/Form/formInput';
import ErrorItem from 'components/Form/formError';
import i18n from 'services/i18n';
import Header from './header';

import './loginStyles.less';

const FormItem = Form.Item;

@i18n('form')
@Form.create({})
export default class ResetPassword extends Component {
  static childContextTypes = {
    form: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      errorText: '',
    };
  }

  getChildContext() {
    return { form: this.props.form };
  }

  checkPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback(new Error());
    } else {
      callback();
    }
  };

  resetPasswordSubmit = async e => {
    e.preventDefault();
    const { password, repeatPassword } = await handleSubmit(e, this.props.form);
    if (password !== repeatPassword) {
      !this.state.errorText && this.toggleError('form.unEqualPasswords');
      return;
    }
    try {
      this.state.loading = true;
      await resetPasswordCRUD.post({
        encryptedPart: this.props.match.params.hash,
        password,
        repeatPassword,
      });
      notification.success({
        message: this.props.translate('success'),
      });
      history.push('/auth/login');
    } catch (error) {
      console.error(error);
      !this.state.errorText && this.toggleError('form.incorrect');
      this.setState(() => ({
        loading: false,
      }));
    }
  };

  toggleError = errorText => {
    this.setState(state => ({ errorText }));
  };

  render() {
    const { translate } = this.props;
    return (
      <Form onSubmit={this.resetPasswordSubmit} className="login_form">
        <Header name="form.createPassword" />
        <FormItemInput
          placeholder
          name="password"
          icon="lock"
          type="password"
          required
        />
        <FormItemInput
          placeholder
          name="repeatPassword"
          icon="lock"
          type="password"
          required
          validator={{
            func: this.checkPassword,
            message: 'unEqualPasswords',
          }}
        />
        <ErrorItem
          toggleError={this.toggleError}
          errorText={!!this.state.errorText}
        />
        <FormItem>
          <Button
            type="primary"
            htmlType="submit"
            className="blueBtn login_max_width"
          >
            {translate('changePassword')}
          </Button>
        </FormItem>
        <FormItem>
          <Link to="/auth/login" className="login_forgot_link">
            {translate('goToLogin')}
          </Link>
        </FormItem>
      </Form>
    );
  }
}
