import React from 'react';
import { Spin, Form, Input } from 'antd';
import { cloneDeep, get } from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';

import { updatePatientR } from 'actions/patient';
import { FieldBase, StaticValue } from './Field';

const FormItem = Form.Item;

const demyFunc = d => d;

const mapStateToProps = ({
  runtime: { patientData: data },
  form: { currentField },
}) => ({
  data,
  isDeleted: get(data, 'deletedAt'),
  currentField,
  updateValues: data,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ dispatch, updatePatientR }, dispatch);

@injectIntl
@withRouter
@connect(
  mapStateToProps,
  mapDispatchToProps,
)
@Form.create({})
export default class NameInput extends FieldBase {
  static defaultProps = { method: 'PUT', longName: 'nameFields' };

  createField = name => {
    const {
      // decorator props
      data, // eslint-disable-line
      form,
      intl,
    } = this.props;

    return (
      <FormItem colon={false} style={{ marginRight: '10px' }}>
        {form.getFieldDecorator(name, {
          rules: [
            {
              required: true,
              message: intl.formatMessage({ id: 'error.required' }),
            },
          ],
          initialValue: get(data, name),
        })(
          this.state.editable ? (
            <Input
              placeholder={intl.formatMessage({ id: `form.${name}` })}
              ref={this.saveInput}
              className="card-name-input"
            />
          ) : (
            <StaticValue className="card-static-name" formatStatic={demyFunc} />
          ),
        )}
      </FormItem>
    );
  };

  saveStartEdit = () => {
    this.prevVals = cloneDeep(
      this.props.form.getFieldsValue(['firstName', 'lastName']),
    );
    this._startEdit();
  };

  resetStopEdit = () => {
    this.props.form.setFieldsValue(this.prevVals);
    this.stopEdit();
  };

  forSendFunc = values => (values.simulatedPatientStatus = 'ACTIVE');

  render() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <Spin spinning={this.state.loading}>
          {this.createField('firstName')}
          {this.createField('lastName')}
          {this.btns}
        </Spin>
      </Form>
    );
  }
}
