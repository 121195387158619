import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button } from 'antd';
import { withRouter } from 'react-router';
// Services
import i18n from 'services/i18n';
import {
  checkPositiveNumber,
  createMaxNumberChecker,
} from 'services/validations';

const graterThenHundred = createMaxNumberChecker(100);

@withRouter
@i18n('form')
@Form.create({})
export default class AddSection extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSubmit(values).then(res => {
          this.props.form.resetFields();
          this.props.onCancel();
        });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form onSubmit={this.handleSubmit}>
        <div className="form_content">
          <Form.Item label={this.props.translate('sectionName')} colon={false}>
            {getFieldDecorator('name', {
              rules: [
                {
                  required: true,
                  message: this.props.translate('insert', 'error'),
                },
              ],
            })(
              <Input
                type="text"
                placeholder={this.props.translate('sectionName')}
              />,
            )}
          </Form.Item>
          <Form.Item label={this.props.translate('description')} colon={false}>
            {getFieldDecorator('description')(
              <Input.TextArea
                placeholder={this.props.translate('description')}
              />,
            )}
          </Form.Item>
          <Form.Item label={this.props.translate('weighting')} colon={false}>
            {getFieldDecorator('weight', {
              initialValue: '100',
              rules: [
                {
                  validator: checkPositiveNumber,
                  message: this.props.translate('onlyPositiveNumber', 'error'),
                },
                {
                  validator: graterThenHundred,
                  message: 'Can not be grater then 100',
                },
              ],
            })(<Input type="number" />)}
          </Form.Item>
        </div>
        <div className="ant-modal-footer footer_max_width">
          <Button onClick={this.props.onCancel}>
            {this.props.translate('cancel')}
          </Button>
          <Button htmlType="submit" className="blueBtn">
            {this.props.translate('save')}
          </Button>
        </div>
      </Form>
    );
  }
}
