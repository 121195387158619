import React, { PureComponent } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { v1 as uuidv1 } from 'uuid';
/** Services */
import { checkTimeIsBefore, checkTimeIsSame } from 'services/format';

/** Constants */
import { DEFAULT_DESKTOP_SIZE, STATION_TYPES } from 'constants/common';

/** Components */
import DoubleStation from './StationTemplate/Double';
import DoubleSPStation from './StationTemplate/DoubleSP';
import PreparationStation from './StationTemplate/Preparation';
import DefaultStation from './StationTemplate/Default';
import BreakStation from './StationTemplate/BreakStation';
import BreakData from '../PopUpContent/BreakData';

/**
 * Component for rendering content inside cell rotation
 * @reactProps {object} timeSlot - data of current timeSlot
 * @reactProps {number} index - index of array
 */
export default class TimeSlot extends PureComponent {
  static propsTypes = {
    timeSlot: PropTypes.object,
    rotations: PropTypes.array,
    stationType: PropTypes.string,
    stationId: PropTypes.number,
    countStudents: PropTypes.number,
    isSpPaused: PropTypes.bool,
    circuitId: PropTypes.number,
    osceDayId: PropTypes.number,
  };

  get heightStation() {
    const { isSpPaused, countStudents, stationType, timeSlot } = this.props;
    const heightCell = window.screen.width < DEFAULT_DESKTOP_SIZE ? 38 : 44;
    if (
      isSpPaused &&
      stationType === STATION_TYPES.DOUBLE &&
      !!timeSlot.coffeeBreaks.length
    ) {
      return (countStudents + 1.5) * heightCell;
    }
    if (isSpPaused && stationType === STATION_TYPES.DOUBLE) {
      return (countStudents + 1) * heightCell;
    }
    if (isSpPaused && !!timeSlot.coffeeBreaks.length) {
      return (countStudents + 0.5) * heightCell;
    }
    if (isSpPaused) {
      return countStudents * heightCell;
    }
    return 'auto';
  }

  parseStudent = (students, coffeeBreakStartTime, endTime) =>
    students.filter(
      student =>
        (checkTimeIsBefore(student.endTime, coffeeBreakStartTime) ||
          checkTimeIsSame(student.endTime, coffeeBreakStartTime)) &&
        (!endTime ||
          (endTime &&
            (checkTimeIsBefore(endTime, student.startTime) ||
              checkTimeIsSame(endTime, student.startTime)))),
    ) || [];

  renderPostsWithCoffeeBreaks = posts => {
    const breaks = get(this.props.timeSlot, 'coffeeBreaks');
    return breaks.map((coffeeBreak, index) => {
      const newPosts = posts.map(post => ({
        ...post,
        slotPosition: index === 0 ? 'first' : 'middle',
        studentAssignments: this.parseStudent(
          post.studentAssignments,
          coffeeBreak.startTime,
          breaks[index - 1] && breaks[index - 1].endTime,
        ),
      }));
      const endPosts =
        index === breaks.length - 1 &&
        posts.map(post => ({
          ...post,
          slotPosition: 'last',
          studentAssignments: post.studentAssignments.filter(
            student => !checkTimeIsBefore(student.endTime, coffeeBreak.endTime),
          ),
        }));
      return (
        <React.Fragment>
          <div className="station_row">{this.renderPosts(newPosts)}</div>
          <BreakData isDisabled timeSlot={coffeeBreak} showBigBreak={false} />
          {index === breaks.length - 1 && (
            <div className="station_row">{this.renderPosts(endPosts)}</div>
          )}
        </React.Fragment>
      );
    });
  };

  renderPosts = posts =>
    posts.map(post => {
      const {
        rotations,
        stationType,
        timeSlot,
        isSpPaused,
        circuitId,
        osceDayId,
      } = this.props;
      const opt = {
        ...post,
        isSpPaused,
        rotations: rotations,
        timeSlotId: timeSlot.id,
        circuitId: circuitId,
        osceDayId: osceDayId,
      };
      switch (stationType) {
        case STATION_TYPES.BREAK:
          return <BreakStation key={uuidv1()} {...opt} />;
        case STATION_TYPES.PREPARATION:
          return <PreparationStation key={uuidv1()} {...opt} />;
        case STATION_TYPES.DOUBLE:
          return (
            <DoubleStation
              {...opt}
              key={uuidv1()}
              hasCoffeeBreaks={!!timeSlot.coffeeBreaks.length}
            />
          );
        case STATION_TYPES.DOUBLESP:
          if (!isSpPaused) {
            return <DoubleSPStation key={uuidv1()} {...opt} />;
          }
          return <DefaultStation key={uuidv1()} {...opt} />;
        default:
          return <DefaultStation key={uuidv1()} {...opt} />;
      }
    });

  render() {
    const { timeSlot, stationId, isSpPaused } = this.props;
    const posts = timeSlot.posts.filter(item => item.stationId === stationId);
    return !isSpPaused && timeSlot.coffeeBreaks.length > 0 ? (
      this.renderPostsWithCoffeeBreaks(posts)
    ) : (
      <div className="station_row" style={{ minHeight: this.heightStation }}>
        {this.renderPosts(posts)}
      </div>
    );
  }
}
