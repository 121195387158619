import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  Modal,
  Spin,
  Icon,
  Popconfirm,
  Select,
  Form,
  Tooltip,
  message,
} from 'antd';
import { debounce } from 'lodash';
// Services
import i18n from 'services/i18n';
import api from 'services/api';
// Actions
import {
  getRoleInfo,
  getAllRolesCriteria,
  deleteRoleCriterion,
  updateRoleCriterion,
  createRoleCriterion,
  lazyLoadCriteria,
} from 'actions/role';
// Options
import {
  NUMERIC_SIGN,
  LANGUAGE_LEVEL,
  BUTTONS,
  FILTER_TYPE,
} from 'constants/options';
// Icons
import { ReactComponent as GenderSvg } from 'images/icons/ic-gender.svg';
import { ReactComponent as LangSvg } from 'images/icons/ic-lang.svg';
import { ReactComponent as MaritalSvg } from 'images/icons/ic-marital.svg';
import { ReactComponent as NationSvg } from 'images/icons/ic-nation.svg';
import { ReactComponent as NumberSvg } from 'images/icons/ic-number.svg';
import { ReactComponent as ProfessionSvg } from 'images/icons/ic-profession.svg';
import { ReactComponent as QuestionSvg } from 'images/icons/ic-question.svg';
import { ReactComponent as WorkSvg } from 'images/icons/ic-work.svg';
// Components
import EditDragTable from 'components/EditDragTable';
import {
  FilterItem,
  GenderFilter,
  NumericFilter,
  LanguageFilter,
  ProfessionFilter,
  NationalityFilter,
  MaritalStatusFilter,
  QuestionnaireFilter,
  WorkPermissionFilter,
} from 'components/Filter';
import WorkPermissionForm from './WorkPermissionForm';
import QuestionnaireForm from './QuestionnaireForm';
import MaritalStatusForm from './MaritalStatusForm';
import NationalityForm from './NationalityForm';
import ProfessionForm from './ProfessionForm';
import LanguageForm from './LanguageForm';
import NumericForm from './NumericForm';
import GenderForm from './GenderForm';
import AddCriterion from './AddCriterion';

import './styles.less';

const mapStateToProps = state => ({
  role: state.role.info,
  criteria: state.role.criteria,
  loading: state.role.loading,
  errors: state.role.errors,
});

const mapDispatchToProps = {
  getRoleInfo,
  getAllRolesCriteria,
  deleteRoleCriterion,
  updateRoleCriterion,
  createRoleCriterion,
  lazyLoadCriteria,
};

const getLanguageLevel = level =>
  LANGUAGE_LEVEL.filter(item => item.value === level);

const getNumericSign = sign => NUMERIC_SIGN.filter(item => item.value === sign);

@withRouter
@i18n('roleCriteria')
@connect(
  mapStateToProps,
  mapDispatchToProps,
)
export default class CriteriaForSP extends Component {
  static propTypes = {
    role: PropTypes.shape({
      id: PropTypes.number,
      idForMergingVersions: PropTypes.number,
      isUsedInOsce: PropTypes.bool,
      lastVersion: PropTypes.bool,
      majorVersion: PropTypes.number,
      minorVersion: PropTypes.number,
      roleInformationResponse: PropTypes.object,
      skillId: PropTypes.number,
      targetSystemResponses: PropTypes.array,
      versions: PropTypes.array,
    }).isRequired,
    criteria: PropTypes.array.isRequired,
    loading: PropTypes.bool,
    errors: PropTypes.object,
  };

  static defaultProps = {
    loading: false,
    errors: {},
  };

  constructor(props) {
    super(props);
    this.columns = [
      {
        title: props.translate('criteria'),
        dataIndex: 'type',
        width: '25%',
        className: 'relative-column',
        render: (text, record, index, tableInstance, Context) => {
          const editable = tableInstance.isEditing(record);

          const obj = {
            children: [],
          };

          if (record.operation === 'OR') {
            obj.children.push(
              <span
                className="criteria_table_operation"
                key={`criteria_table_operation-${index}`}
              >
                {props.translate('or')}
              </span>,
            );
          }

          if (editable) {
            const children = (
              <div
                className={
                  record.operation === 'OR' ? 'criteria_table_value' : ''
                }
                key={`criteria_table_value-${index}`}
              >
                <Context.Consumer>
                  {form => {
                    return (
                      <Form.Item style={{ marginBottom: 0 }}>
                        {form.getFieldDecorator('type', {
                          initialValue: record.type,
                        })(
                          <Select
                            disabled
                            style={{
                              width: 220,
                              maxWidth: 220,
                            }}
                            placeholder={props.translate('chooseType', 'form')}
                          >
                            {BUTTONS.map(item => (
                              <Select.Option key={item.type} value={item.type}>
                                {props.translate(item.type)}
                              </Select.Option>
                            ))}
                          </Select>,
                        )}
                      </Form.Item>
                    );
                  }}
                </Context.Consumer>
              </div>
            );

            obj.children.push(children);
          } else {
            const children = (
              <div
                className={
                  record.operation === 'OR' ? 'criteria_table_value' : ''
                }
                key={`criteria_table_value-${index}`}
              >
                {props.translate(text)}
              </div>
            );

            obj.children.push(children);
          }

          return obj;
        },
      },
      {
        title: '',
        dataIndex: '',
        width: '45%',
        render: (text, record, index, tableInstance, Context) => {
          const editable = tableInstance.isEditing(record);

          const obj = {
            children: [],
          };

          // If row is in edit mode
          if (editable) {
            const children = (
              <Context.Consumer>
                {form => {
                  const type = form.getFieldValue('type');

                  switch (type) {
                    case 'NUMERIC':
                      return (
                        <NumericForm
                          key={record.id}
                          record={record}
                          form={form}
                        />
                      );
                    case 'LANGUAGE':
                      return (
                        <LanguageForm
                          key={record.id}
                          record={record}
                          form={form}
                        />
                      );
                    case 'QUESTIONNAIRE':
                      return (
                        <QuestionnaireForm
                          key={record.id}
                          record={record}
                          form={form}
                        />
                      );
                    case 'GENDER':
                      return (
                        <GenderForm
                          key={record.id}
                          record={record}
                          form={form}
                        />
                      );
                    case 'MARITAL_STATUS':
                      return (
                        <MaritalStatusForm
                          key={record.id}
                          record={record}
                          form={form}
                        />
                      );
                    case 'WORK_PERMISSION':
                      return (
                        <WorkPermissionForm
                          key={record.id}
                          record={record}
                          form={form}
                        />
                      );
                    case 'NATIONALITY':
                      return (
                        <NationalityForm
                          key={record.id}
                          record={record}
                          form={form}
                        />
                      );
                    case 'PROFESSION':
                      return (
                        <ProfessionForm
                          key={record.id}
                          record={record}
                          form={form}
                        />
                      );
                    default:
                      return null;
                  }
                }}
              </Context.Consumer>
            );

            obj.children.push(children);
          } else {
            const { criterion } = record;
            const { isSimPatHasCriterion, isAnsweredWith } = criterion;
            const isString = isSimPatHasCriterion
              ? props.translate('is', 'form')
              : props.translate('isnt', 'form');
            const hasString = isSimPatHasCriterion
              ? props.translate('has', 'form')
              : props.translate('hasnt', 'form');
            const isAnsweredWithString = isAnsweredWith
              ? props.translate('answer', 'questionnaireAnswers')
              : props.translate('noanswer', 'questionnaireAnswers');

            const children = (
              <Context.Consumer>
                {form => {
                  switch (record.type) {
                    case 'QUESTIONNAIRE': {
                      return `"${criterion.question.question ||
                        criterion.question
                          .name}" ${isAnsweredWithString} ${criterion.openQuestionSearch ||
                        criterion.answerFromAnswersOptions.value ||
                        criterion.answer}`;
                    }

                    case 'LANGUAGE': {
                      const language = criterion.language.name;
                      const { isOnLevel, level } = criterion;

                      return `${language} ${props.translate(
                        isOnLevel,
                        'form',
                      )} ${getLanguageLevel(level)[0].title}`;
                    }

                    case 'MARITAL_STATUS': {
                      const { maritalStatus } = criterion;

                      return `${isString} ${props.translate(
                        maritalStatus,
                        'form',
                      )}`;
                    }

                    case 'NATIONALITY': {
                      const nationality = criterion.nationality.name;

                      return `${isString} ${nationality}`;
                    }

                    case 'NUMERIC': {
                      const { field, sign, value } = criterion;

                      return `${props.translate(field)} ${
                        getNumericSign(sign)[0].title
                      } ${value}`;
                    }

                    case 'PROFESSION': {
                      const profession = criterion.profession.name;

                      return `${isString} ${profession}`;
                    }

                    case 'WORK_PERMISSION': {
                      const { workPermission } = criterion;

                      return `${hasString} ${this.localizeOptions(
                        workPermission,
                      )}`;
                    }

                    case 'GENDER': {
                      const { gender } = criterion;

                      return `${isString} ${props.translate(gender, 'form')}`;
                    }

                    default:
                      return null;
                  }
                }}
              </Context.Consumer>
            );

            obj.children.push(children);
          }

          return obj;
        },
      },
      {
        dataIndex: '',
        width: '370px',
        className: 'table-button_centered',
        render: (text, record, index, tableInstance, Context) => {
          const { disabled } = this.props;
          const editable = tableInstance.isEditing(record);

          // If buttons disabled
          if (disabled) {
            return (
              <div className="editable-row-operations">
                <span>
                  {record.operation === 'OR' ? null : (
                    <a className="action-btn action-btn-is-disabled">
                      <Icon type="plus" /> {this.props.translate('or')}
                    </a>
                  )}
                  <a className="action-btn action-btn-is-disabled">
                    <Icon type="edit" /> {this.props.translate('edit', 'form')}
                  </a>
                  <a className="action-btn action-btn-is-disabled">
                    <Icon type="delete" />{' '}
                    {this.props.translate('delete', 'form')}
                  </a>
                </span>
              </div>
            );
          }

          // If row is in edit mode
          if (editable) {
            return (
              <div className="editable-row-operations">
                <span>
                  <Context.Consumer>
                    {form => (
                      <a
                        className="action-btn"
                        onClick={e => {
                          e.stopPropagation();
                          tableInstance.save(form, record.key);
                        }}
                        style={{ marginRight: 8 }}
                      >
                        {this.props.translate('save', 'form')}
                      </a>
                    )}
                  </Context.Consumer>
                  <a
                    className="action-btn"
                    onClick={e => {
                      e.stopPropagation();
                      tableInstance.cancel(record.key);
                    }}
                  >
                    {this.props.translate('cancel', 'form')}
                  </a>
                </span>
              </div>
            );
          }

          // If row is not in edit mode
          return (
            <div className="editable-row-operations">
              <span>
                {record.operation === 'OR' ? null : (
                  <a
                    className="action-btn"
                    onClick={e => {
                      e.stopPropagation();
                      this.handleOpenCriterionModal(record);
                    }}
                  >
                    <Icon type="plus" /> {this.props.translate('or')}
                  </a>
                )}
                <a
                  className="action-btn"
                  onClick={e => {
                    e.stopPropagation();
                    tableInstance.edit(record.key);
                  }}
                >
                  <Icon type="edit" /> {this.props.translate('edit', 'form')}
                </a>
                <Popconfirm
                  title={this.props.translate('areYouSureDelete', 'confirm')}
                  onClick={e => e.stopPropagation()}
                  onCancel={e => e.stopPropagation()}
                  onConfirm={this.handleDeleteCriterion(record)}
                >
                  <a className="action-btn">
                    <Icon type="delete" />{' '}
                    {this.props.translate('delete', 'form')}
                  </a>
                </Popconfirm>
              </span>
            </div>
          );
        },
      },
    ];

    this.state = {
      isAddCriterionModalOpen: false,
      prevCriterionId: -1,
      page: 0,
      pageSize: 30,
      total: 0,
      preventLazyLoad: false,
      activeFilter: '',
    };
  }

  componentDidMount() {
    this.props.getRoleInfo(this.props.match.params.id);
    this.handleUpdateTableData();
    window.addEventListener('scroll', this.handlePageScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handlePageScroll);
  }

  handleChangeActiveFilter = activeFilter =>
    this.setState(prevState => ({
      activeFilter: prevState.activeFilter === activeFilter ? '' : activeFilter,
    }));

  handlePageScroll = debounce(e => {
    const { body } = document;
    if (
      !this.state.preventLazyLoad &&
      this.props.criteria.length < this.state.total &&
      window.scrollY > body.scrollHeight - body.offsetHeight - 30
    ) {
      this.setState(
        { page: this.state.page + 1, preventLazyLoad: true },
        this.lazyLoadCriteria,
      );
    }
  }, 100);

  lazyLoadCriteria = () => {
    const { page, pageSize } = this.state;
    this.props
      .lazyLoadCriteria(this.props.match.params.id, { page, pageSize })
      .then(res => {
        this.setState({ total: res.pagination.total, preventLazyLoad: false });
      })
      .catch(err => message.error(this.props.translate('all', 'error')));
  };

  handleUpdateTableData = () => {
    const { page, pageSize } = this.state;
    this.props
      .getAllRolesCriteria(this.props.match.params.id, { page, pageSize })
      .then(res => {
        this.setState({ total: res.pagination.total });
      })
      .catch(err => message.error(this.props.translate('all', 'error')));
  };

  handleOpenCriterionModal = prevCriterion =>
    this.setState({
      isAddCriterionModalOpen: true,
      prevCriterionId: prevCriterion.id,
    });

  handleCloseCriterionModal = e =>
    this.setState({ isAddCriterionModalOpen: false });

  handleDeleteCriterion = criterion => e => {
    e.stopPropagation();
    const { id } = this.props.role;
    this.props.deleteRoleCriterion(id, criterion.id).catch(error => {
      if (
        error.response.status === 400 &&
        error.response.data.errorCode === 1800
      ) {
        message.error(error.response.data.message);
      }
    });
  };

  handleUpdateCriterion = async fields => {
    // Get role id
    const { id: roleId } = this.props.role;
    // Get id of edited criterion
    const { id: criterionId } = fields;
    // Create object to be send
    const data = {
      type: fields.type,
    };
    // Based on criterion type
    // create criterion DTO
    switch (fields.type) {
      case 'NUMERIC':
        data.criterion = {
          field: fields.field,
          sign: fields.sign,
          value: fields.value,
        };
        break;
      case 'LANGUAGE':
        data.criterion = {
          languageId: fields.languageId,
          isOnLevel: fields.isOnLevel,
          level: fields.level,
        };
        break;
      case 'QUESTIONNAIRE': {
        try {
          // Get all questions list
          const response = await api.getAllQuestions({
            search: '',
            page: 0,
            pageSize: 1000,
          });
          const questions = response.data.data;
          // Find selected question by id
          const question = questions.find(q => q.id === fields.questionId);
          // Get quesiton type, [ OPEN, SINGLE_SELECTION, MULTIPLE_SELECTION, YES_NO ]
          const { questionType } = question;
          // Convert is answered to bool value
          const isAnsweredWith = fields.isAnsweredWith === 'answer';
          // Create DTO object
          data.criterion = {
            isAnsweredWith,
            question: {
              id: fields.questionId,
              questionType,
            },
          };
          // If type OPEN add openQuestionSearch field to DTO
          if (questionType === 'OPEN') {
            data.criterion.openQuestionSearch = fields.openQuestionSearch;
          } else {
            // If any other type, find answer object by answer id
            const answerFromAnswersOptions = question.answersOptions.find(
              a => a.id === fields.answer,
            );
            // Add answer object to DTO
            data.criterion.answerFromAnswersOptions = answerFromAnswersOptions;
          }
        } catch (error) {
          throw error;
        }
        break;
      }
      case 'GENDER':
        data.criterion = {
          gender: fields.gender,
          isSimPatHasCriterion: fields.isSimPatHasCriterion === 'is', // Convert to bool val
        };
        break;
      case 'MARITAL_STATUS':
        data.criterion = {
          maritalStatus: fields.maritalStatus,
          isSimPatHasCriterion: fields.isSimPatHasCriterion === 'is', // Convert to bool val
        };
        break;
      case 'WORK_PERMISSION':
        data.criterion = {
          workPermission: fields.workPermission,
          isSimPatHasCriterion: fields.isSimPatHasCriterion === 'has', // Convert to bool val
        };
        break;
      case 'NATIONALITY':
        data.criterion = {
          nationalityId: fields.nationalityId,
          isSimPatHasCriterion: fields.isSimPatHasCriterion === 'is', // Convert to bool val
        };
        break;
      case 'PROFESSION':
        data.criterion = {
          professionId: fields.professionId,
          isSimPatHasCriterion: fields.isSimPatHasCriterion === 'is', // Convert to bool val
        };
        break;
      default:
        throw new Error('Unknown criterion type');
    }

    this.props.updateRoleCriterion(roleId, criterionId, data).catch(error => {
      if (
        error.response.status === 400 &&
        error.response.data.errorCode === 1800
      ) {
        message.error(error.response.data.message);
      }
    });
  };

  localizeOptions = item =>
    `${item}-${this.props.translate('permission', 'patient')}`;

  handleAddORCriterion = data => {
    const { prevCriterionId } = this.state;
    const { id: roleId } = this.props.role;
    // Add prevCriterion id to data object
    data.prevCriterionId = prevCriterionId;
    this.props
      .createRoleCriterion(roleId, data)
      .then(res => {
        this.setState({ page: 0 }, this.handleUpdateTableData);
      })
      .catch(error => {
        if (
          error.response.status === 400 &&
          error.response.data.errorCode === 1800
        ) {
          message.error(error.response.data.message);
        }
      });
  };

  handleAddCriterion = (type, data) => {
    const { id: roleId } = this.props.role;
    // Add type and operation type
    data.type = type;
    data.operation = 'AND';
    // Add criterion to the begining of the list
    data.prevCriterionId = 0;

    this.props
      .createRoleCriterion(roleId, data)
      .then(res => {
        this.setState(
          { page: 0, activeFilter: '' },
          this.handleUpdateTableData,
        );
      })
      .catch(error => {
        if (
          error.response.status === 400 &&
          error.response.data.errorCode === 1800
        ) {
          message.error(error.response.data.message);
        }
      });
  };

  render() {
    const dataSource = this.props.criteria.map(criterion => ({
      ...criterion,
      key: criterion.id,
    }));

    return (
      <React.Fragment>
        <div className="criteria">
          <div className="criteria_panel_btns">
            <FilterItem
              disabled={!this.props.role.lastVersion}
              type={FILTER_TYPE.NUMERIC}
              visible={this.state.activeFilter === FILTER_TYPE.NUMERIC}
              onVisibleChange={this.handleChangeActiveFilter}
              iconComponent={NumberSvg}
              formComponent={
                <NumericFilter onSubmit={this.handleAddCriterion} />
              }
            />
            <FilterItem
              disabled={!this.props.role.lastVersion}
              type={FILTER_TYPE.GENDER}
              visible={this.state.activeFilter === FILTER_TYPE.GENDER}
              onVisibleChange={this.handleChangeActiveFilter}
              iconComponent={GenderSvg}
              formComponent={
                <GenderFilter onSubmit={this.handleAddCriterion} />
              }
            />
            <FilterItem
              disabled={!this.props.role.lastVersion}
              type={FILTER_TYPE.QUESTIONNAIRE}
              visible={this.state.activeFilter === FILTER_TYPE.QUESTIONNAIRE}
              onVisibleChange={this.handleChangeActiveFilter}
              iconComponent={QuestionSvg}
              formComponent={
                <QuestionnaireFilter onSubmit={this.handleAddCriterion} />
              }
            />
            <FilterItem
              disabled={!this.props.role.lastVersion}
              type={FILTER_TYPE.LANGUAGE}
              visible={this.state.activeFilter === FILTER_TYPE.LANGUAGE}
              onVisibleChange={this.handleChangeActiveFilter}
              iconComponent={LangSvg}
              formComponent={
                <LanguageFilter onSubmit={this.handleAddCriterion} />
              }
            />
            <FilterItem
              disabled={!this.props.role.lastVersion}
              type={FILTER_TYPE.NATIONALITY}
              visible={this.state.activeFilter === FILTER_TYPE.NATIONALITY}
              onVisibleChange={this.handleChangeActiveFilter}
              iconComponent={NationSvg}
              formComponent={
                <NationalityFilter onSubmit={this.handleAddCriterion} />
              }
            />
            <FilterItem
              disabled={!this.props.role.lastVersion}
              type={FILTER_TYPE.PROFESSION}
              visible={this.state.activeFilter === FILTER_TYPE.PROFESSION}
              onVisibleChange={this.handleChangeActiveFilter}
              iconComponent={ProfessionSvg}
              formComponent={
                <ProfessionFilter onSubmit={this.handleAddCriterion} />
              }
            />
            <FilterItem
              disabled={!this.props.role.lastVersion}
              type={FILTER_TYPE.WORK_PERMISSION}
              visible={this.state.activeFilter === FILTER_TYPE.WORK_PERMISSION}
              onVisibleChange={this.handleChangeActiveFilter}
              iconComponent={WorkSvg}
              formComponent={
                <WorkPermissionFilter onSubmit={this.handleAddCriterion} />
              }
            />
            <FilterItem
              disabled={!this.props.role.lastVersion}
              type={FILTER_TYPE.MARITAL_STATUS}
              visible={this.state.activeFilter === FILTER_TYPE.MARITAL_STATUS}
              onVisibleChange={this.handleChangeActiveFilter}
              iconComponent={MaritalSvg}
              formComponent={
                <MaritalStatusFilter onSubmit={this.handleAddCriterion} />
              }
            />
          </div>
        </div>
        <Modal
          destroyOnClose
          title={this.props.translate('addCriteria', 'role')}
          visible={this.state.isAddCriterionModalOpen}
          onCancel={this.handleCloseCriterionModal}
          footer={null}
        >
          <AddCriterion
            onCancel={this.handleCloseCriterionModal}
            onSubmit={this.handleAddORCriterion}
          />
        </Modal>
        <Spin spinning={this.props.loading}>
          <Tooltip
            placement="top"
            title={this.props.translate('criteriaForSp', 'tooltips')}
          >
            <div className="table-with-action">
              <EditDragTable
                bordered
                canDrag={false}
                columns={this.columns}
                dataSource={dataSource}
                onSave={this.handleUpdateCriterion}
              />
            </div>
          </Tooltip>
        </Spin>
      </React.Fragment>
    );
  }
}
