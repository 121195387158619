import React, { Component } from 'react';
import { Dropdown, Icon, Menu } from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { find } from 'lodash';

import { LANG_MENU } from '../../constants/common';
import { CHANGE_LANGUAGE } from '../../constants/actions';

const mapStateToProps = ({ localization: { lang } }) => ({ lang });

@injectIntl
@connect(mapStateToProps)
export default class Header extends Component {
  static contextTypes = {
    form: PropTypes.object,
  };

  get currentLang() {
    const langObj = find(LANG_MENU, { key: this.props.lang });
    if (langObj) {
      return langObj.text;
    }
    return 'Deutsch';
  }

  onLangClick = ({ key }) =>
    this.props.dispatch({ type: CHANGE_LANGUAGE, lang: key });

  get menu() {
    return (
      <Menu onClick={this.onLangClick}>
        {LANG_MENU.map(({ text, key }) => (
          <Menu.Item key={key}>{text}</Menu.Item>
        ))}
      </Menu>
    );
  }

  render() {
    const { intl } = this.props;
    return (
      <div className="login_title">
        {intl.formatMessage({ id: this.props.name })}
        <Dropdown overlay={this.menu} trigger={['click']}>
          <span className="ant-dropdown-link login_languages ">
            {this.currentLang} <Icon type="down" />
          </span>
        </Dropdown>
      </div>
    );
  }
}
