import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Icon, Collapse } from 'antd';
import { get, groupBy, sortBy } from 'lodash';

/** Actions */
import { importFileFromIOsce } from 'actions/osceAdministration';

/** Components */
import TablePanel from 'components/TablePanel';

/** Services */
import { msgError } from 'services/errorHandler';
import { momentFormatDateTime } from 'services/format';

/** Constants */
import { IMPORT_STATUS } from 'constants/common';

export default class Import extends React.PureComponent {
  static propTypes = {
    translate: PropTypes.func,
    osceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    osceData: PropTypes.object,
    lastImportedFromIOsce: PropTypes.string,
    osceImportStatus: PropTypes.string,
    updateData: PropTypes.func,
  };

  state = {
    importedFiles: [],
    warnings: [],
    loading: false,
    text: '',
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.lastImportedFromIOsce &&
      prevProps.lastImportedFromIOsce !== this.props.lastImportedFromIOsce
    ) {
      this.setState({
        text: `Last import was ${momentFormatDateTime(
          this.props.lastImportedFromIOsce,
        )}`,
      });
    }
  }

  get type() {
    let type = '';
    switch (this.props.osceImportStatus) {
      case 'FAILED':
      case 'PARTIALLY_FAILED':
        type = 'error';
        break;
      case 'SUCCESSFUL':
        type = 'success';
        break;
      default:
        type = 'info';
    }
    return type;
  }

  get status() {
    const { osceImportStatus, lastImportedFromIOsce } = this.props;
    const status =
      osceImportStatus !== 'NONE' && lastImportedFromIOsce
        ? `Status: ${
            osceImportStatus ? IMPORT_STATUS[osceImportStatus] : 'none'
          }`
        : this.props.translate('noFilesImported');
    return this.state.loading ? 'Files are importing ...' : status;
  }

  importFiles = async () => {
    const { osceData } = this.props;
    this.setState({ text: '', loading: true });
    try {
      const resp = await importFileFromIOsce({ osceId: this.props.osceId });
      if (!resp.data.importedFiles.length && !resp.data.errors.length) {
        this.setState({
          text: 'There are no files to import',
          warnings: resp.data.warnings,
        });
      } else if (resp.data.importedFiles.length && resp.data.errors.length) {
        this.setState({
          importedFiles: resp.data.importedFiles,
          warnings: resp.data.warnings,
          text: `${resp.data.errors.length} of ${resp.data.importedFiles
            .length + resp.data.errors.length} file(-s) were not imported\n`,
        });
        this.props.updateData(
          { ...osceData, osceImportStatus: 'PARTIALLY_FAILED' },
          'osce',
        );
      } else if (!resp.data.errors.length) {
        this.setState({
          importedFiles: resp.data.importedFiles,
          warnings: resp.data.warnings,
          text: 'Files are successfully imported',
        });
        this.props.updateData(
          { ...osceData, osceImportStatus: 'SUCCESSFUL' },
          'osce',
        );
      } else {
        this.setState({
          warnings: resp.data.warnings,
          text: `${resp.data.errors.length} of ${resp.data.importedFiles
            .length + resp.data.errors.length} file(-s) were not imported\n`,
        });
        this.props.updateData(
          { ...osceData, osceImportStatus: 'FAILED' },
          'osce',
        );
      }
    } catch (e) {
      msgError(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  renderWarnings = warningsArr => {
    // Group warnings array by date
    const warningsByDate = groupBy(warningsArr, 'osceDayDate');

    return (
      <Collapse>
        <Collapse.Panel header="Warnings" key="1" extra={warningsArr.length}>
          <Collapse>
            {Object.entries(warningsByDate).map(([date, warnings]) => (
              // Render a collapsed panel for each date
              <Collapse.Panel key={date} header={date}>
                {/* Sort warnings by start time and render Alert for each warning */}
                {sortBy(warnings, ['timeSlotStartTime']).map((warning, i) => (
                  <Alert
                    key={i}
                    message={`${warning.examinerName} | ${
                      warning.timeSlotStartTime
                    }-${warning.timeSlotEndTime}`}
                    description={`${warning.studentName}'s results are missing`}
                    type="warning"
                  />
                ))}
              </Collapse.Panel>
            ))}
          </Collapse>
        </Collapse.Panel>
      </Collapse>
    );
  };

  render() {
    const { translate, osceImportStatus, lastImportedFromIOsce } = this.props;
    const { importedFiles, text, warnings } = this.state;
    return (
      <React.Fragment>
        <div className="import-block_title">{translate('import', 'form')}</div>
        <TablePanel>
          <Button
            icon="import"
            className="table-panel_btn"
            onClick={this.importFiles}
            disabled={get(this.props, 'osceData.freezed')}
          >
            {translate('import', 'form')}
          </Button>
        </TablePanel>
        {osceImportStatus && (
          <Alert
            className="import-block_alert"
            description={lastImportedFromIOsce && text}
            message={this.status}
            type={this.state.loading ? 'info' : this.type}
            showIcon
          />
        )}
        {warnings.length > 0 && this.renderWarnings(warnings)}
        {importedFiles.length > 0 && (
          <ul className="import-block_list-files">
            {importedFiles.map((item, i) => (
              <li key={i}>
                <Icon type="paper-clip" />
                {item}
              </li>
            ))}
          </ul>
        )}
      </React.Fragment>
    );
  }
}
