import qs from 'qs';
import { msgError } from 'services/errorHandler';
import { wrapRequest } from './ajax';

export const getPdfFile = async ({
  url,
  locale,
  params,
  data,
  method = 'GET',
}) => {
  try {
    const resp = await wrapRequest({
      method,
      url,
      params: {
        ...params,
        locale: locale === 'en' ? 'ENGLISH' : 'GERMAN',
      },
      data,
      paramsSerializer: data => qs.stringify(data, { arrayFormat: 'repeat' }),
      responseType: 'arraybuffer',
    });
    const blob = new Blob([resp.data], { type: 'application/pdf' });
    const openWindow = window.open();
    openWindow.location = window.URL.createObjectURL(blob);
  } catch (e) {
    msgError(e);
  }
  return null;
};
