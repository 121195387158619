import objKeys2Strings from '../services/objKeys2Strings';

export default {
  messages: objKeys2Strings({
    header: {
      simulatedPatients: 'Simulated Patients',
      examiners: 'Examiners',
      roles: 'Roles',
      OSCEAdministration: 'OSCE Administration',
      systemAdministration: 'System Administration',
      administration: 'Administration',
      multiple: 'Multiple',
    },
    form: {
      role: 'Role',
      room: 'Room',
      bellSystem: 'Bell System',
      eventType: 'Event',
      preview: 'iOSCE Preview',
      useDefaultSettings: 'Use Default Settings',
      basicData: 'Basic',
      circuitsDetails: 'Circuits',
      generateOSCE: 'Generate OSCE',
      createCircuit: 'Create Circuit',
      schedule: 'Schedule',
      studentsData: 'Students',
      importAndExport: 'Import & Export',
      planSpTrainings: 'Plan SP trainings',
      assignments: 'Assignments',
      osceSettings: 'Settings',
      results: 'Results',
      sectionName: 'Section name',
      description: 'Description',
      weighting: 'Weighting in %',
      typeName: 'Type name',
      typeDescription: 'Type description',
      MINUTE: 'Minute',
      SECOND: 'Second',
      basePath: 'Base path',
      feedbackBasePath: 'Feedback Base path',
      host: 'Host',
      feedbackHost: 'Feedback Host',
      roleInformation: 'Role Information',
      checklist: 'Checklist',
      targetSystem: 'Target System',
      pastUseOfRole: 'Past Use of Role',
      criteriaForSpSelection: 'Criteria For SP Selection',
      export: 'Export',
      filter: 'Filter',
      clearAll: 'Clear All',
      hideFilters: 'Hide Filters',
      selectRole: 'Select Role',
      selectQuestion: 'Select Question',
      selectSection: 'Select Section',
      new: 'New',
      import: 'Import',
      minor: 'Minor',
      major: 'Major',
      rolesChangeTitle: 'Some changes were made',
      rolesChangeDescription: 'The role was used in OSCE ',
      insert: 'Please fill in',
      studentId: 'Student ID',
      floor: 'Floor',
      calendarYear: 'Calendar Year',
      semesterType: 'Semester Type',
      roomNumber: 'Room number',
      discipline: 'Discipline',
      clinic: 'Clinic',
      title: 'Title',
      username: 'Username',
      feedbackUsername: 'Feedback Username',
      firstName: 'First Name',
      lastName: 'Last Name',
      password: 'Password',
      feedbackPassword: 'Feedback Password',
      repeatPassword: 'Repeat password',
      goToLogin: 'Go to login',
      login: 'Log In',
      city: 'City',
      name: 'Name',
      postalCode: 'Postal code',
      street: 'Street',
      enterEmail: 'Enter email',
      lookEmail: 'Look at your email',
      changePassword: 'Change password',
      createPassword: 'Create password',
      send: 'Send',
      create: 'Create',
      save: 'Save',
      edit: 'Edit',
      delete: 'Delete',
      deleteOsce: 'Delete OSCE',
      freezeOsce: 'Freeze OSCE',
      unFreezeOsce: 'Unfreeze OSCE',
      osceFrozen:
        'This OSCE is frozen. Please contact your administrator to request any changes.',
      cancel: 'Cancel',
      success: 'Success',
      signUp: 'Sign Up',
      forgotPassword: 'Forgot password?',
      resetPassword: 'Reset password',
      checkboxGroup: 'Please check',
      incorrect: 'Incorrect credentials',
      birthday: 'Birth date',
      gender: 'Gender',
      MALE: 'Male',
      FEMALE: 'Female',
      UNKNOWN: 'Unknown',
      weight: 'Weight',
      height: 'Height',
      nationality: 'Nationality',
      maritalStatus: 'Marital status',
      UNMARRIED: 'Unmarried',
      MARRIED: 'Married',
      CIVIL_UNION: 'Civil union',
      DIVORCED: 'Divorced',
      WIDOWED: 'Widowed',
      email: 'Email',
      country: 'Country',
      mobile: 'Mobile',
      telephone: 'Telephone',
      profession: 'Profession',
      workPermission: 'Work permission',
      socialInsurance: 'Social Insurance No.',
      languages: 'Languages',
      addLanguage: 'Add Language',
      DOB: 'Birth date',
      rights: 'Rights',
      IBAN: 'IBAN',
      owner: 'Owner',
      bank: 'Bank',
      BIC: 'BIC',
      add: 'Add',
      change: 'Change',
      addPhoto: 'Add foto',
      changePhoto: 'Change foto',
      remove: 'Remove',
      skillName: 'Skill name',
      maxStudentsNumber: 'Max students',
      studyYear: 'Academic year',
      roleDesignation: 'Role designation',
      acronym: 'Acronym',
      roleType: 'Role type',
      skill: 'Skill',
      absoluteNumberOfBackupSPs: 'Number of backups-SPs',
      statisticFactor: 'Statistic factor',
      WEIGHT: 'Weight',
      HEIGHT: 'Height',
      AGE: 'Age',
      BMI: 'BMI',
      OVER: 'on a better level than',
      LOWER: 'on a lower level than',
      EQUAL: 'on level',
      NOT_EQUAL: 'not on level',
      is: 'is',
      isnt: "isn't",
      has: 'has',
      hasnt: "hasn't",
      section: 'Section',
      question: 'Question',
      comment: 'Comment',
      only6OrCriterias: "Sorry, but you can add only 6 'OR' criteria",
      search: 'Search',
      criteria: 'Criteria',
      value: 'Value',
      count: 'Count',
      comments: 'Comments',
      option: 'Option',

      OSCEName: 'OSCE Name',
      OSCECreationType: 'OSCE creation type',
      repetition: 'Repetition',
      isFormative: 'Is formative OSCE',
      NumberOfStudents: '# of students',
      NumberOfCircuits: '# of circuits',
      NumberOfRooms: '# of rooms',

      osceDate: 'Start day',
      startTime: 'Start time',
      endTime: 'Max. end time',
      stationDuration: 'Station duration, min',

      shortBreak: 'Short break duration, min',
      lunchBreak: 'Lunch break duration, min',
      rotationBreak: 'Rotation break duration, min',
      coffeeBreak: 'Coffee break duration, min',
      spChangeover: 'Short break with SP-changeover, min',
      maxTimeUntilLunchBreak: 'Max. time till the lunch break, min',
      maxTimeUntilLongBreak: 'Max. time till the coffee break, min',
      encryptionKey: 'Encryption key',

      addManually: 'Add Manually SPs',
      addAllSP: 'Add All Active SPs',
      simulatedPatient: 'Simulated Patient',

      studentName: 'Student',
      selectStudent: 'Select Student',
      spName: 'Simulated Patient',
      selectSP: 'Select Simulated Patient',
      swapSP: 'Swap Simulated Patient',
      swapStudent: 'Swap Student',
      swap: 'Swap',
      examinerName: 'Examiner',
      selectExaminer: 'Select Examiner',
      notAssigned: 'Not assigned',
      assignStartTime: 'Start time',
      assignEndTime: 'End time',
      notFound: 'Not found',
      chooseProperty: 'Choose Property',
      chooseOperation: 'Choose Operation',
      chooseQuestionnaire: 'Choose Questionnaire',
      chooseOptions: 'Choose Options',
      chooseGender: 'Choose Gender',
      chooseLanguage: 'Choose Language',
      chooseMaritalStatus: 'Choose Marital Status',
      chooseLevel: 'Choose Level',
      chooseWorkPermission: 'Choose Work Permission',
      chooseNationality: 'Choose Nationality',
      chooseProfession: 'Choose Profession',
      chooseType: 'Choose Type',

      SIM_PAT: 'Simulated Patient',
      SUPERNUMERARY: 'Supernumerary',
      MATERIAL: 'Material',

      RATIO: 'Ratio',
      NONE: 'None',

      answer: 'Answered with',
      noanswer: 'Not answered with',

      failedItems: 'Partially Items',
      allItems: 'All Items',
      feedback: 'Feedback',
      audio: 'Gesprochenes Feedback',
      downloadFailed: 'Download',
      downloadAll: 'Download',
    },
    patientsColumns: {
      name: 'Last Name',
      firstName: 'First Name',
      email: 'Email',
      gender: 'Gender',
      town: 'City',
      age: 'Age',
    },
    studentsColumns: {
      name: 'Last Name',
      firstName: 'First Name',
      email: 'Email',
      attendance: 'Attendance',
      import: 'Import',
    },
    studentsOSCEColumns: {
      name: 'Last Name',
      firstName: 'First Name',
      email: 'Email',
    },
    professionColumns: {
      name: 'Profession',
      addBtn: 'Add profession',
    },
    sectionColumns: {
      section: 'Section',
      addBtn: 'Add section',
    },
    sections: {
      add: 'Add question',
      edit: 'Edit question',
      question: 'Question',
      answer: 'Answer',
      answerType: 'Answer Type',
      addAnswer: 'Add answer',
      chooseAnswer: 'Choose answer',
      open: 'Open',
      yesNo: 'Yes/No',
      single: 'Single',
      multiply: 'Multiply',
      yes: 'Yes',
      no: 'No',
      placeholderSection: 'Please select section',
      atLeastOneAnswer: 'Please input answer or delete this field',
      typeQuestion: 'Text question',
    },
    examinerColumns: {
      name: 'Name',
      firstName: 'First Name',
      title: 'Title',
    },
    osceColumns: {
      osces: 'OSCEs',
      sps: 'SPs',
      stationNumber: 'Number of stations',
      dates: 'Dates',
      autoOrManual: 'Auto/Manual',
    },
    patients_sidebar: {
      simulatedPatients: 'Simulated Patients',
      professions: 'Professions',
      questionnaire: 'Questionnaire',
    },
    placeholder: {
      selectGender: 'Select Gender',
      selectDiscipline: 'Select Discipline',
      academicTitle: 'f.e. Prof. Dr. Med',
      selectClinic: 'Select Clinic',
    },
    patient: {
      print: 'Print',
      printFees: 'Print fees',
      showDeletedSP: 'Show deleted SP',
      showInactiveSP: 'Show inactive SP',
      patientProfile: 'Patient profile',
      questionnaire: 'Questionnaire',
      personalInformation: 'Personal Information',
      contactDetails: 'Contact Details',
      professionalDetails: 'Professional Details',
      billingDetails: 'Billing Details',
      permission: 'permission',
      deleted: 'Deleted',
      active: 'Activated',
      inactive: 'Deactivated',
      showComments: 'Show comments',
      questionsAndAnswers: 'Questions & Answers',
      sections: 'Sections',
      noChangeAnswer: "Can't change answer",
      noComment: 'No comments.',
      addSP: 'Add SP',
      filtered: 'Filtered',
      of: 'of',
    },
    examiners_sidebar: {
      examiners: 'Examiners',
      clinics: 'Clinics',
    },
    cards: {
      personalInformation: 'Personal Information',
      clinicInformation: 'Clinic Information',
      secretary: 'Secretary',
      generalInformation: 'General Information',
      OSCEParticipation: 'OSCE Participation',
      storageCredentials: 'Server Settings (SFTP)',
      feedbackCredentials: 'Student-Feedback Server Settings (SFTP)',
      iOsceSettings: 'iOSCE Settings',
      osceName: 'OSCE name',
      feedbackDepot: 'Student-Feedback Server Settings (DepotCredentials)',
      deployDepot: 'Deploy Server Settings (DepotCredentials)',
      resultsDepot: 'Results Server Settings (DepotCredentials)',
    },
    role_sidebar: {
      roleDefinition: 'Roles',
      discipline: 'Disciplines & Skills',
    },
    roleDefinitionColumns: {
      skill: 'Skill',
      availableRoles: 'Available roles',
      addRole: 'Add role',
      roleInformation: 'Role Information',
    },
    role: {
      addTargetSystem: 'Add target system',
      addSection: 'Add Section',
      addQuestion: 'Add Item',
      editQuestion: 'Edit Item',
      studentsExits: '{created} created, {updated} updated',
      editSection: 'Edit Section',
      addCriteria: 'Add Criterion',
      addOption: 'Add Option',
      POPOVER: 'Popover',
      SLIDER: 'Slider',
      TOGGLE_TWO: 'Toggle 2',
      TOGGLE_THREE: 'Toggle 3',
      checklistQrCode: 'Checklist QR code',
      regressionItem: 'Is regression item',
      criteria: 'Criteria',
      options: 'Options',
    },
    checklistColumns: {
      section: 'Section',
      description: 'Description',
      weight: 'Weighting in %',
    },
    subChecklist: {
      questionCriterias: 'Items & Criteria',
      options: 'Options',
      values: 'Values',
      criteriaCount: 'Criterion count',
    },
    examinersOSCEColumns: {
      osce: 'OSCEs',
      startDate: 'Start',
      endDate: 'End',
      semester: 'Semester',
    },
    disciplineColumns: {
      discipline: 'Discipline',
      actions: 'Actions',
      addBtn: 'Add discipline',
      addSkill: 'Add skill',
      editSkill: 'Edit skill',
    },
    skillInDisciplineColumns: {
      skill: 'Skill',
      maxNumber: 'Max students',
      studyYear: 'Academic year',
    },
    systemAdministration: {
      baseData: 'Base data',
      resources: 'Resources',
      rooms: 'Rooms',
      semesters: 'Semesters',
      OSCEManager: 'OSCE Manager',
      administrators: 'Users',
      activityLog: 'Activity log',
      students: 'Students',
      studentsDetails: 'Students',
      activate: 'Activate',
      deactivate: 'Deactivate',
      catalogs: 'Catalogs',
    },
    osceAdministration: {
      osceData: 'OSCE data',
      settings: 'Settings',
      semesters: 'Semesters',
      osces: 'OSCEs',
      costAccounting: 'Cost Accounting',
      outputs: 'Outputs',
      spSelection: 'SP Selection',
      basicData: 'Basic data',
      osceSettings: 'Default Settings',
      timeData: 'Time data',
      MANUAL: 'Manual',
      AUTO: 'Auto',
      osceTypeTitle:
        'Are you sure you want to switch the creation type? In this case the entered data will be erased.',
    },
    nationalityColumns: {
      nationality: 'Nationality',
      name: 'Nationality',
      addBtn: 'Add nationality',
    },
    administratorsColumns: {
      ID: 'ID',
      email: 'Email',
      firstName: 'First Name',
      lastName: 'Last Name',
      roles: 'Roles',
      state: 'Status',
      activate: 'Activate',
      deactivate: 'Deactivate',
    },
    questionnaireAnswers: {
      all: 'All',
      answered: 'Answered',
      unanswered: 'Unanswered',
      answer: 'Answered with',
      noanswer: 'Not answered with',
      deleteAnswer: 'Delete answer',
    },
    catalogsColumns: {
      catalogs: 'Catalogs',
    },
    clinicExaminersByOsceColumns: {
      examiner: 'Examiner',
    },
    catalogs: {
      updateAll: 'Update All',
      update: 'Update',
    },
    modalForm: {
      addNewAdministrator: 'Add new User',
      addNewSection: 'Add Section',
      addNewExaminer: 'Add Examiner',
      osce: 'OSCE',
      editAdministrator: 'Edit User',
      addNewClinic: 'Add Clinic',
      addNewSP: 'Add Simulated Patient',
      addNewRoom: 'Add Room',
      addNewSemester: 'Add Semester',
      addNewRole: 'Add Role',
      addNewOsce: 'Add OSCE',
      allClinics: 'All clinics',
    },
    rights: {
      ROLE_SUPER_ADMIN: 'System Adminstration',
      ROLE_S_PATIENT_ADMIN: 'Simulated Patients',
      ROLE_EXAM_ADMIN: 'Examiners',
      ROLE_OSCE_ADMIN: 'OSCE Administration',
      ROLE_ROLES_ADMIN: 'Roles',
      ROLE_ADMIN: 'Adminstration',
    },
    error: {
      fillRequired: 'Please fill in all required fields',
      insert: 'Please fill in',
      unEqualPasswords: 'The passwords must be equals',
      notValidPhoneNumber: 'Invalid phone number',
      notValidAHVNumber: 'Invalid AHV number',
      notValidPostalCode: 'Invalid postal code',
      onlyPositiveNumber: 'Only the positive values of numbers',
      validatorOsceName:
        'OSCE name can only contain letters, numbers, -, _ and space',
      email: 'Invalid email',
      iban: 'Invalid IBAN',
      youCanUpload: 'You can only upload',
      imgMustBeSmaller: 'Image must be smaller than',
      cantUpload: "Can't upload",
      required: 'Please fill this field',
      invalidLink: 'Invalid link',
      failed: 'Failed',
      success: 'Success',
      noOptionChosen: "Answer hasn't been saved, choose at least one option.",
      missed: 'Missed',
      '1062+': 'There is',
      1451: 'Cannot delete, because this is in use.',
      1062: 'This item already exists.',
      all: 'Something went wrong',
      invalidOptionsCount: 'Invalid number of options',
      BicNumber: 'Invalid BIC',
      tooLong: 'Text is too long. Please make it little shorter and try again.',
    },
    confirm: {
      areYouSureDelete: 'Are you sure you want to delete ?',
    },
    roleCriteria: {
      criteria: 'Criteria',
      or: 'OR',
      GENDER: 'Gender',
      LANGUAGE: 'Language',
      MARITAL_STATUS: 'Marital status',
      NATIONALITY: 'Nationality',
      NUMERIC: 'Numeric',
      PROFESSION: 'Profession',
      WORK_PERMISSION: 'Work permission',
      QUESTIONNAIRE: 'Questionnaire',
      WEIGHT: 'Weight',
      HEIGHT: 'Height',
      AGE: 'Age',
      BMI: 'BMI',
    },
    iOsceSettings: {
      backUpPeriod: 'Backup period',
      timeUnit: 'Time unit',
      symmetricKey: 'Symmetric Key',
      settingPassword: 'Setting password',
      screenSaverTime: 'Screen saver time (min.)',
      webServicePath: 'Web service path',
      registerDevicePath: 'Register device path',
      screenSaverText: 'Screen saver text',
    },
    notFound: {
      notExist: 'The page you are looking for does not exist',
      returnHomePage: 'Return to home page',
    },
    output: {
      export: 'Export OSCE Data',
      success: 'Mail has been sent',
      exportedfiles: 'Exported files',
      exportIOSCE: 'Export iOSCE',
      sendInvitations: 'Send Invitations',
      printSchedules: 'Print Schedules',
      sendMail: 'Send mail',
      printCopies: 'Print copies',
      exportQRCode: 'Export QR Code',
      students: 'Students',
      examiners: 'Examiners',
      simulatedPatients: 'Simulated Patients',
      successMail: 'Invitations sent',
      successExport: 'Export success',
      download: 'Download',
      showQr: 'Show QR Code',
      openInPusher: 'Open in Pusher App',
      template: 'Template',
      areYouSureSendEmail: 'Are you sure you want to send email?',
    },
    longText: {
      xlsInformation:
        'The Excel file containing the participating Students must have following column headers: ' +
        'studentid, firstname, lastname, email and gender. The columns street, zip and city are optional.',
    },
    spSelectionsColumns: {
      accepted: 'Accepted',
      name: 'Name',
      assignTo: 'Assign to',
    },
    spSelection: {
      autoAssign: 'Auto Assign',
      addManually: 'Add Manually',
      clearSelection: 'Clear Selection',
      export: 'Export',
      hideUnavailableSPs: 'Hide unavailable SPs',
      assignSPsforHalfDays: 'Assign SPs for half-days',
    },
    preview: {
      logicalBreak: 'Logical break',
      rotationBreak: 'Rotation break',
      lunchBreak: 'Lunch break',
      coffeeBreak: 'Coffee break',
      circuit: 'Circuit',
      endTime: 'End time',
      rotation: 'Rotation',
      generatePreview: 'Generate Preview',
      day: 'Day',
      of: 'of',
    },
    circuit: {
      circuit: 'Circuit',
      alreadyAssigned:
        'This room was already assigned to a different station. Do you still want to assign this room? ' +
        'Therefore, it would be unassigned from the previous one.',
      date: 'Date',
      noData: 'No data',
      dayStartTime: 'Start time',
      dayEndTime: 'End time',
      dayLunchBreak: 'Lunch break',
      dayCoffeeBreak: 'Coffee break',
      generate: 'Generate Schedule',
      calculate: 'Calculate',
      REGULAR: 'Regular',
      BREAK: 'Break',
      PREPARATION: 'Preparation',
      DOUBLE: 'Double',
      DOUBLESP: 'Double SP',
      SP_PAUSED: 'SP Paused',
      PAUSED_STUDENT: 'Student break',
      earlierLunch: 'Earlier Lunch',
      preponeFirst: 'Prepone first rotation to previous',
      laterLunch: 'Later Lunch',
      postponeLast: 'Postpone last rotation to next day',
      ROTATION_BREAK: 'Rotation break',
      LUNCH_BREAK: 'Lunch Break',
      COFFEE_BREAK: 'Coffee Break',
      breakSooner: 'Break Sooner',
      breakLater: 'Break Later',
      breakDuration: 'Break Duration',
      areYouSureClearAllCircuit:
        'Are you sure you want to clear all?\n' +
        "It will delete all the assignments you've set. There will be no possibility of undoing the process of clearing.",
      splitOsceDay:
        'Are you sure you want to split this OSCE day?\n This is an irreversible action.',
      newStation: 'New Station',
      type: 'Type',
      addCircuit: 'Add Circuit',
      addDay: 'Add Day',
      day: 'Day',
    },
    schedule: {
      studentsAssignment: 'Assign students',
      spAssignment: "Assign SP's",
      export: 'Export Shedule',
      assignmentInformation:
        'This action will irrevocably overwite any' +
        'previously defined assignments.',
      alphabetically: 'Alphabetically',
      random: 'Random order',
      updateSP: 'Update SP',
      circuit: 'Circuit',
      spPaused: 'Paused SPs',
      areYouSureClearAllSchedule:
        'Are you sure you want to clear all?\n' +
        'It will delete all your previous settings of schedules.\n' +
        'There will be no possibility of undoing the clearing.',
      students: 'Students',
      slotsSP: 'SP’s with slots',
      rotationSp: 'SP’s rotation plan',
      breakDuration: 'Break Duration',
    },
    import: {
      noFilesImported: 'No results were imported yet.',
      downloadResultFiles: 'Download Result Files',
      newExport: 'New Export',
    },
    costAccountingColumns: {
      firstName: 'First Name',
      lastName: 'Last Name',
    },
    activityLogColumns: {
      time: 'Time',
      user: 'User',
      action: 'Action',
    },
    tooltips: {
      sectionsColumns: 'Click on the section name to view items',
      questionnaireSections:
        'You can create sections for a questionaire. Use drag&drop option to move sections',
      iOscePreview: 'Use your iPad to scan QR code for a preview ',
      checklistWeight: 'The sum of sections weighting must be 100%',
      criteriaForSp:
        'Pick one or more criteria above to set up criterias for SP Selection',
      spSelectionOsce:
        'After you assigned SPs to the roles you can drag&drop them between roles',
      printSchedules: 'Choose a particular OSCE to print schedule for it',
      costAccounting: 'Choose SP which you want to include in cost accounting',
      scissor: 'Split an OSCE day into two sequences',
      rotationCount: 'Number of rotations',
      freezeOsce:
        "Once you freeze an OSCE no one can't make any changes to it, until you unfreeze ",
      generatePreview:
        'Generate OSCE preview to see all the days and rotations with a timing',
      roleVersion: 'View previous versions of the role',
      isFormative: 'Students will be assigned in the iOSCE application',
      materialRole: 'Preparation part. Please assign a role of type Material',
      checklistRole:
        'Assessment part. Please assign a role containing a checklist',
    },
  }),
  // formats: object,
  // messages: object,
  // textComponent: any,
  locale: 'en',
};
