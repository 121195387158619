import qs from 'qs';
import { wrapRequest } from 'utils/ajax';

export const sendOutputEmail = (name, type, params, data) =>
  wrapRequest({
    method: 'POST',
    url: `/${name}/${type}/send`,
    params,
    data,
    paramsSerializer: data => qs.stringify(data, { arrayFormat: 'repeat' }),
  });

export const scheduleQrCode = (type, data, id) =>
  wrapRequest({
    method: 'POST',
    url: `/schedule/osce/${id}/export/${type}`,
    data,
    responseType: 'arraybuffer',
  });

export const printOutput = (name, type, params, data) =>
  wrapRequest({
    method: 'POST',
    url: `/${name}/${type}/print/`,
    params,
    data,
    paramsSerializer: data => qs.stringify(data, { arrayFormat: 'repeat' }),
    responseType: 'arraybuffer',
  });

export const getTemplate = (type, name, id) =>
  wrapRequest({
    method: 'GET',
    url: `/template/${id}/${type}/${name}`,
  });

export const saveTemplate = (type, name, semesterId, data) =>
  wrapRequest({
    method: 'POST',
    url: `/template/${semesterId}/${type}/${name}`,
    data,
  });

export const restoreTemplate = (type, name, semesterId) =>
  wrapRequest({
    method: 'DELETE',
    url: `/template/${semesterId}/${type}/${name}`,
  });
