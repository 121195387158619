import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { find, findIndex } from 'lodash';

/** Services */
import addLoadWithRedux from 'services/decorators/addLoadWithRedux';
import { msgError } from 'services/errorHandler';

/** UI */
import { Button, Icon, Tooltip } from 'antd';

/** Actions */
import {
  getOsceDays,
  getSequence,
  moveBreak,
  moveRotation,
} from 'actions/circuits';

/**
 * Component which render control buttons at osce day header
 * @reactProps {func} translate - function which translate text.
 * @reactProps {array} timeSlotResponses - list of timeslots data
 * @reactProps {array} osceDaysData - details about osce day
 * @reactProps {string} osceDayId - osce day id
 * @reactProps {string} osceStatus - osce status
 * @reactProps {boolean} isLaterDisabled - boolean value for track block of later button
 * @reactProps {func} isEarlieDisabled - boolean value for track block of earlier button
 */
@withRouter
@addLoadWithRedux({
  i18Name: 'circuit',
  runtimeReduxNames: ['osceDays'],
  mapMethods: { getSequence, getOsceDays },
})
class HeaderButtons extends PureComponent {
  static propsTypes = {
    translate: PropTypes.func,
    osceDayId: PropTypes.number,
    osceStatus: PropTypes.string.isRequired,
    osceDaysData: PropTypes.object.isRequired,
    isEarlieDisabled: PropTypes.bool.isRequired,
    isLaterDisabled: PropTypes.bool.isRequired,
    isPreponeDisabled: PropTypes.bool.isRequired,
    isPostponeDisabled: PropTypes.bool.isRequired,
    timeSlotResponses: PropTypes.array.isRequired,
    getSequence: PropTypes.func.isRequired,
  };

  get isDisabled() {
    return this.props.osceStatus !== 'CIRCUITS CREATED';
  }

  editLunchBreak = sooner => async e => {
    e.stopPropagation();
    try {
      const {
        osceDayId,
        timeSlotResponses,
        getSequence,
        getOsceDays,
        match,
      } = this.props;
      const lunchBreak = find(timeSlotResponses, {
        timeSlotType: 'LUNCH_BREAK',
      });
      await moveBreak(lunchBreak.id, { sooner });
      await getSequence(`sequence${osceDayId}`, osceDayId, {
        isBackground: true,
      });
      await getOsceDays('osceDays', match.params.id, {
        isBackground: true,
      });
    } catch (err) {
      msgError(err);
    }
  };

  editRotation = sooner => async e => {
    e.stopPropagation();
    try {
      const {
        osceDayId,
        getOsceDays,
        getSequence,
        match: { params },
      } = this.props;
      // Move rotation to next day
      await moveRotation(osceDayId, { sooner });
      // Get new days data
      const osceDays = await getOsceDays('osceDays', params.id);
      const osceDaysData = osceDays.data.data;
      // For each new date get updated sequence data
      osceDaysData.forEach(day => {
        getSequence(`sequence${day.id}`, day.id);
      });
    } catch (err) {
      msgError(err);
    }
  };

  render() {
    const {
      translate,
      isLaterDisabled,
      isEarlieDisabled,
      isPreponeDisabled,
      isPostponeDisabled,
    } = this.props;
    return (
      <div className="osce-day-header_right">
        <div>
          <Tooltip placement="top" title={translate('earlierLunch')}>
            <Button
              disabled={this.isDisabled || isEarlieDisabled}
              onClick={this.editLunchBreak(true)}
            >
              <Icon type="up" />
            </Button>
          </Tooltip>
          <Tooltip placement="top" title={translate('preponeFirst')}>
            <Button
              disabled={this.isDisabled || isPreponeDisabled}
              onClick={this.editRotation(true)}
            >
              <Icon type="double-right" rotate={-90} />
            </Button>
          </Tooltip>
        </div>
        <div>
          <Tooltip placement="top" title={translate('laterLunch')}>
            <Button
              disabled={this.isDisabled || isLaterDisabled}
              onClick={this.editLunchBreak(false)}
            >
              <Icon type="down" />
            </Button>
          </Tooltip>
          <Tooltip placement="top" title={translate('postponeLast')}>
            <Button
              disabled={this.isDisabled || isPostponeDisabled}
              onClick={this.editRotation(false)}
            >
              <Icon type="double-left" rotate={-90} />
            </Button>
          </Tooltip>
        </div>
      </div>
    );
  }
}

export default HeaderButtons;
