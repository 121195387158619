import React, { PureComponent } from 'react';
import { withRouter } from 'react-router';
import UrlPattern from 'url-pattern';

import { history } from 'store';

export default patternStr => Child => {
  const pattern = new UrlPattern(patternStr);

  @withRouter
  class TabPattern extends PureComponent {
    get match() {
      return pattern.match(window.location.pathname);
    }

    onTabClick = tab => history.push(pattern.stringify({ ...this.match, tab }));

    render() {
      return (
        <Child
          pattern={pattern}
          onTabClick={this.onTabClick}
          {...this.match}
          {...this.props}
        />
      );
    }
  }
  return TabPattern;
};
