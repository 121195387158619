import React from 'react';
import { Redirect, Switch, Route } from 'react-router';

/** Constants */
import * as SIDEBARS from 'constants/sidebars';

/** Components **/
import MainLayout from 'components/MainLayout';
import RoleDefinitions from 'pages/roles/roleDefinitions/RoleDefinitions';
import Discipline from 'pages/roles/discipline/Discipline';
import Role from 'pages/roles/roleDefinitions/Role';

const CurrentView = ({ match }) => {
  const { url } = match;

  return (
    <Switch>
      <Redirect exact from={`${url}/roles`} to={`${url}/roles/definition`} />
      <Route path={`${url}/definition`} component={RoleDefinitions} />
      <Route path={`${url}/discipline`} component={Discipline} />
      <Route path={`${url}/role/:id`} component={Role} />
      <Route path={`${url}/checklist/:id`} component={Role} />
      <Route path={`${url}/target-system/:id`} component={Role} />
      <Route path={`${url}/past-user/:id`} component={Role} />
      <Route path={`${url}/criteria/:id`} component={Role} />
      <Route path={`${url}/osces/:id`} component={Role} />
    </Switch>
  );
};

const Examiners = props => {
  return (
    <MainLayout menu={SIDEBARS.ROLE_MENU} nameMenu="role_sidebar">
      <CurrentView {...props} />
    </MainLayout>
  );
};

export default Examiners;
