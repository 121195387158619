import React from 'react';
import { Switch, Route } from 'react-router';

/** Constants */
import * as SIDEBARS from 'constants/sidebars';

/** Components **/
import MainLayout from 'components/MainLayout';
import ExaminersList from 'pages/examiners/list';
import Clinics from 'pages/examiners/clinics';
import ClinicsProfile from 'pages/examiners/clinics/profile';

const CurrentView = ({ match }) => {
  const { url } = match;

  return (
    <Switch>
      <Route path={`${url}/list`} component={ExaminersList} />
      <Route exact path={`${url}/clinics`} component={Clinics} />
      <Route path={`${url}/clinics/:id`} component={ClinicsProfile} />
    </Switch>
  );
};

const Examiners = props => {
  return (
    <MainLayout menu={SIDEBARS.EXAMINERS_MENU} nameMenu="examiners_sidebar">
      <CurrentView {...props} />
    </MainLayout>
  );
};

export default Examiners;
