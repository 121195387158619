import React, { Component } from 'react';
import { Select, Spin } from 'antd';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { updateData, resetData } from 'actions/updateData';
import { semesterCRUD } from 'actions/cruds';
import addLoad from 'services/decorators/addLoad';
import tabPattern from 'services/decorators/tabPattern';

const mapStateToProps = ({ runtime }) => ({
  osce: runtime.osceData,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ dispatch, updateData, resetData }, dispatch);

@addLoad()
@tabPattern('/osce-administration/:semesterId(/:tab(/:othertab(/:id)))')
@connect(
  mapStateToProps,
  mapDispatchToProps,
)
export default class SemestersSidebar extends Component {
  state = {
    semesters: [],
    selected: null,
  };

  componentDidMount() {
    this.loadSemesters();
  }

  componentWillReceiveProps(nextProps) {
    const { semesterId } = nextProps;
    if (semesterId) {
      this.setState({ selected: +semesterId });
    }
  }

  componentWillUnmount() {
    this.props.resetData('semesterId');
  }

  loadSemesters = async () => {
    const { props } = this;
    const resp = await props.stateloading(semesterCRUD.getList());
    this.setState({ semesters: resp.data, selected: resp.data[0].id });
    if (get(props, 'semesterId')) {
      this.setState({ selected: +props.semesterId });
      return this.props.updateData(+props.semesterId, 'semesterId');
    }
    return this.props.updateData(resp.data[0].id, 'semesterId');
  };

  changeRoute = async value => {
    const urlStr = this.props.location.pathname;
    let url = urlStr.split('/');
    url.splice(2, 1, value);
    if (urlStr.indexOf('/osce/') !== -1) {
      url = url.slice(0, 4);
    }
    await this.props.updateData(value, 'semesterId');
    if (urlStr.indexOf('osce-settings') === -1) {
      return this.props.history.push(url.join('/'));
    }
    return false;
  };

  onChangeSemester = value => {
    this.setState({ selected: value });
    localStorage.setItem('semesterId', value);
    this.changeRoute(value);
  };

  get renderSelect() {
    if (this.state.selected)
      return (
        <Select
          showSearch
          value={this.state.selected}
          className="osce-semester-select"
          onChange={this.onChangeSemester}
          notFoundContent={this.props.loading ? <Spin size="small" /> : null}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          {this.state.semesters.map(item => (
            <Select.Option key={item.id} value={item.id}>
              {`${item.semesterType} ${item.calendarYear}`}
            </Select.Option>
          ))}
        </Select>
      );
    return null;
  }

  render() {
    return this.renderSelect;
  }
}
