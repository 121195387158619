import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { history } from 'store';
import { Form, Tabs } from 'antd';

import handleSubmit from 'services/form';
import { examinersCRUD } from 'actions/cruds';
import i18n from 'services/i18n';
import Footer from 'components/Form/modalFooter';
import 'components/Form/formsStyles.less';

import PersonalInfo from './modalTabs/personalInfo';
import Secretary from './modalTabs/secretary';

@i18n('cards')
@Form.create({})
export default class AddNewExaminer extends Component {
  static childContextTypes = {
    form: PropTypes.object,
    errorText: PropTypes.string,
    toggleError: PropTypes.func,
  };

  state = {
    errorText: '',
  };

  getChildContext() {
    return { form: this.props.form, errorText: this.state.errorText };
  }

  addNewClinicSubmit = async e => {
    try {
      const values = await handleSubmit(e, this.props.form);
      const { data } = await examinersCRUD.post(values);
      history.push(this.props.redirect(data.id));
      this.handleReset();
    } catch (error) {
      console.error(error);
      !this.state.errorText && this.toggleError('error.fillRequired');
    }
  };

  toggleError = errorText => {
    this.setState({ errorText });
  };

  handleReset = () => {
    this.props.form.resetFields();
    this.toggleError('');
    this.props.toggleWindow();
  };

  render() {
    const { translate } = this.props;
    return (
      <Form onSubmit={this.addNewClinicSubmit}>
        <Tabs defaultActiveKey="1" className="form-tabs">
          <Tabs.TabPane tab={translate('personalInformation')} key="1">
            <PersonalInfo />
          </Tabs.TabPane>
          <Tabs.TabPane tab={translate('secretary')} key="2">
            <Secretary />
          </Tabs.TabPane>
        </Tabs>
        <Footer
          submitText="form.save"
          cancelText="form.cancel"
          onCancel={this.handleReset}
        />
      </Form>
    );
  }
}
