export const ADMINISTRATION = [
  {
    menuHeader: 'baseData',
    elements: [{ url: '/administration/semesters', name: 'semesters' }],
  },
  {
    menuHeader: 'resources',
    elements: [{ url: '/administration/rooms', name: 'rooms' }],
  },
  {
    menuHeader: 'students',
    elements: [
      {
        url: '/administration/students-details',
        name: 'studentsDetails',
        patterns: ['/student-profile/:id'],
      },
    ],
  },
];

export const PATIENT_MENU = [
  {
    url: '/sp/patients',
    name: 'simulatedPatients',
    patterns: ['/sp/patient-profile/:id/:type'],
  },
  { url: '/sp/professions', name: 'professions' },
  { url: '/sp/questionnaire', name: 'questionnaire' },
];

export const EXAMINERS_MENU = [
  {
    url: '/examiners/list',
    name: 'examiners',
    patterns: ['/examiner-profile/:id'],
  },
  {
    url: '/examiners/clinics',
    name: 'clinics',
    patterns: ['/examiners/clinics/:id'],
  },
];

export const ROLE_MENU = [
  {
    url: '/roles/definition',
    name: 'roleDefinition',
    patterns: ['/roles/definition', '/roles/:type/:id'],
  },
  { url: '/roles/discipline', name: 'discipline' },
];

export const SYSTEM_ADMINISTRATION = [
  {
    menuHeader: 'OSCEManager',
    elements: [
      { url: '/system-administration/administrators', name: 'administrators' },
      { url: '/system-administration/activity-log', name: 'activityLog' },
      { url: '/system-administration/catalogs', name: 'catalogs' },
    ],
  },
];

const changeId = (path, semesterId) => {
  if (path.indexOf('semesterId') !== -1) {
    const url = path.split('/');
    url.splice(2, 1, semesterId);
    return url.join('/');
  }
  return path;
};

export const OSCE_ADMINISTRATION = [
  {
    menuHeader: 'osceData',
    elements: [
      {
        url: '/osce-administration/:semesterId/osce',
        name: 'osces',
        patterns: [
          '/osce-administration/:semesterId/osce',
          '/osce-administration/:semesterId/osce/basic-data/:id',
          '/osce-administration/:semesterId/osce/circuits-details/:id',
          '/osce-administration/:semesterId/osce/schedule/:id',
          '/osce-administration/:semesterId/osce/students-data/:id',
          '/osce-administration/:semesterId/osce/import-export/:id',
          '/osce-administration/:semesterId/osce/settings/:id',
          '/osce-administration/:semesterId/osce/results/:id',
          '/osce-administration/:semesterId/osce/bell-system/:id',
        ],
        changeId,
      },
      {
        url: '/osce-administration/:semesterId/sp-selection',
        name: 'spSelection',
        patterns: ['/osce-administration/:semesterId/sp-selection'],
        changeId,
      },
      {
        url: '/osce-administration/:semesterId/outputs',
        name: 'outputs',
        patterns: ['/osce-administration/:semesterId/outputs/:type'],
        changeId,
      },
      {
        url: '/osce-administration/:semesterId/cost-accounting',
        name: 'costAccounting',
        patterns: ['/osce-administration/:semesterId/cost-accounting'],
        changeId,
      },
      { url: '/osce-administration/osce-settings', name: 'osceSettings' },
    ],
  },
];
