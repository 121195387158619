import React, { Component } from 'react';
import { Form, Input, Spin } from 'antd';
import { set, invoke } from 'lodash';
import PropTypes from 'prop-types';
import i18n from 'services/i18n';
import EditableBtns from './FormEditableBtns';

import './formsStyles.less';

const FormItem = Form.Item;

@i18n('form')
export default class FormEditableDoubleLabel extends Component {
  static propTypes = {
    withoutLabel: PropTypes.bool, // component will be rendered without label
    title: PropTypes.string, // it will be translated by i18n form
    firstLabelName: PropTypes.string.isRequired, // it will be key on submit object, if !title name will be translated to
    secondLabelName: PropTypes.string.isRequired, // it will be key on submit object, if !title name will be translated to
    fullWidth: PropTypes.bool, // component will take all width
    disabled: PropTypes.bool, //  disable edit onClick (just label)
    className: PropTypes.string,
    required: PropTypes.bool, // form option, if your key is mandatory
    rules: PropTypes.array, // ant form rules
    deleted: PropTypes.bool, // sow deleted text after second label
    initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // ant form initialValue
  };

  static contextTypes = {
    form: PropTypes.object,
    getStatus: PropTypes.func.isRequired,
    setStatus: PropTypes.func.isRequired,
    submit: PropTypes.func,
    disabled: PropTypes.bool,
  };

  state = { editable: false, loading: false };

  constructor(props) {
    super(props);
    this.name = this.props.firstLabelName + this.props.secondLabelName;
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.state.editable && this.name !== nextContext.getStatus())
      this.resetStopEdit();
  }

  saveStartEdit = () => {
    if (this.props.disabled || this.context.disabled) return;
    this.setState({ editable: true });
    invoke(this, 'context.setStatus', this.name);
  };

  stopEdit = () => this.setState({ editable: false });

  resetStopEdit = () => {
    const { props } = this;
    const { setFieldsValue } = this.context.form;
    const newValue = {};
    set(newValue, props.firstLabelName, props.firstInit);
    set(newValue, props.secondLabelName, props.secondInit);
    setFieldsValue(newValue);
    this.stopEdit();
  };

  get btns() {
    if (this.props.disabled || this.context.disabled) return null;
    return (
      <EditableBtns
        editable={this.state.editable}
        saveStartEdit={this.saveStartEdit}
        resetStopEdit={this.resetStopEdit}
      />
    );
  }

  getFormItem = (name, initialValue, title) => {
    const { props } = this;
    const { form } = this.context;
    return (
      <FormItem colon={false} className="card-double-margin">
        {form.getFieldDecorator(name, {
          rules: props.rules,
          initialValue: initialValue,
        })(
          this.state.editable ? (
            <Input
              placeholder={this.props.translate(title || name)}
              className="card-name-input card-double-margin"
            />
          ) : (
            <span onClick={this.saveStartEdit} className="card-static-name">
              {initialValue}
            </span>
          ),
        )}
      </FormItem>
    );
  };

  get isDeleted() {
    if (this.props.deleted) {
      return (
        <React.Fragment>
          <span className="deleted deleted-circle" />
          <span className="deleted deleted-text">
            {this.props.translate('deleted', 'patient')}
          </span>
        </React.Fragment>
      );
    }
    return null;
  }

  render() {
    return (
      <div className="card-username">
        <Spin spinning={this.state.loading}>
          {this.getFormItem(this.props.firstLabelName, this.props.firstInit)}
          {this.getFormItem(this.props.secondLabelName, this.props.secondInit)}
          {this.isDeleted}
          {this.btns}
        </Spin>
      </div>
    );
  }
}
