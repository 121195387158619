import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get, invoke } from 'lodash';
import { Input, Button, Modal, Form } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import i18n from 'services/i18n';

import { updateData } from 'actions/updateData';
import { msgLocalError } from 'services/errorHandler';
import handleSubmit from 'services/form';

const FormItem = Form.Item;

const mapStateToProps = ({ runtime }, props) => ({
  list: get(runtime, `${props.name}Data`),
});

const mapDispatchToProps = (dispatch, props) =>
  bindActionCreators({ updateData }, dispatch);

@i18n('form')
@connect(
  mapStateToProps,
  mapDispatchToProps,
)
@Form.create({})
export default class AddItem extends Component {
  static propTypes = {
    name: PropTypes.string,
    action: PropTypes.object,
    addBtn: PropTypes.string,
    inputName: PropTypes.string,
  };

  state = { modal: false };

  componentDidUpdate(prevState) {
    if (!prevState.modal && this.state.modal) {
      setTimeout(this.focus, 100);
    }
  }

  saveInput = ref => ref && (this.input = ref);

  focus = () => invoke(this.input, 'focus');

  close = () => {
    this.props.form.resetFields({});
    this.setState({ modal: false });
  };

  onCreate = async e => {
    const { form, list, action, name } = this.props;
    const values = await handleSubmit(e, form);
    try {
      const resp = await action.post(values);
      resp.data.key = list.data.length
        ? list.data[list.data.length - 1].key + 1
        : 1;
      const newData = [...list.data, resp.data];
      this.props.updateData(
        { data: newData, pagination: list.pagination },
        name,
      );
      this.close();
    } catch (error) {
      msgLocalError({ error, translate: this.props.translate });
    }
  };

  get getFooter() {
    return (
      <div>
        <Button onClick={this.close}>{this.props.translate('cancel')}</Button>
        <Button
          type="primary"
          htmlType="submit"
          onClick={this.onCreate}
          form="addItemForm"
        >
          OK
        </Button>
      </div>
    );
  }

  render() {
    const { props } = this;
    const { getFieldDecorator } = props.form;
    return (
      <React.Fragment>
        <Button
          icon="plus"
          className="blueBtn table-panel_btn"
          onClick={() => this.setState({ modal: true })}
        >
          {this.props.addBtn}
        </Button>
        <Modal
          destroyOnClose
          visible={this.state.modal}
          onCancel={this.close}
          title={this.props.addBtn}
          footer={this.getFooter}
        >
          <Form layout="vertical" id="addItemForm">
            <FormItem label={this.props.inputName} colon={false}>
              {getFieldDecorator('name', {
                rules: [
                  {
                    required: true,
                    message: props.translate('insert'),
                  },
                ],
              })(<Input ref={this.saveInput} />)}
            </FormItem>
          </Form>
        </Modal>
      </React.Fragment>
    );
  }
}
