import React from 'react';
import { Switch, Route } from 'react-router';

/** Constants */
import * as SIDEBARS from 'constants/sidebars';

/** Components **/
import MainLayout from 'components/MainLayout';
import Catalogs from 'pages/systemAdministration/Catalogs';
import Administrators from 'pages/systemAdministration/Administrators';
import ActivityLog from 'pages/systemAdministration/ActivityLog';

const CurrentView = ({ match }) => {
  const { url } = match;

  return (
    <Switch>
      <Route path={`${url}/administrators`} component={Administrators} />
      <Route path={`${url}/activity-log`} component={ActivityLog} />
      <Route path={`${url}/catalogs`} component={Catalogs} />
    </Switch>
  );
};

const SystemAdministration = props => {
  return (
    <MainLayout
      multipleMenus={SIDEBARS.SYSTEM_ADMINISTRATION}
      nameMenu="systemAdministration"
    >
      <CurrentView {...props} />
    </MainLayout>
  );
};

export default SystemAdministration;
