import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Draggable } from 'react-beautiful-dnd';
import { Form, Icon, Row, Col, Select, Input, InputNumber } from 'antd';
// Services
import i18n from 'services/i18n';
import { classToFunc } from 'services/decorators/DOM';
// Components
import { Flex, Box } from 'components/Styled';

@classToFunc
@i18n('form')
export default class Option extends PureComponent {
  static propTypes = {
    option: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    onRemove: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
  };

  state = { opencomment: false };

  componentDidMount() {
    if (this.props.option.comment) {
      this.setState({ opencomment: true });
    }
  }

  toggleComment = () => {
    this.setState({ opencomment: !this.state.opencomment });
  };

  render() {
    const { option } = this.props;

    return (
      <Draggable draggableId={option.id.toString()} index={this.props.index}>
        {(optProvider, optSnap) => (
          <React.Fragment>
            <div
              ref={optProvider.innerRef}
              {...optProvider.draggableProps}
              {...optProvider.dragHandleProps}
              tabIndex={null}
              className={cn([
                'modal-draggable-option',
                {
                  'modal-options-isDragging': optSnap.isDragging,
                },
              ])}
            >
              <Flex alignItems="center" className="spacer">
                <Flex className="modal-d-n-d-item spacer" alignItems="center">
                  <span className="icon-drag" />
                  <Box className="spacer">
                    <Row gutter={14}>
                      <Col span={12}>
                        <Form.Item
                          validateStatus={
                            option.textDescription === '' ? 'error' : ''
                          }
                          help={
                            option.textDescription === ''
                              ? this.props.translate('insert', 'error')
                              : ''
                          }
                        >
                          <Input
                            placeholder={this.props.translate('option')}
                            value={option.textDescription}
                            onChange={e =>
                              this.props.onChange(
                                'textDescription',
                                e.target.value,
                                this.props.index,
                              )
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={5}>
                        <Form.Item>
                          <InputNumber
                            placeholder={this.props.translate('value')}
                            value={option.values}
                            min={0}
                            onChange={value =>
                              this.props.onChange(
                                'values',
                                value,
                                this.props.index,
                              )
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={5}>
                        <Form.Item>
                          <InputNumber
                            placeholder={this.props.translate('count')}
                            value={option.pointCount}
                            min={0}
                            onChange={value =>
                              this.props.onChange(
                                'pointCount',
                                value,
                                this.props.index,
                              )
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Icon
                          type="down"
                          className={cn([
                            'comment-icon',
                            { open: this.state.opencomment },
                          ])}
                          onClick={this.toggleComment}
                        />
                      </Col>
                    </Row>
                    {this.state.opencomment ? (
                      <Box mt="5px" mr="37px">
                        <Form.Item colon required>
                          <Input
                            placeholder={this.props.translate('comment')}
                            value={option.comment}
                            onChange={e =>
                              this.props.onChange(
                                'comment',
                                e.target.value,
                                this.props.index,
                              )
                            }
                          />
                        </Form.Item>
                      </Box>
                    ) : null}
                  </Box>
                </Flex>
                <Flex ml={3}>
                  <Icon
                    type="delete"
                    className="action-btn-single"
                    onClick={() => this.props.onRemove(this.props.index)}
                  />
                </Flex>
              </Flex>
            </div>
            {optProvider.placeholder}
          </React.Fragment>
        )}
      </Draggable>
    );
  }
}
