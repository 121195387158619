import React, { Component } from 'react';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';

import i18n from 'services/i18n';
import { GENDER } from 'constants/options';
import FormItemInput from 'components/Form/formInput';
import EditableLabelDropDown from 'components/Form/formEditableLabelDropDown';
import ErrorItem from 'components/Form/formError';

import { phoneNumber } from 'services/validations';

import 'components/Form/formsStyles.less';

@i18n('form')
export default class Secretary extends Component {
  static contextTypes = {
    submit: PropTypes.func,
    toggleError: PropTypes.func,
    errorText: PropTypes.string,
  };

  render() {
    const { translate } = this.props;
    return (
      <div className="form_content">
        <Row gutter={20}>
          <Col span={12}>
            <FormItemInput
              name="secretary.firstName"
              label={translate('firstName')}
              focus
            />
          </Col>
          <Col span={12}>
            <FormItemInput
              name="secretary.lastName"
              placeholder="lastName"
              label={translate('lastName')}
            />
          </Col>
        </Row>
        <EditableLabelDropDown
          name="secretary.gender"
          options={GENDER}
          label={translate('gender')}
          placeholder={translate('selectGender', 'placeholder')}
          onlyDropDown
          fullWidth
          withoutLabel
        />
        <FormItemInput
          name="secretary.email"
          type="email"
          label={translate('email')}
        />
        <FormItemInput
          name="secretary.telephone"
          label={translate('telephone')}
          rules={[
            {
              validator: phoneNumber,
              message: translate('notValidPhoneNumber', 'error'),
            },
          ]}
        />
        <ErrorItem
          toggleError={this.context.toggleError}
          errorText={this.context.errorText}
        />
      </div>
    );
  }
}
