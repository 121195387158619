import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Table, Checkbox, Tooltip, Icon } from 'antd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import cn from 'classnames';
// Services
import i18n from 'services/i18n';
import { momentFormatDateMarks } from 'services/format';

@i18n('spSelection')
export default class Osce extends PureComponent {
  static propTypes = {
    selectedRole: PropTypes.object,
    osceDays: PropTypes.array.isRequired,
    onRemoveSp: PropTypes.func.isRequired,
    onSelectRole: PropTypes.func.isRequired,
    onSelectOSCEDay: PropTypes.func.isRequired,
    onAssignSpForHalfDay: PropTypes.func.isRequired,
  };

  static defaultProps = {
    selectedRole: { id: null, acronym: '' },
  };

  constructor(props) {
    super();
    this.columns = [
      {
        key: '0',
        title: 'OSCE',
        width: '65%',
        render: record =>
          `${record.osceName} - ${
            record.osceDayDate
              ? momentFormatDateMarks(record.osceDayDate)
              : `Day ${record.osceDayName}`
          }`,
      },
      {
        key: '1',
        width: '35%',
        render: record => (
          <Checkbox
            onClick={e => e.stopPropagation()}
            onChange={e => this.props.onAssignSpForHalfDay(record.id, e)}
            checked={record.assignSpForHalfDay}
            value={record.assignSpForHalfDay}
          >
            {props.translate('assignSPsforHalfDays')}
          </Checkbox>
        ),
      },
    ];
  }

  renderRoles = role => {
    // If value 0 no sign
    let spSign = role.neededSp;

    // If 2 sp's are missing for ex. "-2"
    if (role.neededSp > 0) {
      spSign = `-${role.neededSp}`;
      // If too much sp's added for ex. "+3"
    } else if (role.neededSp < 0) {
      spSign = `+${Math.abs(role.neededSp)}`;
    }

    return (
      <Droppable
        key={role.id + role.postId}
        droppableId={`${role.id}#${role.postId}#${
          role.roleInformationResponse.acronym
        }`} // the way to provide extra data to onDrag fn
      >
        {provided => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={cn('osces-table_post', {
              selected:
                this.props.selectedRole.id === role.id &&
                this.props.selectedRole.postId === role.postId,
            })}
          >
            <div
              className="osces-table_post_header"
              onClick={() =>
                this.props.onSelectRole(
                  role.id,
                  role.postId,
                  role.roleInformationResponse.acronym,
                )
              }
            >
              {`${role.roleInformationResponse.acronym} ${
                role.roleInformationResponse.roleType
              }`}
              <span
                className={cn('osces-table_post_extra', {
                  red: role.neededSp > 0 || role.neededSp < 0,
                })}
              >
                {spSign}
              </span>
            </div>
            <div className="osces-table_post_body">
              {role.spSelectionResponseList &&
                role.spSelectionResponseList.map((patient, idx) => (
                  <Draggable
                    key={patient.id}
                    draggableId={patient.id}
                    index={idx}
                  >
                    {provided => (
                      <span
                        ref={provided.innerRef}
                        {...provided.dragHandleProps}
                        {...provided.draggableProps}
                        className={cn('osces-table_post_patient', {
                          red: !patient.isFitsAllRoleCriteria,
                        })}
                      >
                        {patient.firstName} {patient.lastName}
                        <span
                          className="osces-table_post_remove"
                          onClick={() =>
                            this.props.onRemoveSp(
                              role.id,
                              patient.id,
                              role.postId,
                            )
                          }
                        >
                          <Icon type="close" />
                        </span>
                      </span>
                    )}
                  </Draggable>
                ))}
            </div>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    );
  };

  onDragEnd = result => {
    const { destination, source, draggableId } = result;
    // Was dropped outside the droppable list
    if (!destination) return;
    // Was dropped inside the same list
    if (destination.droppableId === source.droppableId) return;
    // Previous position
    const [prevIdRole, prevPostId] = source.droppableId.split('#');
    // Next position
    const [idRole, postId, acronym] = destination.droppableId.split('#');

    // Create an object with data for the parent
    // ensure that all corresponding ids are numbers
    const data = {
      idRole: Number(idRole),
      postId: Number(postId),
      acronym,
      idSelectedSp: draggableId,
      prevIdRole: Number(prevIdRole),
      prevPostId: Number(prevPostId),
    };

    this.props.onDragSp(data);
  };

  renderSequences = (item, index) => (
    <div className="osces-table_sequence" key={index}>
      <div className="osces-table_sequence_name">{item.label}</div>
      <DragDropContext onDragEnd={this.onDragEnd}>
        <div className="osces-table_posts">
          {item.roleResponses.map(this.renderRoles)}
        </div>
      </DragDropContext>
    </div>
  );

  render() {
    const dataSource = this.props.osceDays.map(osce => ({
      ...osce,
      key: osce.id,
    }));

    return (
      <Tooltip
        placement="top"
        title={this.props.translate('spSelectionOsce', 'tooltips')}
      >
        <div className="table-with-action osces-table">
          <Table
            bordered
            pagination={false}
            expandRowByClick
            locale={{ emptyText: 'No OSCEs' }}
            dataSource={dataSource}
            columns={this.columns}
            expandedRowRender={record =>
              record.sequenceResponseList.map(this.renderSequences)
            }
            onRow={osce => {
              return {
                onClick: e => this.props.onSelectOSCEDay(osce),
              };
            }}
            expandIcon={props => {
              let icon;
              if (props.expanded) {
                icon = 'caret-down';
              } else {
                icon = 'caret-right';
              }
              return <Icon type={icon} />;
            }}
          />
        </div>
      </Tooltip>
    );
  }
}
