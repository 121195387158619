import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Spin } from 'antd';
// Actions
import { getAllCatalogs } from 'actions/role';
// Components
import Catalog from './Catalog';
// Styles
import './style.less';

const mapStateToProps = state => ({
  targetSystemResponses: state.role.info.targetSystemResponses,
  catalogs: state.role.catalogs,
  loading: state.role.loading,
});

const mapDispatchToProps = {
  getAllCatalogs,
};

@connect(
  mapStateToProps,
  mapDispatchToProps,
)
export default class TargetSystem extends Component {
  static propTypes = {
    targetSystemResponses: PropTypes.array.isRequired,
    catalogs: PropTypes.array.isRequired,
    loading: PropTypes.bool,
  };

  static defaultProps = {
    targetSystemResponses: [],
    loading: false,
  };

  componentDidMount() {
    this.props.getAllCatalogs();
  }

  render() {
    return (
      <Spin spinning={this.props.loading}>
        <div className="catalogs-container">
          {this.props.catalogs.map(catalog => (
            <Catalog
              catalog={catalog}
              targets={this.props.targetSystemResponses.filter(
                ts => ts.nameCatalog === catalog.name,
              )}
              key={catalog.id}
            />
          ))}
        </div>
      </Spin>
    );
  }
}
