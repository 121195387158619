import React, { Component } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { Icon } from 'antd';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
// Services
import i18n from 'services/i18n';
// Components
import Criteria from './Criteria';

@i18n('role')
class Criterias extends Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
  };

  handleAddNewCriteria = () => {
    const { getFieldValue, setFieldsValue } = this.props.form;
    const oldCriteriaRequestList = getFieldValue('criteriaRequestList');
    const numberInQueue = oldCriteriaRequestList.length;
    const id = Date.now(); //generate unique id

    const newCriteriaRequestList = oldCriteriaRequestList.concat([
      { id, text: '', comment: '', numberInQueue },
    ]);

    setFieldsValue({ criteriaRequestList: newCriteriaRequestList });
  };

  handleChangeCriteria = (e, index) => {
    const { getFieldValue, setFieldsValue } = this.props.form;
    const { name, value } = e.target;
    // Old array list
    const oldCriteriaRequestList = getFieldValue('criteriaRequestList');
    // Create new array list
    const newCriteriaRequestList = [...oldCriteriaRequestList];
    // Set value
    newCriteriaRequestList[index][name] = value;
    // Actually update state
    setFieldsValue({ criteriaRequestList: newCriteriaRequestList });
  };

  handleRemoveCriteria = index => {
    const { getFieldValue, setFieldsValue } = this.props.form;
    // Old array list
    const oldCriteriaRequestList = getFieldValue('criteriaRequestList');
    // Create new array list
    const newCriteriaRequestList = oldCriteriaRequestList
      .filter((_, i) => i !== index)
      .map((criteria, index) => ({ ...criteria, numberInQueue: index }));
    // Actually update state
    setFieldsValue({ criteriaRequestList: newCriteriaRequestList });
  };

  onDragEnd = result => {
    const { destination, source } = result;
    // If user drop item outside of droppable list
    if (!destination) {
      return;
    }

    // If item position didn't change
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    // Move items
    const { getFieldValue, setFieldsValue } = this.props.form;
    // Old array list
    const oldCriteriaRequestList = getFieldValue('criteriaRequestList');
    // Create new array list
    const newCriteriaRequestList = [...oldCriteriaRequestList];
    // Get fields objects that was moved
    const sourceField = newCriteriaRequestList[source.index];
    const destField = newCriteriaRequestList[destination.index];
    sourceField.numberInQueue = destination.index;
    destField.numberInQueue = source.index;
    // Remove field object from old position
    newCriteriaRequestList.splice(source.index, 1);
    // Add field object to new position
    newCriteriaRequestList.splice(destination.index, 0, sourceField);

    // Actually update state
    setFieldsValue({ criteriaRequestList: newCriteriaRequestList });
  };

  render() {
    const { getFieldValue } = this.props.form;
    const criteriaRequestList = getFieldValue('criteriaRequestList');

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {(dropProvid, dropSnap) => (
            <div
              ref={dropProvid.innerRef}
              className={cn([
                'modal-draggable-container',
                {
                  'modal-draggable-isDragging': dropSnap.isDraggingOver,
                },
              ])}
            >
              {criteriaRequestList.map((criteria, index) => (
                <Criteria
                  key={criteria.id}
                  index={index}
                  criteria={criteria}
                  form={this.props.form}
                  onChange={this.handleChangeCriteria}
                  onRemove={this.handleRemoveCriteria}
                />
              ))}
              <span className="action-btn" onClick={this.handleAddNewCriteria}>
                <Icon type="plus" /> {this.props.translate('addCriteria')}
              </span>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

export default Criterias;
