import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Modal } from 'antd';
import { withRouter } from 'react-router';
/* Services */
import api from 'services/api';
import formDecorate from 'services/decorators/formDecorate';
/* Components */
import LazySelect from 'components/LazySelect';

@withRouter
@formDecorate()
export default class ModalManually extends PureComponent {
  static propTypes = {
    onAddSP: PropTypes.func.isRequired,
    onAddAllSPs: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
  };

  get footer() {
    const { props } = this;
    return (
      <div>
        <Button icon="plus" className="btn-left" onClick={this.handleAddAllSPs}>
          {props.translate('addAllSP')}
        </Button>
        <Button onClick={this.handleClose}>{props.translate('cancel')}</Button>
        <Button
          disabled={props.loading}
          type="primary"
          htmlType="submit"
          onClick={this.handleSubmit}
          form="addItem"
        >
          {props.translate('add')}
        </Button>
      </div>
    );
  }

  handleAddAllSPs = () => {
    this.props.onAddAllSPs();
    this.handleClose();
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onAddSP(values);
        this.handleClose();
      }
    });
  };

  handleClose = () => {
    this.props.form.resetFields();
    this.props.close();
  };

  handleLoadSPs = data =>
    api.getNotInvitedSPs(this.props.match.params.semesterId, data).then(res => {
      const data = res.data.data.map(sp => ({
        id: sp.id,
        name: `${sp.firstName} ${sp.lastName}`,
      }));
      return { data };
    });

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Modal
        destroyOnClose
        title={this.props.translate('addManually')}
        visible={this.props.visible}
        onCancel={this.handleClose}
        footer={this.footer}
      >
        <Form.Item label={this.props.translate('simulatedPatient')}>
          {getFieldDecorator('spId', {
            rules: [
              {
                required: true,
                message: this.props.translate('chooseOptions'),
              },
            ],
          })(
            <LazySelect
              dropdownMatchSelectWidth
              loadData={this.handleLoadSPs}
            />,
          )}
        </Form.Item>
      </Modal>
    );
  }
}
