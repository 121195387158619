import React from 'react';
import { Button, DatePicker, Table } from 'antd';
import { get } from 'lodash';

/** Components */
import TablePanel from 'components/TablePanel';

/** Constants */
import { DATE_SEND_WITH_TIME } from 'constants/formats';
import { DEFAULT_PROPS_TABLE, DEFAULT_LIST_SIZE } from 'constants/common';

/** Actions */
import { activityLogCRUD } from 'actions/cruds';

/** Services */
import i18nTable from 'services/i18nTable';
import addLoadWithRedux from 'services/decorators/addLoadWithRedux';
import stateSend from 'services/stateSend';

@i18nTable('activityLogColumns')
@addLoadWithRedux({
  i18Name: 'form',
  runtimeReduxNames: ['activityLog'],
  mapMethods: { getList: activityLogCRUD.getList },
})
export default class ActivityLog extends React.PureComponent {
  state = {
    startDate: null,
    endDate: null,
    loading: false,
  };

  componentDidMount() {
    this.load();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (!this.state.startDate && this.state.startDate !== prevState.startDate) ||
      (!this.state.endDate && this.state.endDate !== prevState.endDate)
    ) {
      this.load();
    }
  }

  get dateParams() {
    let queryParams = '';
    const { startDate, endDate } = this.state;
    let isAskAdded = false;
    if (startDate) {
      queryParams += `?startTime=${startDate.format(DATE_SEND_WITH_TIME)}`;
      isAskAdded = true;
    }
    if (endDate) {
      queryParams += `${isAskAdded ? '&' : '?'}endTime=${endDate.format(
        DATE_SEND_WITH_TIME,
      )}`;
    }
    return queryParams;
  }

  onDateSelect = name => value => this.setState({ [name]: value });

  load = async (params = {}) => {
    stateSend(
      loading => this.setState({ loading }),
      this.props.getList('activityLog', {
        additionalUrl: `${this.dateParams}`,
        params,
      }),
    );
  };

  onChange = pagination => {
    this.load({ page: pagination.current - 1 });
  };

  render() {
    const { activityLogData } = this.props;
    return (
      <React.Fragment>
        <TablePanel>
          <DatePicker
            showTime
            format={DATE_SEND_WITH_TIME}
            onChange={this.onDateSelect('startDate')}
            value={this.state.startDate}
            placeholder="Start date"
          />
          <span className="role_date-picker-separator" />
          <DatePicker
            showTime
            format={DATE_SEND_WITH_TIME}
            onChange={this.onDateSelect('endDate')}
            value={this.state.endDate}
            placeholder="End date"
          />
          <Button
            icon="search"
            className="blueBtn role_search-btn"
            onClick={() => this.load()}
          >
            {this.props.translate('search')}
          </Button>
        </TablePanel>
        <Table
          {...DEFAULT_PROPS_TABLE}
          loading={this.state.loading}
          pagination={{
            ...get(activityLogData, 'pagination'),
            pageSize: DEFAULT_LIST_SIZE,
            current: get(activityLogData, 'pagination.page') + 1,
          }}
          onChange={this.onChange}
          columns={this.props.activityLogColumns}
          dataSource={get(activityLogData, 'data') || []}
        />
      </React.Fragment>
    );
  }
}
