import React, { Component } from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import { Table } from 'antd';
import { pick, get, find, invoke, cloneDeep, omit } from 'lodash';
import cn from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import dispatchSend from 'services/dispatchSend';
import stateSend from 'services/stateSend';
import { updateData, resetData } from 'actions/updateData';
import i18n from 'services/i18n';

import { DEFAULT_LIST_SIZE, DEFAULT_PROPS_TABLE } from 'constants/common';

import ExpandIcon from './ExpandIcon';
import ButtonsWithAction from './ButtonsWithAction';
import actionOfTable, {
  checkCacheData,
  EditableCell,
  handleChange,
} from './ActionOfTable';

import './table.less';

const btnActions = ['edit', 'save', 'cancel', 'remove'];

/**
 * MainTable Reusable table component
 * @reactProps {func} translate - Function for translate
 * @reactProps {string} name - Redux name for table
 * @reactProps {stging} customName - custom get name from redux
 * @reactProps {object} action - object of actions
 * @reactProps {object} sendParams - Send params object
 * @reactProps {object} parentAction -
 * @reactProps {array} columns - Columns of tables
 * @reactProps {bool} noLoad - Should i before Render
 * @reactProps {array} dataSource -
 * @reactProps {number} parentId - Id of parent element
 * @reactProps {number} parntId - Id of parent element
 * @reactProps {func} editAction - On edit action
 * @reactProps {bool} notResetData - Reset data on unmount
 * @reactProps {func} clearOnChangeAdditionalUrl - Reset data on unmount
 * @reactProps {bool}  hasActionBtns - Should component has action buttons
 * @reactProps {bool}  withoutScroll - Should component has scroll
 * @reactProps {func}  additionalBtns - Array of addinationals
 * @reactProps {number}  widthActionBtns - Array of addinationals
 * @reactProps {string}  expandedAllRows - Allows extend row
 * @reactProps {string}  filter - path into redux for filters
 * @reactProps {bool}  disabledActions - disabled s

 */

const mapStateToProps = ({ runtime }, props) => ({
  list: get(runtime, props.customName || `${props.name}Data`),
  [props.additionalParamName]: get(
    runtime,
    props.customName || `${props.name}Data.${props.additionalParamName}`,
  ),
  data: get(runtime, `${props.name}Data.data`),
  loading: get(runtime, `${props.name}Loading`),
  filterList: get(runtime, props.filter),
});

const mapDispatchToProps = (dispatch, props) =>
  bindActionCreators(
    { dispatchSend, updateData, resetData, dispatch },
    dispatch,
  );

@i18n('error')
@connect(
  mapStateToProps,
  mapDispatchToProps,
)
export default class MainTable extends Component {
  static propTypes = {
    name: PropTypes.string,
    customName: PropTypes.string, // For use without dispatchSend
    action: PropTypes.object,
    sendParams: PropTypes.object,
    parentAction: PropTypes.object,
    columns: PropTypes.array.isRequired,
    noLoad: PropTypes.bool,
    parentId: PropTypes.number,
    dataSource: PropTypes.array,
    editAction: PropTypes.func,
    notResetData: PropTypes.bool,
    clearOnChangeAdditionalUrl: PropTypes.bool,
    hasActionBtns: PropTypes.bool,
    withoutScroll: PropTypes.bool,
    additionalBtns: PropTypes.func,
    widthActionBtns: PropTypes.number,
    expandedAllRows: PropTypes.bool,
    additionalUrl: PropTypes.string,
    filter: PropTypes.string, // Redux path
    disabledActions: PropTypes.bool,
    list: PropTypes.object,
    locale: PropTypes.object,
  };

  static defaultProps = {
    noLoad: false,
    list: { data: [], pagination: {} },
    widthActionBtns: 300,
    clearOnChangeAdditionalUrl: false,
  };

  constructor(props) {
    super(props);
    this.key = 0;
    if (props.hasActionBtns) {
      this.createActionBtns();
    } else {
      this.columns = props.columns;
    }
    this.sort = React.createRef();
  }

  state = { loading: false, hasError: false, clearOnChange: false };

  get name() {
    return this.props.name;
  }

  componentWillMount() {
    const { props } = this;
    if (!props.withoutScroll) {
      window.addEventListener('scroll', this.onTableScroll, true);
    }
    if (!props.noLoad) {
      this.loadList();
    }
  }

  componentWillUnmount() {
    const { props } = this;
    if (!props.withoutScroll) {
      window.removeEventListener('scroll', this.onTableScroll, true);
    }
    if (!props.notResetData) {
      props.resetData(this.name);
    }
  }

  componentDidCatch(err) {
    console.error(err);
    this.setState({ hasError: true });
  }

  componentWillUpdate(nextProps, nextState, nextContext) {
    checkCacheData(this, nextProps);
  }

  componentDidUpdate(prevProps) {
    const { props } = this;
    if (props.additionalUrl !== prevProps.additionalUrl) {
      if (this.props.clearOnChangeAdditionalUrl) {
        this.setState({ clearOnChange: true });
      }
      this.loadList();
    }
  }

  get expandedRowKeys() {
    const { props } = this;
    const opt = {};
    if (props.expandedAllRows) {
      opt.expandedRowKeys = invoke(props.list, 'data.map', item => item.key);
    }
    return opt;
  }

  keyPress = (e, record, actions) => {
    if (e.keyCode === 27) {
      actions.cancel(record);
    } else if (e.keyCode === 13) {
      actions.save(record);
    }
  };

  createActionBtns = () => {
    const actions = {};
    const { props } = this;
    btnActions.forEach(name => {
      actions[name] = props[`${name}Click`] || actionOfTable[name].bind(this);
    });
    this.handleChange = handleChange.bind(this);
    this.columns = props.columns.map(item => ({
      ...item,
      render: (text, record) => (
        <EditableCell
          editable={record.editable}
          value={typeof text === 'object' && !Array.isArray(text) ? '' : text}
          localizeFrom={item.localizeFrom}
          component={item.component}
          options={item.options}
          onChange={value =>
            this.handleChange(value, record.key, item.dataIndex)
          }
          onKey={e => this.keyPress(e, record, actions)}
        />
      ),
    }));
    this.columns.push({
      title: '',
      dataIndex: '',
      width: props.widthActionBtns,
      render: (text, record) => (
        <ButtonsWithAction
          record={record}
          actions={actions}
          disabled={props.disabledActions}
        >
          {invoke(props, 'additionalBtns', record)}
        </ButtonsWithAction>
      ),
    });
  };

  loadAction = expression => (expression ? 'POST' : 'GET');

  get defaultParamsSerializer() {
    return par => qs.stringify(par, { arrayFormat: 'repeat' });
  }

  loadList = async params => {
    const { props } = this;
    const { list } = props;
    const search = get(list, 'search');
    const filter = this.props.filterList;
    const sort = this.sort.current;
    let newData = [];
    let pagination = {};
    let paramsForSend = params;
    if (search) {
      paramsForSend.search = search;
    }
    if (props.sendParams) {
      paramsForSend = { ...paramsForSend, ...props.sendParams };
    }
    if (sort) {
      paramsForSend = { ...paramsForSend, ...sort };
    }
    const paramsSerializer =
      props.paramsSerializer || this.defaultParamsSerializer;
    const resp = await stateSend(
      loading => this.setState({ loading }),
      props.action.getList(
        {
          params: paramsForSend,
          additionalUrl: props.additionalUrl,
          paramsSerializer: paramsSerializer,
          method: this.loadAction(this.props.filter || filter),
        },
        { criteria: filter },
      ),
      { translate: this.props.translate },
    );
    resp.data.forEach(item => (item.key = this.key++));
    if (props.parentId) {
      newData = [...list.data];
      const item = find(newData, { id: props.parentId });
      item.kids = resp.data;
      pagination = list.pagination; /* eslint-disable-line */
    } else {
      if (this.state.clearOnChange) {
        newData = resp.data;
        this.setState({ clearOnChange: false });
      } else {
        newData = list.data.concat(resp.data);
      }
      pagination = resp.pagination; /* eslint-disable-line */
    }
    props.updateData({ data: newData, pagination, search, sort }, this.name);
  };

  onTableScroll = () => {
    const { body } = document;
    if (
      !this.state.loading &&
      this.canLoadMore &&
      window.scrollY > body.scrollHeight - body.offsetHeight - 30
    ) {
      this.loadList({ page: this.props.list.pagination.page + 1 });
    }
  };

  get canLoadMore() {
    const pagination = get(this.props, 'list.pagination') || {};
    return (
      !!pagination.pageSize &&
      (pagination.page + 1) * DEFAULT_LIST_SIZE < pagination.total
    );
  }

  onChange = async (pagination, filters, sorter) => {
    let key = 0;
    if (sorter.order) {
      this.sort.current = {
        sortProp: sorter.column.sorterName || sorter.field,
        sortDirection: sorter.order === 'ascend' ? 'DESC' : 'ASC',
      };
    } else {
      this.sort.current = null;
    }
    const { props } = this;
    const params = omit(this.props.list, ['data', 'pagination']);
    const filter = props.filterList;
    let paramsForSend = Object.assign(params, cloneDeep(props.params));
    if (this.sort.current) {
      paramsForSend = { ...paramsForSend, ...this.sort.current };
    }
    if (props.sendParams) {
      paramsForSend = { ...paramsForSend, ...props.sendParams };
    }
    if (props[props.additionalParamName]) {
      paramsForSend[props.additionalParamName] =
        props[props.additionalParamName];
    }
    const paramsSerializer =
      props.paramsSerializer || this.defaultParamsSerializer;
    const resp = await stateSend(
      loading => this.setState({ loading }),
      this.props.action.getList(
        {
          params: paramsForSend,
          additionalUrl: props.additionalUrl,
          method: this.loadAction(this.props.filter || filter),
          paramsSerializer,
        },
        { criteria: filter },
      ),
      { translate: props.translate },
    );
    resp.data.forEach(item => (item.key = key++));
    props.updateData(
      {
        ...resp,
        ...paramsForSend,
        filter,
        ...props[props.additionalParamName],
      },
      this.name,
    );
  };

  render() {
    const { state, props } = this;
    if (state.hasError) {
      return <h4>Error</h4>;
    }
    const loading = state.loading || props.loading;
    return (
      <div className={cn(props.hasActionBtns ? 'table-with-action' : 'table')}>
        <Table
          {...DEFAULT_PROPS_TABLE}
          {...pick(props, [
            'onRow',
            'className',
            'showHeader',
            'expandRowByClick',
            'expandedRowRender',
            'expandIconAsCell',
          ])}
          locale={props.locale}
          {...this.expandedRowKeys}
          expandIcon={props.expandedRowRender ? ExpandIcon : null}
          onChange={this.onChange}
          columns={this.columns}
          loading={loading}
          dataSource={this.props.dataSource || get(props.list, 'data')}
        />
      </div>
    );
  }
}
