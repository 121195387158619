import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Select, message } from 'antd';
import { get } from 'lodash';
// Services
import i18n from 'services/i18n';
import api from 'services/api';
// Components
import LazySelect from 'components/LazySelect';
// Options
import { NUMERIC_SIGN, LANGUAGE_LEVEL } from 'constants/options';

@i18n('form')
class LanguageForm extends Component {
  static propTypes = {
    record: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
  };

  handleLoadLanguages = data => {
    return api
      .getAllLanguages(data)
      .then(res => res.data)
      .catch(err => message.error(this.props.translate('all', 'error')));
  };

  render() {
    const { record, translate } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <Form>
        <Input.Group compact>
          <Form.Item style={{ width: 150, marginRight: 10, marginBottom: 0 }}>
            {getFieldDecorator('languageId', {
              initialValue: get(record, 'criterion.language.id', null),
            })(<LazySelect loadData={this.handleLoadLanguages} />)}
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            {getFieldDecorator('isOnLevel', {
              initialValue: get(record, 'criterion.isOnLevel', 'EQUAL'),
            })(
              <Select
                style={{ marginRight: 10 }}
                placeholder={translate('chooseOperation')}
                onClick={e => e.stopPropagation()}
              >
                {NUMERIC_SIGN.map(item => (
                  <Select.Option key={item.value} value={item.value}>
                    {translate(item.value)}
                  </Select.Option>
                ))}
              </Select>,
            )}
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            {getFieldDecorator('level', {
              initialValue: get(record, 'criterion.level', 'A1'),
            })(
              <Select
                placeholder={translate('chooseLevel')}
                onClick={e => e.stopPropagation()}
              >
                {LANGUAGE_LEVEL.map(item => (
                  <Select.Option key={item.key} value={item.value}>
                    {item.title}
                  </Select.Option>
                ))}
              </Select>,
            )}
          </Form.Item>
        </Input.Group>
      </Form>
    );
  }
}

export default LanguageForm;
