import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Table, Button, Popover, message } from 'antd';
import { debounce } from 'lodash';
// Services
import api from 'services/api';
import i18n from 'services/i18n';
// Utils
import { downloadFile } from 'utils/downloadFile';
// Actions
import { getOsceStudentsResults, lazyLoadResults } from 'actions/osce';

const mapStateToProps = state => ({
  results: state.osce.results,
  loading: state.osce.loading,
});

const mapDispatchToProps = {
  getOsceStudentsResults,
  lazyLoadResults,
};

@i18n('form')
@withRouter
@connect(
  mapStateToProps,
  mapDispatchToProps,
)
export default class Results extends Component {
  static propTypes = {
    results: PropTypes.array.isRequired,
    loading: PropTypes.bool,
  };

  static defaultProps = {
    loading: false,
  };

  constructor(props) {
    super(props);
    this.columns = [
      {
        title: props.translate('studentId'),
        dataIndex: 'studentId',
        from: 'form',
        width: 110,
        sorter: true,
      },
      {
        title: props.translate('lastName'),
        dataIndex: 'lastName',
        width: 100,
        sorter: true,
      },
      {
        title: props.translate('firstName'),
        dataIndex: 'firstName',
        width: 100,
        sorter: true,
      },
      {
        title: props.translate('failedItems'),
        dataIndex: 'failed',
        width: 100,
        align: 'center',
        sorter: false,
        render: record => {
          const dto = {
            osceId: record.osceId,
            locale: record.locale === 'en' ? 'ENGLISH' : 'GERMAN',
            showAllAnswers: false,
          };

          return (
            <Button
              type="normal"
              icon="download"
              size="small"
              onClick={e => {
                e.preventDefault();
                this.handleLoadItems(record.id, dto);
              }}
            >
              {props.translate('downloadFailed')}
            </Button>
          );
        },
      },
      {
        title: props.translate('allItems'),
        dataIndex: 'all',
        width: 100,
        align: 'center',
        sorter: false,
        render: record => {
          const dto = {
            osceId: record.osceId,
            locale: record.locale === 'en' ? 'ENGLISH' : 'GERMAN',
            showAllAnswers: true,
          };

          return (
            <Button
              type="normal"
              icon="download"
              size="small"
              onClick={e => {
                e.preventDefault();
                this.handleLoadItems(record.id, dto);
              }}
            >
              {props.translate('downloadAll')}
            </Button>
          );
        },
      },
      {
        title: props.translate('feedback'),
        dataIndex: 'feedback',
        width: 150,
        sorter: false,
        render: (feedbacks, row) => {
          return (
            <React.Fragment>
              {feedbacks.map(feedback => (
                <Popover
                  key={feedback.title}
                  content={
                    feedback.audioNoteId
                      ? props.translate('audio')
                      : feedback.text
                  }
                  title={feedback.title}
                >
                  <Button
                    disabled={!feedback.audioNoteId}
                    style={{ margin: 5 }}
                    type="normal"
                    icon={feedback.audioNoteId ? 'download' : 'edit'}
                    size="small"
                    onClick={e => {
                      e.preventDefault();
                      this.handleLoadAudio(feedback, row);
                    }}
                  />
                </Popover>
              ))}
            </React.Fragment>
          );
        },
      },
    ];
    this.state = {
      page: 0,
      pageSize: 30,
      total: 0,
      sortProp: 'lastName',
      sortDirection: 'ASC',
      preventLazyLoad: false,
    };
  }

  componentDidMount() {
    this.handleUpdateTableData();
    window.addEventListener('scroll', this.handlePageScroll);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.handleUpdateTableData();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handlePageScroll);
  }

  handlePageScroll = debounce(e => {
    const { body } = document;
    if (
      !this.state.preventLazyLoad &&
      this.props.results.length < this.state.total &&
      window.scrollY > body.scrollHeight - body.offsetHeight - 30
    ) {
      this.setState(
        { page: this.state.page + 1, preventLazyLoad: true },
        this.lazyLoadResults,
      );
    }
  }, 100);

  lazyLoadResults = () => {
    const osceId = this.props.match.params.id;
    const { page, pageSize, sortDirection, sortProp } = this.state;
    this.props
      .lazyLoadResults(osceId, {
        page,
        pageSize,
        sortProp,
        sortDirection,
      })
      .then(res => {
        this.setState({ total: res.pagination.total, preventLazyLoad: false });
      })
      .catch(err => message.error(this.props.translate('all', 'error')));
  };

  handleUpdateTableData = () => {
    const osceId = this.props.match.params.id;
    const { page, pageSize, sortProp, sortDirection } = this.state;

    this.props
      .getOsceStudentsResults(osceId, {
        page,
        pageSize,
        sortProp,
        sortDirection,
      })
      .then(res => {
        this.setState({ total: res.pagination.total });
      })
      .catch(err => {
        if (err.response?.status === 409) {
          return message.error(err.response.data.message);
        }
        return message.error(this.props.translate('all', 'error'));
      });
  };

  handleSort = (pagination, filters, sorter) => {
    // If sorting column is enabled
    if (sorter.columnKey) {
      const sortDirection = sorter.order === 'ascend' ? 'ASC' : 'DESC';
      const sortProp = sorter.columnKey;
      this.setState(
        { sortDirection, sortProp, page: 0 },
        this.handleUpdateTableData,
      );
    }
  };

  handleLoadItems = (studentId, dto) => {
    api
      .printStudentResults(studentId, dto)
      .then(res =>
        downloadFile(
          res.data,
          `student_${studentId}_${dto.showAllAnswers ? 'all' : 'failed'}.zip`,
          'application/x-download',
        ),
      )
      .catch(err => message.error(this.props.translate('all', 'error')));
  };

  handleLoadAudio = (feedback, row) => {
    api
      .downloadAudioNote(feedback.audioNoteId)
      .then(res =>
        downloadFile(
          res.data,
          `${feedback.title}-${row.lastName}-${row.studentId}.m4a`,
          'audio/m4a',
        ),
      )
      .catch(err => message.error(this.props.translate('all', 'error')));
  };

  render() {
    const dataSource = this.props.results.map(result => ({
      id: result.id,
      key: result.id,
      firstName: result.firstName,
      lastName: result.lastName,
      studentId: result.studentId,
      feedback: result.notes,
      failed: {
        id: result.id,
        osceId: this.props.match.params.id,
        locale: this.props.intl.locale,
      },
      all: {
        id: result.id,
        osceId: this.props.match.params.id,
        locale: this.props.intl.locale,
      },
    }));

    return (
      <div>
        <Table
          className="student-results"
          bordered
          pagination={false}
          columns={this.columns}
          dataSource={dataSource}
          loading={this.props.loading}
          onChange={this.handleSort}
        />
      </div>
    );
  }
}
