import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Button, Select } from 'antd';
import { withRouter } from 'react-router';
// Services
import api from 'services/api';
import i18n from 'services/i18n';
// Actions
import { getAllCheckListSections } from 'actions/checkList';
import { getAllRoles } from 'actions/role';
// Components
import LazySelect from 'components/LazySelect';

const mapDispatchToProps = {
  getAllCheckListSections,
  getAllRoles,
};

@withRouter
@i18n('form')
@Form.create({})
@connect(
  null,
  mapDispatchToProps,
)
export default class ImportSection extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
  };

  state = {
    idCheckList: null,
    sections: [],
  };

  handleLoadRoles = data => {
    return api.getAllRoles(data).then(res => res.data);
  };

  handleSelectRole = idCheckList =>
    this.setState({ idCheckList }, this.loadCheckListSections);

  loadCheckListSections = () => {
    const { idCheckList } = this.state;
    this.props
      .getAllCheckListSections({ idCheckList, page: 0, pageSize: 3000 })
      .then(({ payload }) => {
        const { data } = payload.sections;
        this.setState({ sections: data });
        // Clear previous selected section
        // when new section list is loaded
        this.props.form.resetFields('idSection');
      });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSubmit(values);
        this.props.form.resetFields();
        this.props.onCancel();
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form onSubmit={this.handleSubmit}>
        <div className="form_content">
          <Form.Item
            className="form-full-width"
            label={this.props.translate('selectRole')}
            colon={false}
            labelCol={{ span: 24 }}
          >
            {getFieldDecorator('idCheckListFrom', {
              rules: [
                {
                  required: true,
                  message: this.props.translate('checkboxGroup'),
                },
              ],
            })(
              <LazySelect
                dropdownMatchSelectWidth
                onChange={this.handleSelectRole}
                loadData={this.handleLoadRoles}
              />,
            )}
          </Form.Item>
          <Form.Item
            className="form-full-width"
            label={this.props.translate('selectSection')}
            colon={false}
            labelCol={{ span: 24 }}
          >
            {getFieldDecorator('idSection', {
              rules: [
                {
                  required: true,
                  message: this.props.translate('checkboxGroup'),
                },
              ],
            })(
              <Select
                showSearch
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                <Select.Option key="all" value="all">
                  All
                </Select.Option>
                {this.state.sections.map(section => (
                  <Select.Option key={section.id} value={section.id}>
                    {section.name}
                  </Select.Option>
                ))}
              </Select>,
            )}
          </Form.Item>
        </div>
        <div className="ant-modal-footer footer_max_width">
          <Button onClick={this.props.onCancel}>
            {this.props.translate('cancel')}
          </Button>
          <Button htmlType="submit" className="blueBtn">
            {this.props.translate('save')}
          </Button>
        </div>
      </Form>
    );
  }
}
