import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Select, message } from 'antd';
import { get } from 'lodash';
// Services
import i18n from 'services/i18n';
import api from 'services/api';
// Components
import LazySelect from 'components/LazySelect';
// Options
import { IS_CRITERIA } from 'constants/options';

@i18n('form')
export default class ProfessionForm extends Component {
  static propTypes = {
    record: PropTypes.object,
    form: PropTypes.object.isRequired,
  };

  handleChangeProfessions = data => {
    return api
      .getAllProfessions(data)
      .then(res => res.data)
      .catch(err => message.error(this.props.translate('all', 'error')));
  };

  render() {
    const { record, translate } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <Form>
        <Input.Group compact>
          <Form.Item style={{ marginBottom: 0 }}>
            {getFieldDecorator('isSimPatHasCriterion', {
              initialValue: record.criterion.isSimPatHasCriterion
                ? 'is'
                : 'isnt',
            })(
              <Select
                style={{ marginRight: 10 }}
                placeholder={translate('chooseOptions')}
                onClick={e => e.stopPropagation()}
              >
                {IS_CRITERIA.map(item => (
                  <Select.Option key={item.value} value={item.value}>
                    {translate(item.value)}
                  </Select.Option>
                ))}
              </Select>,
            )}
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            {getFieldDecorator('professionId', {
              initialValue: get(record, 'criterion.profession.id', null),
            })(<LazySelect loadData={this.handleChangeProfessions} />)}
          </Form.Item>
        </Input.Group>
      </Form>
    );
  }
}
