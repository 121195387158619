import React from 'react';
import { Row, Col } from 'antd';
import { Route, Switch } from 'react-router';

/** Components **/
import Login from 'pages/auth/login';
import ResetPassword from 'pages/auth/resetPassword';
import Email from 'pages/auth/email';
import Signup from 'pages/auth/signup';

import Logo from './loginSVG';

const CurrentView = ({ match }) => {
  const { url } = match;

  return (
    <Switch>
      <Route path={`${url}/login`} component={Login} />
      <Route path={`${url}/resetpassword/:hash`} component={ResetPassword} />
      <Route path={`${url}/email`} component={Email} />
      <Route path={`${url}/signup/:hash`} component={Signup} />
    </Switch>
  );
};

const AuthContainer = props => {
  return (
    <Row
      type="flex"
      justify="space-around"
      align="middle"
      className="login_wrapper"
    >
      <Col span={15} className="login_block">
        <Row
          type="flex"
          justify="space-around"
          align="middle"
          className="login_block_wrapper"
        >
          <Col span={12} className="loginLeftBlock">
            <Row
              type="flex"
              justify="space-around"
              align="middle"
              className="login_block_wrapper"
            >
              <Logo />
            </Row>
          </Col>
          <Col span={12} className="login_block_right">
            <Row
              type="flex"
              justify="space-around"
              align="middle"
              className="login_block_wrapper"
            >
              <CurrentView {...props} />
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default AuthContainer;
