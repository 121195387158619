import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

/** Constants */
import { STATION_TYPES } from 'constants/common';

/** Components*/
import BreakData from '../PopUpContent/BreakData';

/**
 * Component for rendering content inside cell rotation
 * @reactProps {object} timeSlot - data of current timeSlot
 * @reactProps {bool} isSpPaused - whether circuit is SP Paused or not
 */
export default class Break extends PureComponent {
  static propsTypes = {
    timeSlot: PropTypes.object,
    hasBigBreak: PropTypes.bool,
    stationType: PropTypes.string,
    isSpPaused: PropTypes.bool,
    isFirstBreak: PropTypes.bool,
    sequenceNumberLastBreak: PropTypes.number,
  };

  get breakCell() {
    let countCell = 0;
    switch (this.props.stationType) {
      case STATION_TYPES.BREAK:
      case STATION_TYPES.SP_PAUSED:
        countCell = 1;
        break;
      case STATION_TYPES.PREPARATION:
      case STATION_TYPES.DOUBLESP:
        countCell = 4;
        break;
      case STATION_TYPES.DOUBLE:
        countCell = 6;
        break;
      default:
        countCell = 3;
        break;
    }
    return Array(countCell)
      .fill(1)
      .map((item, i) => <span className="break-cell" key={i} />);
  }

  get showBigBreak() {
    const { hasBigBreak, isSpPaused, stationType } = this.props;
    return (
      ((isSpPaused && stationType !== STATION_TYPES.DOUBLE) ||
        (!isSpPaused &&
          stationType !== STATION_TYPES.PREPARATION &&
          stationType !== STATION_TYPES.DOUBLE)) &&
      hasBigBreak
    );
  }

  render() {
    const { osceDayId, timeSlot, isFirstBreak } = this.props;
    return (
      <BreakData
        timeSlot={timeSlot}
        osceDayId={osceDayId}
        showBigBreak={this.showBigBreak}
        breakCell={this.breakCell}
        isFirstBreak={isFirstBreak}
        sequenceNumberLastBreak={this.props.sequenceNumberLastBreak}
      />
    );
  }
}
