import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { Col, Row, Button, Spin, Tooltip } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { updateData, resetData } from 'actions/updateData';
import { genaratePreview } from 'actions/osceAdministration';
import addLoad from 'services/decorators/addLoad';
import { msgError } from 'services/errorHandler';

import PreviewAnotationItem from './previewAnotationItem';
import Preview from './preview';

const mapDispatchToProps = dispatch =>
  bindActionCreators({ dispatch, updateData, resetData }, dispatch);

const mapStateToProps = ({ runtime }) => ({ data: runtime.osceData });

@withRouter
@addLoad({
  i18Name: 'preview',
})
@connect(
  mapStateToProps,
  mapDispatchToProps,
)
export default class OscePreview extends Component {
  state = { loading: false };

  getOscePreview = async () => {
    try {
      this.setState({ loading: true });
      const { data } = await genaratePreview(this.props.match.params.id);
      this.props.updateData(data, 'oscePreview');
    } catch (err) {
      msgError(err);
    } finally {
      this.setState({ loading: false });
    }
  };

  componentWillUnmount() {
    this.props.resetData('oscePreview');
  }

  render() {
    const { translate } = this.props;
    return (
      <Spin spinning={this.state.loading}>
        <div className="form-header-grey">{translate('preview', 'form')}</div>
        <Row>
          <Col span={6}>
            <Tooltip
              placement="top"
              title={this.props.translate('generatePreview', 'tooltips')}
            >
              <Button className="blueBtn" onClick={this.getOscePreview}>
                {translate('generatePreview')}
              </Button>
            </Tooltip>
            <div className="annotation_wrapper">
              <PreviewAnotationItem text="L">
                {translate('logicalBreak')}
              </PreviewAnotationItem>
              <PreviewAnotationItem className="annotation_rotation-break">
                {translate('rotationBreak')}
              </PreviewAnotationItem>
              <PreviewAnotationItem className="annotation_lunch-break">
                {translate('lunchBreak')}
              </PreviewAnotationItem>
              <PreviewAnotationItem className="annotation_coffee-break">
                {translate('coffeeBreak')}
              </PreviewAnotationItem>
            </div>
          </Col>
          <Col span={18}>
            <Preview />
          </Col>
        </Row>
      </Spin>
    );
  }
}
