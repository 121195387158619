import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Spin, Tabs, Button, Tooltip, message } from 'antd';
import { history } from 'store';
import PropTypes from 'prop-types';
import { get } from 'lodash';

/** * Services*/
import api from 'services/api';
import tabPattern from 'services/decorators/tabPattern';
import addLoadWithRedux from 'services/decorators/addLoadWithRedux';
import { msgError } from 'services/errorHandler';

/** * Actions*/
import { resetData } from 'actions/updateData';
import {
  getOsceById,
  deleteOsce,
  getAllOsces,
} from 'actions/osceAdministration';
import { updateOsceBellSystem } from 'actions/osce';
/** Constants **/
import { SUPER_ADMIN } from 'constants/common';

/** * Components*/
import TablePanel from 'components/TablePanel/index';
import DeleteButton from 'components/DeleteButton';
import Circuit from './Circuit';
import Schedule from './Schedule';
import BasicData from './BasicData';
import OSCEsList from './OSCEsList';
import OsceSettings from './osceCustomSettings';
import StudentsData from './studentsData';
import ImportExport from './ImportExport';
import Results from './Results';
import BellSystem from './BellSystem';

import './style.less';

const { TabPane } = Tabs;

/**
 * The main component, which display osceAdministration
 * @reactProps {func} translate - function which translate text.
 * @reactProps {func} stateloading
 * @reactProps {func} getOsceById - get information about osce
 * @reactProps {string} id - OSCE id
 * @reactProps {object} osceData - object with OSCE data
 * @reactProps {string} semesterId - semester id at OSCE
 * @reactProps {string} tab - tab name
 * @reactProps {func} getAllOsces - function for getting info about all osces at semester
 * @reactProps {object} oscesBySemesterData - object of osces data
 */
@tabPattern('/osce-administration/:semesterId/osce/:tab/:id')
@addLoadWithRedux({
  runtimeReduxNames: ['osce', 'osceLoading', 'oscesBySemester'],
  mapMethods: {
    getOsceById,
    getAllOsces,
    resetData,
  },
})
@connect(
  null,
  { updateOsceBellSystem },
)
export default class OsceProfile extends Component {
  static propTypes = {
    translate: PropTypes.func,
    getOsceById: PropTypes.func,
    id: PropTypes.string,
    osceData: PropTypes.object,
    semesterId: PropTypes.string,
    tab: PropTypes.string,
    oscesBySemesterData: PropTypes.object,
    getAllOsces: PropTypes.func,
  };

  state = { roles: [] };

  componentDidMount() {
    this.getUserRoles();
    this.load();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      (prevProps.osceData === null && get(this.props.osceData, 'name')) ||
      (get(prevProps.osceData, 'name') &&
        prevProps.osceData.name !== get(this.props.osceData, 'name'))
    ) {
      this.props.getAllOsces('oscesBySemester', this.props.semesterId);
    }
  }

  componentWillUnmount() {
    this.props.resetData('schedule');
    this.props.resetData('osce');
    this.props.resetData('osceDays');
  }

  getUserRoles = () => {
    try {
      const json = localStorage.getItem('roles');
      const roles = JSON.parse(json);
      this.setState({ roles });
    } catch (error) {
      message.error(this.props.translate('all', 'error'));
    }
  };

  load = async osceId => {
    const { id, semesterId, tab, getOsceById } = this.props;
    this.props.resetData('osce');
    await getOsceById('osce', osceId || id);
    if (osceId)
      history.push(`/osce-administration/${semesterId}/osce/${tab}/${osceId}`);
  };

  handleUpdateBellSystem = () => {
    const { id: osceId } = this.props.osceData;
    this.props.updateOsceBellSystem(osceId);
  };

  get renderName() {
    const { semesterId, osceData } = this.props;
    if (osceData) {
      return (
        <span className="osce-profile-name_wrapper">
          <p className="osce-profile-name">OSCE</p>
          <OSCEsList
            value={osceData.name}
            semesterId={semesterId}
            onChange={this.load}
          />
          {this.props.tab === 'bell-system' ? (
            <Button
              className="refresh-btn"
              onClick={this.handleUpdateBellSystem}
            >
              Refresh Bell System
            </Button>
          ) : null}
          {osceData.freezed ? (
            <p className="osce-profile-is-freezed">
              {this.props.translate('osceFrozen')}
            </p>
          ) : null}
        </span>
      );
    }
    return null;
  }

  deleteOsce = async () => {
    const { id, semesterId } = this.props;
    const url = `/osce-administration/${semesterId}/osce`;
    try {
      const { props } = this;
      await deleteOsce(id);
      props.updateData({ data: [] }, 'osceDays');
      props.updateData(
        { ...props.osceData, osceStatus: 'OSCE CREATED' },
        'osce',
      );
      this.props.history.push(url);
    } catch (e) {
      console.error(e);
      msgError(e);
    }
  };

  handleFreezeOsce = () => {
    const { id: osceId, freezed } = this.props.osceData;
    api
      .freezeOsce(osceId, !freezed)
      .then(res => this.load())
      .catch(err => message.error(this.props.translate('all', 'error')));
  };

  render() {
    const {
      translate,
      tab,
      osceLoading,
      onTabClick,
      oscesBySemesterData,
    } = this.props;

    const showFreezeOsceButton = this.state.roles.indexOf(SUPER_ADMIN) !== -1;

    return (
      <Spin spinning={osceLoading}>
        <React.Fragment>
          <TablePanel className="role_panel_line">
            {this.renderName}
            <DeleteButton
              textBtn={translate('deleteOsce')}
              className="table-panel_btn-right"
              action={this.deleteOsce}
            />
            {showFreezeOsceButton ? (
              <Tooltip
                placement="top"
                title={this.props.translate('freezeOsce', 'tooltips')}
              >
                <Button
                  onClick={this.handleFreezeOsce}
                  className="freeze-btn table-panel_btn-right"
                >
                  {get(this.props, 'osceData.freezed')
                    ? translate('unFreezeOsce')
                    : translate('freezeOsce')}
                </Button>
              </Tooltip>
            ) : null}
          </TablePanel>
          <Tabs
            activeKey={tab}
            onTabClick={onTabClick}
            animated={{ inkBar: true, tabPane: false }}
          >
            <TabPane tab={translate('basicData')} key="basic-data">
              <BasicData repetitionData={oscesBySemesterData} />
            </TabPane>
            <TabPane tab={translate('circuitsDetails')} key="circuits-details">
              <Circuit />
            </TabPane>
            <TabPane tab={translate('schedule')} key="schedule">
              <Schedule />
            </TabPane>
            <TabPane tab={translate('studentsData')} key="students-data">
              <StudentsData />
            </TabPane>
            <TabPane tab={translate('importAndExport')} key="import-export">
              <ImportExport />
            </TabPane>
            <TabPane tab={translate('osceSettings')} key="settings">
              <OsceSettings />
            </TabPane>
            <TabPane tab={translate('results')} key="results">
              <Results />
            </TabPane>
            <TabPane tab={translate('bellSystem')} key="bell-system">
              <BellSystem />
            </TabPane>
          </Tabs>
        </React.Fragment>
      </Spin>
    );
  }
}
