import React, { Component } from 'react';
import { history } from 'store';
import { Form, notification, Spin } from 'antd';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { updateData } from 'actions/updateData';
import { msgLocalError } from 'services/errorHandler';
import TablePanel from 'components/TablePanel/index';
import DeleteButton from 'components/DeleteButton';
import i18n from 'services/i18n';
import profileFormDecorator from 'services/decorators/profileFormDecorator';
import EditableDoubleLabel from 'components/Form/formEditableDoubleLabel';
import { examinersCRUD } from 'actions/cruds';

import PersonalInfo from './cards/personalInfo';
import ClinicInfo from './cards/clinicInformation';
import Secretary from './cards/secretary';

const mapDispatchToProps = dispatch =>
  bindActionCreators({ dispatch, updateData }, dispatch);

@i18n('form')
@connect(
  null,
  mapDispatchToProps,
)
@profileFormDecorator()
export default class EditExaminer extends Component {
  state = { loading: false };

  deleteExaminers = async () => {
    try {
      await examinersCRUD.deleteRequest(this.props.id);
      notification.success({
        message: this.props.translate('success'),
      });
      history.push('/examiners/list');
    } catch (e) {
      msgLocalError({ error: e, translate: this.props.translate });
    }
  };

  get isDisable() {
    return Boolean(
      !get(this.props, 'data.canDelete') || get(this.props, 'data.deletedAt'),
    );
  }

  get isDeleted() {
    return !!get(this.props, 'data.deletedAt');
  }

  render() {
    const { data } = this.props;
    return (
      <Spin spinning={this.state.loading}>
        <Form
          onSubmit={this.props.wrapSubmit(
            values => examinersCRUD.put(this.props.id, values),
            newData => this.props.updateData(newData, 'examinerProfile'),
          )}
        >
          <TablePanel className="clinic_panel_line examiner-edit-header">
            <EditableDoubleLabel
              rules={[
                {
                  required: true,
                  message: this.props.translate('required', 'error'),
                },
              ]}
              firstLabelName="lastName"
              secondLabelName="firstName"
              firstInit={get(data, 'lastName')}
              secondInit={get(data, 'firstName')}
              deleted={this.isDeleted}
            />
            <DeleteButton
              action={this.deleteExaminers}
              disabled={this.isDisable}
            />
          </TablePanel>
          <div className="examiner-card-row">
            <PersonalInfo data={data} />
            <span className="card-border-vertical" />
            <ClinicInfo data={get(data, 'examinerContactDetails.clinic')} />
            <span className="card-border-vertical" />
            <Secretary data={get(data, 'secretaryResponse')} />
          </div>
        </Form>
      </Spin>
    );
  }
}
