import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

/** Utils, Configs*/
import addLoadWithRedux from 'services/decorators/addLoadWithRedux';

/** Actions*/
import { getOsceDays, getSequence } from 'actions/circuits';

/** Constants **/
import { OSCE_STATUS } from 'constants/common';

/** Components*/
import Sequence from './Sequence';

/**
 * Component for rendering osce days with sequences and circuits
 * @reactProps {object} day - data about current osce day
 * @reactProps {object} sequenceData - data about sequence at osce
 * @reactProps {function} getOsceDays - function for getting information about osce days
 * @reactProps {string} page - value for tracking current page ( circuits OR schedule )
 */
@addLoadWithRedux({
  i18Name: 'circuit',
  runtimeReduxNames: [
    'osce',
    props => ({ as: 'sequence', runtimeName: `sequence${props.day.id}` }),
  ],
  mapMethods: { getSequence, getOsceDays },
})
export default class OsceDayDetails extends Component {
  static propsTypes = {
    day: PropTypes.object.isRequired,
    sequenceData: PropTypes.object.isRequired,
  };

  static defaultProps = {};

  componentDidMount() {
    this.loadCircuits();
  }

  loadCircuits = () => {
    const {
      day: { id },
    } = this.props;
    this.props.getSequence(`sequence${id}`, id);
  };

  get renderSequence() {
    const { day, getOsceDays, translate } = this.props;
    const { data = [] } = this.props.sequenceData || {};
    if (
      get(this.props.osceData, 'osceType') === 'MANUAL' &&
      get(this.props.osceData, 'osceStatus') === OSCE_STATUS.CIRCUITS_CREATED &&
      data.length === 1
    ) {
      data.push({
        name: '',
        numberRotations: 0,
        positionInOsceDay: 2,
      });
    }
    return data.map(sequence => (
      <Sequence
        translate={translate}
        key={sequence.id}
        sequence={sequence}
        osceDay={day}
        loadCircuits={this.loadCircuits}
        getOsceDays={getOsceDays}
      />
    ));
  }

  render() {
    return (
      <div className="osce-day">
        <div className="osce-day_sequences-wrapper">{this.renderSequence}</div>
      </div>
    );
  }
}
