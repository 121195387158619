import { clone } from 'lodash';

import { CHANGE_LANGUAGE } from '../constants/actions';

let lang = localStorage.getItem('lang');

if (!lang) {
  // get lang from browser
  lang = navigator.language || navigator.userLanguage || '';
  if (lang.toLowerCase().includes('en')) {
    lang = 'en';
  } else {
    lang = 'de';
  }
  localStorage.setItem('lang', lang);
}
const defState = { lang };

export default function localization(state = clone(defState), action = {}) {
  if (action.type === CHANGE_LANGUAGE) {
    localStorage.setItem('lang', action.lang);
    return { lang: action.lang };
  }
  return state;
}
