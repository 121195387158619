import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { message, Table } from 'antd';
// Services
import i18n from 'services/i18n';
import { momentFormatDateTime } from 'services/format';
// Actions
import {
  getOsceStudentsResults,
  lazyLoadResults,
  getOsceBellSystem,
} from 'actions/osce';
// Options
import { BELL_SYSTEM_ICONS } from 'constants/options';
import BellSystemTable from './BellSystemTable';

const mapStateToProps = state => ({
  bellSystem: state.osce.bellSystem,
  loading: state.osce.loading,
});

const mapDispatchToProps = {
  getOsceStudentsResults,
  lazyLoadResults,
  getOsceBellSystem,
};

@i18n('form')
@withRouter
@connect(
  mapStateToProps,
  mapDispatchToProps,
)
export default class BellSystem extends Component {
  static propTypes = {
    bellSystem: PropTypes.array.isRequired,
    loading: PropTypes.bool,
  };

  static defaultProps = {
    loading: false,
  };

  constructor(props) {
    super(props);

    this.dayTableColumns = [
      {
        title: props.translate('date', 'circuit'),
        dataIndex: 'date',
        render: time => momentFormatDateTime(time),
      },
      {
        title: props.translate('name'),
        dataIndex: 'title',
      },
    ];

    this.eventTableColumns = [
      {
        title: props.translate('time', 'activityLogColumns'),
        dataIndex: 'time',
        render: time => momentFormatDateTime(time),
      },
      {
        title: props.translate('eventType'),
        dataIndex: 'type',
        render: eventType => {
          return (
            <div>
              <img
                style={{ marginRight: 10 }}
                src={BELL_SYSTEM_ICONS[eventType]}
                alt="icon"
                height="24"
              />
              <span>{props.translate(eventType)}</span>
            </div>
          );
        },
      },
    ];
  }

  componentDidMount() {
    this.handleUpdateTableData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.handleUpdateTableData();
    }
  }

  handleUpdateTableData = () => {
    const osceId = this.props.match.params.id;

    this.props
      .getOsceBellSystem(osceId)
      .catch(err => message.error(this.props.translate('all', 'error')));
  };

  expandedRowRender = day => {
    const dataSource = day.events.map(event => ({
      ...event,
      key: event.id,
    }));

    return (
      <div className="bell-system-expanded-row">
        <BellSystemTable
          id={day.id}
          key={day.id}
          columns={this.eventTableColumns}
          dataSource={dataSource}
          loading={this.props.loading}
        />
      </div>
    );
  };

  render() {
    const dataSource = this.props.bellSystem.map(day => ({
      ...day,
      key: day.id,
    }));

    return (
      <div className="table-with-action">
        <Table
          expandRowByClick
          pagination={false}
          columns={this.dayTableColumns}
          dataSource={dataSource}
          expandedRowRender={this.expandedRowRender}
        />
      </div>
    );
  }
}
