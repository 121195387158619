import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

/** UI*/
import { Col, Row } from 'antd';

/** Services*/
import i18n from 'services/i18n';
import { checkPositiveNumber } from 'services/validations';
/** Actions */
import { osceCRUD } from 'actions/cruds';
/** Components*/
import Input from 'components/Form/formEditableLabel';
import DropDown from 'components/Form/formEditableLabelDropDown';
import CheckBox from 'components/Form/formSingleCheckbox';

const checkPositive = {
  func: checkPositiveNumber,
  message: 'onlyPositiveNumber',
};

const OSCE_CREATION_TYPES = [{ value: 'MANUAL' }, { value: 'AUTO' }];

/**
 * Component for rendering inputs fields at basic data
 * @reactProps {func} data - data about osce
 * @reactProps {func} repetitionData - data about repetition of osces
 */
@i18n('form')
export default class BasicInputs extends React.Component {
  static propTypes = {
    data: PropTypes.object,
    repetitionData: PropTypes.object,
  };

  render() {
    const { data = {}, repetitionData } = this.props;
    if (!repetitionData) return null;
    return (
      <Row gutter={32}>
        <Col span={10}>
          <Input
            name="name"
            title="OSCEName"
            required
            initialValue={data.name}
            disabled={this.props.data.freezed}
          />
          <DropDown
            name="OSCECreationType"
            i18Name="osceAdministration"
            options={OSCE_CREATION_TYPES}
            initialValue={data.OSCECreationType || data.osceType}
            disabled={this.props.data.freezed}
          />
          <DropDown
            search
            name="repetition"
            getList={osceCRUD.getList}
            options={repetitionData.data}
            queryParams={{ semesterId: this.props.semesterId }}
            withoutTranslate
            withFindInOptions
            alwaysAddOptions={[{ name: 'None', id: null }]}
            initialValue={data.repetitionOsceId || ''}
            disabled={this.props.data.freezed}
          />
          <CheckBox
            name="isFormative"
            initialValue={Boolean(data.isFormative)}
            disabled={this.props.data.freezed}
          />
        </Col>
        {get(data, 'osceType') === 'AUTO' && (
          <Col span={10}>
            <Input
              name="maxStudents"
              title="NumberOfStudents"
              type="number"
              validator={checkPositive}
              initialValue={data.maxStudents}
              disabled={this.props.data.freezed}
            />
            <Input
              name="maxCircuits"
              title="NumberOfCircuits"
              type="number"
              validator={checkPositive}
              initialValue={data.maxCircuits}
              disabled={this.props.data.freezed}
            />
            <Input
              name="maxRooms"
              title="NumberOfRooms"
              type="number"
              validator={checkPositive}
              initialValue={data.maxRooms}
              disabled={this.props.data.freezed}
            />
          </Col>
        )}
      </Row>
    );
  }
}
