import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Input,
  Tabs,
  Button,
  Checkbox,
  Select,
  Radio,
  message,
} from 'antd';
// Services
import i18n from 'services/i18n';
// Components
import Criterias from './Criterias';
import Options from './Options';

@i18n('form')
@Form.create()
export default class EditCheckListQuestion extends PureComponent {
  static propTypes = {
    sectionId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    checkList: PropTypes.array.isRequired,
    form: PropTypes.object.isRequired,
    question: PropTypes.object,
  };

  static defaultProps = {
    question: {
      comment: '',
      criteriaList: [],
      numberInQueue: 0,
      optionType: '',
      questionOptionList: [],
      regressionItem: true,
      text: '',
    },
  };

  state = { tab: 'criterias' };

  handleChangeActiveTab = tab => this.setState({ tab });

  onSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const options = values.questionOptionRequestList;
        const optionsLength = options.length;
        const criteria = values.criteriaRequestList;
        const criteriaLength = criteria.length;
        // Validate options
        if (
          optionsLength < 1 ||
          (values.optionType === 'TOGGLE_TWO' && optionsLength !== 2) ||
          (values.optionType === 'TOGGLE_THREE' && optionsLength !== 3)
        ) {
          message.error(this.props.translate('invalidOptionsCount', 'error'));
          this.handleChangeActiveTab('options');
          return;
        }
        // If option added but not filled in
        if (options.some(option => option.textDescription === '')) {
          return;
        }

        // Validate criteria
        // If criteria added but not filled in
        if (
          criteriaLength > 0 &&
          criteria.some(criterion => criterion.text === '')
        ) {
          return;
        }

        this.props.onSubmit(values);
        this.props.form.resetFields();
        this.props.onCancel();
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { criteriaList, questionOptionList } = this.props.question;

    // Set initial values for keys
    getFieldDecorator('criteriaRequestList', {
      initialValue: criteriaList,
    });
    getFieldDecorator('questionOptionRequestList', {
      initialValue: questionOptionList,
    });

    return (
      <Form onSubmit={this.onSubmit} id="modal-form">
        <div className="form_content">
          <Form.Item label={this.props.translate('section')}>
            {getFieldDecorator('sectionId', {
              initialValue: this.props.sectionId,
              rules: [
                {
                  required: true,
                  message: this.props.translate('checkboxGroup'),
                },
              ],
            })(
              <Select disabled>
                {this.props.checkList.map(section => (
                  <Select.Option key={section.id} value={section.id}>
                    {section.name}
                  </Select.Option>
                ))}
              </Select>,
            )}
          </Form.Item>
          <Form.Item label={this.props.translate('question')}>
            {getFieldDecorator('text', {
              initialValue: this.props.question.text,
              rules: [
                {
                  required: true,
                  message: this.props.translate('insert', 'error'),
                },
              ],
            })(
              <Input.TextArea
                placeholder={this.props.translate('typeQuestion', 'sections')}
              />,
            )}
          </Form.Item>
          <Form.Item label={this.props.translate('comment')}>
            {getFieldDecorator('comment', {
              initialValue: this.props.question.comment,
            })(<Input />)}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('regressionItem', {
              valuePropName: 'checked',
              initialValue: this.props.question.regressionItem,
            })(
              <Checkbox>
                {this.props.translate('regressionItem', 'role')}
              </Checkbox>,
            )}
          </Form.Item>
          <Tabs
            activeKey={this.state.tab}
            onTabClick={this.handleChangeActiveTab}
          >
            <Tabs.TabPane
              tab={this.props.translate('criteria')}
              key="criterias"
              forceRender
            >
              <Criterias form={this.props.form} />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={this.props.translate('options', 'role')}
              key="options"
              forceRender
            >
              <Form.Item>
                {getFieldDecorator('optionType', {
                  initialValue: this.props.question.optionType,
                })(
                  <Radio.Group className="modal-full-width-checkbox ">
                    <Radio.Button value="POPOVER">
                      {this.props.translate('POPOVER', 'role')}
                    </Radio.Button>
                    <Radio.Button value="SLIDER">
                      {this.props.translate('SLIDER', 'role')}
                    </Radio.Button>
                    <Radio.Button value="TOGGLE_TWO">
                      {this.props.translate('TOGGLE_TWO', 'role')}
                    </Radio.Button>
                    <Radio.Button value="TOGGLE_THREE">
                      {this.props.translate('TOGGLE_THREE', 'role')}
                    </Radio.Button>
                  </Radio.Group>,
                )}
              </Form.Item>
              <Options form={this.props.form} />
            </Tabs.TabPane>
          </Tabs>
        </div>
        <div className="ant-modal-footer footer_max_width">
          <Button onClick={this.props.onCancel}>
            {this.props.translate('cancel')}
          </Button>
          <Button htmlType="submit" className="blueBtn">
            {this.props.translate('save')}
          </Button>
        </div>
      </Form>
    );
  }
}
