import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Button } from 'antd';

class EditableField extends Component {
  static propTypes = {
    editable: PropTypes.bool,
    label: PropTypes.string,
    readOnly: PropTypes.bool,
    value: PropTypes.node,
    children: PropTypes.element,
    edit: PropTypes.func,
    help: PropTypes.node,
  };

  static defaultProps = {
    label: '',
    readOnly: false,
    editable: false,
    edit: () => {},
  };

  render() {
    // If field is readOnly return simple span with value
    if (this.props.readOnly) {
      return (
        <Form.Item
          className="card-item"
          colon={false}
          label={<span>{this.props.label}</span>}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 24 }}
        >
          <span className="card-no-editable">{this.props.value}</span>
        </Form.Item>
      );
    }

    return (
      <Form.Item
        className="card-item"
        help={this.props.help}
        colon={false}
        label={<span onClick={this.props.edit}>{this.props.label}</span>}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 24 }}
      >
        {this.props.editable ? (
          this.props.children
        ) : (
          <React.Fragment>
            <span onClick={this.props.edit} className="card-no-editable">
              {this.props.value}
            </span>
            <div
              className="card-btns-container_hover"
              onClick={this.props.edit}
            >
              <Button shape="circle" icon="edit" size="small" />
            </div>
          </React.Fragment>
        )}
      </Form.Item>
    );
  }
}

export default EditableField;
