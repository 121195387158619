import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, Popconfirm } from 'antd';
import { connect } from 'react-redux';
import { get } from 'lodash';

/** Services */
import addLoad from 'services/decorators/addLoad';
import { msgError } from 'services/errorHandler';

/** Actions */
import { sendOutputEmail, scheduleQrCode, printOutput } from 'actions/outputs';

/** Utils */
import { downloadFile } from 'utils/downloadFile';

/** Components */
import TableSelections from './TableSelections';
import ModalEditor from './modalEditor';

const mapStateToProps = ({ localization }) => ({ language: localization.lang });

/**
 * Table with selection for section Output.
 * @reactProps {bool} sendMail - show button Send mail.
 * @reactProps {bool} sendQR - show button Send QR.
 * @reactProps {string} title - title table.
 * @reactProps {string} url - status loading list of osceDays.
 * @reactProps {string} name - variable in redux with data for table.
 * @reactProps {function} renderRow - function for view row in table.
 * @reactProps {function} getButtons - show buttons.
 * @reactProps {object} printCopiesParams - params for request to BE.
 * @reactProps {string} type - type table.
 */
@addLoad({ i18Name: 'output' })
@connect(mapStateToProps)
export default class PrintExportList extends PureComponent {
  static propTypes = {
    sendMail: PropTypes.bool,
    sendQR: PropTypes.bool,
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    renderRow: PropTypes.func,
    getButtons: PropTypes.func,
    printCopiesParams: PropTypes.object,
    type: PropTypes.string,
  };

  static defaultProps = {
    type: 'schedule',
  };

  state = { showModal: false, ids: [] };

  loadPDF = data => async () => {
    const { props } = this;
    const { name, type, language, printCopiesParams } = props;
    const resp = await props.stateloading(
      printOutput(
        name,
        type,
        {
          ...printCopiesParams,
          locale: language === 'en' ? 'ENGLISH' : 'GERMAN',
        },
        { ids: this.state.ids, template: data },
      ),
    );
    downloadFile(resp, `${props.name}${props.type}.pdf`, 'application/pdf');
  };

  sendMail = ids => async () => {
    try {
      const { props } = this;
      const { name, type, printCopiesParams } = props;
      const resp = await props.stateloading(
        sendOutputEmail(
          name,
          type,
          {
            ...printCopiesParams,
          },
          { ids },
        ),
      );
      if (get(resp, 'simulatedPatientInvites.length')) {
        let error = 'Wrong email for: ';
        resp.simulatedPatientInvites.forEach(item => {
          error += `\n${item.firstName} ${item.lastName} - ${item.email};`;
        });
        msgError(error, 10);
      }
    } catch (e) {
      msgError(e);
    }
  };

  sendQR = async ids => {
    const { props } = this;
    const resp = await props.stateloading(
      scheduleQrCode(
        props.name.toUpperCase(),
        { ids },
        props.printCopiesParams.osceId,
      ),
    );
    downloadFile(resp, `${props.name}QR.pdf`, 'application/pdf');
  };

  showEditor = ids => () => {
    this.setState({ showModal: true, ids });
  };

  getButtons = ({ disabled: _disabled, selected }) => {
    const { props } = this;
    const { translate } = props;
    const disabled = _disabled || props.loading;
    return (
      <React.Fragment>
        <Button
          className="sendInvitations-btn"
          disabled={disabled}
          onClick={this.showEditor(selected)}
        >
          {translate('printCopies')}
        </Button>
        {props.sendMail && (
          <Popconfirm
            placement="bottom"
            title={translate('areYouSureSendEmail')}
            onConfirm={this.sendMail(selected)}
          >
            <Button className="sendInvitations-btn" disabled={disabled}>
              {translate('sendMail')}
            </Button>
          </Popconfirm>
        )}
        {props.sendQR && (
          <Button
            className="sendInvitations-btn"
            disabled={disabled}
            onClick={() => this.sendQR(selected)}
          >
            {translate('exportQRCode')}
          </Button>
        )}
      </React.Fragment>
    );
  };

  render() {
    const { props } = this;
    return (
      <React.Fragment>
        <TableSelections
          {...props}
          getButtons={this.getButtons}
          isLoading={props.loading}
          selectionKey={`${props.name}Id`}
        />
        <ModalEditor
          type={props.type}
          name={props.name}
          loadPDF={data => this.loadPDF(data)}
          printCopiesParams={props.printCopiesParams}
          show={this.state.showModal}
          close={() => this.setState({ showModal: false, ids: [] })}
        />
      </React.Fragment>
    );
  }
}
