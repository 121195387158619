import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Input } from 'antd';

import i18n from 'services/i18n';

@i18n('form')
@Form.create({})
class AddProfession extends Component {
  static propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired,
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSubmit(values);
        this.props.form.resetFields();
        this.props.onCancel();
      }
    });
  };

  render() {
    const { getFieldDecorator, resetFields } = this.props.form;
    return (
      <Modal
        title={this.props.translate('addBtn', 'professionColumns')}
        visible={this.props.visible}
        okText={this.props.translate('save', 'form')}
        cancelText={this.props.translate('cancel', 'form')}
        onCancel={e => {
          resetFields();
          this.props.onCancel();
        }}
        onOk={this.handleSubmit}
      >
        <Form onSubmit={this.handleSubmit}>
          <Form.Item
            className="form-full-width"
            label={this.props.translate('profession')}
          >
            {getFieldDecorator('name', {
              rules: [
                { required: true, message: this.props.translate('insert') },
              ],
            })(<Input type="text" />)}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default AddProfession;
