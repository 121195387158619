import objKeys2Strings from '../services/objKeys2Strings';

export default {
  messages: objKeys2Strings({
    header: {
      simulatedPatients: 'Simulationspersonen',
      examiners: 'Examinatoren',
      roles: 'Rollen',
      OSCEAdministration: 'OSCE-Verwaltung',
      systemAdministration: 'Systemadministration',
      administration: 'Administration',
      multiple: 'mehrere',
    },
    form: {
      role: 'Rolle',
      room: 'Raum',
      bellSystem: 'Bell System',
      eventType: 'das Ereignis',
      preview: 'iOSCE Vorschau',
      useDefaultSettings: 'Standardeinstellungen verwenden',
      basicData: 'Basis',
      circuitsDetails: 'Parcours',
      generateOSCE: 'OSCE erstellen',
      createCircuit: 'Parcours erstellen',
      schedule: 'Zeitplan',
      studentsData: 'Studierendendaten',
      importAndExport: 'Importieren & Exportieren',
      planSpTrainings: 'SP-Training planen',
      assignments: 'Zuordnungen',
      osceSettings: 'Einstellungen',
      results: 'Ergebnisse',
      sectionName: 'Abschnittsname',
      description: 'Beschreibung',
      weighting: 'Gewichtung in %',
      typeName: 'Typname',
      typeDescription: 'Typbeschreibung',
      MINUTE: 'Minute',
      SECOND: 'Sekunde',
      basePath: 'Basispfad',
      feedbackBasePath: 'Feedback Basispfad',
      host: 'Server',
      feedbackHost: 'Feedback Server',
      roleInformation: 'Rolleninformation',
      checklist: 'Checkliste',
      targetSystem: 'Zielsystem',
      pastUseOfRole: 'Vorherige Nutzung der Rolle',
      criteriaForSpSelection: 'Kriterien für SP-Auswahl',
      export: 'Exportieren',
      filter: 'Filter',
      clearAll: 'Alles löschen',
      hideFilters: 'Filter ausblenden',
      selectRole: 'Wählen Sie Rolle',
      selectSection: 'Wählen Sie den Abschnitt',
      selectQuestion: 'Wählen Sie eine Frage aus',
      new: 'Neu',
      import: 'Importieren',
      minor: 'geringfügig',
      major: 'substantiell',
      rolesChangeTitle: 'Einige Änderungen wurden vorgenommen',
      rolesChangeDescription: 'Bitte ändern Sie den Umfang der Änderungen',
      studentId: 'Matrikelnummer',
      floor: 'Stockwerk',
      roomNumber: 'Raumnummer',
      discipline: 'Disziplin',
      clinic: 'Klinik',
      calendarYear: 'Kalenderjahr',
      semesterType: 'Semesterart',
      title: 'Titel',
      username: 'Nutzername',
      feedbackUsername: 'Feedback Nutzername',
      firstName: 'Vorname',
      lastName: 'Nachname',
      password: 'Passwort',
      feedbackPassword: 'Feedback Passwort',
      repeatPassword: 'Passwort widerholen',
      goToLogin: 'Zum Login gehen',
      login: 'Einloggen',
      city: 'Stadt',
      name: 'Name',
      street: 'Strasse',
      postalCode: 'Postleitzahl',
      enterEmail: 'E-Mail eingeben',
      lookEmail: 'E-Mail ansehen',
      changePassword: 'Passwort ändern',
      createPassword: 'Erstellen ändern',
      send: 'Senden',
      create: 'Erstellen',
      save: 'Speichern',
      edit: 'Bearbeiten',
      delete: 'Löschen',
      deleteOsce: 'OSCE löschen',
      freezeOsce: 'OSCE einfrieren',
      unFreezeOsce: 'OSCE entsperren',
      osceFrozen:
        'Dieser OSCE ist gesperrt. Bitte kontaktieren sie den Administrator bei Änderungen.',
      cancel: 'Abbrechen',
      success: 'Erfolg',
      signUp: 'Anmelden',
      forgotPassword: 'Passwort vergessen?',
      resetPassword: 'Passwort zurücksetzen',
      insert: 'Bitte ausfüllen',
      checkboxGroup: 'Bitte prüfen',
      incorrect: 'Falsche Anmeldeinformationen',
      unEqualPasswords: 'Die Passwörter müssen gleich sein',
      birthday: 'Geburtsdatum',
      gender: 'Geschlecht',
      MALE: 'Männlich',
      FEMALE: 'Weiblich',
      UNKNOWN: 'Unbekannt',
      weight: 'Gewicht',
      height: 'Grösse',
      nationality: 'Nationalität',
      maritalStatus: 'Zivilstand',
      UNMARRIED: 'ledig',
      MARRIED: 'verheiratet',
      CIVIL_UNION: 'eingetragene Partnerschaft',
      DIVORCED: 'geschieden',
      WIDOWED: 'verwitwet',
      email: 'E-Mail',
      country: 'Land',
      mobile: 'Mobiltelefon',
      telephone: 'Telefon',
      profession: 'Beruf',
      workPermission: 'Arbeitserlaubnis',
      socialInsurance: 'Sozialversicherungsnummer',
      languages: 'Sprachen',
      addLanguage: 'Sprache hinzufügen',
      IBAN: 'IBAN',
      owner: 'Inhaber',
      bank: 'Bank',
      BIC: 'BIC',
      rights: 'Berechtigungen',
      add: 'Hinzufügen',
      change: 'Veränderung',
      addPhoto: 'Foto hinzufügen',
      changePhoto: 'Foto ändern',
      remove: 'Löschen',
      skillName: 'Fertigkeit Name',
      maxStudentsNumber: 'Max Studenten',
      studyYear: 'Studienjahr',
      roleDesignation: 'Name',
      acronym: 'Abkürzung',
      roleType: 'Rollentyp',
      skill: 'Skill',
      absoluteNumberOfBackupSPs: 'Anzahl Backup-SPs',
      statisticFactor: 'Statistischer Faktor',
      onlyPositiveNumber: 'Nur die positiven Zahlenwerte',
      WEIGHT: 'Gewicht',
      HEIGHT: 'Grösse',
      AGE: 'Alter',
      BMI: 'BMI',
      OVER: 'auf besserem Niveau als',
      LOWER: 'auf niedrigerem Niveau als',
      EQUAL: 'auf Niveau',
      NOT_EQUAL: 'nicht auf Niveau',
      is: 'ist',
      isnt: 'ist nicht',
      has: 'hat',
      hasnt: 'hat nicht',
      only6OrCriterias:
        "Entschuldigung, aber Sie können nur 6 'ODER' Kriterien hinzufügen",
      search: 'Suche',
      section: 'Abschnitt',
      question: 'Frage',
      comment: 'Kommentare',
      criteria: 'Kriterien',
      value: 'Wert',
      count: 'Anzahl',
      comments: 'Bemerkungen',
      option: 'Möglichkeit',

      OSCEName: 'OSCE Name',
      OSCECreationType: 'OSCE Erstellungsart',
      repetition: 'Wiederholung',
      isFormative: 'formativer OSCE',
      NumberOfStudents: 'Anzahl Studierende',
      NumberOfCircuits: 'Anzahl Parcours',
      NumberOfRooms: 'Anzahl Räume',

      osceDate: 'Starttag',
      startTime: 'Startzeit',
      endTime: 'Max. Endzeit',
      stationDuration: 'Stationsdauer, min',

      shortBreak: 'Kurze Pausenzeit, min',
      lunchBreak: 'Mittagspause, min',
      rotationBreak: 'Rotationspause, min',
      coffeeBreak: 'Kaffeepause, min',
      spChangeover: 'Kurze Pause mit SP-Umschaltung, min',
      maxTimeUntilLunchBreak: 'Max. Zeit bis zur Mittagspause, min',
      maxTimeUntilLongBreak: 'Max. Zeit bis zur Kaffeepause, min',
      encryptionKey: 'Verschlüsselungsschlüssel',

      addManually: 'SPs manuell hinzufügen',
      addAllSP: 'Alle aktiven SP hinzufügen',
      simulatedPatient: 'Simulationsperson',

      studentName: 'Studierperson',
      selectStudent: 'Studierperson auswählen',
      swapStudent: 'Studierperson austauschen',
      spName: 'Simulationsperson',
      selectSP: 'Simulationsperson auswählen',
      swapSP: 'Simulationsperson austauschen',
      swap: 'Austauschen',
      examinerName: 'Examinator',
      selectExaminer: 'Examinator auswählen',
      notAssigned: 'Nicht zugewiesen',
      assignStartTime: 'Startzeit',
      assignEndTime: 'Endzeit',
      notFound: 'Nicht gefunden',
      chooseProperty: 'Eigenschaft wählen',
      chooseOperation: 'Operator wählen',
      chooseQuestionnaire: 'Frage wählen',
      chooseOptions: 'Optionen wählen',
      chooseGender: 'Geschlecht wählen',
      chooseLanguage: 'Sprache wählen',
      chooseMaritalStatus: 'Zivilstand wählen',
      chooseLevel: 'Level wählen',
      chooseWorkPermission: 'Arbeitserlaubnis wählen',
      chooseNationality: 'Nationalität wählen',
      chooseProfession: 'Beruf wählen',
      chooseType: 'Typ wählen',

      SIM_PAT: 'Simulationsperson',
      SUPERNUMERARY: 'Statist',
      MATERIAL: 'Matrial',

      RATIO: 'Verhältnis',
      NONE: 'Keiner',

      answer: 'Antwortete mit',
      noanswer: 'Nicht beantwortet mit',

      failedItems: 'Teilerfüllte Items',
      allItems: 'Alle Items',
      feedback: 'Feedback',
      audio: 'Gesprochenes Feedback',
      downloadFailed: 'Download',
      downloadAll: 'Download',
      feedbackDepotHost: 'Feedback Server',
      feedbackDepotPassword: 'Feedback Server Passwort',
      deployDepotHost: 'Deploy Server',
      deployDepotPassword: 'Deploy Server Passwort',
      resultsDepotHost: 'Resultate Server',
      resultsDepotPassword: 'Resultate Server Passwort',
    },
    patient: {
      print: 'Drucken',
      printFees: 'Honorarblatt',
      showDeletedSP: 'Zeige gelöschte SP',
      showInactiveSP: 'Zeige inaktive SP',
      patientProfile: 'Stammdaten',
      questionnaire: 'Fragebogen',
      personalInformation: 'Persönliche Angaben',
      contactDetails: 'Kontakt',
      professionalDetails: 'Beruf',
      billingDetails: 'Bankverbindung',
      permission: 'Bewilligung',
      deleted: 'Gelöscht',
      active: 'Aktiviert',
      inactive: 'Deaktiviert',
      showComments: 'Anmerkungen anzeigen',
      questionsAndAnswers: 'Fragen',
      sections: 'Abschnitte',
      noChangeAnswer: 'Antwort kann nicht geändert werden',
      noComment: 'Keine Kommentare.',
      addSP: 'SP hinzufügen',
      filtered: 'Dargestellt',
      of: 'von',
    },
    patientsColumns: {
      name: 'Name',
      firstName: 'Vorname',
      email: 'E-Mail',
      gender: 'Geschlecht',
      town: 'Stadt',
      age: 'Alter',
    },
    studentsColumns: {
      name: 'Name',
      firstName: 'Vorname',
      email: 'E-Mail',
      attendance: 'Teilnahme',
      import: 'Import',
    },
    professionColumns: {
      name: 'Beruf',
      addBtn: 'Beruf hinzufügen',
    },
    sectionColumns: {
      section: 'Abschnitt',
      addBtn: 'Abschnitt hinzufügen',
    },
    sections: {
      add: 'Frage hinzufügen',
      edit: 'Frage bearbeiten',
      question: 'Frage',
      answer: 'Antworten',
      answerType: 'Fragentyp',
      addAnswer: 'Antworten hinzufügen',
      chooseAnswer: 'Antwort auswählen',
      open: 'Offen',
      yesNo: 'Ja / Nein',
      single: 'Einzeln',
      multiply: 'Mehrfach',
      yes: 'Ja',
      no: 'Nein',
      placeholderSection: 'Abschnitt wählen',
      atLeastOneAnswer:
        'Bitte geben Sie eine Antwort ein oder löschen Sie dieses Feld',
      typeQuestion: 'Textfrage',
    },
    examinerColumns: {
      name: 'Name',
      firstName: 'Vorname',
      title: 'Titel',
    },
    osceColumns: {
      osces: 'OSCEs',
      sps: 'SPs',
      stationNumber: 'Anzahl der Stationen',
      dates: 'Daten',
      autoOrManual: 'Auto / Manuell',
    },
    patients_sidebar: {
      simulatedPatients: 'Simulationspersonen',
      professions: 'Berufe',
      questionnaire: 'Fragebogen',
    },
    placeholder: {
      selectGender: 'Geschlecht wählen',
      selectDiscipline: 'Disziplin wählen',
      academicTitle: 'f.e. Prof. Dr. Med',
      selectClinic: 'Klinik wählen',
    },
    examiners_sidebar: {
      examiners: 'Examinatoren',
      clinics: 'Kliniken',
    },
    cards: {
      personalInformation: 'Persönliche Angaben',
      clinicInformation: 'Klinik-Informationen',
      secretary: 'Sekretariat',
      generalInformation: 'Allgemeine Informationen',
      OSCEParticipation: 'OSCE-Beteiligung',
      storageCredentials: 'Server-Informationen (SFTP)',
      feedbackCredentials: 'Studierenden-Feedback Server-Informationen (SFTP)',
      iOsceSettings: 'iOSCE Einstellungen',
      osceName: 'OSCE Name',
      feedbackDepot:
        'Studierenden-Feedback Server-Informationen (DepotCredentials)',
      deployDepot: 'Deploy Server-Informationen (DepotCredentials)',
      resultsDepot: 'Resultate Server-Informationen (DepotCredentials)',
    },
    role_sidebar: {
      roleDefinition: 'Rollendefinitionen',
      discipline: 'Disziplinen & Fertigkeiten',
    },
    roleDefinitionColumns: {
      skill: 'Fertigkeit',
      availableRoles: 'Verfügbare Rollen',
      addRole: 'Rolle hinzufügen',
      roleInformation: 'Rolleninformationen',
    },
    role: {
      addTargetSystem: 'Zielsystem hinzufügen',
      addSection: 'Abschnitt hinzufügen',
      addQuestion: 'Item hinzufügen',
      editQuestion: 'Abschnitt hinzufügen',
      studentsExits: '{created} erstellt, {updated} aktualisiert',
      editSection: 'Abschnitt bearbeiten',
      addCriteria: 'Kriterium hinzufügen',
      addOption: 'Option hinzufügen',
      POPOVER: 'Popover',
      SLIDER: 'Schieberegler',
      TOGGLE_TWO: 'Umschalten 2',
      TOGGLE_THREE: 'Umschalten 3',
      checklistQrCode: 'Checkliste QR code',
      regressionItem: 'Ist Regressionselement',
      criteria: 'Kriterien',
      options: 'Optionen',
    },
    checklistColumns: {
      section: 'Abschnitt',
      description: 'Beschreibung',
      weight: 'Gewichtung in %',
    },
    subChecklist: {
      questionCriterias: 'Items & Kriterien',
      options: 'Optionen',
      values: 'Werte',
      criteriaCount: 'Kriterienanzahl',
    },
    studentsOSCEColumns: {
      studentId: 'Matrikelnummer',
      name: 'Name',
      firstName: 'Vorname',
      email: 'E-Mail',
    },
    examinersOSCEColumns: {
      osce: 'OSCEs',
      startDate: 'Anfang',
      endDate: 'Ende',
      semester: 'Semester',
    },
    disciplineColumns: {
      discipline: 'Disziplin',
      actions: 'Aktionen',
      addBtn: 'Disziplin hinzufügen',
      addSkill: 'Fertigkeit hinzufügen',
      editSkill: 'Fertigkeit bearbeiten',
    },
    skillInDisciplineColumns: {
      skill: 'Fertigkeit',
      maxNumber: 'Max Studierende',
      studyYear: 'Studienjahr',
    },
    systemAdministration: {
      baseData: 'Grunddaten',
      resources: 'Ressourcen',
      rooms: 'Räume',
      semesters: 'Semester',
      OSCEManager: 'OSCE Manager',
      administrators: 'Benutzer',
      activityLog: 'Aktivitätsprotokoll',
      studentsDetails: 'Studierende',
      students: 'Studierende',
      activate: 'Aktivieren',
      deactivate: 'Deaktivieren',
      catalogs: 'Kataloge',
    },
    osceAdministration: {
      osceData: 'OSCE Daten',
      settings: 'Einstellungen',
      semesters: 'Semester',
      osces: 'OSCEs',
      costAccounting: 'Abrechnung',
      outputs: 'Exports',
      spSelection: 'SP Auswahl',
      osceSettings: 'OSCE Einstellungen',
      basicData: 'Grundinformationen',
      timeData: 'Zeitdaten',
      MANUAL: 'Manuell',
      AUTO: 'Auto',
      osceTypeTitle:
        'Möchten Sie die Erstellungsart ändern? Alle bereits erfassten Daten werden gelöscht!',
    },
    nationalityColumns: {
      nationality: 'Nationalitäten',
      name: 'Nationalität',
      addBtn: 'Nationalität hinzufügen',
    },
    administratorsColumns: {
      ID: 'ID',
      email: 'E-Mail',
      firstName: 'Vorname',
      lastName: 'Nachname',
      roles: 'Rollen',
      state: 'Status',
      activate: 'Aktivieren Sie',
      deactivate: 'Deaktivieren',
    },
    questionnaireAnswers: {
      all: 'Alles',
      answered: 'Beantwortet',
      unanswered: 'Unbeantwortet',
      answer: 'Antwortete mit',
      noanswer: 'Nicht beantwortet mit',
      deleteAnswer: 'Antwort löschen',
    },
    catalogsColumns: {
      catalogs: 'Kataloge',
    },
    clinicExaminersByOsceColumns: {
      examiner: 'Examinator',
    },
    catalogs: {
      updateAll: 'Alle aktualisieren',
      update: 'Aktualisieren',
    },
    modalForm: {
      addNewAdministrator: 'Füge Sie einen neuen Benutzer hinzu',
      editAdministrator: 'Benutzer bearbeiten',
      addNewExaminer: 'Examinator hinzufügen',
      addNewClinic: 'Klinik hinzufügen',
      osce: 'OSCE',
      addNewSection: 'Abschnitt hinzufügen',
      addNewSP: 'Simulationsperson hinzufügen',
      addNewRoom: 'Raum hinzufügen',
      addNewSemester: 'Semester hinzufügen',
      addNewRole: 'Rolle hinzufügen',
      addNewOsce: 'OSCE hinzufügen',
      allClinics: 'Alle Kliniken',
    },
    rights: {
      ROLE_SUPER_ADMIN: 'Systemadministration',
      ROLE_S_PATIENT_ADMIN: 'Simulationspersonen',
      ROLE_EXAM_ADMIN: 'Prüfer',
      ROLE_OSCE_ADMIN: 'OSCE-Verwaltung',
      ROLE_ROLES_ADMIN: 'Rollen',
      ROLE_ADMIN: 'Administration',
    },
    error: {
      fillRequired: 'Bitte füllen Sie alle erforderlichen Felder aus',
      insert: 'Bitte ausfüllen',
      notValidPhoneNumber: 'Keine gültige Telefonnummer',
      notValidAHVNumber: 'Keine gültige AHV-Nummer',
      onlyPositiveNumber: 'Nur die positiven Zahlenwerte',
      validatorOsceName:
        'OSCE Name darf nur Buchstaben, Zahlen, -, _ und Leerzeichen enthalten',
      notValidPostalCode: 'Keine gültige Postleitzahl',
      email: 'keine gültige E-Mail',
      iban: 'keine gültige IBAN',
      youCanUpload: 'Sie können nur hochladen',
      imgMustBeSmaller: 'Das Bild muss kleiner als',
      cantUpload: 'Kann nicht hochgeladen werden',
      required: 'dieses Feld ist zwingend',
      invalidLink: 'Ungültiger Link',
      failed: 'Gescheitert',
      success: 'Erfolgreich',
      noOptionChosen:
        'Antwort wurde nicht gespeichert, wählen Sie mindestens eine Option.',
      missed: 'Verpasst',
      '1062+': 'Es gibt',
      1451: 'Kann nicht gelöscht werden, weil dies verwendet wird.',
      1062: 'Dieses Objekt existiert bereits.',
      all: 'Etwas ist schief gelaufen',
      invalidOptionsCount: 'Ungültige Anzahl von Optionen',
      BicNumber: 'BIC ist nicht gültig',
      tooLong:
        'Der Text ist zu lang. Bitte etwas kürzer machen und es erneut versuchen.',
    },
    confirm: {
      areYouSureDelete: 'Sind Sie sicher, dass Sie löschen möchten?',
    },
    roleCriteria: {
      criteria: 'Kriterien',
      or: 'ODER',
      GENDER: 'Geschlecht',
      LANGUAGE: 'Sprachen',
      MARITAL_STATUS: 'Zivilstand',
      NATIONALITY: 'Nationalität',
      NUMERIC: 'Numerischer Filter',
      PROFESSION: 'Beruf',
      WORK_PERMISSION: 'Arbeitsbewilligung',
      QUESTIONNAIRE: 'Fragebogen',
      WEIGHT: 'Gewicht',
      HEIGHT: 'Grösse',
      AGE: 'Alter',
      BMI: 'BMI',
    },
    iOsceSettings: {
      backUpPeriod: 'Sicherungszeitraum',
      timeUnit: 'Zeiteinheit',
      symmetricKey: 'Symmetrischer Schlüssel',
      settingPassword: 'Passwort Einstellungen',
      screenSaverTime: 'Bildschirmschonerzeit (min.)',
      webServicePath: 'Webservice URL',
      registerDevicePath: 'Registrations URL',
      screenSaverText: 'Bildschirmschonertext',
    },
    notFound: {
      notExist: 'Die von Ihnen gesuchte Seite existiert nicht',
      returnHomePage: 'Zurück zur Startseite',
    },
    output: {
      export: 'OSCE Daten exportieren',
      exportIOSCE: 'iOSCE exportieren',
      exportedfiles: 'Exportierte Dateien',
      success: 'Mail wurde gesendet',
      sendInvitations: 'Einladungen verschicken',
      printSchedules: 'Laufpläne drucken',
      sendMail: 'E-Mail senden',
      printCopies: 'Kopien drucken',
      exportQRCode: 'QR-Code exportieren',
      students: 'Studierende',
      examiners: 'Examinatoren',
      simulatedPatients: 'Simulationspersonen',
      successMail: 'Einladungen versendet',
      successExport: 'Export erfolgreich',
      download: 'Herunterladen',
      showQr: 'QR Code',
      openInPusher: 'Pusher App',
      template: 'Vorlage',
      areYouSureSendEmail:
        'Sind Sie sicher, dass Sie die E-Mail senden möchten?',
    },
    longText: {
      xlsInformation:
        'Die zu importierende Excel-Datei der teilnehmenden Studierenden muss folgende' +
        ' Spaltenüberschriften haben: Matrikkelnummer, Nachame, Vorname, E-Mail und Geschlecht.' +
        ' Die Spalten Strasse, PLZ und Ort sind fakultativ.',
    },
    spSelectionsColumns: {
      accepted: 'Akzeptiert',
      name: 'Name',
      assignTo: 'Zuweisen an',
    },
    spSelection: {
      autoAssign: 'Zuweisen',
      addManually: 'SPs manuell hinzufügen',
      clearSelection: 'Auswahl aufheben',
      export: 'Exportieren',
      hideUnavailableSPs: 'Nicht verfügbare SPs ausblenden',
      assignSPsforHalfDays: 'SPs für halbe Tage einsetzen',
    },
    preview: {
      logicalBreak: 'Logische Pause',
      rotationBreak: 'Rotationspause',
      lunchBreak: 'Mittagspause',
      coffeeBreak: 'Kaffeepause',
      circuit: 'Parcours',
      endTime: 'Endzeit',
      rotation: 'Rotation',
      generatePreview: 'Vorschau erzeugen',
      day: 'Tag',
      of: 'von',
    },
    circuit: {
      circuit: 'Parcours',
      alreadyAssigned:
        'Dieser Raum ist bereits einem anderen Posten zugewiesen. Möchten sie die Zuweisung ändern?',
      date: 'Datum',
      noData: 'keine Daten',
      dayStartTime: 'Startzeit',
      dayEndTime: 'Endzeit',
      dayCoffeeBreak: 'Kaffeepause',
      dayLunchBreak: 'Mittagspause',
      generate: 'Zeitplan erstellen',
      calculate: 'Berechnen',
      REGULAR: 'Regulär',
      BREAK: 'Pause',
      PREPARATION: 'Vorbereitung',
      DOUBLE: 'Doppelt',
      DOUBLESP: 'Doppelt SP',
      SP_PAUSED: 'SP Paused',
      PAUSED_STUDENT: 'Studentenpause',
      earlierLunch: 'Start früher',
      preponeFirst: 'Erste Rotation auf vorherigen Tag schieben',
      laterLunch: 'Mittagspause später',
      postponeLast: 'Letzte Rotation auf nächsten Tag schieben',
      ROTATION_BREAK: 'Rotationspause',
      LUNCH_BREAK: 'Mittagspause',
      COFFEE_BREAK: 'Kaffeepause',
      breakSooner: 'Pause früher',
      breakLater: 'Pause später',
      breakDuration: 'Pausendauer',
      areYouSureClearAllCircuit:
        'Sind Sie sicher, dass Sie alles löschen möchten?\n' +
        'Alle bisher gemachten Zuweisungen werden gelöscht. Dies ist eine irreversible Aktion.',
      splitOsceDay:
        'Sind Sie sicher, dass Sie diesen OSCE-Tag aufteilen möchten?\n Dies ist eine irreversible Aktion.',
      newStation: 'Neuer Posten',
      type: 'Typ',
      addCircuit: 'Parcours hinzufügen',
      addDay: 'Tag hinzufügen',
      day: 'Tag',
    },
    schedule: {
      studentsAssignment: 'Studenten zuweisen',
      spAssignment: 'SPs zuweisen',
      export: 'Einsatzplan exportieren',
      assignmentInformation:
        'Diese Aktion überschreibt eine bereits' +
        'bestehende Zuordnung unwiderruflich!',
      alphabetically: 'Alphabetisch',
      random: 'Zufällige Reihenfolge',
      updateSP: 'SPs aktualisieren',
      circuit: 'Parcours',
      spPaused: 'SPs in Pause',
      areYouSureClearAllSchedule:
        'Are you sure you want to clear all?\n' +
        'It will delete all your previous settings of schedules.\n' +
        'There will be no possibility of undoing the clearing.',
      students: 'Studierende',
      slotsSP: 'SP’s mit Slots',
      rotationSp: 'SP’s als Rotationsplan',
      breakDuration: 'Pausendauer',
    },
    import: {
      noFilesImported: 'Es wurden noch keine Resultate importiert.',
      downloadResultFiles: 'Ergebnisdateien herunterladen',
      newExport: 'Neuer Export',
    },
    costAccountingColumns: {
      firstName: 'Vorname',
      lastName: 'Nachname',
    },
    activityLogColumns: {
      time: 'Zeitpunkt',
      user: 'Benutzer',
      action: 'Aktion',
    },
    tooltips: {
      sectionsColumns: 'Klicken Sie auf den Abschnitt um die Items anzuzeigen',
      questionnaireSections:
        'Sie können Abschnitte hinzufügen. Sie können diese mit der Maus verschieben',
      iOscePreview:
        'Scannen sie den QR Code auf ihrem iPad um eine Vorschau anzuzeigen',
      checklistWeight:
        'Die Summe der Gewichtung der Abschnitte muss 100% betragen',
      criteriaForSp:
        'Wählen Sie Kriterien für die Auswahl der Simualtionspersonen',
      spSelectionOsce:
        'Nach dem Zuweisen können die Simulationspersonen verschoben werden',
      printSchedules:
        'Wählen Sie den OSCE für den der Zeitplan gedruckt werden soll',
      costAccounting:
        'Wählen Sie die Simulationspersonen für die ein Honorarblatt erstellt werden soll',
      scissor: 'Teilen Sie den OSCE Tag in zwei Sequenzen',
      rotationCount: 'Anzahl Rotationen',
      freezeOsce:
        'Einmal gesperrt, kann der OSCE bis zur erneuten Freigabe nicht mehr geändert werden',
      generatePreview:
        'Erstellen Sie eine OSCE Vorschau um alle zeitlichen Details zu sehen',
      roleVersion: 'Wechseln Sie zu vorherigen Versionen der Rolle',
      isFormative:
        'Die Studierenden werden in der iOSCE Applikation zugewiesen',
      materialRole:
        'Vorbereitungsteil. Bitte weisen Sie eine Rolle vom Typ Material zu',
      checklistRole:
        'Bewertungsteil. Bitte weisen Sie eine Rolle mit einer Checkliste zu',
    },
  }),
  // formats: object,
  // messages: object,
  // textComponent: any,
  locale: 'de',
};
