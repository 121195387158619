import React, { PureComponent } from 'react';
import { Popover } from 'antd';
import { BlockPicker } from 'react-color';

export default class ColorPicker extends PureComponent {
  get renderSectors() {
    let deg = 75;
    return ['orange', 'pink', 'yellow', 'blue', 'dark-green', 'lime'].map(
      (className, i) => {
        deg += 60;
        return (
          <div
            key={i}
            className={className}
            style={{ transform: `rotate(${deg}deg) skew(40deg)` }}
          />
        );
      },
    );
  }

  render() {
    const { handleChange, hexColor } = this.props;
    return (
      <Popover
        placement="bottom"
        content={
          <BlockPicker
            onChange={handleChange}
            color={hexColor}
            triangle="hide"
            colors={[
              '#fff100',
              '#ff8c00',
              '#e81123',
              '#ec008c',
              '#68217a',
              '#00188f',
              '#00bcf2',
              '#00b294',
              '#009e49',
              '#bad80a',
            ]}
          />
        }
        trigger="click"
        onClick={e => e.stopPropagation()}
      >
        <div className="color-picker pointer">
          <div className="wrapper">{this.renderSectors}</div>
        </div>
      </Popover>
    );
  }
}
