import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, Col, Spin, Button, Checkbox, message } from 'antd';
import { withRouter } from 'react-router';
/* Services */
import i18n from 'services/i18n';
import api from 'services/api';
/* Actions */
import {
  getAllSelectedSPs,
  removeSpFromSemester,
  getSelectedOSCEDays,
  selectOSCEDays,
  autoAssingSP,
  assignSpForHalfDay,
  selectOSCERole,
  clearSelection,
  assignSpTo,
  removeSpFromRole,
  assignSpToMultipleDays,
  changeRoleCriteria,
  addSpToSemester,
  addAllSpToSemester,
} from 'actions/spSelection';
// Utils
import { downloadFile } from 'utils/downloadFile';
/* Components */
import Search from 'components/Search';
import Patients from './Patients';
import Osce from './Osce';
import Criterias from './Criterias';
import ModalManually from './ModalManually';

const mapStateToProps = state => ({
  selectedRole: state.spSelection.selectedRole,
  selectedSps: state.spSelection.patients,
  osceDays: state.spSelection.osceDays,
  loading: state.spSelection.loading,
});

const mapDispatchToProps = {
  getAllSelectedSPs,
  removeSpFromSemester,
  getSelectedOSCEDays,
  selectOSCEDays,
  autoAssingSP,
  assignSpForHalfDay,
  selectOSCERole,
  clearSelection,
  assignSpTo,
  removeSpFromRole,
  assignSpToMultipleDays,
  changeRoleCriteria,
  addSpToSemester,
  addAllSpToSemester,
};

@i18n('spSelection')
@withRouter
@connect(
  mapStateToProps,
  mapDispatchToProps,
)
export default class SpSelection extends PureComponent {
  static propTypes = {
    selectedRole: PropTypes.object.isRequired,
    selectedSps: PropTypes.array.isRequired,
    osceDays: PropTypes.array.isRequired,
    loading: PropTypes.bool,
  };

  static defaultProps = {
    loading: false,
  };

  state = {
    search: '',
    total: 0,
    hideUnavailable: true,
    selectedOSCEDays: [],
    showModal: false,
    selectedCriteria: [],
  };

  componentDidMount() {
    this.handleUpdateSelectedSPs();
    this.handleUpdateOSCEDays();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.match.params.semesterId !== prevProps.match.params.semesterId
    ) {
      this.setState({ hideUnavailable: true, selectedOSCEDays: [] }, () => {
        this.handleUpdateSelectedSPs();
        this.handleUpdateOSCEDays();
      });
    }
  }

  handleUpdateSelectedSPs = () => {
    const { semesterId } = this.props.match.params;
    const { search, hideUnavailable } = this.state;
    this.props.clearSelection();
    this.props
      .getAllSelectedSPs(semesterId, {
        search,
        hideUnavailable,
      })
      .then(res => this.setState({ total: res.pagination.total }))
      .catch(err => message.error(this.props.translate('all', 'error')));
  };

  handleUpdateOSCEDays = () => {
    const { semesterId } = this.props.match.params;
    this.props
      .getSelectedOSCEDays(semesterId)
      .catch(err => message.error(this.props.translate('all', 'error')));
  };

  handleSearch = search => {
    this.setState({ search }, this.handleUpdateSelectedSPs);
  };

  toggleAvailableSps = e =>
    this.setState(
      { hideUnavailable: e.target.checked },
      this.handleUpdateSelectedSPs,
    );

  handleAddSPToSemester = values => {
    const { semesterId } = this.props.match.params;
    this.props
      .addSpToSemester(values.spId, semesterId)
      .then(() => {
        this.handleUpdateSelectedSPs();
        this.handleUpdateOSCEDays();
      })
      .catch(err => message.error(this.props.translate('all', 'error')));
  };

  handleAddAllSPsToSemester = () => {
    const { semesterId } = this.props.match.params;
    this.props
      .addAllSpToSemester(semesterId)
      .then(() => {
        this.handleUpdateSelectedSPs();
        this.handleUpdateOSCEDays();
      })
      .catch(err => message.error(this.props.translate('all', 'error')));
  };

  handleRemoveSP = id => {
    const { semesterId } = this.props.match.params;
    this.props
      .removeSpFromSemester(id, semesterId)
      .catch(err => message.error(this.props.translate('all', 'error')));
  };

  handleRemoveSPFromRole = (idRole, idSelectedSp, postId) => {
    this.props
      .removeSpFromRole({ idRole, idSelectedSp, postId })
      .then(() => {
        this.handleUpdateSelectedSPs();
        this.handleUpdateOSCEDays();
      })
      .catch(err => {
        if (err.response.status === 400) {
          message.error(err.response.data.message);
        } else {
          message.error(this.props.translate('all', 'error'));
        }
      });
  };

  handleMoveSP = data => {
    const {
      idRole,
      postId,
      acronym,
      idSelectedSp,
      prevIdRole,
      prevPostId,
    } = data;
    // Remove SP from previous role
    const promise = this.props.removeSpFromRole({
      idRole: prevIdRole,
      postId: prevPostId,
      idSelectedSp,
    });
    // Select new role so we can assign SP to it
    promise.then(() => {
      this.handleSelectRole(idRole, postId, acronym);
      // Assign SP to new role
      this.handleAssignTo(idSelectedSp);
    });
  };

  handleSelectOSCEDay = osceDay => {
    let selectedOSCEDays = [...this.state.selectedOSCEDays];

    // If OSCE day is already selected, deselect it
    if (selectedOSCEDays.find(day => day.id === osceDay.id)) {
      selectedOSCEDays = selectedOSCEDays.filter(day => day.id !== osceDay.id);
    } else {
      selectedOSCEDays = [...selectedOSCEDays, osceDay];
    }

    this.setState({ selectedOSCEDays });
  };

  autoAssign = () => {
    const { selectedOSCEDays } = this.state;
    const data = selectedOSCEDays.map(({ assignSpForHalfDay, id }) => ({
      assignForHalfDay: assignSpForHalfDay,
      osceDayId: id,
    }));

    this.props
      .autoAssingSP(data)
      .then(() => {
        this.handleUpdateSelectedSPs();
        this.handleUpdateOSCEDays();
      })
      .catch(err => message.error(this.props.translate('all', 'error')));
  };

  handleAssignTo = id => {
    if (!this.props.selectedRole.id) {
      message.warning('First you have to choose one post');
      return;
    }

    const data = {
      idRole: this.props.selectedRole.id,
      postId: this.props.selectedRole.postId,
      idSelectedSp: id,
    };

    this.props
      .assignSpTo(data)
      .then(res => {
        const { match } = this.props;
        const { selectedCriteria } = this.state;
        const selectedRole = {
          semesterId: match.params.semesterId,
          id: this.props.selectedRole.id,
          postId: this.props.selectedRole.postId,
          acronym: this.props.selectedRole.acronym,
          osceDayId: this.props.selectedRole.osceDayId,
          criteria: selectedCriteria,
        };

        this.props.changeRoleCriteria(selectedRole);
        this.handleUpdateOSCEDays();
      })
      .catch(err => {
        if (err.response.status === 400) {
          const errMsg = err.response.data.message;
          message.error(errMsg);
        } else {
          message.error(this.props.translate('all', 'error'));
        }
      });
  };

  handleAssignSPToMultipleDays = (selectedSpId, data) => {
    this.props
      .assignSpToMultipleDays(selectedSpId, data)
      .then(() => {
        this.handleUpdateSelectedSPs();
        this.handleUpdateOSCEDays();
      })
      .catch(err => message.error(this.props.translate('all', 'error')));
  };

  handleAssignForHalfDay = (osceDayId, e) => {
    const assignSpForHalfDay = e.target.checked;
    this.props
      .assignSpForHalfDay(osceDayId, assignSpForHalfDay)
      .catch(err => message.error(this.props.translate('all', 'error')));
  };

  handleSelectRole = (roleId, postId, acronym) => {
    const { osceDays, match } = this.props;
    const selectedRole = {
      semesterId: match.params.semesterId,
      id: roleId,
      postId,
      acronym,
      osceDayId: null,
      criteria: [],
    };

    // Find OSCE day id
    osceDays.forEach(day => {
      day.sequenceResponseList.forEach(sequence => {
        const target = sequence.roleResponses.find(
          item => item.id === Number(roleId) && item.postId === Number(postId),
        );

        if (target) {
          selectedRole.osceDayId = day.id;
          if (target.spRoleCriterionResponseList) {
            selectedRole.criteria = target.spRoleCriterionResponseList;
            this.setState({
              selectedCriteria: target.spRoleCriterionResponseList,
            });
          }
        }
      });
    });

    this.props.selectOSCERole(selectedRole);
  };

  handleChangeCriteria = selectedRole => {
    this.setState({ selectedCriteria: selectedRole.criteria }, () =>
      this.props.changeRoleCriteria(selectedRole),
    );
  };

  handleClearSelection = () => {
    this.props.clearSelection();
    this.handleUpdateSelectedSPs();
  };

  handleExport = () => {
    api
      .downloadSpXLSX(this.props.match.params.semesterId)
      .then(res =>
        downloadFile(
          res.data,
          'sp_availability.xlsx',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        ),
      )
      .catch(err => message.error(this.props.translate('all', 'error')));
  };

  toggleModalManually = showModal => this.setState({ showModal });

  render() {
    let isAutoAssingDisable = true;

    // If OSCE days selected
    if (this.state.selectedOSCEDays.length !== 0) {
      isAutoAssingDisable = false;
    }

    return (
      <Spin spinning={this.props.loading}>
        <div className="sp-selection">
          <div className="table-panel">
            <Search
              value={this.state.search}
              count={this.state.total}
              onSearch={this.handleSearch}
            />
            <Button
              disabled={isAutoAssingDisable}
              className="table-panel_btn"
              onClick={this.autoAssign}
            >
              {this.props.translate('autoAssign')}
            </Button>
            <Button
              className="table-panel_btn"
              onClick={() => this.toggleModalManually(true)}
            >
              {this.props.translate('addManually')}
            </Button>
            <Button
              className="table-panel_btn"
              onClick={this.handleClearSelection}
            >
              {this.props.translate('clearSelection')}
            </Button>
            <Button className="table-panel_btn" onClick={this.handleExport}>
              {this.props.translate('export')}
            </Button>
            <Checkbox
              checked={this.state.hideUnavailable}
              onChange={this.toggleAvailableSps}
            >
              {this.props.translate('hideUnavailableSPs')}
            </Checkbox>
            <ModalManually
              visible={this.state.showModal}
              onAddSP={this.handleAddSPToSemester}
              onAddAllSPs={this.handleAddAllSPsToSemester}
              close={() => this.toggleModalManually(false)}
            />
          </div>
          <Row gutter={20}>
            <Col sm={13} xl={12}>
              <Patients
                osceDays={this.props.osceDays}
                selectedSps={this.props.selectedSps}
                selectedRole={this.props.selectedRole}
                onRemoveSp={this.handleRemoveSP}
                onAssignSP={this.handleAssignTo}
                onAssignSPToMultipleDays={this.handleAssignSPToMultipleDays}
              />
              <Criterias
                selectedCriteria={this.state.selectedCriteria}
                selectedRole={this.props.selectedRole}
                onChangeCriteria={this.handleChangeCriteria}
              />
            </Col>
            <Col sm={11} xl={12}>
              <Osce
                osceDays={this.props.osceDays}
                selectedRole={this.props.selectedRole}
                onSelectOSCEDay={this.handleSelectOSCEDay}
                onAssignSpForHalfDay={this.handleAssignForHalfDay}
                onSelectRole={this.handleSelectRole}
                onRemoveSp={this.handleRemoveSPFromRole}
                onDragSp={this.handleMoveSP}
              />
            </Col>
          </Row>
        </div>
      </Spin>
    );
  }
}
