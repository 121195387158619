import React, { PureComponent } from 'react';
import { Popconfirm, Button } from 'antd';
import PropTypes from 'prop-types';

/** Services */
import i18n from 'services/i18n';

/**
 * Component delete button
 * @reactProps {func} translate - function which translate text.
 * @reactProps {bool} disabled
 * @reactProps {func} action - function with action for button
 * @reactProps {string} className - less class
 */
@i18n('confirm')
export default class DeleteButton extends PureComponent {
  static propTypes = {
    translate: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    action: PropTypes.func.isRequired,
    className: PropTypes.string,
    textBtn: PropTypes.string,
  };

  state = {
    visible: false,
  };

  handleVisibleChange = visible => {
    // Because of disable property on Popconfirm doesn't work
    // Prevent showing confirm if button is disabled
    if (this.props.disabled) return;

    if (this.state.visible) {
      this.setState({ visible });
    } else {
      this.setState({ visible });
    }
  };

  render() {
    const { props } = this;
    return (
      <Popconfirm
        placement="bottom"
        visible={this.state.visible}
        onVisibleChange={this.handleVisibleChange}
        title={props.translate('areYouSureDelete')}
        onConfirm={props.action}
      >
        <Button
          className={
            props.className ||
            'table-panel_btn clinic_btn table-panel_btn-right'
          }
          disabled={props.disabled}
        >
          {props.textBtn || this.props.translate('delete', 'form')}
        </Button>
      </Popconfirm>
    );
  }
}
