import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Spin, Form, Input, Button } from 'antd';

export default class AnswerText extends PureComponent {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    answer: PropTypes.string.isRequired,
    onAnswer: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    comment: PropTypes.string.isRequired,
  };

  state = {
    editable: false,
    editedAnswer: '',
  };

  // Close editor after response
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.loading === true && this.props.loading === false) {
      this.closeEditable();
    }
  }

  openEditable = () => this.setState({ editable: true });

  closeEditable = () => this.setState({ editable: false });

  handleAnswerChange = e => this.setState({ editedAnswer: e.target.value });

  onSubmit = e => {
    e.preventDefault();
    this.props.onAnswer(this.state.editedAnswer);
  };

  render() {
    if (this.state.editable) {
      return (
        <Spin spinning={this.props.loading}>
          <Form className="answer-text-editable" onSubmit={this.onSubmit}>
            <Form.Item colon={false}>
              <Input.TextArea
                defaultValue={this.props.answer}
                onChange={this.handleAnswerChange}
                autosize={{ minRows: 3, maxRows: 7 }}
              />
            </Form.Item>
            <div>
              <Button
                shape="circle"
                icon="close"
                size="small"
                onClick={this.closeEditable}
              />
              <Button
                shape="circle"
                icon="check"
                size="small"
                type="primary"
                htmlType="submit"
                className="answer-text-btn-last"
              />
            </div>
          </Form>
        </Spin>
      );
    }
    return (
      <div className="answer-text-container">
        <span>{this.props.answer}</span>
        <span className="answer-btns-hover">
          <Button
            shape="circle"
            icon="edit"
            size="small"
            className="answer-text-btn-last"
            onClick={this.openEditable}
          />
          {this.props.answer ? (
            <Button
              icon="delete"
              size="small"
              className="answer-text-btn-last"
              onClick={this.props.onDelete}
              type="link"
            >
              {this.props.translate('deleteAnswer', 'questionnaireAnswers')}
            </Button>
          ) : null}
        </span>
      </div>
    );
  }
}
