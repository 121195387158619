import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon, Popconfirm } from 'antd';
import i18n from 'services/i18n';

@i18n('form')
export default class ButtonsWithAction extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    record: PropTypes.object,
    children: PropTypes.object,
  };

  onClick = (e, name, options) => {
    e.stopPropagation();
    if (this.props.disabled) return;
    this.props.actions[name]({ ...options });
  };

  render() {
    const { record, translate, disabled } = this.props;
    let actionClassName = 'action-btn';
    if (disabled) {
      actionClassName += ' action-btn-is-disabled';
    }
    return (
      <div className="editable-row-operations">
        {record.editable ? (
          <span>
            <a
              onClick={e => this.onClick(e, 'save', record)}
              className={actionClassName}
            >
              {translate('save')}
            </a>
            <a
              onClick={e => this.onClick(e, 'cancel', record)}
              className={actionClassName}
            >
              {translate('cancel')}
            </a>
          </span>
        ) : (
          <span>
            {this.props.children}
            <a
              className={actionClassName}
              onClick={e => this.onClick(e, 'edit', record)}
            >
              <Icon type="edit" /> {translate('edit')}
            </a>
            {disabled ? (
              <a className={actionClassName}>
                <Icon type="delete" /> {translate('delete')}
              </a>
            ) : (
              <Popconfirm
                title={translate('areYouSureDelete', 'confirm')}
                onClick={e => e.stopPropagation()}
                onCancel={e => e.stopPropagation()}
                onConfirm={e => this.onClick(e, 'remove', record)}
              >
                <a className={actionClassName}>
                  <Icon type="delete" /> {translate('delete')}
                </a>
              </Popconfirm>
            )}
          </span>
        )}
      </div>
    );
  }
}
