import React, { Component } from 'react';
import { get } from 'lodash';

import { GENDER } from 'constants/options';
import EditableLable from 'components/Form/formEditableLabel';
import EditableLabelDropDown from 'components/Form/formEditableLabelDropDown';
import Card from 'components/Form/Card';

import '../style.less';

export default class GeneralInformation extends Component {
  render() {
    const { data } = this.props;
    return (
      <Card from="cards" value="generalInformation" className="card-student">
        <EditableLable
          name="studentId"
          initialValue={get(data, 'studentId')}
          disabled
        />
        <EditableLabelDropDown
          name="gender"
          options={GENDER}
          initialValue={get(data, 'gender')}
        />
        <EditableLable
          name="email"
          type="email"
          initialValue={get(data, 'email')}
        />
      </Card>
    );
  }
}
