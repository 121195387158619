import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { withRouter } from 'react-router';
import cn from 'classnames';

/** UI (Antd) */
import { Select, Popover, Button } from 'antd';

/** Actions */
import { assignExaminer, getScheduleAssignment } from 'actions/schedule';
import { examinersAssignCRUD } from 'actions/cruds';

/** Utils, Config */
import addLoadWithRedux from 'services/decorators/addLoadWithRedux';
import closest from 'services/closest';
import { msgError } from 'services/errorHandler';

/** Component */
import PersonSearch from './PersonSearch';

@addLoadWithRedux({
  i18Name: 'form',
  runtimeReduxNames: ['schedule'],
  mapMethods: { getScheduleAssignment },
})
@withRouter
class ExaminerData extends Component {
  static propsTypes = {
    index: PropTypes.number,
    examiner: PropTypes.object,
    timeSlotId: PropTypes.number,
    circuitRotations: PropTypes.array,
    circuitId: PropTypes.number,
  };

  state = {
    endTime: undefined,
    examiner: '',
    examinerId: null,
    visible: false,
    rotations: [],
  };

  componentDidMount() {
    const { examiner, circuitRotations, timeSlotId } = this.props;
    if (examiner.personId) {
      this.setState({
        examinerId: examiner.personId,
        examiner: `${examiner.firstName} ${examiner.lastName}`,
      }); // eslint-disable-line
    }
    const target = circuitRotations.find(
      item => item.endTime === examiner.endTime,
    );
    if (get(target, 'id')) {
      this.setState({ endTime: target.id }); // eslint-disable-line
    }
    const startTimeIndex = circuitRotations.findIndex(
      item => item.id === timeSlotId,
    );
    const rotations = circuitRotations.slice(startTimeIndex);
    this.setState({ rotations }); // eslint-disable-line
    document.body.addEventListener('click', this.checkElement);
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.checkElement);
  }

  checkElement = e => {
    if (
      closest(e.target, '.ant-popover') === null &&
      closest(e.target, '.ant-select-dropdown') === null
    ) {
      this.setState({ visible: false });
    }
  };

  handleChange = item => {
    if (item) {
      this.setState({ examinerId: item.examinerId, examiner: item.name });
    } else {
      this.setState({ examinerId: null, examiner: '' });
    }
  };

  togglePopover = () => this.setState({ visible: !this.state.visible });

  assignExaminer = async () => {
    try {
      const {
        examiner,
        timeSlotId,
        circuitId,
        scheduleData,
        updateData,
      } = this.props;
      const { data } = await assignExaminer({
        examinerId: this.state.examinerId,
        fromTimeSlotId: timeSlotId,
        postId: examiner.postId,
        toTimeSlotId: this.state.endTime,
      });
      const newScheduleData = { ...scheduleData };
      newScheduleData.osceDays.forEach(day => {
        day.sequences.forEach(sequence => {
          const circuitIndex = sequence.circuits.findIndex(
            item => item.id === circuitId,
          );
          if (circuitIndex > -1) {
            sequence.circuits.splice(circuitIndex, 1, data);
          }
        });
      });
      await updateData(newScheduleData, 'schedule');
      this.setState({ visible: false });
    } catch (e) {
      msgError(e);
    }
  };

  get popUpContent() {
    const { examiner, translate } = this.props;
    return (
      <React.Fragment>
        <div className="wrapper">
          <div className="fields">
            <p>{translate('examinerName')}:</p>
            <div className="fields_item fields_item_search">
              <PersonSearch
                allowClear
                name="examiner"
                action={examinersAssignCRUD}
                onChange={this.handleChange}
                value={this.state.examiner}
                placeholder={translate('selectExaminer')}
                translate={translate}
              />
            </div>
          </div>
          <div className="fields">
            <p>{translate('assignStartTime')}:</p>
            <div className="fields_item">{examiner.startTime}</div>
          </div>
          <div className="fields">
            <p>{translate('assignEndTime')}:</p>
            <div className="fields_item">
              <Select
                style={{ width: '120px' }}
                value={this.state.endTime}
                onChange={value => this.setState({ endTime: value })}
              >
                {this.state.rotations.map(item => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.endTime}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
        <div className="footer-btns">
          <Button type="primary" className="btn" onClick={this.assignExaminer}>
            {translate('save')}
          </Button>
          <Button
            className="btn"
            onClick={() => this.setState({ visible: false })}
          >
            {translate('cancel')}
          </Button>
        </div>
      </React.Fragment>
    );
  }

  render() {
    const { examiner, translate } = this.props;
    return (
      <Popover
        trigger="hover"
        placement="right"
        mouseEnterDelay={0.8}
        content={
          examiner.firstName
            ? `${examiner.firstName} ${examiner.lastName}`
            : translate('notAssigned')
        }
      >
        <Popover
          trigger="click"
          placement="right"
          onClick={this.togglePopover}
          visible={this.state.visible}
          content={this.popUpContent}
        >
          <div className={cn('cell', { disabled: !examiner.personId })}>
            {`E0${examiner.sequenceNumber}`}
            <div>
              {examiner.personId
                ? `${examiner.firstName.charAt(0)}${examiner.lastName.charAt(
                    0,
                  )}`
                : ''}
            </div>
          </div>
        </Popover>
      </Popover>
    );
  }
}

export default ExaminerData;
