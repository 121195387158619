import React, { Component } from 'react';
import { Form, Spin } from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { omit } from 'lodash';
import { bindActionCreators } from 'redux';

import { updateData } from 'actions/updateData';
import handleSubmit from 'services/form';
import { getOsceDefaultProfile } from 'actions/osceAdministration';
import TablePanel from 'components/TablePanel/index';
import i18n from 'services/i18n';
import profileFormDecorator from 'services/decorators/profileFormDecorator';
import { settingsDefaultCRUD } from 'actions/cruds';

import StorageCredentials from '../settingsFields/storageCredentials';
import IOsceSettings from '../settingsFields/iOsceSettings';

import './style.less';
import FeedbackCredentials from '../settingsFields/feedbackCredentials';
import DepotCredentials from '../settingsFields/depotCredentials';

const mapStateToProsp = ({ runtime }) => ({
  data: runtime.osceDefaultData,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ dispatch, updateData, getOsceDefaultProfile }, dispatch);

@i18n('form')
@connect(
  mapStateToProsp,
  mapDispatchToProps,
)
@profileFormDecorator()
export default class OsceSettings extends Component {
  static contextTypes = {
    form: PropTypes.object,
  };

  state = { loading: false };

  componentDidMount() {
    this.props.getOsceDefaultProfile();
  }

  submit = async values => {
    let vals = values;
    if (!vals) {
      vals = await handleSubmit(null, this.context.form);
    }
    return settingsDefaultCRUD.post(omit(vals, 'checkBox'));
  };

  render() {
    return (
      <Spin spinning={this.state.loading}>
        <Form
          onSubmit={this.props.wrapSubmit(this.submit, newData =>
            this.props.updateData(newData, 'osceDefault'),
          )}
        >
          <TablePanel className="clinic_panel_line examiner-edit-header">
            <p className="osce-settings-card_header-name">
              {this.props.translate('osceSettings', 'osceAdministration')}
            </p>
          </TablePanel>
          <StorageCredentials data={this.props.data} />
          <FeedbackCredentials data={this.props.data} />
          <DepotCredentials data={this.props.data} />
          <IOsceSettings submit={this.submit} data={this.props.data} />
        </Form>
      </Spin>
    );
  }
}
