import React, { PureComponent, Component } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import UrlPattern from 'url-pattern';
import { cloneDeep, get, set } from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';

import { Form, Input, Button, Spin } from 'antd';

import handleSubmit, { setFocusInTheEnd } from 'services/form';
import stateSend from 'services/stateSend';
import { wait } from 'services/promise';
import { wrapRequest } from 'utils/ajax';
import { updatePatientR } from 'actions/patient';
import { CHANGE_OPEN_FIELD } from 'constants/actions';
import { msgError } from '../../../../../services/errorHandler';

const FormItem = Form.Item;

export class StaticValue extends PureComponent {
  render() {
    const { props } = this;
    return (
      <span
        onClick={props.onClick}
        className={props.className || 'card-no-editable'}
      >
        {props.formatStatic(props.value)}
      </span>
    );
  }
}

const demyFunc = d => d;

export const mapStateToProps = (
  { runtime: { patientData: data }, form: { currentField } },
  {
    name,
    updateName,
    formatInitialValue = d => d || '',
    longName = updateName ? `${updateName}.${name}` : name,
  },
) => ({
  data,
  currentField,
  longName,
  isDeleted: get(data, 'deletedAt'),
  updateValues: updateName ? get(data, updateName) : data,
  initialValue: formatInitialValue(get(data, longName)),
});

export const mapDispatchToProps = dispatch =>
  bindActionCreators({ dispatch, updatePatientR }, dispatch);

const pattern = new UrlPattern('/patient/:id(/:path)');

export class FieldBase extends PureComponent {
  static defaultProps = {
    method: 'POST',
    forSendProp: demyFunc,
    formatStatic: demyFunc,
  };

  state = { editable: false, loading: false };

  componentDidUpdate(prevProps, prevState) {
    const { props } = this;
    if (this.state.editable) {
      if (
        prevProps.currentField === props.longName &&
        props.currentField !== prevProps.currentField
      ) {
        this.resetStopEdit();
      } else if (!prevState.editable) {
        setTimeout(() => this.focus(), 50);
      }
    }
  }

  forSendFunc = (values, val, name, props) =>
    set(values, name, props.forSendProp(val));

  translate = (key, customName, values) => {
    const name = customName || 'form';
    return this.props.intl.formatMessage({ id: `${name}.${key}` }, values);
  };

  handleSubmit = async e => {
    const { props } = this;
    const { name, updateName, data, form } = props;
    let values;
    try {
      values = await handleSubmit(e, form);

      // remove all comma from street
      if (values.street && ~values.street.indexOf(',')) {
        values.street = values.street.replace(/,/g, '');
        form.setFieldsValue(values);
      }
      this.forSendFunc(values, get(values, name), name, props);

      const newData = {
        ...props.updateValues,
        ...values,
      };
      await stateSend(
        loading => this.setState({ loading }),
        wrapRequest({
          method: props.method,
          url: pattern.stringify({
            id: props.match.params.id,
            path: props.path,
          }),
          data: newData,
        }).then(d => wait(d, 200)),
        {
          translate: this.translate,
          noShowCatch: true,
        },
      );
      let newPatient;
      if (updateName) {
        newPatient = { ...data };
        set(newPatient, updateName, newData);
      } else {
        newPatient = { ...data, ...newData };
      }
      props.updatePatientR(newPatient);
      this.stopEdit();
    } catch (e) {
      let error = '';
      if (get(e, 'languages.length')) {
        error = 'Please fill in language skill level';
      }
      msgError(error || e);
    }
  };

  saveStartEdit = () => {
    const { props } = this;
    this.prevVal = cloneDeep(props.form.getFieldValue(props.name));
    this.prevVal = {
      [props.name]: this.prevVal ? this.prevVal : undefined,
    };
    this._startEdit();
  };

  _startEdit = () => {
    const { props } = this;
    props.dispatch({
      type: CHANGE_OPEN_FIELD,
      currentField: props.longName,
    });
    this.setState({ editable: true });
  };

  stopEdit = () => this.setState({ editable: false });

  resetStopEdit = () => {
    this.props.form.setFieldsValue(this.prevVal);
    this.stopEdit();
  };

  get btns() {
    if (this.props.isDeleted) {
      return null;
    }
    if (!this.state.editable) {
      return (
        <div className="card-btns-container_hover" onClick={this.saveStartEdit}>
          <Button shape="circle" icon="edit" size="small" />
        </div>
      );
    }
    return (
      <div className="card-btns-container">
        <Button
          shape="circle"
          icon="close"
          size="small"
          onClick={this.resetStopEdit}
        />
        <Button
          shape="circle"
          type="primary"
          icon="check"
          size="small"
          htmlType="submit"
        />
      </div>
    );
  }

  focus = () => {
    const { focus } = this.props;
    if (focus) {
      focus(this.inputRef);
    } else {
      setFocusInTheEnd(this.inputRef);
    }
  };

  saveInput = ref => ref && (this.inputRef = ref);

  get field() {
    const { props } = this;
    const { name, rules, formatStatic, initialValue, form } = props;
    let component;
    if (this.state.editable) {
      component = props.component ? (
        props.component(this.saveInput)
      ) : (
        <Input ref={this.saveInput} />
      );
    }
    return form.getFieldDecorator(name, {
      rules,
      initialValue,
    })(
      this.state.editable ? (
        component
      ) : (
        <StaticValue formatStatic={formatStatic} onClick={this.saveStartEdit} />
      ),
    );
  }

  render() {
    const {
      name,
      i18Name = name,
      staticWidth,
      className,
      // decorator props
      intl,
    } = this.props;

    return (
      <Form onSubmit={this.handleSubmit} className={className}>
        <FormItem
          colon={false}
          label={intl.formatMessage({ id: `form.${i18Name}` })}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
          className={cn({
            'card-item': true,
            'card-item-static-width': this.state.editable && staticWidth,
            'card-item-editable': this.state.editable,
          })}
        >
          <Spin spinning={this.state.loading}>
            {this.field}
            {this.btns}
          </Spin>
        </FormItem>
      </Form>
    );
  }
}

const FieldDef = injectIntl(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps,
    )(Form.create({})(FieldBase)),
  ),
);

export default class ContextedField extends Component {
  static contextTypes = {
    updateName: PropTypes.string,
    path: PropTypes.string,
  };

  render() {
    return <FieldDef {...this.context} {...this.props} />;
  }
}
