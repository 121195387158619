import React, { Component } from 'react';
import { DatePicker, Button } from 'antd';
import PropTypes from 'prop-types';

import TablePanel from 'components/TablePanel/index';
import Table from 'components/Table';
import { roleCRUD } from 'actions/cruds';
import i18nTable from 'services/i18nTable';
import addLoadWithRedux from 'services/decorators/addLoadWithRedux';
import { DATE_MARK, DATE_SEND } from 'constants/formats';

/**
 * PastUserOfRole Component for past use of role view
 * @reactProps {func} translate - Function for translate
 * @reactProps {array} studentsOSCEColumns - Array of table colums
 * @reactProps {number} id - Id of role
 */
@i18nTable('studentsOSCEParticipationColumns')
@addLoadWithRedux({
  i18name: 'form',
  mapMethods: { getList: roleCRUD.getList },
})
export default class PastUserOfRole extends Component {
  static propTypes = {
    translate: PropTypes.func.isRequired,
    studentsOSCEParticipationColumns: PropTypes.array.isRequired, // For use without dispatchSend
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  };

  state = {
    startDate: null,
    endDate: null,
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      (!this.state.startDate && this.state.startDate !== prevState.startDate) ||
      (!this.state.endDate && this.state.endDate !== prevState.endDate)
    ) {
      this.load();
    }
  }

  get dateParams() {
    let queryParams = '';
    const { startDate, endDate } = this.state;
    let isAskAdded = false;
    if (startDate) {
      queryParams += `?startDate=${startDate.format(DATE_SEND)}`;
      isAskAdded = true;
    }
    if (endDate) {
      queryParams += `${isAskAdded ? '&' : '?'}endDate=${endDate.format(
        DATE_SEND,
      )}`;
    }
    return queryParams;
  }

  onDateSelect = name => value => this.setState({ [name]: value });

  load = async () => {
    this.props.getList('roseOsce', {
      additionalUrl: `${this.props.id}/osce${this.dateParams}`,
    });
  };

  render() {
    return (
      <React.Fragment>
        <TablePanel>
          <DatePicker
            format={DATE_MARK}
            onChange={this.onDateSelect('startDate')}
            value={this.state.startDate}
            placeholder="Start date"
          />
          <span className="role_date-picker-separator" />
          <DatePicker
            format={DATE_MARK}
            onChange={this.onDateSelect('endDate')}
            value={this.state.endDate}
            placeholder="End date"
          />
          <Button
            icon="search"
            className="blueBtn role_search-btn"
            onClick={this.load}
          >
            {this.props.translate('search')}
          </Button>
        </TablePanel>
        <Table
          action={roleCRUD}
          name="roseOsce"
          additionalUrl={`${this.props.id}/osce`}
          columns={this.props.studentsOSCEParticipationColumns}
          clearOnChangeAdditionalUrl
        />
      </React.Fragment>
    );
  }
}
