import axios from 'axios';
import { get as getParam } from 'lodash';
import { history } from 'store';

import { SERVER_URL } from '../constants/global';
import { DEFAULT_LIST_SIZE } from '../constants/common';

export const getAuthHeaders = () => ({
  Authorization: `Bearer ${localStorage.getItem('token')}`,
  'Access-Control-Allow-Origin': '*', // temp
});

const getDefHeaders = () => ({
  'Content-Type': 'application/json',
  ...getAuthHeaders(),
});

export const checkError = error => {
  const status = getParam(error, 'response.status');
  if (status >= 401 && status <= 403) {
    localStorage.removeItem('token');
    history.push('/auth/login');
  }
  return Promise.reject(error);
};

const buildUrl = url => {
  if (url.indexOf(SERVER_URL) === -1) {
    return SERVER_URL + url;
  }
  return url;
};

export const wrapRequest = options =>
  axios({
    headers: getDefHeaders(),
    ...options,
    url: buildUrl(options.url || options),
  }).catch(checkError);

export const buildCRUD = url => {
  if (!url) return Promise.reject(new Error('Need URL'));
  return {
    post: data => {
      if (!data) return Promise.reject(new Error('Need Data'));
      return wrapRequest({ method: 'POST', url, data });
    },
    patch: (data, additionalUrl = '') => {
      if (!data) return Promise.reject(new Error('Need Data'));
      return wrapRequest({ method: 'PATCH', url: url + additionalUrl, data });
    },
    put: (id, data) => {
      if (!data || !id) return Promise.reject(new Error('Need Data or id'));
      return wrapRequest({ method: 'PUT', url: `${url}/${id}`, data });
    },
    deleteRequest: id => {
      if (!id) return Promise.reject(new Error('Need id'));
      return wrapRequest({ method: 'DELETE', url: `${url}/${id}` });
    },
    get: id => {
      if (!id) return Promise.reject(new Error('Need id'));
      return wrapRequest({ method: 'GET', url: `${url}/${id}` });
    },
    getList: (opt = {}, data = {}) => {
      const {
        params: notSpredParams = {},
        additionalUrl,
        method = 'GET',
        paramsSerializer,
      } = opt;
      const params = { ...notSpredParams };
      if (params.page === undefined) {
        params.page = 0;
      }
      if (params.pageSize === undefined) {
        params.pageSize = DEFAULT_LIST_SIZE;
      }
      let sendUrl = url;
      if (additionalUrl) {
        sendUrl += `/${additionalUrl}`;
      }
      return wrapRequest({
        method,
        url: sendUrl,
        params,
        paramsSerializer,
        data,
      });
    },
  };
};
