import { getAuthHeaders, wrapRequest } from '../utils/ajax';
import { patientCRUD } from './cruds';
import dispatchSend from '../services/dispatchSend';
import { END_LOAD } from '../constants/actions';

export function getBase64(img) {
  const reader = new window.FileReader();
  reader.readAsDataURL(img);
  return new Promise((resolve, reject) => {
    reader.onload = function() {
      resolve(reader.result);
    };
    reader.onerror = function() {
      reject(new Error('Error while parsing image'));
    };
  });
}

export const getAvatar = async patientId => {
  const resp = await wrapRequest({
    method: 'GET',
    url: '/patient/avatar/download',
    headers: getAuthHeaders(),
    responseType: 'blob',
    params: { patientId },
  });

  return getBase64(resp.data);
};

export const getPatient = id => dispatchSend('patient', patientCRUD.get(id));

export const updatePatientR = data => dispatch =>
  dispatch({
    type: END_LOAD,
    name: 'patient',
    data,
  });
