import api from 'services/api';
import {
  ROLE_REQUEST_START,
  FETCH_ROLE_SKILLS_SUCCESS,
  FETCH_ROLE_SKILLS_ERROR,
  FETCH_ROLE_INFO_SUCCESS,
  FETCH_ROLE_INFO_ERROR,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_ERROR,
  FETCH_ROLES_SUCCESS,
  FETCH_ROLES_ERROR,
  FETCH_ROLES_CRITERIA_SUCCESS,
  FETCH_ROLES_CRITERIA_ERROR,
  DELETE_ROLE_CRITERION_SUCCESS,
  DELETE_ROLE_CRITERION_ERROR,
  UPDATE_ROLE_CRITERION_SUCCESS,
  UPDATE_ROLE_CRITERION_ERROR,
  CREATE_ROLE_CRITERION_SUCCESS,
  CREATE_ROLE_CRITERION_ERROR,
  LOAD_MORE_CRITERIA_SUCCESS,
  FETCH_ALL_CATALOGS_SUCCESS,
  FETCH_ALL_CATALOGS_ERROR,
  FETCH_ALL_ROLES_OSCES_SUCCESS,
  FETCH_ALL_ROLES_OSCES_ERROR,
  LOAD_MORE_ROLES_OSCES_SUCCESS,
} from 'constants/role';

import { msgError } from 'services/errorHandler';

export const getRoleInfo = id => async dispatch => {
  try {
    dispatch({ type: ROLE_REQUEST_START });
    const response = await api.getRoleInfo(id);
    const info = response.data;
    return dispatch({ type: FETCH_ROLE_INFO_SUCCESS, payload: { info } });
  } catch (error) {
    return dispatch({ type: FETCH_ROLE_INFO_ERROR, error });
  }
};

export const deleteRole = id => async dispatch => {
  return api.deleteRole(id);
};

export const getAllRolesCriteria = (id, data) => async dispatch => {
  try {
    dispatch({ type: ROLE_REQUEST_START });
    const response = await api.getAllRolesCriteria(id, data);
    const criteria = response.data;
    dispatch({ type: FETCH_ROLES_CRITERIA_SUCCESS, payload: { criteria } });
    return criteria;
  } catch (error) {
    dispatch({ type: FETCH_ROLES_CRITERIA_ERROR, error });
    throw error;
  }
};

export const lazyLoadCriteria = (id, data) => async dispatch => {
  try {
    dispatch({ type: ROLE_REQUEST_START });
    const response = await api.getAllRolesCriteria(id, data);
    const criteria = response.data.data;
    dispatch({ type: LOAD_MORE_CRITERIA_SUCCESS, payload: { criteria } });
    return response.data;
  } catch (error) {
    dispatch({ type: FETCH_ROLES_CRITERIA_ERROR, error });
    throw error;
  }
};

export const updateRoleCriterion = (
  roleId,
  criterionId,
  data,
) => async dispatch => {
  try {
    dispatch({ type: ROLE_REQUEST_START });
    const response = await api.updateRoleCriterion(roleId, criterionId, data);
    const criterion = response.data;
    dispatch({ type: UPDATE_ROLE_CRITERION_SUCCESS, payload: { criterion } });
    return criterion;
  } catch (error) {
    dispatch({ type: UPDATE_ROLE_CRITERION_ERROR, error });
    throw error;
  }
};

export const createRoleCriterion = (roleId, data) => async dispatch => {
  try {
    dispatch({ type: ROLE_REQUEST_START });
    const response = await api.createRoleCriterion(roleId, data);
    const criterion = response.data;
    dispatch({ type: CREATE_ROLE_CRITERION_SUCCESS, payload: { criterion } });
    return criterion;
  } catch (error) {
    dispatch({ type: CREATE_ROLE_CRITERION_ERROR, error });
    throw error;
  }
};

export const deleteRoleCriterion = (roleId, criterionId) => async dispatch => {
  try {
    dispatch({ type: ROLE_REQUEST_START });
    await api.deleteRoleCriterion(roleId, criterionId);
    dispatch({ type: DELETE_ROLE_CRITERION_SUCCESS, payload: { criterionId } });
  } catch (error) {
    dispatch({ type: DELETE_ROLE_CRITERION_ERROR, error });
    throw error;
  }
};

export const getAllSkills = data => async dispatch => {
  try {
    dispatch({ type: ROLE_REQUEST_START });
    const response = await api.getAllSkills(data);
    const skills = response.data;
    return dispatch({ type: FETCH_ROLE_SKILLS_SUCCESS, payload: { skills } });
  } catch (error) {
    return dispatch({ type: FETCH_ROLE_SKILLS_ERROR, error });
  }
};

export const createNewRole = data => dispatch => {
  return api.createNewRole(data);
};

export const updateRole = (id, data) => async dispatch => {
  try {
    dispatch({ type: ROLE_REQUEST_START });
    const response = await api.updateRole(id, data);
    const role = response.data;
    dispatch({ type: UPDATE_ROLE_SUCCESS, payload: { role } });
    return role;
  } catch (error) {
    dispatch({ type: UPDATE_ROLE_ERROR, error });
    throw error;
  }
};

export const createNewSkill = data => async dispatch => {
  return api.createNewSkill(data);
};

export const printRole = async (id, data) => {
  try {
    const response = await api.printRole(id, data);
    const openWindow = window.open();
    openWindow.location = window.URL.createObjectURL(response.data);
  } catch (error) {
    msgError(error);
  }
};

export const getAllRoles = data => async dispatch => {
  try {
    dispatch({ type: ROLE_REQUEST_START });
    const response = await api.getAllRoles(data);
    const roles = response.data;
    return dispatch({ type: FETCH_ROLES_SUCCESS, payload: { roles } });
  } catch (error) {
    return dispatch({ type: FETCH_ROLES_ERROR, error });
  }
};

export const getAllCatalogs = isShowEnabledOnly => async dispatch => {
  try {
    dispatch({ type: ROLE_REQUEST_START });
    const response = await api.getAllCatalogs(isShowEnabledOnly);
    const catalogs = response.data;
    dispatch({ type: FETCH_ALL_CATALOGS_SUCCESS, payload: { catalogs } });
    return catalogs;
  } catch (error) {
    dispatch({ type: FETCH_ALL_CATALOGS_ERROR, error });
    throw error;
  }
};

export const addTargetSystemToRole = (
  targetSystemId,
  roleId,
) => async dispatch => {
  try {
    dispatch({ type: ROLE_REQUEST_START });
    const response = await api.addTargetSystemToRole(targetSystemId, roleId);
    const targetSystem = response.data;
    dispatch(getRoleInfo(roleId));
    return targetSystem;
  } catch (error) {
    throw error;
  }
};

export const removeTargetSystemFromRole = (
  targetSystemId,
  roleId,
) => async dispatch => {
  try {
    dispatch({ type: ROLE_REQUEST_START });
    await api.removeTargetSystemFromRole(targetSystemId, roleId);
    dispatch(getRoleInfo(roleId));
  } catch (error) {
    throw error;
  }
};

export const getAllRolesOSCEs = data => async dispatch => {
  try {
    dispatch({ type: ROLE_REQUEST_START });
    const response = await api.getAllRolesOSCEs(data);
    const osces = response.data;
    dispatch({ type: FETCH_ALL_ROLES_OSCES_SUCCESS, payload: { osces } });
    return osces;
  } catch (error) {
    dispatch({ type: FETCH_ALL_ROLES_OSCES_ERROR, error });
    throw error;
  }
};

export const lazyLoadRolesOSCEs = data => async dispatch => {
  try {
    dispatch({ type: ROLE_REQUEST_START });
    const response = await api.getAllRolesOSCEs(data);
    const osces = response.data;
    dispatch({ type: LOAD_MORE_ROLES_OSCES_SUCCESS, payload: { osces } });
    return osces;
  } catch (error) {
    dispatch({ type: FETCH_ALL_ROLES_OSCES_ERROR, error });
    throw error;
  }
};
