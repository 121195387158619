import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row, Table, Tooltip } from 'antd';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';

/** Components */
import TablePanel from 'components/TablePanel';

/** Actions */
import { patientCRUD } from 'actions/cruds';

/** Services */
import i18nTable from 'services/i18nTable';
import addLoadWithRedux from 'services/decorators/addLoadWithRedux';
import stateSend from 'services/stateSend';
import { downloadFile } from 'utils/downloadFile';

/** Constants */
import { DEFAULT_LIST_SIZE, DEFAULT_PROPS_TABLE } from 'constants/common';

import './style.less';
import { getCostAccounting } from '../../../actions/osceAdministration';
import { msgError } from '../../../services/errorHandler';

@withRouter
@i18nTable('costAccountingColumns')
@addLoadWithRedux({
  i18Name: 'osceAdministration',
  runtimeReduxNames: ['studentsBySemester'],
  getLanguageFromRedux: true,
})
export default class CostAccounting extends React.PureComponent {
  static propsTypes = {
    studentsBySemesterData: PropTypes.object,
    studentsBySemesterLoading: PropTypes.bool,
    getStudentsBySemesterId: PropTypes.func,
  };

  static defaultProps = {
    studentsBySemesterData: { data: [], pagination: {} },
  };

  state = { selectedRowKeys: [], loading: false };

  componentWillMount() {
    window.addEventListener('scroll', this.onTableScroll, true);
    this.loadList(this.props.match.params.semesterId);
  }

  componentWillReceiveProps(nextProps) {
    const { params } = nextProps.match;
    if (
      get(this.props.match.params, 'semesterId') !== get(params, 'semesterId')
    ) {
      this.loadList(params.semesterId);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onTableScroll, true);
    this.props.resetData('studentsBySemester');
  }

  get selectedPropKeys() {
    const { selectedRowKeys: keys } = this.state;
    const { studentsBySemesterData } = this.props;

    return studentsBySemesterData.data
      .filter(item => keys.includes(item.id))
      .map(item => item.id);
  }

  get canLoadMore() {
    const pagination =
      get(this.props, 'studentsBySemesterData.pagination') || {};
    return (
      !!pagination.pageSize &&
      (pagination.page + 1) * DEFAULT_LIST_SIZE < pagination.total
    );
  }

  loadList = async (semesterId, pagination) => {
    const { props } = this;
    const resp = await stateSend(
      loading => this.setState({ loading }),
      patientCRUD.getList({
        additionalUrl: `semester/${semesterId}`,
        params: pagination,
      }),
    );
    resp.data.forEach((item, i) => (item.key = i));
    let newData = [];
    if (!get(pagination, 'page')) {
      newData = resp.data;
    } else {
      newData = this.props.studentsBySemesterData.data.concat(resp.data);
    }
    props.updateData(
      { data: newData, pagination: resp.pagination },
      'studentsBySemester',
    );
  };

  onTableScroll = () => {
    const { body } = document;
    if (
      !this.state.loading &&
      this.canLoadMore &&
      window.scrollY > body.scrollHeight - body.offsetHeight - 30
    ) {
      this.loadList(this.props.match.params.semesterId, {
        page: this.props.studentsBySemesterData.pagination.page + 1,
      });
    }
  };

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  printBilling = async () => {
    try {
      const resp = await getCostAccounting(
        {
          semesterId: this.props.match.params.semesterId,
          locale: this.props.language === 'en' ? 'ENGLISH' : 'GERMAN',
        },
        { ids: this.selectedPropKeys },
      );
      downloadFile(
        resp.data,
        `sp_payment_${this.props.language}.pdf`,
        'application/pdf',
      );
    } catch (e) {
      msgError(e);
    }
  };

  render() {
    const {
      translate,
      studentsBySemesterData,
      studentsBySemesterLoading,
      costAccountingColumns,
    } = this.props;
    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const dataSource = studentsBySemesterData.data.map(item => ({
      ...item,
      key: item.id,
    }));

    return (
      <div className="cost-accounting">
        <TablePanel className="clinic_panel_line">
          <p className="osce-settings-card_header-name">
            {translate('costAccounting', 'osceAdministration')}
          </p>
        </TablePanel>
        <TablePanel>
          <Button
            className="table-panel_btn"
            style={{ width: '100px' }}
            icon="printer"
            disabled={!selectedRowKeys.length}
            onClick={this.printBilling}
          >
            {translate('print', 'patient')}
          </Button>
        </TablePanel>
        <Row>
          <Col span={12}>
            <Tooltip
              placement="top"
              title={this.props.translate('costAccounting', 'tooltips')}
            >
              <div className="table-with-selection">
                <Table
                  {...DEFAULT_PROPS_TABLE}
                  loading={this.state.loading || studentsBySemesterLoading}
                  columns={costAccountingColumns}
                  rowSelection={rowSelection}
                  dataSource={dataSource}
                />
              </div>
            </Tooltip>
          </Col>
        </Row>
      </div>
    );
  }
}
