import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Col } from 'antd';

import { history } from 'store';
import { clinicCRUD } from 'actions/cruds';
import handleSubmit from 'services/form';

import i18n from 'services/i18n';
import { onlyNumber } from 'services/validations';
import { msgLocalError } from 'services/errorHandler';
import FormItemInput from 'components/Form/formInput';
import Footer from 'components/Form/modalFooter';
import 'components/Form/formsStyles.less';

@i18n('form')
@Form.create({})
export default class AddNewClinic extends Component {
  static childContextTypes = {
    form: PropTypes.object,
  };

  getChildContext() {
    return { form: this.props.form };
  }

  addNewClinicSubmit = async e => {
    const values = await handleSubmit(e, this.props.form);
    try {
      const { data } = await clinicCRUD.post(values);
      history.push(this.props.redirect(data.id));
      this.handleReset();
    } catch (error) {
      msgLocalError({ error, translate: this.props.translate });
    }
  };

  handleReset = () => {
    this.props.form.resetFields();
    this.props.toggleWindow();
  };

  render() {
    const { translate } = this.props;
    return (
      <Form onSubmit={this.addNewClinicSubmit}>
        <div className="form_content">
          <FormItemInput name="name" label={translate('name')} required focus />
          <FormItemInput name="street" label={translate('street')} />
          <Row gutter={20}>
            <Col span={6}>
              <FormItemInput
                name="postalCode"
                label={translate('postalCode')}
                validator={{
                  func: onlyNumber,
                  message: 'notValidPostalCode',
                }}
              />
            </Col>
            <Col span={18}>
              <FormItemInput name="city" label={translate('city')} />
            </Col>
          </Row>
        </div>
        <Footer
          submitText="form.save"
          cancelText="form.cancel"
          onCancel={this.handleReset}
        />
      </Form>
    );
  }
}
