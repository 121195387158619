import { combineReducers } from 'redux';
import {
  CHANGE_CHECKLIST_START,
  FETCH_CHECKLIST_SUCCESS,
  FETCH_CHECKLIST_ERROR,
  CREATE_CHECKLIST_SECTION_SUCCESS,
  CREATE_CHECKLIST_SECTION_ERROR,
  FETCH_CHECKLIST_SECTIONS_SUCCESS,
  FETCH_CHECKLIST_SECTIONS_ERROR,
  IMPORT_CHECKLIST_SECTIONS_SUCCESS,
  IMPORT_CHECKLIST_SECTIONS_ERROR,
  CHANGE_CHECKLIST_QUEUE_SUCCESS,
  CHANGE_CHECKLIST_QUEUE_ERROR,
  FETCH_CHECKLIST_QUESTIONS_SUCCESS,
  FETCH_CHECKLIST_QUESTIONS_ERROR,
  CHANGE_CHECKLIST_QUESTION_QUEUE_SUCCESS,
  CHANGE_CHECKLIST_QUESTION_QUEUE_ERROR,
  DELETE_CHECKLIST_SECTION_SUCCESS,
  DELETE_CHECKLIST_SECTION_ERROR,
  UPDATE_CHECKLIST_SECTION_SUCCESS,
  UPDATE_CHECKLIST_SECTION_ERROR,
  CREATE_QUESTION_SUCCESS,
  CREATE_QUESTION_ERROR,
  IMPORT_QUESTION_SUCCESS,
  IMPORT_QUESTION_ERROR,
  UPDATE_QUESTION_IN_SECTION_SUCCESS,
  UPDATE_QUESTION_IN_SECTION_ERROR,
  DELETE_QUESTION_IN_SECTION_SUCCESS,
  DELETE_QUESTION_IN_SECTION_ERROR,
} from 'constants/checkList';

const loading = (state = false, action) => {
  switch (action.type) {
    case CHANGE_CHECKLIST_START:
      return true;

    case FETCH_CHECKLIST_SUCCESS:
    case FETCH_CHECKLIST_ERROR:
    case CREATE_CHECKLIST_SECTION_SUCCESS:
    case CREATE_CHECKLIST_SECTION_ERROR:
    case IMPORT_CHECKLIST_SECTIONS_SUCCESS:
    case IMPORT_CHECKLIST_SECTIONS_ERROR:
    case FETCH_CHECKLIST_SECTIONS_SUCCESS:
    case FETCH_CHECKLIST_SECTIONS_ERROR:
    case CHANGE_CHECKLIST_QUEUE_SUCCESS:
    case CHANGE_CHECKLIST_QUEUE_ERROR:
    case FETCH_CHECKLIST_QUESTIONS_SUCCESS:
    case FETCH_CHECKLIST_QUESTIONS_ERROR:
    case CHANGE_CHECKLIST_QUESTION_QUEUE_SUCCESS:
    case CHANGE_CHECKLIST_QUESTION_QUEUE_ERROR:
    case DELETE_CHECKLIST_SECTION_SUCCESS:
    case DELETE_CHECKLIST_SECTION_ERROR:
    case UPDATE_CHECKLIST_SECTION_SUCCESS:
    case UPDATE_CHECKLIST_SECTION_ERROR:
    case CREATE_QUESTION_SUCCESS:
    case CREATE_QUESTION_ERROR:
    case IMPORT_QUESTION_SUCCESS:
    case IMPORT_QUESTION_ERROR:
    case UPDATE_QUESTION_IN_SECTION_SUCCESS:
    case UPDATE_QUESTION_IN_SECTION_ERROR:
    case DELETE_QUESTION_IN_SECTION_SUCCESS:
    case DELETE_QUESTION_IN_SECTION_ERROR:
      return false;

    default:
      return state;
  }
};

const errors = (state = {}, action) => {
  switch (action.type) {
    case FETCH_CHECKLIST_ERROR:
    case CREATE_CHECKLIST_SECTION_ERROR:
    case FETCH_CHECKLIST_SECTIONS_ERROR:
    case IMPORT_CHECKLIST_SECTIONS_ERROR:
    case CHANGE_CHECKLIST_QUEUE_ERROR:
    case FETCH_CHECKLIST_QUESTIONS_ERROR:
    case CHANGE_CHECKLIST_QUESTION_QUEUE_ERROR:
    case UPDATE_CHECKLIST_SECTION_ERROR:
    case CREATE_QUESTION_ERROR:
    case IMPORT_QUESTION_ERROR:
    case UPDATE_QUESTION_IN_SECTION_ERROR:
    case DELETE_QUESTION_IN_SECTION_ERROR:
      return action.error;
    default:
      return state;
  }
};

const data = (state = [], action) => {
  switch (action.type) {
    case FETCH_CHECKLIST_SUCCESS:
    case CHANGE_CHECKLIST_QUEUE_SUCCESS: {
      const sortedCheckList = action.payload.checkList.sort(
        (a, b) => a.numberInQueue - b.numberInQueue,
      );

      return sortedCheckList;
    }

    case CREATE_CHECKLIST_SECTION_SUCCESS: {
      const newCheckList = [...state, action.payload.section];
      const sortedCheckList = newCheckList.sort(
        (a, b) => a.numberInQueue - b.numberInQueue,
      );

      return sortedCheckList;
    }

    case IMPORT_CHECKLIST_SECTIONS_SUCCESS: {
      const newCheckList = [...state, ...action.payload.sections.data];
      const sortedCheckList = newCheckList.sort(
        (a, b) => a.numberInQueue - b.numberInQueue,
      );

      return sortedCheckList;
    }

    case DELETE_CHECKLIST_SECTION_SUCCESS: {
      const newCheckList = state.filter(
        item => item.id !== action.payload.sectionId,
      );

      return newCheckList;
    }

    case UPDATE_CHECKLIST_SECTION_SUCCESS: {
      const { section } = action.payload;
      const newState = state.map(s =>
        s.id === section.id ? { ...section } : s,
      );

      return newState;
    }

    default:
      return state;
  }
};

const questionsBySection = (state = {}, action) => {
  switch (action.type) {
    case FETCH_CHECKLIST_QUESTIONS_SUCCESS:
    case CHANGE_CHECKLIST_QUESTION_QUEUE_SUCCESS: {
      const newState = { ...state };
      newState[action.payload.sectionId] = action.payload.questions;

      return newState;
    }

    case DELETE_CHECKLIST_SECTION_SUCCESS: {
      const newState = { ...state };
      delete newState[action.payload.sectionId];

      return newState;
    }

    case CREATE_QUESTION_SUCCESS:
    case IMPORT_QUESTION_SUCCESS: {
      const { sectionId, question } = action.payload;
      const questions = state[sectionId] || [];
      const newQuestions = questions.concat(question);
      const newState = { ...state, [sectionId]: newQuestions };

      return newState;
    }

    case UPDATE_QUESTION_IN_SECTION_SUCCESS: {
      const { sectionId, question } = action.payload;
      const questions = state[sectionId] || [];
      const newQuestions = questions.map(q =>
        q.id === question.id ? question : q,
      );
      const newState = { ...state, [sectionId]: newQuestions };

      return newState;
    }

    case DELETE_QUESTION_IN_SECTION_SUCCESS: {
      const { sectionId, questionId } = action.payload;
      const newQuestions = state[sectionId].filter(q => q.id !== questionId);
      const newState = { ...state, [sectionId]: newQuestions };

      return newState;
    }

    default:
      return state;
  }
};

const role = combineReducers({
  loading,
  errors,
  data,
  questionsBySection,
});

export default role;
