import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Tabs,
  Select,
  Button,
  Dropdown,
  Menu,
  Icon,
  Tooltip,
  message,
} from 'antd';

import { printRole, deleteRole, getRoleInfo } from 'actions/role';
import i18n from 'services/i18n';
import { msgLocalError } from 'services/errorHandler';
import tabPattern from 'services/decorators/tabPattern';
import DeleteButton from 'components/DeleteButton';

import RoleInformation from './RoleInformation';
import TargetSystem from './TargetSystem';
import PastUserOfRole from './PastUserOfRole';
import CriteriaForSP from './CriteriaForSP/CriteriaForSP';
import Checklist from './Checklist/Checklist';

import './styles.less';
import RoleOSCEs from './RoleOSCEs/RoleOSCEs';

const mapStateToProps = ({ role, localization }) => ({
  role: role.info,
  language: localization.lang,
});

const mapDispatchToProps = {
  deleteRole,
  getRoleInfo,
};

@i18n('form')
@tabPattern('/roles/:tab/:id')
@connect(
  mapStateToProps,
  mapDispatchToProps,
)
export default class Role extends Component {
  static propTypes = {
    role: PropTypes.shape({
      id: PropTypes.number,
      idForMergingVersions: PropTypes.number,
      isUsedInOsce: PropTypes.bool,
      lastVersion: PropTypes.bool,
      majorVersion: PropTypes.number,
      minorVersion: PropTypes.number,
      roleInformationResponse: PropTypes.object,
      skillId: PropTypes.number,
      targetSystemResponses: PropTypes.array,
      versions: PropTypes.array,
    }).isRequired,
  };

  componentDidMount() {
    this.props.getRoleInfo(this.props.match.params.id);
  }

  deleteRole = async () => {
    try {
      await this.props.deleteRole(this.props.match.params.id);
      this.props.history.push('/roles/definition');
    } catch (e) {
      if (e.response.status === 400 && e.response.data.errorCode === 1800) {
        message.error(e.response.data.message);
      } else {
        msgLocalError({ error: e, translate: this.props.translate });
      }
    }
  };

  handleChangeRoleVersion = versionId => {
    this.props.history.push(`/roles/${this.props.tab}/${versionId}`);
  };

  loadPDF = e => {
    e.domEvent.preventDefault();
    const locale = this.props.language === 'en' ? 'ENGLISH' : 'GERMAN';
    const roleDetails = e.key === 'with'; // Include role details
    printRole(this.props.role.id, { locale, roleDetails });
  };

  render() {
    const menu = (
      <Menu onClick={this.loadPDF}>
        <Menu.Item key="with">Checklist with Role Details</Menu.Item>
        <Menu.Item key="withoud">Checklist without Role Details</Menu.Item>
      </Menu>
    );

    return (
      <React.Fragment>
        <div className="table-panel role_panel_line">
          {this.props.role.id && (
            <React.Fragment>
              <Tooltip
                placement="top"
                title={this.props.translate('roleVersion', 'tooltips')}
              >
                <Select
                  value={this.props.role.id}
                  style={{ width: 95 }}
                  onChange={this.handleChangeRoleVersion}
                >
                  {this.props.role.versions.map(ver => (
                    <Select.Option value={ver.id} key={ver.id}>
                      {`v. ${ver.majorVersion}.${ver.minorVersion}`}
                    </Select.Option>
                  ))}
                </Select>
              </Tooltip>
              <p className="role_name">
                {this.props.role.roleInformationResponse.roleDesignation}
              </p>
              <span className="table-panel_btn-right">
                <Dropdown overlay={menu}>
                  <Button className="table-panel_btn" icon="printer">
                    {this.props.translate('print', 'patient')}{' '}
                    <Icon type="down" />
                  </Button>
                </Dropdown>
                <DeleteButton
                  className="table-panel_btn"
                  action={this.deleteRole}
                />
              </span>
            </React.Fragment>
          )}
        </div>
        <Tabs
          activeKey={this.props.tab}
          onTabClick={this.props.onTabClick}
          animated={{ inkBar: true, tabPane: false }}
        >
          <Tabs.TabPane
            tab={this.props.translate('roleInformation')}
            key="role"
          >
            <RoleInformation key={this.props.match.params.id} />
          </Tabs.TabPane>
          <Tabs.TabPane tab={this.props.translate('checklist')} key="checklist">
            <Checklist />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={this.props.translate('targetSystem')}
            key="target-system"
          >
            <TargetSystem />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={this.props.translate('pastUseOfRole')}
            key="past-user"
          >
            <PastUserOfRole
              roleProfile={this.props.role}
              id={this.props.match.params.id}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={this.props.translate('criteriaForSpSelection')}
            key="criteria"
          >
            <CriteriaForSP
              key={this.props.match.params.id}
              disabled={!this.props.role.lastVersion}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="OSCEs" key="osces">
            <RoleOSCEs key={this.props.match.params.id} />
          </Tabs.TabPane>
        </Tabs>
      </React.Fragment>
    );
  }
}
