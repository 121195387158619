import React, { Component, Fragment } from 'react';
import { Upload as AntUpload, message, Icon, Popconfirm } from 'antd';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { get } from 'lodash';

import { wrapRequest } from 'utils/ajax';
import stateSend from 'services/stateSend';

import { SERVER_URL } from 'constants/global';
import { getAvatar, getBase64 } from 'actions/patient';
import i18n from 'services/i18n';
import defUsrImg from 'images/defaultUsr.svg';

const ALLOWED_TYPES = ['image/jpeg', 'image/png'];
const ALLOWED_MSG = ALLOWED_TYPES.map(str => str.replace('image/', '')).join(
  ', ',
);

const mapStateToProps = ({ runtime: { patientData: data } }) => ({
  isDeleted: get(data, 'deletedAt'),
});

@i18n('form')
@withRouter
@connect(mapStateToProps)
export default class Upload extends Component {
  state = {
    loading: false,
  };

  componentDidCatch(error, info) {
    console.error(error, info);
  }

  async componentWillMount() {
    this.auth = `Bearer ${localStorage.getItem('token')}`;
    const imageUrl = await getAvatar(this.patientId);
    this.setState({ imageUrl });
  }

  beforeUpload = file => {
    const { translate } = this.props;
    const isTYPE = ALLOWED_TYPES.indexOf(file.type) === -1;
    if (isTYPE) {
      message.error(`${translate('youCanUpload', 'error')} ${ALLOWED_MSG}!`);
    }
    const isLt2M = file.size / 1024 / 1024 < 1;
    if (!isLt2M) {
      message.error(`${translate('imgMustBeSmaller', 'error')}  1MB!`);
    }
    return !isTYPE && isLt2M;
  };

  handleError = () =>
    message.error(this.props.translate('cantUpload', 'error'));

  handleChange = async info => {
    const { file } = info;
    switch (file.status) {
      case 'uploading':
        this.setState({ loading: true });
        return;
      case 'done': {
        const imageUrl = await getBase64(file.originFileObj);
        this.setState({
          imageUrl,
          loading: false,
        });
        return;
      }
      case 'error':
        this.handleError();
        return;
      default:
        console.error('other info', info);
    }
  };

  get patientId() {
    return this.props.match.params.id;
  }

  sendDelete = async () => {
    await stateSend(
      loading => this.setState({ loading }),
      wrapRequest({
        method: 'DELETE',
        url: '/patient/avatar/delete',
        params: {
          patientId: this.patientId,
        },
      }),
    );
    this.setState({ imageUrl: '' });
  };

  get photo() {
    if (this.state.loading) {
      return <Icon type="loading" />;
    }
    let url = this.state.imageUrl || defUsrImg;
    if (this.props.isDeleted) {
      url = defUsrImg;
    }
    return <img src={url} className="card-image" alt="profile-pic" />;
  }

  openDialog = () => this.uploader.click(); // Couldn't find upload method in AntUpload.

  get btns() {
    if (this.props.isDeleted) {
      return null;
    }
    const { translate } = this.props;
    if (!this.state.imageUrl) {
      return (
        <a className="action-btn" onClick={this.openDialog}>
          <Icon type="plus" />
          {translate('addPhoto')}
        </a>
      );
    }
    return (
      <Fragment>
        <a className="action-btn" onClick={this.openDialog}>
          <Icon type="retweet" />
          {translate('changePhoto')}
        </a>
        <Popconfirm
          placement="bottomLeft"
          title={this.props.translate('areYouSureDelete', 'confirm')}
          onConfirm={this.sendDelete}
        >
          <a className="action-btn">
            <Icon type="delete" />
            {translate('remove')}
          </a>
        </Popconfirm>
      </Fragment>
    );
  }

  render() {
    return (
      <div className="avatar-uploader">
        <AntUpload
          disabled={this.props.isDeleted}
          type="drag"
          name="file"
          listType="picture-card"
          accept={ALLOWED_TYPES.join(', ')}
          showUploadList={false}
          action={`${SERVER_URL}/patient/avatar/upload`}
          headers={{ Authorization: this.auth }}
          data={{ patientId: this.patientId }}
          beforeUpload={this.beforeUpload}
          onChange={this.handleChange}
          onError={this.handleError}
        >
          <span ref={_uploader => _uploader && (this.uploader = _uploader)} />
          {this.photo}
        </AntUpload>
        {this.btns}
      </div>
    );
  }
}
