import React, { PureComponent } from 'react';
import { Tabs } from 'antd';

import tabPattern from 'services/decorators/tabPattern';
import i18n from 'services/i18n';
import SendInvitations from './sendInvitations';
import PrintSchedules from './printSchedules';
import ExportData from './exportData';

const { TabPane } = Tabs;

@i18n('output')
@tabPattern('/osce-administration/:id/outputs/:tab')
export default class Output extends PureComponent {
  render() {
    const { props } = this;
    const { translate } = props;
    return (
      <Tabs
        activeKey={props.tab}
        onTabClick={props.onTabClick}
        animated={{ inkBar: true, tabPane: false }}
      >
        <TabPane tab={translate('export')} key="export-data">
          <ExportData />
        </TabPane>
        <TabPane tab={translate('sendInvitations')} key="send-invitations">
          <SendInvitations />
        </TabPane>
        <TabPane tab={translate('printSchedules')} key="print-schedules">
          <PrintSchedules />
        </TabPane>
      </Tabs>
    );
  }
}
