import React, { Component } from 'react';
import { get } from 'lodash';

import { GENDER } from 'constants/options';
import { phoneNumber } from 'services/validations';
import EditableLable from 'components/Form/formEditableLabel';
import EditableLabelDropDown from 'components/Form/formEditableLabelDropDown';
import Card from 'components/Form/Card';

export default class Secretary extends Component {
  render() {
    const { data } = this.props;
    return (
      <Card from="cards" value="secretary">
        <EditableLable
          name="secretary.lastName"
          title="lastName"
          initialValue={get(data, 'lastName')}
          onSubmit={this.context.submit}
        />
        <EditableLable
          name="secretary.firstName"
          title="firstName"
          initialValue={get(data, 'firstName')}
          onSubmit={this.context.submit}
        />
        <EditableLabelDropDown
          name="secretary.gender"
          title="gender"
          options={GENDER}
          initialValue={get(data, 'gender')}
          onSubmit={this.context.submit}
        />
        <EditableLable
          name="secretary.email"
          title="email"
          type="email"
          initialValue={get(data, 'email')}
          onSubmit={this.context.submit}
        />
        <EditableLable
          name="secretary.telephone"
          title="telephone"
          validator={{
            func: phoneNumber,
            message: 'notValidPhoneNumber',
          }}
          initialValue={get(data, 'telephone')}
          onSubmit={this.context.submit}
        />
      </Card>
    );
  }
}
