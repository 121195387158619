import api from 'services/api';
import {
  CHANGE_DISCIPLINES_START,
  FETCH_DISCIPLINES_SUCCESS,
  FETCH_DISCIPLINES_ERROR,
  LOAD_MORE_DISCIPLINES_SUCCESS,
  CREATE_DISCIPLINE_SUCCESS,
  CREATE_DISCIPLINE_ERROR,
  DELETE_DISCIPLINE_SUCCESS,
  DELETE_DISCIPLINE_ERROR,
  UPDATE_DISCIPLINE_SUCCESS,
  UPDATE_DISCIPLINE_ERROR,
  UPDATE_SKILL_SUCCESS,
  UPDATE_SKILL_ERROR,
  DELETE_SKILL_SUCCESS,
  DELETE_SKILL_ERROR,
} from 'constants/discipline';

export const getAllDisciplines = data => async dispatch => {
  try {
    dispatch({ type: CHANGE_DISCIPLINES_START });
    const response = await api.getAllDisciplines(data);
    const disciplines = response.data;
    dispatch({ type: FETCH_DISCIPLINES_SUCCESS, payload: { disciplines } });
    return disciplines;
  } catch (error) {
    dispatch({ type: FETCH_DISCIPLINES_ERROR, error });
    throw error;
  }
};

export const lazyLoadDisciplines = data => async dispatch => {
  try {
    dispatch({ type: CHANGE_DISCIPLINES_START });
    const response = await api.getAllDisciplines(data);
    const disciplines = response.data;
    dispatch({ type: LOAD_MORE_DISCIPLINES_SUCCESS, payload: { disciplines } });
    return disciplines;
  } catch (error) {
    dispatch({ type: FETCH_DISCIPLINES_ERROR, error });
    throw error;
  }
};

export const createDiscipline = data => async dispatch => {
  try {
    dispatch({ type: CHANGE_DISCIPLINES_START });
    const response = await api.createDiscipline(data);
    const discipline = response.data;
    dispatch({ type: CREATE_DISCIPLINE_SUCCESS, payload: { discipline } });
    return discipline;
  } catch (error) {
    dispatch({ type: CREATE_DISCIPLINE_ERROR, error });
    throw error;
  }
};

export const updateDiscipline = data => async dispatch => {
  try {
    dispatch({ type: CHANGE_DISCIPLINES_START });
    const response = await api.updateDiscipline(data);
    const discipline = response.data;
    dispatch({ type: UPDATE_DISCIPLINE_SUCCESS, payload: { discipline } });
    return discipline;
  } catch (error) {
    dispatch({ type: UPDATE_DISCIPLINE_ERROR, error });
    throw error;
  }
};

export const deleteDiscipline = id => async dispatch => {
  try {
    dispatch({ type: CHANGE_DISCIPLINES_START });
    await api.deleteDiscipline(id);
    dispatch({ type: DELETE_DISCIPLINE_SUCCESS, payload: { id } });
  } catch (error) {
    dispatch({ type: DELETE_DISCIPLINE_ERROR, error });
    throw error;
  }
};

export const updateSkill = (id, data) => async dispatch => {
  try {
    dispatch({ type: CHANGE_DISCIPLINES_START });
    const response = await api.updateSkill(id, data);
    const skill = response.data;
    dispatch({ type: UPDATE_SKILL_SUCCESS, payload: { skill } });
    return skill;
  } catch (error) {
    dispatch({ type: UPDATE_SKILL_ERROR, error });
    throw error;
  }
};

export const deleteSkill = (disciplineId, skillId) => async dispatch => {
  try {
    dispatch({ type: CHANGE_DISCIPLINES_START });
    await api.deleteSkill(skillId);
    dispatch({
      type: DELETE_SKILL_SUCCESS,
      payload: { disciplineId, skillId },
    });
  } catch (error) {
    dispatch({ type: DELETE_SKILL_ERROR, error });
    throw error;
  }
};
