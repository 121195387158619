import React, { Component } from 'react';
import i18n from 'services/i18n';

@i18n('preview')
export default class Circuite extends Component {
  renderSlots = timeSlots => {
    let rotationCounter = 0;
    return timeSlots.map((slot, index) => {
      const { timeSlotType } = slot;
      switch (timeSlotType) {
        case 'LUNCH_BREAK':
          return this.renderBreak('annotation_lunch-break', slot, index);
        case 'ROTATION_BREAK':
          return this.renderBreak('annotation_rotation-break', slot, index);
        case 'COFFEE_BREAK':
          return this.renderBreak('annotation_coffee-break', slot, index);
        case 'ROTATION_LOGICAL_BREAK':
          rotationCounter++;
          return this.renderRotationLogicalBreak(slot, index, rotationCounter);
        default:
          rotationCounter++;
          return this.renderOneSlot(slot, index, rotationCounter);
      }
    });
  };

  getCard = (timeSlots, circuitsNumber) => (
    <table className="preview" key={circuitsNumber}>
      <thead className="preview_header">
        <tr>
          <th className="preview_header-item">
            {this.props.translate('circuit')} {circuitsNumber}
          </th>
          <th className="preview_header-item preview_header-item-time">
            Start Time
          </th>
          <th className="preview_header-item preview_header-item-time">
            End Time
          </th>
        </tr>
      </thead>
      <tbody>{this.renderSlots(timeSlots)}</tbody>
    </table>
  );

  renderOneSlot = (slot, index, rotationCounter) => (
    <tr key={index}>
      <td className="slot">Rotation {rotationCounter}</td>
      <td className="slot time_slot start_time">{slot.startTime}</td>
      <td className="slot time_slot">{slot.endTime}</td>
    </tr>
  );

  renderRotationLogicalBreak = (slot, index, rotationCounter) => (
    <tr key={index}>
      <td className="slot">
        <span>
          {this.props.translate('rotation')} {rotationCounter}
        </span>
        <span className="slot_logical-mark">L</span>
      </td>
      <td className="slot time_slot start_time">{slot.startTime}</td>
      <td className="slot time_slot">{slot.endTime}</td>
    </tr>
  );

  renderBreak = (className, slot, index) => (
    <tr key={index}>
      <td className={`slot_break ${className}`} colSpan={3}>
        {slot.startTime} - {slot.endTime}
      </td>
    </tr>
  );

  render() {
    return (
      <div className="days-container">
        {this.props.day.circuits.map((item, i) =>
          this.getCard(item.timeSlots, i + 1),
        )}
      </div>
    );
  }
}
