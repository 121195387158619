export const checkPositiveNumber = (rule, value, callback) => {
  if (value < 0) {
    callback(new Error());
  } else {
    callback();
  }
};

export const createMaxNumberChecker = maxValue => (rule, value, callback) => {
  if (value > maxValue) {
    callback(new Error());
  } else {
    callback();
  }
};

// IBAN

// A "constant" lookup table of IBAN lengths per country
// (the funky formatting is just to make it fit better in the answer here on CR)
const CODE_LENGTHS = {
  AD: 24,
  AE: 23,
  AT: 20,
  AZ: 28,
  BA: 20,
  BE: 16,
  BG: 22,
  BH: 22,
  BR: 29,
  CH: 21,
  CR: 21,
  CY: 28,
  CZ: 24,
  DE: 22,
  DK: 18,
  DO: 28,
  EE: 20,
  ES: 24,
  FI: 18,
  FO: 18,
  FR: 27,
  GB: 22,
  GI: 23,
  GL: 18,
  GR: 27,
  GT: 28,
  HR: 21,
  HU: 28,
  IE: 22,
  IL: 23,
  IS: 26,
  IT: 27,
  JO: 30,
  KW: 30,
  KZ: 20,
  LB: 28,
  LI: 21,
  LT: 20,
  LU: 20,
  LV: 21,
  MC: 27,
  MD: 24,
  ME: 22,
  MK: 19,
  MR: 27,
  MT: 31,
  MU: 30,
  NL: 18,
  NO: 15,
  PK: 24,
  PL: 28,
  PS: 29,
  PT: 25,
  QA: 29,
  RO: 24,
  RS: 22,
  SA: 24,
  SE: 24,
  SI: 19,
  SK: 24,
  SM: 27,
  TN: 24,
  TR: 26,
};

// piece-wise mod97 using 9 digit "chunks", as per Wikipedia's example:
// http://en.wikipedia.org/wiki/International_Bank_Account_Number#Modulo_operation_on_IBAN
function mod97(string) {
  let checksum = string.slice(0, 2);
  let fragment;

  for (let offset = 2; offset < string.length; offset += 7) {
    fragment = String(checksum) + string.substring(offset, offset + 7);
    checksum = parseInt(fragment, 10) % 97;
  }

  return checksum;
}

export const isValidIBAN = (rule, input, cb) => {
  if (!input) {
    return cb();
  }
  const iban = String(input)
    .toUpperCase()
    .replace(/[^A-Z0-9]/g, ''); // keep only alphanumeric characters
  const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/); // match and capture (1) the country code, (2) the check digits, and (3) the rest

  // check syntax and length
  if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
    return cb(new Error());
  }

  // rearrange country code and check digits, and convert chars to ints
  const digits = (code[3] + code[1] + code[2]).replace(
    /[A-Z]/g,
    letter => letter.charCodeAt(0) - 55,
  );

  // final check
  return cb(mod97(digits) === 1 ? undefined : new Error());
};

export const onlyNumber = (rule, value, callback) => {
  if (value && Number.isNaN(Number(value))) {
    callback(new Error());
  } else {
    callback();
  }
};

export const phoneNumber = (rule, value, callback) => {
  const phoneNumberRegExp = /^(\+[0-9]{2})(\s?)([0-9 ]+?)$/;
  if (value && !value.match(phoneNumberRegExp)) {
    callback(new Error());
  } else {
    callback();
  }
};

function computeAhvn13CheckDigit(ahvn13str) {
  let tmpNumber = ahvn13str.substr(0, ahvn13str.length - 1);

  tmpNumber = tmpNumber
    .split('.')
    .join('')
    .split('')
    .reverse()
    .join('');
  let sum = 0;
  for (let i = 0; i < tmpNumber.length; i++) {
    const add = i % 2 === 0 ? tmpNumber[i] * 3 : tmpNumber[i] * 1;
    sum += add;
  }

  return Math.ceil(sum / 10) * 10 - sum;
}

export const ahvNumber = (rule, value, callback) => {
  const ahvRegExp = /^756\.(\d{4})\.(\d{4})\.(\d{2})$/; // format is 756.0000.0000.00

  if (!value) {
    callback();
  } else if (!value.match(ahvRegExp)) {
    callback(new Error());
  } else if (
    computeAhvn13CheckDigit(value) !== parseInt(value.substr(-1), 10)
  ) {
    callback(new Error());
  } else {
    callback();
  }
};
