import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

/** Constants */
import { STATION_TYPES } from 'constants/common';

/** Components */
import StudentData from '../../PopUpContent/StudentData';
import PatientData from '../../PopUpContent/PatientData';
import ExaminerData from '../../PopUpContent/ExaminerData';

/**
 * Component for rendering data inside cell rotation
 * @reactProps {string} name - name of type cell
 * @reactProps {number} index - index in array
 */
export default class TimeSlotData extends PureComponent {
  static propsTypes = {
    rotations: PropTypes.array,
    stationId: PropTypes.number,
    earlyStart: PropTypes.bool,
    earlyEnd: PropTypes.bool,
    slotPosition: PropTypes.string,
    positionInStation: PropTypes.number,
    studentAssignments: PropTypes.array,
    selectedSpAssignments: PropTypes.array,
    examinerAssignment: PropTypes.array,
    name: PropTypes.string,
    data: PropTypes.array,
    hasStartBreak: PropTypes.bool,
    hasEndBreak: PropTypes.bool,
    isSpPaused: PropTypes.bool,
    osceDayId: PropTypes.number,
  };

  getContentByTypeOfPerson = (el, index) => {
    if (!el) {
      return null;
    }
    const { name, timeSlotId, rotations, circuitId, osceDayId } = this.props;
    switch (name) {
      case 'students':
        return <StudentData student={el} key={index} osceDayId={osceDayId} />;
      case 'simulated-patient':
        return <PatientData patient={el} key={index} />;
      case 'examiner': {
        return (
          <ExaminerData
            key={index}
            examiner={el}
            circuitId={circuitId}
            timeSlotId={timeSlotId}
            circuitRotations={rotations}
          />
        );
      }
      default:
        return {};
    }
  };

  generateContent = () =>
    this.props.data.map((person, i) =>
      this.getContentByTypeOfPerson(person, i),
    );

  render() {
    const {
      name,
      data,
      hasStartBreak,
      hasEndBreak,
      isSpPaused,
      stationType,
      studentAssignments,
    } = this.props;
    return !isSpPaused || (isSpPaused && name === 'simulated-patient') ? (
      <div
        className={cn(`${name} column`, {
          hasStartBreak,
          hasEndBreak,
          isEmpty:
            stationType === STATION_TYPES.DOUBLE &&
            !isSpPaused &&
            !studentAssignments.length,
        })}
      >
        {data && data.length ? (
          <>
            {hasStartBreak && <span className="break-cell" />}
            {(isSpPaused || !!studentAssignments.length) &&
              this.generateContent()}
            {hasEndBreak && <span className="break-cell" />}
          </>
        ) : null}
      </div>
    ) : null;
  }
}
