import React from 'react';
import { Redirect, Switch, Route } from 'react-router';

import MainLayout from 'components/MainLayout';

/** Constants */
import * as SIDEBARS from 'constants/sidebars';

/** Components **/
import Patients from 'pages/simulatedPatients/patients/Patients';
import Patient from 'pages/simulatedPatients/patientView';
import Professions from 'pages/simulatedPatients/professions/Professions';
import Questionnaire from 'pages/simulatedPatients/questionnaire/Questionnaire';

/** Containers **/

const CurrentView = ({ match }) => {
  const { url } = match;

  return (
    <Switch>
      <Route path={`${url}/patients`} component={Patients} />
      <Redirect
        exact
        from={`${url}/patient-profile/:id`}
        to={`${url}/patient-profile/:id/patient-profile`}
      />
      <Route path={`${url}/patient-profile/:id`} component={Patient} />
      <Route
        path={`${url}/patient-profile/:id/patient-profile`}
        component={Patient}
      />
      <Route
        path={`${url}/patient-profile/:id/questionnaire`}
        component={Patient}
      />
      <Route path={`${url}/professions`} component={Professions} />
      <Route path={`${url}/questionnaire`} component={Questionnaire} />
    </Switch>
  );
};

const SimulatedPatients = props => {
  return (
    <MainLayout menu={SIDEBARS.PATIENT_MENU} nameMenu="patients_sidebar">
      <CurrentView {...props} />
    </MainLayout>
  );
};

export default SimulatedPatients;
