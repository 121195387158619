import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { get } from 'lodash';
import { withRouter } from 'react-router';

/** UI*/
import { Button, InputNumber, Popover } from 'antd';

/** Actions*/
import { moveBreak } from 'actions/circuits';
import {
  changeDuration,
  getScheduleAssignment,
  getScheduleOsceDay,
} from 'actions/schedule';

/** Services */
import { msgError } from 'services/errorHandler';
import closest from 'services/closest';
import addLoadWithRedux from 'services/decorators/addLoadWithRedux';
import { calculateDiff } from 'services/format';

/**
//  * Component for rendering content inside pop up about timeSlot
 * @reactProps {func} translate - function for translate text
 * @reactProps {string} osceDayId - id current Osce Day
 * @reactProps {bool} isDisabled - is coffee break inside rotation
 * @reactProps {object} timeSlot - time slot data
 */
@withRouter
@addLoadWithRedux({
  i18Name: 'circuit',
  runtimeReduxNames: ['schedule'],
  mapMethods: { getScheduleAssignment },
})
export default class BreakData extends Component {
  static propsTypes = {
    translate: PropTypes.func,
    isDisabled: PropTypes.bool,
    osceDayId: PropTypes.number,
    timeSlot: PropTypes.object,
    isLunchBreakBetweenOsceSequences: PropTypes.bool,
    sequenceNumberLastBreak: PropTypes.number,
    isFirstBreak: PropTypes.bool,
  };

  state = {
    duration: null,
    visible: false,
  };

  componentDidMount() {
    document.body.addEventListener('click', this.checkElement);
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.checkElement);
  }

  checkElement = e => {
    if (
      closest(e.target, '.ant-popover') === null &&
      closest(e.target, '.ant-select-dropdown') === null
    ) {
      this.setState({ visible: false });
    }
  };

  changeDuration = duration => {
    this.setState({
      duration,
    });
  };

  togglePopover = () => this.setState({ visible: !this.state.visible });

  getNewDataSchedule = async () => {
    const { osceDayId, scheduleData, updateData } = this.props;
    const { data } = await getScheduleOsceDay(osceDayId);
    const newData = [...scheduleData.osceDays];
    const osceDayIndex = newData.findIndex(item => item.id === osceDayId);
    if (osceDayIndex > -1) {
      newData.splice(osceDayIndex, 1, data);
    }
    return updateData({ osceDays: newData }, 'schedule');
  };

  saveDuration = data => async () => {
    try {
      await changeDuration(data);
      await this.getNewDataSchedule();
      this.setState({ visible: false });
    } catch (e) {
      msgError(e);
    }
  };

  moveBreak = (timeSlotId, sooner) => async () => {
    try {
      await moveBreak(timeSlotId, { sooner });
      await this.getNewDataSchedule();
      this.setState({ visible: false });
    } catch (e) {
      msgError(e);
    }
  };

  get contentTimeSlotBreak() {
    const {
      translate,
      osceDayId,
      timeSlot,
      isFirstBreak,
      sequenceNumberLastBreak,
      isDisabled,
    } = this.props;
    const { duration } = this.state;
    if (!timeSlot) return false;
    const durationBreak = calculateDiff(timeSlot);
    const data = {
      osceDayId,
      sequenceNumber: get(timeSlot, 'sequenceNumber'),
      newDuration: duration || durationBreak,
    };
    return (
      <React.Fragment>
        <div className="wrapper">
          <div className="fields">
            <p>Break Type: </p>
            <p>
              {translate(
                get(timeSlot, 'timeSlotType') || get(timeSlot, 'breakType'),
              )}
            </p>
          </div>
          <div className="fields">
            <p>Start Time: </p>
            <p>{get(timeSlot, 'startTime')}</p>
          </div>
          <div className="fields">
            <p>Duration: </p>
            {isDisabled ? (
              <p>{durationBreak}</p>
            ) : (
              <div className="fields_item">
                <InputNumber
                  onChange={this.changeDuration}
                  value={duration || durationBreak}
                />
              </div>
            )}
          </div>
        </div>
        {!isDisabled && (
          <React.Fragment>
            <div className="move-btns">
              <Button
                disabled={isFirstBreak}
                onClick={this.moveBreak(timeSlot.id, true)}
              >
                Move Sooner
              </Button>
              <Button
                disabled={
                  get(timeSlot, 'laterDisabled') ||
                  get(timeSlot, 'sequenceNumber') === sequenceNumberLastBreak
                }
                onClick={this.moveBreak(timeSlot.id, false)}
              >
                Move Later
              </Button>
            </div>
            <div className="footer-btns">
              <Button
                type="primary"
                className="btn"
                onClick={this.saveDuration(data)}
              >
                Save
              </Button>
              <Button
                className="btn"
                onClick={() => this.setState({ visible: false })}
              >
                Cancel
              </Button>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  render() {
    const { children, showBigBreak, isDisabled } = this.props;
    return (
      <Popover
        placement="right"
        content={this.contentTimeSlotBreak}
        trigger="click"
        visible={this.state.visible}
        onClick={this.togglePopover}
      >
        {children || (
          <div
            className={cn('break', {
              break_higher: showBigBreak,
              break_coffeeBreak: isDisabled,
            })}
          />
        )}
      </Popover>
    );
  }
}
