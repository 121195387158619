import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Form, Button } from 'antd';
import { get } from 'lodash';

import ErrorItem from 'components/Form/formError';
import { buildCRUD } from 'utils/ajax';
import i18n from 'services/i18n';
import { HEADER_MENU } from 'constants/common';
import FormItemInput from 'components/Form/formInput';

import Header from './header';
import './loginStyles.less';

const FormItem = Form.Item;

@i18n('form')
@withRouter
@Form.create({})
export default class Login extends Component {
  static childContextTypes = {
    form: PropTypes.object,
  };

  state = {
    loading: false,
    errorText: '',
  };

  getChildContext() {
    return { form: this.props.form };
  }

  loginSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (err) return;
      try {
        this.state.loading = true;
        const user = await buildCRUD('/public/authentication').post({
          email: values.username,
          password: values.password,
        });
        const administratorDto = get(user.data, 'administratorDto') || {};
        localStorage.setItem('token', user.data.token);
        localStorage.setItem(
          'fullname',
          `${administratorDto.firstName} ${administratorDto.lastName}`,
        );
        const roles = JSON.stringify(administratorDto.roles);
        localStorage.setItem('roles', roles);
        const allowedItem = HEADER_MENU.filter(
          item => roles.indexOf(item.access) > -1,
        );
        this.props.history.push(allowedItem[0].url);
      } catch (error) {
        console.error(error);
        !this.state.errorText && this.toggleError('form.incorrect');
      }
      this.setState(() => ({
        loading: false,
      }));
    });
  };

  toggleError = errorText => {
    this.setState(state => ({ errorText }));
  };

  render() {
    const { translate } = this.props;
    return (
      <Form
        onSubmit={this.loginSubmit}
        className="login_form"
        autoComplete="off"
      >
        <Header name="form.login" />
        <FormItemInput
          name="username"
          icon="user"
          required
          placeholder
          autoComplete="new-password"
        />
        <FormItemInput
          name="password"
          autoComplete="new-password"
          icon="lock"
          type="password"
          required
          placeholder
        />
        <ErrorItem
          toggleError={this.toggleError}
          errorText={this.state.errorText}
        />
        <FormItem>
          <Link to="/auth/email" className="login_forgot_link">
            {translate('forgotPassword')}
          </Link>
        </FormItem>
        <FormItem>
          <Button
            type="primary"
            htmlType="submit"
            className="blueBtn login_max_width"
          >
            {translate('login')}
          </Button>
        </FormItem>
      </Form>
    );
  }
}
