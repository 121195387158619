import { combineReducers } from 'redux';
import {
  OSCE_REQUEST_START,
  FETCH_OSCE_RESULTS_SUCCESS,
  FETCH_OSCE_RESULTS_ERROR,
  LOAD_MORE_RESULTS_SUCCESS,
  FETCH_OSCE_BELL_SYSTEM_SUCCESS,
  FETCH_OSCE_BELL_SYSTEM_ERROR,
  UPDATE_OSCE_BELL_SYSTEM_SUCCESS,
  UPDATE_OSCE_BELL_SYSTEM_ERROR,
} from 'constants/osce';

const loading = (state = false, action) => {
  switch (action.type) {
    case OSCE_REQUEST_START:
      return true;

    case FETCH_OSCE_RESULTS_SUCCESS:
    case FETCH_OSCE_RESULTS_ERROR:
    case LOAD_MORE_RESULTS_SUCCESS:
    case FETCH_OSCE_BELL_SYSTEM_SUCCESS:
    case FETCH_OSCE_BELL_SYSTEM_ERROR:
    case UPDATE_OSCE_BELL_SYSTEM_SUCCESS:
    case UPDATE_OSCE_BELL_SYSTEM_ERROR:
      return false;

    default:
      return state;
  }
};

const errors = (state = {}, action) => {
  switch (action.type) {
    case FETCH_OSCE_RESULTS_ERROR:
    case FETCH_OSCE_BELL_SYSTEM_ERROR:
    case UPDATE_OSCE_BELL_SYSTEM_ERROR:
      return action.error;
    default:
      return state;
  }
};

const results = (state = [], action) => {
  switch (action.type) {
    case FETCH_OSCE_RESULTS_SUCCESS:
      return action.payload.results.data;

    case LOAD_MORE_RESULTS_SUCCESS:
      return state.concat(action.payload.results.data);

    default:
      return state;
  }
};

const bellSystem = (state = [], action) => {
  switch (action.type) {
    case FETCH_OSCE_BELL_SYSTEM_SUCCESS:
    case UPDATE_OSCE_BELL_SYSTEM_SUCCESS:
      return action.payload.bellSystem;

    default:
      return state;
  }
};

const osce = combineReducers({
  loading,
  errors,
  results,
  bellSystem,
});

export default osce;
