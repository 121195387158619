import React, { Component } from 'react';
import { get } from 'lodash';
import { Col, Row } from 'antd';

import EditableLabel from 'components/Form/formEditableLabel';
import Card from 'components/Form/Card';

export default class StorageCredentials extends Component {
  renderPassword = password => {
    if (!password) return null;
    const arr = [];
    for (let i = 0; i < password.length; i++) arr.push('*');
    return arr;
  };

  render() {
    const { data } = this.props;
    return (
      <Card
        from="cards"
        value="storageCredentials"
        className="osce-settings-card osce-settings-card_border"
      >
        <Row gutter={48}>
          <Col span={12}>
            <EditableLabel
              name="username"
              initialValue={get(data, 'username')}
            />
            <EditableLabel
              name="password"
              initialValue={get(data, 'password')}
              autoComplete="new-password"
              type="password"
              staticRenderFunction={this.renderPassword}
            />
          </Col>
          <Col span={12}>
            <EditableLabel
              name="remoteDirPath"
              title="basePath"
              initialValue={get(data, 'remoteDirPath')}
            />
            <EditableLabel name="host" initialValue={get(data, 'host')} />
          </Col>
        </Row>
      </Card>
    );
  }
}
