import React, { Component } from 'react';
import { get } from 'lodash';

import { onlyNumber } from 'services/validations';
import EditableLable from 'components/Form/formEditableLabel';
import Card from 'components/Form/Card';

export default class UnNamedInfo extends Component {
  render() {
    const { data } = this.props;
    return (
      <Card className="card-student">
        <EditableLable name="city" initialValue={get(data, 'city')} />
        <EditableLable
          name="zip"
          title="postalCode"
          initialValue={get(data, 'zip')}
          validator={{
            func: onlyNumber,
            message: 'notValidPostalCode',
          }}
        />
        <EditableLable name="street" initialValue={get(data, 'street')} />
      </Card>
    );
  }
}
