import React, { Component } from 'react';

import i18n from 'services/i18n';

import './preview.less';

@i18n('form')
export default class PreviewAnotationItem extends Component {
  render() {
    const { props } = this;
    let className = 'annotation_rectangle';
    if (props.className) className += ` ${props.className}`;
    return (
      <div className="annotation_item-wrapper">
        <span className={className}>{props.text}</span>
        <span className="annotation_text">{props.children}</span>
      </div>
    );
  }
}
