import React, { Component } from 'react';
import { Button, Icon, Spin } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get, find } from 'lodash';

import TablePanel from 'components/TablePanel';
import Table from 'components/Table';
import i18n from 'services/i18n';
import i18nTable from 'services/i18nTable';
import { catalogCRUD } from 'actions/cruds';
import stateSend from 'services/stateSend';
import {
  getCatalogs,
  updateCatalogs,
  changeStatusCatalog,
} from 'actions/administrator';
import { updateData } from 'actions/updateData';
import notification from 'services/decorators/notif';
import { msgLocalError } from 'services/errorHandler';

const mapStateToProps = ({ runtime }) => ({
  list: get(runtime, 'catalogsData.data'),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { dispatch, getCatalogs, updateCatalogs, updateData },
    dispatch,
  );

@notification()
@i18n('catalogs')
@i18nTable('catalogsColumns')
@connect(
  mapStateToProps,
  mapDispatchToProps,
)
export default class Catalogs extends Component {
  state = { loading: false };

  constructor(props) {
    super(props);
    this.columns = props.catalogsColumns.map(item => ({ ...item }));
    this.columns.push({
      title: '',
      dataIndex: '',
      width: '300px',
      render: this.renderAdditionalBtns,
    });
  }

  renderAdditionalBtns = (text, record) => {
    const { translate } = this.props;
    if (record.enabled)
      return (
        <a
          className="action-btn"
          onClick={() => this.changeStatus(record.id, false)}
        >
          <Icon type="close-circle-o" />{' '}
          {translate('deactivate', 'administratorsColumns')}
        </a>
      );
    if (!record.enabled)
      return (
        <a
          className="action-btn"
          onClick={() => this.changeStatus(record.id, true)}
        >
          <Icon type="check-circle-o" />{' '}
          {translate('activate', 'administratorsColumns')}
        </a>
      );
    return null;
  };

  changeStatus = async (id, status) => {
    const { props } = this;
    try {
      await stateSend(
        loading => this.setState({ loading }),
        changeStatusCatalog(id, { enabled: status }),
      );
      const newData = [...props.list];
      const target = find(newData, { id });
      target.enabled = status;
      props.updateData({ data: newData }, 'catalogs');
      props.notifySuccess('success');
    } catch (error) {
      msgLocalError({ error, translate: props.translate });
    }
  };

  render() {
    return (
      <React.Fragment>
        <TablePanel name="catalogs" action={catalogCRUD}>
          <Button
            icon="reload"
            className="table-panel_btn-right"
            onClick={this.props.updateCatalogs}
          >
            {this.props.translate('updateAll')}
          </Button>
        </TablePanel>
        <Spin spinning={this.state.loading}>
          <div className="table-with-action">
            <Table
              name="catalogs"
              action={catalogCRUD}
              columns={this.columns}
            />
          </div>
        </Spin>
      </React.Fragment>
    );
  }
}
