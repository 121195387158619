import { combineReducers } from 'redux';
import {
  ROLE_REQUEST_START,
  FETCH_ROLE_SKILLS_SUCCESS,
  FETCH_ROLE_SKILLS_ERROR,
  FETCH_ROLE_INFO_SUCCESS,
  FETCH_ROLE_INFO_ERROR,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_ERROR,
  FETCH_ROLES_SUCCESS,
  FETCH_ROLES_ERROR,
  FETCH_ROLES_CRITERIA_SUCCESS,
  FETCH_ROLES_CRITERIA_ERROR,
  DELETE_ROLE_CRITERION_SUCCESS,
  DELETE_ROLE_CRITERION_ERROR,
  UPDATE_ROLE_CRITERION_SUCCESS,
  UPDATE_ROLE_CRITERION_ERROR,
  LOAD_MORE_CRITERIA_SUCCESS,
  FETCH_ALL_CATALOGS_SUCCESS,
  FETCH_ALL_CATALOGS_ERROR,
  CREATE_ROLE_CRITERION_SUCCESS,
  CREATE_ROLE_CRITERION_ERROR,
  FETCH_ALL_ROLES_OSCES_SUCCESS,
  FETCH_ALL_ROLES_OSCES_ERROR,
  LOAD_MORE_ROLES_OSCES_SUCCESS,
} from 'constants/role';

const loading = (state = false, action) => {
  switch (action.type) {
    case ROLE_REQUEST_START:
      return true;

    case FETCH_ROLE_SKILLS_SUCCESS:
    case FETCH_ROLE_SKILLS_ERROR:
    case FETCH_ROLE_INFO_SUCCESS:
    case FETCH_ROLE_INFO_ERROR:
    case UPDATE_ROLE_SUCCESS:
    case UPDATE_ROLE_ERROR:
    case FETCH_ROLES_SUCCESS:
    case FETCH_ROLES_ERROR:
    case FETCH_ROLES_CRITERIA_SUCCESS:
    case FETCH_ROLES_CRITERIA_ERROR:
    case DELETE_ROLE_CRITERION_SUCCESS:
    case DELETE_ROLE_CRITERION_ERROR:
    case UPDATE_ROLE_CRITERION_SUCCESS:
    case UPDATE_ROLE_CRITERION_ERROR:
    case LOAD_MORE_CRITERIA_SUCCESS:
    case FETCH_ALL_CATALOGS_SUCCESS:
    case FETCH_ALL_CATALOGS_ERROR:
    case CREATE_ROLE_CRITERION_SUCCESS:
    case CREATE_ROLE_CRITERION_ERROR:
    case FETCH_ALL_ROLES_OSCES_SUCCESS:
    case FETCH_ALL_ROLES_OSCES_ERROR:
    case LOAD_MORE_ROLES_OSCES_SUCCESS:
      return false;

    default:
      return state;
  }
};

const errors = (state = {}, action) => {
  switch (action.type) {
    case FETCH_ROLE_SKILLS_ERROR:
    case FETCH_ROLE_INFO_ERROR:
    case UPDATE_ROLE_ERROR:
    case FETCH_ROLES_ERROR:
    case FETCH_ROLES_CRITERIA_ERROR:
    case DELETE_ROLE_CRITERION_ERROR:
    case UPDATE_ROLE_CRITERION_ERROR:
    case FETCH_ALL_CATALOGS_ERROR:
    case FETCH_ALL_ROLES_OSCES_ERROR:
      return action.error;
    default:
      return state;
  }
};

const skills = (state = [], action) => {
  switch (action.type) {
    case FETCH_ROLE_SKILLS_SUCCESS: {
      return action.payload.skills.data;
    }

    default:
      return state;
  }
};

const info = (state = {}, action) => {
  switch (action.type) {
    case FETCH_ROLE_INFO_SUCCESS:
      return action.payload.info;

    case UPDATE_ROLE_SUCCESS:
      return action.payload.role;

    default:
      return state;
  }
};

const criteria = (state = [], action) => {
  switch (action.type) {
    case FETCH_ROLES_CRITERIA_SUCCESS: {
      return action.payload.criteria.data;
    }

    case LOAD_MORE_CRITERIA_SUCCESS: {
      return state.concat(action.payload.criteria);
    }

    case DELETE_ROLE_CRITERION_SUCCESS: {
      const { criterionId } = action.payload;
      const newState = state.filter(criterion => criterion.id !== criterionId);

      return newState;
    }

    case UPDATE_ROLE_CRITERION_SUCCESS: {
      const { criterion } = action.payload;
      const newState = state.map(c => (c.id === criterion.id ? criterion : c));

      return newState;
    }

    default:
      return state;
  }
};

const data = (state = [], action) => {
  switch (action.type) {
    case FETCH_ROLES_SUCCESS:
      return action.payload.roles.data;

    default:
      return state;
  }
};

const catalogs = (state = [], action) => {
  switch (action.type) {
    case FETCH_ALL_CATALOGS_SUCCESS:
      return action.payload.catalogs.data;

    default:
      return state;
  }
};

const osces = (state = [], action) => {
  switch (action.type) {
    case FETCH_ALL_ROLES_OSCES_SUCCESS:
      return action.payload.osces.data;

    case LOAD_MORE_ROLES_OSCES_SUCCESS:
      return state.concat(action.payload.osces.data);

    default:
      return state;
  }
};

const role = combineReducers({
  loading,
  errors,
  skills,
  criteria,
  info,
  data,
  catalogs,
  osces,
});

export default role;
