import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { Form, Row, Col, Input, Select, Button, Spin, message } from 'antd';

import { getRoleInfo, updateRole, getAllSkills } from 'actions/role';
import { ROLE_TYPE, STATISTIC_FACTOR } from 'constants/options';
import { msgLocalError } from 'services/errorHandler';
import {
  checkPositiveNumber,
  createMaxNumberChecker,
} from 'services/validations';
import i18n from 'services/i18n';
import api from 'services/api';
// Components
import LazySelect from 'components/LazySelect';
import EditableField from 'components/EditableField';

const graterThenTen = createMaxNumberChecker(10);
const graterThenFive = createMaxNumberChecker(5);

const mapStateToProps = ({ role }) => {
  const { info, skills, loading } = role;
  const selectedSkill = skills.find(skill => skill.id === info.skillId);
  const selectedSkillName = selectedSkill ? selectedSkill.name : '';

  return {
    role: info,
    loading,
    selectedSkillName,
  };
};

const mapDispatchToProps = {
  getAllSkills,
  getRoleInfo,
  updateRole,
};
@withRouter
@i18n('form')
@Form.create({})
@connect(
  mapStateToProps,
  mapDispatchToProps,
)
export default class RoleInformation extends Component {
  static propTypes = {
    role: PropTypes.shape({
      id: PropTypes.number,
      idForMergingVersions: PropTypes.number,
      isUsedInOsce: PropTypes.bool,
      lastVersion: PropTypes.bool,
      majorVersion: PropTypes.number,
      minorVersion: PropTypes.number,
      roleInformationResponse: PropTypes.object,
      skillId: PropTypes.number,
      targetSystemResponses: PropTypes.array,
      versions: PropTypes.array,
    }).isRequired,
    selectedSkillName: PropTypes.string,
    loading: PropTypes.bool,
  };

  static defaultProps = {
    loading: false,
    selectedSkillName: '',
  };

  state = {
    editable: '',
  };

  componentDidMount() {
    this.props.getRoleInfo(this.props.match.params.id);
    this.props.getAllSkills({ search: '', page: 0, pageSize: 1000000 }); //BE can not disable pagination so to get list of all skills
  }

  onSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const data = {
          ...values,
          isUsedInOsce: this.props.role.isUsedInOsce,
        };

        this.props
          .updateRole(this.props.role.id, data)
          .then(role => {
            this.props.history.push(`/roles/role/${role.id}`);
          })
          .catch(error => {
            if (
              error.response.status === 400 &&
              error.response.data.errorCode === 1800
            ) {
              message.error(error.response.data.message);
            } else {
              msgLocalError({ error, translate: this.props.translate });
            }
          });

        this.stopEditField();
      }
    });
  };

  editField = fieldName => this.setState({ editable: fieldName });

  stopEditField = () => this.setState({ editable: '' });

  handleLoadSkills = data => {
    return api.getAllSkills(data).then(res => res.data);
  };

  render() {
    const { role } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <Spin spinning={this.props.loading}>
        {this.props.role.id && (
          <Form onSubmit={this.onSubmit} className="role_form">
            <React.Fragment>
              <Row>
                <p className="role_general_header">
                  {this.props.translate(
                    'roleInformation',
                    'roleDefinitionColumns',
                  )}
                </p>
              </Row>
              <Row>
                <Col span={10}>
                  <EditableField
                    editable={this.state.editable === 'roleDesignation'}
                    readOnly={!role.lastVersion}
                    label={this.props.translate('roleDesignation')}
                    value={role.roleInformationResponse.roleDesignation}
                    edit={() => this.editField('roleDesignation')}
                  >
                    <React.Fragment>
                      {getFieldDecorator('roleDesignation', {
                        initialValue:
                          role.roleInformationResponse.roleDesignation,
                        rules: [
                          {
                            required: true,
                            message: this.props.translate('insert', 'error'),
                          },
                        ],
                      })(<Input type="text" />)}
                      <div className="card-btns-container">
                        <Button
                          shape="circle"
                          icon="close"
                          size="small"
                          onClick={this.stopEditField}
                        />
                        <Button
                          shape="circle"
                          type="primary"
                          icon="check"
                          size="small"
                          htmlType="submit"
                          onClick={this.onSubmit}
                        />
                      </div>
                    </React.Fragment>
                  </EditableField>
                  <EditableField
                    editable={this.state.editable === 'acronym'}
                    readOnly={!role.lastVersion}
                    label={this.props.translate('acronym')}
                    value={role.roleInformationResponse.acronym}
                    edit={() => this.editField('acronym')}
                  >
                    <React.Fragment>
                      {getFieldDecorator('acronym', {
                        initialValue: role.roleInformationResponse.acronym,
                        rules: [
                          {
                            required: true,
                            message: this.props.translate('insert', 'error'),
                          },
                        ],
                      })(<Input type="text" />)}
                      <div className="card-btns-container">
                        <Button
                          shape="circle"
                          icon="close"
                          size="small"
                          onClick={this.stopEditField}
                        />
                        <Button
                          shape="circle"
                          type="primary"
                          icon="check"
                          size="small"
                          htmlType="submit"
                          onClick={this.onSubmit}
                        />
                      </div>
                    </React.Fragment>
                  </EditableField>
                  <EditableField
                    editable={this.state.editable === 'roleType'}
                    readOnly={!role.lastVersion}
                    label={this.props.translate('roleType')}
                    value={this.props.translate(
                      role.roleInformationResponse.roleType,
                    )}
                    edit={() => this.editField('roleType')}
                  >
                    <React.Fragment>
                      {getFieldDecorator('roleType', {
                        initialValue: role.roleInformationResponse.roleType,
                      })(
                        <Select>
                          {ROLE_TYPE.map(type => (
                            <Select.Option key={type.value}>
                              {this.props.translate(type.value)}
                            </Select.Option>
                          ))}
                        </Select>,
                      )}
                      <div className="card-btns-container">
                        <Button
                          shape="circle"
                          icon="close"
                          size="small"
                          onClick={this.stopEditField}
                        />
                        <Button
                          shape="circle"
                          type="primary"
                          icon="check"
                          size="small"
                          htmlType="submit"
                          onClick={this.onSubmit}
                        />
                      </div>
                    </React.Fragment>
                  </EditableField>
                  <EditableField
                    readOnly
                    label={this.props.translate('studyYear')}
                    value={role.roleInformationResponse.studyYear}
                  />
                </Col>
                <Col span={10} offset={2}>
                  <EditableField
                    editable={this.state.editable === 'skillId'}
                    readOnly={!role.lastVersion}
                    label={this.props.translate('skill')}
                    value={this.props.selectedSkillName}
                    edit={() => this.editField('skillId')}
                  >
                    <React.Fragment>
                      {getFieldDecorator('skillId', {
                        initialValue: role.skillId,
                      })(
                        <LazySelect
                          dropdownMatchSelectWidth
                          loadData={this.handleLoadSkills}
                        />,
                      )}
                      <div className="card-btns-container">
                        <Button
                          shape="circle"
                          icon="close"
                          size="small"
                          onClick={this.stopEditField}
                        />
                        <Button
                          shape="circle"
                          type="primary"
                          icon="check"
                          size="small"
                          htmlType="submit"
                          onClick={this.onSubmit}
                        />
                      </div>
                    </React.Fragment>
                  </EditableField>
                  <EditableField
                    editable={
                      this.state.editable === 'absoluteNumberOfBackupSPs'
                    }
                    readOnly={!role.lastVersion}
                    label={this.props.translate('absoluteNumberOfBackupSPs')}
                    value={
                      role.roleInformationResponse.absoluteNumberOfBackupSPs
                    }
                    edit={() => this.editField('absoluteNumberOfBackupSPs')}
                  >
                    <React.Fragment>
                      {getFieldDecorator('absoluteNumberOfBackupSPs', {
                        initialValue:
                          role.roleInformationResponse
                            .absoluteNumberOfBackupSPs,
                        rules: [
                          {
                            required: true,
                            message: this.props.translate('insert', 'error'),
                          },
                          {
                            validator: checkPositiveNumber,
                            message: this.props.translate(
                              'onlyPositiveNumber',
                              'error',
                            ),
                          },
                          {
                            validator: graterThenTen,
                            message: 'Can not be grater then 10',
                          },
                        ],
                      })(<Input type="number" min={0} max={9} />)}
                      <div className="card-btns-container">
                        <Button
                          shape="circle"
                          icon="close"
                          size="small"
                          onClick={this.stopEditField}
                        />
                        <Button
                          shape="circle"
                          type="primary"
                          icon="check"
                          size="small"
                          htmlType="submit"
                          onClick={this.onSubmit}
                        />
                      </div>
                    </React.Fragment>
                  </EditableField>
                  <EditableField
                    editable={this.state.editable === 'statisticFactor'}
                    readOnly={!role.lastVersion}
                    label={this.props.translate('statisticFactor')}
                    value={this.props.translate(
                      role.roleInformationResponse.statisticFactor,
                    )}
                    edit={() => this.editField('statisticFactor')}
                  >
                    <React.Fragment>
                      {getFieldDecorator('statisticFactor', {
                        initialValue:
                          role.roleInformationResponse.statisticFactor,
                      })(
                        <Select>
                          {STATISTIC_FACTOR.map(factor => (
                            <Select.Option key={factor.value}>
                              {this.props.translate(factor.value)}
                            </Select.Option>
                          ))}
                        </Select>,
                      )}
                      <div className="card-btns-container">
                        <Button
                          shape="circle"
                          icon="close"
                          size="small"
                          onClick={this.stopEditField}
                        />
                        <Button
                          shape="circle"
                          type="primary"
                          icon="check"
                          size="small"
                          htmlType="submit"
                          onClick={this.onSubmit}
                        />
                      </div>
                    </React.Fragment>
                  </EditableField>
                </Col>
              </Row>
            </React.Fragment>
          </Form>
        )}
      </Spin>
    );
  }
}
