import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Form, Button, Alert } from 'antd';

import i18n from 'services/i18n';
import ErrorItem from 'components/Form/formError';
import { emailSendCRUD } from 'actions/cruds';
import FormItemInput from 'components/Form/formInput';

import Header from './header';
import './loginStyles.less';

const FormItem = Form.Item;

@i18n('form')
@Form.create({})
export default class Email extends Component {
  static childContextTypes = {
    form: PropTypes.object,
  };

  state = {
    loading: false,
    errorText: '',
    success: false,
  };

  getChildContext() {
    return { form: this.props.form };
  }

  emailSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (err) return;
      try {
        this.state.loading = true;
        await emailSendCRUD.post({
          email: values.email,
        });
        this.setState({ success: true });
      } catch (error) {
        console.error(error);
        !this.state.errorText && this.toggleError('form.incorrect');
      }
      this.setState({ loading: false });
    });
  };

  toggleError = errorText => {
    this.setState(state => ({ errorText }));
  };

  get renderBackButton() {
    return (
      <FormItem>
        <Link to="/auth/login" className="login_forgot_link">
          {this.props.translate('goToLogin')}
        </Link>
      </FormItem>
    );
  }

  render() {
    const { translate } = this.props;

    return (
      <Form onSubmit={this.emailSubmit} className="login_form">
        {!this.state.success ? (
          <React.Fragment>
            <Header name="form.forgotPassword" />
            <FormItemInput name="email" type="email" required placeholder />
            <ErrorItem
              toggleError={this.toggleError}
              errorText={this.state.errorText}
            />
            <FormItem>
              <Button
                type="primary"
                htmlType="submit"
                className="blueBtn login_max_width"
              >
                {translate('resetPassword')}
              </Button>
            </FormItem>
            {this.renderBackButton}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Alert
              message={translate('success')}
              description={translate('lookEmail')}
              type="success"
              showIcon
            />
            {this.renderBackButton}
          </React.Fragment>
        )}
      </Form>
    );
  }
}
