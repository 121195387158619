import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import moment from 'moment';
import { isEmpty } from 'lodash';

/** Utils, Configs*/
import i18n from 'services/i18n';
import { Popover } from 'antd';

/**
 * Component which render details about osce day ( date, time, lunch break )
 * @reactProps {func} translate - function which translated content according to chosen language
 * @reactProps {string} name - osce day name
 * @reactProps {string} date - osce day date
 * @reactProps {string} dayStartTime - osce day start time
 * @reactProps {string} dayEndTime - osce day end time
 * @reactProps {string} lunchBreakEndTime - osce day end lunch break time
 * @reactProps {string} lunchBreakStartTime - osce day start lunch break time
 */
@withRouter
@i18n('circuit')
export default class OsceDayHeader extends PureComponent {
  static propsTypes = {
    name: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    startTime: PropTypes.string.isRequired,
    endTime: PropTypes.string.isRequired,
    lunchBreaks: PropTypes.object,
    lunchBreakBetweenOsceSequences: PropTypes.object,
  };

  static defaultProps = {
    lunchBreak: {
      endTime: '',
      startTime: '',
    },
  };

  contentPopoverBreak = (breaks, translate) => {
    return (
      <ul className="list-breaks">
        {breaks.map((item, index) => (
          <li key={index}>
            {this.props.translate(translate)} #{index + 1} - {item.startTime} -{' '}
            {item.endTime}
          </li>
        ))}
      </ul>
    );
  };

  render() {
    const {
      name,
      date,
      translate,
      startTime,
      endTime,
      lunchBreaks,
      lunchBreakBetweenOsceSequences,
    } = this.props;
    return (
      <div className="osce-day">
        <div className="osce-day-header">
          <div className="osce-day-header_left">
            <div className="osce-day-header_block osce-day-header_main-name-block">
              <p className="osce-day-header_name">
                {translate('day')} {name}
              </p>
            </div>
            <div className="osce-day-header_block">
              <div className="osce-day_expander" />
              <div className="osce-day-header_text-wrapper">
                <p className="osce-day-header_small-name">
                  {translate('date')}
                </p>
                <p className="osce-day-header_small-value">
                  {moment(date).format('DD.MM.YYYY')}
                </p>
              </div>
              <div className="osce-day_expander" />
            </div>
            <div className="osce-day-header_block">
              <div className="osce-day_expander" />
              <div className="osce-day-header_text-wrapper">
                <p className="osce-day-header_small-name">
                  {translate('dayStartTime')}
                </p>
                <p className="osce-day-header_small-value">{startTime}</p>
              </div>
              <div className="osce-day_expander" />
            </div>
            <div className="osce-day-header_block">
              <div className="osce-day_expander" />
              {lunchBreaks.length >= 1 ? (
                <Popover
                  placement="bottom"
                  content={this.contentPopoverBreak(
                    lunchBreaks,
                    'dayLunchBreak',
                  )}
                >
                  <div className="osce-day-header_text-wrapper">
                    <p className="osce-day-header_small-name">
                      {`${this.props.translate('dayLunchBreak')} ${
                        isEmpty(lunchBreakBetweenOsceSequences) ? '#1' : ''
                      }`}
                    </p>

                    {!isEmpty(lunchBreakBetweenOsceSequences) ? (
                      <p className="osce-day-header_small-value">
                        {lunchBreakBetweenOsceSequences.startTime}-
                        {lunchBreakBetweenOsceSequences.endTime}
                      </p>
                    ) : (
                      <p className="osce-day-header_small-value">
                        {lunchBreaks[0].startTime}-{lunchBreaks[0].endTime}
                      </p>
                    )}
                  </div>
                </Popover>
              ) : (
                <div className="osce-day-header_text-wrapper">
                  <p className="osce-day-header_small-name">
                    {`${this.props.translate('dayLunchBreak')}`}
                  </p>
                  {!isEmpty(lunchBreakBetweenOsceSequences) ? (
                    <p className="osce-day-header_small-value">
                      {lunchBreakBetweenOsceSequences.startTime}-
                      {lunchBreakBetweenOsceSequences.endTime}
                    </p>
                  ) : (
                    '-'
                  )}
                </div>
              )}
              <div className="osce-day_expander" />
            </div>
            <div className="osce-day-header_block">
              <div className="osce-day_expander" />
              <div className="osce-day-header_text-wrapper">
                <p className="osce-day-header_small-name">
                  {translate('dayEndTime')}
                </p>
                <p className="osce-day-header_small-value">{endTime}</p>
              </div>
              <div className="osce-day_expander" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
