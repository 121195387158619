import React, { Component } from 'react';
import { Button } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import TablePanel from 'components/TablePanel/index';
import ModalForm from 'components/ModalForm/index';
import Table from 'components/Table';
import i18n from 'services/i18n';
import i18nTable from 'services/i18nTable';
import { semesterCRUD } from 'actions/cruds';
import { getSemesters } from 'actions/administrator';

import AddNewSemester from './AddNewSemester';

const mapDispatchToProps = dispatch =>
  bindActionCreators({ dispatch, getSemesters }, dispatch);

@i18n('modalForm')
@i18nTable('semesterColumns')
@connect(
  null,
  mapDispatchToProps,
)
export default class Rooms extends Component {
  state = {
    visible: false,
  };

  showModal = () => this.setState({ visible: true });

  handleCancel = () => {
    this.setState({ visible: false });
  };

  render() {
    const { visible } = this.state;
    const { translate } = this.props;
    return (
      <React.Fragment>
        <ModalForm
          title={translate('addNewSemester')}
          visible={visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <AddNewSemester
            toggleWindow={this.handleCancel}
            updateTable={this.props.getSemesters}
          />
        </ModalForm>
        <TablePanel>
          <Button
            icon="plus"
            className="blueBtn table-panel_btn"
            onClick={this.showModal}
          >
            {translate('addNewSemester')}
          </Button>
        </TablePanel>
        <Table
          withoutScroll
          hasActionBtns
          action={semesterCRUD}
          name="semesters"
          columns={this.props.semesterColumns}
        />
      </React.Fragment>
    );
  }
}
