import styled from 'styled-components';
import PropTypes from 'prop-types';
import styles, {
  space,
  justifyContent as justify,
  alignItems,
  flexDirection,
} from 'styled-system/dist/styles';

// Example of a general purpose Box layout component
export const Box = styled.div`
  ${space} ${styles};
`;

export const Flex = styled.div`
  display: flex;
  ${space} ${styles} ${justify} ${alignItems} ${flexDirection}
`;

Flex.propTypes = {
  justify: PropTypes.string,
  alignItems: PropTypes.string,
  flexDirection: PropTypes.string,
};
