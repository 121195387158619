import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
// import { get } from 'lodash';

import * as tableConstants from 'constants/tableColumns';

const stringToParameterObject = str => ({
  name: str,
  to: str,
});

// [{ name: 'adminColumns', to: 'admin', from: 'admins'}]
// ['adminColumns', 'blaBlaColumns'] => [{ name: 'adminColumns', to: 'adminColumns'}, ...]
// ['adminColumns', { name: 'blaBlaColumns'}] => [{ name: 'adminColumns', to: 'adminColumns'}]
// 'adminColumns' => [{ name: 'adminColumns', to: 'adminColumns'}]
export default function i18nTable(params) {
  let paramsArray = params;
  if (typeof params === 'string') {
    paramsArray = stringToParameterObject(params);
  }
  if (!Array.isArray(paramsArray)) {
    paramsArray = [paramsArray];
  } else {
    paramsArray = paramsArray.map(localisation => {
      if (typeof localisation === 'string') {
        return stringToParameterObject(localisation);
      }
      return localisation;
    });
  }

  return function(Child) {
    @injectIntl
    class LocalizeTable extends Component {
      translate = tableName => elem => ({
        ...elem,
        title: this.props.intl.formatMessage({
          id: `${elem.from || tableName}.${elem.translate}`,
        }),
      });

      get translatedObject() {
        const newProps = {};
        const customTableConst = tableConstants[this.props.customColumnsName];

        paramsArray.forEach(localisation => {
          const array = customTableConst || tableConstants[localisation.name];
          const tableName = localisation.from || localisation.name;

          newProps[localisation.to] = array.map(this.translate(tableName));
        });
        return newProps;
      }

      render() {
        return <Child {...this.props} {...this.translatedObject} />;
      }
    }
    return LocalizeTable;
  };
}
