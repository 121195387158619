import React, { Component } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table, Button, Checkbox, Icon, message } from 'antd';
import { debounce } from 'lodash';
// Components
import Search from 'components/Search';
// Actions
import {
  getAllSimulatedPatients,
  createSimulatedPatient,
  lazyLoadSPs,
  exportSP,
} from 'actions/simulatedPatients';
// Services
import i18n from 'services/i18n';
import { parseAge } from 'services/format';
// Utils
import { downloadFile } from 'utils/downloadFile';
// Components
import {
  Filter,
  FilterItem,
  GenderFilter,
  NumericFilter,
  LanguageFilter,
  ProfessionFilter,
  NationalityFilter,
  MaritalStatusFilter,
  QuestionnaireFilter,
  WorkPermissionFilter,
  CriteriaTable,
} from 'components/Filter';
// Icons
import { ReactComponent as GenderSvg } from 'images/icons/ic-gender.svg';
import { ReactComponent as LangSvg } from 'images/icons/ic-lang.svg';
import { ReactComponent as MaritalSvg } from 'images/icons/ic-marital.svg';
import { ReactComponent as NationSvg } from 'images/icons/ic-nation.svg';
import { ReactComponent as NumberSvg } from 'images/icons/ic-number.svg';
import { ReactComponent as ProfessionSvg } from 'images/icons/ic-profession.svg';
import { ReactComponent as QuestionSvg } from 'images/icons/ic-question.svg';
import { ReactComponent as WorkSvg } from 'images/icons/ic-work.svg';

// Constants
import { FILTER_TYPE } from 'constants/options';

import AddSP from './AddSP';

// Styles
import './style.less';

// Cache criteria
let cachedCriteria = [];

@i18n('patientsColumns')
class Patients extends Component {
  static propTypes = {
    simulatedPatients: PropTypes.array.isRequired,
    loading: PropTypes.bool,
  };

  static defaultProps = {
    loading: false,
  };

  constructor(props) {
    super(props);
    this.columns = [
      {
        title: props.translate('name'),
        dataIndex: 'lastName',
        key: 'lastName',
        width: 100,
        sorter: true,
      },
      {
        title: props.translate('firstName'),
        dataIndex: 'firstName',
        key: 'firstName',
        width: 100,
        sorter: true,
      },
      {
        title: props.translate('email'),
        dataIndex: 'email',
        key: 'email',
        width: 120,
        sorterName: 'patientContactDetailsEmail',
        sorter: true,
      },
      {
        title: props.translate('gender'),
        dataIndex: 'gender',
        key: 'gender',
        sorterName: 'patientPersonalInfoGender',
        width: 30,
        sorter: true,
      },
      {
        title: props.translate('town'),
        dataIndex: 'city',
        key: 'city',
        sorterName: 'patientContactDetailsCity',
        width: 50,
        sorter: true,
      },
      {
        title: props.translate('age'),
        dataIndex: 'birthday',
        key: 'birthday',
        sorterName: 'patientPersonalInfoBirthday',
        width: 30,
        sorter: true,
        render: parseAge,
      },
    ];

    this.state = {
      page: 0,
      pageSize: 30,
      total: 0,
      fullCount: 0,
      search: '',
      isShowInactive: false,
      sortProp: 'lastName',
      sortDirection: 'ASC',
      fields: ['FIRST_NAME', 'LAST_NAME', 'EMAIL'],
      criteria: cachedCriteria,
      activeFilter: '',
      showFilter: false,
      preventLazyLoad: false,
      isAddSPModalOpen: false,
    };
  }

  componentDidMount() {
    this.handleUpdateTableData();
    window.addEventListener('scroll', this.handlePageScroll);
  }

  componentWillUnmount() {
    cachedCriteria = this.state.criteria;
    window.removeEventListener('scroll', this.handlePageScroll);
  }

  handleOpenSPModal = e => this.setState({ isAddSPModalOpen: true });

  handleCloseSPModal = e => this.setState({ isAddSPModalOpen: false });

  handlePageScroll = debounce(e => {
    const { body } = document;
    if (
      !this.state.preventLazyLoad &&
      this.props.simulatedPatients.length < this.state.total &&
      window.scrollY > body.scrollHeight - body.offsetHeight - 30
    ) {
      this.setState(
        { page: this.state.page + 1, preventLazyLoad: true },
        this.lazyLoadSPs,
      );
    }
  }, 100);

  lazyLoadSPs = () => {
    const {
      search,
      page,
      pageSize,
      sortDirection,
      sortProp,
      fields,
      isShowInactive,
      criteria,
    } = this.state;
    this.props
      .lazyLoadSPs({
        search,
        page,
        pageSize,
        sortProp,
        sortDirection,
        fields,
        isShowInactive,
        criteria,
      })
      .then(res => {
        this.setState({
          total: res.pagination.total,
          fullCount: res.pagination.fullCount,
          preventLazyLoad: false,
        });
      })
      .catch(err => message.error(this.props.translate('all', 'error')));
  };

  handleUpdateTableData = () => {
    const {
      fields,
      search,
      page,
      pageSize,
      isShowInactive,
      criteria,
      sortProp,
      sortDirection,
    } = this.state;
    this.props
      .getAllSimulatedPatients({
        fields,
        search,
        page,
        pageSize,
        sortProp,
        sortDirection,
        isShowInactive,
        criteria,
      })
      .then(res =>
        this.setState({
          total: res.pagination.total,
          fullCount: res.pagination.fullCount,
        }),
      )
      .catch(err => message.error(this.props.translate('all', 'error')));
  };

  // FILTERS
  toggleFilter = () => this.setState({ showFilter: !this.state.showFilter });

  handleChangeActiveFilter = activeFilter =>
    this.setState(prevState => ({
      activeFilter: prevState.activeFilter === activeFilter ? '' : activeFilter,
    }));

  handleAddFilterCriterion = (type, data) => {
    const { criteria } = this.state;

    data.type = type;
    data.operation = 'AND';
    // Update criteria list, drop page to first, then make
    // a request to get updated table data
    this.setState(
      { criteria: [...criteria, data], page: 0, activeFilter: '' },
      this.handleUpdateTableData,
    );
  };

  handleChangeCriteria = criteria => {
    this.setState({ criteria }, this.handleUpdateTableData);
  };

  handleClearFilterCriteria = () =>
    this.setState({ criteria: [], page: 0 }, this.handleUpdateTableData);

  handleSearch = search => {
    this.setState({ search, page: 0 }, this.handleUpdateTableData);
  };

  handleOpenPatientProfile = patient =>
    this.props.history.push(
      `/sp/patient-profile/${patient.id}/patient-profile`,
    );

  handleTableChange = (pagination, filters, sorter) => {
    // When no sort column selected sorter = {}
    if (sorter.order) {
      const sortDirection = sorter.order === 'ascend' ? 'ASC' : 'DESC';
      const sortProp = sorter.column.key;
      this.setState(
        { page: 0, sortProp, sortDirection },
        this.handleUpdateTableData,
      );
    } else {
      this.setState(
        { page: 0, sortProp: 'lastName', sortDirection: 'ASC' },
        this.handleUpdateTableData,
      );
    }
  };

  handleChangeInactive = () =>
    this.setState(
      { isShowInactive: !this.state.isShowInactive, page: 0 },
      this.handleUpdateTableData,
    );

  handleExportSP = () => {
    const { criteria } = this.state;
    this.props.exportSP(criteria).then(res => {
      downloadFile(
        res.data,
        'sp.xlsx',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      );
    });
  };

  handleCreateSP = values => {
    this.props
      .createSimulatedPatient(values)
      .then(this.handleOpenPatientProfile);
  };

  render() {
    const dataSource = this.props.simulatedPatients.map(patient => ({
      ...patient,
      key: patient.id,
    }));

    const tableTitle = (
      <div>
        <b style={{ fontSize: 14 }}>
          {this.props.translate('filtered', 'patient')}:{' '}
        </b>
        <span>
          {this.state.total} {this.props.translate('of', 'patient')}{' '}
          {this.state.fullCount}
        </span>
      </div>
    );

    return (
      <React.Fragment>
        <div className="table-panel">
          <Button
            icon="plus"
            className="blueBtn table-panel_btn"
            onClick={this.handleOpenSPModal}
          >
            {this.props.translate('addSP', 'patient')}
          </Button>
          <Search
            value={this.state.search}
            count={this.state.total}
            placeholder={this.props.translate('search', 'form')}
            onSearch={this.handleSearch}
          />
          <Button
            icon="filter"
            className={cn('table-panel_btn', 'table-panel_filter', {
              'table-panel_filter_open': this.state.showFilter,
            })}
            onClick={this.toggleFilter}
          >
            {this.props.translate('filter', 'form')} (
            {this.state.criteria.length})
          </Button>
          <Checkbox
            checked={this.state.isShowInactive}
            onChange={this.handleChangeInactive}
          >
            {this.props.translate('showInactiveSP', 'patient')}
          </Checkbox>
          <Button
            icon="upload"
            className="table-panel_btn table-panel_btn-right"
            onClick={this.handleExportSP}
          >
            {this.props.translate('export', 'form')}
          </Button>
        </div>
        <AddSP
          isVisible={this.state.isAddSPModalOpen}
          onSubmit={this.handleCreateSP}
          onClose={this.handleCloseSPModal}
        />
        <Filter name="patients" open={this.state.showFilter}>
          {translate => (
            <React.Fragment>
              <FilterItem
                type={FILTER_TYPE.NUMERIC}
                visible={this.state.activeFilter === FILTER_TYPE.NUMERIC}
                onVisibleChange={this.handleChangeActiveFilter}
                iconComponent={NumberSvg}
                formComponent={
                  <NumericFilter onSubmit={this.handleAddFilterCriterion} />
                }
              />
              <FilterItem
                type={FILTER_TYPE.GENDER}
                visible={this.state.activeFilter === FILTER_TYPE.GENDER}
                onVisibleChange={this.handleChangeActiveFilter}
                iconComponent={GenderSvg}
                formComponent={
                  <GenderFilter onSubmit={this.handleAddFilterCriterion} />
                }
              />
              <FilterItem
                type={FILTER_TYPE.QUESTIONNAIRE}
                visible={this.state.activeFilter === FILTER_TYPE.QUESTIONNAIRE}
                onVisibleChange={this.handleChangeActiveFilter}
                iconComponent={QuestionSvg}
                formComponent={
                  <QuestionnaireFilter
                    onSubmit={this.handleAddFilterCriterion}
                  />
                }
              />
              <FilterItem
                type={FILTER_TYPE.LANGUAGE}
                visible={this.state.activeFilter === FILTER_TYPE.LANGUAGE}
                onVisibleChange={this.handleChangeActiveFilter}
                iconComponent={LangSvg}
                formComponent={
                  <LanguageFilter onSubmit={this.handleAddFilterCriterion} />
                }
              />
              <FilterItem
                type={FILTER_TYPE.NATIONALITY}
                visible={this.state.activeFilter === FILTER_TYPE.NATIONALITY}
                onVisibleChange={this.handleChangeActiveFilter}
                iconComponent={NationSvg}
                formComponent={
                  <NationalityFilter onSubmit={this.handleAddFilterCriterion} />
                }
              />
              <FilterItem
                type={FILTER_TYPE.PROFESSION}
                visible={this.state.activeFilter === FILTER_TYPE.PROFESSION}
                onVisibleChange={this.handleChangeActiveFilter}
                iconComponent={ProfessionSvg}
                formComponent={
                  <ProfessionFilter onSubmit={this.handleAddFilterCriterion} />
                }
              />
              <FilterItem
                type={FILTER_TYPE.WORK_PERMISSION}
                visible={
                  this.state.activeFilter === FILTER_TYPE.WORK_PERMISSION
                }
                onVisibleChange={this.handleChangeActiveFilter}
                iconComponent={WorkSvg}
                formComponent={
                  <WorkPermissionFilter
                    onSubmit={this.handleAddFilterCriterion}
                  />
                }
              />
              <FilterItem
                type={FILTER_TYPE.MARITAL_STATUS}
                visible={this.state.activeFilter === FILTER_TYPE.MARITAL_STATUS}
                onVisibleChange={this.handleChangeActiveFilter}
                iconComponent={MaritalSvg}
                formComponent={
                  <MaritalStatusFilter
                    onSubmit={this.handleAddFilterCriterion}
                  />
                }
              />
              {/* Component with a key https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#preferred-solutions */}
              <CriteriaTable
                key={this.state.criteria.length}
                criteria={this.state.criteria}
                onChange={this.handleChangeCriteria}
              />
              <div className="bottom">
                <Button
                  className="table-panel_btn table-panel_btn-right"
                  onClick={this.handleClearFilterCriteria}
                >
                  {translate('clearAll')}
                </Button>
                <span className="hide-button" onClick={this.toggleFilter}>
                  <Icon type="up-circle-o" className="icon" />
                  {translate('hideFilters')}
                </span>
              </div>
            </React.Fragment>
          )}
        </Filter>
        <Table
          bordered
          title={() => tableTitle}
          columns={this.columns}
          className="table-row-pointer"
          dataSource={dataSource}
          pagination={false}
          loading={this.props.loading}
          onChange={this.handleTableChange}
          onRow={patient => {
            return {
              onClick: () => {
                this.handleOpenPatientProfile(patient);
              },
            };
          }}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  simulatedPatients: state.simulatedPatients.data,
  loading: state.simulatedPatients.loading,
});

const mapDispatchToProps = {
  getAllSimulatedPatients,
  createSimulatedPatient,
  lazyLoadSPs,
  exportSP,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Patients);
