import { clone } from 'lodash';

import { CHANGE_OPEN_FIELD } from '../constants/actions';

const defState = { currentField: '' };

export default function form(state = clone(defState), action = {}) {
  if (action.type === CHANGE_OPEN_FIELD) {
    return { currentField: action.payload };
  }
  return state;
}
