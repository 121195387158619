import React, { Component } from 'react';
import cn from 'classnames';
import { set, debounce, chain, isEqual, forIn, find } from 'lodash';
import PropTypes from 'prop-types';

/** UI*/
import { Form, Select, Spin } from 'antd';

/** Services*/
import i18n from 'services/i18n';
import stateSend from 'services/stateSend';
import { focusSelect } from 'services/DOM';

/** Constants*/
import { DEFAULT_SCROLL_OFFSET } from 'constants/common';

/** Components*/
import EditableBtns from './FormEditableBtns';

/** Styles*/
import './formsStyles.less';

const FormItem = Form.Item;

/**
 * Editable Label DropDown
 * @reactProps {func} getList - Our function for get list with pagination.
 * @reactProps {array} options - load data to select from object.
 * @reactProps {bool} withoutLabel - use label or not.
 * @reactProps {string} title - title (will be auto-translated).
 * @reactProps {string} customName - get custom name from BE responce (default = name)
 * @reactProps {string} name - key for submit object, will be translate from form if !title
 * @reactProps {bool} fullWidth - antd prop
 * @reactProps {bool} onlyDropDown - should it be editable dropdown (default = true)
 * @reactProps {bool} disabled - disable
 * @reactProps {bool} withoutInitLoading - load data on component inited (default = true)
 * @reactProps {string} className - class in DOM
 * @reactProps {string} i18name - custom translate prop
 * @reactProps {string} selectClassName - class in DOM of select
 * @reactProps {bool} required - is required
 * @reactProps {bool} withFindInOptions - find values in options
 * @reactProps {bool} search - search
 * @reactProps {object} queryParams - reset if query params changed
 * @reactProps {array} alwaysAddOptions - options will be added all teme
 * @reactProps {array} rules - antd prop
 * @reactProps {string} label - antd prop for label
 * @reactProps {string} placeholder - antd prop for placeholder
 * @reactProps {func} getPopupContainer - antd prop for getPopupContainer
 * @reactProps {string} sendValueName - custom value
 * @reactProps {bool} withoutTranslate - Without Translate
 * @reactProps {node} notFoundContent - ant prop for not found content
 * @reactProps {string | number | array} initialValue - ant prop for not found content
 */

@i18n('form')
export default class FormEditableLabelDropDown extends Component {
  static propTypes = {
    getList: PropTypes.func, // only getList or options, getList - from crudBUILDER
    options: PropTypes.array, // if you want to load data without BE
    withoutLabel: PropTypes.bool, // component will be rendered without label
    title: PropTypes.string, // it will be translated by i18n form
    customName: PropTypes.string,
    name: PropTypes.string.isRequired, // it will be key on submit object, if !title name will be translated to
    fullWidth: PropTypes.bool, // component will take all width
    onlyDropDown: PropTypes.bool, // without edit on click, just dropdown
    disabled: PropTypes.bool, //  disable edit onClick (just lable)
    withoutInitLoading: PropTypes.bool, //  disable initial loading
    className: PropTypes.string,
    i18name: PropTypes.string,
    selectClassName: PropTypes.string,
    required: PropTypes.bool, // form option, if your key is mandatory
    search: PropTypes.bool,
    queryParams: PropTypes.object,
    alwaysAddOptions: PropTypes.array,
    rules: PropTypes.array, // ant form rules
    label: PropTypes.string, // ant label
    placeholder: PropTypes.string, // ant placeholder
    getPopupContainer: PropTypes.func, // ant getPopupContainer
    sendValueName: PropTypes.string, // ant placeholder
    withoutTranslate: PropTypes.bool, // ant form initialValue
    withFindInOptions: PropTypes.bool,
    notFoundContent: PropTypes.node, // ant form initialValue
    initialValue: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.array,
    ]), // ant form initialValue
  };

  static contextTypes = {
    form: PropTypes.object,
    getStatus: PropTypes.func,
    setStatus: PropTypes.func,
    submit: PropTypes.func,
    disabled: PropTypes.bool,
  };

  state = {
    editable: this.props.onlyDropDown,
    loading: false,
    array: [],
    pagination: {},
  };

  constructor(props) {
    super(props);
    this.debouncedTryLoadMore = debounce(this.tryLoadMore, 100, {
      leading: false,
    });
  }

  componentDidMount() {
    !this.props.withoutInitLoading && this.props.getList && this.load();
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (
      this.state.editable &&
      !this.props.onlyDropDown &&
      (this.props.name !== nextContext.getStatus() ||
        nextContext.disabled !== this.context.disabled)
    )
      this.resetStopEdit();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !this.props.onlyDropDown &&
      this.state.editable &&
      !prevState.editable
    ) {
      this.focus();
    }
    if (!isEqual(prevProps.queryParams, this.props.queryParams)) {
      this.resetData();
    }
  }

  resetData = () => {
    this.setState({ array: [] });
    this.context.form.resetFields([this.props.name]);
    this.load();
  };

  saveArray = (data, search) => {
    if (this.props.alwaysAddOptions) {
      data.unshift(...this.props.alwaysAddOptions);
    }
    if (search === this.state.search) {
      return this.state.array.concat(data);
    }
    return data;
  };

  getStructure = (resp, search) => ({
    array: this.saveArray(resp.data, search),
    pagination: resp.pagination,
    search,
    selected: this.props.selected,
  });

  isNeedToAddQueryParams = () => {
    const { queryParams } = this.props;
    let isNeed = false;
    if (queryParams) {
      isNeed = true;
      forIn(queryParams, value => {
        if (!value) isNeed = false;
      });
    }
    return isNeed;
  };

  load = async (page = 0, search = '') => {
    try {
      const { props } = this;
      let params = {
        page,
        search,
      };
      if (this.isNeedToAddQueryParams()) {
        params = { ...params, ...props.queryParams };
      }
      const resp = await stateSend(
        loading => this.setState({ loading }),
        this.props.getList({ params }),
        { translate: this.props.translate },
      );
      this.setState(this.getStructure(resp, search));
    } catch (e) {
      console.error(e);
    }
  };

  save = newArray => this.state.array.concat(newArray);

  checkLoadMore = () => {
    const { total } = this.state.pagination;
    return !!total && this.state.array.length < total;
  };

  tryLoadMore = offset => {
    if (
      this.props.getList &&
      !this.state.loading &&
      offset < DEFAULT_SCROLL_OFFSET &&
      this.checkLoadMore()
    ) {
      this.load(this.state.pagination.page + 1);
    }
  };

  saveStartEdit = () => {
    if (this.props.disabled || this.context.disabled) return;
    this.setState({ editable: true });
    this.context.setStatus && this.context.setStatus(this.props.name);
  };

  stopEdit = () => this.setState({ editable: false });

  resetStopEdit = () => {
    const { initialValue, name } = this.props;
    const { setFieldsValue } = this.context.form;
    const newValue = {};
    set(newValue, name, initialValue);
    setFieldsValue(newValue);
    this.stopEdit();
  };

  get btns() {
    const { props } = this;
    if (props.disabled || this.context.disabled || props.onlyDropDown)
      return null;
    return (
      <EditableBtns
        editable={this.state.editable}
        saveStartEdit={this.saveStartEdit}
        resetStopEdit={this.resetStopEdit}
      />
    );
  }

  onScroll = e => {
    const { target } = e;
    this.debouncedTryLoadMore(
      target.scrollHeight - target.offsetHeight - target.scrollTop,
    );
  };

  onSearch = value => {
    this.load(0, value);
  };

  get dropDownBody() {
    const { props } = this;
    if (props.getList) {
      return this.state.array.map(element =>
        element instanceof Object ? (
          <Select.Option value={element.id} key={element.id}>
            {element[props.customName] || element.name}
          </Select.Option>
        ) : (
          <Select.Option value={element} key={element}>
            {element}
          </Select.Option>
        ),
      );
    }
    return props.options.map(element => (
      <Select.Option
        value={element[props.sendValueName] || element.value || element.id}
        key={element.id || element.value}
      >
        {props.withoutTranslate
          ? element.title || element.value || element.name
          : props.translate(element.value, props.i18Name)}
      </Select.Option>
    ));
  }

  chain = (id, array = this.state.array) =>
    chain(array)
      .find({ id })
      .get('name')
      .value() || id;

  getValue = id => {
    const { props } = this;
    if (props.options) {
      if (props.withFindInOptions) {
        return this.chain(id, props.options);
      }
      if (props.initialValue) {
        return props.withoutTranslate ? id : props.translate(id, props.i18Name);
      }
    }

    return this.chain(id);
  };

  saveRef = ref => ref && (this.selectRef = ref);

  focus = () => focusSelect(this.selectRef);

  findSelectedObject = (id, value) => {
    const customName = this.props.customName || 'name';
    return find(this.state.array, { id, [customName]: value });
  };

  get select() {
    const { props } = this;
    let rules = props.rules || [];
    if (props.required) {
      rules = rules.concat({
        required: true,
        message: props.translate('checkboxGroup'),
      });
    }
    const { getFieldDecorator, getFieldValue } = this.context.form;
    return getFieldDecorator(props.name, {
      initialValue: props.initialValue,
      rules,
    })(
      this.state.editable ? (
        <Select
          ref={this.saveRef}
          className={props.selectClassName}
          onPopupScroll={this.onScroll}
          showSearch={props.search}
          onChange={(val, data) =>
            this.props.onChange &&
            this.props.onChange(
              this.findSelectedObject(val, data.props.children),
              data,
            )
          }
          onSearch={this.onSearch}
          onSelect={(value, option) => {
            this.props.onSelect && this.props.onSelect(value, option);
            this.load();
          }}
          filterOption={false}
          placeholder={props.placeholder}
          getPopupContainer={props.getPopupContainer}
          notFoundContent={
            this.state.loading ? <Spin size="small" /> : props.notFoundContent
          }
        >
          {this.dropDownBody}
        </Select>
      ) : (
        <span
          onClick={this.saveStartEdit}
          className={props.className || 'card-no-editable'}
        >
          {this.getValue(getFieldValue(props.name))}
        </span>
      ),
    );
  }

  render() {
    const { props } = this;
    let label;
    if (!props.withoutLabel && !props.label) {
      label = (
        <span onClick={this.saveStartEdit}>
          {props.translate(`${props.title || props.name}`, props.i18name)}
        </span>
      );
    }
    return (
      <FormItem
        colon={false}
        label={props.label || label}
        labelCol={!props.verticalLable && { span: props.label ? 24 : 4 }}
        wrapperCol={!props.verticalLable && { span: props.fullWidth ? 24 : 14 }}
        className={cn({
          'card-item': !props.fullWidth,
          'card-editable-width': this.state.editable && !props.fullWidth,
          'form-full-width': props.fullWidth,
        })}
      >
        {this.select}
        {this.btns}
      </FormItem>
    );
  }
}
