import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { pick } from 'lodash';
import cn from 'classnames';

/** Constants */
import { STATION_TYPES, BREAK_TYPES } from 'constants/common';

/** Services*/
import addLoadWithRedux from 'services/decorators/addLoadWithRedux';

/** Components*/
import Break from './Content/Break';
import StationTemplate from './Content/StationTemplate';

import './style.less';

/**
 * Component for rendering content inside schedule station
 * @reactProps {func} translate - function for translate text
 * @reactProps {number} stationId - id of station
 * @reactProps {string} stationType - type of station
 * @reactProps {number} positionInCircuit - position in circuit
 * @reactProps {array} timeSlots - timeSlot in circuit
 * @reactProps {bool} hasBigBreak - data about osce day
 * @reactProps {number} osceDayId - id of Osce Day
 */
@addLoadWithRedux({
  i18Name: 'circuit',
  runtimeReduxNames: ['schedule'],
})
export default class ScheduleStation extends Component {
  static propsTypes = {
    scheduleData: PropTypes.object,
    translate: PropTypes.func,
    stationId: PropTypes.number,
    stationType: PropTypes.string,
    positionInCircuit: PropTypes.number,
    isSpPaused: PropTypes.bool,
    osceDayId: PropTypes.number,
    timeSlots: PropTypes.array,
    hasBigBreak: PropTypes.bool,
    circuitId: PropTypes.number,
    sequenceNumberLunchBreak: PropTypes.number,
  };

  get sequenceNumberLastBreak() {
    const { timeSlots, sequenceNumberLunchBreak } = this.props;
    const allBreaks = timeSlots.filter(item =>
      BREAK_TYPES.includes(item.timeSlotType),
    );
    if (allBreaks.length) {
      const lastBreak = allBreaks[allBreaks.length - 1];
      if (lastBreak.sequenceNumber !== sequenceNumberLunchBreak) {
        return lastBreak.sequenceNumber;
      }
      return (
        allBreaks[allBreaks.length - 2] &&
        allBreaks[allBreaks.length - 2].sequenceNumber
      );
    }
    return false;
  }

  get stationWidth() {
    const { isSpPaused, stationType } = this.props;
    if (isSpPaused) {
      if (stationType === STATION_TYPES.DOUBLE) {
        return '202px';
      }
      return '101px';
    }
    switch (this.props.stationType) {
      case STATION_TYPES.BREAK:
        return '101px';
      case STATION_TYPES.PREPARATION:
      case STATION_TYPES.DOUBLESP:
        return '241px';
      case STATION_TYPES.DOUBLE:
        return '361px';
      default:
        return '181px';
    }
  }

  renderBreak = (timeSlot, index) => {
    const {
      stationType,
      hasBigBreak,
      osceDayId,
      isSpPaused,
      sequenceNumberLunchBreak,
    } = this.props;
    return !sequenceNumberLunchBreak ||
      sequenceNumberLunchBreak !== timeSlot.sequenceNumber ? (
      <Break
        key={timeSlot.id}
        timeSlot={timeSlot}
        osceDayId={osceDayId}
        hasBigBreak={hasBigBreak}
        stationType={stationType}
        isSpPaused={isSpPaused}
        isFirstBreak={index === 1}
        sequenceNumberLastBreak={this.sequenceNumberLastBreak}
      />
    ) : null;
  };

  render() {
    const {
      stationId,
      stationType,
      positionInCircuit,
      timeSlots,
      translate,
      isSpPaused,
      circuitId,
      osceDayId,
    } = this.props;
    const width = this.stationWidth;
    return (
      <div
        key={stationId}
        className={cn(
          'station',
          { station_sp_paused: isSpPaused },
          `station_${stationType.toLowerCase()}`,
        )}
        style={{ width }}
      >
        <div className="circuit_section_header">
          <h3>Station {positionInCircuit}</h3>
          <p>{translate(stationType)}</p>
        </div>
        {timeSlots.map((timeSlot, i) =>
          BREAK_TYPES.includes(timeSlot.timeSlotType) ? (
            this.renderBreak(timeSlot, i)
          ) : (
            <StationTemplate
              key={i}
              isFirst={i === 0}
              isSpPaused={isSpPaused}
              timeSlot={timeSlot}
              stationId={stationId}
              stationType={stationType}
              circuitId={circuitId}
              osceDayId={osceDayId}
              rotations={timeSlots
                .filter(
                  item =>
                    item.timeSlotType === 'ROTATION' ||
                    item.timeSlotType === 'ROTATION_LOGICAL_BREAK',
                )
                .map(item => pick(item, ['endTime', 'id']))}
            />
          ),
        )}
      </div>
    );
  }
}
