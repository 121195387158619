import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Icon, Badge } from 'antd';
import { debounce } from 'lodash';

const Search = ({ value, onSearch, count = 0, placeholder = '' }) => {
  const [search, setSearch] = useState(value);
  // Debounce onSearch for better perfomance
  const debounceSearch = useCallback(
    debounce(onSearch, 300, { leading: false }),
    [],
  );

  const handleSearch = e => {
    const { value } = e.target;
    // Persist value between renders
    setSearch(value);
    debounceSearch(value);
  };

  return (
    <Badge
      style={{ backgroundColor: '#4080C7' }}
      count={count}
      overflowCount={999}
    >
      <Input
        value={search}
        onChange={handleSearch}
        placeholder={placeholder}
        prefix={<Icon type="search" />}
        className="table-panel_search"
      />
    </Badge>
  );
};

Search.propTypes = {
  value: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired,
  count: PropTypes.number,
};

export default Search;
