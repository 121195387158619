import React, { Component } from 'react';
import { get } from 'lodash';

import { clinicCRUD } from 'actions/cruds';
import EditableLable from 'components/Form/formEditableLabel';
import EditableLabelDropDown from 'components/Form/formEditableLabelDropDown';
import Card from 'components/Form/Card';

export default class ClinicInformation extends Component {
  render() {
    const { data } = this.props;
    return (
      <Card from="cards" value="clinicInformation">
        <EditableLabelDropDown
          search
          name="examinerContactDetails.clinic"
          title="clinic"
          getList={clinicCRUD.getList}
          initialValue={get(data, 'id')}
        />
        <EditableLable
          name="street"
          initialValue={get(data, 'street')}
          disabled
        />
        <EditableLable name="city" initialValue={get(data, 'city')} disabled />
        <EditableLable
          name="postalCode"
          initialValue={get(data, 'postalCode')}
          disabled
        />
      </Card>
    );
  }
}
