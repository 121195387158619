import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Table, Icon, Button } from 'antd';

import i18n from 'services/i18n';
import { msgError } from 'services/errorHandler';
import notification from 'services/decorators/notif';
import { getAllSkills, createNewRole, createNewSkill } from 'actions/role';

import ModalForm from 'components/ModalForm';
import Search from 'components/Search';
import AddRole from './AddRole';
import AddSkill from '../AddSkill';

const mapStateToProps = state => ({
  skills: state.role.skills,
  loading: state.role.loading,
});

const mapDispatchToProps = {
  getAllSkills,
  createNewRole,
  createNewSkill,
};

let lastKnownSearch = '';
let lastKnownY = 0;

@notification()
@i18n('roleDefinitionColumns')
@withRouter
@connect(
  mapStateToProps,
  mapDispatchToProps,
)
export default class RoleDefinitions extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        title: this.props.translate('skill'),
        dataIndex: 'name',
        key: 'name',
        width: '350px',
      },
      {
        title: this.props.translate('availableRoles'),
        dataIndex: 'roles',
        key: 'roles',
        render: (text, record, index) =>
          record.roles.map((item, i) => (
            <span
              className="role_item"
              key={item.id}
              onClick={() => this.props.history.push(`/roles/role/${item.id}`)}
            >
              {`${item.roleInformationResponse.acronym}${
                i === record.roles.length - 1 ? '' : ', '
              }`}
            </span>
          )),
      },
      {
        title: '',
        dataIndex: '',
        width: '300px',
        render: (text, record, index) => (
          <span className="editable-row-operations">
            <span
              className="action-btn"
              onClick={() => this.handleOpenRoleModal(record.id)}
            >
              <Icon type="plus" />
              <span>{this.props.translate('addRole')}</span>
            </span>
          </span>
        ),
      },
    ];
    this.state = {
      search: lastKnownSearch,
      skillId: null,
      page: 0,
      pageSize: 1000000,
      total: 0,
      showSkillModal: false,
      showRoleModal: false,
    };
  }

  componentDidMount() {
    this.loadSkills();
    window.scrollTo({
      top: lastKnownY,
      behavior: 'smooth',
    });
  }

  componentWillUnmount() {
    lastKnownSearch = this.state.search;
    lastKnownY = window.pageYOffset;
  }

  loadSkills = () => {
    const { search, page, pageSize } = this.state;
    this.props.getAllSkills({ search, page, pageSize }).then(res => {
      this.setState({ total: res.payload.skills.pagination.total });
    });
  };

  handleSearch = search => {
    this.setState({ search }, this.loadSkills);
  };

  handleOpenSkillModal = () => this.setState({ showSkillModal: true });

  handleCloseSkillModal = () => this.setState({ showSkillModal: false });

  handleOpenRoleModal = skillId =>
    this.setState({ skillId, showRoleModal: true });

  handleCloseRoleModal = () =>
    this.setState({ skillId: null, showRoleModal: false });

  handleCreateRole = values => {
    const { skillId } = this.state;
    this.props
      .createNewRole({
        skillId,
        roleInformationRequest: { ...values },
      })
      .then(res => {
        this.handleCloseRoleModal();
        this.props.history.push(`/roles/role/${res.data.idForMergingVersions}`);
      })
      .catch(() => {
        msgError();
      });
  };

  handleCreateNewSkill = values => {
    this.props.createNewSkill(values).then(res => {
      this.props.notifySuccess('success');
      this.props.getAllSkills({ page: 0, pageSize: 1000000 });
    });
  };

  render() {
    const dataSource = this.props.skills.map(skill => ({
      ...skill,
      key: skill.id,
    }));

    return (
      <React.Fragment>
        <div className="table-panel">
          <Button
            icon="plus"
            className="blueBtn table-panel_btn"
            onClick={this.handleOpenSkillModal}
          >
            {this.props.translate('addSkill', 'disciplineColumns')}
          </Button>
          <Search
            value={this.state.search}
            count={this.state.total}
            placeholder={this.props.translate('search', 'form')}
            onSearch={this.handleSearch}
          />
        </div>
        <AddSkill
          title={this.props.translate('addSkill', 'disciplineColumns')}
          onClose={this.handleCloseSkillModal}
          isVisible={this.state.showSkillModal}
          onSubmit={this.handleCreateNewSkill}
        />
        <ModalForm
          title={this.props.translate('addNewRole', 'modalForm')}
          visible={this.state.showRoleModal}
          onCancel={this.handleCloseRoleModal}
        >
          <AddRole
            onClose={this.handleCloseRoleModal}
            onSubmit={this.handleCreateRole}
          />
        </ModalForm>
        <div className="table-with-action">
          <Table
            name="skill"
            className="role_definition"
            bordered
            pagination={false}
            loading={this.props.loading}
            locale={{ locale: this.props.intl.locale }}
            dataSource={dataSource}
            columns={this.columns}
          />
        </div>
      </React.Fragment>
    );
  }
}
