import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { Tabs, Spin } from 'antd';

import { resetData } from 'actions/updateData';
import { getPatient } from 'actions/patient';
import { getSimulatedPatientsProfile } from 'actions/simulatedPatients';
import TablePanel from 'components/TablePanel/index';
import tabPattern from 'services/decorators/tabPattern';

import Profile from './Profile/index';
import PatientHeader from './Header';
import Questionnaire from './Questionnaire';
import SpOSCEs from './SPsOSCE/SPsOSCE';

const { TabPane } = Tabs;

const mapStateToProps = ({ runtime }) => ({
  loaded: runtime.patientLoaded,
  error: runtime.patientError,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { dispatch, resetData, getPatient, getSimulatedPatientsProfile },
    dispatch,
  );

@tabPattern('/sp/patient-profile/:id/:tab')
@connect(
  mapStateToProps,
  mapDispatchToProps,
)
export default class Patient extends PureComponent {
  state = { hasError: false };

  componentWillMount() {
    this.load(this.props.match.params.id);
    this.props.getSimulatedPatientsProfile(this.props.match.params.id);
  }

  componentWillUpdate(nextProps) {
    if (nextProps.match.params.id !== this.props.match.params.id) {
      this.load(nextProps.match.params.id);
      this.props.getSimulatedPatientsProfile(this.props.match.params.id);
    }
  }

  componentWillUnmount() {
    this.props.resetData('patient');
  }

  componentDidCatch(error, info) {
    console.error(error);
    this.setState({ hasError: true });
  }

  load = id => this.props.getPatient(id);

  rerender = () => this.forceUpdate();

  render() {
    const { props } = this;
    if (this.state.hasError || this.props.error) {
      return <h4>Error</h4>;
    }
    return (
      <Spin spinning={!props.loaded} delay={500}>
        <TablePanel>
          <PatientHeader />
        </TablePanel>
        <Tabs
          activeKey={props.tab}
          onTabClick={props.onTabClick}
          animated={{ inkBar: true, tabPane: false }}
          className="patient-tabs"
        >
          <TabPane
            tab={<FormattedMessage id="patient.patientProfile" />}
            key="patient-profile"
          >
            <Profile />
          </TabPane>
          <TabPane
            tab={<FormattedMessage id="patient.questionnaire" />}
            key="questionnaire"
          >
            <Questionnaire />
          </TabPane>
          <TabPane tab="OSCEs" key="osces">
            <SpOSCEs />
          </TabPane>
        </Tabs>
      </Spin>
    );
  }
}
