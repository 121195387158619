import { wrapRequest } from 'utils/ajax';

export const getStations = osceId =>
  wrapRequest({
    method: 'GET',
    url: `/station/template/${osceId}`,
  });

export const addStations = (osceId, data) =>
  wrapRequest({
    method: 'POST',
    url: `/station/template/${osceId}`,
    data,
  });

export const editStations = (id, data) =>
  wrapRequest({
    method: 'PUT',
    url: `/station/template/${id}`,
    data,
  });

export const deleteStations = id =>
  wrapRequest({
    method: 'DELETE',
    url: `/station/template/${id}`,
  });
