import React, { PureComponent } from 'react';
import { get } from 'lodash';

/** UI**/
import { Row, Col } from 'antd';

/** Components */
import FormItemInput from 'components/Form/formInput';

/** Services */
import i18n from 'services/i18n';
import { checkPositiveNumber } from 'services/validations';

const checkPositive = {
  func: checkPositiveNumber,
  message: 'onlyPositiveNumber',
};

@i18n('form')
export default class BreakComponent extends PureComponent {
  render() {
    const { props } = this;
    return get(props.days, 'data.length') ? (
      <div className="break-block">
        <Row>
          <Col lg={8} md={12}>
            <FormItemInput
              colon
              min={0}
              type="number"
              name="shortBreak"
              initialValue={get(props.data, 'shortBreak')}
              label={props.translate('shortBreak')}
              labelWidth={14}
              inputWidth={7}
              required
              onChange={props.handleFormChange}
              validator={checkPositive}
            />
            <FormItemInput
              colon
              min={0}
              type="number"
              name="coffeeBreak"
              initialValue={get(props.data, 'coffeeBreak')}
              label={props.translate('coffeeBreak')}
              labelWidth={14}
              inputWidth={7}
              required
              validator={checkPositive}
            />
            <FormItemInput
              colon
              min={0}
              type="number"
              name="maxTimeUntilLunchBreak"
              initialValue={get(props.data, 'maxTimeUntilLunchBreak')}
              label={props.translate('maxTimeUntilLunchBreak')}
              labelWidth={14}
              inputWidth={7}
              validator={checkPositive}
            />
          </Col>
          <Col lg={8} md={12}>
            <FormItemInput
              colon
              min={0}
              type="number"
              name="lunchBreak"
              initialValue={get(props.data, 'lunchBreak')}
              label={props.translate('lunchBreak')}
              labelWidth={14}
              inputWidth={7}
              required
              validator={checkPositive}
            />
            <FormItemInput
              colon
              min={0}
              type="number"
              name="rotationBreak"
              initialValue={get(props.data, 'rotationBreak')}
              label={props.translate('rotationBreak')}
              labelWidth={14}
              inputWidth={7}
              required
              validator={checkPositive}
            />
            <FormItemInput
              colon
              min={0}
              type="number"
              name="maxTimeUntilLongBreak"
              initialValue={get(props.data, 'maxTimeUntilLongBreak')}
              label={props.translate('maxTimeUntilLongBreak')}
              labelWidth={14}
              inputWidth={7}
              validator={checkPositive}
            />
          </Col>
          <Col lg={8} md={12}>
            <FormItemInput
              colon
              min={0}
              type="number"
              name="stationDuration"
              initialValue={get(props.data, 'stationDuration')}
              label={props.translate('stationDuration')}
              labelWidth={14}
              inputWidth={7}
              required
              validator={checkPositive}
            />
          </Col>
        </Row>
      </div>
    ) : null;
  }
}
