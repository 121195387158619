import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { withRouter } from 'react-router';

/** UI*/
import { Button, Form, Modal } from 'antd';

/** Constants*/
import { OSCE_STATUS } from 'constants/common';

/** Services*/
import profileFormDecorator from 'services/decorators/profileFormDecorator';
import { msgError } from 'services/errorHandler';
import addLoadWithRedux from 'services/decorators/addLoadWithRedux';

/** Actions*/
import { getOsceDays } from 'actions/circuits';
import {
  updateOsce,
  generateCircuits,
  generateCircuitsManual,
} from 'actions/osceAdministration';

/** Components*/
import BasicData from './BasicInputs';
import TimeData from './TimeData';

/**
 * Component for rendering forms at osce basic data
 * @reactProps {func} translate - function which translate text.
 * @reactProps {func} data - data about osce
 * @reactProps {func} repetitionData - data about repetition of osces
 * @reactProps {string} updateData - function for updating data at redux store
 */
@withRouter
@addLoadWithRedux({
  i18Name: 'form',
  runtimeReduxNames: ['osce', 'osceDays'],
  mapMethods: { getOsceDays },
})
@profileFormDecorator({ i18Name: 'osceAdministration' })
export default class DataForm extends Component {
  static propTypes = {
    osceData: PropTypes.object,
    osceDaysData: PropTypes.object,
    repetitionData: PropTypes.object,
    translate: PropTypes.func,
    updateData: PropTypes.func,
    getOsceDays: PropTypes.func,
  };

  static childContextTypes = {
    disabled: PropTypes.bool,
  };

  getChildContext() {
    return {
      disabled: this.isDisable,
    };
  }

  componentDidMount() {
    const {
      match: {
        params: { id },
      },
      getOsceDays,
    } = this.props;
    getOsceDays('osceDays', id);
  }

  componentWillUpdate(nextProps) {
    if (
      !get(nextProps.osceData, 'name') &&
      get(this.props.osceData, 'name') !== get(nextProps.osceData, 'name')
    ) {
      this.props.form.resetFields();
    }
  }

  get isDisable() {
    const { props } = this;
    return (
      Boolean(get(props, 'osceData.deletedAt')) ||
      get(props, 'osceData.osceStatus') !== OSCE_STATUS.OSCE_CREATED
    );
  }

  showConfirm = () =>
    new Promise((resolve, reject) => {
      const { translate } = this.props;
      Modal.confirm({
        title: translate('osceTypeTitle'),
        content: ' ',
        onOk: () => resolve(true),
        okText: translate('yes', 'sections'),
        onCancel: () => resolve(false),
        cancelText: translate('no', 'sections'),
      });
    });

  changeOsce = async values => {
    const { osceData } = this.props;
    if (osceData.osceType !== values.OSCECreationType) {
      const isChange = await this.showConfirm();
      if (!isChange) {
        values.OSCECreationType =
          values.OSCECreationType === 'AUTO' ? 'MANUAL' : 'AUTO';
      }
    }
    return updateOsce({ ...osceData, ...values });
  };

  get onSubmit() {
    const { props } = this;
    const { osceData } = props;
    return props.wrapSubmit(
      values => this.changeOsce(values),
      newData => props.updateData({ ...osceData, ...newData }, 'osce'),
    );
  }

  createCircuit = async () => {
    try {
      const {
        osceData,
        osceData: { osceType },
        match: {
          params: { semesterId, id },
        },
        updateData,
      } = this.props;
      osceType === 'AUTO'
        ? await generateCircuits(id)
        : await generateCircuitsManual(id);
      updateData({ ...osceData, osceStatus: 'CIRCUITS CREATED' }, 'osce');
      this.props.history.push(
        `/osce-administration/${semesterId}/osce/circuits-details/${id}`,
      );
    } catch (e) {
      console.error(e);
      msgError(e);
    }
  };

  render() {
    const {
      osceData,
      repetitionData = {},
      translate,
      osceDaysData,
    } = this.props;
    return (
      <Form onSubmit={this.onSubmit}>
        <Button
          type="primary"
          disabled={this.isDisable && get(osceDaysData, 'data.length')}
          style={{ marginBottom: '10px' }}
          onClick={this.createCircuit}
        >
          {get(osceData, 'osceType') === 'AUTO'
            ? translate('generateOSCE', 'form')
            : translate('createCircuit', 'form')}
        </Button>
        <div className="form-header-grey">{translate('basicData')}</div>
        {osceData && (
          <BasicData
            data={osceData}
            repetitionData={repetitionData}
            semesterId={this.props.match.params.semesterId}
          />
        )}
        {get(osceData, 'osceType') === 'AUTO' && (
          <React.Fragment>
            <div className="basic-data-separator" />
            <div className="form-header-grey">{translate('timeData')}</div>
            <TimeData data={osceData} />
            <div className="basic-data-separator" />
          </React.Fragment>
        )}
      </Form>
    );
  }
}
