import React, { Component } from 'react';
import { Form, Checkbox } from 'antd';
import PropTypes from 'prop-types';
import i18n from 'services/i18n';

const FormItem = Form.Item;

@i18n('form')
export default class FormGroupCheckbox extends Component {
  static contextTypes = {
    form: PropTypes.object,
    disabled: PropTypes.bool,
  };

  static propTypes = {
    initialValue: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.array,
    ]), // ant form initialValue
    options: PropTypes.array,
    required: PropTypes.bool,
  };

  render() {
    const { getFieldDecorator } = this.context.form;
    const { props } = this;
    const { initialValue } = props;
    const rules = [];
    if (props.equired)
      rules.push({
        required: true,
        message: props.translate('checkboxGroup'),
      });
    return (
      <FormItem label={props.label}>
        {getFieldDecorator(props.name, { rules, initialValue })(
          <Checkbox.Group
            disabled={this.context.disabled}
            style={{ margin: 0 }}
            options={props.options}
            onChange={props.onChange}
          />,
        )}
      </FormItem>
    );
  }
}
