import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import { ThemeProvider } from 'styled-components';
import { Redirect, Route, Switch } from 'react-router';
// Services
import api from 'services/api';
/** Components **/
import Header from 'components/Header';
import SimulatedPatients from 'pages/simulatedPatients';
import Examiners from 'pages/examiners';
import Roles from 'pages/roles';
import SystemAdministration from 'pages/systemAdministration';
import OsceAdministration from 'pages/osceAdministration';
import ExaminersProfile from 'pages/examiners/profile';
import StudentsProfile from 'pages/administration/Students/profile';
import Administration from 'pages/administration';

/** Constants **/
import {
  S_PATIENT_ADMIN,
  EXAM_ADMIN,
  ROLES_ADMIN,
  OSCE_ADMIN,
  SUPER_ADMIN,
  ADMIN,
} from 'constants/common';

const { Content } = Layout;

const CurrentView = () => {
  const [semesterId, setSemesterId] = useState();
  const roles = localStorage.getItem('roles');

  useEffect(() => {
    const semesterId = localStorage.getItem('semesterId');

    if (semesterId === null) {
      api.getAllSemesters().then(res => {
        const { id } = res.data.data[0];
        localStorage.setItem('semesterId', id);
        setSemesterId(id);
      });
    } else {
      setSemesterId(semesterId);
    }
  });

  return (
    <Switch>
      <Route
        path="/sp"
        render={props => {
          return roles.indexOf(S_PATIENT_ADMIN) === -1 ? (
            <Redirect to="/not-found" />
          ) : (
            <SimulatedPatients {...props} />
          );
        }}
      />
      <Route
        path="/examiners"
        render={props => {
          return roles.indexOf(EXAM_ADMIN) === -1 ? (
            <Redirect to="/not-found" />
          ) : (
            <Examiners {...props} />
          );
        }}
      />
      <Route
        path="/roles"
        component={Roles}
        render={props => {
          return roles.indexOf(ROLES_ADMIN) === -1 ? (
            <Redirect to="/not-found" />
          ) : (
            <Roles {...props} />
          );
        }}
      />
      <Redirect
        exact
        from="/system-administration"
        to="/system-administration/administrators"
      />
      <Route
        path="/system-administration"
        render={props => {
          return roles.indexOf(SUPER_ADMIN) === -1 ? (
            <Redirect to="/not-found" />
          ) : (
            <SystemAdministration {...props} />
          );
        }}
      />
      <Redirect exact from="/administration" to="/administration/semesters" />
      <Route
        path="/administration"
        render={props => {
          return roles.indexOf(ADMIN) === -1 ? (
            <Redirect to="/not-found" />
          ) : (
            <Administration {...props} />
          );
        }}
      />
      {semesterId && (
        <Redirect
          exact
          from="/osce-administration"
          to={`/osce-administration/${semesterId}/osce`}
        />
      )}
      <Route
        path="/osce-administration"
        render={props => {
          return roles.indexOf(OSCE_ADMIN) === -1 ? (
            <Redirect to="/not-found" />
          ) : (
            <OsceAdministration {...props} />
          );
        }}
      />
      <Route path="/examiner-profile/:id" component={ExaminersProfile} />
      <Route path="/student-profile/:id" component={StudentsProfile} />
    </Switch>
  );
};

const MainContainer = () => {
  return (
    <ThemeProvider
      theme={{
        space: [0, 5, 10, 15, 20],
        breakpoints: ['32em', '48em', '64em'],
      }}
    >
      <Layout>
        <Header />
        <Content className="main-content">
          <CurrentView />
        </Content>
      </Layout>
    </ThemeProvider>
  );
};

export default MainContainer;
