import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { get } from 'lodash';
import { Icon, Popover, Button, Upload, message } from 'antd';
// Services
import api from 'services/api';
import i18n from 'services/i18n';
import i18nTable from 'services/i18nTable';
import addLoad from 'services/decorators/addLoad';
import notification from 'services/decorators/notif';
// Components
import Table from 'components/Table';
import TablePanel from 'components/TablePanel/index';
// Actions
import { reloadTableData, updateData } from 'actions/updateData';
import { studentCRUD } from 'actions/cruds';

import './style.less';
import { SERVER_URL } from '../../constants/global';

const mapStateToProps = ({ runtime }, { name }) => ({
  osceData: get(runtime, 'osceData'),
  students: get(runtime, `${name}Data.data`),
  fullCount: get(runtime, `${name}Data.pagination.fullCount`),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ reloadTableData, updateData }, dispatch);

@withRouter
@connect(
  mapStateToProps,
  mapDispatchToProps,
)
@notification('role')
@addLoad()
@i18n('studentsColumns')
@i18nTable('studentsColumns')
export default class Students extends Component {
  constructor(props) {
    super();
    props.studentsColumns.push({
      title: props.translate('attendance'),
      align: 'center',
      render: record => (
        <span
          className="osce-students__attendance"
          onClick={this.changeAttendance(record)}
        >
          <Icon type="close" />
        </span>
      ),
      width: '10%',
    });
  }

  componentDidMount() {
    this.auth = `Bearer ${localStorage.getItem('token')}`;
  }

  changeAttendance = student => e => {
    e.stopPropagation();

    api
      .removeStudentFromOSCE(student.id, this.props.match.params.id)
      .then(res => {
        this.props.reloadTableData(this.props.name, studentCRUD, {
          additionalUrl: this.props.additionalUrl,
        });
      })
      .catch(err => {
        if (err.response.status === 400) {
          message.error(err.response.data.message);
        } else {
          message.error(this.props.translate('all', 'error'));
        }
      });
  };

  get renderInfo() {
    return (
      <div className="information-popover">
        <p>{this.props.translate('xlsInformation', 'longText')}</p>
      </div>
    );
  }

  uploadFile = data => {
    // Create cb bounded to "this" context
    const updateTableData = () =>
      this.props.reloadTableData(this.props.name, studentCRUD, {
        additionalUrl: this.props.additionalUrl,
      });
    const { translate } = this.props;
    // Manually upload selected file
    const formData = new FormData();
    formData.append('file', data.file);
    // Create xhhtp object
    const request = new XMLHttpRequest();
    // Create request object
    request.open(
      'POST',
      `${SERVER_URL}/student/upload?osceId=${this.props.match.params.id}`,
    );
    // Set Authorization header
    request.setRequestHeader('Authorization', this.auth);
    // Wait for response
    request.onreadystatechange = function() {
      if (this.readyState === 4 && this.status === 200) {
        const responseString = this.response;
        const responseJson = JSON.parse(responseString);
        const { error, missingFields } = responseJson;

        if (error === '') {
          message.success(translate('success', 'error'));
          updateTableData();
        } else {
          let errorMsg = 'Next fields are missing: ';

          missingFields.forEach(field => {
            errorMsg += field;
          });

          message.error(errorMsg);
        }
      }
    };

    request.send(formData);
  };

  render() {
    return (
      <React.Fragment>
        <TablePanel
          search={this.props.search}
          name="students"
          action={studentCRUD}
          sendParams={[]}
        >
          <span style={{ display: 'inline-block', marginTop: 5 }}>
            <b>TOTAL</b>: {this.props.fullCount}
          </span>
          <Upload
            className="table-panel_btn-right"
            name="file"
            accept=".xls,.xlsx"
            showUploadList={false}
            customRequest={this.uploadFile}
          >
            <Button
              icon="download"
              loading={this.props.loading}
              disabled={
                get(this.props, 'osceData.freezed') || this.props.loading
              }
            >
              {this.props.translate('import')}
            </Button>
          </Upload>
          <Popover content={this.renderInfo}>
            <Icon
              type="info-circle-o"
              className="table-panel_btn-right information-icon"
            />
          </Popover>
        </TablePanel>
        <Table
          clearOnChangeAdditionalUrl
          name={this.props.name}
          className="osce-students"
          action={this.props.action}
          onRow={this.props.onRow}
          columns={this.props.studentsColumns}
          additionalUrl={this.props.additionalUrl}
          sendParams={this.props.sendParams}
        />
      </React.Fragment>
    );
  }
}
