import React from 'react'; // eslint-disable-line

import { parseAge, multipleDate, momentFormatDateTime } from 'services/format';
import { SEMESTER, YEARS } from './options';

export const patientColumns = [
  {
    translate: 'name',
    dataIndex: 'lastName',
    width: 100,
    sorter: true,
  },
  {
    translate: 'firstName',
    dataIndex: 'firstName',
    width: 100,
    sorter: true,
  },
  {
    translate: 'email',
    dataIndex: 'email',
    width: 100,
    sorterName: 'patientContactDetailsEmail',
    sorter: true,
  },
  {
    translate: 'gender',
    dataIndex: 'gender',
    sorterName: 'patientPersonalInfoGender',
    width: 100,
    sorter: true,
  },
  {
    translate: 'town',
    dataIndex: 'city',
    sorterName: 'patientContactDetailsCity',
    width: 100,
    sorter: true,
  },
  {
    translate: 'age',
    dataIndex: 'birthday',
    sorterName: 'patientPersonalInfoBirthday',
    render: parseAge,
    width: 100,
    sorter: true,
  },
];

export const sectionColumns = [
  {
    translate: 'section',
    dataIndex: 'name',
  },
];

export const examinerColumns = [
  {
    translate: 'lastName',
    from: 'form',
    dataIndex: 'lastName',
    width: '25%',
    sorter: true,
  },
  {
    translate: 'firstName',
    dataIndex: 'firstName',
    width: '25%',
    sorter: true,
  },
  {
    translate: 'title',
    dataIndex: 'academicTitle',
    sorterName: 'examinerPersonalInfoAcademicTitle',
    width: '25%',
    sorter: true,
  },
  {
    translate: 'clinics',
    from: 'examiners_sidebar',
    dataIndex: 'clinicName',
    width: '25%',
    sorterName: 'examinerContactDetailsClinicName',
    sorter: true,
  },
];

export const clinicColumns = [
  {
    translate: 'name',
    from: 'form',
    dataIndex: 'name',
    width: '25%',
    sorter: true,
  },
  {
    translate: 'city',
    from: 'form',
    dataIndex: 'city',
    width: '15%',
    sorter: true,
  },
  {
    translate: 'postalCode',
    from: 'form',
    dataIndex: 'postalCode',
    width: '15%',
    sorter: true,
  },
  {
    translate: 'street',
    from: 'form',
    dataIndex: 'street',
    width: '45%',
    sorter: true,
  },
];

export const clinicExaminersColumns = [
  {
    translate: 'lastName',
    from: 'form',
    dataIndex: 'lastName',
    width: '20%',
  },
  {
    translate: 'firstName',
    from: 'form',
    dataIndex: 'firstName',
    width: '20%',
  },
  {
    translate: 'title',
    from: 'examinerColumns',
    dataIndex: 'academicTitle',
    width: '60%',
  },
];

export const clinicExaminersByOsceColumns = [
  {
    translate: 'discipline',
    from: 'disciplineColumns',
    dataIndex: 'disciplineName',
    width: '20%',
  },
  {
    translate: 'osce',
    from: 'modalForm',
    dataIndex: 'osceName',
    width: '20%',
  },
  {
    translate: 'examiner',
    from: 'clinicExaminersByOsceColumns',
    dataIndex: 'examinerName',
    width: '20%',
  },
  {
    translate: 'startDate',
    from: 'examinersOSCEColumns',
    dataIndex: 'startDate',
    render: momentFormatDateTime,
    width: '20%',
  },
  {
    translate: 'endDate',
    from: 'examinersOSCEColumns',
    dataIndex: 'endDate',
    render: momentFormatDateTime,
    width: '20%',
  },
];

export const administratorsColumns = [
  {
    translate: 'ID',
    dataIndex: 'id',
    sorter: true,
    width: '6%',
  },
  {
    translate: 'firstName',
    dataIndex: 'firstName',
    sorter: true,
    width: '10%',
  },
  {
    translate: 'lastName',
    dataIndex: 'lastName',
    sorter: true,
    width: '10%',
  },
  {
    translate: 'state',
    dataIndex: 'state',
    sorter: true,
    width: '10%',
  },
  {
    translate: 'email',
    dataIndex: 'email',
    sorter: true,
    width: '20%',
  },
  {
    translate: 'rights',
    from: 'form',
    dataIndex: 'roles',
    localizeFrom: 'rights',
    width: '15%',
  },
];

export const roomsColumns = [
  {
    translate: 'roomNumber',
    dataIndex: 'roomNumber',
    from: 'form',
    width: '40%',
  },
  {
    translate: 'floor',
    dataIndex: 'floor',
    from: 'form',
    width: '40%',
  },
];

export const semesterColumns = [
  {
    translate: 'calendarYear',
    dataIndex: 'calendarYear',
    options: YEARS,
    from: 'form',
    component: 'Select',
    width: '35%',
  },
  {
    options: SEMESTER,
    component: 'Select',
    translate: 'semesterType',
    dataIndex: 'semesterType',
    from: 'form',
    width: '35%',
  },
];

export const skillInDisciplineColumns = [
  {
    translate: 'skill',
    dataIndex: 'name',
    width: '35%',
  },
  {
    translate: 'studyYear',
    dataIndex: 'studyYear',
    width: '25%',
  },
];

export const studentsColumns = [
  {
    translate: 'studentId',
    dataIndex: 'studentId',
    from: 'form',
    width: 100,
    sorter: true,
  },
  {
    translate: 'name',
    dataIndex: 'lastName',
    width: 100,
    sorter: true,
  },
  {
    translate: 'firstName',
    dataIndex: 'firstName',
    width: 100,
    sorter: true,
  },
  {
    translate: 'email',
    dataIndex: 'email',
    width: 100,
    sorter: true,
  },
];

export const examinersOSCEColumns = [
  {
    translate: 'osce',
    dataIndex: 'osce.osceName',
    width: '50%',
  },
  {
    translate: 'startDate',
    dataIndex: 'startDate',
    render: momentFormatDateTime,
    width: '25%',
  },
  {
    translate: 'endDate',
    dataIndex: 'endDate',
    render: momentFormatDateTime,
    width: '25%',
  },
];

export const studentsOSCEParticipationColumns = [
  {
    translate: 'osce',
    dataIndex: 'osce',
    from: 'examinersOSCEColumns',
    width: '25%',
  },
  {
    translate: 'semester',
    dataIndex: 'semester',
    from: 'examinersOSCEColumns',
    width: '50%',
  },
];

export const osceColumns = [
  {
    translate: 'osces',
    dataIndex: 'name',
    width: '20%',
  },
  {
    translate: 'stationNumber',
    dataIndex: 'stationNumber',
    width: '20%',
  },
  {
    translate: 'state',
    dataIndex: 'osceStatusType',
    from: 'administratorsColumns',
    width: '20%',
  },
  {
    translate: 'dates',
    render: multipleDate,
    width: '20%',
  },
  {
    translate: 'autoOrManual',
    dataIndex: 'osceType',
    width: '20%',
  },
];

export const checklistColumns = [
  {
    translate: 'section',
    dataIndex: 'name',
    width: '25%',
  },
  {
    translate: 'description',
    dataIndex: 'description',
    width: '25%',
  },
  {
    translate: 'weight',
    dataIndex: 'weight',
    width: '15%',
  },
];

export const catalogsColumns = [
  {
    translate: 'catalogs',
    dataIndex: 'name',
  },
];

export const costAccountingColumns = [
  {
    translate: 'firstName',
    dataIndex: 'firstName',
    width: '40%',
  },
  {
    translate: 'lastName',
    dataIndex: 'lastName',
    width: '60%',
  },
];

export const activityLogColumns = [
  {
    translate: 'time',
    dataIndex: 'time',
    width: '25%',
  },
  {
    translate: 'user',
    dataIndex: 'user',
    width: '25%',
  },
  {
    translate: 'action',
    dataIndex: 'action',
    width: '50%',
  },
];
