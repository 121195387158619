import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';

export default class ModalForm extends Component {
  static propsTypes = {
    width: PropTypes.number,
    title: PropTypes.string,
    visible: PropTypes.bool,
    destroyOnClose: PropTypes.bool,
    onCancel: PropTypes.func,
  };

  render() {
    const { props } = this;
    return (
      <Modal
        width={props.width}
        title={props.title}
        visible={props.visible}
        destroyOnClose={props.destroyOnClose}
        onCancel={props.onCancel}
        className={props.className}
        onOk={props.onOk}
        bodyStyle={{ padding: '0px' }}
        footer={null}
      >
        {this.props.children}
      </Modal>
    );
  }
}
