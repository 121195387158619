import React, { Component } from 'react';
import { get } from 'lodash';
import { Col, Row } from 'antd';

import EditableLabel from 'components/Form/formEditableLabel';
import Card from 'components/Form/Card';

export default class FeedbackCredentials extends Component {
  renderPassword = password => {
    if (!password) return null;
    const arr = [];
    for (let i = 0; i < password.length; i++) arr.push('*');
    return arr;
  };

  render() {
    const { data } = this.props;
    return (
      <Card
        from="cards"
        value="feedbackCredentials"
        className="osce-settings-card osce-settings-card_border"
      >
        <Row gutter={48}>
          <Col span={12}>
            <EditableLabel
              name="feedbackUsername"
              initialValue={get(data, 'feedbackUsername')}
            />
            <EditableLabel
              name="feedbackPassword"
              initialValue={get(data, 'feedbackPassword')}
              autoComplete="new-password"
              type="password"
              staticRenderFunction={this.renderPassword}
            />
          </Col>
          <Col span={12}>
            <EditableLabel
              name="feedbackRemoteDirPath"
              title="feedbackBasePath"
              initialValue={get(data, 'feedbackRemoteDirPath')}
            />
            <EditableLabel
              name="feedbackHost"
              initialValue={get(data, 'feedbackHost')}
            />
          </Col>
        </Row>
      </Card>
    );
  }
}
