import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Select, Spin } from 'antd';

class LazySelect extends Component {
  static propTypes = {
    loadData: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  static defaultProps = {
    dropdownMatchSelectWidth: false,
    onChange: () => {},
  };

  state = {
    data: [],
    search: '',
    page: 0,
    pageSize: 3000,
    loading: false,
  };

  componentDidMount() {
    const { search, page, pageSize } = this.state;
    // Start request
    this.setState({ loading: true });
    this.props
      .loadData({ search, page, pageSize })
      .then(res => {
        // Update state
        this.setState({
          data: res.data,
          loading: false,
        });
      })
      .catch(err => {
        this.setState({ loading: false });
      });
  }

  handleChange = search => {
    // Execute callback to notify parent
    this.props.onChange(search);
  };

  handleFilter = (input, option) =>
    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  render() {
    return (
      <Select
        {...this.props}
        showSearch
        loading={this.state.loading}
        onChange={this.handleChange}
        dropdownMatchSelectWidth={this.props.dropdownMatchSelectWidth}
        defaultActiveFirstOption={false}
        filterOption={this.handleFilter}
        style={{ minWidth: '100px', width: '100%' }}
        notFoundContent={this.state.loading ? <Spin /> : 'Not Found'}
      >
        {this.props.children}
        {this.state.data.map(item => (
          <Select.Option key={item.id} value={item.id}>
            {item.name}
          </Select.Option>
        ))}
      </Select>
    );
  }
}

export default LazySelect;
