import React, { Component } from 'react';
import { get } from 'lodash';

import { GENDER } from 'constants/options';
import { disciplineCRUD } from 'actions/cruds';
import { phoneNumber } from 'services/validations';
import EditableLabel from 'components/Form/formEditableLabel';
import EditableLabelDropDown from 'components/Form/formEditableLabelDropDown';
import Card from 'components/Form/Card';

export default class PersonalInfo extends Component {
  render() {
    const { data } = this.props;
    return (
      <Card from="cards" value="personalInformation">
        <EditableLabel
          name="examinerPersonalInfo.academicTitle"
          title="title"
          initialValue={get(data, 'examinerPersonalInfo.academicTitle')}
          onSubmit={this.context.submit}
        />
        <EditableLabelDropDown
          search
          name="examinerPersonalInfo.discipline"
          title="discipline"
          getList={disciplineCRUD.getList}
          initialValue={get(data, 'examinerPersonalInfo.discipline.id')}
          onSubmit={this.context.submit}
        />
        <EditableLabelDropDown
          name="examinerPersonalInfo.gender"
          title="gender"
          options={GENDER}
          initialValue={get(data, 'examinerPersonalInfo.gender')}
          onSubmit={this.context.submit}
        />
        <EditableLabel
          name="examinerContactDetails.email"
          title="email"
          type="email"
          initialValue={get(data, 'examinerContactDetails.email')}
          onSubmit={this.context.submit}
        />
        <EditableLabel
          name="examinerContactDetails.telephone"
          title="telephone"
          validator={{
            func: phoneNumber,
            message: 'notValidPhoneNumber',
          }}
          initialValue={get(data, 'examinerContactDetails.telephone')}
          onSubmit={this.context.submit}
        />
      </Card>
    );
  }
}
