import React, { Component } from 'react';
import PropTypes from 'prop-types';

import i18n from 'services/i18n';
import { phoneNumber } from 'services/validations';

import Card from '../Card';
import Field from '../form/Field';

@i18n('error')
export default class ContactDetails extends Component {
  localizeLabel = id => this.props.intl.formatMessage({ id });

  static childContextTypes = {
    updateName: PropTypes.string,
    path: PropTypes.string,
  };

  getChildContext() {
    return { updateName: 'contactDetails', path: 'contactDetails' };
  }

  render() {
    const { translate } = this.props;
    return (
      <Card title="contactDetails">
        <Field staticWidth name="street" />
        <Field staticWidth name="postalCode" />
        <Field staticWidth name="city" />
        <Field staticWidth name="country" />
        <Field
          staticWidth
          name="mobile"
          rules={[
            {
              validator: phoneNumber,
              message: translate('notValidPhoneNumber'),
            },
          ]}
        />
        <Field
          staticWidth
          name="telephone"
          rules={[
            {
              validator: phoneNumber,
              message: translate('notValidPhoneNumber'),
            },
          ]}
        />
        <Field
          staticWidth
          name="email"
          rules={[{ type: 'email', message: translate('email') }]}
        />
      </Card>
    );
  }
}
