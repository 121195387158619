import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import Upload from './Upload';
import BillingDetails from './cards/BillingDetails';
import PersonalInformation from './cards/PersonalInformation';
import ContactDetails from './cards/ContactDetails';
import ProfessionalDetails from './cards/ProfessionalDetails';
import NameInput from './form/NameInput';

import './style.less';

const mapStateToProps = ({ runtime }) => ({
  loaded: runtime.patientLoaded,
});

@connect(mapStateToProps)
export default class Patient extends PureComponent {
  get loadedContent() {
    return (
      <React.Fragment>
        <div className="image-col">
          <Upload />
        </div>
        <div className="spacer">
          <div className="card-username">
            <NameInput />
          </div>
          <div className="card-row">
            <PersonalInformation />
            <span className="card-border-vertical" />
            <ContactDetails />
          </div>
          <div className="card-row">
            <ProfessionalDetails />
            <span className="card-border-vertical" />
            <BillingDetails />
          </div>
        </div>
      </React.Fragment>
    );
  }

  render() {
    return (
      <div className="patient-container">
        {this.props.loaded && this.loadedContent}
      </div>
    );
  }
}
