import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';

export default class BellSystemTable extends Component {
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    columns: PropTypes.array.isRequired,
    dataSource: PropTypes.array.isRequired,
    loading: PropTypes.bool,
    onSelectChange: PropTypes.func,
  };

  static defaultProps = {
    loading: false,
    onSelectChange: () => {},
  };

  state = {
    selectedRowKeys: [],
  };

  handleSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
    this.props.onSelectChange(selectedRowKeys, this.props.id);
  };

  render() {
    const rowSelection = {
      selectedRowKeys: this.state.selectedRowKeys,
      onChange: this.handleSelectChange,
    };

    return (
      <Table
        rowSelection={rowSelection}
        pagination={false}
        columns={this.props.columns}
        dataSource={this.props.dataSource}
        loading={this.props.loading}
      />
    );
  }
}
