import React from 'react';
import { withRouter } from 'react-router';
import cn from 'classnames';

/** UI*/
import { Button, Popover } from 'antd';

/** Actions */
import { patientCRUD } from 'actions/cruds';
import { swapSP, getScheduleAssignment } from 'actions/schedule';

/** Services */
import closest from 'services/closest';

/** Component */
import addLoadWithRedux from 'services/decorators/addLoadWithRedux';
import { msgError } from 'services/errorHandler';
import PersonSearch from './PersonSearch';

/**
 * Component for rendering pop up content at simulated patient
 * @reactProps {object} patient - data about patient
 */
@withRouter
@addLoadWithRedux({
  mapMethods: { getScheduleAssignment },
})
class PatientData extends React.PureComponent {
  state = {
    patientId: undefined,
    patient: undefined,
    visible: false,
  };

  componentDidMount() {
    document.body.addEventListener('click', this.checkElement);
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.checkElement);
  }

  checkElement = e => {
    if (
      closest(e.target, '.ant-popover') === null &&
      closest(e.target, '.ant-select-dropdown') === null
    ) {
      this.setState({ visible: false });
    }
  };

  get contentPatient() {
    const { patient, isSpPaused, translate } = this.props;
    return (
      <React.Fragment>
        <div className="wrapper">
          <div className="fields">
            <p>{translate('spName')}:</p>
            <p>
              {patient.firstName
                ? `${patient.firstName} ${patient.lastName}`
                : translate('notAssigned')}
            </p>
          </div>
          <div className="fields">
            <p>{translate('assignStartTime')}:</p>
            <p>{patient.startTime}</p>
          </div>
          <div className="fields">
            <p>{translate('assignEndTime')}:</p>
            <p>{patient.endTime}</p>
          </div>
          {patient.personId && !isSpPaused && (
            <div className="fields">
              <p>{translate('swapSP')}</p>
              <div className="fields_item">
                <PersonSearch
                  action={patientCRUD}
                  additionalUrl={`swap/assignment/${patient.assignmentId}`}
                  onChange={this.handleChange}
                  value={this.state.patient}
                  placeholder={translate('selectSP')}
                  translate={translate}
                />
              </div>
            </div>
          )}
        </div>
        {patient.personId && !isSpPaused && (
          <div className="footer-btns">
            <Button type="primary" className="btn" onClick={this._swapSP}>
              {translate('swap')}
            </Button>
            <Button
              className="btn"
              onClick={() => this.setState({ visible: false })}
            >
              {translate('cancel')}
            </Button>
          </div>
        )}
      </React.Fragment>
    );
  }

  _swapSP = async () => {
    try {
      if (this.state.patientId) {
        const {
          patient,
          match: { params },
          getScheduleAssignment,
        } = this.props;
        await swapSP({
          assignmentToSwapId: patient.assignmentId,
          osceId: params.id,
          spToSwapWithId: this.state.patientId,
        });
        await getScheduleAssignment('schedule', params.id);
        this.setState({
          patient: undefined,
          patientId: undefined,
          visible: false,
        });
      } else {
        msgError('You should select patient in drop-down list');
      }
    } catch (e) {
      msgError(e);
    }
  };

  handleChange = item => {
    this.setState({ patientId: item.id, patient: item.name });
  };

  togglePopover = () => this.setState({ visible: !this.state.visible });

  render() {
    const { patient, translate } = this.props;
    return (
      <Popover
        trigger="hover"
        placement="right"
        mouseEnterDelay={0.8}
        content={
          patient.firstName
            ? `${patient.firstName} ${patient.lastName}`
            : translate('notAssigned')
        }
      >
        <Popover
          trigger="click"
          placement="right"
          onClick={this.togglePopover}
          visible={this.state.visible}
          content={this.contentPatient}
        >
          <div className={cn('cell', { disabled: !patient.personId })}>
            {`SP0${patient.sequenceNumber}`}
            <br />
            <div>
              {patient.personId
                ? `${patient.firstName.charAt(0)}${patient.lastName.charAt(0)}`
                : ''}
            </div>
          </div>
        </Popover>
      </Popover>
    );
  }
}

export default PatientData;
