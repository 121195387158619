import React, { Component } from 'react';
import { Card as ACard } from 'antd';
import { FormattedMessage } from 'react-intl';

export default class Card extends Component {
  render() {
    const { props } = this;
    return (
      <ACard bordered={false} className="form-card">
        <ACard.Meta
          description={<FormattedMessage id={`patient.${props.title}`} />}
        />
        {props.children}
      </ACard>
    );
  }
}
