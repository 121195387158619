import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Popconfirm, Button, message } from 'antd';
// Services
import api from 'services/api';
import i18n from 'services/i18n';
// Utils
import { downloadFile } from 'utils/downloadFile';
// Components
import SelectTable from 'components/SelectTable';
import ModalEditor from './modalEditor';

const mapStateToProps = state => ({
  language: state.localization.lang,
});

@i18n('output')
@connect(mapStateToProps)
class ExaminersTable extends PureComponent {
  static propTypes = {
    osceId: PropTypes.number.isRequired,
    semesterId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    exportQR: PropTypes.bool,
    sendEmail: PropTypes.bool,
  };

  static defaultProps = {
    sendEmail: false,
    exportQR: false,
  };

  constructor(props) {
    super();

    this.columns = [
      {
        title: props.translate('examiners'),
        dataIndex: 'text',
      },
    ];

    this.state = {
      data: [],
      selectedExaminers: [],
      showEditor: false,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.osceId !== this.props.osceId) {
      this.loadData();
    }
  }

  loadData = () => {
    api.getExaminersByOsce(this.props.osceId).then(res => {
      this.setState({ data: res.data.data });
    });
  };

  handleOpenEditor = () => this.setState({ showEditor: true });

  handleCloseEditor = () => this.setState({ showEditor: false });

  handleSelectExaminers = selectedExaminers =>
    this.setState({ selectedExaminers });

  handleloadPDF = template => {
    const data = {
      ids: this.state.selectedExaminers,
      template,
    };

    const locale = this.props.language === 'en' ? 'ENGLISH' : 'GERMAN';

    api
      .printExaminersSchedule(this.props.osceId, locale, data)
      .then(res =>
        downloadFile(res.data, `examiner_schedule.pdf`, 'application/pdf'),
      )
      .catch(err => message.error(this.props.translate('all', 'error')));
  };

  handleLoadQR = () => {
    const data = { ids: this.state.selectedExaminers };
    api
      .printQRCode(this.props.osceId, 'EXAMINER', data)
      .then(res => downloadFile(res.data, 'examinerQR.pdf', 'application/pdf'))
      .catch(err => message.error(this.props.translate('all', 'error')));
  };

  handleSendEmailInvitation = () => {
    const data = { ids: this.state.selectedExaminers };
    api
      .sendEmailInvitation(this.props.semesterId, 'examiner', data)
      .then(res => message.success(this.props.translate('successMail')))
      .catch(err => message.error(this.props.translate('all', 'error')));
  };

  render() {
    const dataSource = this.state.data.map(item => ({
      ...item,
      key: item.examinerId,
      text: `${item.lastName} ${item.firstName}`,
    }));

    let sendMailBtn = null;

    // Do we need to render send mail btn
    if (this.props.sendEmail) {
      // Popconfirm disabled property doesn't work
      // so if no selected sps just render button disabled
      if (this.state.selectedExaminers.length > 0) {
        sendMailBtn = (
          <Popconfirm
            placement="bottom"
            title={this.props.translate('areYouSureSendEmail')}
            onConfirm={this.handleSendEmailInvitation}
          >
            <Button className="sendInvitations-btn">
              {this.props.translate('sendMail')}
            </Button>
          </Popconfirm>
        );
      } else {
        sendMailBtn = (
          <Button disabled className="sendInvitations-btn">
            {this.props.translate('sendMail')}
          </Button>
        );
      }
    }

    return (
      <React.Fragment>
        <ModalEditor
          type="schedule"
          name="examiner"
          loadPDF={this.handleloadPDF}
          printCopiesParams={{ osceId: this.props.osceId }}
          show={this.state.showEditor}
          close={this.handleCloseEditor}
        />
        <Button
          className="sendInvitations-btn"
          disabled={!this.state.selectedExaminers.length}
          onClick={this.handleOpenEditor}
        >
          {this.props.translate('printCopies')}
        </Button>
        {sendMailBtn}
        {this.props.exportQR ? (
          <Button
            className="sendInvitations-btn"
            disabled={!this.state.selectedExaminers.length}
            onClick={this.handleLoadQR}
          >
            {this.props.translate('exportQRCode')}
          </Button>
        ) : null}
        <div className="table-with-selection">
          <SelectTable
            columns={this.columns}
            dataSource={dataSource}
            onSelect={this.handleSelectExaminers}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default ExaminersTable;
