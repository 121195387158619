import React from 'react';
import { Button, Popover } from 'antd';
import { withRouter } from 'react-router';
import cn from 'classnames';

/** Actions */
import {
  swapStudents,
  getScheduleAssignment,
  getScheduleOsceDay,
} from 'actions/schedule';
import { studentCRUD } from 'actions/cruds';

/** Utils, Config */
import addLoadWithRedux from 'services/decorators/addLoadWithRedux';

/** Component */
import closest from 'services/closest';
import { msgError } from 'services/errorHandler';
import PersonSearch from './PersonSearch';

/**
 * Component for rendering pop up content at student
 * @reactProps {object} student - data about student
 */
@withRouter
@addLoadWithRedux({
  runtimeReduxNames: ['schedule'],
  mapMethods: { getScheduleAssignment },
})
class StudentData extends React.PureComponent {
  state = {
    studentSequenceNumber: undefined,
    visible: false,
  };

  componentDidMount() {
    document.body.addEventListener('click', this.checkElement);
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.checkElement);
  }

  checkElement = e => {
    if (
      closest(e.target, '.ant-popover') === null &&
      closest(e.target, '.ant-select-dropdown') === null
    ) {
      this.setState({ visible: false });
    }
  };

  get contentStudent() {
    const {
      student,
      match: { params },
      translate,
    } = this.props;
    return (
      <React.Fragment>
        <div className="wrapper">
          <div className="fields">
            <p>{translate('studentName')}:</p>
            <p>
              {student.firstName
                ? `${student.firstName} ${student.lastName}`
                : translate('notAssigned')}
            </p>
          </div>
          <div className="fields">
            <p>{translate('assignStartTime')}:</p>
            <p>{student.startTime}</p>
          </div>
          <div className="fields">
            <p>{translate('assignEndTime')}:</p>
            <p>{student.endTime}</p>
          </div>
          <div className="fields">
            <p>{translate('swapStudent')}:</p>
            <div className="fields_item">
              <PersonSearch
                scroll={false}
                name="student"
                action={studentCRUD}
                additionalUrl={`assignment/get/${params.id}`}
                onChange={this.handleChange}
                value={this.state.studentSequenceNumber}
                placeholder={translate('selectStudent')}
                translate={translate}
              />
            </div>
          </div>
        </div>
        <div className="footer-btns">
          <Button type="primary" className="btn" onClick={this._swapStudent}>
            {translate('swap')}
          </Button>
          <Button
            className="btn"
            onClick={() => this.setState({ visible: false })}
          >
            {translate('cancel')}
          </Button>
        </div>
      </React.Fragment>
    );
  }

  _swapStudent = async () => {
    try {
      if (this.state.studentSequenceNumber) {
        const {
          student,
          match: { params },
          osceDayId,
          scheduleData,
          updateData,
        } = this.props;
        await swapStudents({
          osceId: params.id,
          firstSequenceNumber: student.sequenceNumber,
          secondSequenceNumber: this.state.studentSequenceNumber,
        });
        const { data } = await getScheduleOsceDay(osceDayId);
        const newData = [...scheduleData.osceDays];
        const osceDayIndex = newData.findIndex(item => item.id === osceDayId);
        if (osceDayIndex > -1) {
          newData.splice(osceDayIndex, 1, data);
        }
        updateData({ osceDays: newData }, 'schedule');
        this.setState({ visible: false, studentSequenceNumber: undefined });
      } else {
        msgError('You should select student in drop-down list');
      }
    } catch (e) {
      msgError(e);
    }
  };

  handleChange = item => {
    this.setState({
      studentSequenceNumber: item.sequenceNumber,
    });
  };

  togglePopover = () => this.setState({ visible: !this.state.visible });

  render() {
    const { student, translate } = this.props;

    return (
      <Popover
        trigger="hover"
        placement="right"
        mouseEnterDelay={0.8}
        content={
          student.firstName
            ? `${student.firstName} ${student.lastName}`
            : translate('notAssigned')
        }
      >
        <Popover
          trigger="click"
          onClick={this.togglePopover}
          visible={this.state.visible}
          content={this.contentStudent}
        >
          <div className={cn('cell', { disabled: !student.personId })}>
            {`S0${student.sequenceNumber}`}
          </div>
        </Popover>
      </Popover>
    );
  }
}

export default StudentData;
