import { combineReducers } from 'redux';
import {
  SP_REQUEST_START,
  FETCH_SPS_SUCCESS,
  FETCH_SPS_ERROR,
  LOAD_MORE_SPS_SUCCESS,
  CREATE_SP_SUCCESS,
  CREATE_SP_ERROR,
  GET_SP_PROFILE_SUCCESS,
  GET_SP_PROFILE_ERROR,
  FETCH_ALL_SP_OSCES_SUCCESS,
  FETCH_ALL_SP_OSCES_ERROR,
  LOAD_MORE_SP_OSCES_SUCCESS,
} from 'constants/simulatedPatients';

const loading = (state = false, action) => {
  switch (action.type) {
    case SP_REQUEST_START:
      return true;

    case FETCH_SPS_SUCCESS:
    case FETCH_SPS_ERROR:
    case LOAD_MORE_SPS_SUCCESS:
    case CREATE_SP_SUCCESS:
    case CREATE_SP_ERROR:
    case GET_SP_PROFILE_SUCCESS:
    case GET_SP_PROFILE_ERROR:
    case FETCH_ALL_SP_OSCES_SUCCESS:
    case FETCH_ALL_SP_OSCES_ERROR:
    case LOAD_MORE_SP_OSCES_SUCCESS:
      return false;

    default:
      return state;
  }
};

const errors = (state = {}, action) => {
  switch (action.type) {
    case FETCH_SPS_ERROR:
    case CREATE_SP_ERROR:
    case GET_SP_PROFILE_ERROR:
    case FETCH_ALL_SP_OSCES_ERROR:
      return action.error;
    default:
      return state;
  }
};

const data = (state = [], action) => {
  switch (action.type) {
    case FETCH_SPS_SUCCESS:
      return action.payload.simulatedPatients.data;

    case LOAD_MORE_SPS_SUCCESS:
      return state.concat(action.payload.simulatedPatients.data);

    default:
      return state;
  }
};

const profile = (state = {}, action) => {
  switch (action.type) {
    case GET_SP_PROFILE_SUCCESS:
      return action.payload.profile;

    default:
      return state;
  }
};

const osces = (state = [], action) => {
  switch (action.type) {
    case FETCH_ALL_SP_OSCES_SUCCESS:
      return action.payload.osces.data;

    case LOAD_MORE_SP_OSCES_SUCCESS:
      return state.concat(action.payload.osces.data);

    default:
      return state;
  }
};

const simulatedPatients = combineReducers({
  loading,
  errors,
  data,
  profile,
  osces,
});

export default simulatedPatients;
