import { combineReducers } from 'redux';
import {
  CHANGE_PROFESSIONS_START,
  GET_PROFESSIONS_SUCCESS,
  GET_PROFESSIONS_ERROR,
  DELETE_PROFESSION_SUCCESS,
  DELETE_PROFESSION_ERROR,
  UPDATE_PROFESSION_SUCCESS,
  UPDATE_PROFESSION_ERROR,
  CREATE_PROFESSION_SUCCESS,
  CREATE_PROFESSION_ERROR,
  LOAD_MORE_PROFESSIONS_SUCCESS,
} from 'constants/profession';

const loading = (state = false, action) => {
  switch (action.type) {
    case CHANGE_PROFESSIONS_START:
      return true;

    case GET_PROFESSIONS_SUCCESS:
    case GET_PROFESSIONS_ERROR:
    case DELETE_PROFESSION_SUCCESS:
    case DELETE_PROFESSION_ERROR:
    case UPDATE_PROFESSION_SUCCESS:
    case UPDATE_PROFESSION_ERROR:
    case CREATE_PROFESSION_SUCCESS:
    case CREATE_PROFESSION_ERROR:
    case LOAD_MORE_PROFESSIONS_SUCCESS:
      return false;

    default:
      return state;
  }
};

const errors = (state = {}, action) => {
  switch (action.type) {
    case GET_PROFESSIONS_ERROR:
    case DELETE_PROFESSION_ERROR:
    case UPDATE_PROFESSION_ERROR:
    case CREATE_PROFESSION_ERROR:
      return action.error;

    default:
      return state;
  }
};

const data = (state = [], action) => {
  switch (action.type) {
    case GET_PROFESSIONS_SUCCESS:
      return action.payload.professions;

    case LOAD_MORE_PROFESSIONS_SUCCESS: {
      return state.concat(action.payload.professions);
    }

    case DELETE_PROFESSION_SUCCESS: {
      return state.filter(p => p.id !== action.payload.id);
    }

    case UPDATE_PROFESSION_SUCCESS: {
      const { profession } = action.payload;
      const newState = state.map(p =>
        p.id === profession.id ? { ...profession } : p,
      );

      return newState;
    }

    case CREATE_PROFESSION_SUCCESS: {
      return state.concat(action.payload.profession);
    }

    default:
      return state;
  }
};

const professions = combineReducers({
  loading,
  data,
  errors,
});

export default professions;
