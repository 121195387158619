import api from 'services/api';
import {
  CHANGE_CHECKLIST_START,
  FETCH_CHECKLIST_SUCCESS,
  FETCH_CHECKLIST_ERROR,
  CREATE_CHECKLIST_SECTION_SUCCESS,
  CREATE_CHECKLIST_SECTION_ERROR,
  FETCH_CHECKLIST_SECTIONS_SUCCESS,
  FETCH_CHECKLIST_SECTIONS_ERROR,
  IMPORT_CHECKLIST_SECTIONS_SUCCESS,
  IMPORT_CHECKLIST_SECTIONS_ERROR,
  CHANGE_CHECKLIST_QUEUE_SUCCESS,
  CHANGE_CHECKLIST_QUEUE_ERROR,
  FETCH_CHECKLIST_QUESTIONS_SUCCESS,
  FETCH_CHECKLIST_QUESTIONS_ERROR,
  CHANGE_CHECKLIST_QUESTION_QUEUE_SUCCESS,
  CHANGE_CHECKLIST_QUESTION_QUEUE_ERROR,
  DELETE_CHECKLIST_SECTION_SUCCESS,
  UPDATE_CHECKLIST_SECTION_SUCCESS,
  UPDATE_CHECKLIST_SECTION_ERROR,
  CREATE_QUESTION_SUCCESS,
  CREATE_QUESTION_ERROR,
  IMPORT_QUESTION_SUCCESS,
  IMPORT_QUESTION_ERROR,
  UPDATE_QUESTION_IN_SECTION_SUCCESS,
  UPDATE_QUESTION_IN_SECTION_ERROR,
  DELETE_QUESTION_IN_SECTION_SUCCESS,
  DELETE_QUESTION_IN_SECTION_ERROR,
  DELETE_CHECKLIST_SECTION_ERROR,
} from 'constants/checkList';

export const checkListChangeQueueSection = data => async dispatch => {
  try {
    dispatch({ type: CHANGE_CHECKLIST_START });
    const response = await api.checkListChangeQueueSection(data);
    const checkList = response.data.data;
    dispatch({ type: CHANGE_CHECKLIST_QUEUE_SUCCESS, payload: { checkList } });
    return checkList;
  } catch (error) {
    dispatch({ type: CHANGE_CHECKLIST_QUEUE_ERROR, error });
    throw error;
  }
};

export const checkListChangeQueueQuestion = (
  sectionId,
  data,
) => async dispatch => {
  try {
    dispatch({ type: CHANGE_CHECKLIST_START });
    const response = await api.checkListChangeQueueQuestion(data);
    const questions = response.data.data;
    dispatch({
      type: CHANGE_CHECKLIST_QUESTION_QUEUE_SUCCESS,
      payload: { questions, sectionId },
    });
    return questions;
  } catch (error) {
    dispatch({ type: CHANGE_CHECKLIST_QUESTION_QUEUE_ERROR, error });
    throw error;
  }
};

export const getCheckList = id => async dispatch => {
  try {
    dispatch({ type: CHANGE_CHECKLIST_START });
    const response = await api.getCheckList(id);
    const checkList = response.data.checkListSectionResponseList;
    return dispatch({ type: FETCH_CHECKLIST_SUCCESS, payload: { checkList } });
  } catch (error) {
    return dispatch({ type: FETCH_CHECKLIST_ERROR, error });
  }
};

export const createCheckListSection = (id, data) => async dispatch => {
  try {
    dispatch({ type: CHANGE_CHECKLIST_START });
    const response = await api.createCheckListSection(id, data);
    const section = response.data;
    dispatch({
      type: CREATE_CHECKLIST_SECTION_SUCCESS,
      payload: { section },
    });
    return section;
  } catch (error) {
    dispatch({ type: CREATE_CHECKLIST_SECTION_ERROR, error });
    throw error;
  }
};

export const getAllCheckListSections = data => async dispatch => {
  try {
    dispatch({ type: CHANGE_CHECKLIST_START });
    const response = await api.getAllCheckListSections(data);
    const sections = response.data;
    return dispatch({
      type: FETCH_CHECKLIST_SECTIONS_SUCCESS,
      payload: { sections },
    });
  } catch (error) {
    return dispatch({ type: FETCH_CHECKLIST_SECTIONS_ERROR, error });
  }
};

export const importAllCheckListSections = data => async dispatch => {
  try {
    dispatch({ type: CHANGE_CHECKLIST_START });
    const response = await api.importAllCheckListSections(data);
    const sections = response.data;
    dispatch({
      type: IMPORT_CHECKLIST_SECTIONS_SUCCESS,
      payload: { sections },
    });
    return sections;
  } catch (error) {
    dispatch({ type: IMPORT_CHECKLIST_SECTIONS_ERROR, error });
    throw error;
  }
};

export const getCheckListQuestionsInSection = sectionId => async dispatch => {
  try {
    dispatch({ type: CHANGE_CHECKLIST_START });
    const response = await api.getCheckListQuestionsInSection(sectionId);
    const questions = response.data;
    dispatch({
      type: FETCH_CHECKLIST_QUESTIONS_SUCCESS,
      payload: { sectionId, questions },
    });
    return questions;
  } catch (error) {
    dispatch({ type: FETCH_CHECKLIST_QUESTIONS_ERROR, error });
    throw error;
  }
};

export const deleteCheckListSection = sectionId => async dispatch => {
  try {
    dispatch({ type: CHANGE_CHECKLIST_START });
    await api.deleteCheckListSection(sectionId);
    dispatch({
      type: DELETE_CHECKLIST_SECTION_SUCCESS,
      payload: { sectionId },
    });
  } catch (error) {
    dispatch({ type: DELETE_CHECKLIST_SECTION_ERROR, error });
    throw error;
  }
};

export const updateCheckListSection = data => async dispatch => {
  try {
    dispatch({ type: CHANGE_CHECKLIST_START });
    const response = await api.updateCheckListSection(data);
    const section = response.data;
    dispatch({ type: UPDATE_CHECKLIST_SECTION_SUCCESS, payload: { section } });
    return section;
  } catch (error) {
    dispatch({ type: UPDATE_CHECKLIST_SECTION_ERROR, error });
    throw error;
  }
};

export const createQuestionInSection = (sectionId, data) => async dispatch => {
  try {
    dispatch({ type: CHANGE_CHECKLIST_START });
    const response = await api.createQuestionInSection(sectionId, data);
    const question = response.data;
    dispatch({
      type: CREATE_QUESTION_SUCCESS,
      payload: { sectionId, question },
    });
    dispatch(getCheckListQuestionsInSection(sectionId));
    return question;
  } catch (error) {
    dispatch({ type: CREATE_QUESTION_ERROR, error });
    throw error;
  }
};

export const importQuestionToSection = (
  sectionId,
  questionId,
) => async dispatch => {
  try {
    dispatch({ type: CHANGE_CHECKLIST_START });
    const response = await api.importQuestionToSection(sectionId, questionId);
    const question = response.data;
    dispatch({
      type: IMPORT_QUESTION_SUCCESS,
      payload: { sectionId, question },
    });
    dispatch(getCheckListQuestionsInSection(sectionId));
    return question;
  } catch (error) {
    dispatch({ type: IMPORT_QUESTION_ERROR, error });
    throw error;
  }
};

export const updateQuestionInSection = (questionId, data) => async dispatch => {
  try {
    dispatch({ type: CHANGE_CHECKLIST_START });
    const { sectionId } = data;
    const response = await api.updateQuestionInSection(questionId, data);
    const question = response.data;
    dispatch({
      type: UPDATE_QUESTION_IN_SECTION_SUCCESS,
      payload: { sectionId, question },
    });
    return question;
  } catch (error) {
    dispatch({ type: UPDATE_QUESTION_IN_SECTION_ERROR, error });
    throw error;
  }
};

export const deleteQuestionInSection = (
  sectionId,
  questionId,
) => async dispatch => {
  try {
    dispatch({ type: CHANGE_CHECKLIST_START });
    await api.deleteQuestionInSection(questionId);
    dispatch({
      type: DELETE_QUESTION_IN_SECTION_SUCCESS,
      payload: { sectionId, questionId },
    });
  } catch (error) {
    dispatch({ type: DELETE_QUESTION_IN_SECTION_ERROR, error });
    throw error;
  }
};
