import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Popconfirm, Select } from 'antd';

/** Actions */
import { getCircuit, addStation, deleteStation } from 'actions/circuits';

/** Constants*/
import { STATION_TYPES } from 'constants/common';
import { STATION_TYPE } from 'constants/options';

/** Utils, Configs*/
import i18n from 'services/i18n';
import { msgError } from 'services/errorHandler';
import addLoadWithRedux from 'services/decorators/addLoadWithRedux';

/** Components*/
import SectionHeader from '../sectionHeader';
import EditableSection from './editableSection';

/**
 * Component for rendering station content at circuits
 * @reactProps {func} translate - function for translation
 * @reactProps {object} sequenceData - list sequence of current osceDay
 * @reactProps {number} index - index of station
 * @reactProps {object} station - station parameters
 * @reactProps {number} positionInOsceSequence - position of station in osce sequence
 * @reactProps {number} circuitId - id of circuit
 * @reactProps {number} countStations - number of station
 * @reactProps {bool} isManualOsce - is current osce Manual
 */
@i18n('circuit')
@addLoadWithRedux({
  i18Name: 'circuit',
  runtimeReduxNames: [
    props => ({
      as: 'sequence',
      runtimeName: `sequence${props.station.osceDayId}`,
    }),
  ],
})
export default class CircuitStation extends Component {
  static propsTypes = {
    translate: PropTypes.func,
    sequenceData: PropTypes.object,
    index: PropTypes.number,
    station: PropTypes.object,
    positionInOsceSequence: PropTypes.number,
    circuitId: PropTypes.number,
    countStations: PropTypes.number,
    isManualOsce: PropTypes.bool.isRequired,
  };

  getStationWidth(stationType) {
    switch (stationType) {
      case STATION_TYPES.BREAK:
        return '130px';
      case STATION_TYPES.PREPARATION:
      case STATION_TYPES.DOUBLE:
        return '260px';
      default:
        return '140px';
    }
  }

  get renderStation() {
    const {
      station: { postResponses },
      index,
    } = this.props;
    return (
      <div style={{ display: 'flex' }}>
        {postResponses.map((el, i) => (
          <EditableSection
            {...this.props}
            fieldData={el}
            key={i}
            index={i}
            stationPosition={index + 1}
          />
        ))}
      </div>
    );
  }

  get deleteButton() {
    const { props } = this;
    return props.isManualOsce ? (
      <Popconfirm
        placement="bottom"
        title={props.translate('areYouSureDelete', 'confirm')}
        onConfirm={this.deleteStation}
      >
        <Button
          disabled={props.countStations < 2}
          className="circuit_section_header_close"
          icon="close"
          shape="circle"
          size="small"
        />
      </Popconfirm>
    ) : null;
  }

  createStation = async item => {
    const { station, circuitId, sequenceData, updateData } = this.props;
    try {
      await addStation(station.osceSequenceId, circuitId, {
        stationType: item,
        isAddToOsceSequence: false,
      });
      sequenceData.data.forEach(async sequence => {
        if (sequence.id) {
          const { data } = await getCircuit(sequence.id);
          updateData({ data: data.data }, `circuit${sequence.id}`);
        }
      });
    } catch (e) {
      msgError(e);
    }
  };

  deleteStation = async () => {
    const { station, sequenceData, updateData } = this.props;
    try {
      await deleteStation(station.id);
      sequenceData.data.forEach(async sequence => {
        if (sequence.id) {
          const { data } = await getCircuit(sequence.id);
          updateData({ data: data.data }, `circuit${sequence.id}`);
        }
      });
    } catch (e) {
      msgError(e);
    }
  };

  render() {
    const {
      station: { id, stationType },
      index,
      translate,
    } = this.props;
    const width = this.getStationWidth(stationType);
    return id ? (
      stationType !== STATION_TYPES.SAMPLE && (
        <div
          className={`station station_circuit station-${stationType &&
            stationType.toLowerCase()}`}
          key={id}
          style={{ width: width }}
        >
          <SectionHeader
            contentDeleteBtn={this.deleteButton}
            title={`Station ${index + 1}`}
            type={translate(stationType)}
          />
          {stationType !== STATION_TYPES.BREAK && this.renderStation}
        </div>
      )
    ) : (
      <div
        className="station station_circuit station_new"
        key={`New station ${index}`}
        style={{ width: width }}
      >
        <SectionHeader title={this.props.translate('newStation')} />
        <div className="station_new_content">
          <Select
            style={{ width: 100 }}
            placeholder={this.props.translate('type')}
            value={undefined}
            onChange={this.createStation}
          >
            {STATION_TYPE.map(element => (
              <Select.Option value={element.value} key={element.value}>
                {translate(element.value)}
              </Select.Option>
            ))}
          </Select>
        </div>
      </div>
    );
  }
}
