import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Popover, Button, Icon } from 'antd';
// Services
import i18n from 'services/i18n';

@i18n('roleCriteria')
class FilterItem extends Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
    formComponent: PropTypes.node.isRequired,
    onVisibleChange: PropTypes.func.isRequired,
    iconComponent: PropTypes.elementType,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    disabled: false,
  };

  render() {
    if (this.props.disabled) {
      return (
        <Button disabled type="ghost">
          <Icon component={this.props.iconComponent} />
          {this.props.translate(this.props.type)}
        </Button>
      );
    }

    return (
      <Popover
        trigger="click"
        placement="bottom"
        overlayClassName="criteria_popover"
        visible={this.props.visible}
        onVisibleChange={() => this.props.onVisibleChange(this.props.type)}
        content={this.props.formComponent}
      >
        <Button type="ghost">
          <Icon component={this.props.iconComponent} />
          {this.props.translate(this.props.type)}
        </Button>
      </Popover>
    );
  }
}

export default FilterItem;
