import { combineReducers } from 'redux';
import {
  CLINIC_REQUEST_START,
  FETCH_CLINICS_SUCCESS,
  FETCH_CLINICS_ERROR,
  LOAD_MORE_CLINICS_SUCCESS,
} from 'constants/clinic';

const loading = (state = false, action) => {
  switch (action.type) {
    case CLINIC_REQUEST_START:
      return true;

    case FETCH_CLINICS_SUCCESS:
    case FETCH_CLINICS_ERROR:
    case LOAD_MORE_CLINICS_SUCCESS:
      return false;

    default:
      return state;
  }
};

const errors = (state = {}, action) => {
  switch (action.type) {
    case FETCH_CLINICS_ERROR:
      return action.error;
    default:
      return state;
  }
};

const data = (state = [], action) => {
  switch (action.type) {
    case FETCH_CLINICS_SUCCESS:
      return action.payload.clinics.data;

    case LOAD_MORE_CLINICS_SUCCESS:
      return state.concat(action.payload.clinics.data);

    default:
      return state;
  }
};

const clinics = combineReducers({
  loading,
  errors,
  data,
});

export default clinics;
